import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LinkMapper } from '../LinkMapperContext';
import { parseTextFromMarkDown, SpanProps, TableProps } from './ContentTable';
import { Div, Span } from '../CleanSlate';

interface ElementsContainerProps {
  level: number;
  link?: string;
}

type ElementsProps = Omit<SpanProps, 'sideContainerWidth'>;

const ElementsContainer = styled(Div)<ElementsContainerProps>`
  padding-left: ${(props) =>
    props.level === 1 ? '0px' : `${12 * (props.level - 1)}px`};
  line-height: 1.3;
  min-width: 250px;

  &:hover {
    color: ${(props) =>
      props.link ? props.theme.primaryColor : props.theme.colors.C1100};
    cursor: ${(props) => (props.link ? 'pointer' : '')};
  }
`;

const Elements = styled(Span)<ElementsProps>`
  margin: 0px;
  padding: 0px;
  color: ${(props) =>
    props.isActive && props.link
      ? props.theme.primaryColor
      : props.theme.colors.C1100};
  line-height: 0.5;
  font-weight: ${(props) => (props.isActive && props.link ? `500` : `400`)};
  font-size: 14px;
  &:hover {
    color: ${(props) =>
      props.link ? props.theme.primaryColor : props.theme.colors.C1100};
    cursor: ${(props) => (props.link ? 'pointer' : '')};
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;

interface ContentProps extends TableProps {
  linkMapper: LinkMapper;
}
export const Content = (props: ContentProps) => {
  const { linkMapper, Node, level, activeList } = props;
  const { Title, Text, SuggestedLink } = Node || {};

  const markdownText = Title || Text || '';
  const markdownCode = parseTextFromMarkDown(markdownText);

  return (
    <LinkStyled to={linkMapper(SuggestedLink) || ''}>
      <ElementsContainer level={level} link={SuggestedLink}>
        <Elements
          link={SuggestedLink}
          isActive={activeList[0]?.href === linkMapper(SuggestedLink)}
          level={level}
        >
          {markdownCode.heading}
        </Elements>
      </ElementsContainer>
    </LinkStyled>
  );
};

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const DownloadIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '11.643'}
    height={props.height || '12.587'}
    viewBox={props.viewBox || '0 0 11.643 12.587'}
    {...props}
  >
    <g transform="translate(0.6 0.608)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2px"
        d="M13.443,15v2.492a1.205,1.205,0,0,1-1.16,1.246H4.16A1.205,1.205,0,0,1,3,17.492V15"
        transform="translate(-3 -7.359)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2px"
        d="M7,10l2.9,3.115L12.8,10"
        transform="translate(-4.679 -5.474)"
      />
      <line
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2px"
        x1="0.085"
        y1="6.646"
        transform="translate(5.118)"
      />
    </g>
  </SVGIcon>
);

export const encodedDownloadIcon =
  /* eslint-disable max-len */
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi41MiIgaGVpZ2h0PSIxMy44NiIgdmlld0JveD0iMCAwIDEyLjUyIDEzLjg2Ij48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMi40IC0xLjA2KSI+PHBhdGggZD0iTTE0LjMyLDE1djIuNTE2YTEuMjU4LDEuMjU4LDAsMCwxLTEuMjU4LDEuMjU4aC04LjhBMS4yNTgsMS4yNTgsMCwwLDEsMywxNy41MTZWMTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTQuNDUzKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjgyZDQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS4yIi8+PHBhdGggZD0iTTcsMTBsMy4xNDUsMy4xNDVMMTMuMjg5LDEwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMS40ODQgLTIuNTk4KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjgyZDQ0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS4yIi8+PGxpbmUgeTE9IjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDguNjYgMS42NikiIHN0cm9rZS13aWR0aD0iMS4yIiBzdHJva2U9IiMyODJkNDQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZmlsbD0ibm9uZSIvPjwvZz48L3N2Zz4=';

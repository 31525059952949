import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const CheckIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '10'}
    height={props.height || '10'}
    viewBox="0 0 24 24"
    fill={props.fill || 'none'}
    stroke={props.stroke || 'currentColor'}
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-check-circle-2"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <path d="m9 11 3 3L22 4" />
  </SVGIcon>
);

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const CrossIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <g transform="translate(6.93 -4.384) rotate(45)">
      <line
        stroke={props.stroke || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        y2="16"
        transform="translate(8 0)"
      />
      <line
        stroke={props.stroke || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.8"
        x2="16"
        transform="translate(0 8)"
      />
    </g>
  </SVGIcon>
);

import { useEffect, useRef, useContext, Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { SubmitIcon } from '../../Icons/SubmitIcon';
import { StopIcon } from '../../Icons/StopIcon';
import { MagicWandIcon } from '../../Icons/MagicWandIcon';
import { ArrowCircleLeftIcon } from '../../Icons/ArrowCircleLeftIcon';
import {
  SuggestedPrompt,
  IconWrapper,
  SuggestedPromptText,
} from '../welcome-message/WelcomeMessage';
import { ChatMessage, Tooltip } from '@dx-portal/design-system';
import { AppContext } from '../ApiCopilot';

interface SearchInputProps {
  isMaximised: boolean;
  isInputDisabled: boolean;
  isResetPromptsButtonHidden: boolean;
}

interface StopGeneratingComponentProps {
  isActive: boolean;
}

interface SearchWrapperProps {
  isMaximised: boolean;
}

const SearchWrapper = styled.div<SearchWrapperProps>`
  width: 100%;
  display: flex;
  align-items: end;
  margin-top: 8px;

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          justify-content: center;

          @media screen and (min-width: 680px) {
            padding-right: 16px;
          }
        `
      : css`
          padding: 0 36px 0 16px;
        `}
`;

const Container = styled.div`
  display: flex;
  align-items: end;
  width: 700px;
  gap: 4px;
  overflow: hidden;

  @media screen and (min-width: 1280px) {
    width: 746px;
  }
  @media screen and (max-width: 680px) {
    width: calc(60vw + 18px + 28px);
  }
  width: calc(550px + 18px + 28px);
`;

interface ResetPromptsButtonProps {
  isDisabled: boolean;
  isHidden: boolean;
  isMaximized: boolean;
}

const ResetPromptsButton = styled.div<ResetPromptsButtonProps>`
  opacity: ${(props) => (props.isDisabled ? '0.7' : '1')};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
`;

const SearchActionWrapper = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  border: 1px solid rgb(231, 233, 242);
  border-radius: 10px;
  padding-right: 4px;

  &:focus {
    border: 1px solid rgba(150, 150, 150);
  }
`;

const SearchInput = styled.textarea<SearchInputProps>`
  min-height: 38px;
  resize: none;
  max-height: 70px;
  width: 100%;
  line-height: 22px;
  box-sizing: border-box;
  padding: 7px 10px;
  font-family: inherit;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.C800};
  -webkit-transition: 0.25s;
  transition: 0.25s;
  outline: none;
  cursor: ${(props) => (props.isInputDisabled ? 'not-allowed' : 'text')};

  border: none;
  border-radius: 10px;

  &::placeholder {
    font-size: 11.85px;
    font-weight: 400;
    line-height: 22px;
  }

  &:disabled {
    background-color: transparent;
  }
`;

interface SubmitButtonProps {
  isInputDisabled: boolean;
  isActive: boolean;
}

const SubmitButton = styled.button<SubmitButtonProps>`
  height: 38px;
  border: none;
  background-color: transparent;
  padding: 0 5px;
  cursor: ${(props) => (props.isInputDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.isInputDisabled ? '0.4' : '1')};
  display: ${(props) => (!props.isActive ? '' : 'none')};
`;

const StopGeneratingComponent = styled.button<StopGeneratingComponentProps>`
  height: 38px;
  border: none;
  padding: 0 5px;
  background-color: transparent;

  display: ${(props) => (props.isActive ? '' : 'none')};
  cursor: pointer;
`;

interface PromptsOverlayProps {
  showOverlay: boolean;
  isMaximised: boolean;
}

const PromptsOverlay = styled.div<PromptsOverlayProps>`
  display: ${(props) => (props.showOverlay ? 'flex' : 'none')};
  align-content: start;
  flex-wrap: wrap;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  position: absolute;
  bottom: 80px;
  height: fit-content;
  padding: 10px;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: #e7e9f2 1px solid;

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          @media screen and (min-width: 1280px) {
            width: 747px;
          }
          @media screen and (max-width: 680px) {
            width: calc(60vw + 18px + 28px);
            left: 50%;
          }
          width: calc(550px + 18px + 28px);
          left: calc(50% - 8px);
        `
      : css`
          width: calc(550px - 32px - 20px);
          left: calc(50% - 10px);
        `}
`;

export interface SearchProps {
  onSubmit: (prompt: ChatMessage) => void;
  isMaximised: boolean;
  isResetPromptsButtonDisabled: boolean;
  isInputDisabled: boolean;
  suggestedPrompts: string[];
  onStopGenerating: () => void;
  isResetPromptsButtonHidden: boolean;
  prompt: string;
  setPrompt: Dispatch<SetStateAction<string>>;
  showOverlay: boolean;
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
}

export function Search({
  isInputDisabled,
  isMaximised,
  isResetPromptsButtonDisabled,
  isResetPromptsButtonHidden,
  onStopGenerating,
  onSubmit,
  suggestedPrompts,
  prompt,
  setPrompt,
  showOverlay,
  setShowOverlay,
}: SearchProps) {
  const { logEvent } = useContext(AppContext);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${Math.min(
        textAreaRef.current.scrollHeight,
        130
      )}px`;
    }
  };

  useEffect(resizeTextArea, [prompt]);

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!validateInput(prompt)) {
      setPrompt('');
      return;
    }
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      onSubmitButtonClick();
    }
  };

  const onSubmitButtonClick = () => {
    const message: ChatMessage = { role: 'user', content: prompt };
    setPrompt('');
    onSubmit(message);
  };

  return (
    <SearchWrapper
      isMaximised={isMaximised}
      id={'api-copilot-chat-bar'}
      data-testid="api-copilot-chat-bar"
    >
      <Container>
        <Tooltip
          text="Example Prompts"
          offset={[0, 8]}
          placement="top-start"
          children={
            <ResetPromptsButton
              isMaximized={isMaximised}
              isDisabled={isResetPromptsButtonDisabled || isInputDisabled}
              isHidden={isResetPromptsButtonHidden}
              onClick={() =>
                isResetPromptsButtonDisabled || isInputDisabled
                  ? null
                  : setShowOverlay((prev) => {
                      logEvent && logEvent('ClickedOnSuggestedPrompts');
                      return !prev;
                    })
              }
            >
              <MagicWandIcon />
            </ResetPromptsButton>
          }
        />
        <SearchActionWrapper>
          <SearchInput
            data-testid={'api-copilot-text-area'}
            isInputDisabled={isInputDisabled}
            disabled={isInputDisabled}
            ref={textAreaRef}
            placeholder="Send a message"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            isMaximised={isMaximised}
            onKeyDown={onEnterPress}
            rows={1}
            isResetPromptsButtonHidden={isResetPromptsButtonHidden}
            onFocus={() => setShowOverlay(false)}
          />
          <StopGeneratingComponent
            title="Stop Generating"
            isActive={isInputDisabled}
            onClick={onStopGenerating}
          >
            <StopIcon />
          </StopGeneratingComponent>
          <SubmitButton
            title="Send Message"
            onClick={onSubmitButtonClick}
            disabled={isInputDisabled || !validateInput(prompt)}
            isInputDisabled={isInputDisabled || !validateInput(prompt)}
            isActive={isInputDisabled}
          >
            <SubmitIcon />
          </SubmitButton>
        </SearchActionWrapper>
      </Container>
      <PromptsOverlay showOverlay={showOverlay} isMaximised={isMaximised}>
        {suggestedPrompts?.map((question: string, index) => (
          <SuggestedPrompt
            key={index}
            onClick={() => {
              onSubmit({ role: 'user', content: question });
              setShowOverlay(false);
            }}
          >
            <IconWrapper>
              <ArrowCircleLeftIcon />
            </IconWrapper>
            <SuggestedPromptText>{question}</SuggestedPromptText>
          </SuggestedPrompt>
        ))}
      </PromptsOverlay>
    </SearchWrapper>
  );
}

function validateInput(input: string) {
  // Check if the input is empty or contains only spaces/enters
  if (/^\s*$/.test(input)) {
    return false;
  }
  return true;
}

export default Search;

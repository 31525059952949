export const defaultColors = {
  primary: '#0c7ff2',
  link: '#0062FF',
  grayCool: {
    C000: '#fff',
    C100: '#F9FAFE',
    C200: '#F5F7F8', // Base color
    C300: '#E7E9F2', // Border color
    C400: '#CED2E2', // Input placeholder & hover border
    C500: '#B0B5C9',
    C600: '#858BA5', // Menu Tittle
    C700: '#606E8A', // Icons, data Types in explorer
    C800: '#3E445D', // Text
    C900: '#282D44', // Language Selector, Headings, Dark Base
    C1000: '#1D2236',
    C1100: '#858BA5',
    C001: '#FFF',
    C201: '#F5F7F8',
    C801: '#3E445D',
    C901: '#282D44',
  },
  grayWarm: {
    C000: '#fff',
    C1000: '#272727',
    C900: '#434343',
    C800: '#636363',
    C750: '#818181', // missing in warm theme
    C700: '#818181',
    C600: '#9D9D9D',
    C500: '#B8B8B8',
    C400: '#CFCFCF',
    C300: '#E1E1E1',
    C200: '#F1F1F1',
    C100: '#F9F9F9',
    C1100: '#9D9D9D',
    C001: '#F9FAFE',
    C201: '#F5F7F8',
    C801: '#3E445D',
    C901: '#282D44',
  },
};

export const defaultTypography = {
  fontFamily: {
    heading: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'`,
    body: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol'`,
    code: `Consolas, 'Liberation Mono', Menlo, Courier,
    monospace`,
  },
  fontSize: {
    xs: '12px', // h6
    sm: '13px',
    base: '14px', // h5, p
    lg: '18px', // h4
    xl: '24px', // h3
    xxl: '30px', // h2
    xxxl: '34px', // h1
  },
};

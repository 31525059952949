import { Component, createRef, RefObject } from 'react';
import styled from 'styled-components';

import { RcMenuWrapper, RcMenuExpandAnimation } from '../RcMenuWrapper';
import Menu, { MenuItem, SubMenu, MenuKeyChangeEvent } from 'rc-menu';
import { PlatformConstantDef } from '../PlatformConstants';
import { Language, PortalSettings } from '../PortalSettings';
import { DotNetIcon } from '../Icons/Languages/WindowsIcon';
import { WebIcon } from '../Icons/Languages/WebIcon';
import { AndroidIcon } from '../Icons/Languages/AndroidIcon';
import { AppleIcon } from '../Icons/Languages/AppleIcon';
import { JavaIcon } from '../Icons/Languages/JavaIcon';
import { PhpIcon } from '../Icons/Languages/PhpIcon';
import { PythonIcon } from '../Icons/Languages/PythonIcon';
import { PythonIconColored } from '../Icons/Languages/PythonIconColored';
import { RubyIcon } from '../Icons/Languages/RubyIcon';
import { JavascriptIcon } from '../Icons/Languages/JavascriptIcon';
import { AngularIcon } from '../Icons/Languages/AngularIcon';
import { GoIcon } from '../Icons/Languages/GoIcon';
import { Div, Li, Span } from 'src/CleanSlate';
import { ChevronIcon } from 'src/Icons/Ui/ChevronIcon';
import { TypescriptIcon } from 'src/Icons/Languages/TypescriptIcon';
import { ScrollableSelector } from 'src/Utilities/ScrollableSelector';
import { staticColors, languageSpecificColors } from 'src/Color';
import { BetaTagIcon } from 'src/Icons/BetaTagIcon';
import { AlphaTagIcon } from 'src/Icons/AlphaTagIcon';
import { languageSwitchEvent } from 'src/Analytics';

export const MenuWrapper = styled(RcMenuWrapper)`
  width: 100%;
  min-width: 120px;
  height: 100%;
  flex: 1;
  border-radius: 8px;
  z-index: 8;
  margin-right: 17px;

  @media screen and (max-width: 1200px) {
    margin-left: 5px;
  }

  @media screen and (max-width: 990px) {
    height: 32px;
    max-width: 90px;
    margin-right: 0px;
  }
  @media screen and (max-width: 575px) {
    min-width: 130px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-right: 0;
    border: none;
    border-radius: 0px;
    border-left: 4px solid #fff;
    margin-left: 0px;
  }
  .rc-menu-root.rc-menu-horizontal.rc-menu {
    width: 100%;
    font-weight: bold !important;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    float: none;
    display: inline-block;
    margin: 0;
    background-color: ${(props) => props.theme.colors.C901} !important;

    @media screen and (min-width: 990px) {
      display: none;
      min-width: 100px;
    }

    @media screen and (max-width: 990px) {
      background-color: ${(props) => props.theme.colors.C001} !important;
    }

    @media screen and (max-width: 575px) {
      height: 48px;
      background: transparent !important;
      color: ${(props) => props.theme.primaryColor}!important;
      border-color: transparent !important;

      &:hover {
        border-color: transparent !important;
      }
    }
  }
  .rc-menu-horizontal > .rc-menu-submenu {
    width: 100%;
    border: none;
  }
  .rc-menu-submenu-title {
    padding: 0 10px 0 5px !important;
    text-align: center;
    font-size: 13.33px;
    color: ${(props) => props.theme.colors.C201} !important;
    line-height: 40px;
    text-align: left;
    width: 100%;
    font-weight: 400;
    border-radius: 8px;

    @media screen and (max-width: 990px) {
      line-height: 30px;
      padding: 0 11px !important;
      border: 1px solid ${(props) => props.theme.colors.C300} !important;
    }

    @media screen and (max-width: 575px) {
      background: transparent !important;
      color: ${(props) => props.theme.colors.C901}!important;
      border-color: transparent !important;
      padding: 0 10px !important;
      line-height: 48px;
    }
  }

  .rc-menu-item {
    height: 40px;
    padding: 6px 8px 6px 8px !important;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.C001};

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:only-child {
      border-radius: 8px;
    }

    @media screen and (max-width: 990px) {
      color: ${(props) => props.theme.colors.C800};

      svg {
        display: none;
      }
    }
  }

  .rc-menu-submenu-selected {
    background-color: ${(props) => props.theme.colors.C901};
    font-weight: 400;

    @media screen and (max-width: 990px) {
      background-color: ${(props) => props.theme.colors.C001} !important;
    }

    @media screen and (max-width: 575px) {
      background: transparent !important;
    }
  }

  .rc-menu-submenu .rc-menu {
    background: ${(props) => props.theme.colors.C800};
    border: 1px solid ${(props) => props.theme.colors.C800};
    box-shadow: 0 0 4px ${(props) => props.theme.colors.C800};
    border-radius: 8px;

    @media screen and (max-width: 990px) {
      background-color: ${(props) => props.theme.colors.C201} !important;
      border-color: ${(props) => props.theme.colors.C001};
    }
  }
  .rc-menu-item-selected,
  .rc-menu-item-active {
    background-color: ${(props) => props.theme.colors.C901};

    @media screen and (max-width: 990px) {
      background-color: ${(props) => props.theme.colors.C001} !important;
    }
  }
  .rc-menu-item-selected {
    font-weight: 500;
  }

  .rc-menu-submenu {
    z-index: 1;
  }

  .rc-menu-submenu-active > .rc-menu-submenu-title,
  .rc-menu-submenu-selected {
    background-color: ${(props) => props.theme.colors.C901};

    &:hover {
      background-color: ${(props) => props.theme.colors.C901};
      border-color: ${(props) => props.theme.colors.C901};
    }

    @media screen and (max-width: 990px) {
      background-color: ${(props) => props.theme.colors.C001} !important;
      border-color: ${(props) => props.theme.colors.C300};

      &:hover {
        background-color: ${(props) => props.theme.colors.C100};
      }
    }

    @media screen and (max-width: 575px) {
      background: transparent !important;
    }
  }

  .rc-menu-horizontal .rc-menu-submenu-arrow {
    right: 8px;
    line-height: 38px;

    @media screen and (max-width: 990px) {
      line-height: 30px;
    }

    @media screen and (max-width: 575px) {
      line-height: 48px;
    }
  }
  .rc-menu-horizontal .rc-menu-submenu-arrow:before {
    border-top-color: ${(props) => props.theme.primaryColor};
  }
`;
MenuWrapper.displayName = 'MenuWrapper';

const SMenuItem = styled(MenuItem)`
  cursor: pointer;
`;

const Title = styled(Div)`
  height: 38px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  span {
    font-size: 13.33px;
    line-height: 24px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.C201} !important;
  }

  @media screen and (max-width: 990px) {
    height: 30px;

    span {
      color: ${(props) => props.theme.colors.C800} !important;
    }

    svg {
      stroke: ${(props) => props.theme.colors.C800} !important;
    }
  }

  @media screen and (max-width: 575px) {
    height: 48px;
  }
`;
Title.displayName = 'Title';

const TitleInner = styled(Div)`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  @media screen and (max-width: 990px) {
    svg {
      display: none;
    }
  }
`;
TitleInner.displayName = 'TitleInner';

const LanguageContainer = styled(Li)`
  list-style: none;
  position: relative;
  margin: 1px 0px 0px 0px;
`;

const LanguageCard = styled(Div)<{
  languageColor?: string;
  isSelected?: boolean;
}>`
  width: 68px;
  height: 60px;
  border-bottom: ${(props) =>
    props.languageColor ? `3px solid ${props.languageColor}` : 'none'};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.staticColors.Unique.C800 : 'transparent'};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-bottom: ${(props) =>
      `3px solid ${
        props.languageColor
          ? props.languageColor
          : props.theme.staticColors.Goose.C200
      }`};
  }
`;

const LanguageIcon = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 19px;
  cursor: pointer;
  position: absolute;
  top: 10px;
`;

const LanguageTitle = styled(Div)<{ languageColor?: string }>`
  text-align: center;
  font-family: ${({ theme: { cssStyles } }) => cssStyles.body.fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: ${({ theme: { cssStyles } }) => cssStyles.body.text3.fontWeight};
  line-height: normal;
  position: absolute;
  top: 36px;
  cursor: pointer;
  color: ${(props) =>
    props.languageColor
      ? props.languageColor
      : props.theme.staticColors.Goose.C200} !important;
`;

const LanguageTag = styled(Span)<{ isSelected: boolean }>`
  position: absolute;
  top: -12px;
  right: 18px;
  display: block;
  svg {
    path:first-child {
      fill: ${({ isSelected }) => (isSelected ? '#FF4451' : '#6b7e8f')};
    }
    rect {
      fill: ${({ isSelected }) => (isSelected ? '#FF4451' : '#6b7e8f')};
    }
  }
`;

export interface PlatformSelectorProps {
  onSelect: (key: Language) => void;
  platforms: PlatformConstantDef[];
  selected: number;
  disabled?: boolean;
  portalSettings: PortalSettings;
}

export interface PlatformSelectorState {
  openKeys?: string[];
}

/**
 * A language picker component
 */
export class PlatformSelector extends Component<
  PlatformSelectorProps,
  PlatformSelectorState
> {
  containerRef: RefObject<HTMLDivElement>;
  menuWrapperRef: RefObject<HTMLDivElement>;

  state: PlatformSelectorState = {};

  constructor(props: PlatformSelectorProps) {
    super(props);
    this.containerRef = createRef();
    this.menuWrapperRef = createRef();
  }

  onSelect = (ev: MenuKeyChangeEvent) => {
    this.props.onSelect(ev.key as Language);
    this.setState({ openKeys: [] });
  };

  onOpenChange = (keys: string[]) => {
    this.setState({ openKeys: keys });
  };

  SubMenuTitle = () => {
    return (
      <Title>
        <TitleInner>
          {getLanguageIcon(this.props.platforms[this.props.selected].iconKey)}
          <span>{this.props.platforms[this.props.selected].title}</span>
        </TitleInner>
        <ChevronIcon
          viewBox="0 0 24 24"
          width="12px"
          fill="none"
          stroke="#fff"
        />
      </Title>
    );
  };

  handleOnCardClick = (svalue: Language, index: number) => {
    const divNode = this.menuWrapperRef.current;
    const selectedUlNode = divNode?.children[0]?.children[1]?.children[index];

    if (selectedUlNode) {
      selectedUlNode.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }

    this.props.onSelect(svalue);
  };

  render() {
    const platforms = this.props.platforms;
    const selected = this.props.selected;

    return (
      <MenuWrapper
        className="platform-selector-wrapper"
        ref={this.menuWrapperRef}
      >
        <ScrollableSelector
          parentRef={this.menuWrapperRef}
          isLanguageSelector={true}
        >
          {platforms.map((language, index) => {
            const isSelected = (selected === index) as boolean;
            const languageColor = isSelected
              ? getLanguageColor(language.iconKey)
              : '';

            const languageReleaseTags = getLanguageSelectorTag(language);

            return (
              <LanguageContainer
                onClick={() =>
                  this.handleOnCardClick(language.templates[0].svalue, index)
                }
                key={`language-card-${index}`}
              >
                <LanguageCard
                  languageColor={languageColor}
                  isSelected={isSelected}
                  onClick={() =>
                    languageSwitchEvent(
                      this.props.portalSettings,
                      platforms[selected].title,
                      platforms[index].title
                    )
                  }
                >
                  <LanguageIcon>
                    {getLanguageIcon(language.iconKey, isSelected)}
                  </LanguageIcon>
                  <LanguageTitle languageColor={languageColor}>
                    {language.title}
                  </LanguageTitle>
                  {languageReleaseTags && (
                    <LanguageTag
                      className={languageReleaseTags ? 'hasLanguageTag' : ''}
                      isSelected={isSelected}
                    >
                      {languageReleaseTags}
                    </LanguageTag>
                  )}
                </LanguageCard>
              </LanguageContainer>
            );
          })}
        </ScrollableSelector>
        <span ref={this.containerRef} />
        <Menu
          mode="horizontal"
          triggerSubMenuAction="click"
          openAnimation={RcMenuExpandAnimation}
          // TODO Do not use lambda functions in render()
          getPopupContainer={() =>
            this.containerRef.current
              ? this.containerRef.current
              : document.body
          }
          onSelect={this.onSelect}
          onOpenChange={this.onOpenChange}
          openKeys={this.state.openKeys}
          selectable={!this.props.disabled}
          selectedKeys={[platforms[selected].templates[0].svalue]}
        >
          <SubMenu title={this.SubMenuTitle()}>
            {platforms.map((t, i) => (
              <SMenuItem key={t.templates[0].svalue}>
                {getLanguageIcon(t.iconKey)} &nbsp;
                {t.title}
              </SMenuItem>
            ))}
          </SubMenu>
        </Menu>
      </MenuWrapper>
    );
  }
}

const getLanguageSelectorTag = (
  selectedlanguage: PlatformConstantDef
): JSX.Element | null => {
  if (selectedlanguage?.stabilityLevelTag === 'alpha') {
    return <AlphaTagIcon />;
  } else if (selectedlanguage?.stabilityLevelTag === 'beta') {
    return <BetaTagIcon />;
  }
  return null;
};

/**
 * Get icon for language key
 * @param langIconKey Icon key field from the PlatformConstants file
 */

export function getLanguageIcon(
  langIconKey: string,
  isActive?: boolean
): JSX.Element | null {
  const languageSvgIcon = {
    width: '33px',
    height: '33px',
    viewBox: '0 0 33 33',
  };
  const languageColor = isActive
    ? getLanguageColor(langIconKey)
    : staticColors.Goose.C200;
  switch (langIconKey) {
    case 'globe':
      return <WebIcon fill={languageColor} />;
    case 'windows':
      return <DotNetIcon fill={languageColor} />;
    case 'android': //remove
      return <AndroidIcon style={languageSvgIcon} fill={languageColor} />;
    case 'apple': //remove
      return <AppleIcon style={languageSvgIcon} fill={languageColor} />;
    case 'java':
      return <JavaIcon fill={languageColor} />;
    case 'php':
      return <PhpIcon fill={languageColor} />;
    case 'python':
      return isActive ? (
        <PythonIconColored />
      ) : (
        <PythonIcon fill={languageColor} />
      );
    case 'ruby':
      return <RubyIcon fill={languageColor} />;
    case 'angular': //remove
      return <AngularIcon style={languageSvgIcon} fill={languageColor} />;
    case 'js': //remove
      return <JavascriptIcon style={languageSvgIcon} fill={languageColor} />;
    case 'go':
      return <GoIcon fill={languageColor} />;
    case 'typescript':
      return <TypescriptIcon fill={languageColor} />;
    default:
      return null;
  }
}

function getLanguageColor(langIconKey: string): string {
  const { http, dotNet, java, php, python, ruby, go, typescript } =
    languageSpecificColors;
  switch (langIconKey) {
    case 'globe':
      return http;
    case 'windows':
      return dotNet;
    case 'java':
      return java;
    case 'php':
      return php;
    case 'python':
      return python;
    case 'ruby':
      return ruby;
    case 'go':
      return go;
    case 'typescript':
      return typescript;
    default:
      return staticColors.Goose.C200;
  }
}

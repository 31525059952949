import * as React from 'react';

import { Div } from './CleanSlate';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';
/**
 * In order to add types in the react-select's style functions
 * we have to import the StylesConfig type def in the current component
 * which helps to type the functions properly. The version of @types/react-select in which
 * StylesConfig as a generic was implemented from v3.1.x onwards so in order to
 * make the type def nearly compatible with react-select@2.4.4 which is itself very
 * older version we have chosen v3.1.2 version of @types/react-select. And we are using v3.1.2
 * which seems to have large number of downloads upon other v3.1.x versions.
 * Check this link: https://www.npmjs.com/package/@types/react-select/v/3.1.1?activeTab=versions
 */
import Select, { StylesConfig } from 'react-select';
import { Examples, Example } from './DxDom';


interface SelectOptionProps {
  label: string;
  value: Example;
}

export interface SelectedRequestValue {
  value: SelectOptionProps | null; 
}

type onValueChangeHandler = (
  value: SelectOptionProps | null
) => void;


export interface RequestExampleDropdownProps {
  examples?: Examples;
  onChange: onValueChangeHandler;
  onFocus: () => void;
  selectedValue: SelectedRequestValue['value'];
}

type ExampleSelectProps = Omit<RequestExampleDropdownProps, 'examples'> & {
  options: SelectOptionProps[];
};

type isMulti = false;

export const ExamplesWrapper = styled(Div)`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px 16px 24px;
  background-color: ${(props) => props.theme.colors.C900};
`;
ExamplesWrapper.displayName = 'ExampleWrapper';

export const ExamplesLabel = styled(Div)`
  font-size: ${(props) => props.theme.cssStyles.body.text3.fontSize};
  margin-right: 8px;
  color: ${(props) => props.theme.colors.C1100};
`;
ExamplesLabel.displayName = 'ExampleLabel';

/**
 * This function is being used to customize
 * the react-select styles according to the
 * dx-portal.
 */
const exampleSelectCustomStyle = (
  theme: DefaultTheme
): StylesConfig<SelectOptionProps, isMulti> => ({
  /**
   * TODO: Need to handle the types of base and state
   * for all of the below functions.
   */
  control: (base, state) => ({
    ...base,
    fontSize: theme.cssStyles.body.text2.fontSize,
    boxShadow: 'none',
    border: `1px solid ${
      state.isFocused ? theme.colors.C001 : theme.colors.C1100
    }`,
    '&:hover': {
      border: `1px solid ${theme.colors.C001}`,
    },
    borderRadius: `4px`,
    minHeight: '32px',
    height: '32px',

    backgroundColor: theme.colors.C900,
  }),
  placeholder: (base) => ({
    ...base,
    color: theme.colors.C001,
  }),
  menu: (base, state) => ({
    ...base,
    width: '100%',
    boxShadow: `0px 3px 6px ${theme.staticColors.Unique.C000}`,
    border: `1px solid #E5E5E5`, // the color will be replaced with a theme variable
    borderRadius: '4px',
    marginTop: '5px',
  }),
  menuList: (base) => ({
    ...base,
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },

    '&::-webkit-scrollbar-track': {
      background: theme.colors.C001,
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.C300,
    },

    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: theme.colors.C400,
      },
    },
  }),
  option: (base, state) => ({
    ...base,
    fontSize: theme.cssStyles.body.text3.fontSize,
    fontWeight: 500,
    color: state.isSelected ? theme.colors.C001 : theme.staticColors.Goose.C000,
    backgroundColor: state.isSelected ? theme.colors.C801 : 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected ? theme.colors.C801 : theme.colors.C201,
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    padding: '0 8px',
    color: theme.colors.C001,
    '&:hover': {
      color: theme.colors.C001,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  container: (base) => ({
    ...base,
    width: '100%',
    marginTop: '4px',
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.colors.C001,
  }),
});

/**
 * A component to render the dropdown in API playground section.
 * This component is built on the top of react-select.
 * For details, you can look into the documentation:
 * https://www.npmjs.com/package/react-select/v/2.4.4
 */
function ExampleSelect({
  options,
  onChange,
  onFocus,
  selectedValue,
}: ExampleSelectProps) {
  const theme = React.useContext(ThemeContext);

  return (
    <Select
      id="examples-container"
      options={options}
      isMulti={false}
      isSearchable={false}
      openMenuOnFocus
      styles={exampleSelectCustomStyle(theme)}
      placeholder="Examples"
      defaultValue={options[0]}
      onChange={onChange}
      onFocus={onFocus}
      value={selectedValue || options[0]}
    />
  );
}

function createOptionsArrayFromExamplesObject(examples?: Examples) {
  if (!examples) return [];

  const exampleOptions = Object.keys(examples).map((example) => {
    return {
      label: examples[example].Name || example,
      value: examples[example],
    };
  });

  return exampleOptions;
}

export default function RequestExampleDropdown({
  examples,
  onChange,
  onFocus,
  selectedValue,
}: RequestExampleDropdownProps) {
  const options = createOptionsArrayFromExamplesObject(examples);

  return (
    <ExamplesWrapper>
      <ExamplesLabel>Request Examples</ExamplesLabel>
      <ExampleSelect
        options={options}
        onChange={onChange}
        onFocus={onFocus}
        selectedValue={selectedValue}
      />
    </ExamplesWrapper>
  );
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, useContext, useEffect } from 'react';
import { ResponseHeaderContextConsumer } from 'src/Context/ResponseHeaderContext';
import {
  DataModelContext,
  DataModelContextConsumer,
} from 'src/DataModelContext';
import { PortalContextConsumer } from 'src/PortalContext';
import { Authorization } from './Authorization';
import * as DxDom from '../DxDom';
import { LiquidJS } from '@dx-portal/utils-liquid';

export interface AuthorizationLoaderBlockProps {
  endpointRef: DxDom.EndpointReference;
  doc: DxDom.Document;
}

export const AuthorizationLoaderBlock: FC<AuthorizationLoaderBlockProps> = ({
  doc,
  endpointRef,
}) => {
  const dataModelContext = useContext(DataModelContext);

  useEffect(() => {
    if (dataModelContext) {
      const { reInitializeLiquidInstance } = dataModelContext;
      const {
        UsageExample: { Globals },
      } = endpointRef;

      const { PartialTemplates } = doc;

      const liquidJsInstance = new LiquidJS(PartialTemplates, Globals);

      reInitializeLiquidInstance(liquidJsInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PortalContextConsumer>
      {(portalSettings) =>
        portalSettings && (
          <DataModelContextConsumer>
            {(dataModelContext) => (
              <ResponseHeaderContextConsumer>
                {(responseHeaders) =>
                  dataModelContext && (
                    <Authorization
                      endpointRef={endpointRef}
                      dataModelContext={dataModelContext}
                      responseHeadersContext={responseHeaders!}
                      portalSettings={portalSettings}
                      authModelSchema={
                        endpointRef.AuthModelSchema as DxDom.JSchema
                      }
                      doc={doc}
                    />
                  )
                }
              </ResponseHeaderContextConsumer>
            )}
          </DataModelContextConsumer>
        )
      }
    </PortalContextConsumer>
  );
};

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ChevronLeftGroup = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox={props.viewBox || '0 0 16 16'}
    fill={props.fill || 'none'}
  >
    <g>
      <circle
        cx="8"
        cy="8"
        r="7.5"
        fill="#F8FAFC"
        stroke="#24313E"
        opacity="0.25"
      />
      <path
        d="M8.90945 11.273L5.63672 8.00027L8.90945 4.72754"
        stroke="#24313E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="0.55"
      />
    </g>
  </SVGIcon>
);

import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  CopyToClipboardIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  CrossIconSmall,
} from '../../icons';
import { Div, IconButton, P, textAreaMixin, PrimaryButton } from '../../theme';
import { ChatMessages, UserFeedback } from '../../types';
import { Tooltip } from '../tooltip/Tooltip';

const FeedbackFormContainer = styled(Div)<{
  hide: boolean;
  platform: 'API Copilot' | 'DX Portal';
}>`
  display: ${({ hide }) => (hide ? 'none' : '')};
  margin-top: ${({ platform }) =>
    platform === 'API Copilot' ? `12px` : 'none'};
  border-top: ${({ platform, theme }) =>
    platform === 'API Copilot'
      ? `1px solid ${theme.staticColors.Snow.C100}`
      : 'none'};
`;

const FeedbackFormActionButtonsContainer = styled(Div)<{ forPortal?: boolean }>`
  display: flex;
  padding: ${({ forPortal }) => (forPortal ? '0px 10px 0px 16px' : '8px 0')};
  border: ${({ forPortal, theme }) =>
    forPortal ? `1px solid ${theme.colors.C300}` : 'none'};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.staticColors.Red.C400};
`;

const FeedbackFormComponent = styled(Div)<{ forPortal: boolean }>`
  position: relative;
  background: ${({ theme }) => theme.staticColors.Snow.C300};
  border: 1px solid ${({ theme }) => theme.staticColors.Snow.C100};
  border-radius: 4px;
  padding: 16px;

  ${({ forPortal }) =>
    forPortal &&
    css`
      width: 100%;
      max-width: 450px;
      margin-top: 20px;
    `}
`;

const RateResponse = styled.div<{ hide: boolean; forPortal?: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  height: 20px;
  align-items: center;
  gap: 6px;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.staticColors.Goose.C200};
  margin-top: ${({ forPortal }) => (forPortal ? '12px' : 'none')};
`;

const IconButtonWrapper = styled(IconButton)`
  &:disabled {
    cursor: not-allowed;
  }
`;

const CrossIconWrapper = styled(IconButtonWrapper)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

const FeedbackFormTitle = styled(P)`
  margin: 0;
  padding-right: 20px;
  line-height: 16px;
  font-size: 14px;

  > span {
    opacity: 0.7;
  }
`;

const FeedbackFormActionButtonsContainerForPortal = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const FeedbackFormTitleForPortal = styled(P)`
  color: ${({ theme }) => theme.staticColors.Unique.C1200};
  margin: 8px 6px 0 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
`;

const FeedbackFormTextArea = styled.textarea`
  ${textAreaMixin}
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.staticColors.Snow.C100};
  margin: 8px 0px 2px 0px;
  width: 100%;
  min-height: 67px;
  resize: vertical;
  outline: none;
  min-width: unset;
  box-sizing: border-box;

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.staticColors.Blue.C200};
  }
`;

const FeedbackFormSubmitButton = styled(PrimaryButton)``;

type FeedbackButtonsComponentProps = {
  tooltipText: string;
  feedbackStatus: UserFeedback;
};

export type Feedback = {
  feedback: UserFeedback;
  completeFeedback: string;
};

export interface FeedbackFormProps {
  platform: 'API Copilot' | 'DX Portal';
  handleFeedbackSubmission: (feedback: Feedback) => void;
  messages?: ChatMessages;
  onResponseCopy?: () => void;
  backgroundColor?: string;
  iconStroke?: string;
  openFurtherFeedbackByDefault?: boolean;
}

export function FeedbackForm({
  platform,
  handleFeedbackSubmission,
  messages,
  onResponseCopy,
  backgroundColor,
  iconStroke,
  openFurtherFeedbackByDefault,
}: FeedbackFormProps) {
  const [hideFeedbackForm, setHideFeedbackForm] = useState<boolean>(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Feedback | undefined>({
    feedback: undefined,
    completeFeedback: '',
  });
  const [copyTooltipText, setCopyTooltipText] = useState<'Copy' | 'Copied'>(
    'Copy'
  );
  const [disableRatingActionButtons, setDisableRatingActionButtons] =
    useState<boolean>(false);
  const feedbackTextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (feedback?.feedback && !hideFeedbackForm) {
      feedbackTextAreaRef.current?.focus({ preventScroll: true });
      feedbackTextAreaRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [feedback, hideFeedbackForm]);

  const handleFeedbackUpdate = (feedback: UserFeedback) => {
    setFeedback((prev) => ({
      feedback: feedback,
      completeFeedback: prev?.completeFeedback || '',
    }));
  };

  const handleCompleteFeedbackUpdate: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    setFeedback((prev) => ({
      feedback: prev?.feedback,
      completeFeedback: e.target.value,
    }));
  };

  const handleFormSubmission = () => {
    if (feedback) {
      handleFeedbackSubmission(feedback);
      setFeedback({
        feedback: feedback.feedback,
        completeFeedback: '',
      });
      setHideFeedbackForm(true);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 5000);
      setDisableRatingActionButtons(true);
    }
  };

  const FormComponent = () => (
    <FeedbackFormComponent forPortal={platform === 'DX Portal'}>
      <CrossIconWrapper
        onClick={() => {
          setFeedback({
            feedback: undefined,
            completeFeedback: '',
          });
          setHideFeedbackForm(true);
        }}
      >
        <CrossIconSmall />
      </CrossIconWrapper>
      <FeedbackFormTitle>
        Would you like to provide more details? <span>(optional)</span>
      </FeedbackFormTitle>
      <FeedbackFormTextArea
        ref={feedbackTextAreaRef}
        onChange={handleCompleteFeedbackUpdate}
      />
      <FeedbackFormSubmitButton onClick={handleFormSubmission}>
        Submit
      </FeedbackFormSubmitButton>
    </FeedbackFormComponent>
  );

  const FeedbackButtonsComponent = ({
    tooltipText,
    feedbackStatus,
  }: FeedbackButtonsComponentProps) => {
    const IconButton = () => (
      <IconButtonWrapper
        disabled={disableRatingActionButtons}
        onClick={() => {
          onFeedbackButtonsClick(feedbackStatus);
        }}
      >
        {feedbackStatus === 'positive' ? (
          <ThumbsUpIcon
            isActive={feedback?.feedback === feedbackStatus}
            backgroundColor={backgroundColor}
            stroke={iconStroke}
          />
        ) : (
          <ThumbsDownIcon
            isActive={feedback?.feedback === feedbackStatus}
            backgroundColor={backgroundColor}
            stroke={iconStroke}
          />
        )}
      </IconButtonWrapper>
    );

    return disableRatingActionButtons ? (
      <IconButton />
    ) : (
      <Tooltip text={tooltipText} placement="top" children={<IconButton />} />
    );
  };

  const onFeedbackButtonsClick = (feedback: UserFeedback) => {
    handleFeedbackUpdate(feedback);
    setHideFeedbackForm(false);
    setShowSuccessMessage(false);
  };

  const showFurtherFeedback =
    openFurtherFeedbackByDefault || (feedback?.feedback && !hideFeedbackForm);

  return (
    <FeedbackFormContainer platform={platform} hide={false}>
      {platform === 'API Copilot' && (
        <>
          <FeedbackFormActionButtonsContainer>
            <Tooltip
              text={copyTooltipText}
              placement="top"
              children={
                <CopyToClipboard
                  text={messages ? messages[messages.length - 1].content : ''}
                  onCopy={onResponseCopy}
                >
                  <IconButtonWrapper
                    onClick={() => {
                      setCopyTooltipText('Copied');
                    }}
                    onMouseOut={() => {
                      setCopyTooltipText('Copy');
                    }}
                  >
                    <CopyToClipboardIcon />
                  </IconButtonWrapper>
                </CopyToClipboard>
              }
            />
            <FeedbackButtonsComponent
              tooltipText={'I found this helpful'}
              feedbackStatus={'positive'}
            />
            <FeedbackButtonsComponent
              tooltipText={"I didn't find this helpful"}
              feedbackStatus={'negative'}
            />
          </FeedbackFormActionButtonsContainer>
          {showFurtherFeedback && FormComponent()}
          <RateResponse hide={!showSuccessMessage}>
            Thank you! Your feedback has been received.
          </RateResponse>
        </>
      )}
      {platform === 'DX Portal' && (
        <FeedbackFormActionButtonsContainerForPortal>
          <FeedbackFormActionButtonsContainer forPortal={true}>
            <FeedbackFormTitleForPortal>
              Was this page helpful?
            </FeedbackFormTitleForPortal>
            <FeedbackButtonsComponent
              tooltipText={'I found this helpful'}
              feedbackStatus={'positive'}
            />
            <FeedbackButtonsComponent
              tooltipText={"I didn't find this helpful"}
              feedbackStatus={'negative'}
            />
          </FeedbackFormActionButtonsContainer>
          {showFurtherFeedback && FormComponent()}
          <RateResponse hide={!showSuccessMessage} forPortal={true}>
            Thank you! Your feedback has been received.
          </RateResponse>
        </FeedbackFormActionButtonsContainerForPortal>
      )}
    </FeedbackFormContainer>
  );
}

export default FeedbackForm;

import { staticColors } from 'src/Color';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const DotNetIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '28'}
    height={props.height || '12'}
    viewBox={props.viewBox || '0 0 28 12'}
    fill={props.fill || staticColors.Goose.C200}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66 2.26003C10.66 5.33002 11.04 10.8 13.97 10.8C14.2 10.8 14.42 10.77 14.65 10.73C11.98 10.11 11.67 4.70002 10.05 1.90002L9.66 2.26003Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.04 1.89999C11.67 4.69999 11.98 10.11 14.64 10.73C14.86 10.69 15.07 10.63 15.28 10.56C12.88 9.38999 12.22 4.13999 10.38 1.60999L10.04 1.89999Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.38 0.230049C11.4968 0.286605 10.6428 0.56895 9.89999 1.05005C10.07 1.22005 10.23 1.40005 10.38 1.61005C11 1.10005 11.61 0.710049 12.22 0.490049C12.45 0.400049 12.68 0.340049 12.91 0.290049C12.7366 0.247816 12.5585 0.227656 12.38 0.230049ZM17.14 9.48005C17.39 9.28005 17.64 9.08005 17.88 8.84005C16.86 5.79005 16.5 0.240049 13.54 0.240049C13.34 0.240049 13.12 0.26005 12.91 0.30005C15.6 0.97005 15.94 6.82005 17.14 9.48005Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.91 0.299981C12.737 0.254377 12.5589 0.230856 12.38 0.22998H13.55C13.33 0.22998 13.12 0.259981 12.91 0.299981ZM17.09 10.13C16.9734 10.0162 16.8632 9.89604 16.76 9.76998C16.26 10.12 15.76 10.39 15.28 10.57C15.5976 10.7246 15.9468 10.8034 16.3 10.8C16.85 10.8 17.29 10.73 17.66 10.55C17.46 10.45 17.26 10.3 17.09 10.13ZM12.22 0.489981C14.62 1.74998 14.84 7.44998 16.76 9.76998L17.14 9.47998C15.94 6.81998 15.6 0.969981 12.91 0.299981C12.68 0.339981 12.45 0.399981 12.22 0.489981Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.38 1.59999C12.22 4.13999 12.88 9.38999 15.28 10.56C15.77 10.39 16.26 10.12 16.76 9.76999C14.84 7.44999 14.63 1.74999 12.22 0.48999C11.61 0.71999 11 1.08999 10.38 1.60999M8.48001 2.18999C8.18001 2.83999 7.89001 3.69999 7.54001 4.82999C8.24001 3.82999 8.96001 2.95999 9.66001 2.25999C9.56001 1.95999 9.46001 1.68999 9.35001 1.43999C9.05001 1.65999 8.77001 1.90999 8.48001 2.18999Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.60001 1.26001L9.35001 1.44001C9.45001 1.69001 9.56001 1.96001 9.65001 2.26001L10.05 1.90001C9.91671 1.6754 9.76627 1.46143 9.60001 1.26001Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.91 1.05L9.61 1.25C9.76 1.45 9.91 1.67 10.04 1.9L10.38 1.6C10.23 1.4 10.08 1.22 9.91 1.05ZM26.33 0C24.97 5.25 22.13 9.47 19.74 10.54L19.61 10.59H19.59L19.55 10.61H19.54L19.48 10.64H19.45L19.42 10.66H19.4L19.37 10.68L19.32 10.69H19.3L19.26 10.71H19.24L19.2 10.72L19.16 10.73C19.26 10.77 19.38 10.79 19.5 10.79C21.76 10.79 24.04 6.74 27.81 0H26.33ZM7.04 0.45H7.05L7.09 0.43H7.1L7.11 0.42L7.15 0.41L7.24 0.37H7.25L7.29 0.36H7.31L7.34 0.34H7.36L7.48 0.31H7.5L7.54 0.3H7.55L7.59 0.28L7.67 0.27H7.74L7.78 0.26H7.8L7.88 0.24H7.64C5.09 0.24 1.58 4.96 0 11.08H0.3C0.81 10.18 1.26 9.32 1.68 8.52C2.78 4.19 5.04 1.23 7.04 0.45ZM8.48 2.19C8.77 1.91 9.06 1.66 9.35 1.44C9.28741 1.30291 9.2173 1.16937 9.14 1.04C8.86891 1.39166 8.64688 1.77853 8.48 2.19Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67999 0.479951C8.84999 0.619951 8.99999 0.819952 9.13999 1.04995L9.26999 0.899951C9.09738 0.733366 8.90212 0.591973 8.68999 0.479951M1.67999 8.51995C3.99999 4.04995 5.09999 1.21995 7.03999 0.449951C5.03999 1.22995 2.78999 4.18995 1.67999 8.51995Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.27999 0.900024L9.13999 1.04002L9.33999 1.44002L9.59999 1.26003C9.49999 1.13003 9.38999 1.01002 9.26999 0.900024M2.72999 11.06H2.64999L2.54999 11.07C4.89999 11.01 5.96999 9.83003 6.50999 8.12003C6.90999 6.82003 7.23999 5.74002 7.53999 4.82002C6.45071 6.39108 5.45885 8.02749 4.56999 9.72002C4.08999 10.6 3.35999 10.98 2.72999 11.06Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.72999 11.0601C3.12656 11.0034 3.50298 10.8496 3.82575 10.6123C4.14852 10.3751 4.40762 10.0617 4.57999 9.70005C5.57999 7.85005 6.55999 6.20005 7.54999 4.82005C7.89999 3.69005 8.19999 2.83005 8.48999 2.18005C6.52999 4.04005 4.55999 7.15005 2.73999 11.0501M1.67999 8.52005C1.25999 9.32005 0.809988 10.1801 0.299988 11.0801H1.18999C1.30999 10.1801 1.46999 9.33005 1.67999 8.52005Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.79999 0.25H7.77999L7.73999 0.26H7.71999L7.68999 0.27H7.66999L7.59999 0.29H7.57999C7.56667 0.29089 7.55331 0.29089 7.53999 0.29L7.51999 0.3H7.48999L7.46999 0.31L7.34999 0.34H7.32999L7.29999 0.36H7.27999L7.23999 0.38H7.22999L7.13999 0.41L7.09999 0.42L7.08999 0.43L7.04999 0.44L7.03999 0.45C5.08999 1.22 3.98999 4.05 1.66999 8.52C1.46999 9.33 1.29999 10.18 1.17999 11.08H2.64999L2.68999 11.07H2.73999C4.54999 7.17 6.51999 4.06 8.47999 2.19C8.69999 1.71 8.90999 1.33 9.13999 1.05C9.02215 0.839628 8.87006 0.650368 8.68999 0.49L8.65999 0.47L8.62999 0.46L8.59999 0.44H8.56999L8.54999 0.42L8.49999 0.4H8.46999C8.45983 0.393581 8.44983 0.386913 8.43999 0.38L8.40999 0.37L8.35999 0.35L8.29999 0.33H8.27999L8.22999 0.31H8.21999C8.17704 0.298432 8.13367 0.288425 8.08999 0.28H8.06999L8.01999 0.27H8.00999L7.93999 0.26H7.79999M21.01 2.9C20.57 4.34 20.21 5.52 19.89 6.5C21.2751 4.52547 22.4736 2.42644 23.47 0.23C22.13 0.66 21.42 1.62 21.01 2.91M19.93 10.45L19.74 10.54C22.13 9.47 24.97 5.25 26.34 0H26.06C22.93 5.6 21.92 9.33 19.93 10.45ZM18.37 9.98C18.89 9.34 19.31 8.27 19.89 6.49C19.291 7.32538 18.6219 8.10821 17.89 8.83L17.88 8.84C18.03 9.28 18.18 9.67 18.37 9.98ZM18.37 9.98C18.1772 10.219 17.935 10.4134 17.66 10.55C17.922 10.692 18.2124 10.774 18.51 10.79H18.92L18.96 10.77H19.04L19.16 10.74C18.85 10.63 18.59 10.36 18.36 9.98M13.98 10.8C14.4245 10.8016 14.8654 10.7202 15.28 10.56C15.5969 10.7178 15.946 10.7999 16.3 10.8H13.98Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3 10.8C16.85 10.8 17.29 10.73 17.66 10.55C17.922 10.692 18.2124 10.774 18.51 10.79H18.64H16.3V10.8ZM18.64 10.8H18.79L18.83 10.79H18.93L18.98 10.77H19.04L19.16 10.74C19.26 10.78 19.38 10.8 19.5 10.8H18.64ZM17.88 8.84C17.5282 9.175 17.154 9.48571 16.76 9.77C16.86 9.9 16.98 10.02 17.09 10.13C17.27 10.3 17.46 10.44 17.66 10.55L17.8 10.48C18 10.36 18.2 10.19 18.37 9.98C18.19 9.66 18.03 9.28 17.88 8.84ZM25.18 0H23.8L23.57 0.0100002L23.47 0.23C22.473 2.42299 21.2745 4.51865 19.89 6.49C19.31 8.26 18.89 9.34 18.37 9.97C18.59 10.35 18.85 10.62 19.16 10.73H19.2L19.24 10.71H19.26L19.3 10.7H19.32L19.37 10.68L19.4 10.67L19.42 10.66L19.45 10.65L19.48 10.64L19.54 10.62H19.55L19.59 10.6H19.61L19.73 10.54H19.74L19.93 10.44C21.92 9.34 22.93 5.59 26.06 0H25.18ZM7.88 0.25H7.93001L8.01 0.26L8.07001 0.28H8.09L8.22001 0.31L8.28001 0.33H8.29001C8.31359 0.33575 8.33694 0.342423 8.36001 0.35L8.41001 0.37H8.43001L8.47001 0.39L8.49001 0.4L8.54001 0.42L8.57001 0.43L8.60001 0.45H8.63L8.66001 0.47C8.88001 0.58 9.08001 0.73 9.27001 0.9C9.33827 0.827967 9.41181 0.761116 9.49001 0.7C9.02461 0.361901 8.45326 0.202207 7.88 0.25Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28001 0.899981C9.38002 0.999981 9.50001 1.12998 9.60001 1.25998C9.70001 1.17998 9.80001 1.11998 9.90001 1.05998C9.77385 0.928528 9.63668 0.808089 9.49002 0.699981C9.41001 0.759981 9.34001 0.819981 9.27001 0.899981M7.88001 0.249981L8.07001 0.239981C8.61001 0.239981 9.07002 0.409981 9.49002 0.699981C9.93002 0.359981 10.45 0.22998 11.19 0.22998H7.64001L7.88001 0.249981Z"
      fill={props.fill || staticColors.Goose.C200}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.18 0.230009C10.45 0.230009 9.93001 0.360009 9.48001 0.700009C9.63001 0.800009 9.78001 0.92001 9.91001 1.05001C10.51 0.67001 11.11 0.41001 11.71 0.30001C11.94 0.26001 12.16 0.230009 12.38 0.230009H11.18ZM19.88 6.50001C20.2 5.52001 20.56 4.34001 21.01 2.90001C21.41 1.62001 22.13 0.650009 23.47 0.230009L23.57 0.0100098C21.39 0.15001 20.37 1.26001 19.85 2.91001C18.95 5.78001 18.41 7.65001 17.88 8.83001C18.6141 8.11027 19.2834 7.32724 19.88 6.49001"
      fill={props.fill || staticColors.Goose.C200}
    />
  </SVGIcon>
);

import styled from 'styled-components';

import { StyledPre } from 'src/StyledElements';
import { CopyToClipboardComp } from 'src/uiComponents/CopyToClipboard';
import { CodeHighlighter } from 'src/CodeHighlighter';
import { Div } from './CleanSlate';

interface ComponentProps {
  text: string;
  lang?: string;
  code: string;
  from?: string;
}

/**
 * Fixing #44.
 * Managing copy button toggle state on hover.
 * Scope related to component that's why it is here.
 * @link https://github.com/apimatic/apimatic-dx-portal/issues/44#issuecomment-888846473
 */
const CodeWithCopyWrapper = styled(Div)`
  position: relative;

  .copy-to-clip-board {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  :hover {
    .copy-to-clip-board {
      opacity: 1;
    }
  }
`;

export function CodeWithCopy(props: ComponentProps) {
  const { text, lang, code = '', from } = props;

  return (
    <CodeWithCopyWrapper>
      <CopyToClipboardComp text={text} iconButtonType="light" from={from} />
      <StyledPre
        as="div"
        className="code-with-copy"
        scrollWidth="4px"
        scrollHeight="4px"
      >
        <CodeHighlighter lang={lang} code={code.trimEnd()} />
      </StyledPre>
    </CodeWithCopyWrapper>
  );
}

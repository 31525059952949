import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Div } from '../CleanSlate';

const placeholderShimmer = keyframes`

    0% {
      background-position: -500px 0;
    }

    100% {
      background-position: 500px 0;
    }
`;
export const sharedStyles = css`
  margin-top: 25px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.staticColors.Snow.C000};

  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.staticColors.Snow.C100} 0%,
    ${(props) => props.theme.staticColors.Snow.C200} 20%,
    ${(props) => props.theme.staticColors.Snow.C300} 40%,
    ${(props) => props.theme.staticColors.Snow.C400} 100%
  );
  background-repeat: no-repeat;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: ${placeholderShimmer};
  -webkit-animation-timing-function: linear;
`;

export const SidebarWrapper = styled(Div)<{
  hasHeight?: boolean;
  forTOC?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: ${({ forTOC }) => (forTOC ? '0' : '20px')};
  height: ${({ hasHeight }) => (hasHeight ? '100%' : 'inherit')};
`;
SidebarWrapper.displayName = `SidebarWrapper`;

const SidebarSection = styled(Div)`
  height: 300px;
  width: 100%;
  ${sharedStyles}
`;
SidebarSection.displayName = `SidebarSection`;

export const SidebarLink = styled(Div)<{
  forTOC?: boolean;
}>`
  width: ${({ forTOC }) => (forTOC ? '145px' : '100%')};
  height: 20px;
  ${sharedStyles}
`;
SidebarLink.displayName = `SidebarLink`;

export const SidebarHeading = styled(Div)`
  width: 100%;
  height: 40px;
  ${sharedStyles}
`;
SidebarHeading.displayName = `SidebarHeading`;

const SidebarSkeleton = () => {
  return (
    <SidebarWrapper hasHeight={true}>
      <SidebarLink />
      <SidebarHeading />
      <SidebarSection />
    </SidebarWrapper>
  );
};

export default SidebarSkeleton;

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const OpenedLockIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '30'}
    height={props.height || '30'}
    viewBox={props.viewBox || '0 0 28 30'}
    fill={props.fill || 'none'}
  >
    <circle
      cx="14"
      cy="14"
      r="13.35"
      fill={props.fill || 'none'}
      stroke={props.stroke || '#0C7FF2'}
      strokeWidth="1.5"
    />
    <path
      d="M18.6667 13.3335H9.33333C8.59695 13.3335 8 13.9304 8 14.6668V19.3335C8 20.0699 8.59695 20.6668 9.33333 20.6668H18.6667C19.403 20.6668 20 20.0699 20 19.3335V14.6668C20 13.9304 19.403 13.3335 18.6667 13.3335Z"
      stroke={props.stroke || '#0C7FF2'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 13.3334V10.6668C10.6659 9.84013 10.9723 9.04268 11.5263 8.42921C12.0804 7.81575 12.8426 7.43004 13.6651 7.34696C14.4875 7.26389 15.3115 7.48937 15.9771 7.97964C16.6426 8.46992 17.1023 9.19 17.2667 10.0001"
      stroke={props.stroke || '#0C7FF2'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);

import styled from 'styled-components';

import { Div } from 'src/CleanSlate';
import { PropsWithChildren } from 'react';

interface ComponentProps {
  movingBarWidth?: string;
  movingBarRotation?: string;
  movingBarOpacity?: number;
}

const LoadingWrapperStyled = styled(Div)<ComponentProps>`
  display: flex;
  position: relative;
  padding: 40px;
  width: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.C300};

  @media screen and (max-width: 990px) {
    width: 100%;
    padding: 40px;
  }

  @keyframes loadingAnimation {
    from {
      left: -50%;
    }
    to {
      left: 150%;
    }
  }

  ::before {
    position: absolute;
    content: '';
    left: -50%;
    top: -25%;
    width: ${(props) => props.movingBarWidth};
    background: white;
    height: 150%;
    transform: rotate(${(props) => props.movingBarRotation});
    animation: loadingAnimation 1s infinite;
    opacity: ${(props) => props.movingBarOpacity};
  }
`;
export function LoadingScreen({
  children,
  movingBarWidth = '5%',
  movingBarRotation = '35deg',
  movingBarOpacity = 0.6,
}: PropsWithChildren<ComponentProps>) {
  return (
    <LoadingWrapperStyled
      movingBarRotation={movingBarRotation}
      movingBarWidth={movingBarWidth}
      movingBarOpacity={movingBarOpacity}
    >
      {children}
    </LoadingWrapperStyled>
  );
}

import * as React from 'react';
import styled from 'styled-components';

import { downloadSdk } from '../ApiClient';
import { CancellablePromise, makeCancellable } from '../CancellablePromise';
import {
  DefaultButtonMixin,
  GetSdkButtonStyleMixin,
  PrimaryButton,
} from '../StyledElements';
import { PortalSettings } from '../PortalSettings';
import { Spinner } from '../Spinner';
import { DownloadIcon2 } from 'src/Icons';
import { GET_SDK_BUTTON_ID } from './GetSdkPopup';

export const DownloadSdkButtonStyle = styled(PrimaryButton)<{
  typeSecondary?: true;
  isInlineNavbar: boolean;
  enableExport: boolean;
  fromGetSdkPopup?: boolean;
}>`
  ${({ typeSecondary }) =>
    typeSecondary ? DefaultButtonMixin : GetSdkButtonStyleMixin};
  gap: 6px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.fromGetSdkPopup
      ? `
      justify-content: center;
				min-width: 161px;
			`
      : ''};

  @media screen and (max-width: 990px) {
    ${(props) =>
      props.isInlineNavbar && !props.enableExport
        ? `
				min-width: 100px;
        margin-right: 0px;
			`
        : ''};
  }
`;

DownloadSdkButtonStyle.displayName = 'DownloadSdkButtonStyle';

export interface DownloadSdkButtonCompProps {
  template: string;
  portalSettings: PortalSettings;
  name: string;
  isInlineNavbar: boolean;
  typeSecondary?: true;
  from?: string;
}

interface DownloadSdkButtonCompState {
  downloading: boolean;
}

export class DownloadSdkButtonComp extends React.Component<
  DownloadSdkButtonCompProps,
  DownloadSdkButtonCompState
> {
  state = {
    downloading: false,
  };

  lastSdkDownloadPromise?: CancellablePromise<Response>;

  onClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    if (this.state.downloading) {
      return;
    }
    if (this.lastSdkDownloadPromise) {
      this.lastSdkDownloadPromise.cancel();
    }
    this.lastSdkDownloadPromise = makeCancellable(
      downloadSdk(this.props.template, this.props.portalSettings)
    );
    this.lastSdkDownloadPromise.promise
      .then(async (response) => {
        this.setState({ downloading: false });
      })
      .catch((err?: { isCancelled?: boolean }) => {
        if (err && err.isCancelled) {
          return; // don't do anything if promise was cancelled
        }

        alert(
          'SDK Not Found. If the problem persists contact your service provider.'
        );
        this.setState({ downloading: false });
      });
    this.setState({ downloading: true });
  };

  componentWillUnmount() {
    if (this.lastSdkDownloadPromise) {
      this.lastSdkDownloadPromise.cancel();
    }
  }

  render() {
    const fromGetSdkPopup = this.props.from === GET_SDK_BUTTON_ID;
    return (
      <DownloadSdkButtonStyle
        onClick={this.onClick}
        disabled={this.state.downloading}
        typeSecondary={this.props.typeSecondary}
        isInlineNavbar={this.props.isInlineNavbar}
        enableExport={this.props.portalSettings.enableExport}
        fromGetSdkPopup={fromGetSdkPopup}
      >
        {this.state.downloading ? (
          <Spinner
            color={this.props.typeSecondary ? '#000' : '#fff'}
            size="14px"
            margin="2px 2px 2px"
          />
        ) : (
          <>
            {this.props.typeSecondary && <DownloadIcon2 />}
            {this.props.name}
          </>
        )}
      </DownloadSdkButtonStyle>
    );
  }
}

/**
 * Each SDK doc defines a data model which is commons for all endpoints in that SDK.
 * This data model contains the config/auth part for the API. Since it is shared
 * by all endpoints, we have created a React context for it which helps pass it down to
 * all API console instances which can update it too. The Data Model is accompanied
 * by a JSON schema which describes the structure of the Data Model and helps render
 * the form/editor for it.
 *
 * NOTE: Different SDK docs for the same API have the same Data Model (and schema).
 * This is true for same endpoints across different SDKs docs for an API as well.
 */

import {
  createContext,
  FC,
  useCallback,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';

import { cloneDeep } from 'lodash';
import { DataModel, Document as Doc, Document, JSchema } from './DxDom';
import { LiquidJS } from '@dx-portal/utils-liquid';
import { removeNullorEmptyValues } from './Utilities/utility';
import { Language } from './PortalSettings';
/**
 * Data model context interface
 */

export interface DataModelContextProps {
  dataModel: DataModel;
  dataModelSchema: JSchema;
  updateDataModel: (data: DataModel) => void;
  reInitializeLiquidInstance: (liquidInstance: LiquidJS) => void;
  definitions: Document['ModelSchemas'];
  authDataModel: DataModel['auth'];
  updateAuthDataModel: (data: DataModelContextProps) => void;
  liquidInstance: LiquidJS;
  activeLanguage: Language;
  updateActiveLanguage: (lang: Language) => void;
}

export const DataModelContext = createContext<
  DataModelContextProps | undefined
>(undefined);

export interface DataModelContextProviderProps {
  doc: Doc;
  liquidInstance: LiquidJS;
}

const getClonedDataModel = (doc: Doc) => {
  const cloneDataModel = removeNullorEmptyValues(cloneDeep(doc.DataModel));
  const clonedDataModelSchema = cloneDeep(doc.DataModelSchema);
  const clonedDefinitions = cloneDeep(doc.ModelSchemas);

  return {
    cloneDataModel,
    clonedDataModelSchema,
    clonedDefinitions,
  };
};

/**
 * A stateful container for document's shared data model.
 *
 * Data model is passed to components that need it using DataModelContextConsumer.
 */
export const DataModelContextProvider: FC<
  PropsWithChildren<DataModelContextProviderProps>
> = (props) => {
  const { doc, liquidInstance } = props;

  const updateDataModel = useCallback((model: DataModel) => {
    setState((st) => (st ? { ...st, dataModel: model } : st));
  }, []);

  const updateAuthDataModel = useCallback((model: DataModelContextProps) => {
    setState((st) => {
      return st ? { ...st, ...model } : st;
    });
  }, []);

  const updateActiveLanguage = useCallback((lang: Language) => {
    setState((st) => (st ? { ...st, activeLanguage: lang } : st));
  }, []);

  const reInitializeLiquidInstance = useCallback((liquidInstance: LiquidJS) => {
    setState((st) =>
      st
        ? {
            ...st,
            liquidInstance: liquidInstance,
          }
        : st
    );
  }, []);

  const [state, setState] = useState<DataModelContextProps | undefined>(
    undefined
  );

  useEffect(() => {
    if (doc) {
      const { cloneDataModel, clonedDataModelSchema, clonedDefinitions } =
        getClonedDataModel(doc);

      setState((st) => {
        const updatedDataModel = !st
          ? cloneDataModel
          : { ...cloneDataModel, ...st.dataModel };

        const updadedDataModelSchema = !st
          ? clonedDataModelSchema
          : { ...clonedDataModelSchema, ...st.dataModelSchema };

        const updatedAuthDataModel = !st
          ? cloneDataModel.auth
          : { ...cloneDataModel.auth, ...st.authDataModel };

        return {
          dataModel: updatedDataModel,
          dataModelSchema: updadedDataModelSchema,
          updateDataModel: updateDataModel,
          reInitializeLiquidInstance: reInitializeLiquidInstance,
          definitions: clonedDefinitions,
          liquidInstance: liquidInstance,
          authDataModel: updatedAuthDataModel,
          updateAuthDataModel: updateAuthDataModel,
          activeLanguage: st?.activeLanguage || 'http_curl_v1',
          updateActiveLanguage: updateActiveLanguage,
        };
      });
    }
  }, [
    doc,
    liquidInstance,
    reInitializeLiquidInstance,
    updateActiveLanguage,
    updateAuthDataModel,
    updateDataModel,
  ]);

  return (
    <DataModelContext.Provider value={state as DataModelContextProps}>
      {props.children}
    </DataModelContext.Provider>
  );
};

export const DataModelContextConsumer = DataModelContext.Consumer;

import { useMemo } from 'react';
import styled from 'styled-components';

import { ResponseViewerProps } from './ResponseViewer';
import { ProxyResponseInterface } from './ProxyResponseInterface';
import { TryAgainButton } from 'src/base-components';
import {
  ArrowBackIcon,
  ConnectionDarkIcon,
  ConnectionLightIcon,
  Error400DarkIcon,
  Error400LightIcon,
  Error422DarkIcon,
  Error422LightIcon,
  GeneralDarkIcon,
  GeneralLightIcon,
  NotFoundDarkIcon,
  NotFoundLightIcon,
  ServerDarkIcon,
  ServerLightIcon,
  TimeOutDarkIcon,
  TimeOutLightIcon,
} from 'src/Icons';
import { Div, H4, Ul, Li, Span, P } from '../CleanSlate';
import { LinkButton, Scrollable } from 'src/StyledElements';
import { renderCssStyles } from 'src/Theme/Theme';
import { Error403LightIcon } from 'src/Icons/errors/Error403Icon';
import { Error406LightIcon } from 'src/Icons/errors/Error406Icon';
import { Error402LightIcon } from 'src/Icons/errors/Error402Icon';
import { Error502LightIcon } from 'src/Icons/errors/Error502Icon';
import { useState } from 'react';
import { cloneElement } from 'react';

export interface ErrorScreenProps {
  variant?: Variant;
  onRetry?: VoidFunction;
  onBack?: VoidFunction;
  isProxy?: boolean;
}

const DefaultErrorMessageStyled = styled(Div)<{ themeVariant: Theme }>`
  .lh {
    line-height: 1.5;
  }

  text-align: center;
  color: ${(props) =>
    props.themeVariant === 'light'
      ? props.theme.colors.C001
      : props.theme.colors.C800};

  > ${Span} {
    color: inherit;
  }

  > ${Div} {
    padding: 0 16px;
    margin: 16px 0;

    > ${Ul} {
      margin: 0;
      color: ${(props) =>
        props.themeVariant === 'light'
          ? props.theme.colors.C001
          : props.theme.colors.C800};

      ${Li} {
        color: inherit;
        margin-top: 10px;
      }
    }
  }
`;
DefaultErrorMessageStyled.displayName = 'DefaultErrorMessageStyled';

function DefaultErrorMessage({
  themeVariant,
  isProxy,
}: {
  themeVariant: Theme;
  isProxy?: boolean;
}) {
  return (
    <DefaultErrorMessageStyled themeVariant={themeVariant}>
      <Span className="lh">
        An unexpected error has occurred while calling the API Server. This
        could be due one of the following reasons:
      </Span>
      <Div className="lh">
        <Ul>
          {!isProxy && (
            <Li>
              Cross-Origin Resource Sharing (CORS) not supported by the API
              Server.
            </Li>
          )}
          <Li>
            Server could not complete the request due to temporary issues.
          </Li>
          <Li>Server could not access the API endpoint.</Li>
        </Ul>
      </Div>
      <Span className="lh">
        Please Try Again. If the issue exists, please contact the API provider
        for further detail.
      </Span>
    </DefaultErrorMessageStyled>
  );
}

export const errorVariants = {
  errorDefaultTryOut: {
    title: 'Unexpected Error',
    icon: GeneralDarkIcon,
    theme: 'light',
    message: <DefaultErrorMessage themeVariant="light" />,
    details: null,
  },
  errorConnectionTryOut: {
    title: 'Connection Lost',
    icon: ConnectionDarkIcon,
    theme: 'light',
    message:
      'A network connection is required to continue the request. Please check your internet and try again.',
    details: null,
  },
  errorNotFoundTryOut: {
    title: 'API Specification Not Found',
    icon: NotFoundDarkIcon,
    theme: 'light',
    message: `The API Specification resource you are trying to access does not exist. Please 
    contact the API provider for further detail.`,
    details: null,
  },
  errorServerTryOut: {
    title: 'Internal Error',
    icon: ServerDarkIcon,
    theme: 'light',
    message:
      'We encountered an internal error and were unable to call the API.',
    details: null,
  },
  error400TryOut: {
    title: 'Validation Failure',
    icon: Error400DarkIcon,
    theme: 'light',
    message: `The server was unable to process your request due to validation failure.
      Please review the required API parameters' inputs and try again.`,
    details: null,
  },
  error422TryOut: {
    title: 'Validation Failure',
    icon: Error422DarkIcon,
    theme: 'light',
    message: `The server was unable to process your request due to validation failure.
      Please review the required API parameters' inputs and try again.`,
    details: null,
  },
  errorTimeOutTryOut: {
    title: 'Request Timed Out',
    icon: TimeOutDarkIcon,
    theme: 'light',
    message: 'The server is taking too long to respond. Please try again.',
    details: null,
  },
  errorStatusUnknownTryOut: {
    title: 'Unexpected Error',
    icon: GeneralDarkIcon,
    theme: 'light',
    message:
      'We encountered an unexpected error and were unable to call the API. Please try again.',
    details: null,
  },
  errorDocsDefault: {
    title: 'Unexpected Error',
    icon: GeneralLightIcon,
    theme: 'dark',
    message: `An unexpected error occurred in loading the documentation. 
      Please try again; if the issue persists, please report the issue.`,
    details: null,
  },
  errorDocsConnection: {
    title: 'Connection Lost',
    icon: ConnectionLightIcon,
    theme: 'dark',
    message: `A network connection is required to
      continue the request. Please check your
      internet and try again.`,
    details: null,
  },
  errorDocsCode400: {
    title: 'Bad Request',
    icon: Error400LightIcon,
    theme: 'dark',
    message: `Your browser sent a request that the
      server could not understand. Please Try
      again later.
      `,
    details: {
      heading: 'Bad Request',
      message: `If you are the maintainer of this site, 
      the server cannot process the request due to invalid API key or platform template. 
      Please contact support for further details.`,
    },
  },
  errorDocsCode402: {
    title: 'Documentation Unavailable/Documentation Access Denied',
    icon: Error402LightIcon,
    theme: 'dark',
    message: `We are having trouble in loading
      documentation. Please contact the API
      provider for further details.
    `,
    details: {
      heading: 'Documentation Unavailable',
      message: `If you are the maintainer of this site, 
      looks like the language being accessed is not enabled. 
      Please check the language settings or contact support for further details.`,
    },
  },
  errorDocsCode403: {
    title: 'Forbidden',
    icon: Error403LightIcon,
    theme: 'dark',
    message: `Sorry, you don't have access to this page.`,
    details: {
      heading: 'Forbidden',
      message:
        'If you are the maintainer of this site, the server refuses access to the resource.',
    },
  },
  errorDocsCode404: {
    title: 'Page Not Found',
    icon: NotFoundLightIcon,
    theme: 'dark',
    message: `The page you are looking for does not
      exist or is temporarily unavailable.
      Please contact the API provider for
      further detail.`,
    details: {
      heading: 'Documentation Not Found',
      message: `If you are the maintainer of this site, 
      the resource being accessed does not exist or is temporarily unavailable. 
      Please check the publishing settings for details.`,
    },
  },
  errorDocsCode406: {
    title: 'Not Acceptable',
    icon: Error406LightIcon,
    theme: 'dark',
    message: `An appropriate representation of the
    requested resource could not be found
    on the server.
    `,
    details: {
      heading: 'Not Acceptable',
      message: `If you are the maintainer of this site, 
      the server could not find the appropriate representation of the requested resource.`,
    },
  },
  errorDocsCode422: {
    title: 'Unprocessable Entity',
    icon: Error422LightIcon,
    theme: 'dark',
    message: `An error occurred validating the API
      Specification while loading
      documentation. Please contact the API
      provider for further detail.
      `,
    details: {
      heading: 'API Validation Failure',
      message: `If you are the maintainer of this site, 
      an error occurred while validating the API specification. 
      Please resolve the validation error and try again.`,
    },
  },
  errorDocsCode500: {
    title: 'Internal Server Error',
    icon: ServerLightIcon,
    theme: 'dark',
    message: `The server encountered an internal error
    or misconfiguration and was unable to
    complete your request.`,
    details: {
      heading: 'Internal Error',
      message: `If you are the maintainer of this site, 
      the server encountered an internal error while loading the documentation. 
      Please try again after a while, or contact support for further details.`,
    },
  },
  technicalIssues: {
    title: 'Unknown Error',
    icon: GeneralLightIcon,
    theme: 'dark',
    message: `If you are the maintainer of this site, 
      the browser has encountered an unknown error. 
      Please contact support for further details.`,
    details: null,
  },
  errorDocsCode502: {
    title: 'Service Temporarily Unavailable',
    icon: Error502LightIcon,
    theme: 'dark',
    message: `The server is temporarily unable to process your request. Please try again in a moment.
    `,
    details: null,
  },
  errorDocsCode503: {
    title: 'Service Temporarily Unavailable',
    icon: Error502LightIcon,
    theme: 'dark',
    message: `The server is temporarily unable to process your request. Please try again in a moment.
    `,
    details: null,
  },
  errorDocsCode524: {
    title: 'Request Timed Out',
    icon: TimeOutLightIcon,
    theme: 'dark',
    message: `The server is taking too long to respond.
     Please try again.`,
    details: null,
  },
};
type ErrorVariants = typeof errorVariants;
type Variant = keyof ErrorVariants;
type Theme = 'light' | 'dark';

const ErrorContainer = styled(Div)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 767px), (max-height: 500px) {
    align-items: flex-start;
  }
  .scrollable {
    width: 100%;
  }
`;

const ErrorScreenStyled = styled(Div)<{ themeVariant: Theme }>`
  height: 100%;
  padding: 40px 32px;
  color: ${(props) =>
    props.themeVariant === 'light'
      ? props.theme.colors.C001
      : props.theme.colors.C800};
  position: relative;
  min-height: 500px;

  @media (max-width: 767px), (max-height: 500px) {
    min-height: 300px;
  }

  .error-icon-container {
    width: 128px;
    height: 128px;
    margin: 0 auto 16px auto;
  }

  .title-container {
    display: flex;
    justify-content: center;
    flex: 1;

    .title-inner-container {
      max-width: 340px;
    }

    ${P} {
      text-align: center;
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text1.fontSize) || '15px'};
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 1.5;
      color: ${(props) => props.theme.colors.C1000};
      opacity: 1;
      margin: unset;
    }
  }

  .error-title {
    color: inherit;
    font-weight: bold;
    text-align: center;
    margin: 0 auto 16px auto;
  }

  .error-message {
    text-align: center;
    color: inherit;
    max-width: 390px;
    margin: 0 auto 16px auto;
    ${({ theme: { cssStyles } }) =>
      cssStyles && renderCssStyles(cssStyles.body.text1)};
    line-height: 1.5;
  }

  .try-again {
    margin: 0 auto 16px auto;
  }

  .go-back-container {
    height: 50px;
  }

  .go-back {
    height: 24px;
    line-height: 23px;
    color: ${(props) => props.theme.linkColor};
    margin: 0 auto;

    > svg {
      margin-right: 10px;
    }
  }

  .more-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .more-details {
    color: ${(props) => props.theme.colors.C800};
    margin: 12px 0;
    cursor: pointer;
    user-select: none;
    font-size: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text3.fontSize) || '12px'};
  }

  .details-container {
    position: relative;
    background: ${(props) => props.theme.colors.C201} 0% 0% no-repeat
      padding-box;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;

    ${Div} {
      text-align: center;
      font-weight: 600;
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text2.fontSize) || '15px'};
      margin-bottom: 10px;
      opacity: 0.66;
    }

    ${P} {
      margin: unset;
      text-align: center;
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text2.fontSize) || '15px'};
      letter-spacing: 0.3px;
      line-height: 1.5;
      color: ${(props) => props.theme.colors.C800};
      opacity: 0.66;
    }

    :after {
      content: '';
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -5px;
      border-width: 10px;
      border-style: solid;
      border-color: ${(props) => props.theme.colors.C201} transparent
        transparent transparent;
      transform: rotate(180deg);
    }
  }
`;
ErrorScreen.displayName = 'ErrorScreen';

/**
 * TODO this component should be in base components
 * @param props
 * @returns
 */
export function ErrorScreen(props: ErrorScreenProps) {
  const { variant = 'errorDefaultTryOut', onRetry, onBack, isProxy } = props;
  const { icon: Icon, message, theme, title, details } = errorVariants[variant];
  const [showDetails, setShowDetails] = useState(false);

  // Passing props to the jsx element
  // isProxy as a prop and use cloneElement method
  const errorMessage = useMemo(
    () =>
      typeof message === 'string'
        ? message
        : cloneElement(message, { isProxy }),
    [message, isProxy]
  );

  const toggleDetails = () => setShowDetails((prevState) => !prevState);

  return (
    <ErrorContainer>
      <Scrollable overflowY={true} invert={true} className="scrollable">
        <ErrorScreenStyled
          themeVariant={theme as Theme}
          className="error-screen"
        >
          <Div className="error-icon-container">
            <Icon />
          </Div>
          {details ? (
            <Div className="title-container">
              <Div className="title-inner-container">
                <P>There is a problem loading the documentation.</P>
              </Div>
            </Div>
          ) : (
            <>
              <H4 className="error-title">{title}</H4>
              <Div className="error-message">{errorMessage}</Div>
            </>
          )}
          {onRetry && (
            <TryAgainButton className="try-again" onClick={onRetry} />
          )}
          {onBack && (
            <LinkButton onClick={onBack} className="go-back">
              <ArrowBackIcon height="100%" />
              Go Back
            </LinkButton>
          )}
          {details && (
            <Div className="more-details-container">
              <Div className="more-details" onClick={toggleDetails}>
                {showDetails ? 'Hide' : 'More'} Details
              </Div>
              {showDetails && (
                <Div className="details-container">
                  <Div>{details?.heading}</Div>
                  <P>{details?.message}</P>
                </Div>
              )}
            </Div>
          )}
        </ErrorScreenStyled>
      </Scrollable>
    </ErrorContainer>
  );
}

interface ResponseViewerErrorProps
  extends Pick<ResponseViewerProps, 'onRetry' | 'onBack' | 'isProxy'> {
  response: ProxyResponseInterface;
}
export function ResponseViewerError(props: ResponseViewerErrorProps) {
  const { response, onRetry, onBack, isProxy } = props;
  const isOnline = window.navigator.onLine;

  if (!isOnline) {
    // With back and retry button
    return (
      <ErrorScreen
        variant="errorConnectionTryOut"
        onRetry={onRetry}
        onBack={onBack}
      />
    );
  }

  if (response.StatusCode === 404) {
    // With back button
    return <ErrorScreen variant="errorNotFoundTryOut" onBack={onBack} />;
  }

  if (response.StatusCode === 400) {
    // With back button
    return <ErrorScreen variant="error400TryOut" onBack={onBack} />;
  }

  if (response.StatusCode === 422) {
    // With back button
    return <ErrorScreen variant="error422TryOut" onBack={onBack} />;
  }

  if (response.StatusCode === 500) {
    // With back and retry button
    return (
      <ErrorScreen
        variant="errorServerTryOut"
        onRetry={onRetry}
        onBack={onBack}
      />
    );
  }

  if (response.StatusCode === 504 || response.StatusCode === 524) {
    // With back and retry button
    return (
      <ErrorScreen
        variant="errorTimeOutTryOut"
        onRetry={onRetry}
        onBack={onBack}
      />
    );
  }

  if (response.StatusCode) {
    // With back and retry button
    return (
      <ErrorScreen
        variant="errorStatusUnknownTryOut"
        onRetry={onRetry}
        onBack={onBack}
      />
    );
  }

  // With back button
  return <ErrorScreen onBack={onBack} isProxy={isProxy} />;
}

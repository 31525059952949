import { forwardRef, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { DefaultButton } from 'src/StyledElements';
import { Span } from 'src/CleanSlate';

interface PopoverButtonProps {
  refFunc?: (instance: HTMLButtonElement | null) => void;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const POPOVER_BTN_ID = 'http-types-popover-btn';

const PopoverButtonStyled = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  max-height: 20px;
  position: relative;
  top: 1px;
  border: none;
`;

const PopoverButtonIcon = styled(Span)`
  pointer-events: none;
  max-height: 20px;
`;

const IconButton = (props: PopoverButtonProps) => {
  const { refFunc, onClick } = props;

  return (
    <PopoverButtonStyled
      id={POPOVER_BTN_ID}
      type="button"
      ref={refFunc}
      onClick={onClick}
    >
      <PopoverButtonIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <circle cx="8" cy="8" r="8" fill="#606e8a" />
          <g transform="translate(17.173 -4.25) rotate(90)">
            <circle
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(11 3.923)"
              fill="#fff"
            />
          </g>
          <g transform="translate(13.173 -4.25) rotate(90)">
            <circle
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(11 3.923)"
              fill="#fff"
            />
          </g>
          <g transform="translate(9.173 -4.25) rotate(90)">
            <circle
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(11 3.923)"
              fill="#fff"
            />
          </g>
        </svg>
      </PopoverButtonIcon>
    </PopoverButtonStyled>
  );
};

export const PopoverButton = forwardRef(IconButton);

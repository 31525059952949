import { getMimeTypeWithoutEncoding } from '../HttpUtils';

export enum ContentCategory {
  JSON,
  YAML,
  HTML,
  XML,
  Image,
  Markdown,
  Text,
  Binary,
}

export const fromHeader = getContentCategoryFromHeader;

/**
 * Get content category from a content-type header
 */
function getContentCategoryFromHeader(
  contentTypeHeader?: string
): ContentCategory {
  if (!contentTypeHeader) {
    return ContentCategory.Binary;
  }

  const cth = getMimeTypeWithoutEncoding(contentTypeHeader);

  if (
    contentTypeHeader === 'image/jpeg' ||
    contentTypeHeader === 'image/png' ||
    contentTypeHeader === 'image/gif' ||
    contentTypeHeader === 'image/svg+xml'
  ) {
    return ContentCategory.Image;
  }

  if (cth === 'text/html') {
    return ContentCategory.HTML;
  }

  if (cth === 'application/json' || cth.endsWith('+json')) {
    return ContentCategory.JSON;
  }

  if (cth === 'application/xml' || cth === 'text/xml' || cth.endsWith('+xml')) {
    return ContentCategory.XML;
  }

  if (cth === 'text/yaml') {
    return ContentCategory.YAML;
  }

  if (cth === 'text/markdown' || cth === 'text/x-markdown') {
    return ContentCategory.Markdown;
  }

  if (cth.startsWith('text/')) {
    return ContentCategory.Text;
  }

  return ContentCategory.Binary;
}

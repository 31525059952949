import { Base64 as base64 } from 'js-base64';
import {
  anyFilter,
  unwrapAdditionalProperty,
  unwrapCallModelData,
} from './utility';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { Liquid, Tokenizer, assert } = require('liquidjs');

export class LiquidJS {
  liquid: any;
  constructor(partialTemplates?: { [key: string]: string }, globals?: any) {
    this.liquid = new Liquid({
      relativeReference: false,
      fs: {
        async readFile(fileName: string) {
          return partialTemplates?.[fileName];
        },
        existsSync() {
          return true;
        },
        async exists() {
          return true;
        },
        contains() {
          return true;
        },
        resolve(root: any, fileName: any, ext: any) {
          return fileName;
        },
      },
      globals,
    });

    this.registerFilters();
  }

  private registerFilters = () => {
    this.registerJsonFileder();
    this.registerGetFileFromDataUrlFilter();
    this.registerBase64EncodeFilter();
    this.registerUnwrapFilter();
    this.registerAnyFilter();
    this.registerAssignTag();
    this.regesterURLEncode();
    this.regesterURLDecode();
    this.registerAdditionalProperty();
  };

  private registerJsonFileder = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.liquid.registerFilter('json', (v: any, i: number | undefined) =>
      JSON.stringify(v, null, i ? 2 : undefined)
    );
  };

  private registerGetFileFromDataUrlFilter = () => {
    /**
     * A filter to get file name from data-URI encoded string
     */
    this.liquid.registerFilter('get_file_from_data_url', (v: string) => {
      const reg = /^data:.*;name=(.*);.*$/g;
      const result = reg.exec(v);
      return result ? result[1] : '';
    });
  };

  private registerAssignTag = () => {
    this.liquid.registerTag('assign', {
      parse: function (token: any) {
        const tokenizer = new Tokenizer(
          token.args,
          this.liquid.options.operatorsTrie
        );
        this.key = tokenizer.readIdentifier().content;
        tokenizer.skipBlank();
        assert(
          tokenizer.peek() === '=',
          () => `illegal token ${token.getText()}`
        );
        tokenizer.advance();
        this.value = tokenizer.remaining();
      },
      render: function* (ctx: any): any {
        if (ctx.globals?.[this.key] !== undefined) {
          ctx.globals[this.key] = yield this.liquid._evalValue(this.value, ctx);
        } else {
          ctx.bottom()[this.key] = yield this.liquid._evalValue(
            this.value,
            ctx
          );
        }
      },
    });
  };

  private registerBase64EncodeFilter = () => {
    /**
     * Base64 encode a string
     */
    this.liquid.registerFilter('base64_encode', (v: string) =>
      base64.encode(v)
    );
  };

  private registerUnwrapFilter = () => {
    this.liquid.registerFilter('unwrap', (v: string) => unwrapCallModelData(v));
  };

  private registerAdditionalProperty = () => {
    this.liquid.registerFilter('unwrap_add_props', (v: string) =>
      unwrapAdditionalProperty(v)
    );
  };

  private registerAnyFilter = () => {
    this.liquid.registerFilter('any', (jsonObject: string, tracer: string) => {
      const TRACER_SEPERATOR = '$->$';
      const QUERY_SEPERATOR = '$,$';

      return anyFilter(jsonObject, tracer, TRACER_SEPERATOR, QUERY_SEPERATOR);
    });
  };

  private regesterURLEncode = () => {
    this.liquid.registerFilter('url_encode', (v: string) =>
      encodeURIComponent(v)
    );
  };

  private regesterURLDecode = () => {
    this.liquid.registerFilter('url_decode', (v: string) =>
      decodeURIComponent(v)
    );
  };

  /* The removeClauses function will be removed when all the customers have republished there poltals */

  removeClauses = (tpl: string) => {
    return tpl.replace('{{clauses}} | split', 'clauses | split');
  };

  /**
   * Render a template with given data context
   */
  render = (tpl: string, ctx?: any): Promise<string> => {
    const updateTPL = this.removeClauses(tpl);
    return this.liquid.parseAndRender(updateTPL, ctx);
  };
}

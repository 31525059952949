import { staticColors } from 'src/Color';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const TypescriptIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '21'}
    height={props.height || '14'}
    viewBox={props.viewBox || '0 0 21 14'}
    fill={props.fill || staticColors.Goose.C200}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7921 9.67132V12.5288C12.311 12.7854 12.8641 12.9659 13.4345 13.0647C14.0938 13.1863 14.7629 13.2461 15.4333 13.2434C16.0866 13.2478 16.7385 13.1834 17.3783 13.0513C17.9523 12.9367 18.5003 12.7173 18.9948 12.4039C19.4532 12.1081 19.8311 11.7032 20.0945 11.2254C20.5662 10.2357 20.6287 9.0999 20.2684 8.06443C20.1145 7.67702 19.8861 7.3236 19.596 7.02422C19.2861 6.7055 18.9343 6.43051 18.5501 6.20685C18.1078 5.948 17.6482 5.71982 17.1747 5.52394C16.8007 5.36898 16.4655 5.2185 16.169 5.07251C15.9044 4.9457 15.6511 4.79627 15.4122 4.62589C15.2251 4.49523 15.0626 4.33266 14.9319 4.14564C14.8189 3.97494 14.7599 3.77408 14.7629 3.56935C14.7604 3.38126 14.8131 3.19657 14.9146 3.0382C15.0244 2.87203 15.1705 2.73308 15.3421 2.63192C15.5508 2.50904 15.7777 2.4202 16.0144 2.36874C16.3065 2.30274 16.6053 2.27052 16.9048 2.2727C17.1564 2.27378 17.4076 2.29175 17.6568 2.32648C17.926 2.3632 18.1924 2.4184 18.454 2.49169C18.7203 2.5659 18.9807 2.65997 19.233 2.77311C19.4778 2.88195 19.7119 3.01347 19.9322 3.16595V0.495793C19.4735 0.322359 18.9976 0.198083 18.5126 0.125048C17.9304 0.0386082 17.3425 -0.00313583 16.754 0.00018338C16.1072 -0.00293894 15.4624 0.0705709 14.833 0.219175C14.2637 0.349406 13.7224 0.580702 13.2347 0.902079C12.7818 1.20376 12.4077 1.60952 12.1436 2.0854C11.8645 2.61452 11.7266 3.20667 11.7431 3.80467C11.7223 4.5952 11.9827 5.36747 12.4779 5.98401C13.0852 6.66788 13.851 7.19227 14.7081 7.51119C15.1 7.67191 15.465 7.82975 15.8031 7.98471C16.1081 8.12047 16.4014 8.28109 16.68 8.46495C16.9045 8.61122 17.1018 8.79551 17.263 9.00955C17.4056 9.20444 17.4808 9.44046 17.4772 9.68189C17.4785 9.86192 17.4325 10.0391 17.3437 10.1957C17.2445 10.364 17.1053 10.5052 16.9384 10.6068C16.7308 10.7341 16.5036 10.8263 16.266 10.8796C15.9549 10.9487 15.6367 10.981 15.318 10.9757C14.6923 10.9744 14.0715 10.8642 13.4835 10.6501C12.8655 10.428 12.2926 10.0965 11.7921 9.67132ZM6.98966 2.55796H10.7173V0.203808H0.329651V2.55796H4.03905V13.0397H6.99254L6.98966 2.55796Z"
      fill={props.fill || staticColors.Goose.C200}
    />
  </SVGIcon>
);

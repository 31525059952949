/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const Error502LightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <g>
      <g>
        <g>
          <path
            fill="#9199A5"
            stroke="#49505A"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            d="M121.4,87.2c-2.7,6.7-6.6,12.9-11.3,18.2
				H94.9c-3.6,0-6.5-2.9-6.5-6.5V89H93v9.8c0,1.3,1.1,2.4,2.4,2.4h15.1c1.3,0,2.4-1.1,2.4-2.4C113,93.5,116.6,88.8,121.4,87.2z"
          />
        </g>
      </g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M53.8,93H18.9c-2.8,0-5-2.3-5-5V43.4
		c0-2.8,2.3-5,5-5h34.9c2.8,0,5,2.3,5,5V88C58.9,90.7,56.6,93,53.8,93z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M37,59.1l-1.3,0c-1.5,0-2.7-1.2-2.7-2.7l0-0.8
		c0-1.5,1.2-2.7,2.7-2.7l1.3,0c1.5,0,2.7,1.2,2.7,2.7l0,0.8C39.7,57.9,38.5,59.1,37,59.1z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M28.4,72.4l-1.3,0c-1.5,0-2.7-1.2-2.7-2.7
		l0-0.8c0-1.5,1.2-2.7,2.7-2.7l1.3,0c1.5,0,2.7,1.2,2.7,2.7l0,0.8C31.1,71.2,29.9,72.4,28.4,72.4z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M44.9,72.4l-1.3,0c-1.5,0-2.7-1.2-2.7-2.7
		l0-0.8c0-1.5,1.2-2.7,2.7-2.7l1.3,0c1.5,0,2.7,1.2,2.7,2.7l0,0.8C47.6,71.2,46.4,72.4,44.9,72.4z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M91.3,72.6H61.7c-0.9,0-1.6-0.7-1.6-1.6v-0.5
		c0-0.9,0.7-1.6,1.6-1.6h29.6c0.9,0,1.6,0.7,1.6,1.6V71C92.9,71.9,92.2,72.6,91.3,72.6z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M91.4,59.4H61.8c-0.9,0-1.6-0.7-1.6-1.6v-0.5
		c0-0.9,0.7-1.6,1.6-1.6h29.6c0.9,0,1.6,0.7,1.6,1.6v0.5C93,58.6,92.3,59.4,91.4,59.4z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M99.7,90.3H78c-1.6,0-2.8-1.3-2.8-2.8V52.9
		c0-1.6,1.3-2.8,2.8-2.8h21.7c1.6,0,2.8,1.3,2.8,2.8v34.6C102.5,89,101.3,90.3,99.7,90.3z"
      />
      <path
        fill="#3E445D"
        stroke="#1D2236"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M101.1,90.3H81.4c-0.8,0-1.4-0.6-1.4-1.4V51.5
		c0-0.8,0.6-1.4,1.4-1.4h19.7c0.8,0,1.4,0.6,1.4,1.4v37.4C102.5,89.6,101.9,90.3,101.1,90.3z"
      />
      <rect
        x="80"
        y="53.3"
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        width="22.5"
        height="2.8"
      />
      <rect
        x="80"
        y="59.1"
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        width="22.5"
        height="2.8"
      />
      <rect
        x="80"
        y="65"
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        width="22.5"
        height="2.8"
      />
      <rect
        x="80"
        y="70.9"
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        width="22.5"
        height="2.8"
      />
      <rect
        x="80"
        y="76.8"
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        width="22.5"
        height="2.8"
      />
      <g>
        <path
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M69.4,72.6h-7.7c-0.9,0-1.6-0.7-1.6-1.6v-0.5
			c0-0.9,0.7-1.6,1.6-1.6h7.7V72.6z"
        />
        <path
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M69.5,59.4h-7.7c-0.9,0-1.6-0.7-1.6-1.6v-0.5
			c0-0.9,0.7-1.6,1.6-1.6h7.7V59.4z"
        />
      </g>

      <rect
        x="88.4"
        y="90.3"
        opacity="0.33"
        fill="#49505A"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        width="4.6"
        height="2.1"
      />
    </g>
    <circle fill="none" cx="64" cy="64" r="62" />
  </SVGIcon>
);

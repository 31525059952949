/* istanbul ignore file */
/* ignore until we figure out shallow rendering to test all cases */
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { prefixClass as pfx } from '../../utils';

const timePickerProps = {
  placeholderText: 'Select a date and time',
  showTimeSelect: true,
  timeFormat: 'HH:mm',
  timeIntervals: 15,
  dateFormat: 'MMM d, yyyy h:mm aa',
};

function fromDateString(jsonDate) {
  if (jsonDate) {
    let date = moment.utc(jsonDate);
    if (date.isValid()) {
      return new Date(
        date.year(),
        date.month(),
        date.date(),
        date.hour(),
        date.minute(),
        date.second(),
      );
    } else {
      return null;
    }
  }
}

function toDateString(dateobj, time) {
  if (dateobj) {
    const momentDate = moment(dateobj);
    return time
      ? momentDate.format('YYYY-MM-DDTHH:mm:ss[Z]')
      : momentDate.format('YYYY-MM-DD');
  }
  return '';
}

function DateTimeWidget(props) {
  const {
    value,
    onChange,
    disabled,
    disableTime,
    required,
    readonly,
    autofocus,
    onBlur,
    onFocus,
  } = props;
  const time = !disableTime;

  return (
    <DatePicker
      showMonthDropdown
      showYearDropdown
      todayButton="Today"
      className={pfx('form-control field-datepicker')}
      selected={fromDateString(value)}
      onChange={(value) => onChange(toDateString(value, time))}
      dateFormat={time ? 'MMM d, yyyy h:mm aa' : 'MMM d, yyyy'}
      isClearable={true}
      timeZone="UTC"
      utcOffset={0}
      disabled={disabled}
      required={required}
      readOnly={readonly}
      autoFocus={autofocus}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholderText="Select a date"
      disabledKeyboardNavigation
      {...(time ? timePickerProps : {})}
    />
  );
}

/* istanbul ignore else */
if (process.env.NODE_ENV !== 'production') {
  DateTimeWidget.propTypes = {
    value: PropTypes.string,
  };
}

export default DateTimeWidget;

import React from 'react';
import { MenuKeyChangeEvent } from 'rc-menu';
import { Div, H6, InlineCode, P } from 'src/CleanSlate';
import { ResponseContentInfo, SectionNode } from 'src/DxDom';
import styled from 'styled-components';
import { ContentTypeSelector } from './ContentTypeSelector';
import { ExampleWrapper, RenderMultipleExamples } from './Examples';
import { ellipsisMixin } from 'src/StyledElements';
import { Callout } from './Callout';
import { ContentTypeInteractionEvent } from 'src/Analytics';
import { PortalSettings } from 'src/PortalSettings';

interface ContentBodyProps {
  contentInfoList: ReadonlyArray<ResponseContentInfo>;
  renderNode: (node: SectionNode) => JSX.Element;
  portalSettings: PortalSettings;
}

interface ContentBodyState {
  selectedContent: ResponseContentInfo;
}

const ContentTabWrapper = styled(Div)`
  margin-top: 20px;
`;
ContentTabWrapper.displayName = 'ContentTabWrapper';

const ContentTab = styled(Div)`
  background-color: ${(props) => props.theme.colors.C000};
  max-width: 400px;
  min-width: 190px;
  height: 42px;
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  align-items: center;
  padding: 0 10px 0 20px;
  ${ellipsisMixin}

  label {
    color: ${(props) => props.theme.colors.C1100};
    margin-right: 10px;
  }
  ${P} {
    width: 100%;
    color: ${(props) => props.theme.colors.C800};
    ${ellipsisMixin}
    margin: 0px 10px 0px 0px;
  }
`;
ContentTab.displayName = 'ContentTab';

const ContentPanel = styled(Div)`
  background-color: ${(props) => props.theme.colors.C000};
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-radius: 0px 4px 4px 4px;
  padding: 20px;
  margin-top: -1px;
`;
ContentPanel.displayName = 'ContentPanel';

const LabelWrapper = styled(Div)`
  ${H6} {
    margin-top: 0;
    margin-bottom: 5px;
  }

  ${P} {
    margin: 0 0 20px;
    font-size: ${(props) => props.theme.cssStyles.body.text2.fontSize};

    ${InlineCode} {
      padding: 0;
    }
  }
`;
LabelWrapper.displayName = 'LabelWrapper';
export class ContentBody extends React.PureComponent<
  ContentBodyProps,
  ContentBodyState
> {
  constructor(props: ContentBodyProps) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.state = {
      selectedContent: this.props.contentInfoList[0],
    };
  }

  componentDidUpdate(
    prevProps: Readonly<ContentBodyProps>,
    prevState: Readonly<ContentBodyState>
  ): void {
    if (prevProps.contentInfoList !== this.props.contentInfoList) {
      this.setState({
        selectedContent: this?.props?.contentInfoList[0],
      });
    }
  }

  filteredContentType = (selectedContentType: string) => {
    return this.props.contentInfoList.find(
      (contentInfo) => contentInfo.ContentType === selectedContentType
    );
  };

  onSelect = (ev: MenuKeyChangeEvent) => {
    const activeContent = this.filteredContentType(ev.key);
    this.setState({
      selectedContent: activeContent
        ? activeContent
        : this.props.contentInfoList[0],
    });
    ContentTypeInteractionEvent(this.props.portalSettings);
  };

  renderSimpleExample = () => {
    return this.state.selectedContent.Example ? (
      <ExampleWrapper>
        {this.props.renderNode({
          ...this.state.selectedContent.Example,
          From: 'Response Example',
        })}
      </ExampleWrapper>
    ) : (
      <Callout icon="info">
        There is no example available for this content type.
      </Callout>
    );
  };

  render() {
    const { contentInfoList, renderNode } = this.props;
    return (
      <ContentTabWrapper>
        <ContentTab>
          <label>Content Type:</label>
          {contentInfoList.length > 1 ? (
            <ContentTypeSelector
              onSelect={this.onSelect}
              selected={this.state.selectedContent.ContentType}
              contentInfoList={contentInfoList}
            />
          ) : (
            <P title={contentInfoList[0].ContentType}>
              {contentInfoList[0].ContentType}
            </P>
          )}
        </ContentTab>
        <ContentPanel>
          {this.state.selectedContent && (
            <LabelWrapper>
              <H6>Type</H6>
              {renderNode({
                Type: 'paragraph',
                Text: getResponseDescription(this.state.selectedContent),
              })}
            </LabelWrapper>
          )}
          {this.state.selectedContent.Examples &&
          this.state.selectedContent.Examples.length > 1 ? (
            <RenderMultipleExamples
              examples={this.state.selectedContent.Examples}
              renderNode={renderNode}
            />
          ) : (
            this.renderSimpleExample()
          )}
        </ContentPanel>
      </ContentTabWrapper>
    );
  }
}

/**
 * Get a Markdown text describing the endpoint response
 */
function getResponseDescription(
  content: ResponseContentInfo
): string | undefined {
  return content.LinkTo
    ? '[`' + content.DataType + '`](' + content.LinkTo + ')'
    : '`' + content.DataType + '`';
}

import * as React from 'react';
import * as DxDom from './DxDom';
import { TypeWrapper } from './StyledElements';
import { RContainerTable } from './RContainerTable';

/**
 * Render model reference
 * @param {array} mref
 */
export function containerReferences(
  mref: DxDom.ContainerReference,
  nodeRenderer: DxDom.NodeHandler<JSX.Element>
): JSX.Element {
  const nodes: JSX.Element[] = [];

  const renderNode = (n: DxDom.SectionNode) =>
    DxDom.callNodeHandler(n, nodeRenderer);

  let i = 0;
  const pushNode = (n: DxDom.SectionNode) =>
    nodes.push(<React.Fragment key={i++}>{renderNode(n)}</React.Fragment>);

  if (mref.StructureType) {
    pushNode({
      Type: 'heading',
      Text: mref.StructureType,
      Level: 2,
    });

    nodes.push(
      <TypeWrapper key="class-name">
        {renderNode({
          Type: 'paragraph',
          Text: '`' + mref.Name + '`',
        })}
      </TypeWrapper>
    );
  }

  pushNode({
    Type: 'heading',
    Text: 'Cases',
    Level: 2,
  });

  nodes.push(
    <RContainerTable key="fields-table" params={mref.ContainerTypes} />
  );

  return <React.Fragment>{nodes}</React.Fragment>;
}

/**
 * Custom Tabs library that uses styled-components for styling and can be customized.
 *
 * I intially used react-tabs but it turned out to be quite limiting, specially in the
 * case of customizing styles.
 */

import React, {
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from 'react';
import styled from 'styled-components';
import { Div, Li, Ul } from './CleanSlate';
import { ChevronIcon } from './Icons/Ui/ChevronIcon';
import { ellipsisMixin } from './StyledElements';
import { TabsInteractionEvent } from './Analytics';
import { PortalContextConsumer } from './PortalContext';
/**
 * Tab container
 */
const TabsWrapper = styled(Div)`
  position: relative;

  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;
TabsWrapper.displayName = 'TabsWrapper';

/**
 * Panel container (one tab's content)
 */
const TabPanelWrapper = styled(Div)`
  border-top-right-radius: ${(props: { isScrollable?: boolean }) =>
    props.isScrollable ? '0' : '4px'} !important;

  @media screen and (max-width: 990px) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    padding: 0;
  }
`;
TabPanelWrapper.displayName = 'TabPanelWrapper';

const TabListWrapper = styled(Div)`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
TabListWrapper.displayName = 'TabListWrapper';
interface TabListActionWrapperProps {
  isScrollable?: boolean;
}

const TabListActionWrapper = styled(Div)<TabListActionWrapperProps>`
  display: flex;

  &:after {
    display: ${(props) => (props.isScrollable ? 'block' : 'none')};
    top: 0;
    bottom: 0;
    width: 30px;
    height: 33px;
    position: absolute;
    margin-top: 2px;
    z-index: 5;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    content: '';
    pointer-events: none;
    right: 23px;
    box-shadow: inset -10px 0 8px -8px #00000014;
  }

  &:before {
    display: ${(props) => (props.isScrollable ? 'block' : 'none')};
    top: 0;
    bottom: 0;
    width: 30px;
    height: 32px;
    position: absolute;
    margin-top: 3px;
    z-index: 5;
    -webkit-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    content: '';
    pointer-events: none;
    left: 22px;
    box-shadow: inset 10px 0 11px -8px #0000002e;
  }
`;
TabListActionWrapper.displayName = 'TabListActionWrapper';

/**
 * List of tab names
 */
const TabList = styled(Ul)`
  width: 100%;
  padding: 0;
  display: flex;
  max-height: 35px;
  margin: 0;
`;
TabList.displayName = 'TabList';

const ActionButton = styled.button`
  background-color: ${(props) => props.theme.colors.C201};
  width: 25px;
  border: 1px solid #e2e5e7;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0;
  position: relative;
  z-index: 6;

  &.left {
    margin: 2px 5px 0 0;
  }
  &.right {
    margin-top: 2px;
  }
`;
ActionButton.displayName = 'ActionButton';

/**
 * List item for tab name
 */

const TabListItem = styled(Li)<TabListItemProps>`
  max-width: 150px;
  min-width: 80px;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -3px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: bold;
  font-size: 11.85px;
  border-color: ${(props) =>
    props.selected ? props.theme.colors.C100 : 'transparent'};
  border-bottom: ${(props) =>
    props.selected ? `1px solid ${props.theme.colors.C100}` : 'transparent'};
  border-radius: ${(props: { selected?: boolean }) =>
    props.selected ? '5px 5px 0 0' : 0};
  pointer-events: ${(props) => (props.isDisable ? 'none' : 'auto')};
  color: ${(props) =>
    props.isDisable ? props.theme.colors.C801 : props.theme.colors.C100};
  display: ${(props) => (props.isHidden === false ? 'none' : 'list-item')};
  ${ellipsisMixin};
`;
TabListItem.displayName = 'TabListItem';

const TabListParent = styled(Div)<TabListItemProps>`
  cursor: ${(props) => (props.isDisable ? 'not-allowed' : 'pointer')};
  display: flex; 
  flex-shrink: 0;
  &:not(:first-child) {
    margin-left: 5px; /* Apply margin-left from the second TabListParent */
  }
`;

/**
 * Default tab elements
 */
export const defaultElements = {
  TabsWrapper,
  TabPanelWrapper,
  TabList,
  TabListItem,
  TabListWrapper,
};

interface TabListItemProps {
  selected?: boolean;
  isDisable?: boolean;
  isHidden?: boolean;
}

interface TabsProps {
  initialOpenKey: string;
  index?: number;
  className?: string;
  children: React.ReactElement<TabPanelProps>[];
  getActiveTab?: (tab: SelectedTabDef) => void;
  fromCodeSample?: boolean;
}

interface SelectedTabDef {
  initialOpenKey: string;
  index: number;
}

interface TabPanelProps {
  title: string;
  tabListItemTemplate?: React.ReactNode;
  isDisable?: boolean;
  isHidden?: boolean;
}

// Calculates whether tabs are scrollable or not
function checkTabListScroll(element: HTMLDivElement | null) {
  return !!(
    element && element.children[0].scrollWidth > element.children[0].clientWidth
  );
}

/**
 * Create a custom Tabs components using the elements provided.
 *
 * This can be used to customized the elements' styling.
 * @param tabElements Elements to use for making the Tabs
 */
export function makeCustomTabs(tabElements: Partial<typeof defaultElements>) {
  const Elements = { ...defaultElements, ...tabElements };

  // The main tabs component
  const Tabs: FunctionComponent<TabsProps> = (props) => {
    const { getActiveTab, fromCodeSample } = props;

    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<SelectedTabDef>({
      initialOpenKey: props.initialOpenKey,
      index: props.index || 0,
    });
    const tabListRef: RefObject<HTMLDivElement> = useRef(null);
    const shouldDisplayArrows = isScrollable && !fromCodeSample;

    const handleScreenResize = useCallback(() => {
      setIsScrollable(checkTabListScroll(tabListRef.current));
    }, []);

    useEffect(() => {
      setIsScrollable(checkTabListScroll(tabListRef.current));
      window.addEventListener('resize', handleScreenResize);

      return () => {
        window.removeEventListener('resize', handleScreenResize);
      };
    }, [handleScreenResize]);

    useEffect(() => {
      setSelectedTab({
        initialOpenKey: props.initialOpenKey,
        index: props.index || 0,
      });
    }, [props.initialOpenKey, props.index]);

    const onScrollLeft = () => {
      const element = tabListRef.current;
      if (element) {
        const elementScrollLeft = element.scrollLeft;
        if (elementScrollLeft > 0) {
          element.scrollTo({
            top: 0,
            left: Math.max(elementScrollLeft - 100, 0),
            behavior: 'smooth',
          });
        }
      }
    };

    const onScrollRight = () => {
      const element = tabListRef.current;
      if (element) {
        const elementScroll = element.scrollLeft;
        const maxScrollAllowed =
          element.scrollWidth - element.children[0].clientWidth || 0;

        if (maxScrollAllowed > elementScroll) {
          element.scrollTo({
            top: 0,
            left: Math.max(
              elementScroll + 100,
              element.children[0].clientWidth
            ),
            behavior: 'smooth',
          });
        }
      }
    };

    // renders tabs item
    const renderTabListItem = () =>
      props.children.map(
        (
          {
            props: { title, tabListItemTemplate, isDisable, isHidden },
          }: React.ReactElement<TabPanelProps>,
          index
        ) => (
          <PortalContextConsumer>
            {(portalSettings) =>
              portalSettings && (
                <TabListParent isDisable={isDisable} key={title + index}>
                  <Elements.TabListItem
                    isHidden={isHidden}
                    isDisable={isDisable}
                    selected={!!(selectedTab.initialOpenKey === title)}
                    title={title}
                    onClick={() => {
                      setSelectedTab({
                        index: index,
                        initialOpenKey: title,
                      });
                      getActiveTab?.({
                        index: index,
                        initialOpenKey: title,
                      });
                      TabsInteractionEvent(portalSettings, title);
                    }}
                  >
                    {tabListItemTemplate ?? title}
                  </Elements.TabListItem>
                  </TabListParent>
              )
            }
          </PortalContextConsumer>
        )
      );

    return (
      <Elements.TabsWrapper className={props.className}>
        <TabListActionWrapper isScrollable={isScrollable}>
          {shouldDisplayArrows && (
            <ActionButton className="left" onClick={onScrollLeft}>
              <ChevronIcon
                viewBox="0 0 24 24"
                width="12px"
                fill="none"
                stroke="#3E445D"
                transform="rotate(90)"
              />
            </ActionButton>
          )}
          <Elements.TabListWrapper ref={tabListRef}>
            <Elements.TabList>{renderTabListItem()}</Elements.TabList>
          </Elements.TabListWrapper>
          {shouldDisplayArrows && (
            <ActionButton className="right" onClick={onScrollRight}>
              <ChevronIcon
                viewBox="0 0 24 24"
                width="12px"
                fill="none"
                stroke="#3E445D"
                transform="rotate(-90)"
              />
            </ActionButton>
          )}
        </TabListActionWrapper>
        <Elements.TabPanelWrapper isScrollable={isScrollable}>
          {props.children[selectedTab.index]}
        </Elements.TabPanelWrapper>
      </Elements.TabsWrapper>
    );
  };

  // The tab panel component that renders the tab content and the tab button using React portal
  const TabPanel: FunctionComponent<PropsWithChildren<TabPanelProps>> = (
    props
  ) => {
    return <>{props.children}</>;
  };

  return { Tabs, TabPanel };
}

export const { Tabs, TabPanel } = makeCustomTabs({});

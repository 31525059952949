import { FC, PropsWithChildren, PureComponent } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { PopoverProps } from '../popover/Popover';
import { Div, Span } from '../../theme/CleanSlate';
import { TOOLTIP_BACKGROUND } from '../../constants';

type TooltipProps = { text: string; parentRefranceId?: string } & Parameters<
  PopoverProps['children']
>[0];

const HoverStyled = styled(Div)`
  background: ${TOOLTIP_BACKGROUND};
  color: ${({ theme }) => theme.colors.C001};
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.cssStyles.body.text2.fontSize};
  font-family: ${({ theme: { cssStyles } }) =>
    cssStyles && cssStyles.body.fontFamily};
  border-radius: 4px;
  max-width: 250px;

  .tooltip-arrow {
    position: absolute;
    width: 10px;
    height: 10px;

    &:after {
      content: ' ';
      position: absolute;
      top: 0px; // we account for the PopperContainer padding
      left: 0;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      background-color: ${TOOLTIP_BACKGROUND};
    }
  }
  &[data-popper-placement^='top'] > .tooltip-arrow {
    bottom: -14px;

    :after {
      top: -9px;
    }
  }
  &[data-popper-placement^='bottom'] > .tooltip-arrow {
    top: -14px;

    :after {
      top: 9px;
    }
  }
  &[data-popper-placement^='left'] > .tooltip-arrow {
    right: -4px;

    :after {
    }
  }
  &[data-popper-placement^='right'] > .tooltip-arrow {
    left: -4px;

    :after {
    }
  }
`;

export const TooltipWrapper: FC<PropsWithChildren<TooltipProps>> = (props) => {
  const {
    hoverVisibility,
    isHover,
    parentRefranceId,
    setReferenceElement,
    children,
  } = props;
  /*
   * Check if refranceID exise in DOM
   * Else get reference of body
   */
  const elementRefranceId =
    parentRefranceId && document.getElementById(parentRefranceId);

  const elementRef = elementRefranceId ? elementRefranceId! : document.body;

  return (
    <Div onMouseEnter={hoverVisibility} onMouseLeave={hoverVisibility}>
      <span ref={setReferenceElement}>{children}</span>
      {isHover && createPortal(<TooltipStyledWrapper {...props} />, elementRef)}
    </Div>
  );
};

export class TooltipStyledWrapper extends PureComponent<TooltipProps> {
  render() {
    const { text, setPopperElement, styles, setArrowElement, attributes } =
      this.props;
    return (
      <HoverStyled
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <Span
          ref={setArrowElement}
          className="tooltip-arrow"
          style={styles.arrow}
          data-popper-placement
        ></Span>
        {text}
      </HoverStyled>
    );
  }
}

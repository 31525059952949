/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const NotFoundDarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <path
      fill="#01A9DB"
      stroke="#0B76B7"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M69.1,59.5v3.7H22.8c-1,0-1.8-0.8-1.8-1.8l0,0
	c0-1,0.8-1.8,1.8-1.8H69.1z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M80.2,25.8v70.9c0,1.9-1.5,3.4-3.4,3.4H28.1
	c-1.9,0-3.4-1.5-3.4-3.4V41l18.5-18.5h33.6C78.7,22.5,80.2,24,80.2,25.8z"
    />
    <path
      opacity="0.15"
      fill="#9199A5"
      d="M78.6,96.5H26.2c-0.9,0-1.5,0.7-1.5,1.5v0.6c0,0.9,0.7,1.5,1.5,1.5h52.4
	c0.9,0,1.5-0.7,1.5-1.5V98C80.2,97.1,79.5,96.5,78.6,96.5z"
    />
    <path
      opacity="0.15"
      fill="#9199A5"
      d="M24.7,42.8l0-1.8l18.5-18.5l1.9,0l0,18.6c0,1-0.8,1.7-1.7,1.7L24.7,42.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M25,40.7l17.9-17.9c0.1-0.1,0.3,0,0.3,0.1
	l0,17.9c0,0.1-0.1,0.2-0.2,0.2l-17.9,0C25,41,24.9,40.8,25,40.7z"
    />
    <g opacity="0.5">
      <path
        fill="#F1F2F4"
        d="M69.1,66.8v18.5H68c-0.3,0-0.6-0.3-0.6-0.6V67.4c0-1.5-1.2-2.6-2.6-2.6H21.5c-0.2,0-0.4-0.1-0.5-0.4
		c0-0.1,0-0.1,0-0.2v-2.1c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0.1-0.1c0.2,0.8,0.9,1.4,1.8,1.4h42.6C67.5,63.2,69.1,64.8,69.1,66.8z"
      />
    </g>
    <path
      opacity="0"
      fill="#F1F2F4"
      d="M69.1,66.8v18.5H68c-0.3,0-0.6-0.3-0.6-0.6V67.4c0-1.5-1.2-2.6-2.6-2.6H21.5
	c-0.2,0-0.4-0.1-0.5-0.4v-3.1c0,0.2,0,0.3,0.1,0.5c0.2,0.8,0.9,1.4,1.8,1.4h42.6C67.5,63.2,69.1,64.8,69.1,66.8z"
    />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="85.2"
      cy="68.7"
      r="31.4"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="85.2"
      cy="68.7"
      r="25.2"
    />
    <circle fill="#CACFD6" cx="85.2" cy="68.7" r="24" />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="85.2"
      cy="68.7"
      r="23.1"
    />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M89.2,78.9l-5.1-7.3l-5,7.3
		c-0.1,0.1-0.2,0.2-0.3,0.2H74c-0.3,0-0.5-0.3-0.3-0.6l7.5-10.3l-7.1-10c-0.2-0.2,0-0.6,0.3-0.6h4.8c0.1,0,0.2,0.1,0.3,0.2l4.8,6.8
		l4.7-6.8c0.1-0.1,0.2-0.2,0.3-0.2h4.5c0.3,0,0.5,0.3,0.3,0.6L87,68l7.6,10.5c0.2,0.2,0,0.6-0.3,0.6h-4.8
		C89.3,79.1,89.2,79,89.2,78.9z"
      />
    </g>
    <path
      opacity="0.15"
      fill="#9199A5"
      d="M69.1,68.7v18.6c0,2-1.6,3.7-3.7,3.7H24.7V65h40.7C67.5,65,69.1,66.6,69.1,68.7z"
    />
    <path
      fill="#0C7FF2"
      stroke="#2750AE"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M69.1,66.8v18.6c0,2-1.6,3.7-3.7,3.7h-43
	c-0.8,0-1.4-0.6-1.4-1.4V61.3c0,1,0.8,1.8,1.8,1.8h42.6C67.5,63.2,69.1,64.8,69.1,66.8z"
    />
    <g>
      <path
        fill="#F6F6F9"
        d="M34,72.7h-3.3L30,74.3h-1.7l3.2-7.2h1.6l3.2,7.2h-1.7L34,72.7z M33.5,71.4l-1.1-2.8l-1.1,2.8H33.5z"
      />
      <path
        fill="#F6F6F9"
        d="M43.4,69.7c0,1.6-1.2,2.6-3.1,2.6h-1.5v2h-1.7v-7.2h3.1C42.2,67.1,43.4,68.1,43.4,69.7z M41.7,69.7
		c0-0.8-0.5-1.3-1.5-1.3h-1.4v2.5h1.4C41.2,70.9,41.7,70.5,41.7,69.7z"
      />
      <path fill="#F6F6F9" d="M44.5,67.1h1.7v7.2h-1.7V67.1z" />
      <path
        fill="#F6F6F9"
        d="M28.7,82.6l0.6-1.3c0.6,0.4,1.5,0.8,2.4,0.8c1,0,1.4-0.3,1.4-0.8c0-1.3-4.2-0.4-4.2-3.1c0-1.2,1-2.2,3-2.2
		c0.9,0,1.8,0.2,2.5,0.6l-0.5,1.3c-0.7-0.4-1.4-0.6-2-0.6c-1,0-1.4,0.4-1.4,0.8c0,1.3,4.2,0.4,4.2,3.1c0,1.2-1,2.2-3.1,2.2
		C30.5,83.4,29.3,83.1,28.7,82.6z"
      />
      <path
        fill="#F6F6F9"
        d="M42,78.7c0,1.6-1.2,2.6-3.1,2.6h-1.5v2h-1.7v-7.2h3.1C40.8,76.1,42,77.1,42,78.7z M40.4,78.7
		c0-0.8-0.5-1.3-1.5-1.3h-1.4V80h1.4C39.8,80,40.4,79.5,40.4,78.7z"
      />
      <path
        fill="#F6F6F9"
        d="M48.8,82v1.3h-5.6v-7.2h5.4v1.3h-3.8V79h3.3v1.3h-3.3V82H48.8z"
      />
      <path
        fill="#F6F6F9"
        d="M49.6,79.7c0-2.2,1.7-3.7,3.9-3.7c1.2,0,2.3,0.5,3,1.3l-1.1,1c-0.5-0.6-1.1-0.8-1.8-0.8
		c-1.3,0-2.3,0.9-2.3,2.3s1,2.3,2.3,2.3c0.7,0,1.3-0.3,1.8-0.9l1.1,1c-0.7,0.8-1.7,1.3-3,1.3C51.3,83.4,49.6,81.9,49.6,79.7z"
      />
    </g>
  </SVGIcon>
);

export const NotFoundLightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M62.9,38.8V93c0,1.4-1.2,2.6-2.6,2.6H23.1
	c-1.4,0-2.6-1.2-2.6-2.6V50.3l14.1-14.1h25.7C61.8,36.2,62.9,37.3,62.9,38.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M73,44.2v56.5c0,1.5-1.2,2.7-2.7,2.7H31.5
	c-1.5,0-2.7-1.2-2.7-2.7V56.3l14.7-14.7h26.8C71.8,41.5,73,42.7,73,44.2z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M29.1,56l14.3-14.3c0.1-0.1,0.2,0,0.2,0.1
	l0,14.3c0,0.1-0.1,0.1-0.1,0.1l-14.3,0C29,56.3,29,56.1,29.1,56z"
    />
    <rect
      x="33.6"
      y="63.8"
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="33.2"
      height="4.6"
    />
    <rect
      x="33.6"
      y="72.5"
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="33.2"
      height="4.6"
    />
    <rect
      x="33.6"
      y="82.1"
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="33.2"
      height="4.6"
    />
    <path
      fill="#0C7FF2"
      stroke="#2750AE"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M97.9,93.9l-14.6-29
	c9.5-6.5,12.9-19.2,7.6-29.7C85.1,23.7,71,19.1,59.5,24.9C48,30.7,43.4,44.7,49.2,56.2c5.3,10.5,17.6,15.3,28.4,11.5l14.6,29
	L97.9,93.9z M54.8,53.5c-4.3-8.5-0.9-18.8,7.6-23c8.5-4.3,18.8-0.9,23,7.6c4.3,8.4,0.9,18.8-7.6,23C69.3,65.3,59,61.9,54.8,53.5z"
    />
    <path
      fill="#3E445D"
      stroke="#1D2236"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M101.9,101.9l-5.5,2.8c-1.1,0.6-2.5,0.1-3.1-1
	l-10.6-21c-0.6-1.1-0.1-2.5,1-3.1l5.5-2.8c1.1-0.6,2.5-0.1,3.1,1l10.6,21C103.5,99.9,103.1,101.3,101.9,101.9z"
    />
  </SVGIcon>
);

/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const InfoIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '12'}
    height={props.height || '12'}
    viewBox="0 0 12 12"
  >
    <path
      transform="translate(-2 -2)"
      fill={props.fill || '#6b7e8f'}
      fillRule="evenodd"
      d="M14,8A6,6,0,1,1,8,2,6,6,0,0,1,14,8ZM8.75,11A.75.75,0,1,1,8,10.25.75.75,0,0,1,8.75,11ZM8,4.25A.75.75,0,0,0,7.25,5V8a.75.75,0,0,0,1.5,0V5A.75.75,0,0,0,8,4.25Z"
    />
  </SVGIcon>
);

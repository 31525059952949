/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const Error400DarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M88.7,60.2V76c0,0.9-0.8,1.7-1.7,1.7H68.1
		c-0.9,0-1.7-0.8-1.7-1.7V60.2H88.7z"
      />
      <path
        fill="#CACFD6"
        d="M88.7,60.2v3c-3.1,2.3-7,3.6-11.2,3.6c-4.1,0-7.9-1.3-11.1-3.5v-3.1H88.7z"
      />
      <g>
        <path
          fill="#F6F6F9"
          stroke="#9CAAB8"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M74.9,74.6H60.6c-2,13.5-13.7,23.8-27.7,23.8
			V90c10.8,0,19.6-8.8,19.6-19.6s-8.8-19.6-19.6-19.6v-8.5c14,0,25.7,10.3,27.7,23.8H74V68l-2.9,2.9L74.9,74.6z"
        />
      </g>
      <circle
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="27.2"
        cy="93.5"
        r="7.6"
      />
      <polygon
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        points="97.7,73.2 94.5,81 79.7,75 77.6,70 
		81.3,68.5 82,66.8 	"
      />

      <ellipse
        transform="matrix(0.3699 -0.9291 0.9291 0.3699 -9.8329 143.3732)"
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="100.8"
        cy="78.9"
        rx="7.6"
        ry="7.6"
      />

      <ellipse
        transform="matrix(0.3699 -0.9291 0.9291 0.3699 -9.8329 143.3732)"
        fill="#0C7FF2"
        stroke="#2750AE"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="100.8"
        cy="78.9"
        rx="4.7"
        ry="4.7"
      />
      <circle
        fill="#0C7FF2"
        stroke="#2750AE"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="27.2"
        cy="93.5"
        r="4.7"
      />
      <circle
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="27.2"
        cy="46.2"
        r="7.6"
      />
      <circle
        fill="#0C7FF2"
        stroke="#2750AE"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="27.2"
        cy="46.2"
        r="4.7"
      />
      <circle
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="77.5"
        cy="46.2"
        r="19.2"
      />
      <circle
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="77.5"
        cy="46.2"
        r="14.1"
      />
      <g>
        <path
          fill="#FF4451"
          stroke="#BA1E45"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M79.9,52.4l-3.1-4.4l-3,4.4
			c0,0.1-0.1,0.1-0.2,0.1h-2.9c-0.2,0-0.3-0.2-0.2-0.3l4.6-6.3l-4.4-6.1c-0.1-0.1,0-0.3,0.2-0.3h2.9c0.1,0,0.1,0,0.2,0.1l2.9,4.2
			l2.9-4.2c0-0.1,0.1-0.1,0.2-0.1h2.7c0.2,0,0.3,0.2,0.2,0.3l-4.3,6l4.6,6.4c0.1,0.1,0,0.3-0.2,0.3h-3C80,52.4,80,52.4,79.9,52.4z"
        />
      </g>
    </g>
  </SVGIcon>
);

export const Error400LightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <rect
      x="29.5"
      y="70.2"
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="30.1"
      height="7.4"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M55.9,82.5l0-17.2c0-0.9,1.1-1.3,1.7-0.7
	l8.6,8.6c0.4,0.4,0.4,1,0,1.4l-8.6,8.6C57,83.8,55.9,83.4,55.9,82.5z"
    />
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M118,35.9v14.3c0,1.2-1,2.2-2.2,2.2H73
		c-1.2,0-2.2-1-2.2-2.2V35.9c0-2.2,1.8-4,4-4H114C116.2,32,118,33.7,118,35.9z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M118,55.7v16c0,1.2-1,2.2-2.2,2.2H73
		c-1.2,0-2.2-1-2.2-2.2v-16c0-1.2,1-2.2,2.2-2.2h42.7C117,53.5,118,54.5,118,55.7z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M118,77.3v14.3c0,2.2-1.8,4-4,4H74.7
		c-2.2,0-4-1.8-4-4V77.3c0-1.2,1-2.2,2.2-2.2h42.7C117,75.1,118,76.1,118,77.3z"
      />
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M102.3,41.6h-1.6c-0.4,0-0.6-0.3-0.6-0.6v-1.6
		c0-0.4,0.3-0.6,0.6-0.6h1.6c0.4,0,0.6,0.3,0.6,0.6v1.6C102.9,41.3,102.6,41.6,102.3,41.6z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M107.2,41.6h-1.6c-0.4,0-0.6-0.3-0.6-0.6v-1.6
		c0-0.4,0.3-0.6,0.6-0.6h1.6c0.4,0,0.6,0.3,0.6,0.6v1.6C107.8,41.3,107.5,41.6,107.2,41.6z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M111.8,41.6h-1.6c-0.4,0-0.6-0.3-0.6-0.6v-1.6
		c0-0.4,0.3-0.6,0.6-0.6h1.6c0.4,0,0.6,0.3,0.6,0.6v1.6C112.4,41.3,112.1,41.6,111.8,41.6z"
      />
      <path
        fill="#F6F6F9"
        d="M113.6,33.2H73.7c-0.1,0-0.2-0.1-0.2-0.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h39.9c0.1,0,0.2,0.1,0.2,0.2v0.6
		C113.8,33.1,113.7,33.2,113.6,33.2z"
      />
      <path
        fill="#F6F6F9"
        d="M113.6,54.6H73.7c-0.1,0-0.2-0.1-0.2-0.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h39.9c0.1,0,0.2,0.1,0.2,0.2v0.6
		C113.8,54.5,113.7,54.6,113.6,54.6z"
      />
      <path
        fill="#F6F6F9"
        d="M113.6,76.1H73.7c-0.1,0-0.2-0.1-0.2-0.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h39.9c0.1,0,0.2,0.1,0.2,0.2v0.6
		C113.8,76,113.7,76.1,113.6,76.1z"
      />
      <path
        opacity="0.1"
        fill="#606774"
        d="M116.7,52.4H72.1c-0.1,0-0.2-0.1-0.2-0.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h44.6
		c0.1,0,0.2,0.1,0.2,0.2v0.6C116.9,52.3,116.8,52.4,116.7,52.4z"
      />
      <path
        opacity="0.1"
        fill="#606774"
        d="M116.7,73.8H72.1c-0.1,0-0.2-0.1-0.2-0.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h44.6
		c0.1,0,0.2,0.1,0.2,0.2v0.6C116.9,73.7,116.8,73.8,116.7,73.8z"
      />
      <path
        opacity="0.1"
        fill="#606774"
        d="M116.7,95.3H72.1c-0.1,0-0.2-0.1-0.2-0.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h44.6
		c0.1,0,0.2,0.1,0.2,0.2v0.6C116.9,95.2,116.8,95.3,116.7,95.3z"
      />
    </g>
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M44,52.4v40.8c0,0.7-0.3,1.3-0.8,1.8
	c-0.4,0.4-1,0.7-1.6,0.7H10.7c-0.3-0.4-0.5-0.9-0.8-1.3c-2.9-5.2-5.1-10.9-6.4-17c-0.2-0.9-0.4-1.9-0.5-2.8
	c-0.1-0.6-0.2-1.2-0.3-1.8c-0.1-0.9-0.2-1.9-0.3-2.8c-0.1-0.6-0.1-1.2-0.1-1.8c-0.1-0.9-0.1-1.9-0.1-2.8c0-0.5,0-0.9,0-1.4
	c0-0.1,0-0.3,0-0.4c0-0.9,0-1.9,0.1-2.8c0.1-1.1,0.1-2.1,0.2-3.2c0.3-2.6,0.7-5.2,1.3-7.7h37.9C42.9,49.9,44,51,44,52.4z"
    />
    <path
      fill="#20D9D2"
      stroke="#06A09B"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M44,52.4v5.3H2.4c0.3-2.6,0.7-5.2,1.3-7.7h37.9
	C42.9,49.9,44,51,44,52.4z"
    />
    <path
      fill="#3E445D"
      stroke="#1D2236"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M42,95.6H18.5V57.4h25.5v36
	C44.1,94.6,43.1,95.6,42,95.6z"
    />
    <path fill="#9199A5" d="M16.1,60.8v2.8h-14c0-0.9,0-1.9,0.1-2.8H16.1z" />
    <path
      fill="#9199A5"
      d="M16.1,65.4v2.8H2.2c-0.1-0.9-0.1-1.9-0.1-2.8H16.1z"
    />
    <path fill="#9199A5" d="M16.1,70v2.8H2.7c-0.1-0.9-0.2-1.9-0.3-2.8H16.1z" />
    <path
      fill="#9199A5"
      d="M16.1,74.5v2.8H3.5c-0.2-0.9-0.4-1.9-0.5-2.8H16.1z"
    />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="44.5"
      cy="48.1"
      r="14.5"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="44.5"
      cy="48.1"
      r="10.7"
    />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M46.4,52.8L44,49.4l-2.3,3.4
		c0,0-0.1,0.1-0.1,0.1h-2.2c-0.1,0-0.2-0.2-0.1-0.3l3.5-4.8l-3.3-4.6c-0.1-0.1,0-0.3,0.1-0.3h2.2c0.1,0,0.1,0,0.1,0.1l2.2,3.1
		l2.2-3.1c0,0,0.1-0.1,0.1-0.1h2.1c0.1,0,0.2,0.2,0.1,0.3l-3.3,4.5l3.5,4.9c0.1,0.1,0,0.3-0.1,0.3h-2.2
		C46.4,52.8,46.4,52.8,46.4,52.8z"
      />
    </g>
    <circle fill="none" cx="63.9" cy="64" r="61.5" />
  </SVGIcon>
);

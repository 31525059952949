import { SVGIcon, SVGIconProps } from '../SvgIcon';
export const CrossIconSmall = (props: SVGIconProps) => (
  <SVGIcon viewBox="0 0 512 512" fill={props.fill}>
    <title>Close</title>
    <path
      d="M289.94 256l95-95A24 24 0 00351 127l-95 
    95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"
    />
  </SVGIcon>
);

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const EllipsisIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '20'}
    height={props.height || '10'}
    viewBox={props.viewBox || '0 0 20 10'}
    fill={props.fill || 'none'}
  >
    <rect width="20" height="10" rx="2" fill="#EBF0F5" />
    <circle cx="5.5" cy="5" r="1.5" fill="#6B7E8F" />
    <circle cx="10" cy="5" r="1.5" fill="#6B7E8F" />
    <circle cx="14.5" cy="5" r="1.5" fill="#6B7E8F" />
  </SVGIcon>
);

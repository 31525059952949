import React, { useCallback, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';

import { baseRules as cssBaseRules, Div, Em } from './CleanSlate';
import {
  Container,
  FlexParent,
  FlexParentMixin,
  ResizeEffectMixin,
  ScrollableMixin,
  ScrollableMixinInner,
} from './StyledElements';
import { RouteToScrollSync, ScrollContainer } from './Scroll';
import { GuidedWalkthroughBottomBar } from './GuidedWalkthroughBottomBar';
import { getSteps, WorkflowState } from './Context/WorkflowContext';
import { getWalkthroughContentType } from './Utilities/utility';
import TableOfContent, {
  TOCProps,
} from './TableOfContent/TableOfContentComponent';
import { SidebarLink, SidebarWrapper } from './Sidebar/SidebarSkeleton';
import { Language, PortalSettings } from './PortalSettings';
import { AIChatbot } from './aichatbot/AIChatbot';
import { markdownRenderer } from './JSchemaForm';
/**
 * Container for app
 *
 * Extends in all directions and is a flex parent.
 */

interface LayoutProps {
  hasWorkflow?: boolean;
  hasCodeSamples?: boolean;
  isLoadingMain?: boolean;
  containerRef?: React.Ref<HTMLDivElement>;
}
const AppLayoutContainer = styled(Container)`
  ${cssBaseRules};
  ${FlexParentMixin};
  ${ResizeEffectMixin};
  min-width: 320px;
  border-top: 2px solid ${(props) => props.theme.primaryColor};
`;
AppLayoutContainer.displayName = 'AppLayoutContainer';

/**
 * Container for fixed-height top navigation (used as an alternative to AppLayoutNav)
 */
const AppLayoutTopMenu = styled(Div)`
  height: 43px;
  background: #fff;
  box-shadow: 0px 5px 5px -4px rgba(0, 0, 0, 0.16);
  z-index: 98;
`;
AppLayoutTopMenu.displayName = 'AppLayoutTopMenu';

/**
 * Container for main items including Side bar, main content and code bar
 */
const AppLayoutMain = styled(FlexParent)<{
  isLoading?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${(props) => props.theme.staticColors.Red.C400};

  filter: blur(
    ${({ isLoading }: { isLoading?: boolean }) => (isLoading ? '1px' : '0')}
  );
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`;
AppLayoutMain.displayName = 'AppLayoutMain';

/**
 * Container for fixed-width side navigation
 */
export const AppLayoutSide = styled(Div)`
  background-color: ${(props) => props.theme.colors.C200};
  border-right: 1px solid ${({ theme }) => theme.colors.C300};

  min-width: 272px;

  @media screen and (max-width: 1200px) {
    flex: 0;
    min-width: 242px;
  }

  @media screen and (max-width: 990px) {
    max-width: 100%;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    position: static;
    flex: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.C300};
  }
  @media screen and (max-width: 575px) {
    padding: 0;
    height: 50px;
  }
`;
AppLayoutSide.displayName = 'AppLayoutSide';

const AppLayoutDocsWrapper = styled(Div)<{ hasWorkflow?: boolean }>`
  ${ScrollableMixin};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: ${({ hasWorkflow }) => (hasWorkflow ? 'visible' : 'inherit')};
`;
AppLayoutDocsWrapper.displayName = 'AppLayoutDocs';

const LanguageSelectorWrapper = styled(Div)`
  height: 84px;
  @media screen and (max-width: 990px) {
    display: none;
  }
`;

const AppLayoutDocs = styled(ScrollContainer)<LayoutProps>`
  ${ScrollableMixin};
  background-color: ${({ theme }) => theme.colors.C000};
  width: 100%;
  height: 100%;
  display: flex;

  @media screen and (max-width: 990px) {
    &.app-layout-docs-container {
      height: 100%;
      padding-bottom: ${({ hasWorkflow }) => (hasWorkflow ? '52px' : '0px')};
      display: block;
      overflow: auto;
      position: relative;
    }
  }
`;
AppLayoutDocs.displayName = 'AppLayoutDocs';

const AppLayoutContent = styled(Div)`
  * {
    ${ScrollableMixinInner};
  }
  flex: 1.5;
`;

/**
 * Container for side bar used for code in two and three-column layout.
 */
const AppLayoutCodeBox = styled(Div)`
  background: ${(props) => props.theme.colors.C900};
  height: calc(100% - 94px);
  border-radius: 8px;
  width: 100%;

  &:empty {
    display: none;
  }

  @media screen and (max-width: 990px) {
    height: 488px;
    margin: 0 5% 60px;
    border-radius: 6px;
  }
`;
AppLayoutCodeBox.displayName = 'AppLayoutCodeBox';

const SideContainer = styled(Div)<LayoutProps>`
  padding: 0px 20px;
  flex: 1;

  @media screen and (min-width: 990px) {
    max-width: 535px;
  }
  > div {
    @media screen and (min-width: 990px) {
      max-width: 515px;
    }
  }
  @media screen and (max-width: 990px) {
    padding: ${({ hasCodeSamples, hasWorkflow }) =>
      hasCodeSamples || hasWorkflow ? '0 40px 40px' : '0'};
    flex: ${({ hasCodeSamples }) => (!hasCodeSamples ? 'none' : '1 1 0%')};
    position: ${({ hasCodeSamples }) =>
      !hasCodeSamples ? 'static' : 'sticky'};

    .AppLayoutCodeBox {
      margin: 0px;
    }
  }
`;

/**
 * Holds element ref for Code box (sidebar for code)
 */
const CodeBoxContext = React.createContext<Element | undefined>(undefined);

export interface AppLayoutProps {
  portalSettings: PortalSettings;
  language: Language;
  // nav: JSX.Element;
  sidebar?: JSX.Element;
  main: JSX.Element;
  languageSelector?: JSX.Element;
  isLoading?: boolean;
  layout?: 'threeColumn' | 'twoColumn' | 'oneColumn';
  workflowName?: string;
  workflowSteps?: WorkflowState[string];
  TOCContent: TOCProps;
  ScrollContainerRef?: React.RefObject<HTMLDivElement>;
  hasCodeSamplesBox?: boolean;
}

/**
 * Create a one, two or three column responsive layout
 */
export function AppLayout(props: AppLayoutProps) {
  const [codeboxRef, setCodeboxRef] = useState<HTMLDivElement | undefined>();

  const {
    workflowName,
    workflowSteps,
    isLoading,
    sidebar,
    main,
    languageSelector,
    TOCContent: { section, activeList },
    language,
    portalSettings,
    ScrollContainerRef,
    hasCodeSamplesBox,
  } = props;

  const sideContainerRef = useRef<HTMLDivElement>(null);
  const layoutContainerRef = useRef<HTMLDivElement>(null);
  const layoutDocsContainerRef = useRef<HTMLDivElement>(null);

  /**
   * Called by AppLayoutCodeBox with ref. This is needed to render
   * an element in the code sidebar using React portals.
   */
  const codeBoxInnerRefCallback = useCallback((x: HTMLDivElement) => {
    setCodeboxRef(x);
  }, []);

  const hasWorkflow = !!workflowName;
  const isLoadingMain = Boolean(!languageSelector); // This informs if the page is reloaded instead of switching the language
  return (
    <AppLayoutErrorBoundary
      workflowName={workflowName}
      workflowSteps={workflowSteps}
    >
      <AppLayoutContainer>
        <AppLayoutMain isLoading={isLoading} ref={layoutContainerRef}>
          {sidebar && <AppLayoutSide>{sidebar}</AppLayoutSide>}
          <AppLayoutDocsWrapper hasWorkflow={hasWorkflow}>
            <RouteToScrollSync containerId="app-layout-docs-container">
              <AppLayoutDocs
                id="app-layout-docs-container"
                hasWorkflow={hasWorkflow}
                hasCodeSamples={hasCodeSamplesBox}
                isLoadingMain={isLoadingMain}
                className={
                  hasCodeSamplesBox || hasWorkflow
                    ? 'app-layout-docs-container'
                    : ''
                }
                containerRef={layoutDocsContainerRef}
              >
                <CodeBoxContext.Provider value={codeboxRef}>
                  <AppLayoutContent className="AppLayoutContent">
                    {main}
                  </AppLayoutContent>
                </CodeBoxContext.Provider>
                {languageSelector && (
                  <SideContainer
                    className="app-layout-side-container"
                    ref={sideContainerRef}
                    hasCodeSamples={hasCodeSamplesBox}
                    hasWorkflow={hasWorkflow}
                  >
                    <LanguageSelectorWrapper className="app-layout-language-selector">
                      {languageSelector}
                    </LanguageSelectorWrapper>
                    <>
                      {!isLoading ? (
                        <TableOfContent
                          section={section}
                          hasWorkFlow={hasWorkflow}
                          sideContainerRef={sideContainerRef}
                          activeList={activeList}
                          layoutDocsContainerRef={layoutDocsContainerRef}
                          ScrollContainerRef={ScrollContainerRef}
                        />
                      ) : (
                        <SidebarWrapper forTOC={true}>
                          <SidebarLink forTOC={true} />
                          <SidebarLink forTOC={true} />
                          <SidebarLink forTOC={true} />
                          <SidebarLink forTOC={true} />
                        </SidebarWrapper>
                      )}
                      <AppLayoutCodeBox
                        ref={codeBoxInnerRefCallback}
                        className="AppLayoutCodeBox"
                      />
                      <AIChatbot
                        apikey={portalSettings.apiKey}
                        language={language}
                        portalSettings={portalSettings}
                        layoutContainerRef={layoutContainerRef}
                        markdownRenderer={markdownRenderer}
                      />
                    </>
                  </SideContainer>
                )}
              </AppLayoutDocs>
              {hasWorkflow && <GuidedWalkthroughBottomBar />}
            </RouteToScrollSync>
          </AppLayoutDocsWrapper>
        </AppLayoutMain>
      </AppLayoutContainer>
    </AppLayoutErrorBoundary>
  );
}

/**
 * Shows children in the code sidebar (via portal)
 */
export function CodeBox({ children }: { children: React.ReactNode }) {
  return (
    <CodeBoxContext.Consumer>
      {(value) =>
        value ? (
          ReactDOM.createPortal(children, value)
        ) : (
          <Em>Failed to show code</Em>
        )
      }
    </CodeBoxContext.Consumer>
  );
}

interface ErrorBoundaryProps {
  workflowName?: string;
  workflowSteps?: WorkflowState[string];
}

class AppLayoutErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>
> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { workflowSteps, workflowName } = this.props;

    if (workflowName && workflowSteps) {
      const { selectedStepName, selectedStepValue } = getSteps(workflowSteps);

      const stepType = getWalkthroughContentType(selectedStepValue?.isContent);

      // throw accepts error object so had to assign
      throw Object.assign(new Error(error.message), {
        workflowName,
        selectedStepName,
        stepType,
      });
    } else {
      throw error;
    }
  }
  render() {
    return this.props.children;
  }
}

import { P } from 'src/CleanSlate';
import { ChevronIcon } from 'src/Icons/Ui/ChevronIcon';
import { MenuSelect as StepsSelect } from 'src/Menu/RoutedMenu';
import { useTheme } from 'styled-components';

interface SelectStepProps {
  selectedStepName?: string;
  selectedStepDescription?: string;
  showWorkflowSteps: boolean;
  onSelectStepClick: () => void;
}
export function SelectSteps(props: SelectStepProps) {
  const {
    selectedStepName,
    selectedStepDescription,
    showWorkflowSteps,
    onSelectStepClick,
  } = props;

  const theme = useTheme();

  return (
    <StepsSelect onClick={onSelectStepClick}>
      <P>
        <b>{selectedStepName}</b>
        {`: ${selectedStepDescription}`}
      </P>
      <ChevronIcon
        viewBox="0 0 24 24"
        width="12px"
        fill="none"
        stroke={theme.colors.C901}
        transform={showWorkflowSteps ? 'rotate(180)' : ''}
      />
    </StepsSelect>
  );
}

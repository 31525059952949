import { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { Div, Span, Button } from './CleanSlate';
import { colors, staticColors } from './Color';
import { getSteps, WorkflowContext } from './Context/WorkflowContext';
import { useWindowSize } from './custom-hooks';
import { ArrowRightIcon } from './Icons/Ui/ArrowRightIcon';
import { CrossIconSmall } from './Icons/Ui/CrossIconSmall';
import { HazardIcon } from './Icons/Ui/HazardIcon';
import { LinkMapperContext } from './LinkMapperContext';
import { HandleOutsideClick } from './HandleOutsideClick';

const BottomBarWrapper = styled(Div)`
  width: 100%;
  padding: 10px 20px 10px 40px;
  background-color: ${(props) => props.theme.colors.C001};
  border-top: 1px solid ${(props) => props.theme.colors.C300};
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1500px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 1350px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 990px) {
    position: fixed;
    bottom: 0px;
    max-width: 100%;
    border-radius: 0;
    padding-right: 40px;
  }
`;

const spanMixin = css`
  font-size: 14px;
  margin: 0px;
  padding-left: 2px 0px 0px 0px;

  &:hover {
    cursor: pointer;
  }
`;

const ResetStep = styled(Button)`
  ${spanMixin};
  color: ${(props) => props.theme.staticColors.Blue.C000};
  border: 1px solid ${(props) => props.theme.staticColors.Blue.C000};
  :disabled {
    cursor: default;
    opacity: 0.2;
  }
`;

const Next = styled(Span)`
  margin: 0px 5px 0px 0px;
  padding: 0px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.C001};
  font-weight: 300;
  cursor: inherit;
`;

const ButtonContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NextContainer = styled(Button)<{
  isBlueDisabled?: boolean;
}>`
  background-color: ${(props) => props.theme.staticColors.Blue.C000};
  border-radius: 8px;
  min-width: 99px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  color: ${(props) => props.theme.colors.C001};
  cursor: pointer;
  &:not(:hover) {
    opacity: 1;
  }
  :disabled {
    cursor: default;
    background-color: ${({ isBlueDisabled, theme }) =>
      isBlueDisabled
        ? theme.staticColors.Blue.C000
        : theme.staticColors.Red.C000};
    opacity: 0.5;
  }
  svg {
    position: relative;
    top: -1px;
    margin-left: 2px;
  }
`;

export const ErrorContainer = styled(Div)`
  padding: 15px 30px 15px 15px;
  border-radius: 0px 8px 8px 0px;
  background-color: ${(props) => props.theme.staticColors.Red.C600};
  position: absolute;
  bottom: 55px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  min-width: 362px;

  &:after {
    content: '';
    position: absolute;
    height: 0px;
    top: 100%;
    right: 32px;
    border: 12px solid transparent;
    border-top-width: 16px;
    border-top-color: ${(props) => props.theme.staticColors.Red.C600};
    filter: drop-shadow(
      0px 2px 0px ${(props) => props.theme.staticColors.Unique.C000}
    );
  }
`;

const ErrorMessageContainer = styled(Div)`
  max-width: 290px;
  line-height: 16px;
  height: fit-content;
`;

const LeftBar = styled(Div)`
  height: 100%;
  width: 8px;
  border-radius: 8px 0px 0px 8px;
  background-color: ${(props) => props.theme.staticColors.Orange.C000};
  position: absolute;
  left: -8px;
`;

const ErrorMessage = styled(Span)`
  color: ${(props) => props.theme.colors.C901};
  font-size: 13px;
  line-height: 16px;
`;

const ClickMessage = styled(Span)`
  color: ${(props) => props.theme.staticColors.Blue.C000};
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }
`;

const CrossIconContainer = styled(Div)`
  height: 20px;
  width: 20px;
  margin-right: 8px;
  display: flex;
  align-self: flex-start;
`;

const CrossIconSmallContainer = styled(Div)`
  height: 15px;
  width: 15px;
  position: absolute;
  top: 3px;
  right: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export function GuidedWalkthroughBottomBar() {
  const [showErorr, setShowError] = useState(false);

  const { getSelectedWorkflow, onResetClick, onNextClick } =
    useContext(WorkflowContext);
  const linkMapper = useContext(LinkMapperContext);

  const { workflowName = '', workflowSteps } = getSelectedWorkflow(linkMapper);
  const {
    selectedStepName = '',
    selectedStepValue,
    nextStepName,
  } = getSteps(workflowSteps);
  const { responseTriggerTime } = (selectedStepValue?.state || {}) as {
    responseTriggerTime?: number;
  };
  const onResetButtonClick = useCallback(() => {
    onResetClick?.(workflowName);
  }, [onResetClick, workflowName]);

  const onNextButtonClick = useCallback(() => {
    onNextClick?.(workflowName);
  }, [onNextClick, workflowName]);

  const errorMessage = selectedStepValue?.error;

  useEffect(() => {
    setShowError(false);
  }, [selectedStepName]);

  useEffect(() => {
    if (selectedStepValue?.error) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [selectedStepValue?.error, responseTriggerTime]);

  const RenderedElement = (
    <BottomBarWrapper>
      <ResetStep
        onClick={onResetButtonClick}
        disabled={
          !selectedStepValue?.status ||
          selectedStepValue?.status === 'incomplete'
        }
      >
        Reset Step
      </ResetStep>

      <ButtonContainer>
        {showErorr && (
          <ErrorPopup setShowError={setShowError} errorMessage={errorMessage} />
        )}
        <NextContainer
          onClick={onNextButtonClick}
          disabled={
            selectedStepValue?.status === 'incomplete' ||
            (selectedStepValue?.status === 'complete' && !nextStepName) ||
            (selectedStepValue?.status === 'current' &&
              !selectedStepValue?.verified)
          }
          isBlueDisabled={selectedStepValue?.status !== 'current' || !showErorr}
        >
          <Next>{nextStepName ? 'NEXT' : 'FINISH'}</Next>
          {nextStepName && <ArrowRightIcon />}
        </NextContainer>
      </ButtonContainer>
    </BottomBarWrapper>
  );

  const screenSize = useWindowSize();

  return screenSize.width < 990
    ? createPortal(RenderedElement, document.body)
    : RenderedElement;
}

export const ErrorPopup = ({
  setShowError,
  errorMessage,
  fromCodeBlock,
  onRunButton,
}: {
  setShowError: (x: boolean) => void;
  errorMessage?: string;
  fromCodeBlock?: true;
  onRunButton?: () => void;
}) => {
  return (
    <HandleOutsideClick
      onOutsideClick={() => {
        setShowError(false);
      }}
    >
      <ErrorContainer>
        <CrossIconSmallContainer
          onClick={() => {
            setShowError(false);
          }}
        >
          <CrossIconSmall fill={colors.grayCool.C901} />
        </CrossIconSmallContainer>
        <CrossIconContainer>
          <HazardIcon
            width="20"
            height="20"
            viewBox="0 0 16 17"
            stroke={staticColors.Orange.C000}
          />
        </CrossIconContainer>
        <ErrorMessageContainer>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          {fromCodeBlock && (
            <ClickMessage
              onClick={() => {
                if (onRunButton) onRunButton();
              }}
            >
              {` Click here to continue`}
            </ClickMessage>
          )}
        </ErrorMessageContainer>
        <LeftBar />
      </ErrorContainer>
    </HandleOutsideClick>
  );
};

import styled from 'styled-components';

import { Div } from '../CleanSlate';

import { HeadersTable } from './HeadersTable';
import { ResponseViewerError } from './ResponseViewerError';
import { ProxyResponseInterface } from './ProxyResponseInterface';
import { ResponseInfoAlert } from './ResponseInfoAlert';
import { ResponseViewerChildrenProps } from './ResponseViewerChildrenProps';
import { ResponseViewerErrorBoundary } from './ResponseViewerErrorBoundary';
import { ResponseViewerMux } from './ResponseViewerMux';
import { ResponseTabs, TabItem } from './ResponseTabs';
import { Scrollable } from '../StyledElements';
import { PortalSettings } from 'src/PortalSettings';

/**
 * Response viewer container
 *
 * Stretches in all directions. No scroll.
 */
const HttpResponseViewerWrapper = styled(Div)`
  flex: 1;
  pre {
    color: ${(props) => props.theme.colors.C100};
  }
`;
HttpResponseViewerWrapper.displayName = 'HttpResponseViewerWrapper';

const BodyResponseViewer = styled(Scrollable)`
  overflow: auto;
  height: calc(100% - 47px);
`;
BodyResponseViewer.displayName = 'BodyResponseViewer';

export interface ResponseViewerProps {
  response: ProxyResponseInterface;
  onRetry?: VoidFunction;
  onBack?: VoidFunction;
  children: (props: ResponseViewerChildrenProps) => JSX.Element;
  isProxy: boolean;
  onHeaderClick: (isHeader: boolean) => void;
  portalSettings: PortalSettings;
}

/**
 * Viewer for endpoint response from console
 * @param props
 */
export function ResponseViewer(props: ResponseViewerProps) {
  const { response, onRetry, onBack, isProxy, onHeaderClick, portalSettings } =
    props;

  if (!response.IsCalled) {
    return props.children({
      responseViewer: (
        <ResponseViewerError
          isProxy={isProxy}
          response={response}
          onRetry={onRetry}
          onBack={onBack}
        />
      ),
    });
  }

  let bodyViewer: ResponseViewerChildrenProps;

  // get body viewer component and wrap it with an error boundary
  try {
    bodyViewer = ResponseViewerMux({ response, portalSettings });
    bodyViewer = {
      ...bodyViewer,
      responseViewer: (
        <ResponseViewerErrorBoundary>
          {bodyViewer.responseViewer}
        </ResponseViewerErrorBoundary>
      ),
    };
  } catch (e) {
    bodyViewer = {
      responseViewer: (
        <ResponseInfoAlert>Could not show the response body.</ResponseInfoAlert>
      ),
    };
  }

  // create complete tabbed view
  const wrappedBodyViewer = (
    <HttpResponseViewerWrapper>
      <ResponseTabs
        defaultActiveIndex={0}
        onHeaderClick={onHeaderClick}
        portalSettings={portalSettings}
      >
        <TabItem title="Body">
          <BodyResponseViewer scrollWidth="4px">
            {bodyViewer.responseViewer}
          </BodyResponseViewer>
        </TabItem>
        <TabItem title="Headers">
          <BodyResponseViewer scrollWidth="4px">
            <HeadersTable
              data={Object.keys(response.Headers).map((f) => {
                return { name: f, value: response.Headers[f] };
              })}
            />
          </BodyResponseViewer>
        </TabItem>
      </ResponseTabs>
    </HttpResponseViewerWrapper>
  );

  return props.children({
    ...bodyViewer,
    responseViewer: wrappedBodyViewer,
  });
}

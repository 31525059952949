import React, { createContext, useState, ReactNode, useCallback } from 'react';
import { TOCProps } from './TableOfContentComponent';

// Create a default value for the context
const defaultTOCProps: TOCProps = {
  section: undefined,
  hasWorkFlow: false,
  activeList: [],
};

// Create the context with the default value
const TOCContext = createContext<{
  tocProps: TOCProps;
  updateTOCProps: (props: Partial<TOCProps>) => void;
}>({
  tocProps: defaultTOCProps,
  updateTOCProps: () => {},
});

// Create the provider component
export const TOCProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [tocProps, setTOCProps] = useState<TOCProps>(defaultTOCProps);

  const updateTOCProps = useCallback((props: Partial<TOCProps>) => {
    setTOCProps((prevProps) => ({
      ...prevProps,
      ...props,
    }));
  }, []);

  return (
    <TOCContext.Provider value={{ tocProps, updateTOCProps }}>
      {children}
    </TOCContext.Provider>
  );
};

// Custom hook to use the TOCContext
export const TOCContextConsumer = TOCContext.Consumer;

import React, { useState } from 'react';
import styled from 'styled-components';

import { Div } from '../CleanSlate';
import { PortalSettings } from 'src/PortalSettings';
import { TabsInteractionEvent } from 'src/Analytics';

const ResponsiveTabs = styled(Div)`
  display: flex;
  justify-content: center;
  margin: 15px 0px;
`;

const ResponsiveTabsWrapper = styled(Div)`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.C201};
  border-radius: 8px;
  overflow: hidden;
`;

const ResponsiveTabsItem = styled(Div)<ResponsiveTabsItemProps>`
  min-width: 90px;
  padding: 2px 0px;
  text-align: center;
  font-size: 12px;
  color: ${(props) => props.theme.colors.C201};
  background: ${(props) => props.isActive && props.theme.colors.C201};
  color: ${(props) => props.isActive && props.theme.colors.C900};
  cursor: pointer;
`;

interface ResponseTabsProps {
  defaultActiveIndex?: number;
  children: React.ReactElement<TabItemProps>[];
  onHeaderClick: (isHeader: boolean) => void;
  portalSettings: PortalSettings;
}

interface TabItemProps {
  title: 'Body' | 'Headers';
  children: React.ReactNode;
}

interface TabTitleProps {
  key?: string;
  title: 'Body' | 'Headers';
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  onHeaderClick: (isHeader: boolean) => void;
  portalSettings: PortalSettings;
}

interface ResponsiveTabsItemProps {
  isActive?: boolean;
}

export function ResponseTabs(props: ResponseTabsProps) {
  const { defaultActiveIndex, children, onHeaderClick, portalSettings } = props;

  const [activeTab, setActiveTab] = useState<number>(defaultActiveIndex || 0);

  return (
    <React.Fragment>
      <ResponsiveTabs>
        <ResponsiveTabsWrapper>
          {children &&
            children.map((item, index) => {
              const isActive: boolean = activeTab === index;
              return (
                <TabTitle
                  key={item.props.title + index}
                  setSelectedTab={setActiveTab}
                  isActive={isActive}
                  title={item.props.title}
                  index={index}
                  onHeaderClick={onHeaderClick}
                  portalSettings={portalSettings}
                />
              );
            })}
        </ResponsiveTabsWrapper>
      </ResponsiveTabs>
      {children[activeTab].props.children}
    </React.Fragment>
  );
}

export function TabItem({ children }: TabItemProps) {
  return <div>{children}</div>;
}

function TabTitle(props: TabTitleProps) {
  const {
    title,
    isActive,
    index,
    setSelectedTab,
    onHeaderClick,
    portalSettings,
  } = props;
  return (
    <ResponsiveTabsItem
      key={title + index}
      isActive={isActive}
      onClick={() => {
        setSelectedTab(index);
        onHeaderClick(title === 'Headers');
        TabsInteractionEvent(portalSettings, title);
      }}
    >
      {title}
    </ResponsiveTabsItem>
  );
}

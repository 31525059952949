import { useState } from 'react';
import { CasesTypes, SectionNode } from './DxDom';
import styled, { css } from 'styled-components';
import { Div, P, Span } from './CleanSlate';

import { Markdown } from './Markdown';
import { CodeSelector, checkIfDataTypeIsLarge } from './CodeSelector';
import { ChevronIcon } from './Icons';
import React from 'react';

interface ExpandableCardProps {
  singleCase: CasesTypes;
  index: number;
  renderNode: (n: SectionNode) => object;
}

interface CardWrapperProp {
  isHeading: boolean;
}

const CardWrapper = styled(Div)`
  width: 100%;
  border: 1px solid black;
  height: fit-content;
  margin-bottom: 16px;
  border: 1px solid ${(props) => props.theme.staticColors.Snow.C000};
  border-radius: 8px;
  opacity: 1;
  padding: 25px;
`;

const fontMixin = css<CardWrapperProp>`
  font-weight: ${({ isHeading, theme: { cssStyles } }) =>
    isHeading ? cssStyles.body.text3.fontWeight : 'bold !important'};
  font-family: ${({ isHeading, theme: { cssStyles } }) =>
    (isHeading ? cssStyles.body.fontFamily : cssStyles.code.fontFamily) +
    '!important'};
  font-size: ${({ isHeading, theme: { cssStyles } }) =>
    isHeading
      ? cssStyles.body.text3.fontSize || '12px'
      : cssStyles.body.text1.fontSize || '16px'};
`;

const SubHeading = styled(Div)<CardWrapperProp>`
  ${fontMixin}
  margin-bottom: ${(props) => (props.isHeading ? '0px' : '12px')};
  color: ${(props) =>
    props.isHeading
      ? props.theme.staticColors.Goose.C200
      : props.theme.staticColors.Goose.C000};
  height: fit-content;
  a {
    ${fontMixin}
  }
`;

const Description = styled(P)`
  color: ${(props) => props.theme.colors.C800};
  font-family: ${({ theme: { cssStyles } }) => cssStyles.body.fontFamily};
  margin: 0px 0px 12px 0px;
`;

const MarkdownWrapper = styled(Div)`
  margin: 2px 0px 12px 0px;
`;

const TopHeading = styled(Div)`
  position: relative;
`;

const ExpandableButtonIcon = styled(Span)`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

const PillsWrapper = styled(Span)`
  border: ${(props) => `1.5px solid ${props.theme.staticColors.Goose.C100}`};
  border-radius: 3px;
  opacity: 1;
  background-color: ${(props) => props.theme.colors.C001};
  padding: 2px 8px;
  margin-right: 8px;
  cursor: pointer;

  ${Span} {
    position: relative;
    bottom: 1px;
    font-size: 11.85px;
    font-family: 'Rubik', sans-serif;
    color: ${(props) => props.theme.staticColors.Goose.C100};
    font-weight: 500;
    cursor: pointer;
  }
`;

const CollapsibleBodyWrapper = styled(Div)``;

export const ExpandableCard = (props: ExpandableCardProps) => {
  const { singleCase, renderNode } = props;

  const [isOpen, setOpen] = useState<boolean>(false);

  const setAccordionOpen = () => {
    setOpen((st) => !st);
  };

  const trimDescription = checkIfDataTypeIsLarge(
    singleCase.Description ?? '',
    75
  );

  return (
    <CardWrapper>
      <TopHeading>
        {singleCase?.Name && (
          <Div>
            <SubHeading isHeading={true}>Name</SubHeading>
            <SubHeading isHeading={false}>{singleCase.Name}</SubHeading>
          </Div>
        )}
        <ExpandableButtonIcon onClick={setAccordionOpen}>
          <ChevronIcon
            style={
              isOpen
                ? { transform: 'rotate(180deg)' }
                : { transform: 'rotate(0deg)' }
            }
            viewBox="0 0 24 24"
            width="14px"
            fill="none"
            stroke="#455666"
          />
        </ExpandableButtonIcon>
      </TopHeading>

      <SubHeading isHeading={true}>Type</SubHeading>
      <SubHeading isHeading={false}>
        <Markdown
          source={
            singleCase.LinkTo && singleCase.DataType
              ? `[${singleCase.DataType}](${singleCase.LinkTo})`
              : singleCase.DataType ?? '-'
          }
          inline={true}
        />
      </SubHeading>
      {singleCase.DiscriminatorValue && (
        <>
          <SubHeading isHeading={true}>Discriminator Value</SubHeading>
          <MarkdownWrapper>
            <Markdown
              source={'`' + singleCase.DiscriminatorValue + '`'}
              inline
            />
          </MarkdownWrapper>
        </>
      )}
      {singleCase.Description && (
        <>
          <SubHeading isHeading={true}>Description</SubHeading>
          <Description>
            {isOpen
              ? singleCase.Description
              : trimDescription || singleCase.Description}
          </Description>
        </>
      )}

      {!isOpen ? (
        <React.Fragment>
          {singleCase.MethodSignature && (
            <PillsTitle title="Method Signature" onClick={setAccordionOpen} />
          )}
          {singleCase.InitializationExamples && (
            <PillsTitle
              title="Initialization Code"
              onClick={setAccordionOpen}
            />
          )}
        </React.Fragment>
      ) : (
        <CollapsibleBodyWrapper>
          {singleCase.MethodSignature && (
            <>
              <SubHeading isHeading={true}>Method Signature</SubHeading>
              <MarkdownWrapper>
                <Markdown
                  source={'`' + singleCase.MethodSignature + '`'}
                  inline
                />
              </MarkdownWrapper>
            </>
          )}
          {singleCase.InitializationExamples && (
            <>
              <SubHeading isHeading={true}>Initialization Code</SubHeading>
              <CodeSelector
                renderNode={renderNode}
                Examples={singleCase.InitializationExamples}
                isContainerExample={false}
              />
            </>
          )}
        </CollapsibleBodyWrapper>
      )}
    </CardWrapper>
  );
};

const PillsTitle = ({
  title,
  onClick,
}: {
  title: string;
  onClick?: () => void;
}) => {
  return (
    <PillsWrapper onClick={onClick}>
      <Span>{title}</Span>
    </PillsWrapper>
  );
};

import styled from 'styled-components';
import { Div, Button } from './CleanSlate';
import { IconButtonMixin, ScrollableMixin } from './StyledElements';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from '@dx-portal/design-system';
import { useContext, useState } from 'react';
import { CopyIcon } from './Icons/Ui/CopyIcon';
import { PortalContextConsumer } from './PortalContext';
import { copiedCodeAPICoPilotEvent } from './Analytics';
import { DataModelContext } from './DataModelContext';

const ChatbotWrapper = styled(Div)`
  position: relative;

  &:hover {
    #copy-to-clipboard-tooltip {
      opacity: 0.85;
    }
  }
`;

const HighlighterWrapper = styled(Div)`
  ${ScrollableMixin};
  max-height: 500px;
  overflow: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 0px;
  background-color: ${(props) => props.theme.staticColors.Unique.C1100};

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & pre {
    overflow: visible !important;
    margin-bottom: 0 !important;
  }

  & pre > code {
    white-space: pre !important;
  }
`;

const CopyToClipboardWrapper = styled(Div)`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;
`;

const IconButton = styled(Button)`
  ${IconButtonMixin}

  &:hover {
    opacity: 1;
  }
`;

interface ChatbotCodeBoxProps {
  sourceCode: string;
  language?: string;
}

export const ChatbotCodeBox = (props: ChatbotCodeBoxProps) => {
  const [copyTooltipText, setCopyTooltipText] = useState<'Copy' | 'Copied'>(
    'Copy'
  );

  const { sourceCode, language } = props;
  const dataModelContext = useContext(DataModelContext);

  return (
    <ChatbotWrapper>
      <HighlighterWrapper>
        <SyntaxHighlighter
          style={dracula}
          language={language}
          children={String(sourceCode).replace(/\n$/, '')}
        />
      </HighlighterWrapper>
      <PortalContextConsumer>
        {(portalSettings) =>
          portalSettings && (
            <CopyToClipboard
              text={String(sourceCode)}
              onCopy={() => {
                copiedCodeAPICoPilotEvent(
                  portalSettings,
                  dataModelContext?.activeLanguage
                );
              }}
            >
              <CopyToClipboardWrapper
                id="copy-to-clipboard-tooltip"
                onClick={() => {
                  setCopyTooltipText('Copied');
                }}
                onMouseOut={() => {
                  setCopyTooltipText('Copy');
                }}
              >
                <Tooltip
                  text={copyTooltipText}
                  offset={[0, 20]}
                  children={
                    <IconButton varient="light">
                      <CopyIcon
                        width="18"
                        height="18"
                        stroke="#000"
                        fill="none"
                      />
                    </IconButton>
                  }
                />
              </CopyToClipboardWrapper>
            </CopyToClipboard>
          )
        }
      </PortalContextConsumer>
    </ChatbotWrapper>
  );
};

import styled from 'styled-components';

import { Base64 as base64 } from 'js-base64';
import { Span } from '../CleanSlate';
import {
  convertBase64ToArrayBuffer,
  tryGetFilenameFromContentDisposition,
  getHeaderValue,
  getExtensionFromContentType,
} from '../HttpUtils';
import { CodeViewer } from './CodeViewer';
import { ContentCategory, fromHeader } from './ContentCategory';
import { FileViewer } from './FileViewer';
import { ImageViewer } from './ImageViewer';
import { JsonViewer } from './JsonViewer';
import { ProxyResponseSuccessInterface } from './ProxyResponseInterface';
import { ResponseInfoAlert } from './ResponseInfoAlert';
import { ResponseViewerChildrenProps } from './ResponseViewerChildrenProps';
import { TextViewer } from './TextViewer';
import { saveAs } from 'file-saver';
import { PortalSettings } from 'src/PortalSettings';

const ResponseBodyType = styled(Span)`
  position: absolute;
  top: 45px;
  right: 5px;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.C001};
  font-size: 13px;
  padding: 6px 10px;

  @media screen and (max-width: 1100px) {
    right: 10px;
  }
`;

/**
 * Selects and shows a response viewer based on content type.
 *
 * Adds a badge for content type. Wraps viewer in error boundary too.
 *
 * @param props
 */
export function ResponseViewerMux({
  response,
  portalSettings,
}: {
  response: ProxyResponseSuccessInterface;
  portalSettings: PortalSettings;
}): ResponseViewerChildrenProps {
  // if the body is empty, show empty body message
  if (!response.RawContent) {
    return {
      responseViewer: (
        <ResponseInfoAlert>The response body was empty.</ResponseInfoAlert>
      ),
    };
  }

  const contentType = getHeaderValue(response.Headers, 'content-type');
  const cc = fromHeader(contentType);
  const onDownload = makeOnDownloadHandler(response, contentType);
  let bodyViewer = <FileViewer response={response} onDownload={onDownload} />;
  let textToCopy: string | undefined;
  let file: Promise<unknown> | undefined;

  // we're gonna check what content category it is and set the bodyViewer and textToCopy accordingly
  if (cc === ContentCategory.Image) {
    bodyViewer = <ImageViewer response={response} />;
  } else if (cc !== ContentCategory.Binary) {
    textToCopy = base64.decode(response.RawContent);

    if (cc === ContentCategory.JSON) {
      bodyViewer = (
        <JsonViewer portalSettings={portalSettings}>{textToCopy}</JsonViewer>
      );
    } else if (
      cc === ContentCategory.XML ||
      cc === ContentCategory.HTML ||
      cc === ContentCategory.YAML ||
      cc === ContentCategory.Markdown
    ) {
      bodyViewer = (
        <CodeViewer lang={ContentCategory[cc].toLowerCase()}>
          {textToCopy}
        </CodeViewer>
      );
    } else if (cc === ContentCategory.Text) {
      bodyViewer = <TextViewer>{textToCopy}</TextViewer>;
    }
  }

  // add viewer type badge
  bodyViewer = (
    <>
      {bodyViewer}
      <ResponseBodyType
        title={
          'This response body is being shown as ' + ContentCategory[cc] + '.'
        }
      >
        {ContentCategory[cc]}
      </ResponseBodyType>
    </>
  );
  if (!textToCopy && cc === ContentCategory.Binary) {
    file = rawContentToBase64Image(response, contentType);
  }

  return {
    responseViewer: bodyViewer,
    onDownload,
    textToCopy,
    file,
  };
}

function makeOnDownloadHandler(
  response: ProxyResponseSuccessInterface,
  contentType: string | undefined
) {
  return () => {
    const contentDispHeader = getHeaderValue(
      response.Headers,
      'content-disposition'
    );
    const ext = contentType && getExtensionFromContentType(contentType);
    const fileNameExt = 'download' + (ext ? '.' + ext : '');
    const fileName =
      tryGetFilenameFromContentDisposition(contentDispHeader) || fileNameExt;
    const blobPart = convertBase64ToArrayBuffer(atob(response.RawContent));
    saveAs(
      new Blob([blobPart], {
        type: contentType,
      }),
      fileName
    );
  };
}

function blobToBase64(blob: Blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}

function addNameToDataURL(dataURL: string, name: string) {
  return dataURL.replace(';base64', `;name=${name};base64`);
}

function rawContentToBase64Image(
  response: ProxyResponseSuccessInterface,
  contentType: string | undefined
) {
  const contentDispHeader = getHeaderValue(
    response.Headers,
    'content-disposition'
  );
  const ext = contentType && getExtensionFromContentType(contentType);
  const fileNameExt = 'download' + (ext ? '.' + ext : '');
  const fileName =
    tryGetFilenameFromContentDisposition(contentDispHeader) || fileNameExt;
  const blobPart = convertBase64ToArrayBuffer(atob(response.RawContent));
  const blob = new Blob([blobPart], {
    type: contentType,
  });

  const base64 = blobToBase64(blob);
  return base64.then((file) => addNameToDataURL(file as string, fileName));
}

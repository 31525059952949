import { Svg } from '../theme';
import { ThumbsUpIconProps } from './ThumbsUpIcon';

export const ThumbsDownIcon = ({
  isActive,
  backgroundColor,
  stroke,
}: ThumbsUpIconProps) => {
  return isActive ? (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.50002 15.1001L8.33335 11.6667H3.47502C3.21627 11.6667 2.96109 11.6065 2.72966 11.4908C2.49823 11.3751 2.29693 11.2071 2.14168 11.0001C1.98644 10.7931 1.88152 10.5528 1.83523 10.2982C1.78895 10.0437 1.80257 9.78181 1.87502 9.53341L3.81668 2.86675C3.91766 2.52056 4.12819 2.21645 4.41668 2.00008C4.70518 1.78371 5.05607 1.66675 5.41668 1.66675H16.6667C17.1087 1.66675 17.5326 1.84234 17.8452 2.1549C18.1578 2.46746 18.3334 2.89139 18.3334 3.33341V10.0001C18.3334 10.4421 18.1578 10.866 17.8452 11.1786C17.5326 11.4912 17.1087 11.6667 16.6667 11.6667H14.3667C14.0566 11.6669 13.7527 11.7536 13.4892 11.917C13.2257 12.0804 13.013 12.3141 12.875 12.5917L10 18.3334C9.60704 18.3285 9.22023 18.2349 8.86851 18.0596C8.51679 17.8842 8.20924 17.6317 7.96885 17.3207C7.72845 17.0098 7.56142 16.6486 7.48024 16.2641C7.39905 15.8795 7.40581 15.4816 7.50002 15.1001Z"
        fill={stroke ?? '#858BA5'}
        stroke={stroke ?? '#858BA5'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 11.6667V1.66675"
        stroke={backgroundColor ?? 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  ) : (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M14.1667 11.6667V1.66675"
        stroke={stroke ?? '#858BA5'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.50002 15.1001L8.33335 11.6667H3.47502C3.21627 11.6667 2.96109 11.6065 2.72966 11.4908C2.49823 11.3751 2.29693 11.2071 2.14168 11.0001C1.98644 10.7931 1.88152 10.5528 1.83523 10.2982C1.78895 10.0437 1.80257 9.78181 1.87502 9.53341L3.81668 2.86675C3.91766 2.52056 4.12819 2.21645 4.41668 2.00008C4.70518 1.78371 5.05607 1.66675 5.41668 1.66675H16.6667C17.1087 1.66675 17.5326 1.84234 17.8452 2.1549C18.1578 2.46746 18.3334 2.89139 18.3334 3.33341V10.0001C18.3334 10.4421 18.1578 10.866 17.8452 11.1786C17.5326 11.4912 17.1087 11.6667 16.6667 11.6667H14.3667C14.0566 11.6669 13.7527 11.7536 13.4892 11.917C13.2257 12.0804 13.013 12.3141 12.875 12.5917L10 18.3334C9.60704 18.3285 9.22024 18.2349 8.86851 18.0596C8.51679 17.8842 8.20924 17.6317 7.96885 17.3207C7.72845 17.0098 7.56142 16.6486 7.48024 16.2641C7.39905 15.8795 7.40581 15.4816 7.50002 15.1001V15.1001Z"
        stroke={stroke ?? '#858BA5'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

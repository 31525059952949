/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const Error422DarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M101,96H27c-1.3,0-2.4-1.1-2.4-2.4V60.2
	c0-1.3,1.1-2.4,2.4-2.4h74c1.3,0,2.4,1.1,2.4,2.4v33.4C103.4,94.9,102.3,96,101,96z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M101,55.2H27c-1.3,0-2.4-1.1-2.4-2.4v-8.5
	c0-1.3,1.1-2.4,2.4-2.4h74c1.3,0,2.4,1.1,2.4,2.4v8.5C103.4,54.1,102.3,55.2,101,55.2z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M61.9,40.2H27c-1.3,0-2.4-1.1-2.4-2.4v-8.5
	c0-1.3,1.1-2.4,2.4-2.4h34.9c1.3,0,2.4,1.1,2.4,2.4v8.5C64.3,39.1,63.2,40.2,61.9,40.2z"
    />
    <circle
      fill="#0C7FF2"
      stroke="#2750AE"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="65.6"
      cy="86"
      r="24.4"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="65.6"
      cy="86"
      r="17.9"
    />
    <g>
      <path
        fill="#0C7FF2"
        stroke="#2750AE"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M68.2,81.8c0-1.4-1.2-2.2-3-2.2
		c-1.8,0-3.2,0.8-4.1,2.1l-3.4-2c1.5-2.3,4.1-3.7,7.9-3.7c4.2,0,7.1,1.9,7.1,5.2c0,4.5-4.7,5-4.7,7.9h-4.2
		C63.9,85.2,68.2,84.3,68.2,81.8z M63.3,93.5c0-1.4,1.1-2.5,2.7-2.5c1.6,0,2.7,1.1,2.7,2.5c0,1.4-1.1,2.5-2.7,2.5
		C64.4,96.1,63.3,94.9,63.3,93.5z"
      />
    </g>
    <g>
      <defs>
        <circle
          id="SVGID_00000171685296077070765470000001445492370768942259_"
          cx="207.4"
          cy="64"
          r="62"
        />
      </defs>

      <g clipPath="url(#SVGID_00000158017139732982531870000018325621697327141043_)">
        <g>
          <ellipse
            opacity="0.1"
            fill="#606774"
            cx="154"
            cy="98"
            rx="35.3"
            ry="1.3"
          />
        </g>
      </g>
    </g>
  </SVGIcon>
);

export const Error422LightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M45.4,66.3L45.4,66.3c0,1.6,1.3,3,3,3h8
	c1.6,0,3-1.3,3-3v0c0-1.6-1.3-3-3-3h-8C46.7,63.3,45.4,64.7,45.4,66.3z M56.2,67.8h-7.6c-0.8,0-1.5-0.7-1.5-1.5l0,0
	c0-0.8,0.7-1.5,1.5-1.5h7.6c0.8,0,1.5,0.7,1.5,1.5l0,0C57.7,67.1,57.1,67.8,56.2,67.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M62.9,64.8h4.9l-0.5-0.5l0.9-0.9h-5.5
	c-1.6,0-3,1.3-3,3c0,1.6,1.3,3,3,3h4.2l0.3-0.3l-0.6-0.6l0.5-0.5h-4.4c-0.8,0-1.5-0.7-1.5-1.5c0-0.4,0.2-0.8,0.4-1.1
	C62.1,65,62.5,64.8,62.9,64.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M61.4,67.2h-4c-0.4,0-0.7-0.3-0.7-0.7v0
	c0-0.4,0.3-0.7,0.7-0.7h4c0.4,0,0.7,0.3,0.7,0.7v0C62.1,66.9,61.8,67.2,61.4,67.2z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M72.8,73.3L72.8,73.3c0.5,1.6,2.1,2.5,3.7,2
	l7.7-2.3c1.6-0.5,2.5-2.1,2-3.7v0c-0.5-1.6-2.1-2.5-3.7-2l-7.7,2.3C73.2,70.1,72.3,71.7,72.8,73.3z M83.6,71.7l-7.3,2.2
	c-0.8,0.2-1.7-0.2-1.9-1h0c-0.2-0.8,0.2-1.7,1-1.9l7.3-2.2c0.8-0.2,1.7,0.2,1.9,1l0,0C84.8,70.6,84.4,71.4,83.6,71.7z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M73.7,74.8c1.2,1.1,1.3,3,0.2,4.2l-2.5,2.8
	l0-0.5l-0.8,0l0-0.8l2.2-2.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.2-0.8-0.5-1c-0.6-0.6-1.6-0.5-2.1,0.1l-1.8,2.1l-0.7,0l-0.1-1.3
	l1.7-1.9c0.5-0.6,1.3-0.9,2-1C72.3,74,73.1,74.2,73.7,74.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M75.6,74.1l-2.3,2.3c-0.4,0.4-1,0.4-1.4,0v0
	c-0.4-0.4-0.4-1,0-1.4l2.3-2.3c0.4-0.4,1-0.4,1.4,0l0,0C76,73,76,73.7,75.6,74.1z"
    />
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M52.3,42.4v11.8c0,1-0.8,1.8-1.8,1.8H15.4
		c-1,0-1.8-0.8-1.8-1.8V42.4c0-1.8,1.5-3.3,3.3-3.3h32.3C50.9,39.2,52.3,40.6,52.3,42.4z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M52.3,58.7v13.2c0,1-0.8,1.8-1.8,1.8H15.4
		c-1,0-1.8-0.8-1.8-1.8V58.7c0-1,0.8-1.8,1.8-1.8h35.2C51.5,56.9,52.3,57.7,52.3,58.7z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M52.3,76.4v11.8c0,1.8-1.5,3.3-3.3,3.3H16.8
		c-1.8,0-3.3-1.5-3.3-3.3V76.4c0-1,0.8-1.8,1.8-1.8h35.2C51.5,74.6,52.3,75.4,52.3,76.4z"
      />
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M38.8,47.1L38.8,47.1c-0.7,0-1.2-0.5-1.2-1.2
		v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0C40,46.5,39.4,47.1,38.8,47.1z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M42.8,47.1L42.8,47.1c-0.7,0-1.2-0.5-1.2-1.2
		v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0C44,46.5,43.5,47.1,42.8,47.1z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M46.6,47.1L46.6,47.1c-0.7,0-1.2-0.5-1.2-1.2
		v0c0-0.7,0.5-1.2,1.2-1.2h0c0.7,0,1.2,0.5,1.2,1.2v0C47.8,46.5,47.3,47.1,46.6,47.1z"
      />
    </g>
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="67.8"
      cy="47.7"
      r="11.9"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="67.8"
      cy="47.7"
      r="8.8"
    />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M69.3,51.6l-1.9-2.8l-1.9,2.8
		c0,0-0.1,0.1-0.1,0.1h-1.8c-0.1,0-0.2-0.1-0.1-0.2l2.9-3.9l-2.7-3.8c-0.1-0.1,0-0.2,0.1-0.2h1.8c0,0,0.1,0,0.1,0.1l1.8,2.6l1.8-2.6
		c0,0,0.1-0.1,0.1-0.1h1.7c0.1,0,0.2,0.1,0.1,0.2l-2.7,3.7l2.9,4c0.1,0.1,0,0.2-0.1,0.2h-1.8C69.4,51.6,69.3,51.6,69.3,51.6z"
      />
    </g>
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M113.9,43.3v43.1c0,1.1-0.9,2.1-2.1,2.1H82.2
	c-1.1,0-2.1-0.9-2.1-2.1V52.5l11.2-11.2h20.4C113,41.3,113.9,42.2,113.9,43.3z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M80.3,52.3l10.9-10.9c0.1-0.1,0.2,0,0.2,0.1
	v10.9c0,0.1,0,0.1-0.1,0.1l-10.9,0C80.3,52.5,80.3,52.4,80.3,52.3z"
    />
    <rect
      x="83.6"
      y="59.3"
      fill="#606774"
      stroke="#3E445D"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="26.8"
      height="2.4"
    />
    <rect
      x="83.6"
      y="65.1"
      fill="#606774"
      stroke="#3E445D"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="26.8"
      height="2.4"
    />
    <rect
      x="83.6"
      y="70.9"
      fill="#606774"
      stroke="#3E445D"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="26.8"
      height="2.4"
    />
    <rect
      x="83.6"
      y="76.7"
      fill="#606774"
      stroke="#3E445D"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="26.8"
      height="2.4"
    />
  </SVGIcon>
);

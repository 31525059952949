import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ExternalLinkIcon = (props: SVGIconProps) => (
  <SVGIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <defs>
      <style>
        {
          '.a,.b{fill:none;}.a{stroke:#404040;strokeLinecap:round;strokeLinejoin:round;strokeWidth:1.5px;}'
        }
      </style>
    </defs>
    <path
      className="a"
      /* eslint-disable max-len */
      d="M8.5,5.5h-3A1.5,1.5,0,0,0,4,7v7.5A1.5,1.5,0,0,0,5.5,16H13a1.5,1.5,0,0,0,1.5-1.5v-3M11.5,4H16m0,0V8.5M16,4,8.5,11.5"
    />
    <rect className="b" width="20" height="20" />
  </SVGIcon>
);

export type JSONValue =
  | string
  | number
  | boolean
  | JSONObject
  | Array<JSONValue>;

interface JSONObject {
  [x: string]: JSONValue;
}

export function isObject(thing: JSONValue) {
  return typeof thing === 'object' && thing !== null && !Array.isArray(thing);
}

export function checkCaseWrapping(data: JSONValue) {
  // eslint-disable-next-line no-prototype-builtins
  if (data && isObject(data) && data.hasOwnProperty('$$__case')) {
    return true;
  }
  return false;
}

export function checkAdditionalPropertyWrapping(data: JSONValue) {
  if (
    data &&
    isObject(data) &&
    Object.prototype.hasOwnProperty.call(data, '$$__additionalProperties')
  ) {
    return true;
  }
  return false;
}

const checkNestedArrayData = (item: JSONValue) => {
  if (item && isObject(item)) {
    return unwrapAdditionalProperty(item);
  } else {
    return item;
  }
};

export function unwrapAdditionalProperty(formData: JSONValue = {}): JSONValue {
  let newFormData: JSONValue = JSON.parse(JSON.stringify(formData));

  if (isObject(newFormData)) {
    // This is the base case for the recursive function.
    if (checkAdditionalPropertyWrapping(newFormData)) {
      const unWrap = unwrapAdditionalProperty(
        newFormData['$$__additionalProperties' as keyof typeof newFormData]
      ) as JSONObject;
      const { $$__additionalProperties, ...rest } = newFormData as JSONObject;
      newFormData = { ...rest, ...unWrap };
    }

    // This checks the values of all the keys of the object to further carry out the recursion
    for (const key in newFormData as JSONObject) {
      // The following two condition reduces unnecessary recursion.
      // The function will call itself if either the value is an object or an array
      if (isObject(newFormData[key as keyof typeof newFormData])) {
        newFormData = {
          ...(newFormData as JSONObject),
          [key]: unwrapAdditionalProperty(
            newFormData[key as keyof typeof newFormData]
          ),
        };
      }
      if (Array.isArray(newFormData[key as keyof typeof newFormData])) {
        (newFormData[key as keyof typeof newFormData] as JSONValue[]) = (
          newFormData[key as keyof typeof newFormData] as JSONValue[]
        ).map((item) => checkNestedArrayData(item));
      }
    }
  }

  if (Array.isArray(newFormData)) {
    newFormData = newFormData.map((item) => checkNestedArrayData(item));
  }
  return newFormData;
}

export function unwrapCallModelData(formData: JSONValue = {}): JSONValue {
  let newFormData: JSONValue = JSON.parse(JSON.stringify(formData));

  if (isObject(newFormData)) {
    if (checkCaseWrapping(newFormData)) {
      newFormData = unwrapCallModelData(
        newFormData['value' as keyof typeof newFormData]
      );
    } else {
      for (const key in newFormData as JSONObject) {
        newFormData = {
          ...(newFormData as JSONObject),
          [key]: unwrapCallModelData(
            newFormData[key as keyof typeof newFormData]
          ),
        };
      }
    }
  }

  if (Array.isArray(newFormData)) {
    newFormData = newFormData.map((item) => {
      if (item && isObject(item)) {
        return unwrapCallModelData(item);
      } else {
        return item;
      }
    });
  }

  return newFormData;
}

export function anyFilter(
  formData: JSONValue = {},
  tracers: string,
  tracerSeperator: string,
  querySeperator: string
): boolean {
  const json: JSONValue = JSON.parse(JSON.stringify(formData));
  if (json == null) return false;
  // If the first parameter is an array, iterate over its objects and check each one
  if (Array.isArray(json)) {
    for (const obj of json) {
      if (anyFilter(obj, tracers, tracerSeperator, querySeperator)) {
        return true;
      }
    }
    return false;
  }

  const tracerList = tracers.split(querySeperator);
  for (const tracer of tracerList) {
    if (filterByTracer(json, tracer, tracerSeperator, querySeperator)) {
      return true;
    }
  }
  return false;
}

function getNumber(property: string) {
  const start = property.indexOf('[') + 1;
  const end = property.indexOf(']');
  const number = parseInt(property.slice(start, end));
  return number;
}

function getDataFromMap(inputData: JSONValue) {
  let finalData: JSONValue = [];
  if (!Array.isArray(inputData)) {
    finalData = Object.values(inputData).flatMap((obj) =>
      Array.isArray(obj) ? obj.flatMap((obj) => obj) : obj
    );
  } else {
    finalData = inputData.flatMap((obj) =>
      Object.values(obj).flatMap((innerObj) =>
        Array.isArray(innerObj) ? innerObj.flatMap((item) => item) : innerObj
      )
    );
  }

  return finalData;
}

function isInvalidCase(inputData: JSONValue, caseValue: number) {
  return (
    // eslint-disable-next-line no-prototype-builtins
    !inputData.hasOwnProperty('$$__case') ||
    // eslint-disable-next-line no-prototype-builtins
    !inputData.hasOwnProperty('value') ||
    inputData['$$__case' as keyof JSONValue] !== caseValue ||
    inputData['value' as keyof JSONValue] === null
  );
}

function filterByTracer(
  json: JSONValue,
  tracer: string,
  tracerSeperator: string,
  querySeperator: string
) {
  const properties = tracer.split(tracerSeperator);
  let currentObj = json;

  for (let index = 0; index < properties.length; index++) {
    const property = properties[index];
    const newArray = properties
      .slice(index + 1, properties.length)
      .join(tracerSeperator);
    const propertyIncludesMap = property.includes(':Map');
    const propertyIncludesCase = property.includes(':case');
    const caseValue = propertyIncludesCase ? getNumber(property) : -1;
    const propertyWithoutMap =
      propertyIncludesMap || propertyIncludesCase
        ? property.slice(0, property.indexOf(':'))
        : property;

    const currentObjProperty: JSONValue[] =
      currentObj[propertyWithoutMap as keyof JSONValue];
    if (
      propertyWithoutMap &&
      // eslint-disable-next-line no-prototype-builtins
      (!currentObj.hasOwnProperty(propertyWithoutMap) ||
        currentObjProperty === null)
    ) {
      return false;
    }

    if (
      propertyIncludesCase &&
      typeof currentObjProperty === 'object' &&
      !propertyIncludesMap &&
      !Array.isArray(currentObjProperty) &&
      isInvalidCase(currentObjProperty, caseValue)
    ) {
      return false;
    }

    if (
      propertyIncludesMap ||
      (Array.isArray(currentObjProperty) &&
        (propertyIncludesCase || (newArray && currentObjProperty[0])))
    ) {
      const object: JSONValue[] = propertyIncludesMap
        ? getDataFromMap(currentObjProperty)
        : currentObjProperty;
      for (const obj of object) {
        if (propertyIncludesCase && isInvalidCase(obj, caseValue)) {
          continue;
        }
        if (anyFilter(obj, newArray, tracerSeperator, querySeperator)) {
          return true;
        }
      }
      return false;
    } else {
      currentObj = propertyIncludesCase
        ? { value: currentObjProperty['value' as keyof JSONValue] }
        : currentObjProperty;
    }
  }

  return true;
}

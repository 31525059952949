export const CheckedCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="12" fill="#0c7ff2" />
    <circle cx="4" cy="4" r="4" transform="translate(8 8)" fill="#0c7ff2" />
    <path
      d="M5.5,12,14,3.5,12.5,2l-7,7-4-4L0,6.5Z"
      transform="translate(5 5.5)"
      fill="#fff"
    />
  </svg>
);

import * as Mousetrap from 'mousetrap';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Document, Section } from './DxDom';
import { LinkMapper } from './LinkMapperContext';

export interface HotKeyCompProps extends RouteComponentProps<object> {
  doc: Document;
  className?: string;
  linkMapper: LinkMapper;
}

/**
 * Keyboard shortcuts for navigating document
 */
class HotKeysComp extends React.Component<
  React.PropsWithChildren<HotKeyCompProps>
> {
  static map = {
    navLeft: 'alt+up',
    navRight: 'alt+down',
  };

  constructor(props: HotKeyCompProps) {
    super(props);

    this.navLeft = this.navLeft.bind(this);
    this.navRight = this.navRight.bind(this);
  }

  componentDidMount() {
    Mousetrap.bind(HotKeysComp.map.navLeft, this.navLeft);
    Mousetrap.bind(HotKeysComp.map.navRight, this.navRight);
  }

  componentWillUnmount() {
    Mousetrap.unbind(HotKeysComp.map.navLeft);
    Mousetrap.unbind(HotKeysComp.map.navRight);
  }

  navLeft() {
    const newLink = getPrevLink(
      this.props.location.pathname,
      this.props.doc.Sections,
      this.props.linkMapper
    );
    if (newLink) {
      this.props.history.push(newLink);
    }
  }

  navRight() {
    const newLink = getNextLink(
      this.props.location.pathname,
      this.props.doc.Sections,
      this.props.linkMapper
    );
    if (newLink) {
      this.props.history.push(newLink);
    }
  }

  render() {
    return this.props.children;
  }
}

export function getPrevLink(
  link: string,
  sections: ReadonlyArray<Section>,
  linkMapper: LinkMapper
): string | null {
  const currentSection = sections.findIndex(
    (x) => linkMapper(x.SuggestedLink) === link
  );
  if (
    currentSection !== -1 &&
    currentSection > 0 &&
    sections[currentSection - 1].SuggestedLink
  ) {
    return linkMapper(
      sections[currentSection - 1].SuggestedLink! // checked above
    );
  }
  return null;
}

export function getNextLink(
  link: string,
  sections: ReadonlyArray<Section>,
  linkMapper: LinkMapper
): string | null {
  const currentSection = sections.findIndex(
    (x) => linkMapper(x.SuggestedLink) === link
  );
  if (
    currentSection !== -1 &&
    currentSection < sections.length - 1 &&
    sections[currentSection + 1].SuggestedLink
  ) {
    return linkMapper(
      sections[currentSection + 1].SuggestedLink! // checked above
    );
  }
  return null;
}

export const HotKeys = withRouter(HotKeysComp);

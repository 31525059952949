import styled from 'styled-components';
import { Div, Li } from './CleanSlate';
import { SectionNode } from './DxDom';
import { RefObject, createRef, useContext, useState } from 'react';
import Tooltip from './Tooltip/Tooltip';
import { DocumentContext } from './DocumentContext';
import { HighlighterLanguage } from './CodeHighlighter';
import {
  ScrollableSelector,
  selectorMixin,
} from './Utilities/ScrollableSelector';

interface CodeSelectorProps {
  Examples: Record<string, string>;
  isContainerExample: boolean;
  renderNode: (n: SectionNode) => object;
}
interface SelectorProp {
  isSelected: boolean;
}

interface CodeDisplayContainerProps {
  ref: RefObject<HTMLDivElement>;
}

const InitilizationCodeWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const Selector = styled(Li)<SelectorProp>`
  ${selectorMixin}
  position: relative;
  top: 1px;
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: -1px;
    left: 0px;
    background-color: ${(props) =>
      props.isSelected ? props.theme.colors.C001 : props.theme.colors.C300};
  }
`;

const CodeDisplayContainer = styled(Div)<CodeDisplayContainerProps>`
  height: fit-content;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.C300};
  padding: 0px 20px;
  border-radius: 0px 0px 8px 8px;
`;

const TRIM_CHAR = 15;

export const CodeSelector = (props: CodeSelectorProps) => {
  const [activeExample, setActiveExample] = useState<number>(0);
  const parentRef: RefObject<HTMLDivElement> = createRef();

  const { Examples, renderNode, isContainerExample } = props;
  const exampleKeys = Object.keys(Examples);
  const doc = useContext(DocumentContext);
  const language =
    doc?.lang && !isContainerExample ? HighlighterLanguage[doc.lang] : 'json';

  return (
    <InitilizationCodeWrapper>
      <ScrollableSelector parentRef={parentRef}>
        {exampleKeys.map((tabTitle, index) => (
          <Selector
            onClick={() => setActiveExample(index)}
            isSelected={index === activeExample}
          >
            {!checkIfDataTypeIsLarge(tabTitle, TRIM_CHAR) ? (
              <span>{tabTitle}</span>
            ) : (
              <Tooltip
                text={tabTitle}
                parentRefranceId="app-layout-docs-container"
              >
                {checkIfDataTypeIsLarge(tabTitle, TRIM_CHAR)}
              </Tooltip>
            )}
          </Selector>
        ))}
      </ScrollableSelector>

      <CodeDisplayContainer ref={parentRef}>
        <>
          {renderNode({
            Type: 'codeblock',
            Text: Examples[exampleKeys[activeExample]],
            Language: language,
            From: 'Initilization Code Example',
          })}
        </>
      </CodeDisplayContainer>
    </InitilizationCodeWrapper>
  );
};

export function checkIfDataTypeIsLarge(
  dataType?: string,
  trimTextLimit?: number
) {
  if (dataType && trimTextLimit && dataType.length > trimTextLimit) {
    return dataType.slice(0, trimTextLimit) + '...';
  }
}

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const SettingIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '12px'}
    height={props.height || '12px'}
    viewBox="0 0 12 12"
    {...props}
  >
    <g transform="translate(-2 -2)">
      <g transform="translate(2 2)">
        <path
          /* eslint-disable max-len */
          d="M12.9,8c0-.138-.006-.27-.019-.408l1.148-.846a.593.593,0,0,0,.16-.78L13.041,4.028a.618.618,0,0,0-.771-.252l-1.327.546a4.715,4.715,0,0,0-.722-.408l-.179-1.386A.613.613,0,0,0,9.431,2h-2.3a.615.615,0,0,0-.617.528L6.333,3.914a4.715,4.715,0,0,0-.722.408L4.284,3.776a.618.618,0,0,0-.771.252L2.359,5.972a.594.594,0,0,0,.16.78L3.667,7.6c-.012.132-.019.264-.019.4s.006.27.019.408l-1.148.846a.593.593,0,0,0-.16.78l1.154,1.938a.618.618,0,0,0,.771.252l1.327-.546a4.715,4.715,0,0,0,.722.408l.179,1.386A.613.613,0,0,0,7.123,14h2.3a.613.613,0,0,0,.611-.528l.179-1.386a4.715,4.715,0,0,0,.722-.408l1.327.546a.618.618,0,0,0,.771-.252l1.154-1.938a.594.594,0,0,0-.16-.78L12.88,8.408A3.017,3.017,0,0,0,12.9,8ZM8.3,10.1A2.1,2.1,0,1,1,10.461,8,2.133,2.133,0,0,1,8.3,10.1Z"
          transform="translate(-2.277 -2)"
        />
      </g>
    </g>
  </SVGIcon>
);

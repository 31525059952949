import styled from 'styled-components';

import { RefreshIcon } from 'src/Icons/Ui/RefreshIcon';
import { PrimaryButton } from 'src/StyledElements';

export interface TryAgainButtonProps {
  onClick?: VoidFunction;
  className?: string;
}

const PrimaryButtonStyled = styled(PrimaryButton)`
  padding: 0 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  .refresh-icon {
    margin-right: 7px;
  }
`;

export default function TryAgainButton(props: TryAgainButtonProps) {
  const { onClick, className = '' } = props;

  return (
    <PrimaryButtonStyled className={className} onClick={onClick}>
      <RefreshIcon className="refresh-icon" />
      Try Again
    </PrimaryButtonStyled>
  );
}

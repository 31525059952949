/**
 * Portal context provides current portal settings to all components that need it
 */

import * as React from 'react';
import { PortalSettings } from './PortalSettings';

export const PortalContext = React.createContext<PortalSettings | undefined>(
  undefined
);

export const PortalContextProvider = PortalContext.Provider;

export const PortalContextConsumer = PortalContext.Consumer;

/**
 * HOC for binding with portal settings context
 * @param Component Component to wrap
 */
export function withPortalContext<
  P extends { portalSettings?: PortalSettings },
  R = Omit<P, 'portalSettings'>
>(
  Component: React.ComponentClass<P> | React.FunctionComponent<P>
): React.FC<React.PropsWithChildren<R>> {
  // Reference: https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
  return function BoundComponent(props: R) {
    // Need this step to resolve TS issue. Types are dynamically changing here
    const getProps: (portalSettings: P['portalSettings']) => unknown = (
      portalSettings: P['portalSettings']
    ) => ({
      ...props,
      portalSettings,
    });

    return (
      <PortalContext.Consumer>
        {(value) => <Component {...(getProps(value) as P)} />}
      </PortalContext.Consumer>
    );
  };
}

import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from 'react';

import { Document as Doc } from '../DxDom';
import { NotificationUiStateDef } from './NotificationBar';
import { APICallException } from 'src/RCompilableCodeBlock';

export interface OAuthError extends APICallException {
  statusCode?: number | null;
}

export type AuthStatus = {
  isAuthorized: boolean;
  isAuthorizing: boolean;
  notification?: NotificationUiStateDef;
  errorDetails?: OAuthError | null;
  tokenTime?: string | null;
};

export type OAuthType = {
  [key: string]: AuthStatus;
};

export interface OAuthContextState {
  oAuthState: OAuthType;
}

export interface OAuthContextProvider {
  updateOAuthState: (oAuthState: OAuthContextState) => void;
  oAuthContextState: OAuthContextState;
}

export const OAuthContext = createContext<OAuthContextProvider | undefined>(
  undefined
);

export interface OAuthContextProps {
  doc?: Doc;
}

export const OAuthContextProvider: FC<PropsWithChildren<OAuthContextProps>> = (
  props
) => {
  const [state, setState] = useState<OAuthContextState>({
    oAuthState: {},
  });

  const updateOAuthState = useCallback((oAuthData: OAuthContextState) => {
    setState((st) => {
      return { ...st, ...oAuthData };
    });
  }, []);

  const provider = {
    updateOAuthState: updateOAuthState,
    oAuthContextState: state,
  };

  return (
    <OAuthContext.Provider value={provider}>
      {props.children}
    </OAuthContext.Provider>
  );
};

export const OAuthContextConsumer = OAuthContext.Consumer;

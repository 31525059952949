import React from 'react';
import PropTypes from 'prop-types';
import mergeAllOf from 'json-schema-merge-allof';

import DataType from './DataType';

import {
  isMultiSelect,
  retrieveSchema,
  getDefaultRegistry,
  getUiOptions,
  isFilesArray,
  deepEquals,
  prefixClass as pfx,
  isOneOfSchema,
  prefixClass,
  isDiscriminator as isDiscriminatorCheck,
  isOafContainer,
} from '../../utils';
import UnsupportedField from './UnsupportedField';
import { validateField } from '../../validationUtils';
import { ChevronIcon } from '../Icons';
import { IconBtn } from './MapField';

// const REQUIRED_FIELD_SYMBOL = "*";
const COMPONENT_TYPES = {
  array: 'ArrayField',
  boolean: 'BooleanField',
  integer: 'NumberField',
  number: 'NumberField',
  object: 'ObjectField',
  string: 'StringField',
  discriminator: 'DiscriminatorField',
};

const MERGE_ALLOF_OPTIONS = {
  ignoreAdditionalProperties: true,
  resolvers: {
    defaultResolver: function (values) {
      return values.length ? values[0] : '';
    },
    id: function (values) {
      return values.length ? values[0] : '';
    },
    description: function (values) {
      return values.length ? values[0] : '';
    },
    dataTypeLink: function (values) {
      return values.length ? values[0] : '';
    },
    dataTypeDisplayText: function (values) {
      return values.length ? values[0] : '';
    },
  },
};

function getFieldComponent(schema, uiSchema, fields) {
  const field = uiSchema['ui:field'];

  if (typeof field === 'function') {
    return field;
  }
  if (typeof field === 'string' && field in fields) {
    return fields[field];
  }

  const componentName = COMPONENT_TYPES[schema.type];

  if (!componentName && (schema.oneOf || schema.anyOf)) {
    const a = fields['DiscriminatorField'];
    return a;
  }

  if (
    schema.typeCombinatorTypes &&
    schema.type !== 'array' &&
    !schema.additionalProperties
  ) {
    return fields['DiscrimatorWrapper'];
  }

  // In case of multiple types, we are going to pick first one
  // according to @saeedjamshaid
  if (Array.isArray(schema.type)) {
    const [type] = schema.type;
    const compName = COMPONENT_TYPES[type];

    schema.type = type;
    return fields[compName];
  }

  return componentName in fields
    ? fields[componentName]
    : () => {
        return (
          <UnsupportedField
            schema={schema}
            reason={`Unknown field type ${schema.type}`}
          />
        );
      };
}

export function Label(props) {
  const { label, id } = props;
  if (!label) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  return (
    <label className={pfx('control-label')} htmlFor={id}>
      {/* {required ? label + REQUIRED_FIELD_SYMBOL : label} */}
      <div className="label-text">{label}</div>
    </label>
  );
}

function Help(props) {
  const { help } = props;
  if (!help) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  if (typeof help === 'string') {
    return <p className={pfx('help-block')}>{help}</p>;
  }
  return <div className={pfx('help-block')}>{help}</div>;
}

function ErrorList(props) {
  const { errors = [] } = props;
  if (errors.length === 0) {
    return <div />;
  }
  return (
    <div>
      <p />
      <ul className={pfx('error-detail bs-callout bs-callout-info')}>
        {errors.map((error, index) => {
          return (
            <li className={pfx('text-danger')} key={index}>
              {error}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function hasOAF(schema) {
  return (
    schema &&
    (Object.prototype.hasOwnProperty.call(schema, 'parentDiscriminator') ||
      Object.prototype.hasOwnProperty.call(schema, 'oneOf') ||
      Object.prototype.hasOwnProperty.call(schema, 'anyOf'))
  );
}
export function DefaultTemplate(props) {
  const {
    id,
    classNames,
    label,
    children,
    help,
    description,
    hidden,
    required,
    displayLabel,
    nullify,
    onNullifyChange,
    disabled,
    fromDiscriminator,
    formData,
    schema,
    onCollapseClick,
    isCollapsed,
  } = props;

  if (hidden) {
    return children;
  }
  const dataType = props.schema.dataTypeDisplayText
    ? props.schema.dataTypeDisplayText
    : props.schema.type;

  // TODO:  dataTypeMarkdown support will be removed in future

  const markdown = schema.dataTypeMarkdown;

  const canCollapse =
    ((hasOAF(schema) && isOafContainer(schema)) ||
      schema.type === 'object' ||
      schema.type === 'array') &&
    onCollapseClick;

  const errors = (
    <ErrorList errors={validateField(schema, formData, required, disabled)} />
  );

  if (schema.readOnly) {
    return null;
  }

  const dataTypeFieldClasses =
    (dataType && schema.containerKind) || markdown
      ? 'type-container type-container-dataType'
      : 'type-container';

  return (
    <div className={pfx(classNames)}>
      {displayLabel && (
        <div
          className={pfx('field-header')}
          onClick={() => {
            if (canCollapse) {
              onCollapseClick();
            }
          }}
        >
          {onNullifyChange && !required ? (
            <legend>
              {canCollapse && (
                <IconBtn tabIndex="-1" className={pfx(`btn toggle-button`)}>
                  {isCollapsed ? (
                    <ChevronIcon width={14} rotate={-90} />
                  ) : (
                    <ChevronIcon width={14} />
                  )}
                </IconBtn>
              )}
              <label onClick={(ev) => ev.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={nullify}
                  className={!disabled && nullify ? 'checked' : 'unchecked'}
                  onChange={onNullifyChange}
                  disabled={disabled}
                />
                <span />
              </label>
              <div className={prefixClass('checkbox-text')}>{label}</div>
            </legend>
          ) : (
            <fieldset>
              {canCollapse && (
                <IconBtn tabIndex="-1" className={pfx(`btn toggle-button`)}>
                  {isCollapsed ? (
                    <ChevronIcon width={14} rotate={-90} />
                  ) : (
                    <ChevronIcon width={14} />
                  )}
                </IconBtn>
              )}
              <Label label={label} required={required} id={id} />
            </fieldset>
          )}
          {!fromDiscriminator && required && (
            <div className={pfx('element-required')}>
              <span>Required</span>
            </div>
          )}
        </div>
      )}

      {displayLabel && (
        <div className={pfx(dataTypeFieldClasses)}>
          <DataType
            containerKind={schema.containerKind}
            containerName={schema.containerName}
            dataType={dataType}
            link={props.schema.dataTypeLink}
            type="schema"
            linkTo={schema.linkTo}
            markdown={markdown}
          />

          {props.schema.paramType && (
            <div className={pfx('param-type')}>{props.schema.paramType}</div>
          )}
        </div>
      )}

      {displayLabel && description ? description : null}
      {children}
      {onNullifyChange && !nullify ? null : errors}
      {help}
    </div>
  );
}

if (process.env.NODE_ENV !== 'production') {
  DefaultTemplate.propTypes = {
    id: PropTypes.string,
    classNames: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node.isRequired,
    errors: PropTypes.element,
    rawErrors: PropTypes.arrayOf(PropTypes.string),
    help: PropTypes.element,
    rawHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.element,
    rawDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hidden: PropTypes.bool,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    displayLabel: PropTypes.bool,
    fields: PropTypes.object,
    formContext: PropTypes.object,
  };
}

DefaultTemplate.defaultProps = {
  hidden: false,
  readonly: false,
  required: false,
  displayLabel: true,
};

function SchemaFieldRender(props) {
  const {
    uiSchema,
    errorSchema,
    name,
    formData,
    required,
    schemaIndex,
    registry = getDefaultRegistry(),
    anyOfTitle,
    typeCombinatorTypes,
    discriminatorObj = {},
    fromDiscriminator,
  } = props;
  const {
    dxInterface,
    fields,
    formContext,
    FieldTemplate = DefaultTemplate,
  } = registry;

  const isDiscriminatorField = isDiscriminatorCheck(name, discriminatorObj);

  // If direct circular reference, we will not fetch further schema through references
  let schema = retrieveSchema(props.schema, formData, dxInterface);

  if (schema.allOf && !schema.typeCombinatorTypes) {
    schema = mergeAllOf(schema, MERGE_ALLOF_OPTIONS);
  }

  const FieldComponent = getFieldComponent(schema, uiSchema, fields);
  const { DescriptionField } = fields;
  const disabled = Boolean(
    props.disabled || uiSchema['ui:disabled'] || isDiscriminatorField
  );
  const readonly = Boolean(props.readonly || uiSchema['ui:readonly']);
  const autofocus = Boolean(props.autofocus || uiSchema['ui:autofocus']);
  const _typeCombinatorTypes =
    typeCombinatorTypes || schema.typeCombinatorTypes || null;

  if (
    Object.prototype.hasOwnProperty.call(props.schema, '$ref') ||
    (schema.type === 'array' &&
      Object.prototype.hasOwnProperty.call(props.schema.items, '$ref'))
  ) {
    schema.title = name;
  }

  if (Object.keys(schema).length === 0) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }

  const uiOptions = getUiOptions(uiSchema);
  let { label: displayLabel = true } = uiOptions;
  if (schema.type === 'array') {
    displayLabel =
      isMultiSelect(schema, dxInterface) ||
      isFilesArray(schema, uiSchema, dxInterface);
  }

  if (schema.type === 'object' && !schema.typeCombinatorTypes) {
    displayLabel = false;
  }

  if (schema.ContainsSubTypes) {
    displayLabel = false;
  }

  if (schema.type === 'boolean' && !uiSchema['ui:widget']) {
    displayLabel = false;
  }

  if (uiSchema['ui:field']) {
    displayLabel = false;
  }

  const { __errors, ...fieldErrorSchema } = errorSchema;

  const { type } = schema;

  const label =
    uiSchema['ui:title'] || props.schema.title || schema.title || name;
  const description =
    uiSchema['ui:description'] ||
    props.schema.description ||
    schema.description;
  const additionalDescription =
    uiSchema['ui:additionalDescription'] ||
    props.schema.additionalDescription ||
    schema.additionalDescription;
  const errors = __errors;
  const help = uiSchema['ui:help'];
  const hidden = uiSchema['ui:widget'] === 'hidden';
  const classNames = [
    'form-group',
    'field',
    `field-${type}`,
    errors && errors.length > 0 ? 'field-error has-error has-danger' : '',
    schema.oneOf || schema.anyOf ? 'discriminator-container' : '',
    uiSchema.classNames,
    fromDiscriminator ? 'from-discriminator' : '',
  ]
    .join(' ')
    .trim();

  const fieldProps = {
    description: (
      <DescriptionField
        description={description}
        formContext={formContext}
        isDiscriminatorField={isDiscriminatorField}
        AdditionalDescription={additionalDescription}
      />
    ),
    rawDescription: description,
    help: <Help help={help} />,
    rawHelp: typeof help === 'string' ? help : undefined,
    errors: <ErrorList errors={errors} />,
    rawErrors: errors,
    label,
    hidden,
    required,
    disabled,
    readonly,
    displayLabel,
    classNames,
    formContext,
    fields,
    schema,
    uiSchema,
    anyOfTitle,
    discriminatorObj,
    formData,
  };

  // See #439: uiSchema: Don't pass consumed class names to child components
  const field = (
    <FieldComponent
      {...props}
      definitions={dxInterface.definitions}
      schema={schema}
      uiSchema={{ ...uiSchema, classNames: undefined }}
      disabled={disabled}
      readonly={readonly}
      autofocus={autofocus}
      errorSchema={fieldErrorSchema}
      formContext={formContext}
      schemaIndex={schemaIndex}
      typeCombinatorTypes={_typeCombinatorTypes}
      fieldProps={fieldProps}
      discriminatorObj={discriminatorObj}
    />
  );

  return isOneOfSchema(schema) ||
    (!isOneOfSchema(schema) &&
      schema.typeCombinatorTypes &&
      schema.type !== 'array') ? (
    field
  ) : (
    <FieldTemplate {...fieldProps}>{field}</FieldTemplate>
  );
}

class SchemaField extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    // if schemas are equal idSchemas will be equal as well,
    // so it is not necessary to compare
    return !deepEquals({ ...this.props }, { ...nextProps });
  }

  render() {
    return SchemaFieldRender(this.props);
  }
}

SchemaField.defaultProps = {
  uiSchema: {},
  errorSchema: {},
  disabled: false,
  readonly: false,
  autofocus: false,
};

/* istanbul ignore else */
if (process.env.NODE_ENV !== 'production') {
  SchemaField.propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.object,
    formData: PropTypes.any,
    errorSchema: PropTypes.object,
    schemaIndex: PropTypes.number,
    dxInterface: PropTypes.shape({
      registry: PropTypes.shape({
        widgets: PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        ).isRequired,
        fields: PropTypes.objectOf(PropTypes.func).isRequired,
        definitions: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired,
      }),
    }),
  };
}

export default SchemaField;

import * as React from 'react';
import styled from 'styled-components';

import * as DxDom from './DxDom';
import { RParamInfo } from './RParamInfo';
import { CodeBox } from './AppLayout';
import {
  CodeBlock,
  CodeBlockCode,
  FlexParent,
  TypeWrapper,
} from './StyledElements';
import { CodeHighlighter } from './CodeHighlighter';
import { ExpandableButton } from './base-components/Accordion';
import { Div } from './CleanSlate';
import { CopyToClipboardComp } from './uiComponents/CopyToClipboard';

export const CodeBlockHeader = styled(Div)`
  background-color: ${(props) => props.theme.colors.C1000};
  margin: 0;
  padding: 0;
  height: 36px;
  border-radius: 8px 8px 0px 0px;
`;
CodeBlockHeader.displayName = 'CodeBlockHeader';

export const CodeBlockTools = styled(Div)`
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: right;
  padding: 0 24px;

  @media screen and (max-width: 1100px) {
    padding: 0 10px;
  }
`;
CodeBlockTools.displayName = 'CodeBlockTools';

/**
 * Render model reference
 * @param {array} mref
 */
export function simplifyModelReference(
  mref: DxDom.EnumReference | DxDom.StructureReference,
  nodeRenderer: DxDom.NodeHandler<JSX.Element>
): JSX.Element {
  const nodes: JSX.Element[] = [];

  const renderNode = (n: DxDom.SectionNode) =>
    DxDom.callNodeHandler(n, nodeRenderer);

  let i = 0;
  const pushNode = (n: DxDom.SectionNode) =>
    nodes.push(<React.Fragment key={i++}>{renderNode(n)}</React.Fragment>);

  if (mref.Description) {
    pushNode({
      Type: 'paragraph',
      Text: mref.Description,
    });
  }

  if (mref.StructureType) {
    pushNode({
      Type: 'heading',
      Text: mref.StructureType,
      Level: 2,
    });
    nodes.push(
      <TypeWrapper key="class-name">
        {renderNode({
          Type: 'paragraph',
          Text: '`' + mref.Name + '`',
        })}
      </TypeWrapper>
    );
  }

  if (mref.BaseClass && mref.Index === 0) {
    pushNode({
      Type: 'heading',
      Text: 'Inherits From',
      Level: 2,
    });
    nodes.push(
      <TypeWrapper key="base-class-link" className="reference-modal-fields">
        {renderNode({
          Type: 'paragraph',
          Text: mref.BaseClass,
        })}
      </TypeWrapper>
    );
  }

  if (mref.Type && mref.Type === 'enumreference') {
    if (mref.ContainedTypesName) {
      pushNode({
        Type: 'heading',
        Text: mref.ContainedTypesName,
        Level: 2,
      });
    } else {
      pushNode({
        Type: 'heading',
        Text: 'Fields',
        Level: 2,
      });
    }
  }

  // Model field documentation
  if (mref.Type === 'enumreference') {
    // TODO: Replace 'let' with const and remove mutation.
    const fieldTableHeaderData = ['Name', 'Description'];

    const fieldTableRows: DxDom.TableRow[] = [];

    const enumRef = mref;

    for (const elementKey in enumRef.Elements) {
      if (enumRef.Elements.hasOwnProperty(elementKey)) {
        const element = enumRef.Elements[elementKey];
        fieldTableRows.push({
          Data: [element.Key, element.Value],
        } as DxDom.TableRow);
      }
    }

    const fieldTable: DxDom.Table = {
      Type: 'enumtable',
      Header: {
        Data: fieldTableHeaderData,
      },
      Rows: fieldTableRows,
      Class: 'enum-table-data',
    };
    pushNode(fieldTable);
  } else {
    const tableHeading =
      mref.Index && mref.Index > 0
        ? `Fields Inherited From [${mref.Title}](${mref.LinkTo})`
        : 'Fields';
    nodes.push(
      <ExpandableButton
        heading={tableHeading}
        defaultOpen={mref.Index === 0 ? true : false}
      >
        <RParamInfo key="fields-table" params={mref.Fields} />
      </ExpandableButton>
    );
  }

  if (mref.ChildClassesLink) {
    pushNode({
      Type: 'heading',
      Text: 'Child Types',
      Level: 2,
    });
    nodes.push(
      <TypeWrapper key="base-class-link">
        {renderNode({
          Type: 'paragraph',
          Text: mref.ChildClassesLink,
        })}
      </TypeWrapper>
    );
  }

  if (mref.Example && mref.Index === 0) {
    nodes.push(
      <CodeBox key="model-example">
        <CodeBlock type="column">
          <CodeBlockHeader />
          <CodeBlockCode invert={true} scrollWidth="7px">
            <CodeHighlighter
              code={mref.Example.Text}
              dark={true}
              lang={mref.Example.Language}
            />
          </CodeBlockCode>
          <CodeBlockTools>
            <FlexParent>
              <CopyToClipboardComp
                text={mref.Example.Text}
                iconButtonType="dark"
              />
            </FlexParent>
          </CodeBlockTools>
        </CodeBlock>
      </CodeBox>
    );
  }

  return <React.Fragment>{nodes}</React.Fragment>;
}

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ArrowBackIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '14.717'}
    height={props.height || '10.13'}
    viewBox={props.viewBox || '0 0 14.717 10.13'}
  >
    <g transform="translate(-715.347 -503.054)">
      <path
        d="M598.064,10022.192H586.731"
        transform="translate(131 -9514)"
        fill="none"
        stroke={props.fill || 'currentColor'}
        strokeLinecap="round"
        strokeWidth={props.strokeWidth || '2'}
      />
      <path
        d="M589,10018.469l-3.651,3.651,3.651,3.65"
        transform="translate(131 -9514)"
        fill="none"
        stroke={props.fill || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || '2'}
      />
    </g>
  </SVGIcon>
);

export const UserIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#6B7E8F" />
      <path
        d="M18.0832 19.25V18.0833C18.0832 17.4645 17.8373 16.871 17.3998 16.4334C16.9622 15.9958 16.3687 15.75 15.7498 15.75H12.2498C11.631 15.75 11.0375 15.9958 10.5999 16.4334C10.1623 16.871 9.9165 17.4645 9.9165 18.0833V19.25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9998 13.4167C15.2885 13.4167 16.3332 12.372 16.3332 11.0833C16.3332 9.79467 15.2885 8.75 13.9998 8.75C12.7112 8.75 11.6665 9.79467 11.6665 11.0833C11.6665 12.372 12.7112 13.4167 13.9998 13.4167Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

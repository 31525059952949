import { Base64 as base64 } from 'js-base64';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  PropsWithChildren,
  useRef,
} from 'react';
import {
  ErrorBoundary as SentryErrorBoundary,
  captureException,
  setTag,
} from '@sentry/react';

import {
  CallModel,
  CompilableCodeBlock,
  DataModel,
  Document as DxDocument,
} from './DxDom';
import { JSchemaForm } from './JSchemaForm';
import { CodeBox } from './AppLayout';
import { CodeHighlighter, HighlighterLanguage } from './CodeHighlighter';
import { ResponseViewer } from './ResponseViewer/ResponseViewer';
import { ProxyResponseInterface } from './ResponseViewer/ProxyResponseInterface';
import {
  CodeBlock,
  CodeBlockCode,
  Scrollable,
  PrimaryButton,
  DarkButton,
  FlexParent,
  IconButtonMixin,
  ExpandableCodeBlock,
  DefaultButton,
} from './StyledElements';
import { callEndpoint } from './ApiClient';
import { Div, Button, P, Em, Span } from './CleanSlate';
import {
  DataModelContextConsumer,
  DataModelContextProps,
} from './DataModelContext';
import { PortalContextConsumer } from './PortalContext';
import { PortalSettings } from './PortalSettings';
import { CancellablePromise, makeCancellable } from './CancellablePromise';
import { FileIcon } from './Icons/Ui/FileIcon';
import { SettingIcon } from './Icons/Ui/SettingIcon';
import { DocumentContextConsumer } from './DocumentContext';
import { CopyToClipboardComp } from './uiComponents/CopyToClipboard';
import { Callout } from './MultipleResponses/Callout';
import {
  ResponseHeaderContextConsumer,
  ResponseHeaderContextProps,
} from './Context/ResponseHeaderContext';
import {
  CodeSampleResponseDownloadEvent,
  CodeSamplesBackButtonInteractionEvent,
  ConfigureWindowEvent,
  docsConsoleCallEvent,
  docsConsoleCallOopsError,
  docsGetTokenOopsError,
  getTokenEvent,
  getTokenSuccessEvent,
  guidedWalkThroughEvents,
  requestExamplesDropdownClickEvent,
  requestExamplesDropdownMenuOptionSelectionEvent,
  rjsfFallbackError,
  trackEvent,
} from './Analytics';
import { ExpandCodeSampleIcon } from './Icons/Ui/ExpandCodeSample';
import { CollapseCodeSampleIcon } from './Icons/Ui/CollapseCodeSample';
import { HandleOutsideClick } from './HandleOutsideClick';
import styled from 'styled-components';
import { Component } from 'react';
import { AuthTokenCallout } from './Authorization/AuthTokenCallout';
import { KeyIcon } from './Icons/Ui/KeyIcon';
import {
  Notification,
  NotificationUiStateDef,
} from './Authorization/NotificationBar';
import { ResetIcon } from './Icons/Ui/ResetIcon';
import { authTokenValidator } from './Utilities/AuthTokenValidator';
import { getWalkthroughContentType } from './Utilities/utility';
import { StatusCode } from './ResponseViewer/StatusCode';
import { DarkTabPanel, DarkTabs } from './ResponseViewer/DarkTabs';
import {
  getSteps,
  WorkflowContext,
  WorkflowContextConsumer,
  WorkflowContextType,
  WorkflowEndpointConsumer,
  WorkflowEndpointContext,
  WorkflowEndpointContextType,
} from './Context/WorkflowContext';
import { LinkMapper, LinkMapperContext } from './LinkMapperContext';
import { isEmpty } from 'lodash';
import RequestExampleDropdown, {
  SelectedRequestValue,
} from './RequestExamplesDropdown';
import { PopupBackground } from './FuzzyPicker';
import ReactDOM from 'react-dom';
import { LiquidJS } from '@dx-portal/utils-liquid';
import { CodeBoxLoader } from './CodeBoxLoader';

const CONFIG_BUTTON_ID = 'code-config-button';

const DOC_CONSOLE_CALL = 'Guided Walkthrough Docs Console Call';

const RunButtonStyle = styled(PrimaryButton)<{
  calling?: boolean;
}>`
  height: 32px;
  line-height: 30px;
  font-weight: 400;
  color: ${({ theme }) => theme?.secondaryColor ?? ''};

  background: ${({ theme }) => (theme?.secondaryColor ? 'transparent' : '')};

  border-color: ${({ theme }) => theme?.secondaryColor ?? ''};

  &:disabled {
    cursor: ${({ calling }) => (calling ? 'wait' : 'default')};
    opacity: 0.5;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme?.secondaryColor ?? ''};
  }
`;
RunButtonStyle.displayName = 'RunButtonStyle';

export const ConfigButtonStyle = styled(DarkButton)`
  height: 32px;
  line-height: 30px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
`;
ConfigButtonStyle.displayName = 'ConfigButtonStyle';

export const IconButton = styled(Button)`
  ${IconButtonMixin};

  display: inline-flex;
  margin-right: 10px;
`;
IconButton.displayName = 'IconButton';

export const ExpandCodeBlockButton = styled(Button)`
  ${IconButtonMixin};
  width: 21px;
  height: 32px;
  position: absolute;
  right: 10px;
  top: 2px;
  z-index: 2;
  &:hover,
  &:focus {
    opacity: 1;
  }
  @media screen and (max-width: 990px) {
    display: none;
  }
`;

ExpandCodeBlockButton.displayName = 'ExpandCodeBlockButton';

export const CodeBlockTools = styled(Div)`
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: right;
  padding: 0 14px;

  @media screen and (max-width: 1100px) {
    padding: 0 10px;
  }
`;
CodeBlockTools.displayName = 'CodeBlockTools';

export const ModalWrapper = styled(Div)`
  background: #fff;
  max-width: 500px;
  min-height: 240px;
  max-height: 500px;
  position: absolute;
  bottom: 50px;
  border-radius: 8px;
  margin-right: 10px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  z-index: 100;

  @media (max-width: 521px) {
    width: 300px;
  }

  @media (max-height: 540px) {
    height: calc(100vh - 120px);
  }
`;
ModalWrapper.displayName = 'ModalWrapper';

export const ModalBody = styled(Div)`
  display: flex;
  flex-direction: column;

  @media (max-width: 521px) {
    width: 300px;
  }
  @media (max-height: 540px) {
    height: calc(100vh - 130px);
  }
`;
ModalBody.displayName = 'ModalBody';

export const ModelHeader = styled(Div)`
  height: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  position: absolute;
  right: 0;
`;
ModelHeader.displayName = 'ModelHeader';

export const ModelCloseIcon = styled(Div)`
  padding: 0 10px;
  font-size: 20px;
  line-height: 15px;
  font-weight: 500;
  text-align: end;
  cursor: pointer;
  z-index: 100;
  color: ${(props) => props.theme.colors.C801};
`;
ModelCloseIcon.displayName = 'ModelCloseIcon';

export const AuthWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  padding: 0 15px 40px;
  margin-top: -21px;
`;
AuthWrapper.displayName = 'AuthWrapper';

export const AuthActionItems = styled(Div)`
  display: flex;
`;
AuthActionItems.displayName = 'AuthActionItems';

const AuthorizeBtn = styled(DarkButton)`
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;
AuthorizeBtn.displayName = 'AuthorizeBtn';

const ResetAuthBtn = styled(DefaultButton)`
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;
ResetAuthBtn.displayName = 'ResetAuthButton';

export const HttpResponseViewerWrapper = styled(Div)`
  flex: 1;
  overflow: hidden;
`;

HttpResponseViewerWrapper.displayName = 'HttpResponseViewerWrapper';

export const StickyDiv = styled(Div)`
  top: 0;
  position: sticky;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
StickyDiv.displayName = 'StickyDiv';

type initialTabType = { initialOpenKey: string; index?: number };

export interface RCompilableCodeBlockCompState {
  code?: string;
  data?: CallModel;
  calling?: boolean;
  authorizing?: boolean;
  response?: ProxyResponseInterface;
  responseTriggerTime?: number;
  isAuthorized?: boolean;
  showConfig?: boolean;
  showCopiedTooltip: boolean;
  isCodeBlockExpanded: boolean;
  isHeader: boolean;
  initialTab: initialTabType;
  uiState: {
    notification: NotificationUiStateDef;
    lastCallType: 'auth' | 'endpoint';
    currentStep?: string;
  };
  selectedRequestExampleValue: SelectedRequestValue['value'];
}

interface RCompilableCodeBlockCompProps {
  node: CompilableCodeBlock;
  dataModelContext: DataModelContextProps;
  portalSettings: PortalSettings;
  onCodeUpdate?: (code: string) => void;
  responseHeadersContext: ResponseHeaderContextProps;
  workflowContext: WorkflowContextType;
  workflowEndpointContext: WorkflowEndpointContextType;
  linkMapper: LinkMapper;
  docs: DxDocument;
}

export interface APICallException extends Error {
  isCancelled: boolean;
}

/**
 * Error boundary component for JSchema form.
 *
 * TODO: Would be a good idea to generalize this and move it to a new file.
 */

interface ErrorBoundaryProps {
  fallback: RCompilableCodeBlockCompProps;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

interface CodeBlockRenderProps {
  renderCodeBlock: (shouldExpand?: boolean) => JSX.Element;
  renderResponseViewer: () => JSX.Element | null;
  enableConsoleCalls: boolean;
  updateTabIndex: (tab: initialTabType) => void;
  initialTab: initialTabType;
  response: ProxyResponseInterface | undefined;
  expandableRef: React.RefObject<HTMLButtonElement>;
  toggleCodeBlock: () => void;
  isCodeBlockExpanded?: boolean;
}

class FormErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { hasError: false };

  render() {
    return (
      <SentryErrorBoundary
        fallback={
          <Callout icon="alert">
            <strong>Oops!</strong> We are having trouble showing an API console
            for this endpoint right now.
          </Callout>
        }
        beforeCapture={(scope, error) => {
          const errorEvent = error as ErrorEvent;

          scope.setTag('apimaticAppReferance', 'API Playground');

          rjsfFallbackError(
            this.props.fallback.node,
            this.props.fallback.portalSettings,
            errorEvent?.message,
          );
        }}
      >
        {this.props.children}
      </SentryErrorBoundary>
    );
  }
}

function checkAuthInitialState(props: RCompilableCodeBlockCompProps) {
  const dataModelAuthLength =
    props.dataModelContext.dataModel.auth !== undefined &&
    props.dataModelContext.dataModel.auth !== null &&
    Object.getOwnPropertyNames(props.dataModelContext.dataModel.auth).length;

  const isAuthorized =
    props.dataModelContext.dataModel.auth !== undefined &&
    props.dataModelContext.dataModel.auth !== null &&
    dataModelAuthLength !== 0;

  return isAuthorized;
}

function parseStringToObj(str: string) {
  try {
    return JSON.parse(str);
  } catch {
    return {};
  }
}

export function CodeViewer(props: {
  textToCopy?: string;
  onDownload?: () => void;
  responseViewer: JSX.Element;
  IsCalled?: boolean;
  onBackButton: () => void;
  response?: ProxyResponseInterface;
  file?: Promise<unknown>;
  responseTriggerTime?: number;
  isHeader: boolean;
  portalSettings: PortalSettings;
  formData?: CallModel;
}) {
  const {
    responseViewer,
    textToCopy,
    onDownload,
    IsCalled,
    onBackButton,
    response,
    file,
    responseTriggerTime,
    isHeader,
    portalSettings,
    formData,
  } = props;
  const linkMapper = useContext(LinkMapperContext);
  const { getSelectedWorkflow, setStepState, onBackRef } =
    useContext(WorkflowContext);
  const { verify } = useContext(WorkflowEndpointContext);

  const { workflowName, workflowSteps } = getSelectedWorkflow(linkMapper);
  const { selectedStepValue } = getSteps(workflowSteps);

  if (onBackRef) {
    onBackRef.current = onBackButton;
  }

  const fileRef = useRef<Promise<unknown>>();
  fileRef.current = file;

  useEffect(() => {
    if (workflowName && selectedStepValue?.status !== 'complete') {
      if (fileRef.current) {
        fileRef.current.then((base64) => {
          setStepState(
            workflowName,
            { ...response, file: base64, responseTriggerTime },
            verify,
          );
        });
      } else {
        setStepState(
          workflowName,
          {
            ...response,
            responseTriggerTime,
            data: textToCopy ? parseStringToObj(textToCopy) : {},
            requestData: formData,
          },
          verify,
        );
      }
    }
  }, [
    workflowName,
    selectedStepValue?.status,
    response,
    setStepState,
    textToCopy,
    verify,
    responseTriggerTime,
    formData,
  ]);

  const showCodeBlockTools =
    (onDownload || textToCopy || IsCalled) && !isHeader;

  return (
    <>
      {responseViewer}
      {showCodeBlockTools && (
        <CodeBlockTools>
          <FlexParent>
            {onDownload && (
              <IconButton
                onClick={() => {
                  onDownload();
                  CodeSampleResponseDownloadEvent(portalSettings);
                }}
                varient={'dark'}
              >
                <FileIcon width="18" height="18" fill="#fff" />
              </IconButton>
            )}
            {textToCopy && (
              <Div style={{ marginRight: '10px' }}>
                <CopyToClipboardComp
                  text={textToCopy}
                  iconButtonType="dark"
                  from={'Response'}
                />
              </Div>
            )}
          </FlexParent>
          <RunButtonStyle onClick={onBackButton}>Back</RunButtonStyle>
        </CodeBlockTools>
      )}
    </>
  );
}

export const TryItOutButton = (props: {
  onRunButton: () => void;
  calling: boolean | undefined;
}) => {
  const { onRunButton, calling } = props;

  const { getSelectedWorkflow } = useContext(WorkflowContext);
  const linkMapper = useContext(LinkMapperContext);

  const { workflowSteps } = getSelectedWorkflow(linkMapper);

  const selectedStep = getSteps(workflowSteps);

  const { selectedStepValue } = selectedStep;

  const disableButton =
    selectedStepValue &&
    selectedStepValue.isSelected &&
    selectedStepValue.status === 'current';

  const hasWorkflowSteps = !!workflowSteps;
  const workflowDisabled = hasWorkflowSteps && !disableButton;

  return (
    <RunButtonStyle
      onClick={onRunButton}
      disabled={calling || workflowDisabled}
      calling={calling}
    >
      {calling ? 'RUNNING...' : 'TRY IT OUT'}
    </RunButtonStyle>
  );
};

/**
 * Renders the console and the code in the sidebar including the response viewer
 * and the buttons.
 *
 * TODO: Component does too much. Break apart when possible.
 */
class RCompilableCodeBlockComp extends Component<
  PropsWithChildren<RCompilableCodeBlockCompProps>,
  RCompilableCodeBlockCompState
> {
  state: RCompilableCodeBlockCompState = {
    showCopiedTooltip: false,
    isCodeBlockExpanded: false,
    isHeader: false,
    uiState: {
      notification: {
        show: false,
        message: '',
        type: 'default',
        dismissible: false,
      },
      lastCallType: 'endpoint',
    },
    initialTab: { initialOpenKey: 'Request', index: 0 },
    selectedRequestExampleValue: null,
  };

  /**
   * We use this to track the last code sample template rendered in a cancellable promise.
   * This promise should be cancelled before being replaced with a new one and also on
   * component unmount.
   */
  lastTplRenderPromise?: CancellablePromise<string>;
  lastProxyCallPromise?: CancellablePromise<ProxyResponseInterface>;
  lastAuthCallPromise?: CancellablePromise<ProxyResponseInterface>;
  authPopupWindow: Window | null = null;
  expandableRef: React.RefObject<HTMLButtonElement>;
  liquidJsInstance: LiquidJS;

  constructor(props: RCompilableCodeBlockCompProps) {
    super(props);
    const isAuthorized = checkAuthInitialState(props);

    this.expandableRef = React.createRef();

    const {
      dataModelContext: { reInitializeLiquidInstance },
      node: { Globals },
      docs: { PartialTemplates },
    } = props;

    this.liquidJsInstance = new LiquidJS(PartialTemplates, Globals);

    reInitializeLiquidInstance(this.liquidJsInstance);

    this.state = {
      ...this.state,
      isAuthorized: isAuthorized,
      uiState: {
        notification: {
          show: isAuthorized,
          message: isAuthorized ? 'Authorized Successfully' : '',
          type: isAuthorized ? 'success' : 'default',
        },
        lastCallType: 'endpoint',
      },
    };
  }

  static getSelectedStep = (props: RCompilableCodeBlockCompProps) => {
    const { workflowContext, linkMapper } = props;
    const { getSelectedWorkflow, formDataRef } = workflowContext;
    const { workflowSteps } = getSelectedWorkflow(linkMapper);
    const { selectedStepName, selectedStepValue, nextStepName } =
      getSteps(workflowSteps);
    return {
      selectedStepValue,
      selectedStepName,
      isLastStep: !nextStepName,
      formDataRef,
    };
  };

  static getDerivedStateFromProps(
    props: RCompilableCodeBlockCompProps,
    state: RCompilableCodeBlockCompState,
  ) {
    const { selectedStepValue, selectedStepName } =
      RCompilableCodeBlockComp.getSelectedStep(props);
    const { state: stepState = {}, status } = selectedStepValue || {};
    const { file, data, ...response } = stepState as ProxyResponseInterface & {
      data: unknown;
      file: unknown;
    };

    if (status) {
      switch (status) {
        case 'complete':
          if (selectedStepName !== state.uiState.currentStep) {
            // Set response
            // Default tab to response
            // set currentStep
            return {
              ...state,
              response,
              data: selectedStepValue?.formData,
              initialTab: { initialOpenKey: 'Response', index: 1 },
              uiState: {
                ...state.uiState,
                currentStep: selectedStepName,
              },
            };
          } else {
            // only set response
            return {
              ...state,
              data: selectedStepValue?.formData,
              response,
            };
          }
        case 'incomplete':
          // Response would be undefined
          return {
            ...state,
            response: undefined,
            responseTime: undefined,
            uiState: {
              ...state.uiState,
              currentStep: selectedStepName,
            },
          };
        case 'current':
          if (selectedStepName !== state.uiState.currentStep) {
            return {
              ...state,
              response:
                !isEmpty(response) && selectedStepValue?.verified
                  ? response
                  : undefined,
              uiState: {
                ...state.uiState,
                currentStep: selectedStepName,
              },
            };
          } else {
            return {
              ...state,
              uiState: {
                ...state.uiState,
                currentStep: selectedStepName,
              },
            };
          }
      }
    }

    return state;
  }

  componentWillUnmount() {
    // cancel promises to prevent them from mutating component state after unmount
    if (this.lastTplRenderPromise) {
      this.lastTplRenderPromise.cancel();
    }
    if (this.lastProxyCallPromise) {
      this.lastProxyCallPromise.cancel();
    }
    if (this.lastAuthCallPromise) {
      this.lastAuthCallPromise.cancel();
    }
  }

  componentDidMount() {
    const {
      workflowContext: { resetCallbackRef },
    } = this.props;

    if (resetCallbackRef) {
      resetCallbackRef.current = this.onResetStep;
    }

    this.onCallModelChange(this.props.node.CallModel);
  }

  getStepData = (props: RCompilableCodeBlockCompProps) => {
    const {
      workflowContext: { getSelectedWorkflow, formDataRef },
      linkMapper,
    } = props;
    const { workflowSteps } = getSelectedWorkflow(linkMapper);
    const { selectedStepName = '', selectedStepValue } =
      getSteps(workflowSteps);

    return {
      selectedStepName,
      status: selectedStepValue?.status,
      data: selectedStepValue?.formData,
      formData: formDataRef?.current[selectedStepName],
    };
  };

  onResetStep = () => {
    const {
      workflowEndpointContext: { payload },
    } = this.props;

    const stepPayload = payload as CallModel;

    if (stepPayload.args) {
      this.onCallModelChange(stepPayload);
    } else {
      this.onCallModelChange(this.props.node.CallModel);
    }
  };

  componentDidUpdate(
    prevProps: RCompilableCodeBlockCompProps,
    prevState: RCompilableCodeBlockCompState,
  ) {
    const { status: currentStatus } = this.getStepData(this.props);
    const {
      workflowEndpointContext: { payload },
    } = this.props;

    const callModelPaylaod = payload as CallModel;

    if (
      currentStatus !== 'complete' &&
      prevProps.node.CallModel !== this.props.node.CallModel
    ) {
      this.onCallModelChange(callModelPaylaod || this.props.node.CallModel);
    } else if (prevProps.dataModelContext !== this.props.dataModelContext) {
      if (callModelPaylaod?.args) {
        if (prevProps.node.EndpointName !== this.props.node.EndpointName) {
          this.onCallModelChange(callModelPaylaod);
        }
      } else {
        this.onCallModelChange(this.state.data || this.props.node.CallModel);
      }
    }
  }

  /**
   * Update call model and hence and the code
   */
  onCallModelChange = (data: CallModel) => {
    const { node } = this.props;
    const lang = Object.keys(node.Templates)[0] as keyof typeof node.Templates;

    const dataModel = {
      ...this.props.dataModelContext.dataModel,
      call: data,
    };

    // inefficient
    if (this.lastTplRenderPromise) {
      this.lastTplRenderPromise.cancel(); // cancel last tpl render
    }

    this.lastTplRenderPromise = makeCancellable(
      this.liquidJsInstance.render(node.Templates[lang] || '', dataModel),
    );
    this.lastTplRenderPromise.promise
      .then((code) => {
        this.setState({
          code,
          data: dataModel.call,
        });
        if (this.props.onCodeUpdate) {
          this.props.onCodeUpdate(code);
        }
      })
      .catch((ex) => {
        return true;
      }); // do nothing
  };

  showCopiedTooltip = () => {
    this.setState({
      showCopiedTooltip: true,
    });
    setTimeout(() => {
      this.setState({
        showCopiedTooltip: false,
      });
    }, 1000);
  };

  /**
   * Event handler for when run button is pressed.
   *
   * This calls the current endpoint using the ApiClient and shows the
   * response using the response viewer.
   */
  onRunButton = () => {
    const {
      portalSettings,
      responseHeadersContext: {
        responseHeaders: { apiDigest },
      },
      workflowContext: { formDataRef, getSelectedWorkflow },
      linkMapper,
      node: {
        EndpointName,
        EndpointGroupName,
        Index,
        Templates,
        HttpCallTemplate,
      },
    } = this.props;

    const { workflowName, workflowSteps } = getSelectedWorkflow(linkMapper);
    const { selectedStepName, selectedStepValue } = getSteps(workflowSteps);
    const stepType = getWalkthroughContentType(selectedStepValue?.isContent);

    if (formDataRef && selectedStepName) {
      formDataRef.current[selectedStepName] = this.state.data;
    }

    this.setState((st) => ({
      ...st,
      calling: true,
      uiState: {
        ...st.uiState,
        lastCallType: 'endpoint',
      },
    }));

    const dataModel: DataModel = {
      ...this.props.dataModelContext.dataModel,
      call: this.state.data,
    };

    const dis = this;

    if (this.lastProxyCallPromise) {
      this.lastProxyCallPromise.cancel(); // cancel last proxy call
    }

    const lang = Object.keys(Templates)[0];
    this.lastProxyCallPromise = makeCancellable(
      // TODO That's a ton of args. Refactor and improve this.
      callEndpoint(
        EndpointName,
        EndpointGroupName,
        Index,
        dataModel,
        HttpCallTemplate,
        portalSettings,
        apiDigest,
        lang,
        this.liquidJsInstance,
        false,
      ),
    );

    this.lastProxyCallPromise.promise
      .then(function (json: ProxyResponseInterface) {
        dis.setState({
          response: json,
          responseTriggerTime: Date.now(),
          calling: false,
          initialTab: { initialOpenKey: 'Response', index: 1 },
        });

        workflowName &&
          guidedWalkThroughEvents(
            DOC_CONSOLE_CALL,
            dis.props.portalSettings,
            workflowName,
            selectedStepName,
            stepType,
            json.StatusCode,
            json.ReasonPhrase,
          );

        // Analytics Events
        if (!json.IsCalled) {
          // On Failure
          docsConsoleCallOopsError(
            dis.props.portalSettings,
            dis.props.node,
            `${json.StatusCode}: ${json.ReasonPhrase}`,
          );
        } else {
          // On Success
          docsConsoleCallEvent(
            dis.props.portalSettings,
            dis.props.node,
            json.StatusCode,
            json.ReasonPhrase,
          );
        }
      })
      .catch(function (err?: unknown) {
        const error = err as APICallException;

        if (error && error.isCancelled) {
          return; // don't do anything if promise was cancelled
        }

        if (dis.props.portalSettings.useProxyForConsoleCalls) {
          const response = err as ProxyResponseInterface;
          dis.setState({
            response,
            responseTriggerTime: Date.now(),
            calling: false,
            initialTab: { initialOpenKey: 'Response', index: 1 },
          });
        } else {
          dis.setState({
            response: {
              IsCalled: false,
            },
            responseTriggerTime: Date.now(),
            initialTab: { initialOpenKey: 'Response', index: 1 },
            calling: false,
          });
        }

        docsConsoleCallOopsError(
          dis.props.portalSettings,
          dis.props.node,
          error?.message,
        );
      });
  };

  /**
   * Event handler for going back from response viewer
   */
  onBackButton = () => {
    this.setState({
      response: undefined,
      responseTriggerTime: undefined,
      initialTab: { initialOpenKey: 'Request', index: 0 },
    });
    CodeSamplesBackButtonInteractionEvent(this.props.portalSettings);
  };

  /**
   * Even handler toggling config
   */
  onConfigToggle = () => {
    const configStatus = this.state.showConfig ? 'closed' : 'opened';
    ConfigureWindowEvent(this.props.portalSettings, configStatus);
    this.setState({ showConfig: !this.state.showConfig });
  };

  onConfigClose = (element: HTMLElement) => {
    if (element.id !== CONFIG_BUTTON_ID) {
      ConfigureWindowEvent(this.props.portalSettings, 'closed');
      this.setState({ showConfig: false });
    }
  };

  /**
   * Event handler for when show-full-code checkbox is toggled
   */
  onShowFullCodeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.props.dataModelContext.updateDataModel({
      ...this.props.dataModelContext.dataModel,
      showFullCode: ev.target.checked,
    });
  };

  onCancelAuthClick = () => {
    this.setState({
      authorizing: false,
    });

    if (this.lastAuthCallPromise && this.authPopupWindow) {
      this.authPopupWindow.close();
      this.lastAuthCallPromise.cancel();
    }
  };

  getAuthUrl = (
    callbackUrlEncoded: string,
    jsonEncodedAuthUrl: string,
    jsonEncodedTokenUrl: string,
  ) => {
    return (
      JSON.parse(jsonEncodedAuthUrl) +
      '&redirect_uri=' +
      callbackUrlEncoded +
      '&state=' +
      encodeURI(
        callbackUrlEncoded +
          ';' +
          encodeURI(
            this.props.dataModelContext.dataModel.config.OAuthClientId + '',
          ) +
          ';' +
          encodeURI(
            this.props.dataModelContext.dataModel.config.OAuthClientSecret + '',
          ) +
          ';' +
          encodeURI(JSON.parse(jsonEncodedTokenUrl)) +
          ';' +
          encodeURI(window.location.origin),
      )
    );
  };

  authPopupWindowPromise = (authUrl: string) => {
    return new Promise(
      (resolve: (value: ProxyResponseInterface) => void, reject) => {
        const messageEventHandler = (event: MessageEvent) => {
          window.removeEventListener('message', messageEventHandler, false);
          const callbackUrl = new URL(
            this.props.portalSettings.codegenApiRoutes.oauth2Callback,
          );
          if (event.origin === callbackUrl.origin) {
            // resolved = true;
            resolve(event.data);
          }
        };

        this.authPopupWindow = window.open(
          authUrl,
          '_blank',
          'width=800,height=600,status=0,toolbar=0',
        );

        if (this.authPopupWindow === null) {
          reject({
            IsCalled: false,
          });
          return;
        }

        // capture close event of cross browser popup
        const timer = setInterval(() => {
          if (this.authPopupWindow && this.authPopupWindow.closed) {
            clearInterval(timer);
            this.setState({
              authorizing: false,
            });
          }
        }, 1000);

        window.addEventListener('message', messageEventHandler, false);
      },
    );
  };

  onAuthorizeClick = (doc: DxDocument) => {
    const dis = this;
    if (this.lastAuthCallPromise) {
      this.lastAuthCallPromise.cancel();
    }

    if (doc.ApiAuthentication) {
      this.lastAuthCallPromise = makeCancellable(
        Promise.all([
          this.liquidJsInstance.render(
            doc.ApiAuthentication.AuthorizationUrlTemplate as string,
            this.props.dataModelContext.dataModel,
          ),
          this.liquidJsInstance.render(
            doc.ApiAuthentication.AccessTokenUrlTemplate as string,
            this.props.dataModelContext.dataModel,
          ),
        ]).then(([jsonEncodedAuthUrl, jsonEncodedTokenUrl]) => {
          const callbackUrlEncoded = encodeURI(
            this.props.portalSettings.codegenApiRoutes.oauth2Callback,
          );
          const authUrl = this.getAuthUrl(
            callbackUrlEncoded,
            jsonEncodedAuthUrl,
            jsonEncodedTokenUrl,
          );
          return this.authPopupWindowPromise(authUrl);
        }),
      );
    } else {
      const {
        portalSettings,
        responseHeadersContext: {
          responseHeaders: { apiDigest },
        },
        dataModelContext: { dataModel },
        node: { EndpointName, EndpointGroupName, Index, Templates },
      } = this.props;

      const lang = Object.keys(Templates)[0];

      const endpointCall = callEndpoint(
        EndpointName,
        EndpointGroupName,
        Index,
        dataModel,
        doc.AuthHttpCallTemplate! as string,
        portalSettings,
        apiDigest,
        lang,
        this.liquidJsInstance,
        true,
      );

      this.lastAuthCallPromise = makeCancellable(endpointCall);
    }

    dis.setState((st) => ({
      ...st,
      uiState: {
        ...st.uiState,
        lastCallType: 'auth',
      },
      authorizing: true,
    }));

    this.lastAuthCallPromise.promise
      .then((response) => {
        if (
          response.IsCalled &&
          response.StatusCode >= 200 &&
          response.StatusCode < 300
        ) {
          const decodedBody = base64.decode(response.RawContent);
          const authResponse = authTokenValidator(JSON.parse(decodedBody));
          if (
            Object.prototype.hasOwnProperty.call(authResponse, 'access_token')
          ) {
            this.props.dataModelContext.updateDataModel({
              ...this.props.dataModelContext.dataModel,
              auth: authResponse,
            });
            getTokenSuccessEvent(this.props.portalSettings);
            // Access token expiry start time in seconds
            localStorage.setItem(
              'token_stamp',
              JSON.stringify(Date.now() / 1000),
            );
            dis.setState((st) => ({
              ...st,
              authorizing: false,
              isAuthorized: true,
              uiState: {
                ...st.uiState,
                notification: {
                  show: true,
                  message: 'Authorization Successful.',
                  type: 'success',
                },
              },
            }));
          } else {
            dis.setState((st) => ({
              ...st,
              authorizing: false,
              isAuthorized: false,
              uiState: {
                ...st.uiState,
                notification: {
                  show: true,
                  message: 'Authorization Error.',
                  type: 'error',
                },
              },
            }));
          }
        } else {
          dis.setState({
            authorizing: false,
            response,
            isAuthorized: false,
            initialTab: { initialOpenKey: 'Response', index: 1 },
          });
        }
      })
      .catch((err?: unknown) => {
        const error = err as APICallException;

        if (error && error.isCancelled) {
          return; // don't do anything if promise was cancelled
        }

        if (dis.props.portalSettings.useProxyForConsoleCalls) {
          const response = err as ProxyResponseInterface;
          dis.setState({
            authorizing: false,
            isAuthorized: false,
            response,
            initialTab: { initialOpenKey: 'Response', index: 1 },
            showConfig: false,
          });
        } else {
          dis.setState({
            authorizing: false,
            isAuthorized: false,
            response: { IsCalled: false },
            responseTriggerTime: Date.now(),
            initialTab: { initialOpenKey: 'Response', index: 1 },
            showConfig: false,
          });
        }

        docsGetTokenOopsError(
          dis.props.portalSettings,
          dis.props.node,
          error?.message,
        );
        setTag('apimaticAppReferance', 'Get Token via OAuth 2.0');
        captureException(error);
      });
  };

  handleGetToken = (docs: DxDocument) => {
    getTokenEvent(this.props.portalSettings);
    this.onAuthorizeClick(docs);
  };

  onResetAuthClick = () => {
    this.props.dataModelContext.updateDataModel({
      ...this.props.dataModelContext.dataModel,
      auth: null,
    });
    this.setState((st) => ({
      ...st,
      isAuthorized: false,
      uiState: {
        ...st.uiState,
        notification: {
          show: false,
          message: '',
          type: 'default',
        },
      },
    }));
  };

  /**
   * Should the call form be visible?
   */
  shouldCallFormRender = () => {
    const schema = this.props.node.CallModelSchema;
    return (
      (schema.properties &&
        schema.properties.args &&
        typeof schema.properties.args !== 'boolean' &&
        schema.properties.args.properties) ||
      (schema.properties && schema.properties.additionalQueryParams) ||
      (schema.properties && schema.properties.additionalQueryParams)
    );
  };

  toggleCodeBlock = () => {
    this.setState((prevState) => {
      return { isCodeBlockExpanded: !prevState.isCodeBlockExpanded };
    });
  };

  onOutsideClick = () => {
    if (this.state.isCodeBlockExpanded) {
      this.setState({
        isCodeBlockExpanded: false,
      });
    }
  };

  onNotificationDismiss = () => {
    this.setState((st) => ({
      ...st,
      uiState: {
        ...st.uiState,
        notification: {
          show: false,
          message: '',
          type: 'default',
          dismissible: false,
        },
      },
    }));
  };

  onRetry = () => {
    this.onBackButton();
    this.onRunButton();
  };

  onHeaderClick = (isHeader: boolean) => {
    this.setState((prevState) => ({
      ...prevState,
      isHeader,
    }));
  };

  /**
   * Render response viewer along with back button.
   *
   * Response viewer is visible when a user "runs" an endpoint.
   */
  renderResponseViewer = () => {
    return !this.state.response ? null : (
      <CodeBlock type="column">
        <ResponseViewer
          isProxy={this.props.portalSettings.useProxyForConsoleCalls}
          response={this.state.response}
          onRetry={
            this.state.uiState.lastCallType === 'endpoint'
              ? this.onRetry
              : undefined
          }
          onBack={this.onBackButton}
          onHeaderClick={this.onHeaderClick}
          portalSettings={this.props.portalSettings}
        >
          {({ responseViewer, onDownload, textToCopy, file }) => (
            <CodeViewer
              formData={this.state.data}
              responseViewer={responseViewer}
              onDownload={onDownload}
              onBackButton={this.onBackButton}
              IsCalled={this.state.response?.IsCalled}
              textToCopy={textToCopy}
              response={this.state.response}
              responseTriggerTime={this.state.responseTriggerTime}
              file={file}
              isHeader={this.state.isHeader}
              portalSettings={this.props.portalSettings}
            />
          )}
        </ResponseViewer>
      </CodeBlock>
    );
  };

  /**
   * Render show-full-code checkbox
   */
  renderShowFullCodeCheckbox = () => {
    return Object.keys(this.props.node.Templates)[0] ===
      'HTTP_CURL_V1' ? null : (
      <Span
        style={{
          float: 'left',
          color: '#fff',
          fontSize: '12px',
          marginTop: '6px',
        }}
      >
        <label>
          <input
            type="checkbox"
            checked={this.props.dataModelContext.dataModel.showFullCode}
            onChange={this.onShowFullCodeChange}
            style={{ verticalAlign: 'middle' }}
          />
          &nbsp; Show Complete File
        </label>
      </Span>
    );
  };

  /**
   * Toggle config form visibility
   */
  renderConfigButton = () => {
    return (
      <ConfigButtonStyle id={CONFIG_BUTTON_ID} onClick={this.onConfigToggle}>
        <SettingIcon fill="#fff" />
        &nbsp; Configure
      </ConfigButtonStyle>
    );
  };

  onConfigUpdate = (data: DataModel) => {
    const {
      dataModelContext: { updateDataModel },
    } = this.props;
    updateDataModel(data);
    this.onCallModelChange(this.state.data as CallModel);
  };

  renderConfigModal = () => {
    const {
      dataModelContext: { dataModelSchema, dataModel, definitions },
    } = this.props;

    return (
      <ModalWrapper>
        <HandleOutsideClick onOutsideClick={this.onConfigClose}>
          <ModelHeader>
            <ModelCloseIcon onClick={this.onConfigToggle}>
              &times;
            </ModelCloseIcon>
          </ModelHeader>
          <ModalBody>
            <DocumentContextConsumer>
              {(doc) =>
                doc && (
                  <>
                    <Scrollable
                      style={{ minHeight: '220px', maxHeight: '420px' }}
                    >
                      <JSchemaForm
                        schema={dataModelSchema}
                        data={dataModel}
                        levelReversal={true}
                        onChange={this.onConfigUpdate}
                        disableFormJsonEdit={true}
                        definitions={definitions}
                        removeViewJsonButton={true}
                        portalSettings={this.props.portalSettings}
                        trackEvent={trackEvent}
                      />

                      {(doc.AuthHttpCallTemplate || doc.ApiAuthentication) &&
                        !doc?.Version && (
                          <AuthWrapper>
                            {this.state.isAuthorized &&
                              dataModel.auth &&
                              Object.keys(dataModel.auth).length !== 0 && (
                                <AuthTokenCallout
                                  authToken={dataModel.auth}
                                  onTokenExpiry={this.onResetAuthClick}
                                />
                              )}
                            <AuthActionItems>
                              {!this.state.isAuthorized && (
                                <>
                                  {this.props.portalSettings
                                    .enableConsoleCalls && (
                                    <AuthorizeBtn
                                      // TODO Do not use lambda functions in render()
                                      onClick={() => this.handleGetToken(doc)}
                                      disabled={this.state.authorizing}
                                    >
                                      {this.state.authorizing ? (
                                        'Getting Token...'
                                      ) : (
                                        <>
                                          <KeyIcon stroke="#FFF" /> Get Token
                                        </>
                                      )}
                                    </AuthorizeBtn>
                                  )}
                                  {this.state.authorizing && (
                                    <DefaultButton
                                      onClick={this.onCancelAuthClick}
                                    >
                                      Cancel
                                    </DefaultButton>
                                  )}
                                </>
                              )}

                              {this.state.isAuthorized && (
                                <ResetAuthBtn onClick={this.onResetAuthClick}>
                                  <ResetIcon fill="#282D44" />
                                  Reset Token
                                </ResetAuthBtn>
                              )}
                            </AuthActionItems>
                          </AuthWrapper>
                        )}
                    </Scrollable>
                    {!doc?.Version && (
                      <Notification
                        {...this.state.uiState.notification}
                        onDismiss={this.onNotificationDismiss}
                      />
                    )}
                  </>
                )
              }
            </DocumentContextConsumer>
          </ModalBody>
        </HandleOutsideClick>
      </ModalWrapper>
    );
  };

  setSelectedRequestExample = (value: SelectedRequestValue['value']) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedRequestExampleValue: value,
    }));
  };

  /**
   * Render codeblock, show full code checkbox and copy+run button in the side
   */
  renderCodeBlock = (shouldExpand?: boolean) => {
    const {
      node: { Templates, EndpointName, EndpointGroupName, Examples },
      portalSettings,
      workflowContext: { getSelectedWorkflow },
      linkMapper,
    } = this.props;
    const { workflowName } = getSelectedWorkflow(linkMapper);
    const currentTemplate = Object.keys(Templates)[0];
    const hasMultipleRequestExample = Boolean(
      Examples && Object.keys(Examples).length,
    );
    const hasNoWorkFlow = !workflowName;
    const shouldShowConfig =
      this.state.showConfig &&
      ((!shouldExpand && !this.state.isCodeBlockExpanded) ||
        (shouldExpand && this.state.isCodeBlockExpanded));

    const { selectedRequestExampleValue } = this.state;

    return this.state.code ? (
      <DocumentContextConsumer>
        {(doc) => {
          const language = doc?.lang ? HighlighterLanguage[doc.lang] : 'bash';

          return (
            <CodeBlock type="column">
              <StickyDiv>
                {hasMultipleRequestExample && hasNoWorkFlow && (
                  <RequestExampleDropdown
                    examples={Examples}
                    selectedValue={selectedRequestExampleValue}
                    onChange={(value) => {
                      const obj = {
                        args: value?.value.Value,
                      };
                      this.onCallModelChange(obj as CallModel);
                      this.setSelectedRequestExample(value);
                      requestExamplesDropdownMenuOptionSelectionEvent(
                        portalSettings,
                        EndpointName,
                        value?.label,
                      );
                    }}
                    onFocus={() => {
                      requestExamplesDropdownClickEvent(
                        portalSettings,
                        EndpointName,
                      );
                    }}
                  />
                )}
              </StickyDiv>
              <CodeBlockCode
                className="code-block-code"
                scrollWidth="4px"
                scrollHeight="4px"
                invert={true}
              >
                <CodeHighlighter
                  showLineNumbers={false}
                  lang={language}
                  code={this.state.code || ''}
                  dark={true}
                  hasMultipleRequestExample={
                    hasMultipleRequestExample && hasNoWorkFlow
                  }
                />
              </CodeBlockCode>
              <CodeBlockTools>
                {shouldShowConfig && this.renderConfigModal()}
                <Div>{this.renderConfigButton()}</Div>
                <FlexParent>
                  <Div style={{ marginRight: '10px' }}>
                    <CopyToClipboardComp
                      text={this.state.code || ''}
                      iconButtonType="dark"
                      from="Request Code"
                      analyticsData={{
                        template: currentTemplate,
                        sectionType: 'code-sample',
                        endpointName: EndpointName,
                        endpointGroupName: EndpointGroupName,
                      }}
                    />
                  </Div>

                  {this.props.portalSettings.enableConsoleCalls && (
                    <TryItOutButton
                      onRunButton={this.onRunButton}
                      calling={this.state.calling}
                    />
                  )}
                </FlexParent>
              </CodeBlockTools>
            </CodeBlock>
          );
        }}
      </DocumentContextConsumer>
    ) : (
      <CodeBoxLoader />
    );
  };
  /**
   * Render the console using json schema
   */
  renderConsole = () => {
    const {
      dataModelContext: { definitions },
    } = this.props;

    return this.shouldCallFormRender() ? (
      <FormErrorBoundary fallback={this.props}>
        <JSchemaForm
          schema={this.props.node.CallModelSchema}
          data={this.state.data!}
          expandAllLevel={3}
          isExpandable={this.props.node.isExpandable}
          onChange={this.onCallModelChange}
          className="rjsf-content"
          definitions={definitions}
          isConsole={true}
          portalSettings={this.props.portalSettings}
          trackEvent={trackEvent}
        />
      </FormErrorBoundary>
    ) : (
      <Callout icon="info">This endpoint does not take any parameters.</Callout>
    );
  };

  updateTabIndex = (tab: initialTabType) => {
    this.setState({ initialTab: tab });
  };

  render() {
    const { isCodeBlockExpanded, response, initialTab } = this.state;
    const enableConsoleCalls = this.props.portalSettings.enableConsoleCalls;

    return (
      <>
        {this.renderConsole()}
        <CodeBox>
          <CodeBlockRender
            renderCodeBlock={this.renderCodeBlock}
            renderResponseViewer={this.renderResponseViewer}
            enableConsoleCalls={enableConsoleCalls}
            updateTabIndex={this.updateTabIndex}
            initialTab={initialTab}
            response={response}
            expandableRef={this.expandableRef}
            toggleCodeBlock={this.toggleCodeBlock}
            isCodeBlockExpanded={false}
          />
        </CodeBox>
        {isCodeBlockExpanded &&
          ReactDOM.createPortal(
            <PopupBackground isCodeSample={true}>
              <CodeBlockRender
                renderCodeBlock={this.renderCodeBlock}
                renderResponseViewer={this.renderResponseViewer}
                enableConsoleCalls={enableConsoleCalls}
                updateTabIndex={this.updateTabIndex}
                initialTab={initialTab}
                response={response}
                expandableRef={this.expandableRef}
                toggleCodeBlock={this.toggleCodeBlock}
                isCodeBlockExpanded={true}
              />
            </PopupBackground>,
            document.body,
          )}
      </>
    );
  }
}

export const CodeBlockRender = ({
  toggleCodeBlock,
  expandableRef,
  response,
  initialTab,
  renderCodeBlock,
  updateTabIndex,
  enableConsoleCalls,
  renderResponseViewer,
  isCodeBlockExpanded,
}: CodeBlockRenderProps) => {
  const { initialOpenKey, index } = initialTab;
  const isStatusCode = initialOpenKey === 'Response';

  const hasResponse =
    isStatusCode && response && response.IsCalled && response.StatusCode;
  return (
    <ExpandableCodeBlock
      className={isCodeBlockExpanded ? 'ExpandedCodeSample' : ''}
    >
      <HttpResponseViewerWrapper>
        <ExpandCodeBlockButton
          onClick={toggleCodeBlock}
          ref={expandableRef}
          data-testid="expand-code-block-button"
        >
          {isCodeBlockExpanded ? (
            <CollapseCodeSampleIcon />
          ) : (
            <ExpandCodeSampleIcon />
          )}
        </ExpandCodeBlockButton>
        {hasResponse && (
          <StatusCode
            code={response.StatusCode}
            reason={response.ReasonPhrase}
          />
        )}
      </HttpResponseViewerWrapper>
      <DarkTabs
        initialOpenKey={initialOpenKey}
        index={index}
        getActiveTab={(data) => {
          updateTabIndex(data);
        }}
        fromCodeSample={true}
      >
        <DarkTabPanel title="Request">
          {renderCodeBlock(isCodeBlockExpanded)}
        </DarkTabPanel>
        <DarkTabPanel
          title="Response"
          isDisable={!response || isEmpty(response)}
          isHidden={enableConsoleCalls}
        >
          {renderResponseViewer()}
        </DarkTabPanel>
      </DarkTabs>
    </ExpandableCodeBlock>
  );
};

export interface RCompilableCodeBlockProps {
  node: CompilableCodeBlock;
  onCodeUpdate?: (code: string) => void;
}

/**
 * It should be class component because loadale lib require render function
 */
export class RCompilableCodeBlock extends Component<RCompilableCodeBlockProps> {
  render() {
    return (
      <WorkflowEndpointConsumer>
        {(workflowEndpointContext) => (
          <WorkflowContextConsumer>
            {(worflowContext) => (
              <LinkMapperContext.Consumer>
                {(linkMapper) => (
                  <PortalContextConsumer>
                    {(settings) => (
                      <DocumentContextConsumer>
                        {(doc) =>
                          doc && (
                            <DataModelContextConsumer>
                              {(dataModelContext) => (
                                <ResponseHeaderContextConsumer>
                                  {(responseHeaders) =>
                                    dataModelContext && (
                                      <RCompilableCodeBlockComp
                                        node={this.props.node}
                                        dataModelContext={dataModelContext}
                                        portalSettings={settings!}
                                        onCodeUpdate={this.props.onCodeUpdate}
                                        responseHeadersContext={
                                          responseHeaders!
                                        }
                                        workflowContext={worflowContext}
                                        linkMapper={linkMapper}
                                        workflowEndpointContext={
                                          workflowEndpointContext
                                        }
                                        docs={doc}
                                      />
                                    )
                                  }
                                </ResponseHeaderContextConsumer>
                              )}
                            </DataModelContextConsumer>
                          )
                        }
                      </DocumentContextConsumer>
                    )}
                  </PortalContextConsumer>
                )}
              </LinkMapperContext.Consumer>
            )}
          </WorkflowContextConsumer>
        )}
      </WorkflowEndpointConsumer>
    );
  }
}

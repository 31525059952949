import * as React from 'react';
import styled from 'styled-components';

import { Div } from 'src/CleanSlate';
import { ScrollableMixin, BaseShadowMixin } from 'src/StyledElements';

interface MenuScrollWrapperProps {
  toggleMenuSelect?: boolean;
  selectedItemKey?: string;
  scrollWidth?: string;
  overflowX?: boolean;
}

const MenuScrollWrapper = styled(Div)<MenuScrollWrapperProps>`
  ${ScrollableMixin};
  height: 100%;
  padding-right: 10px;

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  @media screen and (max-width: 990px) {
    background: ${(props) => props.theme.colors.C201};
    width: 100%;
    max-width: 300px;
    height: calc(100% - 140px);
    display: ${(props) => (props.toggleMenuSelect ? 'block' : 'none')};
    position: absolute;
    top: 75px;
    left: 0;
    z-index: 100;
    margin-top: 0;
    ${BaseShadowMixin};
    padding-top: 10px;
  }
  @media screen and (max-width: 575px) {
    top: 60px;
  }
`;
MenuScrollWrapper.displayName = 'MenuScrollWrapper';

export class MenuScroll extends React.Component<
  React.PropsWithChildren<MenuScrollWrapperProps>
> {
  private menuScrollRef = React.createRef<HTMLDivElement>();

  triggerScroll = () => {
    const domNode = this.menuScrollRef.current;
    if (domNode) {
      const selectElement = domNode.querySelector('.rc-menu-item-selected');

      if (selectElement) {
        const containerElementHeight = domNode.clientHeight + 100;
        const selectedElementOffset = selectElement.getBoundingClientRect().top;

        // 75 is added to increase error margin,
        // in case if any element coinciding with the lower boundary of the navbar is selected
        // then it doesn't scroll automatically. It stays in the viewport in its position.
        const isSelectedElementBelowViewport =
          selectedElementOffset > containerElementHeight + 75;

        // whenever any element leaves the viewport of Navbar, its top position is less than 100.
        const isSelectedElementAboveViewport = selectedElementOffset < 100;

        const isSelectedElementNotInViewport =
          isSelectedElementBelowViewport || isSelectedElementAboveViewport;

        if (isSelectedElementNotInViewport) {
          domNode.scrollTo({
            top:
              domNode.scrollTop +
              selectElement.getBoundingClientRect().top -
              350,
          });
        }
      }
    }
  };

  componentDidUpdate(prevProps: MenuScrollWrapperProps) {
    if (this.props.selectedItemKey !== prevProps.selectedItemKey) {
      this.triggerScroll();
    }
  }

  componentDidMount() {
    this.triggerScroll();
  }

  render() {
    return (
      <MenuScrollWrapper
        ref={this.menuScrollRef}
        toggleMenuSelect={this.props.toggleMenuSelect}
        scrollWidth="4px"
        overflowX={false}
      >
        {this.props.children}
      </MenuScrollWrapper>
    );
  }
}

/* eslint-disable camelcase */
import { Placement } from '@popperjs/core';
import { KeyValue } from '../types';

export const TOOLTIP_FALLBACK_PLACEMENT: Placement[] = [
  'top',
  'bottom',
  'top-start',
  'bottom-start',
  'left',
  'right',
  'left-start',
  'right-start',
];

export const TOOLTIP_PLACEMENT: Placement = 'top';

export const TOOLTIP_BACKGROUND = '#24313E';

export const GET_SDK_TOOLTIP_TEXT: KeyValue<string> = {
  http_curl_v1:
    'Select a programming language from the language selector on the right.',
  other: 'SDK is unavailable.',
};

import { Pre, Div, styled } from 'src/CleanSlate';
import { ExampleInfo, SectionNode } from 'src/DxDom';
import { EnclosedTabPanel, EnclosedTabsLarge } from 'src/EnclosedTabs';

export const ExampleWrapper = styled(Div)`
  ${Pre} {
    margin-bottom: 0px;
  }
`;

interface RenderMultipleExamplesProps {
  examples: ReadonlyArray<ExampleInfo>;
  renderNode: (node: SectionNode) => JSX.Element;
}

export function RenderMultipleExamples(props: RenderMultipleExamplesProps) {
  const { examples, renderNode } = props;
  return (
    <EnclosedTabsLarge initialOpenKey={examples[0].Name}>
      {examples.map((example: ExampleInfo) => {
        return (
          <EnclosedTabPanel title={example.Name} key={example.Id}>
            <ExampleWrapper>
              {example.Description &&
                renderNode({
                  Type: 'paragraph',
                  Text: example.Description,
                })}
              {example.Text &&
                renderNode({
                  Type: 'codeblock',
                  Text: example.Text,
                  Language: 'json',
                  From: 'Response Example',
                })}
            </ExampleWrapper>
          </EnclosedTabPanel>
        );
      })}
    </EnclosedTabsLarge>
  );
}

/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';

function DateWidget(props) {
  const {
    onChange,
    dxInterface: {
      registry: {
        widgets: { BaseInput },
      },
    },
  } = props;
  return (
    <BaseInput
      type="date"
      {...props}
      onChange={(value) => onChange(value || undefined)}
    />
  );
}

/* istanbul ignore else */
if (process.env.NODE_ENV !== 'production') {
  DateWidget.propTypes = {
    value: PropTypes.string,
  };
}

export default DateWidget;

import { Div, Tbl } from 'src/CleanSlate';
import { ResponseHeaderInfo } from 'src/DxDom';
import { Markdown } from 'src/Markdown';
import { DescriptionWrapper } from 'src/RParamInfo';
import {
  DangerLabel,
  ParamName,
  ParamType,
  TableWrapper,
  TypeWrapper,
} from 'src/StyledElements';

interface ResponseHeaderTableProps {
  headers: ReadonlyArray<ResponseHeaderInfo>;
}

export function ResponseHeaderTable({ headers }: ResponseHeaderTableProps) {
  return (
    <TableWrapper overflowX={true} overflowY={true}>
      <Tbl.Table>
        <Tbl.Thead>
          <Tbl.Tr>
            <Tbl.Th>Name</Tbl.Th>
            <Tbl.Th>Description</Tbl.Th>
          </Tbl.Tr>
        </Tbl.Thead>
        <Tbl.Tbody>
          {headers.map((header, index) => {
            return (
              <Tbl.Tr key={header.Name + index}>
                <Tbl.Td>
                  <ParamName title={header.Name}>{header.Name}</ParamName>
                  <Div>
                    {header.Required && (
                      <DangerLabel className="required">REQUIRED</DangerLabel>
                    )}
                  </Div>
                </Tbl.Td>
                <Tbl.Td>
                  <TypeWrapper>
                    {header.LinkTo ? (
                      <Markdown
                        className="model-table-link"
                        source={
                          '[`' + header.DataType + '`](' + header.LinkTo + ')'
                        }
                        inline={true}
                      />
                    ) : (
                      <ParamType>{header.DataType}</ParamType>
                    )}
                    {header.Constant && (
                      <ParamType marginLeft={'10px'}>Constant</ParamType>
                    )}
                  </TypeWrapper>
                  {header.Description && header.Description !== '-' && (
                    <DescriptionWrapper>
                      <Markdown
                        source={
                          header.Description
                            ? header.Description.replace(/<br>/gi, '   \n')
                            : header.Description
                        }
                      />
                    </DescriptionWrapper>
                  )}
                </Tbl.Td>
              </Tbl.Tr>
            );
          })}
        </Tbl.Tbody>
      </Tbl.Table>
    </TableWrapper>
  );
}

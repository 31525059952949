export const MagicWandIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H30C34.1421 0.5 37.5 3.85786 37.5 8V30C37.5 34.1421 34.1421 37.5 30 37.5H8C3.85786 37.5 0.5 34.1421 0.5 30V8Z"
        fill="white"
      />
      <path
        d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H30C34.1421 0.5 37.5 3.85786 37.5 8V30C37.5 34.1421 34.1421 37.5 30 37.5H8C3.85786 37.5 0.5 34.1421 0.5 30V8Z"
        stroke="#E7E9F2"
      />
      <path
        d="M28.64 10.64L27.36 9.36004C27.2475 9.24636 27.1135 9.15612 26.9659 9.09452C26.8183 9.03293 26.6599 9.00122 26.5 9.00122C26.3401 9.00122 26.1817 9.03293 26.0341 9.09452C25.8865 9.15612 25.7525 9.24636 25.64 9.36004L9.36 25.64C9.24632 25.7526 9.15607 25.8865 9.09448 26.0341C9.03289 26.1817 9.00117 26.3401 9.00117 26.5C9.00117 26.66 9.03289 26.8184 9.09448 26.966C9.15607 27.1136 9.24632 27.2475 9.36 27.36L10.64 28.64C10.7518 28.755 10.8855 28.8463 11.0332 28.9087C11.181 28.971 11.3397 29.0031 11.5 29.0031C11.6603 29.0031 11.819 28.971 11.9668 28.9087C12.1145 28.8463 12.2482 28.755 12.36 28.64L28.64 12.36C28.7549 12.2482 28.8462 12.1145 28.9086 11.9668C28.971 11.8191 29.0031 11.6604 29.0031 11.5C29.0031 11.3397 28.971 11.181 28.9086 11.0333C28.8462 10.8856 28.7549 10.7519 28.64 10.64V10.64Z"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14L24 17"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13V17"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 21V25"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9V11"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15H10"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 23H24"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 10H16"
        stroke="#6B7E8F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

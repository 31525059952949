import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const JavascriptIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M27.161,18.398 L26.727,18.127 C26.710,18.117 26.700,18.098 26.700,18.078 L26.700,
    17.536 C26.700,17.516 26.710,17.497 26.727,17.487 L27.161,17.211 C27.169,17.207 27.179,
    17.207 27.188,17.207 C27.197,17.207 27.203,17.206 27.211,17.211 L27.645,17.487 C27.661,
    17.497 27.672,17.516 27.672,17.536 L27.672,18.078 C27.672,18.098 27.661,18.117 27.645,
    18.127 L27.211,18.398 C27.195,18.408 27.177,18.408 27.161,18.398 ZM29.573,17.344 L28.220,
    18.201 C28.178,18.228 28.126,18.228 28.084,18.201 C28.042,18.175 28.016,18.126 28.016,
    18.073 L28.016,17.374 C28.016,17.321 27.991,17.272 27.948,17.246 L27.243,16.803 C27.200,
    16.776 27.149,16.776 27.107,16.803 L26.401,17.246 C26.359,17.272 26.329,17.321 26.329,
    17.374 L26.329,18.260 C26.329,18.313 26.358,18.367 26.401,18.393 L28.672,19.811 C28.715,
    19.838 28.740,19.886 28.740,19.939 C28.740,19.993 28.711,20.042 28.668,20.068 L27.310,
    20.890 C27.227,20.940 27.126,20.937 27.044,20.885 L24.804,19.496 C24.719,19.444 24.664,
    19.347 24.664,19.240 L24.664,16.404 C24.664,16.298 24.715,16.201 24.799,16.148 L27.057,
    14.730 C27.099,14.703 27.146,14.690 27.193,14.690 C27.240,14.690 27.286,14.703 27.329,
    14.729 L29.573,16.148 C29.657,16.201 29.708,16.298 29.708,16.404 L29.708,17.088 C29.708,
    17.194 29.657,17.291 29.573,17.344 ZM20.587,20.954 C20.502,21.007 20.400,21.007 20.315,
    20.954 L18.057,19.536 C17.973,19.483 17.922,19.386 17.922,19.280 L17.922,16.443 C17.922,
    16.337 17.973,16.235 18.057,16.182 L20.315,14.764 C20.399,14.711 20.502,14.711 20.587,
    14.764 L21.152,15.118 C21.182,15.137 21.218,15.137 21.247,15.118 C21.277,15.100 21.293,
    15.067 21.293,15.030 L21.293,11.263 C21.293,11.210 21.319,11.161 21.360,11.134 C21.381,
    11.121 21.405,11.115 21.428,11.115 C21.452,11.114 21.475,11.117 21.496,11.130 L22.840,
    11.947 C22.926,11.999 22.980,12.101 22.980,12.208 L22.980,19.280 C22.980,19.386 22.929,
    19.483 22.845,19.536 L20.587,20.954 ZM21.288,17.369 C21.288,17.343 21.273,17.318 21.252,
    17.305 L20.478,16.817 C20.468,16.811 20.454,16.807 20.442,16.807 L20.442,16.808 C20.430,
    16.808 20.421,16.811 20.410,16.817 L19.637,17.305 C19.616,17.318 19.600,17.343 19.600,
    17.369 L19.600,18.344 C19.600,18.370 19.615,18.395 19.637,18.408 L20.410,18.896 C20.431,
    8.909 20.457,18.909 20.478,18.896 L21.252,18.408 C21.273,18.395 21.288,18.370 21.288,
    18.344 L21.288,17.369 ZM13.822,20.900 C13.738,20.953 13.635,20.953 13.551,20.900 L11.293,
    19.481 C11.209,19.429 11.157,19.332 11.157,19.225 L11.157,16.389 C11.157,16.283 11.209,
    16.181 11.293,16.128 L13.551,14.715 C13.593,14.688 13.640,14.675 13.687,14.675 C13.734,
    14.675 13.780,14.688 13.822,14.715 L16.080,16.128 C16.165,16.181 16.216,16.283 16.216,
    16.389 L16.216,19.225 C16.216,19.332 16.164,19.429 16.080,19.481 L13.822,20.900 ZM9.375,
    20.299 C9.333,20.326 9.281,20.326 9.239,20.299 L7.900,19.462 C7.815,19.408 7.759,
    19.312 7.759,19.206 L7.759,17.428 C7.759,17.322 7.708,17.225 7.624,17.172 L7.053,
    16.813 C7.012,16.786 6.964,16.773 6.918,16.773 C6.870,16.773 6.825,16.786 6.782,
    16.813 L6.212,17.172 C6.127,17.225 6.076,17.322 6.076,17.428 L6.076,19.206 C6.076,
    19.312 6.021,19.407 5.936,19.462 L4.597,20.299 C4.555,20.326 4.503,20.326 4.461,
    20.299 C4.418,20.273 4.393,20.224 4.393,20.171 L4.388,16.364 C4.388,16.258 4.440,
    16.161 4.524,16.108 L6.782,14.690 C6.821,14.666 6.861,14.657 6.904,14.655 L6.931,
    14.655 C6.975,14.657 7.015,14.666 7.053,14.690 L9.311,16.108 C9.396,16.161 9.447,
    16.258 9.447,16.364 L9.442,20.171 C9.442,20.224 9.416,20.273 9.375,20.299 Z"
    />
  </SVGIcon>
);

/**
 * Interface for cancellable promise
 */
export interface CancellablePromise<T> {
  /**
   * Promise object
   */
  promise: Promise<T>;
  /**
   * Cancel promise
   */
  cancel: () => void;
}

/**
 * Make this promise cancellable.
 *
 * A cancelled promise throws reject as soon as cancel() is called.
 * @see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * @param promise Promise to convert
 */
export function makeCancellable<T>(promise: Promise<T>): CancellablePromise<T> {
  let hasCancelled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => (hasCancelled ? reject({ isCancelled: true }) : resolve(val)),
      (error) => (hasCancelled ? reject({ isCancelled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCancelled = true;
    },
  };
}

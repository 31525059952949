import { PureComponent } from 'react';
// import ReactJson from 'react-json-view';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import 'react18-json-view/src/dark.css';
import { SimpleJsonType } from '../DxDom';
import { CodeViewer } from './CodeViewer';
import { PortalSettings } from 'src/PortalSettings';
import { CopyEvent } from 'src/Analytics';
import copy from 'copy-to-clipboard';

interface JsonViewerProps {
  children: string;
  portalSettings: PortalSettings;
}
interface ClipboardProps {
  name: string;
  src: string | Array<Record<string, unknown>>;
  namespace: Array<string>;
}

const reactJsonStyle = {
  backgroundColor: 'transparent',
  fontFamily: "Consolas, 'Liberation Mono', Menlo, Courier, monospace",
  fontSize: '13px',
  lineHeight: '19px',
  padding: '10px 15px',
};

/**
 * Json viewer for json responses
 */
export class JsonViewer extends PureComponent<JsonViewerProps> {
  /*
   * Custome copy to clipboard.
   * react-json-view default Copy is not handling escaped value like &times
   * if escaped value exist then will use native clipboard.writeText
   * else will use default library copy
   */

  onCopyClipboard = (copyClipboard: ClipboardProps) => {
    const escapedValues = '&times';
    const stringfyCopiedValue = JSON.stringify(copyClipboard.src);

    if (stringfyCopiedValue?.indexOf(escapedValues) !== -1) {
      try {
        copy(stringfyCopiedValue);
      } catch {
        console.log('Unable to copy via inline copy');
      }
    }
    if (typeof copyClipboard.src === 'string') {
      try {
        copy(JSON.parse(stringfyCopiedValue));
      } catch {
        console.log('Unable to copy via inline copy');
      }
    }

    CopyEvent(this.props.portalSettings, 'Code Sample - Inline Response');
    /*
     * In eles case JSONViewer default implementation will be executed.
     */
  };

  render() {
    const { children } = this.props;
    try {
      const parsed = JSON.parse(children) as SimpleJsonType;

      if (typeof parsed !== 'object' || parsed == null) {
        return <CodeViewer lang="json">{children}</CodeViewer>;
      }

      return (
        <>
          <JsonView
            dark={true}
            src={parsed}
            style={reactJsonStyle}
            collapsed={2}
            theme={'a11y'}
            enableClipboard={true}
          />
        </>
      );
    } catch (e) {
      return <CodeViewer lang="json">{children}</CodeViewer>;
    }
  }
}

import styled from 'styled-components';
import { Div } from 'src/CleanSlate';
import { CheckedCircleIcon } from 'src/Icons/Ui/CheckedCircleIcon';
import { CircleDotIcon } from 'src/Icons/Ui/CircleDotIcon';
import { WorkflowState } from 'src/Context/WorkflowContext';

interface Line {
  isComplete: boolean;
}
interface StepTrackerProps {
  status: WorkflowState[string][string]['status'];
}

const StepsTrackerWrapper = styled(Div)`
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LineStyled = styled(Div)<Line>`
  height: calc(100% - 24px);
  width: 2px;
  background-color: ${(props) =>
    props.isComplete
      ? props.theme.staticColors.Blue.C000
      : props.theme.colors.C300};
`;

function getIcon(status: StepTrackerProps['status']) {
  switch (status) {
    case 'complete':
      return <CheckedCircleIcon />;
    case 'current':
      return <CircleDotIcon isActive={true} />;
    default:
      return <CircleDotIcon isActive={false} />;
  }
}
export function StepTracker(props: StepTrackerProps) {
  const { status } = props;

  return (
    <StepsTrackerWrapper>
      {getIcon(status)}
      <LineStyled isComplete={status === 'complete'} />
    </StepsTrackerWrapper>
  );
}

export const ArrowRightIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.888"
    height="9.015"
    viewBox="0 0 10.888 9.015"
  >
    <g transform="translate(-64.35 -11.993)">
      <path
        d="M8,8l3.588,3.588L8,15.176"
        transform="translate(63 4.912)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <line
        x2="9"
        transform="translate(65 16.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </g>
  </svg>
);

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const KeyIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox={props.viewBox || '0 0 16 16'}
    fill={props.fill || 'none'}
  >
    <g clipPath="url(#clip0_1534_1850)">
      <path
        d="M14.0002 1.33301L12.6669 2.66634M7.59352 7.73967L10.3335 4.99967M10.3335 4.99967L12.3335 6.99967L14.6669 4.66634L12.6669 2.66634M10.3335 4.99967L12.6669 2.66634M7.59352 7.73967C7.93774 8.07932 8.21139 8.4837 8.39869 8.92954C8.58599 9.37537 8.68325 9.85385 8.68487 10.3374C8.68649 10.821 8.59244 11.3001 8.40813 11.7472C8.22382 12.1943 7.95289 12.6005 7.61095 12.9424C7.269 13.2844 6.8628 13.5553 6.41572 13.7396C5.96864 13.9239 5.48952 14.018 5.00594 14.0164C4.52236 14.0147 4.04388 13.9175 3.59804 13.7302C3.15221 13.5429 2.74783 13.2692 2.40818 12.925C1.74027 12.2335 1.37069 11.3073 1.37904 10.3459C1.3874 9.38448 1.77302 8.46484 2.45285 7.78501C3.13268 7.10518 4.05232 6.71956 5.01371 6.7112C5.9751 6.70285 6.90131 7.07243 7.59285 7.74034L7.59352 7.73967Z"
        stroke={props.stroke || '#0C7FF2'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1534_1850">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
);

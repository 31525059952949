import { RefObject, createRef, useCallback, useEffect, useState } from 'react';
import { Div, Ul } from 'src/CleanSlate';
import { ChevronIcon } from 'src/Icons';
import { ChevronLeftGroup } from 'src/Icons/Ui/ChevronLeftGroup';
import { ChevronRightGroup } from 'src/Icons/Ui/ChevronRightGroup';
import { InvisibleScrollableMixin } from 'src/StyledElements';
import styled, { css } from 'styled-components';

interface SelectorContainerProps {
  ref: RefObject<HTMLUListElement>;
  hasArrow: boolean;
  isLanguageSelector?: boolean;
}

interface SelectorProp {
  isSelected: boolean;
  isLanguageSelector?: boolean;
  isRightArrow?: boolean;
}

interface ScrollableSelectorProps {
  parentRef: RefObject<HTMLDivElement>;
  isLanguageSelector?: boolean;
  children: JSX.Element[];
}

interface DisableArrowsState {
  leftArrow: boolean;
  rightArrow: boolean;
}

const TabsWrapper = styled(Div)<{ isLanguageSelector?: boolean }>`
  display: flex;
  position: relative;
  align-items: ${(props) => (props.isLanguageSelector ? 'center' : 'flex-end')};
  top: ${(props) => (props.isLanguageSelector ? '0px' : '1px')};
  padding-right: 1px;
  height: 100%;

  > div:first-child {
    margin-right: ${(props) => (props.isLanguageSelector ? '10px' : '0px')};
  }

  > div:last-child {
    margin-left: ${(props) => (props.isLanguageSelector ? '10px' : '0px')};
  }

  @media screen and (max-width: 990px) {
    display: ${(props) => (props.isLanguageSelector ? 'none' : 'flex')};
  }
`;

const SelectorContainer = styled(Ul)<SelectorContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) => (props.isLanguageSelector ? '100%' : '42px')};
  margin: 0px;
  padding: 0px 4px 0px 0px;
  width: fit-content;
  ${InvisibleScrollableMixin} {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  scroll-behavior: smooth;
  gap: 5px;
`;

export const selectorMixin = css<SelectorProp>`
  border: 1px solid
    ${(props) =>
      props.isLanguageSelector
        ? props.theme.staticColors.Goose.C400
        : props.theme.colors.C300};
  border-radius: 8px 8px 0px 0px;

  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.C901
      : props.theme.staticColors.Goose.C200};
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.C001 : props.theme.colors.C201};
  font-size: 'regular';
  font-weight: ${(props) =>
    props.isSelected || props.isLanguageSelector ? 'bold' : 'normal'};
  padding: 6px 19px;
  min-width: fit-content;
  margin: 0px;
  list-style: none;

  &:hover {
    cursor: pointer;
  }
`;

const IconWrapper = styled(Div)<{ disable: boolean }>`
  height: 20px;
  width: 16px;
  flex: 0 0 auto;
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};

  ${(props) =>
    props.disable
      ? css`
          opacity: 0.4;
        `
      : css`
          &:hover {
            > svg {
              > g {
                > path,
                circle {
                  stroke: ${(props) => props.theme.staticColors.Unique.C100};
                  opacity: 0.7;
                }
              }
            }
          }
        `}
`;

const Arrows = styled(Div)<SelectorProp>`
  ${selectorMixin}
  border-radius: 8px 8px 0px 0px;
  padding: ${(props) =>
    props.isRightArrow ? '4px 12px 6px 16px' : '6px 16px 4px 12px'};
  user-select: none;
  height: 42px;
  width: 48px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.C001};
    border-color: ${(props) => props.theme.colors.C300};
    color: ${(props) => props.theme.staticColors.Goose.C300};
  }
`;

export const ScrollableSelector = (props: ScrollableSelectorProps) => {
  const [displayArrows, setDisplayArrows] = useState<boolean>(false);
  const [disableArrows, setDisableArrows] = useState<DisableArrowsState>({
    leftArrow: true,
    rightArrow: false,
  });
  const tabRef: RefObject<HTMLUListElement> = createRef();
  const leftIconRef: RefObject<HTMLDivElement> = createRef();
  const rightIconRef: RefObject<HTMLDivElement> = createRef();

  const updateDivWidth = useCallback(() => {
    const tabDivWidth = tabRef.current?.offsetWidth;
    const parentDivWidth = props.parentRef.current?.offsetWidth;
    const offset = props?.isLanguageSelector ? 70 : 100;
    if (tabDivWidth && parentDivWidth) {
      if (
        (!displayArrows && tabDivWidth + 6 > parentDivWidth) ||
        (displayArrows && tabDivWidth + offset > parentDivWidth)
      ) {
        setTimeout(() => {
          setDisplayArrows(true);
        }, 0);
      } else if (
        (!displayArrows && tabDivWidth + 6 <= parentDivWidth) ||
        (displayArrows && tabDivWidth + offset <= parentDivWidth)
      ) {
        setDisplayArrows(false);
      }
    }
  }, [displayArrows, props?.isLanguageSelector, props.parentRef, tabRef]);

  const checkScroll = useCallback(
    (actualscrollLeftPosition: number) => {
      const tabDiv = tabRef.current;
      const leftIconDiv = leftIconRef.current;
      const rightIconDiv = rightIconRef.current;
      if (tabDiv && leftIconDiv && rightIconDiv) {
        if (actualscrollLeftPosition <= 0)
          setDisableArrows({
            leftArrow: true,
            rightArrow: false,
          });
        else if (
          actualscrollLeftPosition >=
          tabDiv.scrollWidth - tabDiv.offsetWidth
        )
          setDisableArrows({
            leftArrow: false,
            rightArrow: true,
          });
        else
          setDisableArrows({
            leftArrow: false,
            rightArrow: false,
          });
      }
    },
    [leftIconRef, rightIconRef, tabRef]
  );

  const moveScroll = (
    arrowName: 'Left' | 'Right',
    isLanguageSelector?: boolean
  ) => {
    const tabDiv = tabRef.current;
    const scrollValue = isLanguageSelector ? 80 : 150;
    const negScrollValue = scrollValue * -1;
    let actualscrollLeftPosition: number = tabDiv?.scrollLeft || 0;
    if (tabDiv) {
      // The actualscrollLeftPostion logic has been impleted because if we directly use the position of scroll
      // then the outdated value is passed to the function, because on click the new scroll position is set but
      // at the time when scrollposition is sent to the fucntion the scrollLeft position is still not updated
      // and the old value is passed.
      actualscrollLeftPosition +=
        arrowName === 'Left' ? negScrollValue : scrollValue;

      // The following conditions are implemented because we don't want scroll position to be a negative number
      // and its value should not exceed the width of the div
      actualscrollLeftPosition =
        actualscrollLeftPosition < 0 // if the scrollposition is a negative number then set it as zero
          ? 0
          : actualscrollLeftPosition > tabDiv.scrollWidth - tabDiv.offsetWidth // else if the scrollposition exceeds div's width then keep it within the div's limit.
          ? tabDiv.scrollWidth - tabDiv.offsetWidth
          : actualscrollLeftPosition;

      tabDiv.scrollLeft = actualscrollLeftPosition;
      checkScroll(actualscrollLeftPosition);
    }
  };

  useEffect(() => {
    // Call the function once to get the initial width
    updateDivWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateDivWidth);

    // Clean up the event listener when component is unmounted
    return () => {
      window.removeEventListener('resize', updateDivWidth);
    };
  }, [
    displayArrows,
    tabRef,
    props.parentRef,
    props.isLanguageSelector,
    updateDivWidth,
  ]);

  //This useEffect is only for first time render
  useEffect(() => {
    if (tabRef.current) {
      checkScroll(tabRef.current?.scrollLeft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabsWrapper isLanguageSelector={props?.isLanguageSelector}>
      {displayArrows &&
        (props?.isLanguageSelector ? (
          <IconWrapper
            ref={leftIconRef}
            onClick={() => moveScroll('Left', true)}
            disable={disableArrows.leftArrow}
          >
            <ChevronLeftGroup />
          </IconWrapper>
        ) : (
          <ArrowTabs moveScroll={moveScroll} />
        ))}
      <SelectorContainer
        ref={tabRef}
        hasArrow={displayArrows}
        isLanguageSelector={props?.isLanguageSelector}
      >
        {props.children}
      </SelectorContainer>
      {displayArrows &&
        (props?.isLanguageSelector ? (
          <IconWrapper
            ref={rightIconRef}
            onClick={() => moveScroll('Right', true)}
            disable={disableArrows.rightArrow}
          >
            <ChevronRightGroup />
          </IconWrapper>
        ) : (
          <ArrowTabs moveScroll={moveScroll} isRightArrow />
        ))}
    </TabsWrapper>
  );
};

interface ArrowTabsProps {
  moveScroll: (
    arrowName: 'Left' | 'Right',
    isLanguageSelector?: boolean
  ) => void;
  isRightArrow?: true;
}

const ArrowTabs = (props: ArrowTabsProps) => {
  const { isRightArrow, moveScroll } = props;
  return (
    <Arrows
      isSelected={false}
      onClick={() => moveScroll(isRightArrow ? 'Right' : 'Left', false)}
      isLanguageSelector={false}
      isRightArrow={isRightArrow}
    >
      <ChevronIcon
        style={{ transform: `rotate(${isRightArrow ? '270deg' : '90deg'})` }}
        viewBox="0 0 28 28"
        width="18px"
        fill="none"
        stroke="#6B7E8F"
        strokeWidth="2"
      />
    </Arrows>
  );
};

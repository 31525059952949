/**
 * Link mapper was created as a way to convert between DX Document link
 * format and the portal link format.
 */

import * as React from 'react';

/**
 * Convert DX Link to route
 */
export type LinkMapper = (<T extends string | undefined>(oldLink: T) => T) & {
  /**
   * Get DX Link from route
   */
  reverse: (link: string | undefined) => string | undefined;
  /**
   * Is DX DOM link?
   */
  isDxDomLink: (link: string | undefined) => boolean;
};

// This is the only way to build a function object from a property
/* eslint-disable  @typescript-eslint/no-explicit-any */
const defaultValue: any = (x?: string) => x;
defaultValue.reverse = (x?: string) => x;

export const LinkMapperContext = React.createContext<LinkMapper>(defaultValue);

export function getHashId(text?: string) {
  return text
    ? text
        .toLowerCase()
        .replace(/\\/g, '')
        .replace(/[^\w\d\s]/g, ' ')
        .replace(/\s+/g, ' ')
        .trim()
        .replace(/\s/g, '-')
    : '';
}

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/* Enable and test when its used */
/* istanbul ignore next */
// export function withLinkMapperContext<P extends { linkMapper: LinkMapper }>(
//   Component: React.ComponentClass<P> | React.StatelessComponent<P>
// ): React.StatelessComponent<Omit<P, 'linkMapper'>> {
//   // Reference: https://reactjs.org/docs/context.html#consuming-context-with-a-hoc
//   return function LinkMapperBoundComponent(props: Omit<P, 'linkMapper'>) {
//     return (
//       <LinkMapperContext.Consumer>
//         {value => <Component {...props} linkMapper={value} />}
//       </LinkMapperContext.Consumer>
//     );
//   };
// }

/**
 * Since the DX Portal maybe embedded in other websites, we need to aggressively
 * reset styles for HTML elements present on those websites but without affecting
 * the website styling itself. This file implements those resets for common HTML
 * elements.
 */
import styled, { css } from 'styled-components';
import { bodyDefaultFamily, renderCssStyles } from './Theme';
/**
 * Base rules for all elements (customize for App)
 */

export const baseRules = css`
  font-size: 16px;
  line-height: 1.75;
  direction: ltr;
  text-align: left;
  text-align: start;
  font-family: ${bodyDefaultFamily};
  color: ${(props) => props.theme.colors.C800};
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.body.text1)};
`;
/**
 * Reset all CSS styles for all elements
 */
const cleanSlate = css`
  background-attachment: scroll;
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
  background-repeat: repeat;
  border-color: black;
  border-color: currentColor;
  border-radius: 0;
  border-style: none;
  border-width: medium;
  bottom: auto;
  clear: none;
  clip: auto;
  color: inherit;
  counter-increment: none;
  counter-reset: none;
  cursor: auto;
  direction: inherit;
  display: inline;
  float: none;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-variant: normal;
  font-weight: inherit;
  left: auto;
  letter-spacing: normal;
  line-height: inherit;
  margin: 0;
  max-height: none;
  max-width: none;
  min-height: 0;
  min-width: 0;
  opacity: 1;
  outline: invert none medium;
  overflow: visible;
  padding: 0;
  position: static;
  quotes: '' '';
  right: auto;
  table-layout: auto;
  text-align: inherit;
  text-decoration: inherit;
  text-indent: 0;
  text-transform: none;
  top: auto;
  unicode-bidi: normal;
  vertical-align: baseline;
  visibility: inherit;
  white-space: normal;
  word-spacing: normal;
  z-index: auto;
  /* CSS3 */
  background-origin: padding-box;
  background-clip: border-box;
  background-size: auto;
  border-image: none;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  column-count: auto;
  column-gap: normal;
  column-rule: medium none black;
  column-span: 1;
  column-width: auto;
  font-feature-settings: normal;
  overflow-x: visible;
  overflow-y: visible;
  hyphens: manual;
  perspective: none;
  perspective-origin: 50% 50%;
  backface-visibility: visible;
  text-shadow: none;
  transition: all 0s ease 0s;
  transform: none;
  transform-origin: 50% 50%;
  transform-style: flat;
  word-break: normal;
  ${baseRules};
`;
const inheritFont = css`
  font-size: inherit;
  line-height: inherit;
`;
/**
 * Reset styles for block elements
 */
const blockMixin = css`
  display: block;
  height: auto;
  width: auto;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
`;
/**
 * Basic block elements
 */
export const Div = styled.div`
  ${cleanSlate};
  ${blockMixin};
  ${inheritFont};
`;

Div.displayName = 'CS.Div';
export const anchorMixin = css`
  ${cleanSlate};
  color: ${(props) => props.theme.linkColor};
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  ${inheritFont} > code {
    color: ${(props) => props.theme.linkColor};
    border: none;
    background: none;
  }
  &:visited {
    opacity: 0.8;
  }
  &:active,
  &:hover {
    outline: 0;
    text-decoration: underline;
  }
  &:hover,
  &:focus {
    opacity: 0.8;
    color: ${(props) => props.theme.linkColor};
  }
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
`;
export const A = styled.a`
  ${anchorMixin};
`;
A.displayName = 'CS.A';
export const codeMixin = css`
  padding: 0;
  ${inheritFont} color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
`;
export const blockCodeMixin = css`
  ${cleanSlate};
  ${codeMixin};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.code.blockCode)};
`;
export const inlineCodeMixin = css`
  ${cleanSlate};
  ${codeMixin};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.code.inlineCode)};
`;
export const InlineCode = styled.code`
  ${inlineCodeMixin};
  color: ${({ theme: { colors } }) => colors.C700};
  background: ${({ theme: { colors } }) => colors.C201};
  border: 1px solid ${({ theme: { colors } }) => colors.C300};
  border-radius: 4px;
  padding: 1px 5px;
  ${A} & {
    cursor: pointer;
  }
`;
InlineCode.displayName = 'CS.InlineCode';
export const preMixin = css`
  padding: 16px;
  margin: 0 0 10px;
  word-wrap: break-word;
  word-break: break-word;
  background-color: ${(props) => props.theme.colors.C201};
`;
export const Pre = styled.pre`
  ${cleanSlate};
  ${preMixin};
  ${blockMixin};
  code {
    ${blockCodeMixin};
  }
`;
Pre.displayName = 'CS.Pre';
export const Hr = styled.hr`
  ${cleanSlate};
  ${blockMixin};
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.colors.C300};
  height: 0;
`;
Hr.displayName = 'CS.Hr';
export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: 0;
  vertical-align: middle;
`;
Img.displayName = 'CS.Img';

/**
 * Basic inline elements
 */
export const Strong = styled.strong`
  font-weight: 500;
  ${inheritFont};
  color: inherit;
`;
Strong.displayName = 'CS.Strong';

export const Em = styled.em`
  font-style: italic;
  ${inheritFont};
  font-weight: inherit;
  color: inherit;

  > ${Strong} {
    font-style: inherit;
  }
`;
Em.displayName = 'CS.Em';
export const Span = styled.span`
  ${cleanSlate};
`;
Span.displayName = 'CS.Span';
export const ButtonMixin = css`
  ${cleanSlate};
  height: 32px;
  font: inherit;
  font-weight: 400;
  font-size: 13.33px;
  padding: 0 24px;
  line-height: 30px;
  display: flex;
  text-align: center;
  border: 1px solid transparent;
  border-color: 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  white-space: no-wrap;
  -webkit-appearance: none;
  outline: initial;
  vertical-align: middle;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  @media screen and (max-width: 1200px) {
    padding: 0 12px;
  }
  &:hover,
  &:visited,
  &:focus {
    /* IE 8 */
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=85)';

    /* IE 5-7 */
    filter: alpha(opacity=85);

    /* Netscape */
    -moz-opacity: 0.85;

    /* Safari 1.x */
    -khtml-opacity: 0.85;

    /* Good browsers */
    opacity: 0.85;
    text-decoration: none !important;
  }
`;
export const Button = styled.button`
  ${ButtonMixin};
`;
Button.displayName = 'CS.Button';

/**
 * Paragraph
 */
export const P = styled.p`
  ${cleanSlate};
  ${blockMixin};
  margin: 20px 0;
  color: ${(props) => props.theme.colors.C800};
  ${inheritFont}
  ${InlineCode}, ${A} {
    font-size: inherit;
    line-height: inherit;
  }

  &.display-block {
    img {
      max-width: 100%;
      display: block;
      margin: 30px auto;
    }
  }
`;
P.displayName = 'CS.P';

/**
 * Headings
 */

export const HeadingCommon = css`
  ${cleanSlate};
  ${blockMixin};
  font-weight: 500;
  margin: 60px 0 20px;
  color: ${(props) => props.theme.colors.C901};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.headings.h1)};
  ${InlineCode}, ${A} {
    font-size: inherit;
    line-height: inherit;
  }

  .heading-markdown {
    display: inline;
  }

  > .heading-link {
    display: none;
    margin-left: 5px;
    transition: opacity 1s;
  }

  :hover {
    .heading-link {
      display: initial;
    }
  }
`;
export const H1 = styled.h1`
  ${HeadingCommon};
`;
H1.displayName = 'CS.H1';
export const H2 = styled.h2`
  ${HeadingCommon};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.headings.h2)};
`;

H2.displayName = 'CS.H2';
export const H3 = styled.h3`
  ${HeadingCommon};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.headings.h3)};
`;
H3.displayName = 'CS.H3';
export const H4 = styled.h4`
  ${HeadingCommon};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.headings.h4)};
`;
H4.displayName = 'CS.H4';
export const H5 = styled.h5`
  ${HeadingCommon};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.headings.h5)};
`;
H5.displayName = 'CS.H5';
export const H6 = styled.h6`
  ${HeadingCommon};
  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.headings.h6)};
`;
H6.displayName = 'CS.H6';
export const TextSpacingFixMixin = css`
  ${P}, ${H1} {
    margin: 0;
  }
`;
export const Blockquote = styled.blockquote`
  ${cleanSlate};
  ${blockMixin};
  margin: 20px 0;
  padding: 10px 20px;
  border-left: 4px solid ${(props) => props.theme.colors.C300};
  quotes: none;
  ${inheritFont};
  ${TextSpacingFixMixin};
  &:before,
  &:after {
    content: '';
  }
`;
Blockquote.displayName = 'CS.Blockquote';
/**
 * List
 */
const ListBaseStyleMixin = css`
  ${cleanSlate};
  ${blockMixin};
  margin: 20px 0;
  padding-left: 22px;
  ${inheritFont} list-style-position: outside;
  list-style-image: none;
  ul,
  ol {
    margin: 10px 0;
  }
`;
export const Ul = styled.ul`
  ${ListBaseStyleMixin};
`;
Ul.displayName = 'CS.Ul';
export const Ol = styled.ol`
  ${ListBaseStyleMixin};
`;
Ol.displayName = 'CS.Ol';
export const Li = styled.li`
  ${cleanSlate};
  display: list-item;
  min-height: auto;
  min-width: auto;
  padding: 0;
  margin: 0;
  margin-bottom: 2px;
  ${inheritFont} &:before,
   &:after {
    content: '';
  }
  ${TextSpacingFixMixin};
  ${InlineCode}, ${A} {
    font-size: inherit;
    line-height: inherit;
  }
  .code-with-copy {
    margin: 8px 0;
  }
`;
Li.displayName = 'CS.Li';
export const CheckedLi = styled(Li)`
  list-style-type: none;
  margin-left: -20px;
  margin-bottom: 3px;
`;
CheckedLi.displayName = 'CS.CheckedLi';
/**
 * Table
 */
export const Table = styled.table`
  ${cleanSlate};
  display: table;
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  border-collapse: separate;
  border-spacing: 0;
  text-align: left;
  border-radius: 4px 4px 0 0;
  font-size: ${(props) => props.theme.cssStyles.body.text2.fontSize};
  line-height: ${(props) => props.theme.cssStyles.body.text2.lineHeight};
  overflow-y: hidden;
  ${TextSpacingFixMixin};
`;
Table.displayName = 'CS.Table';
export const Tbody = styled.tbody`
  ${cleanSlate};
  display: table-row-group;
  vertical-align: middle;
  border-spacing: 0;
  border-collapse: separate;
  ${inheritFont};
`;
Tbody.displayName = 'CS.Tbody';
export const Thead = styled.thead`
  ${cleanSlate};
  display: table-header-group;
  vertical-align: middle;
  ${inheritFont};
`;
Thead.displayName = 'CS.Thead';
export const Tr = styled.tr`
  ${cleanSlate};
  display: table-row;
  word-wrap: break-word;
  ${inheritFont};
`;
Tr.displayName = 'CS.Tr';
export const Th = styled.th`
  ${cleanSlate};
  padding: 16px;
  display: table-cell;
  text-align: left;
  font-weight: 500;
  border-top: 0;
  vertical-align: middle;
  word-wrap: break-word;
  ${inheritFont};
  ${TextSpacingFixMixin};
  color: ${({ theme }) => theme.colors.C1100};
`;

Th.displayName = 'CS.Th';
export const Td = styled.td`
  ${cleanSlate};
  display: table-cell;
  padding: 16px;
  vertical-align: top;
  word-wrap: break-word;
  ${inheritFont};
  ${TextSpacingFixMixin};
`;
Td.displayName = 'CS.Td';
/**
 * Table element for easy usage
 */
export const Tbl = {
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
};
/**
 * Input elements
 */
export const Select = styled.select`
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  cursor: default;
  background: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  align-items: center;
  white-space: pre;
`;
Select.displayName = 'CS.Select';
export const Svg = styled.svg`
  fill: '#000';
  pointer-events: none;
`;
Select.displayName = 'CS.Svg';

export { styled };

/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const Error402LightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M76,25.9v70.9c0,1.9-1.5,3.4-3.4,3.4H23.8
	c-1.9,0-3.4-1.5-3.4-3.4V41L39,22.5h33.6C74.5,22.5,76,24,76,25.9z"
    />
    <path
      opacity="0.15"
      fill="#9199A5"
      d="M74.4,96.5H22c-0.9,0-1.5,0.7-1.5,1.5v0.6c0,0.9,0.7,1.5,1.5,1.5h52.4c0.9,0,1.5-0.7,1.5-1.5
	v-0.6C76,97.2,75.3,96.5,74.4,96.5z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M80.4,29.6v70.9c0,1.9-1.5,3.4-3.4,3.4H28.3
	c-1.9,0-3.4-1.5-3.4-3.4V44.7l18.5-18.5H77C78.9,26.2,80.4,27.7,80.4,29.6z"
    />
    <path
      opacity="0.15"
      fill="#9199A5"
      d="M78.8,100.2H26.4c-0.9,0-1.5,0.7-1.5,1.5v0.6c0,0.9,0.7,1.5,1.5,1.5h52.4
	c0.9,0,1.5-0.7,1.5-1.5v-0.6C80.4,100.9,79.7,100.2,78.8,100.2z"
    />
    <path
      opacity="0.15"
      fill="#9199A5"
      d="M24.9,46.6l0-1.8l18.5-18.5l1.9,0l0,18.6c0,1-0.8,1.7-1.7,1.7L24.9,46.6z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M25.2,44.4l17.9-17.9c0.1-0.1,0.3,0,0.3,0.1
	v17.9c0,0.1-0.1,0.2-0.2,0.2l-17.9,0C25.1,44.7,25.1,44.5,25.2,44.4z"
    />
    <rect
      x="30.9"
      y="54.1"
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="41.7"
      height="5.8"
    />
    <rect
      x="30.9"
      y="65.1"
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="41.7"
      height="5.8"
    />
    <rect
      x="30.9"
      y="77.2"
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="41.7"
      height="5.8"
    />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="84.3"
      cy="72.5"
      r="31.4"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="84.3"
      cy="72.5"
      r="25.2"
    />
    <circle fill="#CACFD6" cx="84.3" cy="72.5" r="24" />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="84.3"
      cy="72.5"
      r="23.1"
    />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M88.3,82.7l-5.1-7.3l-5,7.3
		c-0.1,0.1-0.2,0.2-0.3,0.2h-4.8c-0.3,0-0.5-0.3-0.3-0.6l7.5-10.3l-7.1-10c-0.2-0.2,0-0.6,0.3-0.6h4.8c0.1,0,0.2,0.1,0.3,0.2
		l4.8,6.8l4.7-6.8c0.1-0.1,0.2-0.2,0.3-0.2h4.5c0.3,0,0.5,0.3,0.3,0.6l-7.1,9.8l7.6,10.5c0.2,0.2,0,0.6-0.3,0.6h-4.8
		C88.5,82.8,88.4,82.8,88.3,82.7z"
      />
    </g>
  </SVGIcon>
);

import styled from 'styled-components';

import { Pre, blockCodeMixin } from './CleanSlate';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  tomorrow as lightTheme,
  tomorrowNight as darkTheme,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import objc from 'react-syntax-highlighter/dist/esm/languages/hljs/objectivec';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import xml from 'react-syntax-highlighter/dist/esm/languages/hljs/xml';
import ruby from 'react-syntax-highlighter/dist/esm/languages/hljs/ruby';
import cs from 'react-syntax-highlighter/dist/esm/languages/hljs/csharp';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';
import go from 'react-syntax-highlighter/dist/esm/languages/hljs/go';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import yaml from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml';
import md from 'react-syntax-highlighter/dist/esm/languages/hljs/markdown';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import xmlFormatter from 'xml-beautifier';

const { registerLanguage } = SyntaxHighlighter;

/**
 * Register language highlighters
 */
registerLanguage('bash', bash);
registerLanguage('cs', cs);
registerLanguage('csharp', cs);
registerLanguage('go', go);
registerLanguage('html', xml);
registerLanguage('java', java);
registerLanguage('javascript', js);
registerLanguage('js', js);
registerLanguage('objc', objc);
registerLanguage('objectivec', objc);
registerLanguage('php', php);
registerLanguage('python', python);
registerLanguage('ruby', ruby);
registerLanguage('xml', xml);
registerLanguage('yaml', yaml);
registerLanguage('md', md);
registerLanguage('markdown', md);
registerLanguage('json', json);
registerLanguage('http', moddedHttp);

export const HighlighterLanguage = {
  http_curl_v1: 'bash',
  cs_net_standard_lib: 'cs',
  cs_portable_net_lib: 'cs',
  cs_universal_windows_platform_lib: 'cs',
  java_gradle_android_lib: 'java',
  objc_cocoa_touch_ios_lib: 'objectivec',
  java_eclipse_jre_lib: 'java',
  php_generic_lib_v2: 'php',
  python_generic_lib: 'python',
  ruby_generic_lib: 'ruby',
  angular_javascript_lib: 'javascript',
  ts_generic_lib: 'typescript',
  go_generic_lib: 'go',
};
/**
 * Since the syntax highlighter creates its own Pre tag, we need to
 * neutralize that Pre's styles and add our own styled fake-pre using
 * a wrapper div.
 */

export type PreWrapperProp = {
  hasMre?: boolean;
};

const PreWrapper = styled(Pre)<PreWrapperProp>`
  background: transparent !important;
  border: none !important;
  border-radius: 8px !important;
  margin: 0;
  padding: ${(props) => (props.hasMre ? '0px' : '18px')} 24px;

  .linenumber {
    color: ${(props) => props.theme.colors.C1100};
    margin-right: 10px;
  }

  & > pre {
    background: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    margin: 0;
    padding: ${(props) => (props.hasMre ? '0' : '0.5em')} !important;
  }

  & > code {
    ${blockCodeMixin};
  }
`;
PreWrapper.displayName = 'PreWrapper';

export interface CodeHighlighterProps {
  lang?: string;
  code: string;
  dark?: boolean;
  showLineNumbers?: boolean;
  hasMultipleRequestExample?: boolean;
}

function indentCode(code: string, language?: string) {
  switch (language) {
    case 'xml':
      return xmlFormatter(code);
    default:
      return code;
  }
}

/**
 * Syntax higlighter for code
 * @param props
 */
export function CodeHighlighter(props: CodeHighlighterProps) {
  if (
    props.code === null ||
    props.code === undefined ||
    props.code.length === 0 ||
    props.code.trim() === ''
  ) {
    return null;
  }

  const indentedCode = indentCode(props.code, props.lang);

  return (
    <PreWrapper as="div" hasMre={props.hasMultipleRequestExample}>
      <SyntaxHighlighter
        language={props.lang}
        style={props.dark ? darkTheme : lightTheme}
        showLineNumbers={props.showLineNumbers}
      >
        {indentedCode}
      </SyntaxHighlighter>
    </PreWrapper>
  );
}

// utility methods:

/**
 * Modded highlighter for HTTP code samples used by Dx Docs
 */
function moddedHttp() {
  return {
    aliases: ['https'],
    illegal: '\\S',
    contains: [
      {
        begin: '^[A-Z]+ (.*?)$',
        returnBegin: true,
        end: '$',
        contains: [
          {
            className: 'string',
            begin: ' |\\}',
            end: ' |\\{',
            excludeBegin: true,
            excludeEnd: true,
            contains: [
              {
                className: 'title',
                begin: '\\{',
                end: '\\}',
                excludeBegin: false,
                excludeEnd: false,
              },
            ],
          },
          {
            className: 'keyword',
            begin: '[A-Z]+',
          },
        ],
      },
    ],
  };
}

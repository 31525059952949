import { StepProps } from './WorkflowStep';
import styled from 'styled-components';
import { H6 } from 'src/CleanSlate';

type StepNameExtraProp = {
  isDisabled: boolean;
};
type StepNameProps = Omit<StepProps, 'data'> & StepNameExtraProp;

interface StepNameProp extends StepNameExtraProp {
  isPreview: boolean;
}

const StepName = styled(H6)<StepNameProp>`
  font-size: 15px;
  margin: 0px 0px 6px 0px;
  padding: 3px 0px 0px 0px;
  color: ${(props) =>
    props.isPreview
      ? props.theme.staticColors.Blue.C000
      : props.theme.colors.C901};
  font-weight: ${(props) => (props.isPreview ? 'bold' : '500')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : '')};

  &:hover {
    cursor: pointer;
  }
`;
export function Name({
  stepName,
  selected,
  onStepClick,
  isDisabled,
}: StepNameProps) {
  return (
    <StepName
      isPreview={selected === stepName}
      isDisabled={isDisabled}
      onClick={(e) => {
        const target = e.target as HTMLInputElement;
        onStepClick(target.innerText);
      }}
    >
      {stepName}
    </StepName>
  );
}

import * as Sentry from '@sentry/react';
import { HTTP_STATUS_CODES } from './constants/HttpConstants';

import { convertBlobToBase64, parseDataUriIntoBlob } from './HttpUtils';
import { ProxyResponseInterface } from './ResponseViewer/ProxyResponseInterface';

const PROXY2_HEADERS_PREFIX = 'x-apimatic-proxy-header-';
const PROXY2_URL_HEADER = 'X-APIMatic-Proxy-URL';
const PROXY2_SSL_HEADER = 'X-APIMatic-Proxy-SSL';
const PROXY2_METHOD_HEADER = 'X-APIMatic-Proxy-Method';
const PROXY2_RESPONSE_TYPE_HEADER = 'X-Apimatic-Proxy-ResponseType';
const PROXY2_HEADERS_TO_PRESERVE = ['content-type', 'content-length'];

/**
 * Interoperable structure for describing an HTTP request used by DxDOM for HttpCallTemplate
 * in CompilableCodeBlock
 */
interface InteropHTTPRequest {
  method: string;
  url: string;
  headers: { [key: string]: string };
  body: NoBody | StringBody | FormBody | MultipartBody | JsonBody | FileBody;
}

/**
 * Empty body
 */
interface NoBody {
  type: 'none';
}

/**
 * String/text body
 */
interface StringBody {
  type: 'text';
  data: string;
}

/**
 * Send file as binary in body
 */
interface FileBody {
  type: 'file';
  data: string;
}

/**
 * Send x-www-url-encoded body
 */
interface FormBody {
  type: 'form';
  data: [[string, string]];
}

/**
 * Send multipart form body
 */
interface MultipartBody {
  type: 'multipart';
  data: [[string, string]];
  files: [[string, string]];
}

/**
 * Send JSON in body
 */
interface JsonBody {
  type: 'json';
  data: Record<string, unknown>;
}

/**
 * `PreparedHttpRequest` object is used to pass around the request data. This
 * replaces the use of `Request` class which is difficult to clone with its
 * body intact. So most methods in this file will use a `PreparedHttpRequest`
 * interface to pass around the request data until the request needs to be
 * sent at which point we convert this object to a `Request` instance using the
 * `preparedHttpRequestToFetchRequest()` method.
 */
type PreparedHttpRequest = {
  url: string;
  method: string;
} & RequestInit;

/**
 * Parse and execute a DxDOM request structure and get response as proxy-style response object
 */
export function executeRequestFromString(
  requestString: string,
  useProxy: boolean,
  proxy2Url: string,
  skipSslCertVerification?: boolean
): Promise<ProxyResponseInterface> {
  try {
    const parsed = JSON.parse(requestString);
    const request = prepareRequest(parsed);
    return useProxy
      ? executeRequestViaProxy(request, proxy2Url, skipSslCertVerification)
      : executeRequestDirectly(request);
  } catch (ex) {
    Sentry.setTag('apimaticAppReferance', 'Template Parsing Error');
    Sentry.captureException(ex);
    return Promise.reject({
      IsCalled: false,
      Error: 'Could not create a request.',
    });
  }
}

/**
 * Execute a DxDOM request structure and get response
 */
async function executeRequestDirectly(
  request: PreparedHttpRequest
): Promise<ProxyResponseInterface> {
  const fetchRequest = preparedHttpRequestToFetchRequest(request);
  const fetchResponse = await fetch(fetchRequest);
  const adaptedResponse = convertFetchResponseToProxyResponse(fetchResponse);

  return adaptedResponse;
}

function prepareRequest(request: InteropHTTPRequest): PreparedHttpRequest {
  return {
    url: request.url,
    method: request.method,
    headers: appendContentHeaders(request.headers, request),
    mode: 'cors',
    body: makeRequestBody(request.body),
  };
}

/**
 * Convert response from fetch to proxy-style response object
 */
function convertFetchResponseToProxyResponse(
  response: Response
): Promise<ProxyResponseInterface> {
  return response
    .blob()
    .then(convertBlobToBase64)
    .then<ProxyResponseInterface>((body) => ({
      IsCalled: true,
      StatusCode: response.status,
      ReasonPhrase: response.statusText,
      RawContent: body,
      Headers: convertHeadersToObject(response.headers),
    }));
}

/**
 * Convert headers to simple JS object
 * @param headers Headers object
 */
function convertHeadersToObject(headers: Headers) {
  const obj: { [key: string]: string } = {};
  headers.forEach(function (value: string, name: string) {
    const val = headers.get(name);
    if (val !== null) {
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * Add content headers to headers dictionaries based on body
 */
function appendContentHeaders(
  headers: {
    [key: string]: string;
  },
  request: InteropHTTPRequest
): Headers {
  const cpy = new Headers(headers);

  if (cpy.has('content-type')) {
    // custom content-type not allowed on form requests
    if (request.body.type === 'form') {
      cpy.set('content-type', 'application/x-www-form-urlencoded');
    }

    // remove content-type in case of multipart requests so that
    // it will be calculated automatically by the fetch client.
    if (request.body.type === 'multipart') {
      cpy.delete('content-type');
    }

    // return if custom content-type was added by user
    return cpy;
  }

  // Add content type
  if (request.body.type === 'json') {
    cpy.set('content-type', 'application/json');
  } else if (request.body.type === 'file') {
    cpy.set('content-type', 'application/octet-stream');
  }
  return cpy;
}

/**
 * Make body for fetch from DxDom request structure
 * @param request DxDom Request
 */
function makeRequestBody(
  body: InteropHTTPRequest['body']
): RequestInit['body'] {
  switch (body.type) {
    case 'none':
      return null;
    case 'json':
      return makeJSONBody(body);
    case 'multipart':
      return makeMultipartBody(body);
    case 'form':
      return makeFormBody(body);
    case 'text':
      return makeStringBody(body);
    case 'file':
      return makeFileBody(body);
    default:
      // an error here means you have not handled all body.type values above
      return assertNever(body);
  }
}

/**
 * Make file body from InteropHTTPRequest body
 */
function makeFileBody(body: FileBody) {
  return parseDataUriIntoBlob(body.data);
}

/**
 * Make string body from InteropHTTPRequest body
 */
function makeStringBody(body: StringBody) {
  return body.data;
}

/**
 * Make form body from InteropHTTPRequest body
 */
function makeFormBody(body: FormBody) {
  const formData = new URLSearchParams();
  for (let index = 0; index < body.data.length; index++) {
    const element = body.data[index];
    formData.append(element[0], element[1]);
  }
  return formData;
}

/**
 * Make multipart body from InteropHTTPRequest body
 */
function makeMultipartBody(body: MultipartBody) {
  const formData = new FormData();

  for (let index = 0; index < body.data.length; index++) {
    const element = body.data[index];

    const elementValue =
      typeof element[1] === 'object' ? JSON.stringify(element[1]) : element[1];

    formData.append(element[0], elementValue);
  }
  for (let index = 0; index < body.files.length; index++) {
    const element = body.files[index];
    formData.append(element[0], parseDataUriIntoBlob(element[1]));
  }
  return formData;
}

/**
 * Make json body from InteropHTTPRequest body
 */
function makeJSONBody(body: JsonBody) {
  return JSON.stringify(body.data);
}

function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

/**
 * Executes a HTTP call via the APIMatic Proxy 2 service.
 *
 * @param request Request
 * @param proxy2Url Proxy 2 server URL
 * @returns
 */
async function executeRequestViaProxy(
  request: PreparedHttpRequest,
  proxy2Url: string,
  skipSslCertVerification?: boolean
): Promise<ProxyResponseInterface> {
  // Checkout how the proxy2 service works here:
  //    https://github.com/apimatic/apimatic-proxy-v2/wiki

  const adaptedRequest = await adaptRequestForProxy2(
    proxy2Url,
    request,
    skipSslCertVerification
  );
  const fetchResponse = await fetch(adaptedRequest);
  const adaptedResponse = adaptResponseFromProxy2(fetchResponse);

  return adaptedResponse;
}

async function adaptRequestForProxy2(
  apiProxy2Url: string,
  request: PreparedHttpRequest,
  skipSslCertVerification?: boolean
): Promise<Request> {
  const proxy2Request: PreparedHttpRequest = {
    url: apiProxy2Url,
    method: 'POST',
    headers: adaptRequestHeadersForProxy2(request, skipSslCertVerification),
    mode: 'cors',
    body: request.body,
  };

  return preparedHttpRequestToFetchRequest(proxy2Request);
}

function adaptRequestHeadersForProxy2(
  request: PreparedHttpRequest,
  skipSslCertVerification?: boolean
): Headers {
  const inHeaders = new Headers(request.headers);
  const outHeaders = new Headers();

  // Copy over request headers while prefixing them
  inHeaders.forEach((value, key) => {
    outHeaders.append(PROXY2_HEADERS_PREFIX + key, value);
  });

  // Add APIMatic Proxy 2 specific headers
  outHeaders.set(PROXY2_URL_HEADER, request.url);
  outHeaders.set(PROXY2_METHOD_HEADER, request.method);
  outHeaders.set(
    PROXY2_SSL_HEADER,
    skipSslCertVerification ? 'disable' : 'enable'
  );

  // Certain reserved headers must be passed-through as is.
  for (const header of PROXY2_HEADERS_TO_PRESERVE) {
    if (inHeaders.has(header)) {
      outHeaders.delete(PROXY2_HEADERS_PREFIX + header);
      outHeaders.set(header, inHeaders.get(header)!);
    }
  }

  return outHeaders;
}

async function adaptResponseFromProxy2(
  response: Response
): Promise<ProxyResponseInterface> {
  // Proxy2 sets a special header to 'Server' the response is from the
  // API being called.
  if (
    response.headers.has(PROXY2_RESPONSE_TYPE_HEADER) &&
    response.headers.get(PROXY2_RESPONSE_TYPE_HEADER) === 'Server'
  ) {
    return convertFetchResponseToProxyResponse(
      adaptProxy2ResponseToFetchResponse(response)
    );
  }

  // It seems like the API called failed for some reason. Proxy2 sends details
  // in the body as JSON.
  const responseBody = await response.json();
  return {
    IsCalled: false,
    ReasonPhrase: responseBody.detail,
    StatusCode: responseBody.status,
  };
}

function adaptProxy2ResponseToFetchResponse(response: Response): Response {
  const { status, statusText, body } = response;
  // You can't send body on 204 status code
  const payload = status !== HTTP_STATUS_CODES.NO_CONTENT ? body : null;

  return new Response(payload, {
    status,
    statusText,
    headers: adaptProxy2ResponseHeaders(response.headers),
  });
}

function adaptProxy2ResponseHeaders(proxy2Headers: Headers): Headers {
  const headers = new Headers();

  proxy2Headers.forEach((val, key) => {
    if (key.toLowerCase().indexOf(PROXY2_HEADERS_PREFIX) === 0) {
      headers.append(key.substring(PROXY2_HEADERS_PREFIX.length), val);
    }
  });

  for (const header of PROXY2_HEADERS_TO_PRESERVE) {
    if (proxy2Headers.has(header)) {
      headers.set(header, proxy2Headers.get(header)!);
    }
  }

  return headers;
}

function preparedHttpRequestToFetchRequest(
  request: PreparedHttpRequest
): Request {
  const { url, ...requestInit } = request;
  return new Request(url, requestInit);
}

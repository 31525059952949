/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ServerDarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M102.5,30.6v17.5c0,1.5-1.2,2.7-2.7,2.7H47.4
	c-1.5,0-2.7-1.2-2.7-2.7V30.6c0-2.7,2.2-4.8,4.8-4.8h48.1C100.3,25.7,102.5,27.9,102.5,30.6z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M102.5,54.8v19.6c0,1.5-1.2,2.7-2.7,2.7H47.4
	c-1.5,0-2.7-1.2-2.7-2.7V54.8c0-1.5,1.2-2.7,2.7-2.7h52.3C101.3,52.1,102.5,53.3,102.5,54.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M102.5,81.2v17.5c0,2.7-2.2,4.8-4.8,4.8H49.5
	c-2.7,0-4.8-2.2-4.8-4.8V81.2c0-1.5,1.2-2.7,2.7-2.7h52.3C101.3,78.5,102.5,79.7,102.5,81.2z"
    />
    <path
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M82.9,37.5h-1.3c-0.6,0-1.1-0.5-1.1-1.1v-1.3
	c0-0.6,0.5-1.1,1.1-1.1h1.3c0.6,0,1.1,0.5,1.1,1.1v1.3C84.1,37,83.6,37.5,82.9,37.5z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M88.9,37.5h-1.3c-0.6,0-1.1-0.5-1.1-1.1v-1.3
	c0-0.6,0.5-1.1,1.1-1.1h1.3c0.6,0,1.1,0.5,1.1,1.1v1.3C90.1,37,89.6,37.5,88.9,37.5z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M94.6,37.5h-1.3c-0.6,0-1.1-0.5-1.1-1.1v-1.3
	c0-0.6,0.5-1.1,1.1-1.1h1.3c0.6,0,1.1,0.5,1.1,1.1v1.3C95.7,37,95.2,37.5,94.6,37.5z"
    />
    <path
      fill="#F6F6F9"
      d="M97,27.2H48.4c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4H97c0.2,0,0.4,0.2,0.4,0.4v0.4
	C97.4,27,97.2,27.2,97,27.2z"
    />
    <path
      fill="#CACFD6"
      d="M76.4,75.4c0,0.6,0,1.2-0.1,1.8H47.4c-1.5,0-2.7-1.2-2.7-2.7V54.8c0-1.5,1.2-2.7,2.7-2.7h16.8
	C71.6,57.3,76.4,65.8,76.4,75.4z"
    />
    <path
      fill="#CACFD6"
      d="M47.4,78.5h28.8c-1.4,13-11.6,23.4-24.5,25.1h-2.2c-2.7,0-4.8-2.2-4.8-4.8V81.2
	C44.7,79.7,45.9,78.5,47.4,78.5z"
    />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="45.6"
      cy="75.4"
      r="28.4"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="45.6"
      cy="75.4"
      r="22.8"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="45.6"
      cy="75.4"
      r="22.8"
    />
    <circle fill="#CACFD6" cx="45.6" cy="75.4" r="21.7" />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="45.6"
      cy="75.4"
      r="20.9"
    />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M49.2,84.6L44.6,78l-4.5,6.6
		c-0.1,0.1-0.2,0.1-0.3,0.1h-4.3c-0.3,0-0.4-0.3-0.3-0.5l6.8-9.4l-6.5-9c-0.2-0.2,0-0.5,0.3-0.5h4.3c0.1,0,0.2,0.1,0.3,0.1l4.4,6.2
		l4.3-6.2c0.1-0.1,0.2-0.1,0.3-0.1h4c0.3,0,0.4,0.3,0.3,0.5l-6.4,8.9l6.9,9.5c0.2,0.2,0,0.5-0.3,0.5h-4.4
		C49.4,84.8,49.3,84.7,49.2,84.6z"
      />
    </g>
  </SVGIcon>
);

export const ServerLightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M79.2,41.6v-4.3h-2c-0.1-0.6-0.3-1.1-0.6-1.7
	l1.4-1.4l-3-3l-1.4,1.4c-0.5-0.3-1.1-0.5-1.7-0.6v-2h-4.3v2c-0.6,0.1-1.1,0.3-1.7,0.6l-1.4-1.4l-3,3l1.4,1.4
	c-0.3,0.5-0.5,1.1-0.6,1.7h-2v4.3h2c0.1,0.6,0.3,1.1,0.6,1.7l-1.4,1.4l3,3l1.4-1.4c0.5,0.3,1.1,0.5,1.7,0.6v2h4.3v-2
	c0.6-0.1,1.1-0.3,1.7-0.6l1.4,1.4l3-3l-1.4-1.4c0.3-0.5,0.5-1.1,0.6-1.7H79.2z M72.5,42.1c-0.7,0.7-1.6,1.1-2.7,1.1
	c-2.1,0-3.8-1.7-3.8-3.8c0-1,0.4-2,1.1-2.7c0.7-0.7,1.6-1.1,2.7-1.1c1,0,2,0.4,2.7,1.1c0.7,0.7,1.1,1.6,1.1,2.7S73.1,41.4,72.5,42.1
	z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M94.1,36.4V33h-1.6c-0.1-0.5-0.3-0.9-0.5-1.3
	l1.1-1.1l-2.4-2.4l-1.1,1.1c-0.4-0.2-0.9-0.4-1.3-0.5v-1.6h-3.4v1.6c-0.5,0.1-0.9,0.3-1.3,0.5l-1.1-1.1l-2.4,2.4l1.1,1.1
	c-0.2,0.4-0.4,0.9-0.5,1.3h-1.6v3.4h1.6c0.1,0.5,0.3,0.9,0.5,1.3l-1.1,1.1l2.4,2.4l1.1-1.1c0.4,0.2,0.9,0.4,1.3,0.5v1.6h3.4v-1.6
	c0.5-0.1,0.9-0.3,1.3-0.5l1.1,1.1l2.4-2.4L92,37.7c0.2-0.4,0.4-0.9,0.5-1.3H94.1z M88.6,36.8c-0.5,0.5-1.3,0.9-2.1,0.9
	c-1.7,0-3-1.4-3-3c0-0.8,0.3-1.6,0.9-2.2c0.6-0.5,1.3-0.9,2.2-0.9c0.8,0,1.6,0.3,2.1,0.9c0.6,0.6,0.9,1.3,0.9,2.2
	S89.2,36.3,88.6,36.8z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M95.9,45.9v19c0,1.3-1,2.3-2.3,2.3H49.2
	c-1.3,0-2.3-1-2.3-2.3v-19H95.9z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M81.6,11.2L81.6,11.2L46.9,45.9l0,0
	c-2.1-2.1-2.1-5.5,0-7.6L74,11.2C76.1,9.1,79.5,9.1,81.6,11.2z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M95.9,70.6v16.7c0,1.3-1,2.3-2.3,2.3H49.2
	c-1.3,0-2.3-1-2.3-2.3V70.6c0-1.3,1-2.3,2.3-2.3h44.5C94.9,68.3,95.9,69.4,95.9,70.6z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M95.9,93.1v14.9c0,2.3-1.8,4.1-4.1,4.1H51
	c-2.3,0-4.1-1.8-4.1-4.1V93.1c0-1.3,1-2.3,2.3-2.3h44.5C94.9,90.7,95.9,91.8,95.9,93.1z"
    />
    <path
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M79.2,55.9h-0.7c-0.6,0-1.1-0.5-1.1-1.1V54
	c0-0.6,0.5-1.1,1.1-1.1h0.7c0.6,0,1.1,0.5,1.1,1.1v0.7C80.3,55.4,79.8,55.9,79.2,55.9z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M84.3,55.9h-0.7c-0.6,0-1.1-0.5-1.1-1.1V54
	c0-0.6,0.5-1.1,1.1-1.1h0.7c0.6,0,1.1,0.5,1.1,1.1v0.7C85.4,55.4,84.9,55.9,84.3,55.9z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M89,55.9h-0.7c-0.6,0-1.1-0.5-1.1-1.1V54
	c0-0.6,0.5-1.1,1.1-1.1H89c0.6,0,1.1,0.5,1.1,1.1v0.7C90.2,55.4,89.7,55.9,89,55.9z"
    />
    <path
      fill="#F6F6F9"
      d="M91.2,47.2H50c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h41.2c0.2,0,0.4,0.2,0.4,0.4v0.2
	C91.6,47,91.4,47.2,91.2,47.2z"
    />
    <path
      fill="#F6F6F9"
      d="M91.2,69.5H50c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h41.2c0.2,0,0.4,0.2,0.4,0.4v0.2
	C91.6,69.3,91.4,69.5,91.2,69.5z"
    />
    <path
      fill="#CACFD6"
      d="M73.8,88.1c0,0.5,0,1,0,1.5H49.2c-1.3,0-2.3-1-2.3-2.3V70.6c0-1.3,1-2.3,2.3-2.3h14.3
	C69.7,72.7,73.8,79.9,73.8,88.1z"
    />
    <path
      fill="#F6F6F9"
      d="M91.2,91.8H50c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h41.2c0.2,0,0.4,0.2,0.4,0.4v0.2
	C91.6,91.6,91.4,91.8,91.2,91.8z"
    />
    <path
      fill="#CACFD6"
      d="M49.2,90.7h24.5c-1.2,11.1-9.9,19.9-20.8,21.3H51c-2.3,0-4.1-1.8-4.1-4.1V93.1
	C46.8,91.8,47.9,90.7,49.2,90.7z"
    />
    <path
      opacity="0.1"
      fill="#606774"
      d="M94.4,67.2H48.3c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h46.1
	c0.2,0,0.4,0.2,0.4,0.4v0.2C94.8,67,94.6,67.2,94.4,67.2z"
    />
    <path
      opacity="0.1"
      fill="#606774"
      d="M94.4,89.5H48.3c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h46.1
	c0.2,0,0.4,0.2,0.4,0.4v0.2C94.8,89.3,94.6,89.5,94.4,89.5z"
    />
    <path
      opacity="0.1"
      fill="#606774"
      d="M94.4,111.8H48.3c-0.2,0-0.4-0.2-0.4-0.4v-0.2c0-0.2,0.2-0.4,0.4-0.4h46.1
	c0.2,0,0.4,0.2,0.4,0.4v0.2C94.8,111.6,94.6,111.8,94.4,111.8z"
    />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="47.6"
      cy="88.1"
      r="24.1"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="47.6"
      cy="88.1"
      r="19.3"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="47.6"
      cy="88.1"
      r="19.3"
    />
    <circle fill="#CACFD6" cx="47.6" cy="88.1" r="18.4" />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="47.6"
      cy="88.1"
      r="17.7"
    />
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M45.7,77.6h4.5l-0.8,14.4h-3L45.7,77.6z"
      />
    </g>
    <g>
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M48.1,97.7h-0.3c-1.1,0-2-0.9-1.9-2v0
		c0-1,0.9-1.9,1.9-1.9h0.3c1.1,0,1.9,0.8,1.9,1.9l0,0C50.1,96.9,49.2,97.7,48.1,97.7z"
      />
    </g>
  </SVGIcon>
);

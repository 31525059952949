import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const DownloadIcon2 = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '15'}
    height={props.height || '16'}
    fill={props.fill || 'none'}
    viewBox="0 0 15 16"
    {...props}
  >
    <g id="download-cloud">
      <path
        id="Vector"
        d="M2.50033 9.81216C2.03598 9.33774 1.68569 8.76386 1.47598 8.134C1.26627 7.50414 1.20265 6.83481 1.28993 6.17673C1.37722 5.51864 1.61312 4.88904 1.97977 4.33562C2.34642 3.78221 2.8342 3.31948 3.40617 2.98251C3.97813 2.64553 4.61929 2.44313 5.28106 2.39065C5.94284 2.33816 6.60788 2.43697 7.22581 2.67957C7.84375 2.92218 8.39837 3.30223 8.84767 3.79094C9.29697 4.27964 9.62916 4.86418 9.81908 5.50029H10.9378C11.5413 5.50022 12.1287 5.69424 12.6135 6.05369C13.0982 6.41314 13.4544 6.91895 13.6296 7.49642C13.8047 8.07388 13.7895 8.69237 13.5862 9.26052C13.3828 9.82867 13.0021 10.3164 12.5003 10.6515"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M7.5 8V13.625"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M5 11.125L7.5 13.625L10 11.125"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SVGIcon>
);

import { ChatbotSettings } from '../lib/ApiCopilot';

type ChatbotSettingsProps = {
  apikey: string;
  language: string;
};

export const fetchSettings = async ({
  apikey,
  language,
}: ChatbotSettingsProps): Promise<ChatbotSettings | undefined> => {
  try {
    const response = await fetch(
      `${
        process.env.NODE_ENV === 'production'
          ? 'https://chatbotapi.apimatic.io'
          : 'https://chatbotapi.dev.apimatic.io'
      }/settings/${apikey}/${language}`
    );

    if (!response.body) {
      console.error('Response body is null');
      return;
    }
    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

import styled from 'styled-components';

import { InlineCode, Pre } from '../CleanSlate';

const OpaquePre = styled(Pre)`
  background: transparent;
  font-size: 13px;
  line-height: 19px;
  margin-left: 15px;
  margin-right: 15px;
`;

/**
 * Simple text viewer for textual responses
 */
export function TextViewer(props: { children: string }) {
  return (
    <OpaquePre>
      <InlineCode>{props.children}</InlineCode>
    </OpaquePre>
  );
}

import { Placement } from '@popperjs/core';
import { PropsWithChildren } from 'react';

import { Popover } from '../popover/Popover';
import { TOOLTIP_FALLBACK_PLACEMENT, TOOLTIP_PLACEMENT } from '../../constants';
import { TooltipWrapper } from '.';

type OffsetItem = number | null | undefined;

type OffsetProp = [OffsetItem, OffsetItem];

export interface TooltipProps {
  placement?: Placement;
  fallbackPlacements?: Placement[];
  text: string;
  offset?: OffsetProp;
  parentRefranceId?: string;

  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const defaultOffset: OffsetProp = [0, 10];

export function Tooltip(props: PropsWithChildren<TooltipProps>) {
  const {
    children,
    text,
    placement = TOOLTIP_PLACEMENT,
    fallbackPlacements = TOOLTIP_FALLBACK_PLACEMENT,
    offset = defaultOffset,
    parentRefranceId,
    onMouseEnter,
  } = props;
  return (
    <Popover
      placement={placement}
      fallbackPlacements={fallbackPlacements}
      offset={offset}
      onMouseEnter={onMouseEnter}
    >
      {(props) => (
        <TooltipWrapper
          {...props}
          text={text}
          parentRefranceId={parentRefranceId}
        >
          {children}
        </TooltipWrapper>
      )}
    </Popover>
  );
}

export default Tooltip;

import styled from 'styled-components';
import { ThemeInterface } from './StyledComponents';
import { defaultElements, makeCustomTabs } from './Tabs';

const TabListItem = styled(defaultElements.TabListItem)`
  min-width: 60px;
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-bottom-color: ${(props: {
    selected?: boolean;
    theme: ThemeInterface;
  }) => (props.selected ? props.theme.colors.C001 : props.theme.colors.C300)};
  color: ${(props: { selected?: boolean; theme: ThemeInterface }) =>
    props.selected ? props.theme.colors.C800 : props.theme.colors.C1100};
  border-radius: 4px 4px 0 0;
  background-color: ${(props: { selected?: boolean; theme: ThemeInterface }) =>
    props.selected ? props.theme.colors.C001 : props.theme.colors.C201};
  font-weight: ${(props: { selected?: boolean }) =>
    props.selected ? 600 : 400};
  cursor: pointer;

  &,
  & > .tab-list-item-template-wrapper {
    color: ${(props) => props.theme.colors.C800};
    opacity: ${(props: { selected?: boolean; theme: ThemeInterface }) =>
      props.selected ? 1 : 0.75};
  }
  &:not(:first-child) {
    margin-left: 5px;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
`;
TabListItem.displayName = 'TabListItem';

const TabListItemLarge = styled(TabListItem)`
  min-width: 100px;
`;

const TabList = styled(defaultElements.TabList)`
  width: calc(100% - 60px);
  margin: 0;
  padding: 0;
  border-bottom: 0;
`;
TabList.displayName = 'TabList';

const TabPanelWrapper = styled(defaultElements.TabPanelWrapper)`
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-radius: 0 4px 4px 4px;
  padding: 20px 20px;
`;
TabPanelWrapper.displayName = 'TabPanelWrapper';

/**
 * Create custom tabs components for endpoint reference
 */
export const { Tabs: EnclosedTabs, TabPanel: EnclosedTabPanel } =
  makeCustomTabs({
    TabListItem,
    TabList,
    TabPanelWrapper,
  });

export const { Tabs: EnclosedTabsLarge } = makeCustomTabs({
  TabListItem: TabListItemLarge,
  TabList,
  TabPanelWrapper,
});

import * as React from 'react';
import styled from 'styled-components';
// import * as Color from 'color';
import { MenuItem, SubMenu } from 'rc-menu';
import { Link } from 'react-router-dom';
import { Div, Span } from '../CleanSlate';
import { NavItem } from '../DxDom';
import { OverviewIcon } from '../Icons/Ui/OverviewIcon';
import { ExternalLinkIcon } from '../Icons/Ui/ExternalLinkIcon';

const OverviewIconStyle = {
  marginRight: '10px',
  transform: 'translateY(3px)',
};

/**
 * Menu Overview Icon component
 */
function MenuOverviewIcon() {
  return (
    <OverviewIcon
      fill="#9caab8"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      style={OverviewIconStyle}
    />
  );
}

const MenuItemWrapper = styled.div<{ isExternal?: boolean }>`
  display: flex;
  cursor: pointer;
  padding-right: ${({ isExternal }) => (isExternal ? '30px' : '0px')};
`;
const ExternalLinkIconWrapper = styled(Div)`
  height: 20px;
  width: fit-content;
  padding-top: 2px;
  cursor: pointer;
  position: absolute;
  right: 13px;
`;

/**
 * HTTP verb label component for menu
 */
const MenuHttpVerbLabel = styled(Div)`
  min-width: 43px;
  width: 43px;
  height: 17px;
  display: block;
  background-color: ${(props: { color: string }) => props.color};
  color: white;
  text-align: center;
  line-height: 17px;
  border-radius: 2px;
  margin: 2px 10px 0 0;
  font-size: 10px;
  pointer-events: none;
`;
MenuHttpVerbLabel.displayName = 'MenuHttpVerbLabel';

/**
 * Does this label need space?
 * @param label label
 */
function isKnownLabelAndNeedsSpace(label?: string) {
  return (
    typeof label === 'string' &&
    ['http-get', 'http-put', 'http-post', 'http-delete', 'http-patch'].indexOf(
      label
    ) !== -1
  );
}

/**
 * Menu label component
 */
function MenuLabel({ label }: { label?: string }) {
  if (label === 'endpoint-group-overview') {
    return <MenuOverviewIcon />;
  } else if (label === 'http-get') {
    return <MenuHttpVerbLabel color="#45bf7b">GET</MenuHttpVerbLabel>;
  } else if (label === 'http-put') {
    return <MenuHttpVerbLabel color="#0099ff">PUT</MenuHttpVerbLabel>;
  } else if (label === 'http-post') {
    return <MenuHttpVerbLabel color="#ff9d2b">POST</MenuHttpVerbLabel>;
  } else if (label === 'http-delete') {
    return <MenuHttpVerbLabel color="#f24722">DELETE</MenuHttpVerbLabel>;
  } else if (label === 'http-patch') {
    return <MenuHttpVerbLabel color="#9675cc">PATCH</MenuHttpVerbLabel>;
  }
  return null;
}

/**
 * Menu link wrapper (adds some space on the left to make space for label)
 */
const IndentedMenuLink = styled(Link)`
  position: relative;
  margin-left: 42px;
  padding-left: 14px !important;
`;

const MenuItemHeading = styled(Span)`
  color: ${(props) => props.theme.colors.C600};
  font-weight: 500;
  text-transform: uppercase;
  line-height: normal;
  cursor: pointer;
  font-size: ${({ theme: { cssStyles } }) =>
    (cssStyles && cssStyles.body.text2.fontSize) || '13.33px'};
`;

IndentedMenuLink.displayName = 'IndentedMenuLink';

/**
 * Menu link wrapper (optionally adds some space on the left to make space for label)
 */
function MenuLink(props: {
  showLabel: boolean;
  children: React.ReactNode;
  to: string;
  isExternal?: boolean;
}) {
  return props.isExternal ? (
    <a href={props.to} target="_blank" rel="nofollow noopener noreferrer">
      {props.children}
    </a>
  ) : props.showLabel ? (
    <IndentedMenuLink to={props.to}>{props.children}</IndentedMenuLink>
  ) : (
    <Link to={props.to}>{props.children}</Link>
  );
}

/**
 * Render a NavItem list into MenuItems/SubMenu
 * @param nav DX NavItem list
 */
export function menuItems(
  nav: ReadonlyArray<NavItem>,
  navParent: string | undefined,
  isHeading: boolean
): (JSX.Element | undefined)[] {
  return nav.map((item) => {
    return item.Skip ? undefined : item.SubItems.length === 0 ||
      item.SubItems.every((m) => m.Skip) ? (
      <MenuItem className={item.Link.split('/').join('-')} key={item.Link}>
        <MenuLink
          to={item.Link}
          showLabel={isKnownLabelAndNeedsSpace(item.Label)}
          isExternal={item.IsExternal}
        >
          <MenuLabel label={item.Label} />
          <MenuItemWrapper isExternal={item.IsExternal}>
            {item.Text}
            {item.IsExternal ? (
              <ExternalLinkIconWrapper>
                <ExternalLinkIcon
                  style={{
                    marginLeft: 4,
                    width: 14,
                    height: 14,
                  }}
                />
              </ExternalLinkIconWrapper>
            ) : undefined}
          </MenuItemWrapper>
        </MenuLink>
      </MenuItem>
    ) : (
      <SubMenu
        title={isHeading ? <MenuHeading title={item.Text} /> : item.Text}
        key={item.Link}
      >
        {menuItems(item.SubItems, navParent, false)}
      </SubMenu>
    );
  });
}

const MenuHeading = ({ title }: { title?: string }) => {
  return <MenuItemHeading>{title}</MenuItemHeading>;
};

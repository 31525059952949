import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const AppleIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M21.645,17.525 C21.672,20.356 24.155,21.298 24.183,21.310 C24.162,
    21.376 23.786,22.653 22.875,23.971 C22.087,25.111 21.269,26.246 19.981,
    26.270 C18.715,26.293 18.308,25.527 16.860,25.527 C15.414,25.527 14.961,
    26.246 13.763,26.293 C12.519,26.340 11.572,25.060 10.778,23.925 C9.154,21.602 7.914,
    17.361 9.580,14.498 C10.407,13.077 11.886,12.177 13.491,12.154 C14.713,12.130 15.865,
    12.966 16.612,12.966 C17.358,12.966 18.758,11.961 20.231,12.109 C20.847,12.134 22.577,
    12.355 23.689,13.965 C23.599,14.020 21.624,15.158 21.645,17.525 M19.267,10.572 C19.927,
    9.781 20.371,8.680 20.250,7.585 C19.298,7.623 18.147,8.212 17.465,9.003 C16.853,9.703 16.318,
    10.823 16.462,11.897 C17.523,11.978 18.606,11.363 19.267,10.572 "
    />
  </SVGIcon>
);

import deepClone from 'lodash.clonedeep';
import { AJV } from './AJV';

export function getOneAnyOfPath(path = '', obj) {
  const result = path
    ? `${path}/${obj.$$__case_of}/${obj.$$__case}`
    : `${obj.$$__case_of}/${obj.$$__case}`;

  return result;
}

function isPrimitiveSchema(schema) {
  return (
    schema.type &&
    schema.type !== 'object' &&
    schema.type !== 'array' &&
    !Object.prototype.hasOwnProperty.call(schema, 'oneOf') &&
    !Object.prototype.hasOwnProperty.call(schema, 'anyOf')
  );
}

export function validateField(schema, formData, required, disabled) {
  let errors = [];
  // We shows validation only on enabled fields
  if (!disabled) {
    // if formData === undefined it means that we are in initial state
    if (formData === undefined || formData === '' || formData === null) {
      // if optional field we don't need to show errors;
      // otherwise we will show required error on initial state.
      if (required && isPrimitiveSchema(schema)) {
        errors = ['is a required field'];
      } else {
        return errors;
      }
    } else if (isPrimitiveSchema(schema)) {
      const clonedSchema = deepClone(schema);

      const ajv = AJV.getInstance();
      ajv.validate(clonedSchema, formData);

      errors = ajv.errors
        ? [...ajv.errors.map((err) => err.message), ...errors]
        : errors;
    }
  }

  return errors;
}

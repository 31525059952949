import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const PackageCheckIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '14'}
    height={props.height || '14'}
    fill={props.fill || 'none'}
    viewBox="0 0 14 14"
    style={{ marginRight: '5px', marginBottom: '2px' }}
    {...props}
  >
    <path
      d="M9.33398 9.33268L10.5007 10.4993L12.834 8.16602"
      stroke={props.stroke || '#0062FF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 5.83344V4.66677C12.2498 4.46218 12.1958 4.26124 12.0934 4.08412C11.991 3.90699 11.8438 3.7599 11.6667 3.65761L7.58333 1.32427C7.40598 1.22188 7.20479 1.16797 7 1.16797C6.79521 1.16797 6.59402 1.22188 6.41667 1.32427L2.33333 3.65761C2.15615 3.7599 2.00899 3.90699 1.9066 4.08412C1.80422 4.26124 1.75021 4.46218 1.75 4.66677V9.33344C1.75021 9.53803 1.80422 9.73897 1.9066 9.91609C2.00899 10.0932 2.15615 10.2403 2.33333 10.3426L6.41667 12.6759C6.59402 12.7783 6.79521 12.8322 7 12.8322C7.20479 12.8322 7.40598 12.7783 7.58333 12.6759L8.75 12.0109"
      stroke={props.stroke || '#0062FF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.62513 5.48266L4.4043 2.47266"
      stroke={props.stroke || '#0062FF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.91992 4.08398L7.00076 7.00065L12.0816 4.08398"
      stroke={props.stroke || '#0062FF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12.8333V7"
      stroke={props.stroke || '#0062FF'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);

/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const TimeOutDarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M100,31.3V48c0,1.4-1.2,2.6-2.6,2.6h-50
		c-1.4,0-2.6-1.2-2.6-2.6V31.3c0-2.6,2.1-4.6,4.6-4.6h45.9C98,26.7,100,28.8,100,31.3z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M100,54.5v18.7c0,1.4-1.2,2.6-2.6,2.6h-50
		c-1.4,0-2.6-1.2-2.6-2.6V54.5c0-1.4,1.2-2.6,2.6-2.6h50C98.9,51.9,100,53.1,100,54.5z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M100,79.7v16.7c0,2.6-2.1,4.6-4.6,4.6H49.5
		c-2.6,0-4.6-2.1-4.6-4.6V79.7c0-1.4,1.2-2.6,2.6-2.6h50C98.9,77.1,100,78.3,100,79.7z"
      />
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M81.4,38h-1.2c-0.6,0-1.1-0.5-1.1-1.1v-1.2
		c0-0.6,0.5-1.1,1.1-1.1h1.2c0.6,0,1.1,0.5,1.1,1.1v1.2C82.5,37.5,82,38,81.4,38z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M87.1,38h-1.2c-0.6,0-1.1-0.5-1.1-1.1v-1.2
		c0-0.6,0.5-1.1,1.1-1.1h1.2c0.6,0,1.1,0.5,1.1,1.1v1.2C88.2,37.5,87.7,38,87.1,38z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M92.5,38h-1.2c-0.6,0-1.1-0.5-1.1-1.1v-1.2
		c0-0.6,0.5-1.1,1.1-1.1h1.2c0.6,0,1.1,0.5,1.1,1.1v1.2C93.6,37.5,93.1,38,92.5,38z"
      />
      <g>
        <rect
          x="42.5"
          y="38"
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          width="7.3"
          height="14.8"
        />
        <path
          fill="#CACFD6"
          d="M49.2,45.1h-6.2c-0.3,0-0.5-0.2-0.5-0.5V44c0-0.3,0.2-0.5,0.5-0.5h6.2c0.3,0,0.5,0.2,0.5,0.5v0.6
			C49.8,44.8,49.5,45.1,49.2,45.1z"
        />
        <path
          fill="#CACFD6"
          d="M49.2,48h-6.2c-0.3,0-0.5-0.2-0.5-0.5v-0.6c0-0.3,0.2-0.5,0.5-0.5h6.2c0.3,0,0.5,0.2,0.5,0.5v0.6
			C49.8,47.8,49.5,48,49.2,48z"
        />
        <circle
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          cx="45.8"
          cy="74.1"
          r="27.1"
        />
        <circle
          fill="#F6F6F9"
          stroke="#9CAAB8"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          cx="45.8"
          cy="74.1"
          r="21.7"
        />
        <path
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M57,38H34.5c-1.8,0-3.2,1.4-3.2,3.2v0
			c0,1.8,1.4,3.2,3.2,3.2H57c1.8,0,3.2-1.4,3.2-3.2v0C60.2,39.4,58.8,38,57,38z"
        />
        <g>
          <path
            fill="#2750AE"
            d="M45.2,91.2c-15,0.5-22.7-17.7-12.5-28.6c3.1-3.4,7.7-5.5,12.5-5.7v4c-3.7-0.2-7.5,1.2-10.3,3.8
				C24.9,74.1,31.6,90.8,45.2,91.2L45.2,91.2z"
          />
        </g>
        <path
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M46,56.3l-0.5,0c-0.3,0-0.5,0.2-0.5,0.5
			L45,72.9c0,0.3,0.2,0.5,0.5,0.5l0.5,0c0.3,0,0.5-0.2,0.5-0.5l-0.1-16.1C46.5,56.5,46.3,56.3,46,56.3z"
        />
        <path
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M60.9,74.4l0-0.5c0-0.3-0.2-0.5-0.5-0.5
			l-13.2,0.1c-0.3,0-0.5,0.2-0.5,0.5l0,0.5c0,0.3,0.2,0.5,0.5,0.5l13.2-0.1C60.7,74.9,60.9,74.7,60.9,74.4z"
        />
        <circle
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          cx="45.8"
          cy="74.1"
          r="2.9"
        />
      </g>
    </g>
  </SVGIcon>
);

export const TimeOutLightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M100,31.3V48c0,1.4-1.2,2.6-2.6,2.6h-50
		c-1.4,0-2.6-1.2-2.6-2.6V31.3c0-2.6,2.1-4.6,4.6-4.6h45.9C98,26.7,100,28.8,100,31.3z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M100,54.5v18.7c0,1.4-1.2,2.6-2.6,2.6h-50
		c-1.4,0-2.6-1.2-2.6-2.6V54.5c0-1.4,1.2-2.6,2.6-2.6h50C98.9,51.9,100,53.1,100,54.5z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M100,79.7v16.7c0,2.6-2.1,4.6-4.6,4.6H49.5
		c-2.6,0-4.6-2.1-4.6-4.6V79.7c0-1.4,1.2-2.6,2.6-2.6h50C98.9,77.1,100,78.3,100,79.7z"
      />
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M81.4,38h-1.2c-0.6,0-1.1-0.5-1.1-1.1v-1.2
		c0-0.6,0.5-1.1,1.1-1.1h1.2c0.6,0,1.1,0.5,1.1,1.1v1.2C82.5,37.5,82,38,81.4,38z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M87.1,38h-1.2c-0.6,0-1.1-0.5-1.1-1.1v-1.2
		c0-0.6,0.5-1.1,1.1-1.1h1.2c0.6,0,1.1,0.5,1.1,1.1v1.2C88.2,37.5,87.7,38,87.1,38z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M92.5,38h-1.2c-0.6,0-1.1-0.5-1.1-1.1v-1.2
		c0-0.6,0.5-1.1,1.1-1.1h1.2c0.6,0,1.1,0.5,1.1,1.1v1.2C93.6,37.5,93.1,38,92.5,38z"
      />
      <g>
        <rect
          x="42.5"
          y="38"
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          width="7.3"
          height="14.8"
        />
        <path
          fill="#CACFD6"
          d="M49.2,45.1h-6.2c-0.3,0-0.5-0.2-0.5-0.5V44c0-0.3,0.2-0.5,0.5-0.5h6.2c0.3,0,0.5,0.2,0.5,0.5v0.6
			C49.8,44.8,49.5,45.1,49.2,45.1z"
        />
        <path
          fill="#CACFD6"
          d="M49.2,48h-6.2c-0.3,0-0.5-0.2-0.5-0.5v-0.6c0-0.3,0.2-0.5,0.5-0.5h6.2c0.3,0,0.5,0.2,0.5,0.5v0.6
			C49.8,47.8,49.5,48,49.2,48z"
        />
        <circle
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          cx="45.8"
          cy="74.1"
          r="27.1"
        />
        <circle
          fill="#F6F6F9"
          stroke="#9CAAB8"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          cx="45.8"
          cy="74.1"
          r="21.7"
        />
        <g>
          <path
            fill="#2750AE"
            d="M45.2,91.2c-15,0.5-22.7-17.7-12.5-28.6c3.1-3.4,7.7-5.5,12.5-5.7v4c-3.7-0.2-7.5,1.2-10.3,3.8
				C24.9,74.1,31.6,90.8,45.2,91.2L45.2,91.2z"
          />
        </g>
        <path
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M57,38H34.5c-1.8,0-3.2,1.4-3.2,3.2v0
			c0,1.8,1.4,3.2,3.2,3.2H57c1.8,0,3.2-1.4,3.2-3.2v0C60.2,39.4,58.8,38,57,38z"
        />
        <path
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M46,56.3l-0.5,0c-0.3,0-0.5,0.2-0.5,0.5
			L45,72.9c0,0.3,0.2,0.5,0.5,0.5l0.5,0c0.3,0,0.5-0.2,0.5-0.5l-0.1-16.1C46.5,56.5,46.3,56.3,46,56.3z"
        />
        <path
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M60.9,74.4l0-0.5c0-0.3-0.2-0.5-0.5-0.5
			l-13.2,0.1c-0.3,0-0.5,0.2-0.5,0.5l0,0.5c0,0.3,0.2,0.5,0.5,0.5l13.2-0.1C60.7,74.9,60.9,74.7,60.9,74.4z"
        />
        <circle
          fill="#3E445D"
          stroke="#1D2236"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          cx="45.8"
          cy="74.1"
          r="2.9"
        />
      </g>
    </g>
  </SVGIcon>
);

export const ArrowCircleLeftIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 1.33337C8.28785 1.33337 8.54324 1.51823 8.63321 1.79174L9.90788 5.66707L9.90788 5.66708C9.94049 5.76625 9.99595 5.85637 10.0698 5.93019C10.1436 6.00401 10.2337 6.05947 10.3329 6.09208L10.3329 6.09208L14.2082 7.36675C14.4817 7.45672 14.6666 7.71211 14.6666 8.00004C14.6666 8.28797 14.4817 8.54337 14.2082 8.63333L10.3329 9.908L10.3329 9.908C10.2337 9.94062 10.1436 9.99607 10.0698 10.0699C9.99595 10.1437 9.94049 10.2338 9.90788 10.333L9.90788 10.333L8.63321 14.2083C8.54324 14.4819 8.28785 14.6667 7.99992 14.6667C7.71199 14.6667 7.45659 14.4819 7.36663 14.2083L6.09196 10.333L6.09196 10.333C6.05934 10.2338 6.00389 10.1437 5.93007 10.0699C5.85625 9.99607 5.76613 9.94062 5.66696 9.908L5.66695 9.908L1.79162 8.63333C1.5181 8.54337 1.33325 8.28797 1.33325 8.00004C1.33325 7.71211 1.5181 7.45672 1.79162 7.36675L5.66695 6.09208L5.66696 6.09208C5.76613 6.05947 5.85625 6.00401 5.93007 5.93019C6.00389 5.85637 6.05934 5.76625 6.09196 5.66708L6.09196 5.66707L7.36663 1.79174C7.45659 1.51823 7.71199 1.33337 7.99992 1.33337ZM7.35854 6.08367C7.35854 6.08367 7.35854 6.08367 7.35854 6.08367C7.26069 6.38118 7.09434 6.65155 6.87288 6.873C6.65143 7.09446 6.38106 7.26081 6.08355 7.35866C6.08355 7.35866 6.08355 7.35867 6.08354 7.35867L4.13359 8.00004L6.08354 8.64142C6.08355 8.64142 6.08355 8.64142 6.08355 8.64142C6.38106 8.73927 6.65143 8.90562 6.87288 9.12708C7.09434 9.34853 7.26069 9.6189 7.35854 9.91641C7.35854 9.91641 7.35854 9.91641 7.35854 9.91642L7.99992 11.8664L8.64129 9.91642C8.64129 9.91641 8.6413 9.91641 8.6413 9.91641C8.73915 9.6189 8.9055 9.34853 9.12696 9.12708C9.34841 8.90562 9.61878 8.73927 9.91629 8.64142C9.91629 8.64142 9.91629 8.64142 9.91629 8.64142L11.8662 8.00004L9.91629 7.35867C9.91629 7.35867 9.91629 7.35866 9.91629 7.35866C9.61878 7.26081 9.34841 7.09446 9.12696 6.873C8.9055 6.65155 8.73915 6.38118 8.6413 6.08367C8.6413 6.08367 8.64129 6.08367 8.64129 6.08367L7.99992 4.13371L7.35854 6.08367Z"
        fill="#3E445D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33341 1.33337C3.7016 1.33337 4.00008 1.63185 4.00008 2.00004V4.66671C4.00008 5.0349 3.7016 5.33337 3.33341 5.33337C2.96522 5.33337 2.66675 5.0349 2.66675 4.66671V2.00004C2.66675 1.63185 2.96522 1.33337 3.33341 1.33337Z"
        fill="#3E445D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 10.6666C13.0349 10.6666 13.3333 10.9651 13.3333 11.3333V14C13.3333 14.3681 13.0349 14.6666 12.6667 14.6666C12.2985 14.6666 12 14.3681 12 14V11.3333C12 10.9651 12.2985 10.6666 12.6667 10.6666Z"
        fill="#3E445D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 3.33329C1.33325 2.9651 1.63173 2.66663 1.99992 2.66663H4.66659C5.03478 2.66663 5.33325 2.9651 5.33325 3.33329C5.33325 3.70148 5.03478 3.99996 4.66659 3.99996H1.99992C1.63173 3.99996 1.33325 3.70148 1.33325 3.33329Z"
        fill="#3E445D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 12.6667C10.6667 12.2985 10.9652 12 11.3334 12H14.0001C14.3683 12 14.6667 12.2985 14.6667 12.6667C14.6667 13.0349 14.3683 13.3333 14.0001 13.3333H11.3334C10.9652 13.3333 10.6667 13.0349 10.6667 12.6667Z"
        fill="#3E445D"
      />
    </svg>
  );
};

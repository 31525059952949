import React from 'react';
import PropTypes from 'prop-types';

import {
  getDefaultFormState,
  checkDiscriminator,
  prefixClass,
  isMultipleSchema,
  classNames,
  isOneOfSchema,
  retrieveSchema,
  getEvenOddClass,
  getEvenOdd,
  findSchemaDefinition,
  isOafContainer,
} from '../../utils';
import TagSelector from '../widgets/TagSelector';
import { getOneAnyOfPath } from '../../validationUtils';
import { DefaultTemplate } from './SchemaField';

const CHAR_THRESHOLD = 120;

export function generateFormDataForMultipleSchema(schema, index, caseOf) {
  if (isMultipleSchema(schema)) {
    const _schema = schema.oneOf ? schema.oneOf[index] : schema.anyOf[index];

    return {
      $$__case: index,
      $$__case_of: caseOf,
      value: generateFormDataForMultipleSchema(
        _schema,
        0,
        getMultipleSchemaType(_schema)
      ),
    };
  }
  return computeInitialValue(schema);
}

const getMultipleSchemaType = (schema) => {
  return schema &&
    Object.prototype.hasOwnProperty.call(schema, 'parentDiscriminator')
    ? 'Inheritance'
    : Object.prototype.hasOwnProperty.call(schema, 'oneOf')
    ? 'oneOf'
    : 'anyOf';
};
function computeInitialValue(schema) {
  if (schema.type === 'object') {
    return {};
  } else if (schema.type === 'array') {
    return [];
  } else if (schema && isMultipleSchema(schema)) {
    return generateFormDataForMultipleSchema(
      schema,
      0,
      getMultipleSchemaType(schema)
    );
  } else {
    return undefined;
  }
}

function getInitialFormData(schema, index, caseOf) {
  let initialFormData = {
    $$__case: index,
    $$__case_of: caseOf,
    value: computeInitialValue(schema),
  };
  return initialFormData;
}

function getMultipleLabel(schema) {
  if (Object.prototype.hasOwnProperty.call(schema, 'anyOf')) {
    return 'Any Of';
  }
  if (Object.prototype.hasOwnProperty.call(schema, 'oneOf')) {
    return 'One Of';
  }
}

class DiscriminatorField extends React.Component {
  state;
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.depth < 4 || !isOafContainer(props.schema) ? false : true,
      checked: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { schema, formData, parentPath, required, fromDiscriminator } = props;

    /**
     * Fixes {@link https://github.com/apimatic/apimatic-dx-portal/issues/426}
     * When both anyOf and oneOf exists then pick oneOf as currently there is
     * limited support in docgen side
     * 🙌🙌🙌
     */
    if (schema.oneOf && schema.anyOf) {
      delete schema.anyOf;
    }

    const initialSchema = schema.oneOf || schema.anyOf;

    const { typeCombinatorTypes } = schema;

    const discriminator = schema.discriminator;
    const newInitialSchema = initialSchema.map((subSchema, index) => {
      if (
        typeCombinatorTypes &&
        typeCombinatorTypes[index].ContainsSubTypes &&
        typeCombinatorTypes[index].SubDiscriminator
      ) {
        const discriminatorValue =
          typeCombinatorTypes[index].DiscriminatorValue;
        const parentDiscriminator = {
          [discriminator]: discriminatorValue,
          ...(schema.parentDiscriminator && { ...schema.parentDiscriminator }),
        };
        subSchema = {
          ...subSchema,
          discriminator: typeCombinatorTypes[index].SubDiscriminator,
          parentDiscriminator: parentDiscriminator,
          discriminatorValue: typeCombinatorTypes[index].DiscriminatorValue,
          typeCombinatorTypes: typeCombinatorTypes[index].SubTypes,
          ContainsSubTypes: typeCombinatorTypes[index].ContainsSubTypes,
        };
      }
      return subSchema;
    });

    const initialSchemaIndex =
      formData && formData.$$__case ? formData.$$__case : 0;

    const data = formData || {};

    const caseOf = getMultipleSchemaType(schema);

    const newFormData = formData
      ? {
          ...state.formState,
          [getOneAnyOfPath(parentPath, data)]: formData,
        }
      : {};

    const newState = {
      formState: newFormData,
      selectedSchema: {
        index: initialSchemaIndex,
        schema: newInitialSchema[initialSchemaIndex],
      },
      caseOf,
      optional: fromDiscriminator ? false : !required,
      checked: formData?.value
        ? true
        : formData === undefined
        ? false
        : formData === null
        ? false
        : true,
    };

    return newState;
  }

  onDiscriminatorChange = () => {
    const { onChange, formData, parentPath } = this.props;
    const { selectedSchema } = this.state;

    return (value, options, schemaIndex) => {
      let newFormData;

      if (checkDiscriminator(formData)) {
        newFormData = {
          ...formData,
          $$__case: selectedSchema.index,
          value,
        };
      } else {
        newFormData = {
          ...formData,
          value,
        };
      }

      this.setState((st) => {
        return {
          ...st,
          formState: {
            ...st.formState,
            [getOneAnyOfPath(parentPath, newFormData)]: newFormData,
          },
        };
      });
      onChange(
        newFormData,
        {
          validate: false,
        },
        this.state.selectedSchema.index
      );
    };
  };

  renderSchema = (depth) => {
    const {
      disabled,
      errorSchema,
      idPrefix,
      onBlur,
      onFocus,
      registry,
      uiSchema,
      typeCombinatorTypes: typeCombinatorTypesFromProps,
      parentPath,
      formData,
      schema,
    } = this.props;

    const { fields, dxInterface } = registry;

    const { definitions } = dxInterface;

    const _SchemaField = fields.SchemaField;

    const { selectedSchema } = this.state;

    const { selectOptions } = this.getSelectOptions();

    const { typeCombinatorTypes = typeCombinatorTypesFromProps } = schema;

    const schemaDefination =
      Object.prototype.hasOwnProperty.call(selectedSchema.schema, '$ref') &&
      findSchemaDefinition(selectedSchema.schema.$ref, definitions);

    const hasSchemaDefination =
      schemaDefination && schemaDefination.type === 'object';

    const childIsMap =
      selectedSchema.schema &&
      selectedSchema.schema.type === 'object' &&
      Object.prototype.hasOwnProperty.call(
        selectedSchema.schema,
        'additionalProperties'
      ) &&
      selectedSchema.schema.additionalProperties.type !== 'object';

    const childIsArray =
      selectedSchema.schema &&
      selectedSchema.schema.type === 'array' &&
      Object.prototype.hasOwnProperty.call(selectedSchema.schema, 'items') &&
      selectedSchema.schema.items.type !== 'object';

    const childIsNestedMultipleSchema = isMultipleSchema(selectedSchema.schema);

    const isDiscriminatorChild =
      !(childIsArray || childIsMap || childIsNestedMultipleSchema) &&
      hasSchemaDefination;

    const uiTitle = selectOptions[selectedSchema.index].label;

    const selectedSchemaTypeCombinator =
      typeCombinatorTypes && typeCombinatorTypes[selectedSchema.index];

    let discriminatorObj = undefined;

    const discriminatorChildFieldsetDepth =
      selectedSchemaTypeCombinator &&
      !selectedSchemaTypeCombinator.ContainsSubTypes
        ? depth + 1
        : depth;

    const hasDiscriminatorWithContainsSubTypes =
      selectedSchemaTypeCombinator &&
      isDiscriminatorChild &&
      !selectedSchemaTypeCombinator.ContainsSubTypes;

    const childDepth = hasDiscriminatorWithContainsSubTypes
      ? depth + 2
      : depth + 1;

    const discriminatorClassName = classNames({
      field: true,
      'discriminator-field-child': hasDiscriminatorWithContainsSubTypes,
      [getEvenOddClass(discriminatorChildFieldsetDepth)]: isDiscriminatorChild,
      [`depth_${discriminatorChildFieldsetDepth}`]:
        hasDiscriminatorWithContainsSubTypes,
      ['discriminator-field-child-empty']: !isDiscriminatorChild,
      'even-bg': getEvenOdd(discriminatorChildFieldsetDepth),
      'odd-bg': !getEvenOdd(discriminatorChildFieldsetDepth),
    });

    let typeCombinatorSubTypes;

    if (typeCombinatorTypes) {
      const discriminatorValue =
        typeCombinatorTypes[selectedSchema.index].DiscriminatorValue;
      discriminatorObj = {
        [schema.discriminator]: discriminatorValue,
        ...(schema.parentDiscriminator && { ...schema.parentDiscriminator }),
      };

      typeCombinatorSubTypes = selectedSchemaTypeCombinator.ContainsSubTypes
        ? selectedSchemaTypeCombinator.SubTypes
        : null;
    }

    return (
      <fieldset className={prefixClass(discriminatorClassName)}>
        <React.Fragment>
          {this.state && formData ? (
            <_SchemaField
              schema={selectedSchema.schema}
              uiSchema={{
                ...uiSchema,
                'ui:title': isOneOfSchema(selectedSchema.schema)
                  ? undefined
                  : uiTitle,
              }}
              errorSchema={errorSchema}
              idPrefix={idPrefix}
              formData={formData.value}
              onChange={this.onDiscriminatorChange()}
              onBlur={onBlur}
              onFocus={onFocus}
              registry={registry}
              disabled={disabled}
              schemaIndex={selectedSchema.index}
              depth={childDepth}
              isEven={childDepth % 2 === 0}
              // Flag for detecting discriminator in child level
              fromDiscriminator={true}
              // Title will set in boolean fields
              anyOfTitle={this.props.schema.title || this.props.anyOfTitle}
              typeCombinatorTypes={typeCombinatorSubTypes}
              parentPath={getOneAnyOfPath(parentPath, formData)}
              discriminatorObj={discriminatorObj}
            />
          ) : (
            <p>schema not available</p>
          )}
        </React.Fragment>
      </fieldset>
    );
  };

  selectOnChange = (value, initialRender) => {
    const { onChange, parentPath, registry } = this.props;
    const { dxInterface } = registry;
    const { formState, selectedSchema } = this.state;
    // Don't do anything on same item click
    if (!initialRender && selectedSchema.index === value.index) {
      return;
    }

    this.setState({
      selectedSchema: value,
    });

    let defaultFormState = getDefaultFormState(
      value.schema,
      getInitialFormData(value.schema, value.index, this.state.caseOf),
      0,
      dxInterface
    );
    const path = getOneAnyOfPath(parentPath, defaultFormState);

    if (!formState[path]) {
      this.setState((st) => ({
        ...st,
        formState: {
          ...st.formState,
          [path]: defaultFormState,
        },
      }));
    }

    onChange(
      formState[path] || defaultFormState,
      {
        validate: true,
      },
      value.index
    );
  };

  toggleCollapse = () => {
    this.setState((prevState) => {
      return { ...prevState, collapse: !prevState.collapse };
    });
  };

  toggleCheckbox = () => {
    const { formData, schema, registry, onChange } = this.props;
    const { dxInterface } = registry;

    this.setState((st) => {
      const { checked } = st;
      const updatedChecked = !checked;

      const initialSchema = schema.oneOf || schema.anyOf;
      const initialSchemaIndex = formData ? formData.$$__case : 0;

      /**
       * Conditionally handling the formData here. To avoid crashing
       * the UI when formData is undefined / null or empty.
       *
       *
       * Also, we are using the formData prop instead
       * of the value from `getInitialFormData` function.
       * The reason is when the checkbox for the optional discriminator field was checked
       * then the default value was set which was empty
       * so instead of using the default value we are using a value from an example.
       * The example value is already set in the formData prop.
       */
      const data =
        !formData || Object.keys(formData).length === 0
          ? getInitialFormData(
              initialSchema[initialSchemaIndex],
              initialSchemaIndex,
              this.state.caseOf
            )
          : formData;

      const defaultFormState = getDefaultFormState(
        initialSchema[initialSchemaIndex],
        data,
        0,
        dxInterface
      );

      onChange(
        updatedChecked ? defaultFormState : null,
        {
          validate: true,
        },
        initialSchemaIndex
      );
      return { ...st, checked: updatedChecked };
    });
  };

  initializeFormData = () => {
    const {
      formData,
      disabled,
      registry: {
        dxInterface: { authTokenRenderProps },
      },
    } = this.props;

    if (!formData && (authTokenRenderProps || !disabled)) {
      const { selectOptions } = this.getSelectOptions();
      this.selectOnChange(selectOptions[0].value, true);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { formData } = this.props;
    if (prevProps.formData === undefined && formData !== undefined) {
      this.setState((prevState) => {
        return {
          ...prevState,
          checked: true,
        };
      });
    }
    if (this.state.checked || !this.state.optional) {
      this.initializeFormData();
    }
  };

  componentDidMount = () => {
    if (this.state.checked || !this.state.optional) {
      this.initializeFormData();
    }
  };

  getSelectOptions = () => {
    const { schema, typeCombinatorTypesFromProps, formData, registry } =
      this.props;
    const { dxInterface } = registry;

    const { linkMapper } = dxInterface;

    const { typeCombinatorTypes = typeCombinatorTypesFromProps } = schema;
    const discriminator = schema.discriminator;

    const multipleSchema = schema.oneOf || schema.anyOf;
    const prevParentDiscriminator = schema.parentDiscriminator;

    return multipleSchema.reduce(
      ({ selectOptions, charCounts }, schema, index) => {
        if (
          typeCombinatorTypes &&
          typeCombinatorTypes[index].ContainsSubTypes &&
          typeCombinatorTypes[index].SubDiscriminator
        ) {
          const discriminatorValue =
            typeCombinatorTypes[index].DiscriminatorValue;
          const parentDiscriminator = {
            [discriminator]: discriminatorValue,
            ...(prevParentDiscriminator && {
              ...prevParentDiscriminator,
            }),
          };
          schema = {
            ...schema,
            discriminator: typeCombinatorTypes[index].SubDiscriminator,
            parentDiscriminator: parentDiscriminator,
            discriminatorValue: typeCombinatorTypes[index].DiscriminatorValue,
            typeCombinatorTypes: typeCombinatorTypes[index].SubTypes,
            ContainsSubTypes: typeCombinatorTypes[index].ContainsSubTypes,
          };
        } else if (Object.prototype.hasOwnProperty.call(schema, '$ref')) {
          schema = retrieveSchema(schema, formData, dxInterface);
        }
        if (
          schema.additionalProperties &&
          Object.prototype.hasOwnProperty.call(
            schema.additionalProperties,
            '$ref'
          )
        ) {
          schema.additionalProperties = retrieveSchema(
            schema.additionalProperties,
            formData,
            dxInterface
          );
        }

        const type = typeCombinatorTypes && typeCombinatorTypes[index].DataType;

        const linkTo = typeCombinatorTypes && typeCombinatorTypes[index].LinkTo;

        const label = type
          ? type
          : getMultipleLabel(schema) || schema.title || schema.type || '';

        selectOptions.push({
          label,
          value: {
            index: index,
            schema: schema,
            linkTo: linkTo ? linkMapper(linkTo) : null,
          },
        });

        return { selectOptions, charCounts: charCounts + label.length };
      },
      { selectOptions: [], charCounts: 0 }
    );
  };

  render() {
    const {
      schema,
      depth,
      fieldProps,
      fromDiscriminator,
      disabled,
      tagsTitle,
      registry,
    } = this.props;
    const { dxInterface } = registry;
    const { renderToolTip, trackEvent, portalSettings } = dxInterface;

    const { selectedSchema, checked, optional, collapse } = this.state;

    const { selectOptions, charCounts } = this.getSelectOptions();

    const tagSelectorTitle = dxInterface?.authTokenRenderProps
      ? null
      : tagsTitle || getMultipleLabel(schema);

    const isOneOfOrAnyOf =
      Object.prototype.hasOwnProperty.call(selectedSchema.schema, 'oneOf') ||
      Object.prototype.hasOwnProperty.call(selectedSchema.schema, 'anyOf');
    const isObject =
      selectedSchema.schema.type === 'array' ||
      selectedSchema.schema.type === 'object' ||
      isOneOfOrAnyOf;
    const tagSelectorClassName = classNames({
      'anyof-child': isObject && isOneOfOrAnyOf,
      'object-child': !isObject || (isObject && !isOneOfOrAnyOf),
      'select-container': charCounts > CHAR_THRESHOLD,
    });
    const fieldSetClassNames = classNames({
      field: true,
      [getEvenOddClass(depth)]: true,
      [`depth_${depth}`]: true,
      'discriminator-field': true,
      'even-bg': getEvenOdd(depth),
      'odd-bg': !getEvenOdd(depth),
    });

    return (
      <DefaultTemplate
        {...fieldProps}
        nullify={checked}
        required={!optional}
        onNullifyChange={this.toggleCheckbox}
        fromDiscriminator={fromDiscriminator}
        onCollapseClick={this.toggleCollapse}
        isCollapsed={this.state.collapse}
      >
        {!collapse ? (
          <fieldset className={prefixClass(fieldSetClassNames)}>
            <TagSelector
              className={tagSelectorClassName}
              value={selectedSchema}
              title={tagSelectorTitle}
              options={selectOptions}
              onChange={this.selectOnChange}
              renderToolTip={renderToolTip}
              disabled={disabled || !checked}
              trackEvent={trackEvent}
              portalSettings={portalSettings}
            />
            {this.renderSchema(depth)}
          </fieldset>
        ) : null}
      </DefaultTemplate>
    );
  }
}

/* istanbul ignore else */
DiscriminatorField.defaultProps = {
  disabled: false,
  errorSchema: {},
  uiSchema: {},
};

if (process.env.NODE_ENV !== 'production') {
  DiscriminatorField.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    baseType: PropTypes.string,
    uiSchema: PropTypes.object,
    formData: PropTypes.any,
    errorSchema: PropTypes.object,
    dxInterface: PropTypes.shape({
      registry: PropTypes.shape({
        widgets: PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        ).isRequired,
        fields: PropTypes.objectOf(PropTypes.func).isRequired,
        definitions: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired,
      }),
    }),
  };
}

export default DiscriminatorField;

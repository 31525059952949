import { NavItem, Table, Section } from './DxDom';

/**
 * Finds and returns the link of the parent nav item or undefined if not found
 * @param navLink Link to find
 * @param nav Nav
 */
export function findNavParentLink(
  navLink: string,
  nav: ReadonlyArray<NavItem>
): string | undefined {
  return nav
    .map((n) => findSubNavParentLink(navLink, n))
    .find((s) => s !== undefined);
}

function findSubNavParentLink(
  navLink: string,
  nav: NavItem
): string | undefined {
  return nav.SubItems.find((s) => s.Link === navLink)
    ? nav.Link
    : findNavParentLink(navLink, nav.SubItems);
}

export function flatMap<T>(f: (x: T) => Array<T>, xs: ReadonlyArray<T>) {
  return xs.reduce((acc, x) => acc.concat(f(x)), [] as Array<T>);
}

export function getFlattenedLinksSimple(
  navItems: ReadonlyArray<NavItem>
): NavItem[] {
  return flatMap(
    (n) =>
      [n].concat(getFlattenedLinksSimple(n.SubItems.filter((s) => !s.Skip))),
    navItems
  );
}

export function filterExternalLinks(
  navItems: ReadonlyArray<NavItem>
): NavItem[] {
  return navItems.filter((n) => !n.IsExternal);
}

export function getFlattenedSections(
  sections: ReadonlyArray<Section>
): Section[] {
  return flatMap(
    (n) =>
      getFlattenedSections(
        n.Nodes.filter(
          (f) => f.Type === 'section' && f.HideFromNavigation === false
        ) as ReadonlyArray<Section>
      ).concat([n]),
    sections
  );
}

export function withoutEmptyColumnsTable(table: Table): Table {
  const emptyColumns: boolean[] = [];
  const headerRowData: string[] = [];
  const rows = table.Rows.map((r) => [] as string[]);
  const className = table.Class;

  for (let i = 0; i < table.Header.Data.length; i++) {
    emptyColumns[i] = table.Rows.every((r) => i >= r.Data.length || !r.Data[i]);
    if (emptyColumns[i]) {
      continue;
    }
    headerRowData.push(table.Header.Data[i]);
    for (let jIndex = table.Rows.length - 1; jIndex >= 0; jIndex--) {
      rows[jIndex].push(table.Rows[jIndex].Data[i]);
    }
  }

  return {
    Type: 'table',
    Header: {
      Data: headerRowData,
    },
    Rows: rows.map((r) => ({
      Data: r,
    })),
    Class: className,
  };
}

/* eslint-disable camelcase */
/**
 * This file defines the DX Document structure based on the specs here:
 * https://gist.github.com/thehappybug/fb926018b3886a79fd2d87e4cffc6129
 *
 * Note that the DX Document Structure (or DxDOM) is designed to be like
 * an immutable AST for defining a document.
 */

import { JSONSchema6 } from 'json-schema';

export type JSchema = JSONSchema6 & { isSecret?: boolean };

export type SimpleJsonType = string | number | boolean | object | null;

export interface ResponseHeaders {
  apiDigest: string;
}

export interface CallModel {
  readonly additionalQueryParams?: { readonly [key: string]: SimpleJsonType };
  readonly additionalFieldParams?: { readonly [key: string]: SimpleJsonType };
  readonly args?: { readonly [key: string]: object };
}

export interface AuthTokenDef {
  access_token?: string;
  refresh_token?: string;
  token_type?: string;
  expires_in?: number;
}

export interface ApiGroupModel {
  $$__case: number;
  $$__case_of: string;
  value: SimpleJsonType;
}

export interface DataModel {
  readonly showFullCode: boolean;
  readonly config: { readonly [key: string]: SimpleJsonType };
  readonly auth?: {
    [key: string]: SimpleJsonType | AuthTokenDef;
  } | null;
  readonly environment: string;
  readonly skipSslCertVerification?: boolean;
  readonly call?: CallModel;
}

export type AllSearchSections =
  | 'All'
  | 'Models'
  | 'API Endpoints'
  | 'Documentation';

export type MainSearchSections = Omit<AllSearchSections, 'All'>;

export interface NavItem {
  readonly Text?: string;
  readonly Link: string;
  readonly InSection?: MainSearchSections;
  readonly Skip: boolean;
  readonly Label?: string;
  readonly SubItems: ReadonlyArray<NavItem>;
  readonly IsExternal?: boolean;
}

export interface Linkable {
  readonly SuggestedLinkText?: string;
  readonly SuggestedLink?: string;
  readonly SuggestedLinkLevel?: number;
  readonly LinkLabel?: string;
  readonly HideFromNavigation?: boolean;
}

export enum Language {
  CsStandard = 'CS_NET_STANDARD_LIB',
  CsPortable = 'CS_PORTABLE_NET_LIB',
  CsUWP = 'CS_UNIVERSAL_WINDOWS_PLATFORM_LIB',
  Android = 'JAVA_GRADLE_ANDROID_LIB',
  ObjectiveC = 'OBJC_COCOA_TOUCH_IOS_LIB',
  Java = 'JAVA_ECLIPSE_JRE_LIB',
  JaxRs = 'JAVA_ECLIPSE_JAX_RS',
  Php = 'PHP_GENERIC_LIB',
  Python = 'PYTHON_GENERIC_LIB',
  Ruby = 'RUBY_GENERIC_LIB',
  AngularJs = 'ANGULAR_JAVASCRIPT_LIB',
  JavaScript = 'TS_GENERIC_LIB',
  Golang = 'GO_GENERIC_LIB',
  HttpCurl = 'HTTP_CURL_V1',
}

export enum CalloutType {
  Info = 0,
  Warning = 1,
  Danger = 2,
}

export interface ParamInfo {
  readonly Name: string;
  readonly GenericName?: string;
  readonly DataType: string;
  readonly DataTypeMarkdown: null | string;
  readonly Description?: string;
  readonly Required: boolean;
  readonly LinkTo?: string;
  readonly Constant: boolean;
  readonly ParamType?: string;
  readonly TypeCombinatorTypes?: SimpleJsonType;
  readonly ReadOnly?: boolean;
  readonly WriteOnly?: boolean;
  // Type: 'paraminfo';
  readonly Discriminator?: string;
  readonly DiscriminatorValue?: string;
  readonly ContainerKind?: string;
  readonly ContainerName?: string;
  readonly DisableJsonView?: boolean;
  readonly AdditionalDescription?: string;
}

export interface ContainerType {
  readonly DataType: string;
  readonly LinkTo: string;
  readonly Method: string;
  readonly Type: string;
}

export interface ErrorInfo {
  readonly Name: string;
  readonly StatusCode: string;
  readonly Description: string;
  readonly LinkTo?: string;
  // Type: 'errorinfo';
}

// TODO: Remove containerreference support in future.

export interface ModelReference extends Linkable {
  readonly Title: string;
  readonly Name: string;
  readonly OriginalName?: string;
  readonly Description?: string;
  readonly BaseClass?: string;
  readonly BaseClassLink?: string;
  readonly StructureType?: string;
  readonly Example?: CodeBlock;
  readonly ContainedTypesName?: string;
  readonly Type:
    | 'enumreference'
    | 'structurereference'
    | 'containerreference'
    | 'typecombinatorcontainerreference';
  readonly ChildClassesLink?: string;
  readonly Index?: number;
  readonly LinkTo?: string;
}

export interface Step {
  readonly Title?: string;
  readonly Nodes: ReadonlyArray<SectionNode>;
  SuggestedLinkLevel?: number;
  // Type: 'step';
}

export interface TableRow {
  readonly Data: ReadonlyArray<string>;
  // Type: 'tablerow';
}

/**
 * DX Document root node
 */
export interface Document {
  readonly headers: Headers;
  readonly Title?: string;
  readonly WhiteLabel: boolean;
  readonly Sections: ReadonlyArray<Section>;
  readonly NavItems: ReadonlyArray<NavItem>;
  readonly DataModel: DataModel;
  readonly DataModelSchema: JSchema;
  readonly PartialTemplates: { [key: string]: string };
  readonly PublishedPackage?: PublishedPackage;
  readonly AuthHttpCallTemplate?: string | AuthHttpCallTemplateDictonary;
  readonly ApiAuthentication?: OAuth2CodeSettings | OAuth2CodeSettingsDictonary;
  readonly KnownLinkPrefixes?: ReadonlyArray<string>;
  readonly Type: 'document';
  readonly ApiDigest: string;
  readonly ResponseHeaders: ResponseHeaders;
  readonly ModelSchemas: JSchema;
  readonly Structures: ReferenceType;
  readonly Version?: string;
}
/*
 * TODO: Add types for maintaining backwords Compatibility.
 * OAuth2CodeSettings Will be removed in feture.
 * OAuth2CodeSettingsDictonary is the new implementation.
 */
export interface OAuth2CodeSettings {
  readonly AuthorizationUrlTemplate: string;
  readonly AccessTokenUrlTemplate: string;
}

export type AuthHttpCallTemplateDictonary = { [key: string]: string };

export type OAuth2CodeSettingsDictonary = { [key: string]: OAuth2CodeSettings };

export interface PublishedPackage {
  readonly Provider?: string;
  readonly Name?: string;
  readonly Version?: string;
  readonly Link?: string;
  readonly SourceLink?: string;
  readonly InstallCommand?: string;
  readonly InstallHeadingLink?: string;
}

export interface Section extends Linkable {
  readonly Text?: string;
  readonly Steps?: ReadonlyArray<SectionNode>;
  readonly Title?: string;
  readonly Nodes: ReadonlyArray<SectionNode>;
  readonly PlaceholderId?: string;
  readonly Type: 'section';
  readonly hasStepByStepPage?: boolean;
}

export interface Callout {
  readonly Title?: string;
  readonly Body: string;
  readonly CalloutType: CalloutType;
  readonly Type: 'callout';
}

export interface CodeBlock {
  readonly Text: string;
  readonly Language?: string;
  readonly Type: 'codeblock';
  readonly From?: string;
}

export interface Example {
  Name: string;
  Value: SimpleJsonType;
}
export interface Examples {
  readonly [key: string]: Example;
}

export interface RequiredAuthScopes {
  [key: string]: string[];
}

export interface CompilableCodeBlock {
  readonly EndpointGroupName: string;
  readonly EndpointName: string;
  readonly Index: number;
  readonly CallModel: CallModel;
  readonly CallModelSchema: JSchema;
  readonly Examples?: Examples;
  readonly Templates: { readonly [language in Language]?: string };
  readonly HttpCallTemplate: string;
  readonly Type: 'compilablecodeblock';
  readonly Parameters?: ReadonlyArray<ParamInfo>;
  readonly isExpandable?: boolean;
  readonly Globals?: { [key: string]: any };
}

export interface EndpointReference extends Linkable {
  readonly Title: string;
  readonly Name?: string;
  readonly Description?: string;
  readonly ReturnType: string;
  readonly ReturnTypeLink: string;
  readonly ReturnTypeExample?: CodeBlock;
  readonly ResponseText?: string;
  readonly RequiresAuthentication: boolean;
  readonly MethodSignature: CodeBlock;
  readonly Parameters?: ReadonlyArray<ParamInfo>;
  readonly Scopes?: ReadonlyArray<string>;
  readonly Errors?: ReadonlyArray<ErrorInfo>;
  readonly StaticUsageExample?: CodeBlock;
  readonly UsageExample: CompilableCodeBlock;
  readonly ServerName?: string;
  readonly Type: 'endpointreference';
  readonly Response?: ReadonlyArray<ResponseInfo>;
  readonly hasStepByStepPage?: boolean;
  readonly PlaygroundTitle?: string;
  readonly AuthDescription?: string;
  readonly AuthModelSchema?: JSchema;
  readonly RequiredAuths?: Array<Array<string>>;
  readonly RequiredAuthScopes?: RequiredAuthScopes;
}
export interface ResponseInfo {
  readonly StatusCode: string;
  readonly Name?: string;
  readonly Description?: string;
  readonly Headers: ReadonlyArray<ResponseHeaderInfo>;
  readonly Content: ReadonlyArray<ResponseContentInfo>;
  readonly Type: 'responseinfo';
}

export interface ResponseHeaderInfo {
  readonly Name: string;
  readonly DataType: string;
  readonly Description?: string;
  readonly Required: boolean;
  readonly LinkTo?: string;
  readonly Constant: boolean;
  readonly Example?: string | null;
  Type: 'responseheaderinfo';
}

export interface ResponseContentInfo {
  readonly ContentType: string;
  readonly DataType: string;
  readonly LinkTo?: string;
  readonly Example?: CodeBlock;
  // tslint:disable-next-line:no-any
  readonly Examples: Array<ExampleInfo>;
  Type: 'responsecontentinfo';
}

export interface ExampleInfo {
  Id: string;
  Name: string;
  Description: string | null;
  ExternalValue: string | null;
  Text: string;
  Language: string;
  Type: 'examplecodeblock';
}

export interface EnumReference extends ModelReference {
  readonly Elements: ReadonlyArray<{
    readonly Key: string;
    readonly Value: string;
  }>;
  readonly Type: 'enumreference';
}

export interface StructureReference extends ModelReference {
  readonly Fields: ReadonlyArray<ParamInfo>;
  readonly Type: 'structurereference';
}

export interface ContainerReference extends ModelReference {
  readonly ContainerTypes: ReadonlyArray<ContainerType>;
  readonly Name: string;
  readonly StructureType: string;
  readonly Title: string;
  readonly Type: 'containerreference';
}

export interface CasesTypes {
  Name: string;
  DataType: string;
  LinkTo: string;
  DiscriminatorValue?: string;
  Description: string;
  MethodSignature?: string;
  InitializationExamples: Record<string, string>;
  Type: string;
}

export interface TypeCombinatorContainer extends ModelReference {
  readonly StructureType: string;
  readonly DataType: string;
  readonly Title: string;
  readonly Cases: ReadonlyArray<CasesTypes>;
  readonly UtilizationCode?: string;
  readonly Examples?: Record<string, string>;
  readonly DiscriminatorField?: string;
  readonly Type: 'typecombinatorcontainerreference';
}

export interface Heading extends Linkable {
  readonly Text?: string;
  readonly Level: number;
  readonly Type: 'heading';
}

export interface Image {
  readonly Url: string;
  readonly AlternateText?: string;
  readonly Type: 'image';
}

export interface Link {
  readonly Url: string;
  readonly Type: 'link';
}

export interface Paragraph {
  readonly Text?: string;
  readonly Type: 'paragraph';
}

export interface SteppedGuide {
  readonly Steps: ReadonlyArray<Step>;
  readonly Type: 'steppedguide';
}

export interface Table {
  readonly Header: TableRow;
  readonly Rows: ReadonlyArray<TableRow>;
  readonly Type: 'table' | 'enumtable';
  readonly Class?: 'enum-table-data';
}

/**
 * Section node types
 *
 * These are discriminated by Type field.
 */

export type SectionNode =
  | Callout
  | CodeBlock
  | EndpointReference
  | CompilableCodeBlock
  | EnumReference
  | StructureReference
  | Heading
  | Image
  | Link
  | Paragraph
  | SteppedGuide
  | Table
  | Section
  | ContainerReference
  | TypeCombinatorContainer;

export type ReferenceType = (
  | StructureReference
  | EnumReference
  | ContainerReference
)[];

// These utilities allow processing section nodes exhaustively

// Ref: https://stackoverflow.com/questions/48750647/get-type-of-union-by-discriminant
type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<
  K,
  V
>
  ? T
  : never;

/**
 * A section node handler
 */
export type NodeHandler<R> = {
  readonly [T in SectionNode['Type']]: (props: {
    node: DiscriminateUnion<SectionNode, 'Type', T>;
  }) => R;
};

/**
 * Calls the correct handling method for a section node
 * @param node Any section node
 * @param handler A section node handler
 */
export function callNodeHandler<R>(
  node: SectionNode,
  handler: NodeHandler<R>
): R {
  // TODO: Not working in the latest typescript and react 18. Need to resolve types
  //@ts-ignore
  return handler[node.Type].call(handler, { node: node });
}

/**
 * Mapping from DxDom language type to Markdown language identifier
 */
export const LanguageMap: { [k in Language]: string } = {
  ANGULAR_JAVASCRIPT_LIB: 'js',
  TS_GENERIC_LIB: 'typescript',
  CS_NET_STANDARD_LIB: 'csharp',
  CS_UNIVERSAL_WINDOWS_PLATFORM_LIB: 'csharp',
  CS_PORTABLE_NET_LIB: 'csharp',
  JAVA_ECLIPSE_JRE_LIB: 'java',
  JAVA_GRADLE_ANDROID_LIB: 'java',
  JAVA_ECLIPSE_JAX_RS: 'java',
  OBJC_COCOA_TOUCH_IOS_LIB: 'objectivec',
  PHP_GENERIC_LIB: 'php',
  PYTHON_GENERIC_LIB: 'python',
  RUBY_GENERIC_LIB: 'ruby',
  GO_GENERIC_LIB: 'go',
  HTTP_CURL_V1: 'bash',
};

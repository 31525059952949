/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const SuccessIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '12'}
    height={props.height || '12'}
    viewBox={props.height || '0 0 12 12'}
  >
    <g transform="translate(-2.089 -1.994)">
      <g
        transform="translate(2.089 1.994)"
        fill="none"
        stroke="#50cba7"
        strokeWidth="1.5"
      >
        <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
        <circle cx="6.5" cy="6.5" r="5.75" fill="none" />
      </g>
      <path
        d="M13.593,3.283,9.785,6.659,8.579,5.072"
        transform="translate(-2.497 3.523)"
        fill="none"
        stroke="#50cba7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SVGIcon>
);

/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const Error406LightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <g>
      <g>
        <path
          fill="#F6F6F9"
          stroke="#9CAAB8"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M51.4,38.6v43.9c0,1.2-0.9,2.1-2.1,2.1H19.1
			c-1.2,0-2.1-0.9-2.1-2.1V48l11.5-11.5h20.8C50.5,36.6,51.4,37.5,51.4,38.6z"
        />
        <path
          fill="#F6F6F9"
          stroke="#9CAAB8"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M48.7,36.2v43.9c0,1.2-0.9,2.1-2.1,2.1H16.4
			c-1.2,0-2.1-0.9-2.1-2.1V45.5l11.5-11.5h20.8C47.8,34.1,48.7,35,48.7,36.2z"
        />
        <path
          fill="#F6F6F9"
          stroke="#9CAAB8"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M14.5,45.4l11.1-11.1c0.1-0.1,0.2,0,0.2,0.1
			l0,11.1c0,0.1,0,0.1-0.1,0.1H14.6C14.5,45.5,14.5,45.4,14.5,45.4z"
        />
        <rect
          x="17.9"
          y="52.5"
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          width="27.3"
          height="2.5"
        />
        <rect
          x="17.9"
          y="58.4"
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          width="27.3"
          height="2.5"
        />
        <rect
          x="17.9"
          y="64.3"
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          width="27.3"
          height="2.5"
        />
        <rect
          x="17.9"
          y="70.2"
          fill="#606774"
          stroke="#3E445D"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          width="27.3"
          height="2.5"
        />
      </g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M104.1,39.7v15.1c0,1.3-1.1,2.3-2.3,2.3H56.7
		c-1.3,0-2.3-1.1-2.3-2.3V39.7c0-2.3,1.9-4.2,4.2-4.2h41.4C102.2,35.5,104.1,37.4,104.1,39.7z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M104.1,60.6v16.9c0,1.3-1.1,2.3-2.3,2.3H56.7
		c-1.3,0-2.3-1.1-2.3-2.3V60.6c0-1.3,1.1-2.3,2.3-2.3h45.1C103.1,58.2,104.1,59.3,104.1,60.6z"
      />
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M104.1,83.3v15.1c0,2.3-1.9,4.2-4.2,4.2H58.5
		c-2.3,0-4.2-1.9-4.2-4.2V83.3c0-1.3,1.1-2.3,2.3-2.3h45.1C103.1,80.9,104.1,82,104.1,83.3z"
      />
      <path
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M87.1,45.7h-0.7c-0.7,0-1.2-0.5-1.2-1.2v-0.7
		c0-0.7,0.5-1.2,1.2-1.2h0.7c0.7,0,1.2,0.5,1.2,1.2v0.7C88.3,45.1,87.7,45.7,87.1,45.7z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M92.3,45.7h-0.7c-0.7,0-1.2-0.5-1.2-1.2v-0.7
		c0-0.7,0.5-1.2,1.2-1.2h0.7c0.7,0,1.2,0.5,1.2,1.2v0.7C93.4,45.1,92.9,45.7,92.3,45.7z"
      />
      <path
        fill="#9199A5"
        stroke="#49505A"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M97.1,45.7h-0.7c-0.7,0-1.2-0.5-1.2-1.2v-0.7
		c0-0.7,0.5-1.2,1.2-1.2h0.7c0.7,0,1.2,0.5,1.2,1.2v0.7C98.3,45.1,97.8,45.7,97.1,45.7z"
      />
      <circle
        fill="#FF4451"
        stroke="#BA1E45"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="52.2"
        cy="57.7"
        r="15.3"
      />
      <circle
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        cx="52.2"
        cy="57.7"
        r="11.2"
      />
      <g>
        <path
          fill="#FF4451"
          stroke="#BA1E45"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M54.1,62.6l-2.5-3.5l-2.4,3.5
			c0,0-0.1,0.1-0.1,0.1h-2.3c-0.1,0-0.2-0.2-0.1-0.3l3.7-5l-3.5-4.9c-0.1-0.1,0-0.3,0.1-0.3h2.3c0.1,0,0.1,0,0.1,0.1l2.3,3.3
			l2.3-3.3c0,0,0.1-0.1,0.1-0.1h2.2c0.1,0,0.2,0.2,0.1,0.3l-3.4,4.8l3.7,5.1c0.1,0.1,0,0.3-0.1,0.3h-2.4
			C54.2,62.7,54.2,62.7,54.1,62.6z"
        />
      </g>
    </g>
  </SVGIcon>
);

import { createContext } from 'react';
import { DataModel } from 'src/DxDom';
import { WorkflowContextType, WorkflowState } from './WorkflowContext';
import { DataModelContextProps } from 'src/DataModelContext';

export type Events = {
  readyArgsInstance: ReadyArgs;
};

export type SetConfig = (
  callback: (updatedConfig?: DataModel) => DataModel
) => Promise<void>;

export type RegisterWorkflow = (
  key: string,
  title: string,
  callback: (
    workflowCtx: {
      showContent: WorkflowContextType['showContent'];
      showEndpoint: ReturnType<WorkflowContextType['getShowEndpoint']>;
    },
    portal: {
      setConfig: SetConfig;
    }
  ) => WorkflowState[string]
) => void;

export type SetDataModelState = (model: DataModel) => void;

export type DataModelRef = {
  dataModelContext?: DataModelContextProps;
};

export type WorkflowStateType = {
  workflowSteps?: WorkflowState[string];
  selectedWorkflowStep?: string;
};

export type WorkflowStateRef = {
  workflowState?: WorkflowStateType;
};

export type FireReadyArgs = {
  dataModelRef: React.MutableRefObject<DataModelRef>;
  setWorkflowState: WorkflowContextType['setWorkflowState'];
  showContent: WorkflowContextType['showContent'];
  showEndpoint: ReturnType<WorkflowContextType['getShowEndpoint']>;
  setTitles: WorkflowContextType['setTitles'];
  workflowStateRef?: React.MutableRefObject<WorkflowStateRef>;
};
export type FireReady = (args: FireReadyArgs) => void;

export type FireReadyCallback = (args: {
  setConfig: SetConfig;
  registerWorkflow: RegisterWorkflow;
}) => void;

export class ReadyArgs {
  private args: FireReadyArgs | undefined;
  set = (value: FireReadyArgs) => {
    this.args = value;
  };

  get = () => {
    return this.args;
  };
}

export const EventContext = createContext<Events>({
  readyArgsInstance: new ReadyArgs(),
});

export const EventContextProvider = EventContext.Provider;

export const EventContextConsumer = EventContext.Consumer;

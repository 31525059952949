import { BrowserRouter, HashRouter, MemoryRouter } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ErrorEvent } from '@sentry/browser';

import { AppLayout } from './AppLayout';
import {
  DataModelContextConsumer,
  DataModelContextProvider,
} from './DataModelContext';
import { DocLoader } from './DocLoader';
import { Document as DxDocument } from './DxDom';
import { RSectionDocument } from './DxDomRender';
import { HotKeys } from './HotKeys';
import { LinkMapperContext, LinkMapper } from './LinkMapperContext';
import { Sidebar } from './Sidebar/Sidebar';
import { PortalContextProvider } from './PortalContext';
import { Language, PortalSettings } from './PortalSettings';
import { RouteToLanguage, RouteToSection } from './Routes';
import { RouteToScrollSync, ScrollContainer } from './Scroll';

import { ScrollableMixin } from './StyledElements';
import { ErrorOverlay, LoadingOverlay } from './Overlays';
import { DocumentContextProvider } from './DocumentContext';
import { ResponseHeaderContextProvider } from './Context/ResponseHeaderContext';
import {
  DataModelRef,
  EventContextConsumer,
  EventContextProvider,
  Events,
  WorkflowStateRef,
} from './Context/EventsContext';
import {
  getSteps,
  Workflow,
  WorkflowContextConsumer,
  WorkflowContextType,
  WorkflowState,
} from './Context/WorkflowContext';
import { staticColors } from './Color';
import WorkflowContent from './WorkflowContent';
import { useRef } from 'react';
import { guidedWalkThroughEvents } from './Analytics';
import { OAuthContextProvider } from './Authorization/OAuthContext';
import { RoutedPlatform } from './Platform/RoutedPlatform';
import { TOCContextConsumer, TOCProvider } from './TableOfContent/TOCContext';
import { TOCProps } from './TableOfContent/TableOfContentComponent';
import { LoadingScreen } from './base';
import { MainLoadingIcon } from './Icons';

/**
 * Main application component
 * @param props
 */
export function App({
  settings,
  events,
}: {
  settings: PortalSettings;
  events: Events;
}) {
  const theme = {
    primaryColor: settings.themeOverrides.palette.primaryColor,
    secondaryColor: settings.themeOverrides.palette?.secondaryColor,
    linkColor: settings.themeOverrides.palette.linkColor,
    colors: settings.themeOverrides.palette.colors,
    cssStyles: settings.themeOverrides.cssStyles,
    staticColors: staticColors,
  };
  const Router = getRouter(settings);

  return (
    <SentryErrorBoundary
      fallback={
        <ThemeProvider theme={theme}>
          <ErrorOverlay statusCode={900} />
        </ThemeProvider>
      }
      beforeCapture={(scope, error) => {
        const errorEvent = error as ErrorEvent;
        const WALKTHROUGH_FALLBACK_ERROR = 'Guided Walkthrough Fallback Error';
        const { workflowName, selectedStepName, stepType } = error as {
          workflowName?: string;
          selectedStepName?: string;
          stepType?: string;
        };
        if (workflowName) {
          guidedWalkThroughEvents(
            WALKTHROUGH_FALLBACK_ERROR,
            settings,
            workflowName,
            selectedStepName,
            stepType,
            undefined,
            errorEvent?.message,
          );
        }

        scope.setTag('apimaticAppReferance', 'Main Error Boundary');
      }}
    >
      <PortalContextProvider value={settings}>
        <EventContextProvider value={events}>
          <ThemeProvider theme={theme}>
            <Router>
              <Workflow>
                <RouteToLanguage
                  settings={settings}
                  render={(lang) => (
                    <TOCProvider>
                      <Portal lang={lang} portalSettings={settings} />
                    </TOCProvider>
                  )}
                />
              </Workflow>
            </Router>
          </ThemeProvider>
        </EventContextProvider>
      </PortalContextProvider>
    </SentryErrorBoundary>
  );
}

/**
 * Renders portal for showing DX documents
 * @param props Props
 */
function Portal(props: { lang: Language; portalSettings: PortalSettings }) {
  const embedded = props.portalSettings.portalStyle === 'embedded';
  const docRef = useRef<DxDocument>();
  const dataModelRef = useRef<DataModelRef>({});
  const workflowStateRef = useRef<WorkflowStateRef>({});
  const ScrollContainerRef = useRef<HTMLDivElement>(null);
  const hasCodeSampleRef = useRef<boolean>(false);

  const hasCodeSamplesBox = (hasCodeBox: boolean) => {
    hasCodeSampleRef.current = hasCodeBox;
  };

  return (
    <EventContextConsumer>
      {(events) => (
        <WorkflowContextConsumer>
          {({
            setWorkflowState,
            showContent,
            getShowEndpoint,
            onStepClick,
            getSelectedWorkflow,
            setVerified,
            setTitles,
            titles,
          }) => {
            return (
              //  DocLoader Loads and gets the SDK from API
              <DocLoader
                readyArgsInstance={events.readyArgsInstance}
                tpl={props.lang}
                portalSettings={props.portalSettings}
              >
                {(
                  {
                    doc,
                    linkMapper,
                    loading,
                    isError,
                    statusCode,
                    liquidInstance,
                  },
                  refresh,
                ) => {
                  const { workflowName, workflowSteps, selectedWorkflowStep } =
                    getSelectedWorkflow(linkMapper);

                  workflowStateRef.current.workflowState = {
                    workflowSteps,
                    selectedWorkflowStep,
                  };
                  docRef.current = doc;
                  // this is true when doc is loaded or during navigation from a prev doc
                  return doc && linkMapper ? (
                    <DocumentContextProvider
                      value={{ ...doc, lang: props.lang }}
                    >
                      {/* Context provider for Data Model needed by API console */}
                      <DataModelContextProvider
                        doc={doc}
                        liquidInstance={liquidInstance}
                      >
                        {/* Context provider for link mapper (translates DX links to portal links) */}
                        <LinkMapperContext.Provider value={linkMapper}>
                          {/* Context provider for docsGen API response header */}
                          <ResponseHeaderContextProvider
                            responseHeaders={doc.ResponseHeaders}
                          >
                            {/* OAuth Context */}
                            <OAuthContextProvider>
                              {/* Handles keyboard navigation for docs */}
                              <HotKeys doc={doc} linkMapper={linkMapper}>
                                {/* Helps create the familiar 2/3 column layout */}
                                <TOCContextConsumer>
                                  {(value) => {
                                    return (
                                      <AppLayout
                                        language={props.lang}
                                        portalSettings={props.portalSettings}
                                        TOCContent={value.tocProps}
                                        ScrollContainerRef={ScrollContainerRef}
                                        hasCodeSamplesBox={
                                          hasCodeSampleRef.current
                                        }
                                        // The header
                                        // Sidebar contains Navbar, Search, and Menu
                                        // The document navigation menu on left (or top in embedded)
                                        sidebar={
                                          <DataModelContextConsumer>
                                            {(value) => {
                                              dataModelRef.current.dataModelContext =
                                                value;

                                              return (
                                                <Sidebar
                                                  lang={props.lang}
                                                  linkMapper={linkMapper}
                                                  packageInfo={
                                                    doc.PublishedPackage
                                                  }
                                                  nav={doc.NavItems}
                                                  horizontal={embedded}
                                                  disabled={loading}
                                                  whiteLabel={doc.WhiteLabel}
                                                  readyArgs={{
                                                    dataModelRef: dataModelRef,
                                                    setWorkflowState:
                                                      setWorkflowState,
                                                    showContent,
                                                    setTitles,
                                                    showEndpoint:
                                                      getShowEndpoint(
                                                        docRef as React.MutableRefObject<DxDocument>,
                                                        hasCodeSamplesBox,
                                                      ),
                                                    workflowStateRef:
                                                      workflowStateRef,
                                                  }}
                                                  workflowName={workflowName}
                                                  workflowSteps={workflowSteps}
                                                  workflowPageTitle={
                                                    titles[workflowName || '']
                                                  }
                                                  onStepClick={onStepClick}
                                                  readyArgsInstance={
                                                    events.readyArgsInstance
                                                  }
                                                />
                                              );
                                            }}
                                          </DataModelContextConsumer>
                                        }
                                        languageSelector={
                                          <RoutedPlatform
                                            disabled={loading}
                                            currentTemplate={props.lang}
                                            linkMapper={linkMapper}
                                            packageInfo={doc.PublishedPackage}
                                          />
                                        }
                                        // The main content
                                        main={
                                          loading ? (
                                            // Had to disable main content during loading because
                                            // the route changes for loading next document and main
                                            // content only renders routes that are available in
                                            // currently loaded document
                                            // TODO Improve this
                                            <LoadingScreen>
                                              <MainLoadingIcon />
                                            </LoadingScreen>
                                          ) : (
                                            <MainContent
                                              lang={props.lang}
                                              doc={doc}
                                              linkMapper={linkMapper}
                                              workflowSteps={workflowSteps}
                                              setVerified={setVerified}
                                              workflowName={workflowName}
                                              updateTOCProps={
                                                value.updateTOCProps
                                              }
                                              ScrollContainerRef={
                                                ScrollContainerRef
                                              }
                                              hasCodeSamplesBox={
                                                hasCodeSamplesBox
                                              }
                                            />
                                          )
                                        }
                                        isLoading={loading}
                                        layout={
                                          embedded ? 'twoColumn' : 'threeColumn'
                                        }
                                        workflowName={workflowName}
                                        workflowSteps={workflowSteps}
                                      />
                                    );
                                  }}
                                </TOCContextConsumer>
                              </HotKeys>
                            </OAuthContextProvider>
                          </ResponseHeaderContextProvider>
                        </LinkMapperContext.Provider>
                      </DataModelContextProvider>
                    </DocumentContextProvider>
                  ) : (
                    // Show error overlay with refresh button if doc loading failed
                    <AppLayout
                      language={props.lang}
                      portalSettings={props.portalSettings}
                      TOCContent={{
                        section: undefined,
                        hasWorkFlow: false,
                        activeList: [],
                      }}
                      // nav={<Nav disabled={loading} currentTemplate={props.lang} />}
                      main={
                        isError ? (
                          <ErrorOverlay
                            onRetry={refresh}
                            statusCode={statusCode}
                          />
                        ) : (
                          <LoadingOverlay />
                        )
                      }
                      layout="oneColumn"
                    />
                  );
                }}
              </DocLoader>
            );
          }}
        </WorkflowContextConsumer>
      )}
    </EventContextConsumer>
  );
}

/**
 * Wrap scroll container and adds a vertical scroll-bar and height
 */
const ScrollListener = styled(ScrollContainer)`
  ${ScrollableMixin};
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  height: 100%;
  border-right: 1px solid ${(props) => props.theme.staticColors.Snow.C100};

  @media screen and (max-width: 990px) {
    height: fit-content;
    border: none;
  }
`;

/**
 * Render main doc content
 * @param props
 */
function MainContent(props: {
  lang: Language;
  doc: DxDocument;
  linkMapper: LinkMapper;
  workflowName?: string;
  workflowSteps?: WorkflowState['string'];
  setVerified: WorkflowContextType['setVerified'];
  updateTOCProps: (props: Partial<TOCProps>) => void;
  ScrollContainerRef: React.RefObject<HTMLDivElement>;
  hasCodeSamplesBox: (hasCodeBox: boolean) => void;
}) {
  const {
    workflowSteps,
    setVerified,
    workflowName,
    updateTOCProps,
    ScrollContainerRef,
    hasCodeSamplesBox,
  } = props;
  const { selectedStepName } = getSteps(workflowSteps);
  return (
    <RouteToScrollSync
      containerId="scroll-container"
      selectedStepName={selectedStepName}
    >
      <ScrollListener
        id="scroll-container"
        scrollWidth="4px"
        overflowX={false}
        containerRef={ScrollContainerRef}
      >
        <RouteToSection
          {...props}
          render={(section) =>
            workflowSteps ? (
              <WorkflowContent
                workflowSteps={workflowSteps}
                setVerified={setVerified}
                workflowName={workflowName}
              />
            ) : (
              <RSectionDocument
                activeLanguage={props.lang}
                section={section}
                hasWorkFlow={!!workflowName}
                updateTOCProps={updateTOCProps}
                hasCodeSamplesBox={hasCodeSamplesBox}
              />
            )
          }
        />
      </ScrollListener>
    </RouteToScrollSync>
  );
}

/**
 * Get React router to use based on portal settings
 */
export function getRouter(settings: PortalSettings) {
  const { routeStyle } = settings;
  switch (routeStyle) {
    case 'hash':
      return (props: React.ComponentProps<typeof HashRouter>) => (
        <HashRouter {...props} basename={settings.baseRoute}>
          {props.children}
        </HashRouter>
      );
    case 'memory':
      return MemoryRouter;
    case 'browser':
      return (props: React.ComponentProps<typeof BrowserRouter>) => (
        <BrowserRouter {...props} basename={settings.baseRoute}>
          {props.children}
        </BrowserRouter>
      );
    default:
      return exhaustiveCheck(routeStyle);
  }
}

function exhaustiveCheck(type: never): never {
  throw new Error('Unknown option or missing implementation.');
}

import React from 'react';
import 'setimmediate';
import validateFormData, { isValid } from './validate';

export function unwrapFormData(formData) {
  let newFormData = formData;

  if (isObject(newFormData)) {
    if (checkDiscriminator(newFormData)) {
      newFormData = unwrapFormData(newFormData.value);
    } else {
      for (const key in newFormData) {
        newFormData = {
          ...newFormData,
          [key]: unwrapFormData(newFormData[key]),
        };
      }
    }
  }

  if (Array.isArray(newFormData)) {
    newFormData = newFormData.map((item) => {
      if (item && isObject(item)) {
        return unwrapFormData(item);
      } else {
        return item;
      }
    });
  }

  return newFormData;
}

const widgetMap = {
  boolean: {
    checkbox: 'CheckboxWidget',
    radio: 'RadioWidget',
    select: 'SelectWidget',
    hidden: 'HiddenWidget',
  },
  string: {
    text: 'TextWidget',
    password: 'PasswordWidget',
    email: 'EmailWidget',
    hostname: 'TextWidget',
    ipv4: 'TextWidget',
    ipv6: 'TextWidget',
    uri: 'URLWidget',
    'data-url': 'FileWidget',
    radio: 'RadioWidget',
    select: 'SelectWidget',
    textarea: 'TextareaWidget',
    hidden: 'HiddenWidget',
    date: 'NewDateWidget',
    datetime: 'NewDateTimeWidget',
    'date-time': 'NewDateTimeWidget',
    'alt-date': 'AltDateWidget',
    'alt-datetime': 'AltDateTimeWidget',
    color: 'ColorWidget',
    file: 'FileWidget',
  },
  number: {
    text: 'TextWidget',
    select: 'SelectWidget',
    updown: 'UpDownWidget',
    range: 'RangeWidget',
    radio: 'RadioWidget',
    hidden: 'HiddenWidget',
  },
  integer: {
    text: 'TextWidget',
    select: 'SelectWidget',
    updown: 'UpDownWidget',
    range: 'RangeWidget',
    radio: 'RadioWidget',
    hidden: 'HiddenWidget',
  },
  array: {
    select: 'SelectWidget',
    checkboxes: 'CheckboxesWidget',
    files: 'FileWidget',
  },
};

export function getDefaultRegistry() {
  return {
    fields: require('./components/fields').default,
    widgets: require('./components/widgets').default,
    definitions: {},
    formContext: {},
  };
}

export function getWidget(schema, widget, registeredWidgets = {}) {
  const { type } = schema;

  function mergeOptions(Widget) {
    // cache return value as property of widget for proper react reconciliation
    if (!Widget.MergedWidget) {
      const defaultOptions =
        (Widget.defaultProps && Widget.defaultProps.options) || {};
      Widget.MergedWidget = ({ options = {}, ...props }) => (
        <Widget
          options={{
            ...defaultOptions,
            ...options,
          }}
          {...props}
        />
      );
    }
    return Widget.MergedWidget;
  }

  if (typeof widget === 'function') {
    return mergeOptions(widget);
  }

  if (typeof widget !== 'string') {
    throw new Error(`Unsupported widget definition: ${typeof widget}`);
  }

  if (Object.prototype.hasOwnProperty.call(registeredWidgets, widget)) {
    const registeredWidget = registeredWidgets[widget];
    return getWidget(schema, registeredWidget, registeredWidgets);
  }

  if (!Object.prototype.hasOwnProperty.call(widgetMap, type)) {
    throw new Error(`No widget for type "${type}"`);
  }

  if (Object.prototype.hasOwnProperty.call(widgetMap[type], widget)) {
    const registeredWidget = registeredWidgets[widgetMap[type][widget]];
    return getWidget(schema, registeredWidget, registeredWidgets);
  }

  throw new Error(`No widget "${widget}" for type "${type}"`);
}

function computeDefaults(
  schema,
  parentDefaults,
  schemaIndex = 0,
  dxInterface,
  requiredFields
) {
  const { definitions = {} } = dxInterface;
  // Compute the defaults recursively: give highest priority to deepest nodes.
  let defaults = parentDefaults;
  if (isObject(defaults) && isObject(schema.default)) {
    // For object defaults, only override parent defaults that are defined in
    // schema.default.
    defaults = mergeObjects(defaults, schema.default);
  } else if ('default' in schema) {
    // Use schema defaults for this node.
    defaults = schema.default;
  } else if ('$ref' in schema) {
    // Use referenced schema defaults for this node.
    const refSchema = findSchemaDefinition(schema.$ref, definitions);
    return computeDefaults(
      refSchema,
      defaults,
      undefined,
      dxInterface,
      schema?.required
    );
  } else if (isFixedItems(schema)) {
    defaults = schema.items.map((itemSchema) =>
      computeDefaults(
        itemSchema,
        undefined,
        undefined,
        dxInterface,
        schema?.required
      )
    );
  } else if ('oneOf' in schema) {
    const isRequired = requiredFields && requiredFields.includes(schema.title);
    const computedValue = computeDefaults(
      schema.oneOf[schemaIndex],
      undefined,
      schemaIndex,
      dxInterface,
      schema?.required
    );

    defaults =
      isRequired || (!isRequired && computedValue)
        ? {
            $$__case: schemaIndex,
            $$__case_of: 'oneOf',
            value: computedValue,
          }
        : undefined;
  } else if ('anyOf' in schema) {
    defaults = {
      $$__case: schemaIndex,
      $$__case_of: 'anyOf',
      value: computeDefaults(
        schema.anyOf[schemaIndex],
        undefined,
        schemaIndex,
        dxInterface,
        schema?.required
      ),
    };
  }
  // Not defaults defined for this node, fallback to generic typed ones.
  if (typeof defaults === 'undefined') {
    defaults = schema.default;
  }

  switch (schema.type) {
    // We need to recur for object schema inner default values.
    case 'object': {
      defaults = Object.keys(schema.properties || {}).reduce((acc, key) => {
        const property = schema.properties[key];

        if (Object.prototype.hasOwnProperty.call(property, '$ref')) {
          acc[key] = (defaults || {})[key];
        } else {
          acc[key] = computeDefaults(
            property,
            (defaults || {})[key],
            undefined,
            dxInterface,
            schema?.required
          );
        }

        return acc;
      }, {});
      break;
    }

    case 'array': {
      // Inject defaults into existing array defaults
      if (Array.isArray(defaults)) {
        defaults = defaults.map((item, idx) => {
          return computeDefaults(
            schema.items[idx] || schema.additionalItems || {},
            item,
            undefined,
            dxInterface,
            schema?.required
          );
        });
      }
      if (schema.minItems) {
        if (!isMultiSelect(schema, dxInterface)) {
          const defaultsLength = defaults ? defaults.length : 0;
          if (schema.minItems > defaultsLength) {
            // const defaultEntries = defaults || [];
            // populate the array with the defaults
            /*
             * To stop multi rendering and Self Referancing, RJSF
             * library has some limitation to resole it Self Referancing
             * of array which caused multi rendering.
             * It wont effect default values of array in any level.
             * Just to stop multi rendering removied mutation or array.
             * */
            /*  const fillerEntries = new Array(
              schema.minItems - defaultsLength
            ).fill(
              computeDefaults(
                schema.items,
                schema.items.defaults,
                undefined,
                dxInterface,
                schema?.required
              )
            ); */
            // then fill up the rest with either the item default or empty, up to minItems

            return [];
          }
        } else {
          return [];
        }
      }
      break;
    }
  }

  return defaults;
}

export function getDefaultFormState(
  _schema,
  formData,
  schemaIndex = 0,
  dxInterface
) {
  if (!isObject(_schema)) {
    throw new Error('Invalid schema: ' + _schema);
  }
  const schema = retrieveSchema(_schema, formData, dxInterface);
  const defaults = computeDefaults(
    schema,
    _schema.default,
    schemaIndex,
    dxInterface
  );
  const formDataOrDefault = formData || defaults;
  const isString = schema.type === 'string';
  if (typeof formData === 'undefined') {
    // No form data? Use schema defaults.
    return isString ? defaults || '' : defaults;
  }
  if (isObject(formData)) {
    // Override schema defaults with form data.
    let a = mergeDefaultsWithFormData(defaults, formData);
    return a;
  }
  return isString ? formDataOrDefault || '' : formDataOrDefault;
}

export function getUiOptions(uiSchema) {
  // get all passed options from ui:widget, ui:options, and ui:<optionName>
  return Object.keys(uiSchema)
    .filter((key) => key.indexOf('ui:') === 0)
    .reduce((options, key) => {
      const value = uiSchema[key];

      if (key === 'ui:widget' && isObject(value)) {
        console.warn(
          'Setting options via ui:widget object is deprecated, use ui:options instead'
        );
        return {
          ...options,
          ...(value.options || {}),
          widget: value.component,
        };
      }
      if (key === 'ui:options' && isObject(value)) {
        return {
          ...options,
          ...value,
        };
      }
      return {
        ...options,
        [key.substring(3)]: value,
      };
    }, {});
}

export function isObject(thing) {
  return typeof thing === 'object' && thing !== null && !Array.isArray(thing);
}

export function mergeObjects(obj1, obj2, concatArrays = false) {
  // Recursively merge deeply nested objects.
  var acc = Object.assign({}, obj1); // Prevent mutation of source object.
  return Object.keys(obj2).reduce((acc, key) => {
    const left = obj1[key],
      right = obj2[key];
    if (Object.prototype.hasOwnProperty.call(obj1, key) && isObject(right)) {
      acc[key] = mergeObjects(left, right, concatArrays);
    } else if (concatArrays && Array.isArray(left) && Array.isArray(right)) {
      acc[key] = left.concat(right);
    } else {
      acc[key] = right;
    }
    return acc;
  }, acc);
}

/**
 * When merging defaults and form data, we want to merge in this specific way:
 * - objects are deeply merged
 * - arrays are merged in such a way that:
 *   - when the array is set in form data, only array entries set in form data
 *     are deeply merged; additional entries from the defaults are ignored
 *   - when the array is not set in form data, the default is copied over
 * - scalars are overwritten/set by form data
 */
export function mergeDefaultsWithFormData(defaults, formData) {
  if (Array.isArray(formData)) {
    if (!Array.isArray(defaults)) {
      defaults = [];
    }

    return formData.map((value, idx) => {
      if (defaults[idx]) {
        return mergeDefaultsWithFormData(defaults[idx], value);
      }
      return value;
    });
  } else if (isObject(formData) && formData.$$__case === undefined) {
    const acc = Object.assign({}, defaults);

    return Object.keys(formData).reduce((acc, key) => {
      acc[key] = mergeDefaultsWithFormData(
        defaults ? defaults[key] : {},
        formData[key]
      );
      return acc;
    }, acc);
  } else {
    return formData;
  }
}

export function asNumber(value) {
  if (value === '') {
    return undefined;
  }
  if (/\.$/.test(value)) {
    // "3." can't really be considered a number even if it parses in js. The
    // user is most likely entering a float.
    return value;
  }
  if (/\.0$/.test(value)) {
    // we need to return this as a string here, to allow for input like 3.07
    return value;
  }
  const n = Number(value);
  const valid = typeof n === 'number' && !Number.isNaN(n);

  if (/\.\d*0$/.test(value)) {
    // It's a number, that's cool - but we need it as a string so it doesn't screw
    // with the user when entering dollar amounts or other values (such as those with
    // specific precision or number of significant digits)
    return value;
  }

  return valid ? n : value;
}

export function orderProperties(properties, order) {
  if (!Array.isArray(order)) {
    return properties;
  }

  const arrayToHash = (arr) =>
    arr.reduce((prev, curr) => {
      prev[curr] = true;
      return prev;
    }, {});
  const errorPropList = (arr) =>
    arr.length > 1
      ? `properties '${arr.join("', '")}'`
      : `property '${arr[0]}'`;
  const propertyHash = arrayToHash(properties);
  const orderHash = arrayToHash(order);
  const extraneous = order.filter(
    (prop) => prop !== '*' && !propertyHash[prop]
  );
  if (extraneous.length) {
    throw new Error(
      `uiSchema order list contains extraneous ${errorPropList(extraneous)}`
    );
  }
  const rest = properties.filter((prop) => !orderHash[prop]);
  const restIndex = order.indexOf('*');
  if (restIndex === -1) {
    if (rest.length) {
      throw new Error(
        `uiSchema order list does not contain ${errorPropList(rest)}`
      );
    }
    return order;
  }
  if (restIndex !== order.lastIndexOf('*')) {
    throw new Error('uiSchema order list contains more than one wildcard item');
  }

  const complete = [...order];
  complete.splice(restIndex, 1, ...rest);
  return complete;
}

/**
 * This function checks if the given schema matches a single
 * constant value.
 */
export function isConstant(schema) {
  return (
    (Array.isArray(schema.enum) && schema.enum.length === 1) ||
    Object.prototype.hasOwnProperty.call(schema, 'const')
  );
}

export function toConstant(schema) {
  if (Array.isArray(schema.enum) && schema.enum.length === 1) {
    return schema.enum[0];
  } else if (Object.prototype.hasOwnProperty.call(schema, 'const')) {
    return schema.const;
  } else {
    throw new Error('schema cannot be inferred as a constant');
  }
}

export function isSelect(_schema, dxInterface) {
  // clear
  const schema = retrieveSchema(_schema, undefined, dxInterface);
  const altSchemas = schema.oneOf || schema.anyOf;
  if (Array.isArray(schema.enum)) {
    return true;
  } else if (Array.isArray(altSchemas)) {
    return altSchemas.every((altSchemas) => isConstant(altSchemas));
  }
  return false;
}

export function isMultiSelect(schema, dxInterface) {
  if (!schema.uniqueItems || !schema.items) {
    return false;
  }
  return isSelect(schema.items, dxInterface);
}

export function isFilesArray(schema, uiSchema, dxInterface) {
  if (uiSchema['ui:widget'] === 'files') {
    return true;
  } else if (schema.items) {
    // clear
    const itemsSchema = retrieveSchema(schema.items, undefined, dxInterface);
    return itemsSchema.type === 'string' && itemsSchema.format === 'data-url';
  }
  return false;
}

export function isFixedItems(schema) {
  return (
    Array.isArray(schema.items) &&
    schema.items.length > 0 &&
    schema.items.every((item) => isObject(item))
  );
}

export function allowAdditionalItems(schema) {
  if (schema.additionalItems === true) {
    console.warn('additionalItems=true is currently not supported');
  }
  return isObject(schema.additionalItems);
}

export function optionsList(schema) {
  if (schema.enum) {
    return schema.enum.map((value, i) => {
      const label = (schema.enumNames && schema.enumNames[i]) || String(value);
      return {
        label,
        value,
      };
    });
  } else {
    const altSchemas = schema.oneOf || schema.anyOf;
    return altSchemas.map((schema, i) => {
      const value = toConstant(schema);
      const label = schema.title || String(value);
      return {
        label,
        value,
      };
    });
  }
}

export function findSchemaDefinition($ref, definitions = {}, fallback) {
  // Extract and use the referenced definition if we have it.
  const match = /^ModelSchemas#\/(.*)$/.exec($ref);
  if (match && match[1]) {
    const parts = match[1].split('/');
    let current = definitions;
    for (let part of parts) {
      if (fallback) {
        part = part.replace(/~1/g, '/').replace(/~0/g, '~');
      }
      if (Object.prototype.hasOwnProperty.call(current, part)) {
        current = current[part];
      } else {
        // TODO: Hack for compatiblity. Need to remove after proper fix
        if (!fallback) {
          const decodedRef = decodeURIComponent($ref);
          return findSchemaDefinition(decodedRef, definitions, true);
        }
        // No matching definition found, that's an error (bogus schema?)
        throw new Error(`Could not find a definition for ${$ref}.`);
      }
    }
    return current;
  }

  // No matching definition found, that's an error (bogus schema?)
  throw new Error(`Could not find a definition for ${$ref}.`);
}

function getStructure(modelName, structures) {
  const structure = structures.find((struct) => {
    return struct.Name === modelName || struct.OriginalName === modelName;
  });

  return structure;
}

const TABLE_HEADER = '| Type | Value |';

function splitDesription(description) {
  switch (description) {
    case '-':
      return;
    default: {
      const descriptionHas = Boolean(description.includes(TABLE_HEADER));
      const [originalDescription] = description.split(TABLE_HEADER);

      return descriptionHas ? originalDescription : description;
    }
  }
}

export function getDescription(type) {
  const { Description = '' } = type;
  const description = typeof Description === 'string' ? Description.trim() : '';

  return splitDesription(description);
}

function getDataTypeDisplayText(type) {
  return type.DataType ? type.DataType : undefined;
}

function getUrl(linkMapper, link) {
  return linkMapper(link);
}

function generateAdditionalProperties(type, linkMapper) {
  return {
    additionalDescription: type.AdditionalDescription,
    description: getDescription(type),
    dataTypeDisplayText: getDataTypeDisplayText(type),
    dataTypeLink: type.LinkTo ? getUrl(linkMapper, type.LinkTo) : undefined,
    linkTo: type.LinkTo,
    dataTypeMarkdown: type.DataTypeMarkdown,
    paramType: type.ParamType,
    title: type.Name,
    typeCombinatorTypes: type.TypeCombinatorTypes,
    discriminator: type.Discriminator,
    discriminatorValue: type.DiscriminatorValue,
    readOnly: type.ReadOnly,
    writeOnly: type.WriteOnly,
    containerKind: type.ContainerKind,
    containerName: type.ContainerName,
    isJsonViewDisabled: type.DisableJsonView,
  };
}

function mergeStructure(schema, structure, linkMapper) {
  if (structure && structure.Fields) {
    structure.Fields.forEach((field) => {
      let property = schema.properties[field.GenericName];
      if (property) {
        const additionalProperties = generateAdditionalProperties(
          field,
          linkMapper
        );
        if (property.type === 'array') {
          schema.properties[field.GenericName].items = {
            ...property.items,
            typeCombinatorTypes: field.TypeCombinatorTypes,
            discriminator: field.Discriminator,
            dataTypeDisplayText: getArrayItem(field.DataType),
            dataTypeLink: additionalProperties.dataTypeLink,
            dataTypeMarkdown: additionalProperties.dataTypeMarkdown,
          };
        }
        schema.properties[field.GenericName] = {
          ...property,
          ...additionalProperties,
        };
      }
    });
  }

  return schema;
}

function mergeFieldsData(refSchema, modelName, structure, linkMapper) {
  if (Object.prototype.hasOwnProperty.call(refSchema, 'allOf')) {
    const selectedIndex = refSchema.allOf.findIndex(
      (item) => item.id === modelName
    );

    if (selectedIndex) {
      const selectedSchema = refSchema.allOf[selectedIndex];

      refSchema.allOf[selectedIndex] = mergeStructure(
        selectedSchema,
        structure,
        linkMapper
      );
    }
  } else {
    refSchema = mergeStructure(refSchema, structure, linkMapper);
  }

  return refSchema;
}

export function retrieveSchema(schema, formData = {}, dxInterface) {
  const { definitions = {}, structures = [], linkMapper } = dxInterface;

  if (Object.prototype.hasOwnProperty.call(schema, '$ref')) {
    // Retrieve the referenced schema definition.
    let $refSchema = findSchemaDefinition(schema.$ref, definitions);

    const modelName = $refSchema.id || $refSchema.title;
    const structure = getStructure(modelName, structures);
    $refSchema = mergeFieldsData($refSchema, modelName, structure, linkMapper);

    // Drop the $ref property of the source schema.
    const { $ref, ...localSchema } = schema;
    // Update referenced schema definition with local schema properties.
    return retrieveSchema(
      {
        ...$refSchema,
        ...localSchema,
      },
      formData,
      dxInterface
    );
  } else if (Object.prototype.hasOwnProperty.call(schema, 'dependencies')) {
    const resolvedSchema = resolveDependencies(schema, formData, dxInterface);

    return retrieveSchema(resolvedSchema, formData, dxInterface);
  } else if (Object.prototype.hasOwnProperty.call(schema, 'allOf')) {
    const name = schema.id || schema.title;
    const structure = getStructure(name, structures);

    let $refSchema = schema;
    $refSchema = mergeFieldsData($refSchema, name, structure, linkMapper);

    $refSchema.allOf = $refSchema.allOf.map((schema) =>
      retrieveSchema(schema, formData, dxInterface)
    );

    return schema;
  } else {
    // No $ref or dependencies attribute found, returning the original schema.
    return schema;
  }
}

function resolveDependencies(schema, formData, dxInterface) {
  // Drop the dependencies from the source schema.
  let { dependencies = {}, ...resolvedSchema } = schema;
  // Process dependencies updating the local schema properties as appropriate.
  for (const dependencyKey in dependencies) {
    // Skip this dependency if its trigger property is not present.
    if (formData[dependencyKey] === undefined) {
      continue;
    }
    const dependencyValue = dependencies[dependencyKey];
    if (Array.isArray(dependencyValue)) {
      resolvedSchema = withDependentProperties(resolvedSchema, dependencyValue);
    } else if (isObject(dependencyValue)) {
      resolvedSchema = withDependentSchema(
        resolvedSchema,
        formData,
        dependencyKey,
        dependencyValue,
        dxInterface
      );
    }
  }
  return resolvedSchema;
}

function withDependentProperties(schema, additionallyRequired) {
  if (!additionallyRequired) {
    return schema;
  }
  const required = Array.isArray(schema.required)
    ? Array.from(new Set([...schema.required, ...additionallyRequired]))
    : additionallyRequired;
  return {
    ...schema,
    required: required,
  };
}

function withDependentSchema(
  schema,
  formData,
  dependencyKey,
  dependencyValue,
  dxInterface
) {
  let { oneOf, ...dependentSchema } = retrieveSchema(
    dependencyValue,
    formData,
    dxInterface
  );
  schema = mergeSchemas(schema, dependentSchema);
  return oneOf === undefined
    ? schema
    : withExactlyOneSubschema(
        schema,
        formData,
        dependencyKey,
        oneOf,
        dxInterface
      );
}

function withExactlyOneSubschema(
  schema,
  formData,
  dependencyKey,
  oneOf,
  dxInterface
) {
  const { definitions } = dxInterface;
  if (!Array.isArray(oneOf)) {
    throw new Error(
      `invalid oneOf: it is some ${typeof oneOf} instead of an array`
    );
  }
  const validSubschemas = oneOf.filter((subschema) => {
    if (!subschema.properties) {
      return false;
    }
    const { [dependencyKey]: conditionPropertySchema } = subschema.properties;
    if (conditionPropertySchema) {
      const conditionSchema = {
        type: 'object',
        properties: {
          [dependencyKey]: conditionPropertySchema,
        },
      };
      const { errors } = validateFormData(
        formData,
        conditionSchema,
        undefined,
        undefined,
        undefined,
        definitions
      );
      return errors.length === 0;
    }
  });
  if (validSubschemas.length !== 1) {
    console.warn(
      "ignoring oneOf in dependencies because there isn't exactly one subschema that is valid"
    );
    return schema;
  }
  const subschema = validSubschemas[0];
  const { [dependencyKey]: conditionPropertySchema, ...dependentSubschema } =
    subschema.properties;
  const dependentSchema = {
    ...subschema,
    properties: dependentSubschema,
  };
  return mergeSchemas(
    schema,
    retrieveSchema(dependentSchema, formData, dxInterface)
  );
}

function mergeSchemas(schema1, schema2) {
  return mergeObjects(schema1, schema2, true);
}

function isArguments(object) {
  return Object.prototype.toString.call(object) === '[object Arguments]';
}

export function deepEquals(a, b, ca = [], cb = []) {
  // Partially extracted from node-deeper and adapted to exclude comparison
  // checks for functions.
  // https://github.com/othiym23/node-deeper
  if (a === b) {
    return true;
  } else if (typeof a === 'function' || typeof b === 'function') {
    // Assume all functions are equivalent
    // see https://github.com/mozilla-services/react-jsonschema-form/issues/255
    return true;
  } else if (typeof a !== 'object' || typeof b !== 'object') {
    return false;
  } else if (a === null || b === null) {
    return false;
  } else if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  } else if (a instanceof RegExp && b instanceof RegExp) {
    return (
      a.source === b.source &&
      a.global === b.global &&
      a.multiline === b.multiline &&
      a.lastIndex === b.lastIndex &&
      a.ignoreCase === b.ignoreCase
    );
  } else if (isArguments(a) || isArguments(b)) {
    if (!(isArguments(a) && isArguments(b))) {
      return false;
    }
    let slice = Array.prototype.slice;
    return deepEquals(slice.call(a), slice.call(b), ca, cb);
  } else {
    if (a.constructor !== b.constructor) {
      return false;
    }

    let ka = Object.keys(a);
    let kb = Object.keys(b);
    // don't bother with stack acrobatics if there's nothing there
    if (ka.length === 0 && kb.length === 0) {
      return true;
    }
    if (ka.length !== kb.length) {
      return false;
    }

    let cal = ca.length;
    while (cal--) {
      if (ca[cal] === a) {
        return cb[cal] === b;
      }
    }
    ca.push(a);
    cb.push(b);

    ka.sort();
    kb.sort();
    for (var j = ka.length - 1; j >= 0; j--) {
      if (ka[j] !== kb[j]) {
        return false;
      }
    }

    let key;
    for (let k = ka.length - 1; k >= 0; k--) {
      key = ka[k];
      if (!deepEquals(a[key], b[key], ca, cb)) {
        return false;
      }
    }

    ca.pop();
    cb.pop();

    return true;
  }
}

export function shouldRender(comp, nextProps, nextState) {
  const { props, state } = comp;
  return !deepEquals(props, nextProps) || !deepEquals(state, nextState);
}

export function parseDateString(dateString, includeTime = true) {
  if (!dateString) {
    return {
      year: -1,
      month: -1,
      day: -1,
      hour: includeTime ? -1 : 0,
      minute: includeTime ? -1 : 0,
      second: includeTime ? -1 : 0,
    };
  }
  const date = new Date(dateString);
  if (Number.isNaN(date.getTime())) {
    throw new Error('Unable to parse date ' + dateString);
  }
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1, // oh you, javascript.
    day: date.getUTCDate(),
    hour: includeTime ? date.getUTCHours() : 0,
    minute: includeTime ? date.getUTCMinutes() : 0,
    second: includeTime ? date.getUTCSeconds() : 0,
  };
}

export function toDateString(
  { year, month, day, hour = 0, minute = 0, second = 0 },
  time = true
) {
  const utcTime = Date.UTC(year, month - 1, day, hour, minute, second);
  const datetime = new Date(utcTime).toJSON();
  return time ? datetime : datetime.slice(0, 10);
}

export function pad(num, size) {
  let s = String(num);
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

export function setState(instance, state, callback) {
  const { safeRenderCompletion } = instance.props;
  if (safeRenderCompletion) {
    instance.setState(state, callback);
  } else {
    instance.setState(state);
    setImmediate(callback);
  }
}

export function dataURItoBlob(dataURI) {
  // Split metadata from data
  const splitted = dataURI.split(',');
  // Split params
  const params = splitted[0].split(';');
  // Get mime-type from params
  const type = params[0].replace('data:', '');
  // Filter the name property from params
  const properties = params.filter((param) => {
    return param.split('=')[0] === 'name';
  });
  // Look for the name and use unknown if no name property.
  let name;
  if (properties.length !== 1) {
    name = 'unknown';
  } else {
    // Because we filtered out the other property,
    // we only have the name case here.
    name = properties[0].split('=')[1];
  }

  // Built the Uint8Array Blob parameter from the base64 string.
  const binary = atob(splitted[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  // Create the blob object
  const blob = new window.Blob([new Uint8Array(array)], {
    type,
  });

  return {
    blob,
    name,
  };
}

export function rangeSpec(schema) {
  const spec = {};
  if (schema.multipleOf) {
    spec.step = schema.multipleOf;
  }
  if (schema.minimum || schema.minimum === 0) {
    spec.min = schema.minimum;
  }
  if (schema.maximum || schema.maximum === 0) {
    spec.max = schema.maximum;
  }
  return spec;
}

export const classPrefix = 'rjsf-';

export function prefixClass(className) {
  return className && classPrefix
    ? className
        .split(' ')
        .map((e) =>
          e !== '' && e.indexOf(classPrefix) !== 0 ? classPrefix + e : e
        )
        .join(' ')
    : className;
}

export function getMatchingOption(formData, options, rootSchema) {
  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    // If the schema describes an object then we need to add slightly more
    // strict matching to the schema, because unless the schema uses the
    // "requires" keyword, an object will match the schema as long as it
    // doesn't have matching keys with a conflicting type. To do this we use an
    // "anyOf" with an array of requires. This augmentation expresses that the
    // schema should match if any of the keys in the schema are present on the
    // object and pass validation.
    if (option.properties) {
      // Create an "anyOf" schema that requires at least one of the keys in the
      // "properties" object
      const requiresAnyOf = {
        anyOf: Object.keys(option.properties).map((key) => ({
          required: [key],
        })),
      };

      let augmentedSchema;

      // If the "anyOf" keyword already exists, wrap the augmentation in an "allOf"
      if (option.anyOf) {
        // Create a shallow clone of the option
        const { ...shallowClone } = option;

        if (!shallowClone.allOf) {
          shallowClone.allOf = [];
        } else {
          // If "allOf" already exists, shallow clone the array
          shallowClone.allOf = shallowClone.allOf.slice();
        }

        shallowClone.allOf.push(requiresAnyOf);

        augmentedSchema = shallowClone;
      } else {
        augmentedSchema = Object.assign({}, option, requiresAnyOf);
      }

      // Remove the "required" field as it's likely that not all fields have
      // been filled in yet, which will mean that the schema is not valid
      delete augmentedSchema.required;

      if (isValid(augmentedSchema, formData)) {
        return i;
      }
    } else if (isValid(options[i], formData)) {
      return i;
    }
  }
  return 0;
}

export function checkDiscriminator(data) {
  if (
    data &&
    isObject(data) &&
    Object.prototype.hasOwnProperty.call(data, '$$__case')
  ) {
    return true;
  }
  return false;
}

export const isMultipleSchema = (schema) =>
  !!(
    schema &&
    (Object.prototype.hasOwnProperty.call(schema, 'oneOf') ||
      Object.prototype.hasOwnProperty.call(schema, 'anyOf'))
  );

export function classNames(classObj) {
  if (typeof classObj !== 'object') {
    return;
  }

  let className = '';

  Object.entries(classObj).forEach(([key, value]) => {
    if (value) {
      className += ` ${key}`;
    }
  });

  return className;
}

export function isOneOfSchema(schema = {}) {
  return schema.oneOf || schema.anyOf;
}

export function pipe(...func) {
  return (data) =>
    func.reduce((cache, currentFunc) => {
      return currentFunc(cache);
    }, data);
}

export function getListRootItem(markdown) {
  const items = markdown.split('*');
  return items[1];
}

export function isDiscriminator(name = '', discriminatorObj = {}) {
  const discriminators = Object.keys(discriminatorObj);
  return discriminators?.includes(name);
}

export function getArrayItem(type) {
  const pattern = /<(.+)>/;
  const match = pattern.exec(type);
  if (match) {
    return match[1];
  }

  return '';
}

export function getEvenOdd(depth) {
  return depth % 2 === 0;
}

export function getEvenOddClass(depth) {
  return getEvenOdd(depth) ? 'even' : 'odd';
}

export function isOafContainer(schema) {
  const oafLabelFromMarkdown = Boolean(
    schema?.markdown ? getListRootItem(schema?.markdown) : null
  );
  const isContainerKind = Object.prototype.hasOwnProperty.call(
    schema,
    'containerKind'
  );
  return isContainerKind || oafLabelFromMarkdown;
}

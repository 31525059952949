import propTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { prefixClass, classNames } from '../../../utils';
import { ContextConsumer } from '../../context';
import { TagLinkSvg } from '../../Icons';

const VIEW_MODEL = 'View Model';

class TagSelector extends React.Component {
  render() {
    const {
      title,
      options,
      onChange,
      value = options[0],
      className = '',
      renderToolTip,
      disabled,
      trackEvent,
      portalSettings,
    } = this.props;

    const onClick = (option) => {
      return () => {
        onChange && onChange(option.value);
        trackEvent(
          portalSettings,
          'API Playground - OneOf/AnyOf Type Changed',
          {}
        );
      };
    };

    const selectValue = options && options[value.index];

    const selectedDescription =
      selectValue && selectValue?.value?.schema?.description;

    const selectedLabel = selectValue && selectValue?.label;

    const selectLinkTo = selectValue && selectValue?.value?.linkTo;

    return (
      <>
        <div className={`tag-selector ${className}`}>
          {title && <span className="__title --tag">{title}</span>}
          <div className="__tags-wrapper --variant-tag">
            {options.map((option, index) => {
              const linkTo = option.value.linkTo;

              const classTagLabel = classNames({
                '--tag': true,
                ' tag-label': true,
                '--active': value.index === index,
                '--active-with-no-link':
                  value.index === index && Boolean(!linkTo),
              });

              const classTagLink = classNames({
                '--tag': true,
                'tag-link': true,
                '--active': value.index === index,

                'tag-selector-button-link': true,
              });

              const classTagWrapper = classNames({
                'tag-wrapper-item': true,
                'tag-wrapper-item-disabled': disabled,
              });

              return (
                <div className={classTagWrapper}>
                  <span
                    key={`option-item-${index}-${option.label}`}
                    className={classTagLabel}
                    onClick={onClick(option)}
                  >
                    {option.label}
                  </span>
                  {linkTo && value.index === index && (
                    <ContextConsumer>
                      {({ onRouteChange }) =>
                        renderToolTip(
                          <span
                            key={`option-item-${index}-${option.label}`}
                            className={classTagLink}
                            onClick={() => {
                              onRouteChange(linkTo);
                            }}
                          >
                            <TagLinkSvg width="10" />
                          </span>,
                          VIEW_MODEL
                        )
                      }
                    </ContextConsumer>
                  )}
                </div>
              );
            })}
          </div>
          <div className="__tags-wrapper --variant-select">
            <Select
              className={`${prefixClass('form-control')}`}
              classNamePrefix="react-select"
              value={selectValue}
              options={options}
              onChange={({ value }) => {
                trackEvent(
                  portalSettings,
                  'API Playground - OneOf/AnyOf Type Changed',
                  {}
                );
                onChange(value);
              }}
            />
            <span className="tags-variant-select-link">
              <ContextConsumer>
                {({ onRouteChange }) =>
                  renderToolTip(
                    <a onClick={() => onRouteChange(selectLinkTo)}>
                      <TagLinkSvg width="10" /> {VIEW_MODEL}
                    </a>,
                    selectedLabel
                  )
                }
              </ContextConsumer>
            </span>
          </div>
        </div>
        {selectedDescription && (
          <div
            className={`${prefixClass('field-description')} tag-description`}
          >
            <ContextConsumer>
              {({ markdownRenderer }) => markdownRenderer(selectedDescription)}
            </ContextConsumer>
          </div>
        )}
      </>
    );
  }
}

TagSelector.defaultProps = {
  title: propTypes.string.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.any,
      value: propTypes.any,
    })
  ).isRequired,
  onChange: propTypes.func,
  value: propTypes.any,
};

export default TagSelector;

import { CasesTypes, SectionNode } from './DxDom';
import styled from 'styled-components';
import { H3 } from './CleanSlate';
import { ExpandableCard } from './ExpandableCard';

const CasesHeading = styled(H3)`
  margin-top: 60px;
`;

interface ExpandableCardWrapperProps {
  readonly Cases: ReadonlyArray<CasesTypes>;
  renderNode: (n: SectionNode) => object;
}

export const ExpandableCardWrapper = ({
  Cases,
  renderNode,
}: ExpandableCardWrapperProps) => {
  return (
    <>
      <CasesHeading>Cases</CasesHeading>
      {Cases.map((singleCase, index) => {
        return (
          <ExpandableCard
            singleCase={singleCase}
            index={index}
            renderNode={renderNode}
          />
        );
      })}
    </>
  );
};

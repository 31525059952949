import styled from 'styled-components';
import { Span } from 'src/CleanSlate';
import Tooltip from 'src/Tooltip/Tooltip';

const StepDescription = styled(Span)`
  line-height: 18px;
  font-size: 15px;
`;

interface DescriptionProps {
  description: string;
}
export function Description({ description }: DescriptionProps) {
  const hasMoreThanFifteenWords = description.split(' ').length > 15;
  const firstFifteenWords = hasMoreThanFifteenWords
    ? description.split(' ').slice(0, 15).join(' ').concat(' ...')
    : '';

  return (
    <>
      {hasMoreThanFifteenWords ? (
        <Tooltip
          text={description}
          parentRefranceId="app-layout-docs-container"
          placement="right"
        >
          <StepDescription>{firstFifteenWords}</StepDescription>
        </Tooltip>
      ) : (
        <StepDescription>{description}</StepDescription>
      )}
    </>
  );
}

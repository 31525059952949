import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Div, Span } from './CleanSlate';
import { ArrowBackIcon } from './Icons';
import { Language } from './PortalSettings';
import { getUrlFriendlyName } from './PlatformConstants';
import { useContext, useEffect } from 'react';
import {
  getSteps,
  WorkflowContext,
  WorkflowState,
} from './Context/WorkflowContext';
import { PortalContext } from './PortalContext';
import { guidedWalkThroughEvents } from './Analytics';
import { getWalkthroughContentType } from './Utilities/utility';

const backToMenuMixin = css`
  color: ${(props) => props.theme.staticColors.Goose.C000};
  width: fit-content;

  &:hover {
    cursor: pointer;
  }
`;

const BackToMenuText = styled(Span)`
  ${backToMenuMixin};
  padding-left: 5px;
  font-size: 12px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const BackToMenu = styled(Div)`
  ${backToMenuMixin};
  margin: 8px 0px 35px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 990px) {
    margin: 20px 0px;
  }
`;

const WALKTHROUGH_CLOSED_STEP = 'Guided Walkthrough Closed';
export function BackToNavBarMenu(props: {
  lang: Language;
  workflowName?: string;
  workflowSteps?: WorkflowState[string];
  closeWorkflow: () => void;
}) {
  const { lang, workflowName = '', closeWorkflow, workflowSteps } = props;
  const history = useHistory();
  const { resetToInitialState, formDataRef } = useContext(WorkflowContext);

  const { selectedStepName, selectedStepValue } = getSteps(workflowSteps);

  const portalSettings = useContext(PortalContext);

  const goBack = () => {
    const stepType = getWalkthroughContentType(selectedStepValue?.isContent);
    closeWorkflow();

    portalSettings &&
      guidedWalkThroughEvents(
        WALKTHROUGH_CLOSED_STEP,
        portalSettings,
        workflowName,
        selectedStepName,
        stepType
      );

    history.push(`/${getUrlFriendlyName(lang)}`);
  };

  useEffect(() => {
    return () => {
      if (workflowName) {
        resetToInitialState(workflowName);
        // Revert initial state of formDataRef on leaving workflow
        // There is only one formData for each workflow.
        if (formDataRef) {
          formDataRef.current = {};
        }
      }
    };
  }, [workflowName, resetToInitialState, formDataRef]);

  return (
    <BackToMenu onClick={goBack}>
      <ArrowBackIcon
        height="10"
        width="12"
        viewBox="0 0 10 10"
        strokeWidth="1.5"
      />
      <BackToMenuText>Back to Menu</BackToMenuText>
    </BackToMenu>
  );
}

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { Button, styled } from 'src/CleanSlate';
import { DataModelContextProps } from 'src/DataModelContext';
import {
  DataModel,
  EndpointReference,
  JSchema,
  Document,
  AuthTokenDef,
  CompilableCodeBlock,
  SimpleJsonType,
  AuthHttpCallTemplateDictonary,
  OAuth2CodeSettingsDictonary,
  ApiGroupModel,
  RequiredAuthScopes,
} from 'src/DxDom';
import { JSchemaForm } from 'src/JSchemaForm';
import { PortalSettings } from 'src/PortalSettings';
import {
  APICallException,
  AuthActionItems,
  AuthWrapper,
} from 'src/RCompilableCodeBlock';
import { AuthTokenCallout } from './AuthTokenCallout';
import { KeyIcon, ResetIcon } from 'src/Icons';
import {
  docsGetTokenOopsError,
  getTokenEvent,
  getTokenSuccessEvent,
  trackEvent,
} from 'src/Analytics';
import { ProxyResponseInterface } from 'src/ResponseViewer/ProxyResponseInterface';
import { CancellablePromise, makeCancellable } from 'src/CancellablePromise';
import { callEndpoint } from 'src/ApiClient';
import { authTokenValidator } from 'src/Utilities/AuthTokenValidator';
import { captureException, setTag } from '@sentry/react';
import { ResponseHeaderContextProps } from 'src/Context/ResponseHeaderContext';
import { AuthorizationWrapper } from './AuthorizationWrapper';
import { AuthStatus, OAuthContext, OAuthError } from './OAuthContext';
import { Notification } from './NotificationBar';
import { Callout } from 'src/MultipleResponses/Callout';
import { Base64 } from 'js-base64';

export interface AuthorizationProps {
  endpointRef: EndpointReference;
  dataModelContext: DataModelContextProps;
  portalSettings: PortalSettings;
  authModelSchema: JSchema;
  responseHeadersContext: ResponseHeaderContextProps;
  doc: Document;
}

const AuthorizationSection = styled(AuthWrapper)`
  margin-top: 0px;
  padding: 0px;
`;

export const MultiAuthButton = styled(Button)<{
  isError?: boolean;
  isSuccess?: boolean;
}>`
  align-items: center;
  margin: 0px 10px
    ${(props) => (props.isError || props.isSuccess ? '25px' : '0px')} 0px;
  color: ${(props) => props.theme.staticColors.Blue.C000};
  font-size: 15px;
  border: 1px solid ${(props) => props.theme.staticColors.Blue.C000};

  svg {
    margin-right: 5px;
  }
`;

export type JSONValue =
  | string
  | number
  | boolean
  | JSONObject
  | Array<JSONValue>;

interface JSONObject {
  [x: string]: JSONValue;
}

export interface AuthorizationState {
  [key: string]: { isAuthorized: boolean; isAuthorizing: boolean };
}

export interface AuthRendererProps {
  authTokenRenderer: (
    propertyKey: string,
    authRenderState: AuthorizationState
  ) => React.ReactNode;
  authRenderState: AuthorizationState;
  dataModelProp: DataModel;
  authDataModel: DataModel['auth'];
  RequiredScopes?: RequiredAuthScopes;
}
type FormAuthDataType = DataModel['auth'];

export const Authorization = (props: AuthorizationProps) => {
  const {
    doc,
    dataModelContext,
    dataModelContext: {
      dataModel,
      authDataModel,

      liquidInstance,
      updateAuthDataModel,
    },
    authModelSchema,
    portalSettings,
    endpointRef: {
      UsageExample: { EndpointName, EndpointGroupName, Index, Templates },
      RequiredAuths,
      RequiredAuthScopes,
      AuthDescription,
    },
    responseHeadersContext: {
      responseHeaders: { apiDigest },
    },
  } = props;

  const { ModelSchemas } = doc;

  const oAuthContext = useContext(OAuthContext);

  const authModelContextRef = useRef<FormAuthDataType>();
  const authErrorRef = useRef<OAuthError>();

  const lastAuthCallPromise = useRef<
    CancellablePromise<ProxyResponseInterface> | undefined
  >();

  const authPopupWindow = useRef<Window | null>(null);

  const apiAuthentication =
    doc.ApiAuthentication as OAuth2CodeSettingsDictonary;

  const authHttpCallTemplate =
    doc.AuthHttpCallTemplate as AuthHttpCallTemplateDictonary;

  const [authFormData, setFormData] = useState<FormAuthDataType>();

  useEffect(() => {
    if (
      RequiredAuths &&
      RequiredAuths?.length <= 1 &&
      !hasAuthGroup(authModelSchema)
    ) {
      const newFormData = filterRequiredFormData(
        RequiredAuths,
        authDataModel,
        authFormData,
        hasAuthGroup(authModelSchema)
      );
      updateAuthDataModel({
        ...dataModelContext,
        dataModel: {
          ...dataModel,
          auth: newFormData,
        },
      });
      setFormData(newFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RequiredAuths, authDataModel]);

  const setUnWrapedFormData = useCallback(
    (
      model: DataModel['auth'],
      updatedModel?: {
        [x: string]: {
          OAuthToken: AuthTokenDef & Record<string, string>;
        };
      }
    ) => {
      if (RequiredAuths) {
        const defaultFormData = filterRequiredFormData(
          RequiredAuths,
          authDataModel,
          model,
          hasAuthGroup(authModelSchema)
        );
        const authGroup = model?.authGroup as ApiGroupModel;

        const authGroupValue = updatedModel
          ? updatedModel
          : { ...(authGroup.value as object) };

        const wrapData = {
          ...model,
          authGroup: {
            ...authGroup,
            value: {
              ...defaultFormData,
              ...authGroupValue,
            },
          },
        };

        return wrapData;
      }
    },
    [RequiredAuths, authDataModel]
  );

  const updateAuthModel = useCallback(
    (model: DataModel['auth']) => {
      const hasCaseWrapping = checkCaseWrapping(model?.authGroup as JSONValue);

      if (hasCaseWrapping && RequiredAuths) {
        const updatedFormWrapper = setUnWrapedFormData(model);

        setFormData(updatedFormWrapper);
        const updatedFormData = unwrapCallModelData(
          updatedFormWrapper?.authGroup as JSONValue
        ) as object;

        updateAuthDataModel({
          ...dataModelContext,
          dataModel: {
            ...dataModel,
            auth: updatedFormData as DataModel['auth'],
          },
          authDataModel: { ...authDataModel, ...updatedFormData },
        });
      } else {
        setFormData(model);
        updateAuthDataModel({
          ...dataModelContext,
          dataModel: {
            ...dataModel,
            auth: model,
          },
          authDataModel: { ...authDataModel, ...model },
        });
      }
    },
    [
      RequiredAuths,
      authDataModel,
      dataModel,
      dataModelContext,
      setUnWrapedFormData,
      updateAuthDataModel,
    ]
  );

  const setAuthLoadingState = useCallback(
    (propertyKey: string, state: AuthStatus) => {
      if (oAuthContext) {
        const { oAuthContextState, updateOAuthState } = oAuthContext;
        const oAuthState = oAuthContext?.oAuthContextState?.oAuthState;
        updateOAuthState({
          ...oAuthContextState,
          oAuthState: {
            ...oAuthState,
            [propertyKey]: {
              ...oAuthState?.[propertyKey],
              ...state,
            },
          },
        });
      }
    },
    [oAuthContext]
  );

  const getAuthUrl = useCallback(
    (
      callbackUrlEncoded: string,
      jsonEncodedAuthUrl: string,
      jsonEncodedTokenUrl: string,
      propertyKey: string
    ) => {
      const authCallToken = authFormData?.[propertyKey] as {
        [key: string]: SimpleJsonType;
      };

      const OAuthClientId = authCallToken?.OAuthClientId;
      const OAuthClientSecret = authCallToken?.OAuthClientSecret;

      return (
        JSON.parse(jsonEncodedAuthUrl) +
        '&redirect_uri=' +
        callbackUrlEncoded +
        '&state=' +
        encodeURI(
          callbackUrlEncoded +
            ';' +
            encodeURI(OAuthClientId + '') +
            ';' +
            encodeURI(OAuthClientSecret + '') +
            ';' +
            encodeURI(JSON.parse(jsonEncodedTokenUrl)) +
            ';' +
            encodeURI(window.location.origin)
        )
      );
    },
    [authFormData]
  );

  const authPopupWindowPromise = useCallback(
    (authUrl: string, propertyKey: string) => {
      return new Promise(
        (resolve: (value: ProxyResponseInterface) => void, reject) => {
          const messageEventHandler = (event: MessageEvent) => {
            window.removeEventListener('message', messageEventHandler, false);
            const callbackUrl = new URL(
              portalSettings.codegenApiRoutes.oauth2Callback
            );
            if (event.origin === callbackUrl.origin) {
              resolve(event.data);
            }
          };

          authPopupWindow.current = window.open(
            authUrl,
            '_blank',
            'width=800,height=600,status=0,toolbar=0'
          );

          if (authPopupWindow.current === null) {
            reject({
              IsCalled: false,
            });
            return;
          }

          // capture close event of cross browser popup
          const timer = setInterval(() => {
            const oAuthModelRef = authModelContextRef.current?.[
              propertyKey
            ] as {
              OAuthToken: AuthTokenDef;
            };

            const errorRef = authErrorRef.current;

            if (authPopupWindow.current && authPopupWindow.current.closed) {
              if (errorRef) {
                setAuthLoadingState(propertyKey, {
                  isAuthorizing: false,
                  isAuthorized: false,
                  notification: {
                    show: true,
                    message:
                      'Authorization failed: no accessCode received from the server. Please try again.',
                    type: 'error',
                  },
                  errorDetails: errorRef,
                });
              } else if (!oAuthModelRef?.OAuthToken) {
                setAuthLoadingState(propertyKey, {
                  isAuthorizing: false,
                  isAuthorized: false,
                });
              }
              clearInterval(timer);
            }
          }, 1000);

          window.addEventListener('message', messageEventHandler, false);
        }
      );
    },
    [portalSettings.codegenApiRoutes.oauth2Callback, setAuthLoadingState]
  );

  const onResetAuthClick = useCallback(
    (propertyKey: string) => {
      setAuthLoadingState(propertyKey, {
        isAuthorizing: false,
        isAuthorized: false,
        notification: {
          show: false,
          message: '',
          type: 'default',
        },
        errorDetails: null,
        tokenTime: null,
      });

      authModelContextRef.current = undefined;
      authErrorRef.current = undefined;

      const authPropertyKey = dataModel?.auth?.[propertyKey] as object;

      const updatedModel = {
        [propertyKey]: { ...authPropertyKey, OAuthToken: {} },
      };

      updateAuthDataModel({
        ...dataModelContext,
        dataModel: {
          ...dataModel,
          auth: {
            ...dataModel.auth,
            ...updatedModel,
          },
        },
        authDataModel: {
          ...authDataModel,
          ...updatedModel,
        },
      });
      const hasCaseWrapping = checkCaseWrapping(
        authFormData?.authGroup as JSONValue
      );

      if (hasCaseWrapping && RequiredAuths) {
        const updatedFormWrapper = setUnWrapedFormData(
          authFormData,
          updatedModel
        );
        setFormData(updatedFormWrapper);
      }
    },
    [
      RequiredAuths,
      authDataModel,
      authFormData,
      dataModel,
      dataModelContext,
      setAuthLoadingState,
      setUnWrapedFormData,
      updateAuthDataModel,
    ]
  );

  const onCancelAuthClick = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      propertyKey: string
    ) => {
      e.preventDefault();
      setAuthLoadingState(propertyKey, {
        isAuthorizing: false,
        isAuthorized: false,
      });
      authModelContextRef.current = undefined;
      authErrorRef.current = undefined;

      if (lastAuthCallPromise.current && authPopupWindow.current) {
        authPopupWindow.current.close();
        lastAuthCallPromise.current.cancel();
      }
    },
    [setAuthLoadingState]
  );

  const onAuthorizeClick = useCallback(
    (propertyKey: string) => {
      try {
        if (dataModel) {
          if (lastAuthCallPromise) {
            lastAuthCallPromise?.current?.cancel();
          }

          if (apiAuthentication?.[propertyKey]) {
            lastAuthCallPromise.current = makeCancellable(
              Promise.all([
                liquidInstance.render(
                  apiAuthentication[propertyKey].AuthorizationUrlTemplate,
                  dataModel
                ),
                liquidInstance.render(
                  apiAuthentication[propertyKey].AccessTokenUrlTemplate,
                  dataModel
                ),
              ]).then(([jsonEncodedAuthUrl, jsonEncodedTokenUrl]) => {
                const callbackUrlEncoded = encodeURI(
                  portalSettings.codegenApiRoutes.oauth2Callback
                );
                const authUrl = getAuthUrl(
                  callbackUrlEncoded,
                  jsonEncodedAuthUrl,
                  jsonEncodedTokenUrl,
                  propertyKey
                );
                return authPopupWindowPromise(authUrl, propertyKey);
              })
            );
          } else {
            const lang = Object.keys(Templates)[0];

            const endpointCall = callEndpoint(
              EndpointName,
              EndpointGroupName,
              Index,
              dataModel,
              authHttpCallTemplate?.[propertyKey],
              portalSettings,
              apiDigest,
              lang,
              liquidInstance,
              true
            );

            lastAuthCallPromise.current = makeCancellable(endpointCall);
          }

          setAuthLoadingState(propertyKey, {
            isAuthorizing: true,
            isAuthorized: false,
          });

          lastAuthCallPromise.current.promise
            .then((response) => {
              if (
                response.IsCalled &&
                response.StatusCode >= 200 &&
                response.StatusCode < 300
              ) {
                const decodedBody = Base64.decode(response.RawContent);
                const authResponse = authTokenValidator(
                  JSON.parse(decodedBody)
                );
                if (
                  Object.prototype.hasOwnProperty.call(
                    authResponse,
                    'access_token'
                  )
                ) {
                  const authPropertyKey = dataModel?.auth?.[
                    propertyKey
                  ] as object;
                  const updatedModel = {
                    [propertyKey]: {
                      ...authPropertyKey,
                      OAuthToken: authResponse,
                    },
                  };

                  updateAuthDataModel({
                    ...dataModelContext,
                    dataModel: {
                      ...dataModel,
                      auth: {
                        ...dataModel.auth,
                        ...updatedModel,
                      },
                    },
                    authDataModel: {
                      ...authDataModel,
                      ...updatedModel,
                    },
                  });
                  const hasCaseWrapping = checkCaseWrapping(
                    authFormData?.authGroup as JSONValue
                  );

                  if (hasCaseWrapping && RequiredAuths) {
                    const updatedFormWrapper = setUnWrapedFormData(
                      authFormData,
                      updatedModel
                    );
                    setFormData(updatedFormWrapper);
                  }

                  getTokenSuccessEvent(portalSettings);
                  // Access token expiry start time in seconds
                  localStorage.setItem(
                    'token_stamp',
                    JSON.stringify(Date.now() / 1000)
                  );

                  authModelContextRef.current = updatedModel;
                  authErrorRef.current = undefined;

                  setAuthLoadingState(propertyKey, {
                    isAuthorizing: false,
                    isAuthorized: true,
                    notification: {
                      show: true,
                      message: 'Authorization Successful.',
                      type: 'success',
                    },
                    errorDetails: null,
                    tokenTime: JSON.stringify(Date.now() / 1000),
                  });
                } else {
                  const error: OAuthError = {
                    message: authResponse.message || '',
                    statusCode: parseInt(authResponse?.code) || 0,
                    stack: authResponse.message || '',
                    isCancelled: false,
                    name: '',
                  };
                  authErrorRef.current = error;

                  setAuthLoadingState(propertyKey, {
                    isAuthorizing: false,
                    isAuthorized: false,
                    notification: {
                      show: true,
                      message:
                        'Authorization failed: no accessCode received from the server. Please try again.',
                      type: 'error',
                    },
                    errorDetails: error,
                  });
                }
              } else {
                const error: OAuthError = {
                  message:
                    response.ReasonPhrase ||
                    'Authorization failed: no accessCode received from the server',
                  statusCode: response.StatusCode || 0,
                  stack: response.ReasonPhrase,
                  isCancelled: false,
                  name: '',
                };

                authModelContextRef.current = undefined;
                authErrorRef.current = error;

                setAuthLoadingState(propertyKey, {
                  isAuthorizing: false,
                  isAuthorized: false,
                  notification: {
                    show: true,
                    message:
                      'Authorization failed: no accessCode received from the server. Please try again.',
                    type: 'error',
                  },
                  errorDetails: error,
                });
              }
            })
            .catch((err?: unknown) => {
              const error = err as APICallException;
              const authError: OAuthError = {
                message: error?.message || '',
                statusCode: null,
                stack: error?.stack || '',
                isCancelled: false,
                name: '',
              };
              authErrorRef.current = authError;

              if (error && error.isCancelled) {
                return; // don't do anything if promise was cancelled
              }

              if (portalSettings.useProxyForConsoleCalls) {
                setAuthLoadingState(propertyKey, {
                  isAuthorizing: false,
                  isAuthorized: false,
                  notification: {
                    show: true,
                    message:
                      'Authorization failed: no accessCode received from the server. Please try again.',
                    type: 'error',
                  },
                  errorDetails: authError,
                });
              } else {
                setAuthLoadingState(propertyKey, {
                  isAuthorizing: false,
                  isAuthorized: false,
                  notification: {
                    show: true,
                    message:
                      'Authorization failed: no accessCode received from the server. Please try again.',
                    type: 'error',
                  },
                  errorDetails: authError,
                });
              }

              authModelContextRef.current = undefined;

              docsGetTokenOopsError(
                portalSettings,
                {
                  Templates,
                  EndpointName,
                  EndpointGroupName,
                } as CompilableCodeBlock,
                error?.message
              );
              setTag('apimaticAppReferance', 'Get Token via OAuth 2.0');
              captureException(error);
            });
        }
      } catch (e) {
        console.log({ e });
      }
    },
    [
      EndpointGroupName,
      EndpointName,
      Index,
      RequiredAuths,
      Templates,
      apiAuthentication,
      apiDigest,
      authDataModel,
      authFormData,
      authHttpCallTemplate,
      authPopupWindowPromise,
      dataModel,
      dataModelContext,
      getAuthUrl,
      liquidInstance,
      portalSettings,
      setAuthLoadingState,
      setUnWrapedFormData,
      updateAuthDataModel,
    ]
  );

  const handleGetToken = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      propertyKey: string
    ) => {
      e.preventDefault();
      portalSettings && getTokenEvent(portalSettings);
      onAuthorizeClick(propertyKey);
    },
    [onAuthorizeClick, portalSettings]
  );

  const authTokenRenderer = useCallback(
    (propertyKey: string) => {
      const oAuthState = oAuthContext?.oAuthContextState?.oAuthState;
      const authStateKey = oAuthState?.[propertyKey];
      const authNotificationType =
        authStateKey?.notification && authStateKey.notification.type;

      const authCalloutToken = dataModel?.auth?.[propertyKey] as {
        OAuthToken: AuthTokenDef;
      };

      return apiAuthentication?.[propertyKey] ||
        authHttpCallTemplate?.[propertyKey] ? (
        <>
          <AuthorizationSection>
            {authStateKey?.isAuthorized &&
              authCalloutToken &&
              authCalloutToken.OAuthToken &&
              Object.keys(authCalloutToken.OAuthToken).length !== 0 && (
                <AuthTokenCallout
                  authToken={authCalloutToken.OAuthToken}
                  onTokenExpiry={() => onResetAuthClick(propertyKey)}
                  tokenTime={authStateKey?.tokenTime || '0'}
                />
              )}
            <AuthActionItems>
              {!authStateKey?.isAuthorized && (
                <>
                  {portalSettings?.enableConsoleCalls && (
                    <MultiAuthButton
                      // TODO Do not use lambda functions in render()
                      onClick={(e) => handleGetToken(e, propertyKey)}
                      disabled={authStateKey?.isAuthorizing}
                      isError={authNotificationType === 'error'}
                      className="reset-token-button"
                    >
                      {authStateKey?.isAuthorizing ? (
                        'Getting Token...'
                      ) : (
                        <>
                          <KeyIcon /> Get Token
                        </>
                      )}
                    </MultiAuthButton>
                  )}
                  {authStateKey?.isAuthorizing && (
                    <MultiAuthButton
                      onClick={(e) => onCancelAuthClick(e, propertyKey)}
                      className="reset-token-button"
                    >
                      Cancel
                    </MultiAuthButton>
                  )}
                </>
              )}

              {authStateKey?.isAuthorized && (
                <MultiAuthButton
                  onClick={() => onResetAuthClick(propertyKey)}
                  isSuccess={authNotificationType === 'success'}
                  className="reset-token-button"
                >
                  <ResetIcon fill="#0C7FF2" />
                  Reset Token
                </MultiAuthButton>
              )}
            </AuthActionItems>
          </AuthorizationSection>
          {authStateKey?.notification && (
            <Notification
              {...authStateKey?.notification}
              fromAuthorization={true}
              errorDetails={authStateKey?.errorDetails}
            />
          )}
        </>
      ) : null;
    },
    [
      oAuthContext?.oAuthContextState?.oAuthState,
      dataModel?.auth,
      apiAuthentication,
      authHttpCallTemplate,
      portalSettings?.enableConsoleCalls,
      onResetAuthClick,
      handleGetToken,
      onCancelAuthClick,
    ]
  );

  return (
    <AuthErrorBoundary>
      <AuthorizationWrapper
        AuthDescription={AuthDescription || ''}
        portalSettings={portalSettings}
      >
        <JSchemaForm
          schema={authModelSchema}
          data={authFormData}
          onChange={updateAuthModel}
          definitions={ModelSchemas}
          authTokenRenderProps={{
            authTokenRenderer,
            authRenderState: oAuthContext?.oAuthContextState?.oAuthState || {},
            dataModelProp: dataModel,
            authDataModel: authDataModel,
            RequiredScopes: RequiredAuthScopes,
          }}
          removeViewJsonButton={true}
          portalSettings={portalSettings}
          trackEvent={trackEvent}
        />
      </AuthorizationWrapper>
    </AuthErrorBoundary>
  );
};

export function checkCaseWrapping(data: JSONValue) {
  if (
    data &&
    isObject(data) &&
    Object.prototype.hasOwnProperty.call(data, '$$__case')
  ) {
    return true;
  }
  return false;
}

export function unwrapCallModelData(formData: JSONValue = {}): JSONValue {
  let newFormData: JSONValue = JSON.parse(JSON.stringify(formData));

  if (isObject(newFormData)) {
    if (checkCaseWrapping(newFormData)) {
      newFormData = unwrapCallModelData(
        newFormData['value' as keyof typeof newFormData]
      );
    } else {
      for (const key in newFormData as JSONObject) {
        newFormData = {
          ...(newFormData as JSONObject),
          [key]: unwrapCallModelData(
            newFormData[key as keyof typeof newFormData]
          ),
        };
      }
    }
  }

  if (Array.isArray(newFormData)) {
    newFormData = newFormData.map((item) => {
      if (item && isObject(item)) {
        return unwrapCallModelData(item);
      } else {
        return item;
      }
    });
  }

  return newFormData;
}

export function isObject(thing: JSONValue) {
  return typeof thing === 'object' && thing !== null && !Array.isArray(thing);
}

const getSelectedIndex = (authformData: DataModel['auth']) => {
  const authGroupData = authformData?.authGroup as ApiGroupModel;
  return authGroupData?.$$__case || 0;
};

const filterRequiredFormData = (
  requiredAuths: string[][],
  authDataModel: DataModel['auth'],
  authFormData: DataModel['auth'],
  hasAuthGroup?: boolean
) => {
  const authSelectedIndex = hasAuthGroup ? getSelectedIndex(authFormData) : 0;
  if (requiredAuths?.length > 0) {
    const selectedAuthGroup = requiredAuths?.[authSelectedIndex];

    const filterSelected =
      Array.isArray(selectedAuthGroup) &&
      selectedAuthGroup?.reduce((final, authKey) => {
        return authDataModel?.[authKey]
          ? { ...final, [authKey]: authDataModel?.[authKey] }
          : final;
      }, {});

    return filterSelected;
  } else {
    return {};
  }
};

const hasAuthGroup = (authModelSchema: JSchema) => {
  return Object.prototype.hasOwnProperty.call(
    authModelSchema.properties,
    'authGroup'
  )
    ? true
    : false;
};

interface AuthErrorProps {
  children: React.ReactNode;
}

const AuthErrorBoundary = (props: AuthErrorProps) => {
  return (
    <SentryErrorBoundary
      fallback={
        <Callout icon="alert">
          <strong>Oops!</strong> We are having trouble showing a Multi-Auth
          console for this endpoint right now.
        </Callout>
      }
      beforeCapture={(scope, error) => {
        scope.setTag('apimaticAppReferance', 'Authentication Console');
      }}
    >
      {props.children}
    </SentryErrorBoundary>
  );
};

import { ExportFormatTemplate } from './PortalSettings';

export interface TransformerDef {
  name: string;
  value: string;
  templates: TransformerTemplateDef[];
}

export interface TransformerTemplateDef {
  value: string;
  name: string;
  extension: string;
}

/**
 * Definitions for all export formats
 */

export const TransformerConstants: TransformerDef[] = [
  {
    name: 'Postman',
    value: 'postman',
    templates: [
      {
        value: 'postman10',
        name: 'Postman Collection 1.0',
        extension: 'postman_collection.json',
      },
      {
        value: 'postman20',
        name: 'Postman Collection 2.0',
        extension: 'postman_collection.json',
      },
    ],
  },

  {
    name: 'OpenAPI',
    value: 'openApiSpec',
    templates: [
      {
        value: 'openapi31json',
        name: 'OpenAPI 3.1 (JSON)',
        extension: 'json',
      },
      {
        value: 'openapi31yaml',
        name: 'OpenAPI 3.1 (YAML)',
        extension: 'yaml',
      },
      {
        value: 'openapi3json',
        name: 'OpenAPI 3.0 (JSON)',
        extension: 'json',
      },
      {
        value: 'openapi3yaml',
        name: 'OpenAPI 3.0 (YAML)',
        extension: 'yaml',
      },
      {
        value: 'swagger20',
        name: 'OpenAPI 2.0 (JSON)',
        extension: 'json',
      },
      {
        value: 'swaggeryaml',
        name: 'OpenAPI 2.0 (YAML)',
        extension: 'yaml',
      },
      {
        value: 'swagger10',
        name: 'OpenAPI 1.2 (JSON)',
        extension: 'json',
      },
    ],
  },

  {
    name: 'RAML',
    value: 'raml',
    templates: [
      {
        value: 'raml',
        name: 'RAML 0.8',
        extension: 'raml',
      },
      {
        value: 'raml10',
        name: 'RAML 1.0',
        extension: 'raml',
      },
    ],
  },
  {
    name: 'Insomnia',
    value: 'insomiaEportFormat',
    templates: [
      {
        value: 'insomnia',
        name: 'Insomnia Export Format (JSON)',
        extension: 'json',
      },
      {
        value: 'insomniayaml',
        name: 'Insomnia Export Format (YAML)',
        extension: 'yaml',
      },
    ],
  },

  {
    name: 'API Blueprint',
    value: 'apiBlueprint',
    templates: [
      {
        value: 'apiblueprint',
        name: 'API Blueprint',
        extension: 'apib',
      },
    ],
  },

  {
    name: 'WADL',
    value: 'wadl',
    templates: [
      {
        value: 'wadl2009',
        name: 'WADL',
        extension: 'wadl',
      },
    ],
  },

  {
    name: 'APIMATIC Format',
    value: 'apimaticformat',
    templates: [
      {
        value: 'apimatic',
        name: 'APIMATIC Format',
        extension: 'json',
      },
    ],
  },

  {
    name: 'WSDL',
    value: 'wsdl',
    templates: [
      {
        value: 'wsdl',
        name: 'WSDL',
        extension: 'wsdl',
      },
    ],
  },

  {
    name: 'GraphQL Schema',
    value: 'graphqlschema',
    templates: [
      {
        value: 'graphqlschema',
        name: 'GraphQL Schema',
        extension: 'graphql',
      },
    ],
  },
];

export function filterExportFormats(
  allowedFormats: ExportFormatTemplate[]
): TransformerDef[] {
  const formats = TransformerConstants;
  return formats
    .map((m) => ({
      ...m,
      templates: m.templates.filter(
        ({ value }) =>
          allowedFormats.indexOf(value as ExportFormatTemplate) !== -1
      ),
    }))
    .filter((m) => m.templates.length !== 0);
}

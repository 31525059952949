import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ClockIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '11.192'}
    height={props.height || '11.192'}
    viewBox={props.viewBox || '0 0 11.192 11.192'}
  >
    <path
      fill={props.fill || '#606E8A'}
      fillRule="evenodd"
      /* eslint-disable max-len */
      d="M7.6,13.191A5.6,5.6,0,1,0,2,7.6,5.6,5.6,0,0,0,7.6,13.191ZM8.3,4.8a.7.7,0,0,0-1.4,0V7.6a.7.7,0,0,0,.2.494l1.978,1.979a.7.7,0,0,0,.99-.99L8.3,7.306Z"
      transform="translate(-2 -2)"
    />
  </SVGIcon>
);

interface CircleDotProps {
  isActive: boolean;
}
export const CircleDotIcon = ({ isActive }: CircleDotProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="#fff" stroke={isActive ? '#0C7FF2' : '#E2E8F0'} strokeWidth="2">
      <circle cx="12" cy="12" r="12" stroke="none" />
      <circle cx="12" cy="12" r="11" fill="none" />
    </g>
    <circle
      cx="4"
      cy="4"
      r="4"
      transform="translate(8 8)"
      fill={isActive ? '#0C7FF2' : '#E2E8F0'}
    />
  </svg>
);

import * as React from 'react';
import styled from 'styled-components';

import { InlineCode, Tbl, Div, P, Span } from './CleanSlate';
import { Markdown } from './Markdown';
import { ParamInfo } from './DxDom';
import {
  TypeWrapper,
  TableWrapper,
  ParamName,
  ParamType,
  DangerLabel,
} from './StyledElements';
import { HttpTypes } from 'src/TypesPopover';
import { Popover } from 'src/base-components';
import { getListRootItem } from './Utilities/utility';
import Tooltip from './Tooltip/Tooltip';

const RESPONSE_ONLY = 'Response Only';

const REQUEST_ONLY = 'Request Only';

const RESPONSE_ONLY_TOOLTIP =
  'This property is only part of the response. It should not be sent as part of the request';
const REQUEST_ONLY_TOOLTIP =
  'This property is only part of the request. It should not be sent as part of the response';

export const OneOfWrapper = styled(Div)`
  ${Span} {
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.C700};
    font-size: ${({ theme }) => theme.cssStyles.body.text2.fontSize};
  }
`;

export const DescriptionWrapper = styled(Div)`
  margin-top: 4px;
  margin-bottom: 4px;
  &.modal-markdown-table {
    margin-top: 10px;
  }
  & ${P} {
    margin-bottom: 6px;
  }

  & ${P}:last-child {
    margin-bottom: 0;
  }
  &.modal-container-kind {
    padding-top: 6px;
  }
`;

const ParamsTableWrapper = styled(TableWrapper)`
  ${Tbl.Table} {
    position: relative;
    width: 100%;

    & tr td:first-child ${InlineCode} {
      max-width: 280px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
    }

    & tr td:last-child {
      width: 70%;
    }
  }
`;

const TagWrapper = styled(Div)`
  display: inline-block;
`;

const TagType = styled(Span)`
  background: ${({ theme }) => theme.colors.C300};
  color: ${({ theme }) => theme.colors.C700};
  font-size: ${({ theme }) => theme.cssStyles.body.text3.fontSize};
  padding: 3px 6px;
  border-radius: 38px;
  align-items: center;
  margin-right: 6px;
  cursor: default;
  font-weight: bold;
  font-family: ${({ theme }) => theme.cssStyles.code.fontFamily};
`;

const TABLE_HEADER = '| Type | Value |';
const DISCRIMINATOR_INFO = `<br>This is the discriminator field and it has a following model/value mapping.<br> `;

const getParemDescription = (description: string) => {
  if (description.includes(TABLE_HEADER)) {
    const [tableDescription, renderTable] = description.split(TABLE_HEADER);
    return `${tableDescription} ${DISCRIMINATOR_INFO} ${TABLE_HEADER} ${renderTable}`;
  } else {
    return description;
  }
};

const getDescription = (param: ParamInfo) => {
  const containerKind =
    param.ContainerKind || getListRootItem(param.DataTypeMarkdown || '');

  const markdown = param.DataTypeMarkdown;
  if ((param.ContainerKind && param.DataType) || param.DataTypeMarkdown) {
    return (
      <OneOfWrapper>
        <Popover>
          {(props) => (
            <HttpTypes
              {...props}
              source={markdown || ''}
              label={containerKind}
              useAlwaysPortal={true}
              containerName={param.ContainerName}
              link={param.LinkTo}
              dataType={param.DataType}
              isFromRParamInfo={true}
            />
          )}
        </Popover>
      </OneOfWrapper>
    );
  } else if (param.LinkTo) {
    return (
      <Markdown
        className="model-table-link"
        source={'[`' + param.DataType + '`](' + param.LinkTo + ')'}
        inline={true}
      />
    );
  } else {
    return <ParamType>{param.DataType}</ParamType>;
  }
};

/**
 * Params info component
 * @param props
 */
const getTags = (paremInfo: ParamInfo) => {
  const responseTag = {
    tagName: RESPONSE_ONLY,
    tagToolTip: RESPONSE_ONLY_TOOLTIP,
  };

  const requestTag = {
    tagName: REQUEST_ONLY,
    tagToolTip: REQUEST_ONLY_TOOLTIP,
  };

  let readWriteTags = null;

  if (paremInfo.ReadOnly) {
    readWriteTags = responseTag;
  } else if (paremInfo.WriteOnly) {
    readWriteTags = requestTag;
  }

  return readWriteTags;
};

export function RParamInfo({ params }: { params: ReadonlyArray<ParamInfo> }) {
  return (
    <ParamsTableWrapper overflowX={true} overflowY={true}>
      <Tbl.Table>
        <Tbl.Thead>
          <Tbl.Tr>
            <Tbl.Th>Name</Tbl.Th>
            <Tbl.Th>Description</Tbl.Th>
          </Tbl.Tr>
        </Tbl.Thead>
        <Tbl.Tbody>
          {params.map((param, i) => {
            const readWriteTags = getTags(param);

            const paramTypeName = param.ParamType
              ? `This is a ${param.ParamType.toLowerCase()} parameter.`
              : '';

            if (param.GenericName === '$$__additionalProperties') {
              return null;
            }

            return (
              <Tbl.Tr key={`paramTable-${i}`}>
                <Tbl.Td>
                  <ParamName title={param.Name}>{param.Name}</ParamName>
                  <Div>
                    {param.Required && (
                      <DangerLabel className="required">REQUIRED</DangerLabel>
                    )}
                  </Div>
                </Tbl.Td>
                <Tbl.Td>
                  <TypeWrapper>
                    {getDescription(param)}
                    {(param.ParamType || param.Constant) && (
                      <React.Fragment>
                        {param.ParamType && (
                          <ParamType title={paramTypeName} marginLeft={'10px'}>
                            {param.ParamType}
                          </ParamType>
                        )}
                        {param.Constant && (
                          <ParamType marginLeft={'10px'}>CONSTANT</ParamType>
                        )}
                      </React.Fragment>
                    )}
                  </TypeWrapper>

                  {readWriteTags && (
                    <TagWrapper>
                      <Tooltip
                        text={readWriteTags.tagToolTip}
                        parentRefranceId="app-layout-docs-container"
                      >
                        <TagType>{readWriteTags.tagName}</TagType>
                      </Tooltip>
                    </TagWrapper>
                  )}

                  {param.Description && param.Description !== '-' && (
                    <DescriptionWrapper
                      className={
                        param.Description.includes('| Type | Value |')
                          ? 'modal-markdown-table'
                          : param.ContainerKind && param.DataType
                          ? 'modal-container-kind'
                          : ''
                      }
                    >
                      <Markdown
                        source={
                          param.Description
                            ? getParemDescription(param.Description).replace(
                                /<br>/gi,
                                '   \n'
                              )
                            : param.Description
                        }
                      />
                    </DescriptionWrapper>
                  )}
                </Tbl.Td>
              </Tbl.Tr>
            );
          })}
        </Tbl.Tbody>
      </Tbl.Table>
    </ParamsTableWrapper>
  );
}

import { useState, useEffect, useCallback, RefObject } from 'react';

export interface Dimensions {
  width: number;
  height: number;
}

export const useDimensions = (ref?: RefObject<HTMLElement>): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    if (ref?.current) {
      const { offsetWidth, offsetHeight } = ref.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Initial dimensions
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return dimensions;
};

import { Svg } from '../theme';

export type ThumbsUpIconProps = {
  isActive: boolean;
  backgroundColor?: string;
  stroke?: string;
};

export const ThumbsUpIcon = ({
  isActive,
  backgroundColor,
  stroke,
}: ThumbsUpIconProps) => {
  return isActive ? (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.5001 4.90008L11.6667 8.33342H16.5251C16.7838 8.33342 17.039 8.39366 17.2704 8.50937C17.5019 8.62508 17.7032 8.79309 17.8584 9.00008C18.0137 9.20708 18.1186 9.44737 18.1649 9.70194C18.2111 9.95651 18.1975 10.2184 18.1251 10.4667L16.1834 17.1334C16.0824 17.4796 15.8719 17.7837 15.5834 18.0001C15.2949 18.2165 14.944 18.3334 14.5834 18.3334H3.33341C2.89139 18.3334 2.46746 18.1578 2.1549 17.8453C1.84234 17.5327 1.66675 17.1088 1.66675 16.6667V10.0001C1.66675 9.55805 1.84234 9.13413 2.1549 8.82157C2.46746 8.50901 2.89139 8.33342 3.33341 8.33342H5.63341C5.94348 8.33325 6.24736 8.24659 6.51088 8.08318C6.77439 7.91977 6.9871 7.68609 7.12508 7.40842L10.0001 1.66675C10.3931 1.67161 10.7799 1.76522 11.1316 1.94058C11.4833 2.11593 11.7909 2.3685 12.0313 2.67942C12.2716 2.99033 12.4387 3.35155 12.5199 3.73608C12.601 4.12062 12.5943 4.51853 12.5001 4.90008Z"
        fill={stroke ?? '#858BA5'}
        stroke={stroke ?? '#858BA5'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8335 8.33325V18.3333"
        stroke={backgroundColor ?? 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  ) : (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.83325 8.33337V18.3334"
        stroke={stroke ?? '#858BA5'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5001 4.89996L11.6667 8.33329H16.5251C16.7838 8.33329 17.039 8.39353 17.2704 8.50925C17.5019 8.62496 17.7032 8.79297 17.8584 8.99996C18.0137 9.20695 18.1186 9.44725 18.1649 9.70182C18.2111 9.95639 18.1975 10.2182 18.1251 10.4666L16.1834 17.1333C16.0824 17.4795 15.8719 17.7836 15.5834 18C15.2949 18.2163 14.944 18.3333 14.5834 18.3333H3.33341C2.89139 18.3333 2.46746 18.1577 2.1549 17.8451C1.84234 17.5326 1.66675 17.1087 1.66675 16.6666V9.99996C1.66675 9.55793 1.84234 9.13401 2.1549 8.82145C2.46746 8.50889 2.89139 8.33329 3.33341 8.33329H5.63341C5.94348 8.33313 6.24736 8.24647 6.51088 8.08306C6.77439 7.91965 6.9871 7.68597 7.12508 7.40829L10.0001 1.66663C10.3931 1.67149 10.7799 1.7651 11.1316 1.94046C11.4833 2.11581 11.7909 2.36838 12.0313 2.6793C12.2716 2.99021 12.4387 3.35143 12.5199 3.73596C12.601 4.1205 12.5943 4.51841 12.5001 4.89996V4.89996Z"
        stroke={stroke ?? '#858BA5'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

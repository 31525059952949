import { InlineCode, Tbl, Div, P } from './CleanSlate';
import { ContainerType } from './DxDom';
import { ParamType, TableWrapper } from './StyledElements';
import styled from 'styled-components';
import { Markdown } from './Markdown';

export const DescriptionWrapper = styled(Div)`
  margin-top: 4px;
  margin-bottom: 4px;

  & ${P} {
    margin-bottom: 6px;
  }

  & ${P}:last-child {
    margin-bottom: 0;
  }
`;

const ContainerTableWrapper = styled(TableWrapper)`
  ${Tbl.Table} {
    width: 100%;

    & code {
      padding: 0;
    }

    & tr td:first-child ${InlineCode} {
      max-width: 280px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
    }

    & tr td:last-child {
      width: 70%;
    }
  }
`;

export function RContainerTable({
  params,
}: {
  params: ReadonlyArray<ContainerType>;
}) {
  return (
    <ContainerTableWrapper overflowX={true} overflowY={true}>
      <Tbl.Table>
        <Tbl.Thead>
          <Tbl.Tr>
            <Tbl.Th>Type</Tbl.Th>
            <Tbl.Th>Factory Method</Tbl.Th>
          </Tbl.Tr>
        </Tbl.Thead>
        <Tbl.Tbody>
          {params.map((param, i) => (
            <Tbl.Tr key={i}>
              <Tbl.Td>
                <Markdown
                  className="model-table-link"
                  source={
                    param.LinkTo
                      ? `[${param.DataType}](${param.LinkTo})`
                      : `**${param.DataType}**`
                  }
                  inline={true}
                />
              </Tbl.Td>
              <Tbl.Td>
                <ParamType>{param.Method}</ParamType>
              </Tbl.Td>
            </Tbl.Tr>
          ))}
        </Tbl.Tbody>
      </Tbl.Table>
    </ContainerTableWrapper>
  );
}

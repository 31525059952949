import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface TitlePortalProps {
  children: React.ReactNode;
}

export class TitlePortal extends React.Component<TitlePortalProps> {
  private el: HTMLDivElement;
  private titleRoot?: HTMLDivElement;

  constructor(props: TitlePortalProps) {
    super(props);
    this.el = document.createElement('div');
    this.setTitleRoot();
  }

  setTitleRoot() {
    this.titleRoot = document.getElementById('title-wrapper') as HTMLDivElement;
  }
  // TODO: remove this file completely
  componentDidMount() {
    this.setTitleRoot();
    if (this.titleRoot) {
      this.titleRoot.appendChild(this.el);
    }
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

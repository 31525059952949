import { Language, LanguageSettings } from './PortalSettings';
import { getHashId } from './LinkMapperContext';

export interface PlatformConstantDef {
  name: string;
  title: string;
  iconKey: string;
  noSDK?: boolean;
  stabilityLevelTag?: 'alpha' | 'beta';
  templates: TemplateDef[];
}

export interface TemplateDef {
  name: string;
  value: string;
  svalue: Language;
}

/**
 * All supported language and template definitions
 */
export const PlatformConstants: PlatformConstantDef[] = [
  {
    name: 'HTTP',
    title: 'HTTP',
    iconKey: 'globe',
    noSDK: true,
    templates: [
      {
        name: 'HTTP',
        value: 'HTTP_CURL_V1',
        svalue: 'http_curl_v1',
      },
    ],
  },
  {
    name: '.NET',
    title: '.NET',
    iconKey: 'windows',
    templates: [
      {
        name: '.Net Standard Library',
        value: 'CS_NET_STANDARD_LIB',
        svalue: 'cs_net_standard_lib',
      },
      {
        name: 'Universal Windows Platform',
        value: 'CS_UNIVERSAL_WINDOWS_PLATFORM_LIB',
        svalue: 'cs_universal_windows_platform_lib',
      },
      {
        name: 'Portable Class Library',
        value: 'CS_PORTABLE_NET_LIB',
        svalue: 'cs_portable_net_lib',
      },
    ],
  },
  {
    name: 'Android',
    title: 'Android',
    iconKey: 'android',
    templates: [
      {
        name: 'Android',
        value: 'JAVA_GRADLE_ANDROID_LIB',
        svalue: 'java_gradle_android_lib',
      },
    ],
  },
  {
    name: 'iOS',
    title: 'iOS',
    iconKey: 'apple',
    templates: [
      {
        name: 'Objective-C',
        value: 'OBJC_COCOA_TOUCH_IOS_LIB',
        svalue: 'objc_cocoa_touch_ios_lib',
      },
    ],
  },
  {
    name: 'Java',
    iconKey: 'java',
    title: 'Java',
    templates: [
      {
        name: 'Java',
        value: 'JAVA_ECLIPSE_JRE_LIB',
        svalue: 'java_eclipse_jre_lib',
      },
    ],
  },
  {
    name: 'PHP',
    title: 'PHP',
    iconKey: 'php',
    templates: [
      {
        name: 'PHP',
        value: 'PHP_GENERIC_LIB_V2',
        svalue: 'php_generic_lib_v2',
      },
    ],
  },
  {
    name: 'Python',
    title: 'Python',
    iconKey: 'python',
    templates: [
      {
        name: 'Python',
        value: 'PYTHON_GENERIC_LIB',
        svalue: 'python_generic_lib',
      },
    ],
  },
  {
    name: 'Ruby',
    title: 'Ruby',
    iconKey: 'ruby',
    templates: [
      {
        name: 'Ruby',
        value: 'RUBY_GENERIC_LIB',
        svalue: 'ruby_generic_lib',
      },
    ],
  },
  {
    name: 'Angular',
    title: 'Angular',
    iconKey: 'angular',
    templates: [
      {
        name: 'AngularJS',
        value: 'ANGULAR_JAVASCRIPT_LIB',
        svalue: 'angular_javascript_lib',
      },
    ],
  },
  {
    name: 'TypeScript',
    iconKey: 'typescript',
    title: 'TypeScript',
    templates: [
      {
        name: 'TypeScript',
        value: 'TS_GENERIC_LIB',
        svalue: 'ts_generic_lib',
      },
    ],
  },
  {
    name: 'Go',
    title: 'Go',
    iconKey: 'go',
    templates: [
      {
        name: 'Go',
        value: 'GO_GENERIC_LIB',
        svalue: 'go_generic_lib',
      },
    ],
  },
];

export function sortPlatforms(
  langs: Language[],
  platforms: PlatformConstantDef[],
  languageSettings?: { [language in Language]?: LanguageSettings }
): PlatformConstantDef[] {
  const unsortedPlatforms = platforms;
  const sortedPlatforms: PlatformConstantDef[] = [];
  langs.forEach((l) =>
    unsortedPlatforms.forEach((p, i) => {
      if (p.templates.some((t) => l === t.svalue)) {
        if (languageSettings) {
          const languageTags = languageSettings?.[l];
          const hasStabilityLevelTag =
            languageTags?.stabilityLevelTag === 'alpha' ||
            languageTags?.stabilityLevelTag === 'beta';
          const updatedPlatform = hasStabilityLevelTag
            ? {
                ...p,
                stabilityLevelTag: languageTags?.stabilityLevelTag,
              }
            : p;
          sortedPlatforms.push(updatedPlatform);
        } else {
          sortedPlatforms.push(p);
        }

        unsortedPlatforms.splice(i, 1);
      }
    })
  );
  return sortedPlatforms;
}

/**
 * Get platform definition from language
 * @param langs Language to search for
 */
export function filterPlatforms(langs: Language[]): PlatformConstantDef[] {
  // TODO: Remove unnecessary variable
  const Platforms = PlatformConstants;
  return Platforms.map((m) => ({
    ...m,
    templates: m.templates.filter((t) => langs.indexOf(t.svalue) !== -1),
  })).filter((m) => m.templates.length !== 0);
}

/**
 * Get language identifier to be used in URL
 * @param lang Language
 */
export function getUrlFriendlyName(lang: Language) {
  return getHashId(filterPlatforms([lang])[0].templates[0].name);
}

export const generateGUID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function extractQuestions(welcomeMessage: string): {
  cleanWelcomeMessage: string;
  questions: string[];
} {
  const questionsRegex = /[*-]\s*`([^`]+)`/g;
  const extractedQuestions: string[] = [];
  const cleanWelcomeMessage = welcomeMessage
    ?.replace(questionsRegex, (match, question) => {
      extractedQuestions.push(question.trim());
      return '';
    })
    .trim();

  return { cleanWelcomeMessage, questions: extractedQuestions };
}

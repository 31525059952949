import styled, { keyframes } from 'styled-components';

import { Div } from './CleanSlate';

import Color from 'color';

const circleFadeDelay = keyframes`
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
`;

const SpinnerWrapper = styled(Div)`
  margin: ${(props: { size?: string; margin?: string }) =>
    props.margin || '20px 20px 18px'};
  width: ${(props: { size?: string; margin?: string }) => props.size || '30px'};
  height: ${(props: { size?: string; margin?: string }) =>
    props.size || '30px'};
  position: relative;

  & .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
SpinnerWrapper.displayName = 'SpinnerWrapper';

const SpinnerCircle = styled(Div)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(
    ${(props: { degree: string; delay: string; color?: string }) =>
      props.degree}deg
  );

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    /* background-color: ${(props) => props.theme.primaryColor}; */
    background-color: ${(props) =>
      props.color
        ? props.color
        : Color('#333333')
            .mix(Color(props.theme.primaryColor), 0.1)
            .toString()};
    border-radius: 100%;
    animation: ${circleFadeDelay} 1.2s infinite ease-in-out both;
    animation-delay: ${(props: {
      degree: string;
      delay: string;
      color?: string;
    }) => props.delay}s;
  }
`;
SpinnerCircle.displayName = 'SpinnerCircle';

/**
 * Loading Spinner component
 * @param props
 */
export function Spinner(props: {
  color?: string;
  size?: string;
  margin?: string;
}) {
  return (
    <SpinnerWrapper size={props.size} margin={props.margin}>
      <SpinnerCircle color={props.color} degree="0" delay="0" />
      <SpinnerCircle color={props.color} degree="30" delay="-1.1" />
      <SpinnerCircle color={props.color} degree="60" delay="-1" />
      <SpinnerCircle color={props.color} degree="90" delay="-0.9" />
      <SpinnerCircle color={props.color} degree="120" delay="-0.8" />
      <SpinnerCircle color={props.color} degree="150" delay="-0.7" />
      <SpinnerCircle color={props.color} degree="180" delay="-0.6" />
      <SpinnerCircle color={props.color} degree="210" delay="-0.5" />
      <SpinnerCircle color={props.color} degree="240" delay="-0.4" />
      <SpinnerCircle color={props.color} degree="270" delay="-0.3" />
      <SpinnerCircle color={props.color} degree="300" delay="-0.2" />
      <SpinnerCircle color={props.color} degree="330" delay="-0.1" />
    </SpinnerWrapper>
  );
}

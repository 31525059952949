import { Div, styled } from 'src/CleanSlate';
import { InfoIcon, AlertIcon, ErrorIcon, SuccessIcon } from 'src/Icons';

const Wrapper = styled(Div)`
  background: #ffff;
  width: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid ${({ color }) => color};
  border-radius: 6px;
  padding: 8px 14px;
  border-left: 8px solid ${({ color }) => color};
`;

const IconWrapper = styled(Div)`
  display: flex;
  width: 15px;
  flex: 0 0 auto;
  align-self: flex-start;
`;

const Message = styled(Div)`
  width: fit-content;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0px;
  color: #6b7e8f;
  font-style: italic;
  margin-left: 6px;
`;

interface CalloutProps {
  children?: React.ReactNode;
  icon?: 'info' | 'success' | 'error' | 'alert';
}

const getIconColor = (value: CalloutProps['icon']) => {
  switch (value) {
    case 'success':
      return '#50CBA7';
    case 'error':
      return '#FF4451';
    case 'alert':
      return '#FF8A70';
    default:
      return '#6B7E8F';
  }
};

export function Callout(props: CalloutProps) {
  const { children, icon = 'info' } = props;

  const renderIcon = (value: CalloutProps['icon']) => {
    switch (value) {
      case 'success':
        return (
          <SuccessIcon fill={getIconColor(value)} height="13" width="13" />
        );
      case 'error':
        return <ErrorIcon fill={getIconColor(value)} height="12" width="12" />;
      case 'alert':
        return <AlertIcon fill={getIconColor(value)} height="13" width="15" />;
      default:
        return <InfoIcon fill={getIconColor(value)} height="14" width="14" />;
    }
  };

  return (
    <Wrapper
      data-testid={`${icon}_callout`}
      className="callout-wrapper"
      color={getIconColor(icon)}
    >
      <IconWrapper>{renderIcon(icon)}</IconWrapper>
      <Message data-testid={`${icon}-message`}>{children}</Message>
    </Wrapper>
  );
}

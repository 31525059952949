import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const SearchDocumentIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '37'}
    height={props.height || '41'}
    viewBox={props.viewBox || '0 0 37 41'}
  >
    <g transform="translate(-1 -1)">
      <path
        d="M4,41H31.222a3.894,3.894,0,0,0,3.889-3.9V12.725L24.417,2H7.889A3.894,3.894,0,0,0,4,5.9v5.85"
        transform="translate(1.889)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M14,2V13.7H25.667"
        transform="translate(11.333)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M7.833,22.7A5.85,5.85,0,1,0,2,16.85,5.842,5.842,0,0,0,7.833,22.7Z"
        transform="translate(0 8.55)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M10.417,19.425,7.5,16.5"
        transform="translate(5.194 13.775)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </SVGIcon>
);

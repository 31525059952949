/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';

import cloneDeep from 'clone-deep';
import { ProxyResponseInterface } from './ResponseViewer/ProxyResponseInterface';
import { DataModel, Document, ResponseHeaders } from './DxDom';
import { makeDocumentCompat } from './DxDomCompat';
import { LinkMapper } from './LinkMapperContext';
import { executeRequestFromString } from './DxFetchInterop';
import { saveAs } from 'file-saver';
import { PortalSettings } from './PortalSettings';
import {
  docsLoadedEvent,
  docsFailureEvent,
  sdkDownloadSuccessEvent,
  sdkDownloadFailureEvent,
  legacyProxyFailOverEvent,
} from './Analytics';
import { fetchRetry } from './Utilities/FetchRetry';

import { HTTP_STATUS_CODES } from 'src/constants/HttpConstants';

import { memoize } from 'lodash';
import { LiquidJS } from '@dx-portal/utils-liquid';
import { tryGetFilenameFromContentDisposition } from './HttpUtils';

interface ClientCredentialsTokenConfigDef {
  apiKey: string;
  checksum: string;
  environment: string;
  clientId: string;
  clientSecret: string;
  apiProxy?: string;
  grantType?: string;
  descriptionUrl?: string;
  scope: Array<string>;
}

/**
 * Call API endpoint and get response
 * @param endpointName Endpoint name (exactly from API file)
 * @param endpointGroupName Endpoint Group name (exactly from API file)
 * @param endpointIndex Index number of endpoint object in SDL
 * @param callData Call model from console
 * @param httpCallTemplate Call template which is deserialized into InteropHttpRequest structure
 * @param apiKey API key from APIMatic
 * @param useProxy Use proxy to call endpoint?
 */
export async function callEndpoint(
  endpointName: string,
  endpointGroupName: string,
  endpointIndex: number,
  callData: DataModel,
  httpCallTemplate: string,
  portalSettings: PortalSettings,
  apiDigest: string,
  template: string,
  liquidInstance: LiquidJS,
  isGetToken: boolean
): Promise<ProxyResponseInterface> {
  const {
    useProxyForConsoleCalls,
    codegenApiRoutes: { apiProxy2 },
  } = portalSettings;

  if (useProxyForConsoleCalls) {
    try {
      // Make call via Proxy2
      return await callEndpointViaFetch(
        callData,
        httpCallTemplate,
        true,
        apiProxy2,
        liquidInstance
      );
    } catch (e: unknown) {
      // replacing e.Error with e.message
      // some cases are not handled through this condiition.
      // releasing it because of the deadline, need to fix it proper.
      Sentry.captureException(e);
      if ((e as Error).message || (e as { Error: unknown }).Error) {
        // If portal fails to build a request for Proxy2, we failover to using
        // the legacy proxy. This failover needs to be removed once we've tested
        // the new proxy for most of the customers.

        // Register event for failover.
        legacyProxyFailOverEvent(
          portalSettings,
          template,
          endpointName,
          endpointGroupName
        );

        const config = callData?.config;

        // Make the API call to legacy proxy.
        return isGetToken
          ? getClientCredentialsTokenViaProxy({
              apiKey: portalSettings.apiKey,
              checksum: apiDigest,
              clientId: (config?.OAuthClientId || '').toString(),
              clientSecret: (config?.OAuthClientSecret || '').toString(),
              environment: callData.environment,
              scope: (config?.OAuthScopes as Array<string>) || [],
            })
          : callEndpointViaProxy(
              endpointName,
              endpointGroupName,
              endpointIndex,
              callData,
              portalSettings,
              apiDigest
            );
      } else {
        // Rethrow error if it was not related to an unhandled bug.
        throw e;
      }
    }
  } else {
    return callEndpointViaFetch(
      callData,
      httpCallTemplate,
      false,
      apiProxy2,
      liquidInstance
    );
  }
}

/**
 * Call console proxy (which calls the real API endpoint) and get response
 * @param endpointName Endpoint name (exactly from API file)
 * @param endpointGroupName Endpoint Group name (exactly from API file)
 * @param callData Call model from console
 * @param apiKey API key from APIMatic
 */
function callEndpointViaProxy(
  endpointName: string,
  endpointGroupName: string,
  endpointIndex: number,
  callData: DataModel,
  portalSettings: PortalSettings,
  apiDigest: string
): Promise<ProxyResponseInterface> {
  return fetch(portalSettings.codegenApiRoutes.apiProxy, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      apiKey: portalSettings.apiKey,
      authTypeV2ClientCreds: false,
      checksum: apiDigest,
      endpointName: endpointName,
      endpointGroupName: endpointGroupName,
      index: endpointIndex,
      Data: callData,
      descriptionUrl: 'https://www.apimatic.io',
      encodeBody: true,
    }),
  })
    .then(function (response: Response) {
      const contentType = response.headers.get('content-type');
      // If call success
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      }

      // If call failed with json response
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then((data) =>
          Promise.reject({
            ...data,
            StatusCode: response.status,
            IsCalled: false,
          })
        );
      }

      // If call failed with text response
      return response.text().then(() =>
        Promise.reject({
          StatusCode: response.status,
          IsCalled: false,
        })
      );
    })
    .then(function (response: Response) {
      return response.json();
    })
    .catch((e) => {
      if (!e.StatusCode) {
        Sentry.setTag('apimaticAppReferance', 'Console Call via Proxy');

        Sentry.captureException(e);
      }
    });
}

/**
 * Call console proxy for getting OAuth 2.0 client credentials token
 * @param config Payload for token proxy
 * @returns Promise<ProxyResponseInterface>
 */
export function getClientCredentialsTokenViaProxy(
  config: ClientCredentialsTokenConfigDef
): Promise<ProxyResponseInterface> {
  const {
    apiKey,
    environment,
    clientSecret,
    clientId,
    scope,
    checksum,
    grantType = 'client_credentials',
    descriptionUrl = 'https://www.apimatic.io',
    apiProxy = 'https://proxy.apimatic.io/api/oauth/token',
  } = config;

  return fetch(apiProxy, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      apiKey,
      checksum,
      descriptionUrl,
      environment,
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: grantType,
      scope,
    }),
  })
    .then(function (response: Response) {
      if (
        response.status >= HTTP_STATUS_CODES.OK &&
        response.status < HTTP_STATUS_CODES.MULTIPLE_CHOICES
      ) {
        return Promise.resolve(response);
      } else {
        const contentType = response.headers.get('content-type');
        // If call success
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response);
        }

        // If call failed with json response
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json().then((data) =>
            Promise.reject({
              ...data,
              StatusCode: response.status,
              IsCalled: false,
            })
          );
        }

        // If call failed with text response
        return response.text().then(() =>
          Promise.reject({
            StatusCode: response.status,
            IsCalled: false,
          })
        );
      }
    })
    .then(function (response: Response) {
      return response.json();
    });
}

/**
 * Call API endpoint and get response
 * @param callData Call model from console
 * @param httpCallTemplate Call template which is deserialized into InteropHttpRequest structure
 */
export function callEndpointViaFetch(
  callData: DataModel,
  httpCallTemplate: string,
  useProxy: boolean,
  proxy2Url: string,
  liquidInstance: LiquidJS
): Promise<ProxyResponseInterface> {
  try {
    const clonedCallData = cloneDeep(callData);
    return liquidInstance
      .render(httpCallTemplate, clonedCallData)
      .then((requestString) => {
        return executeRequestFromString(
          requestString,
          useProxy,
          proxy2Url,
          callData.skipSslCertVerification
        );
      });
  } catch (error) {
    Sentry.setTag('apimaticAppReferance', 'Console Call via Browser');

    Sentry.captureException(error);
    return Promise.reject({
      ...(error as Error),
    });
  }
}

/**
 * Get SDK document from API.
 *
 * SDK documents will be cached by the tpl name.
 * @param tpl Language/template to load doc for
 * @param apiKey API key from APIMatic
 * @param baseUrl Baseurl for APIMatic's docs-gen API
 */
export const getSdkDocument = memoize(
  (
    tpl: string,
    portalSettings: PortalSettings
  ): Promise<[Document, LinkMapper]> => {
    return fetchRetry(
      portalSettings.codegenApiRoutes.docsgen
        .replace('{apikey}', portalSettings.apiKey)
        .replace('{template}', tpl),
      [500],
      3,
      1,
      {
        headers: {
          Accept: 'application/vnd.apimatic.dxDom.v3+json',
        },
      }
    ).then((resp: Response) => {
      if (resp.status >= 200 && resp.status < 300) {
        docsLoadedEvent(portalSettings, tpl, resp.status, resp.statusText);
      } else {
        docsFailureEvent(
          portalSettings,
          tpl,
          resp.status,
          resp.statusText,
          resp.url
        );
      }

      return resp
        .json()
        .then((json: Document) => {
          const apiDigest = resp.headers.get('x-api-description-digest');

          const t = {
            responseHeaders: {
              apiDigest: apiDigest ? apiDigest : 'wowowowow1',
            },
            doc: json,
          };
          return t;
        })
        .then((x: { doc: Document; responseHeaders: ResponseHeaders }) =>
          makeDocumentCompat(
            x.doc,
            tpl,
            x.responseHeaders,
            portalSettings.routeStyle === 'browser'
          )
        )
        .catch((e: Error) => {
          // evict failed requests from cache so that they can be retried later
          getSdkDocument.cache.delete(tpl);
          return Promise.reject({ ...e, statusCode: resp.status });
        });
    });
  }
);
/**
 * Trigger download SDK
 */
export async function downloadSdk(tpl: string, portalSettings: PortalSettings) {
  const url = portalSettings.codegenApiRoutes.codegen
    .replace('{template}', tpl)
    .replace('{apikey}', portalSettings.apiKey);

  const headers = {
    // eslint-disable-next-line camelcase
    sdk_generation: 'SDKGenerated_WIDGET',
  };

  // make HTTP call
  const response = await fetch(url, { headers });

  // reject if response not ok
  if (response.status !== 200) {
    sdkDownloadFailureEvent(
      portalSettings,
      tpl,
      response.status,
      response.statusText,
      url
    );
    return Promise.reject();
  }
  sdkDownloadSuccessEvent(
    portalSettings,
    tpl,
    response.status,
    response.statusText,
    url
  );
  // trigger download dialog
  const contentHeader = response.headers.get('content-disposition');
  const name = makeFileNameForSdkDownload(contentHeader, url, tpl);
  saveAs(await response.blob(), name);

  return response;
}

export function makeFileNameForSdkDownload(
  contentHeader: string | null,
  url: string,
  template: string
) {
  return (
    tryGetFilenameFromContentDisposition(contentHeader) ||
    tryGetZipFilenameFromUrl(url) ||
    template
  );
}

function tryGetZipFilenameFromUrl(url: string) {
  return url.endsWith('.zip') || url.endsWith('.ZIP')
    ? getFilenameFromUrl(url)
    : undefined;
}

function getFilenameFromUrl(url: string) {
  return url.substring(url.lastIndexOf('/') + 1);
}

/**
 * Create a link for exporting API description
 * @param format API description format
 * @param apiKey API key from APIMatic
 * @param baseUrl Baseurl for APIMatic's docs-gen API
 */
export function makeApiFileExportLink(
  format: string,
  extension: string,
  portalSettings: PortalSettings
) {
  return portalSettings.codegenApiRoutes.transform
    .replace('{format}', format)
    .replace('{apikey}', portalSettings.apiKey)
    .replace('{ext}', extension);
}

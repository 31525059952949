/**
 * This files register the public interface for the portal on the global window
 * object. The portal settings passed by the user will be validated and if the
 * validation fails, the portal will not render and an error will be registered
 * in the console.
 */

// Polyfill imports should be the first thing in this file.
import 'react-app-polyfill/stable';

// Rest of the imports go here...
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import AJV from 'ajv5';

import { App } from './App';
import { validatePortalSettings, PortalSettings } from './PortalSettings';
import { PlatformConstants } from './PlatformConstants';
import { defaultFontSource } from './Theme/Theme';
import {
  FireReady,
  FireReadyArgs,
  FireReadyCallback,
  ReadyArgs,
  RegisterWorkflow,
  SetConfig,
} from './Context/EventsContext';
import { removeNullorEmptyValues } from './Utilities/utility';
import { DataModel, JSchema } from './DxDom';
import { WorkflowState } from './Context/WorkflowContext';
import ReactDOM from 'react-dom';

// Add APIMaticDevPortal to window interface
declare global {
  interface Window {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    APIMaticDevPortal: any;
    analytics?: any;
    clarity?: any;
    [key: string]: any;
  }
}

const ajv = new AJV({
  errorDataPath: 'property',
  allErrors: true,
});

/**
 * Initialize Sentry for error logging in the app
 *
 * Sentry will not be initialized if enableAnalytics is set to false
 * in the portal settings passed.
 *
 * @param param0 Portal settings
 */
function loadSentry({ enableAnalytics }: PortalSettings) {
  if (process.env.NODE_ENV === 'production' && enableAnalytics) {
    Sentry.init({
      dsn: 'https://b2e200a9ed2e46f9877639309dde96b5@o1327799.ingest.sentry.io/6589502',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.debugIntegration(),
      ],
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.NODE_ENV,
      beforeSend: (event: Sentry.ErrorEvent, hint: Sentry.EventHint) => {
        // only send error boundary events

        if (event.tags && event.tags['apimaticAppReferance']) {
          return event;
        }
        // don't send any other event
        return null;
      },
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.5,
    });
  }
}

/**
 * Load Segment's analytics script
 */
function loadAnalyticsScript() {
  const analytics = (window.analytics = window.analytics || []);
  if (analytics.initialize) return;
  if (analytics.invoked) {
    console.error('Segment snippet included twice.');
    return;
  }
  analytics.invoked = true;

  const methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ];

  analytics.factory = (method: string) => {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  methods.forEach((method) => {
    analytics[method] = analytics.factory(method);
  });

  analytics.load = function (key: string, options: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(script, firstScript);
    analytics._loadOptions = options;
  };

  analytics.SNIPPET_VERSION = '4.1.0';
  analytics.load('CDqWsMuliaD6q4bcFEa3lfmpHMUAm1ce');
}

export function loadFont(settings: PortalSettings) {
  const { fontSource = [] } = settings.themeOverrides || {};
  const newFontSource = [defaultFontSource, ...fontSource];

  if (newFontSource) {
    for (let i = 0; i < newFontSource.length; i++) {
      const font = document.createElement('Link') as HTMLLinkElement;
      font.href = newFontSource[i];
      font.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(font);
    }
  }
}

/**
 * Change anything in global context needed to be changed based on portal settings
 */
function changeGlobalSettings(settings: PortalSettings) {
  // TODO: Need to make function pure. Remove mutation if possible

  // handle renameHttpToRest flag
  if (settings.renameHttpToRest) {
    const httpPlatform = PlatformConstants.find(
      (p) => p.templates[0].value === 'HTTP_CURL_V1',
    );
    if (httpPlatform) {
      httpPlatform.name = 'REST';
      httpPlatform.title = 'REST';
      httpPlatform.templates[0].name = 'REST';
    }
  }

  //  handle renameTypeScriptToNodejs flag Renaming.
  if (settings.renameTypeScriptToNodejs) {
    const tsPlatform = PlatformConstants.find(
      (p) => p.templates[0].value === 'TS_GENERIC_LIB',
    );
    if (tsPlatform) {
      tsPlatform.name = 'Node.js';
      tsPlatform.title = 'Node.js';
      tsPlatform.templates[0].name = 'Node.js';
      tsPlatform.iconKey = 'js';
    }
  }
}

class PortalInterface {
  private readyArgs = new ReadyArgs();
  constructor() {
    const { fireReady } = this;
    let _args: FireReadyArgs;

    Object.defineProperty(this.readyArgs, 'args', {
      set(args: FireReadyArgs) {
        _args = args;
        fireReady(args);
      },
      get() {
        return _args;
      },
    });
  }
  private handlers: FireReadyCallback[] = [];

  // Setting doc state when is portal is rendered properly
  private setConfig: (args: FireReadyArgs) => SetConfig =
    ({ dataModelRef, workflowStateRef }) =>
    async (setConfigCallback) => {
      if (dataModelRef.current.dataModelContext) {
        const { dataModelSchema, dataModel, definitions, updateAuthDataModel } =
          dataModelRef.current.dataModelContext;

        const { call, ...config } = dataModel as DataModel;

        const updatedConfig = await setConfigCallback(
          removeNullorEmptyValues(config as DataModel),
        );
        let dataModelSchemaClone = dataModelSchema as JSchema;
        if (updatedConfig.auth) {
          const { AuthRoot } = definitions as { AuthRoot: JSchema };

          dataModelSchemaClone = {
            ...dataModelSchema,
            properties: {
              ...dataModelSchema?.properties,
              auth: AuthRoot,
            },
          };
        }

        const workflowState = workflowStateRef?.current.workflowState;

        const workflowSteps = workflowState?.workflowSteps;

        const selectedWorkflowStep = workflowState?.selectedWorkflowStep;

        const isCompletedStpe = Boolean(
          selectedWorkflowStep &&
            workflowSteps?.[selectedWorkflowStep].status === 'complete',
        );

        const valid = ajv.validate(
          dataModelSchemaClone as JSchema,
          updatedConfig,
        );
        if (valid) {
          const authUpdate = updatedConfig.auth
            ? {
                authDataModel: isCompletedStpe
                  ? dataModelRef.current.dataModelContext.authDataModel
                  : {
                      ...dataModelRef.current.dataModelContext.authDataModel,
                      ...updatedConfig.auth,
                    },
              }
            : {};

          updateAuthDataModel?.({
            ...dataModelRef.current.dataModelContext,
            dataModel: isCompletedStpe
              ? dataModel
              : {
                  ...dataModel,
                  ...updatedConfig,
                },
            ...authUpdate,
          });
        } else {
          const errors = ajv.errors?.map(
            (err) => `${err || 'config'} ${err.message}`,
          );
          // eslint-disable-next-line no-console
          console.error(
            'Invalid configs in ready method: \n',
            '\n',
            errors?.join('\n'),
            '\n',
          );
        }
      }
    };

  private triggerCallback = async (
    args: FireReadyArgs,
    fireReadyCallback: FireReadyCallback,
  ) => {
    const setConfig = this.setConfig(args);
    const registerWorkflow = this.registerWorkflow(args, { setConfig });
    await fireReadyCallback.call(this, { setConfig, registerWorkflow });
  };

  private registerWorkflow: (
    args: FireReadyArgs,
    portal: { setConfig: SetConfig },
  ) => RegisterWorkflow =
    (args, portal) => async (key, title, registerWorkflowCallback) => {
      const { setWorkflowState, showContent, showEndpoint, setTitles } = args;
      const workflow = await registerWorkflowCallback(
        {
          showContent,
          showEndpoint,
        },
        portal,
      );
      // Make first item selected on initial run
      const updatedWorkflow = Object.entries(workflow).reduce<
        WorkflowState[string]
      >((prev, curr, index) => {
        const [stepName, stepValue] = curr;
        if (index === 0) {
          prev[stepName] = {
            ...stepValue,
            isSelected: true,
            status: 'current',
          };
        } else {
          prev[stepName] = { ...stepValue, status: 'incomplete' };
        }

        return prev;
      }, {});
      setWorkflowState((st) => ({ ...st, [key]: updatedWorkflow }));
      setTitles((titles) => ({ ...titles, [key]: title }));
    };

  // This event triggers when portal is ready after rendering every component
  // User can change data config for the api calls.
  private fireReady: FireReady = async (args) => {
    for (const handler of this.handlers) {
      await this.triggerCallback(args, handler);
    }
  };

  private addClarityScriptToHeader = (c: Window, a: string) => {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.clarity.ms/tag/hn86bo1kwk';
    document.head.appendChild(script);
  };

  show = (settings: unknown) => {
    const validatedSettings = validatePortalSettings(settings);
    if (!validatedSettings) {
      /* eslint-disable  no-console */
      console.log(
        'Portal settings are invalid. Please check your portal configuration or contact support@apimatic.io.',
      );
      return;
    }

    if (validatedSettings.enableAnalytics) {
      this.addClarityScriptToHeader(window, 'clarity');
      loadAnalyticsScript();
    }

    changeGlobalSettings(validatedSettings);

    loadSentry(validatedSettings);
    loadFont(validatedSettings);

    const container = document.getElementById(
      validatedSettings.container,
    ) as HTMLElement;

    ReactDOM.render(
      <App
        settings={validatedSettings}
        events={{
          readyArgsInstance: this.readyArgs,
        }}
      />,
      container,
    );
  };

  ready = async (fn: FireReadyCallback) => {
    const readyArgs = this.readyArgs.get();
    if (readyArgs) {
      await this.triggerCallback(readyArgs, fn);
    }
    this.handlers.push(fn);
  };
}

// Add APIMaticDevPortal to global window
window.APIMaticDevPortal = new PortalInterface();

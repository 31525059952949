import { useState } from 'react';
import { AuthAccordianInteractionEvent } from 'src/Analytics';
import { Div, H5 } from 'src/CleanSlate';
import { ChevronIcon, OpenedLockIcon } from 'src/Icons';
import { Markdown } from 'src/Markdown';
import { PortalSettings } from 'src/PortalSettings';
import { sharedStyles } from 'src/Sidebar/SidebarSkeleton';
import styled from 'styled-components';

interface AuthorizationWrapperProps {
  isCollapsed: boolean;
}

const AuthorizationBodyWrapper = styled(Div)<AuthorizationWrapperProps>`
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.staticColors.Blue.C000};

  background-color: ${(props) => props.theme.colors.C201};

  @media screen and (max-width: 520px) {
    min-width: 520px;
  }
`;

const AuthorizationHeader = styled(Div)<{ isCollapsed: boolean }>`
  min-height: 72px;
  padding: 15px 23px ${(props) => (props.isCollapsed ? '15px' : '0px')} 25px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled(Div)`
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  cursor: pointer;
`;

const HeaderTitleAndIconWrapper = styled(Div)`
  display: flex;
  align-items: center;
`;

const IconWapper = styled(Div)`
  height: 28px;
  width: 28px;
  margin-right: 8px;
`;

const AuthenticationText = styled(H5)`
  margin: 0px;
  line-height: normal;
  color: ${(props) => props.theme.colors.C901};
  cursor: pointer;
  user-select: none;
`;

const AuthTypes = styled(Div)`
  p {
    font-family: ${(props) => props.theme.cssStyles.body.fontFamily};
    font-size: 12px;
    line-height: normal;
    color: ${(props) => props.theme.colors.C901};
    margin: 0px;
  }
`;

const ExpandableButtonIcon = styled(Div)`
  width: 18px;
  cursor: pointer;
  svg {
    transition: all 0.3s ease-in-out;
  }
`;

const AuthenticationFormWrapper = styled(Div)<{ isCollapsed: boolean }>`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  > div {
    padding: 0px;
    > form {
      > div {
        margin: 0px !important;
        padding: 0px !important;
      }
    }
  }
`;

export const AuthorizationWrapper = ({
  children,
  AuthDescription,
  portalSettings,
}: {
  children: React.ReactNode;
  AuthDescription?: string;
  portalSettings: PortalSettings;
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <AuthorizationBodyWrapper isCollapsed={isCollapsed}>
      <AuthorizationHeader isCollapsed={isCollapsed}>
        <HeaderWrapper
          onClick={() => {
            setIsCollapsed(!isCollapsed);
            AuthAccordianInteractionEvent(portalSettings, !isCollapsed);
          }}
        >
          <HeaderTitleAndIconWrapper>
            <IconWapper>
              <OpenedLockIcon />
            </IconWapper>

            <AuthenticationText>Authentication </AuthenticationText>
          </HeaderTitleAndIconWrapper>

          <ExpandableButtonIcon>
            <ChevronIcon
              style={
                isCollapsed
                  ? { transform: 'rotate(0deg)' }
                  : { transform: 'rotate(180deg)' }
              }
              viewBox="0 0 24 24"
              width="18px"
              fill="none"
              stroke="#000"
              strokeWidth="2"
            />
          </ExpandableButtonIcon>
        </HeaderWrapper>
        <AuthTypes>
          <Markdown source={AuthDescription}></Markdown>
        </AuthTypes>
      </AuthorizationHeader>
      <AuthenticationFormWrapper isCollapsed={isCollapsed}>
        {children}
      </AuthenticationFormWrapper>
    </AuthorizationBodyWrapper>
  );
};

const AuthSkeletonHeading = styled(Div)`
  ${sharedStyles}
  margin-top: 0px;
  width: 117px;
  height: 20px;
`;

const AuthSkeletonDetail = styled(Div)`
  ${sharedStyles}
  margin-top: 0px;
  width: 200px;
  height: 14px;
`;

export const AuthorizationLoadingWrapper = () => (
  <AuthorizationBodyWrapper isCollapsed={true}>
    <AuthorizationHeader isCollapsed={true}>
      <HeaderWrapper>
        <HeaderTitleAndIconWrapper>
          <IconWapper>
            <OpenedLockIcon />
          </IconWapper>
          <AuthSkeletonHeading />
        </HeaderTitleAndIconWrapper>

        <ExpandableButtonIcon>
          <ChevronIcon
            style={{ transform: 'rotate(180deg)' }}
            viewBox="0 0 24 24"
            width="18px"
            fill="none"
            stroke="#000"
            strokeWidth="2"
          />
        </ExpandableButtonIcon>
      </HeaderWrapper>
      <AuthSkeletonDetail />
    </AuthorizationHeader>
  </AuthorizationBodyWrapper>
);

import React from 'react';
import PropTypes from 'prop-types';
import { prefixClass } from '../../utils';
import { ChevronIcon } from '../Icons';
import { IconBtn } from './ObjectField';

const REQUIRED_FIELD_SYMBOL = '*';

function TitleField(props) {
  const {
    id,
    title,
    required,
    nullify,
    onNullifyChange,
    disabled,
    onClick,
    fromDiscriminator = false,
    fromObject,
    isCollapsed,
    toggleCollapse,
  } = props;
  const legend = required ? title + REQUIRED_FIELD_SYMBOL : title;
  const isRequired = required || !onNullifyChange || fromDiscriminator;

  const displayTitle =
    title === '$$__additionalProperties' ? 'Additional Properties' : title;

  return (
    <legend
      id={id}
      className={fromObject ? prefixClass(`object-legend`) : undefined}
    >
      {fromObject && (
        <IconBtn
          tabIndex="-1"
          className={prefixClass(`btn toggle-button object-toggle`)}
          onClick={toggleCollapse}
        >
          {isCollapsed ? (
            <ChevronIcon width={14} rotate={-90} />
          ) : (
            <ChevronIcon width={14} />
          )}
        </IconBtn>
      )}
      {isRequired ? null : (
        <label onClick={(ev) => ev.stopPropagation()}>
          <input
            type="checkbox"
            checked={nullify}
            className={nullify ? 'checked' : 'unchecked'}
            onChange={onNullifyChange}
            disabled={disabled}
          />
          <span />
        </label>
      )}
      <div
        className={
          isRequired ? prefixClass('no-checkbox') : prefixClass('checkbox-text')
        }
        onClick={() => (onClick ? onClick() : null)}
      >
        {displayTitle}
      </div>
    </legend>
  );
}

/* istanbul ignore else */
if (process.env.NODE_ENV !== 'production') {
  TitleField.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    nullify: PropTypes.bool,
    onNullifyChange: PropTypes.func,
  };
}

export default TitleField;

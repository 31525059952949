import styled from 'styled-components';
import { Tbl } from '../CleanSlate';

/**
 * A dark-themed striped table
 */
const StripedTable = styled(Tbl.Table)`
  margin-top: 0px;

  & thead tr th,
  & tbody tr td {
    color: #ddd;
    font-size: 12px;
    padding: 10px;

    &:nth-child(2) {
      /* These are technically the same, but use both */
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      /* This is the dangerous one in WebKit, as it breaks things wherever */
      word-break: break-all;
      /* Instead use this non-standard one: */
      word-break: break-word;

      /* Adds a hyphen where the word breaks, if supported (No Blink) */
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }

  & thead tr {
    font-weight: 500;
  }

  & > tbody > tr:nth-child(odd) > td,
  & > tbody > tr:nth-child(odd) > th {
    background-color: ${(props) => props.theme.colors.C1000};
  }
`;
StripedTable.displayName = 'StripedTable';

/**
 * HTTP Headers component
 * @param props
 */
export function HeadersTable(props: {
  data: Array<{ name: string; value: string }>;
}) {
  return (
    <StripedTable>
      <Tbl.Thead>
        <Tbl.Tr>
          <Tbl.Th>Header</Tbl.Th>
          <Tbl.Th>Value</Tbl.Th>
        </Tbl.Tr>
      </Tbl.Thead>
      <Tbl.Tbody>
        {props.data.map((d) => (
          <Tbl.Tr key={d.name}>
            <Tbl.Td>{d.name}</Tbl.Td>
            <Tbl.Td>{d.value}</Tbl.Td>
          </Tbl.Tr>
        ))}
      </Tbl.Tbody>
    </StripedTable>
  );
}

import { createRef, PureComponent } from 'react';
import styled from 'styled-components';

import { CopyIcon } from '../Icons/Ui/CopyIcon';
import { IconButton } from 'src/StyledElements';
import { Tooltip } from './Tooltip';
import { Div } from '../CleanSlate';
import { withPortalContext } from 'src/PortalContext';
import { PortalSettings } from 'src/PortalSettings';
import { codeCopiedEvent, CopyEvent } from 'src/Analytics';

import CopyToClipboard from 'react-copy-to-clipboard';
import { InstallationCommandCopyIcon } from 'src/Icons';
import { GET_SDK_BUTTON_ID } from 'src/Navbar/GetSdkPopup';

export type ClipboardSectionType =
  | 'code-sample'
  | 'response-viewer'
  | 'method-signature';

interface CopyToClipboardProps {
  children?: JSX.Element;
  text: string;
  iconButtonType: 'dark' | 'light' | 'basic';
  className?: string;
  tooltip?: {
    adjustTop: number;
    adjustLeft: number;
  };
  // use this data for analytics
  analyticsData?: {
    template: string;
    sectionType: ClipboardSectionType;
    endpointName: string;
    endpointGroupName: string;
  };
  portalSettings: PortalSettings;
  from?: string;
}

interface CopyToClipboardState {
  toggleTooltip: boolean;
  top?: string;
  left?: string;
}

export const CopyToClipboardWrapper = styled(Div)`
  position: relative;
`;

export class CopyToClipboardWithPortalSettings extends PureComponent<
  CopyToClipboardProps,
  CopyToClipboardState
> {
  private buttonRef: React.RefObject<HTMLDivElement>;

  constructor(props: CopyToClipboardProps) {
    super(props);

    this.state = {
      toggleTooltip: false,
    };

    this.showCopiedTooltip = this.showCopiedTooltip.bind(this);
    this.buttonRef = createRef();
  }

  showCopiedTooltip = () => {
    const { portalSettings, analyticsData, tooltip: tooltipProps } = this.props;

    if (analyticsData) {
      codeCopiedEvent(
        portalSettings,
        analyticsData.template,
        analyticsData.sectionType,
        analyticsData.endpointName,
        analyticsData.endpointGroupName
      );
    }

    if (this.buttonRef.current) {
      // Dimension of button when click on copy icon.
      const rect = this.buttonRef.current.getBoundingClientRect();
      const scrollY = window.scrollY;
      const scrollX = window.scrollX;
      // TODO: Temporary fix refactoring required
      const top = tooltipProps
        ? `${rect.top - tooltipProps.adjustTop + scrollY}px`
        : `${rect.top - rect.height - 5 + scrollY}px`;
      const left = tooltipProps
        ? `${rect.left - tooltipProps.adjustLeft + scrollX}px`
        : `${rect.left - rect.width / 2 + scrollX}px`;

      this.setState({
        toggleTooltip: true,
        top,
        left,
      });

      setTimeout(() => {
        this.setState((st) => ({
          ...st,
          toggleTooltip: false,
        }));
      }, 1000);
    }
  };

  render() {
    const {
      text,
      iconButtonType,
      children,
      className = 'copy-to-clip-board',
      portalSettings,
      from,
    } = this.props;

    const fromGetSdkPopup = from === GET_SDK_BUTTON_ID;

    return (
      <CopyToClipboardWrapper className={className}>
        {this.state.toggleTooltip && (
          <Tooltip
            placement="bottom"
            top={this.state.top}
            left={this.state.left}
          />
        )}
        <CopyToClipboard
          text={text}
          onCopy={() => {
            this.showCopiedTooltip();
            if (from) {
              CopyEvent(portalSettings, from);
            }
          }}
        >
          {children ? (
            <Div ref={this.buttonRef}>{children}</Div>
          ) : (
            <Div ref={this.buttonRef}>
              <IconButton
                varient={iconButtonType}
                title="Copy"
                width={fromGetSdkPopup ? '18px' : undefined}
              >
                {fromGetSdkPopup ? (
                  <InstallationCommandCopyIcon />
                ) : (
                  <CopyIcon
                    width="18"
                    height="18"
                    stroke={iconButtonType === 'dark' ? '#fff' : '#3e445d'}
                    fill="none"
                  />
                )}
              </IconButton>
            </Div>
          )}
        </CopyToClipboard>
      </CopyToClipboardWrapper>
    );
  }
}

export const CopyToClipboardComp = withPortalContext(
  CopyToClipboardWithPortalSettings
);

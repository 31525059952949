import * as React from 'react';
import { Div, Span } from 'src/CleanSlate';
import { CheckIcon } from 'src/Icons/Ui/CheckIcon';
import { WarningIcon } from 'src/Icons/Ui/WarningIcon';
import styled, { css } from 'styled-components';
import { OAuthError } from './OAuthContext';

const NotificationWrapper = styled(Div)<{
  fromAuthorization?: boolean;
  showErrorDetails?: boolean;
}>`
  border-radius: ${(props) => (props.fromAuthorization ? '7px' : '0px')};
  display: flex;
  min-height: 38px;
  padding: 7px 0px;

  &.success {
    justify-content: center;
    background-color: ${(props) => props.theme.staticColors.Unique.C300};
    border: ${(props) => (props.fromAuthorization ? '1px' : '0px')} solid
      ${(props) => props.theme.staticColors.Unique.C400};
    border-top: 1px solid ${(props) => props.theme.staticColors.Unique.C400};
  }

  &.error {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    background-color: ${(props) => props.theme.staticColors.Unique.C700};
    border: ${(props) => (props.fromAuthorization ? '1px' : '0px')} solid
      ${(props) => props.theme.staticColors.Unique.C600};
  }
`;

const NotificationContainer = styled(Div)`
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled(Div)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: flex;
  flex: 0 0 auto;

  &.success {
    margin-top: 2px;
  }
  &.error {
    margin-top: 1px;
  }
`;

const messageMixin = css`
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  user-select: none;
`;

const Message = styled(Span)`
  ${messageMixin}
  color: ${(props) => props.theme.colors.C001};
  margin: 4px 0px;
  &.success {
    color: ${(props) => props.theme.staticColors.Unique.C400};
    font-size: 12px;
  }
  &.error {
    color: ${(props) => props.theme.staticColors.Unique.C600};
  }
`;

const MoreDetails = styled(Span)`
  ${messageMixin};
  margin-left: 2px;
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.staticColors.Unique.C600};
  line-height: normal;
  display: inline-block;
  width: 75px;
`;

const ErrorContainer = styled(Div)`
  height: fit-content;
  max-height: 295px;
  overflow-y: auto;
  min-height: 85px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.C001};
  border: 1px solid ${(props) => props.theme.staticColors.Snow.C100};
  margin: 8px 0px 13px 0px;
  padding: 14px;
  color: ${(props) => props.theme.staticColors.Unique.C100};
  font-size: 11px;
  line-height: normal;
  white-space: normal;
  word-break: break-word;

  > Div {
    margin-bottom: 10px;
    color: ${(props) => props.theme.staticColors.Unique.C600};
    font-weight: 600;
    font-size: 13px;
  }
`;

export interface NotificationUiStateDef {
  message: string;
  show: boolean;
  dismissible?: boolean;
  onDismiss?: () => void;
  type: 'default' | 'success' | 'error' | 'warning';
}

interface NotificationBarProps extends NotificationUiStateDef {
  className?: string;
  fromAuthorization?: boolean;
  errorDetails?: OAuthError | null;
}

interface NotificationBarState {
  showErrorDetails: boolean;
}

export class Notification extends React.Component<
  NotificationBarProps,
  NotificationBarState
> {
  constructor(props: NotificationBarProps) {
    super(props);

    this.state = { showErrorDetails: false };
  }

  setShowErrorDetails = () => {
    this.setState({ showErrorDetails: !this.state.showErrorDetails });
  };
  notificationInterval?: NodeJS.Timeout;
  componentDidUpdate(prevProps: NotificationBarProps) {
    const { onDismiss, dismissible } = this.props;

    if (this.props !== prevProps) {
      if (dismissible) {
        this.notificationInterval = setInterval(() => {
          if (onDismiss) {
            onDismiss();
          }
          clearInterval(this.notificationInterval);
        }, 10000);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.notificationInterval);
  }

  IconWrapper = () => {
    switch (this.props.type) {
      case 'success':
        return <CheckIcon stroke="#437C3C" width="16" height="16" />;
      case 'error':
        return <WarningIcon stroke="#C9190B" />;
      default:
        return null;
    }
  };

  render() {
    const { message, type, errorDetails, fromAuthorization } = this.props;
    return (
      this.props.show && (
        <NotificationWrapper
          showErrorDetails={this.state.showErrorDetails}
          className={type}
          fromAuthorization={fromAuthorization}
        >
          <NotificationContainer>
            <IconWrapper className={type}>{this.IconWrapper()}</IconWrapper>

            <Message className={type}>
              {message}
              {type === 'error' && (
                <MoreDetails onClick={() => this.setShowErrorDetails()}>
                  {`${this.state.showErrorDetails ? 'Less' : 'More'} Details`}
                </MoreDetails>
              )}
            </Message>
          </NotificationContainer>
          {this.state.showErrorDetails && errorDetails && (
            <ErrorContainer>
              <Div>Error {errorDetails?.statusCode}</Div>
              {errorDetails?.stack || errorDetails?.message}
            </ErrorContainer>
          )}
        </NotificationWrapper>
      )
    );
  }
}

/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const HazardIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '15.412'}
    height={props.height || '13.517'}
    viewBox={props.viewBox || '0 0 15.412 13.517'}
  >
    <g transform="translate(-0.782 -2.147)">
      <path
        d="M7.352,3.536,1.73,12.923a1.328,1.328,0,0,0,1.135,1.991H14.11a1.328,1.328,0,0,0,1.135-1.991L9.623,3.536a1.328,1.328,0,0,0-2.27,0Z"
        transform="translate(0)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || '1.5'}
      />
      <line
        y2="2.655"
        transform="translate(8.487 6.948)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || '1.5'}
      />
      <line
        x2="0.007"
        transform="translate(8.487 12.259)"
        fill="none"
        stroke={props.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || '1.5'}
      />
    </g>
  </SVGIcon>
);

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const GitHubIcon = (props: SVGIconProps) => (
  <SVGIcon
    width="19.724"
    height="19.241"
    viewBox="0 0 19.724 19.241"
    fill={props.fill || '#000'}
    {...props}
  >
    <path
      /* eslint-disable max-len */
      d="M18.4,10.277a9.819,9.819,0,0,0-3.589-3.589,9.646,9.646,0,0,0-4.95-1.323,9.647,9.647,0,0,0-4.95,1.323,9.818,9.818,0,0,0-3.589,3.589A9.647,9.647,0,0,0,0,15.227a9.584,9.584,0,0,0,1.881,5.8,9.659,9.659,0,0,0,4.86,3.563.575.575,0,0,0,.514-.09.5.5,0,0,0,.167-.385q0-.039-.006-.693t-.006-1.143l-.3.051a3.768,3.768,0,0,1-.713.045,5.43,5.43,0,0,1-.892-.09,1.994,1.994,0,0,1-.86-.385,1.629,1.629,0,0,1-.565-.79l-.128-.3a3.209,3.209,0,0,0-.4-.655,1.55,1.55,0,0,0-.559-.488l-.09-.064a.943.943,0,0,1-.167-.154.7.7,0,0,1-.116-.18q-.039-.09.064-.148a.833.833,0,0,1,.372-.057l.257.038a1.874,1.874,0,0,1,.636.308,2.073,2.073,0,0,1,.623.668,2.264,2.264,0,0,0,.713.8,1.512,1.512,0,0,0,.841.276,3.652,3.652,0,0,0,.732-.064,2.554,2.554,0,0,0,.578-.193,2.081,2.081,0,0,1,.629-1.323,8.794,8.794,0,0,1-1.316-.231,5.241,5.241,0,0,1-1.207-.5,3.457,3.457,0,0,1-1.034-.86,4.134,4.134,0,0,1-.674-1.348,6.412,6.412,0,0,1-.263-1.926,3.745,3.745,0,0,1,1.015-2.645,3.452,3.452,0,0,1,.09-2.619,1.8,1.8,0,0,1,1.1.173,7.721,7.721,0,0,1,1.072.494q.34.205.546.347a9.274,9.274,0,0,1,4.931,0l.488-.308A6.911,6.911,0,0,1,14,9.589a1.683,1.683,0,0,1,1.04-.141,3.419,3.419,0,0,1,.1,2.619,3.746,3.746,0,0,1,1.015,2.645,6.483,6.483,0,0,1-.263,1.932,3.974,3.974,0,0,1-.68,1.348,3.59,3.59,0,0,1-1.04.854,5.251,5.251,0,0,1-1.207.5,8.783,8.783,0,0,1-1.316.231,2.287,2.287,0,0,1,.668,1.823v2.709a.512.512,0,0,0,.161.385.558.558,0,0,0,.507.09,9.658,9.658,0,0,0,4.861-3.563,9.586,9.586,0,0,0,1.881-5.8A9.655,9.655,0,0,0,18.4,10.277Z"
      transform="translate(0 -5.365)"
    />
  </SVGIcon>
);

/**
 * Portal context provides current portal settings to all components that need it
 */

import * as React from 'react';
import { Document as DxDocument } from './DxDom';
import { Language } from './PortalSettings';

export const DocumentContext = React.createContext<
  (DxDocument & { lang: Language }) | undefined
>(undefined);

export const DocumentContextProvider = DocumentContext.Provider;

export const DocumentContextConsumer = DocumentContext.Consumer;

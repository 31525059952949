/**
 * The following components handle routing to language and to section.
 * We intend to use these only for declaring routes; keep them UI agnostic.
 */

import { Redirect, Route, Switch } from 'react-router-dom';
import { Document as DxDocument, Section } from './DxDom';
import { LinkMapper } from './LinkMapperContext';
import { getUrlFriendlyName } from './PlatformConstants';
import { Language, PortalSettings } from './PortalSettings';
import { getFlattenedLinksSimple, filterExternalLinks } from './DxDomUtils';

/**
 * Get language for route
 */
export function RouteToLanguage(props: {
  settings: PortalSettings;
  render: (lang: Language) => JSX.Element;
}) {
  let lang: Language | undefined;
  const allowedLanguage = Object.keys(
    props.settings.languageSettings
  ) as Array<Language>;
  return (
    <Switch>
      {/* Redirect http to rest if flag for renaming is enabled */}
      {props.settings.renameHttpToRest && (
        <Route
          path="/http/:rest+"
          render={({ match }) => <Redirect to={`/rest/${match.params.rest}`} />}
        />
      )}
      {/* Redirect typescript to node-js if flag for renaming is enabled */}
      {props.settings.renameTypeScriptToNodejs ? (
        <Route
          path="/typescript/:rest+"
          render={({ match }) => (
            <Redirect to={`/node-js/${match.params.rest}`} />
          )}
        />
      ) : (
        <Route
          path="/node-js/:rest+"
          render={({ match }) => (
            <Redirect to={`/typescript/${match.params.rest}`} />
          )}
        />
      )}
      {/* Check for renamed languages */}
      <Redirect from="/php-composer-library" to="/php/" exact={true} />
      <Route
        path="/php-composer-library/:rest+"
        render={({ match }) => <Redirect to={`/php/${match.params.rest}`} />}
      />
      <Redirect from="/angular-js" to="/angularjs/" exact={true} />
      <Route
        path="/angular-js/:rest+"
        render={({ match }) => (
          <Redirect to={`/angularjs/${match.params.rest}`} />
        )}
      />
      <Redirect from="/java-maven-library" to="/java/" exact={true} />
      <Route
        path="/java-maven-library/:rest+"
        render={({ match }) => <Redirect to={`/java/${match.params.rest}`} />}
      />
      <Redirect from="/ruby-gem" to="/ruby/" exact={true} />
      <Route
        path="/ruby-gem/:rest+"
        render={({ match }) => <Redirect to={`/ruby/${match.params.rest}`} />}
      />
      {/* Map all allowed languages to routes */}
      <Route
        path={'/:lang'}
        render={({ match }) =>
          (lang = allowedLanguage.find(
            (l) => getUrlFriendlyName(l) === match.params.lang
          )) !== undefined ? (
            props.render(lang)
          ) : (
            <Redirect
              to={'/' + getUrlFriendlyName(props.settings.initialPlatform)}
            />
          )
        }
      />
      {/* Redirect incorrect language routes to initial language */}
      <Redirect to={'/' + getUrlFriendlyName(props.settings.initialPlatform)} />
    </Switch>
  );
}

/**
 * Get section for route
 */
export function RouteToSection(props: {
  lang: Language;
  doc: DxDocument;
  linkMapper: LinkMapper;
  render: (section: Section) => JSX.Element;
}) {
  return (
    <Switch>
      {/* Check for explicit request redirect to section */}
      <Route
        path={
          '/' + getUrlFriendlyName(props.lang) + '/x-redirect/:redirectPath+'
        }
        render={({ match }) => {
          // TODO: Fix URI Decoding for path with emoji and characters

          const redirectPath = match.params.redirectPath;

          /*
           * If the redirect path is a DxDom Link (e.g. icluded $e,$h, etc)
           * it replaces any spaces in `redirectPath`
           * with '%20', which is the URL encoding for a space. This ensures spaces
           * are interpreted correctly within the URL without decoading
           * else
           * It will decode the `redirectPath` and then will replace
           * '%20', which is the URL encoding for a space.
           */

          const newRedirectPath =
            redirectPath && props.linkMapper.isDxDomLink(redirectPath)
              ? redirectPath.replace(/ /g, '%20')
              : decodeURIComponent(redirectPath || '').replace(/ /g, '%20');

          return match.params.redirectPath &&
            props.linkMapper(newRedirectPath) !== newRedirectPath ? (
            <Redirect to={props.linkMapper(newRedirectPath)} />
          ) : (
            <Redirect to={'/' + getUrlFriendlyName(props.lang)} />
          );
        }}
      />
      {/* Map all sections to routes */}
      {props.doc.Sections.map((section) => (
        <Route
          key={props.linkMapper(section.SuggestedLink)}
          path={props.linkMapper(section.SuggestedLink)}
          render={() => props.render(section)}
        />
      ))}
      {/* Map all nav groups to first child */}
      {getFlattenedLinksSimple(props.doc.NavItems)
        .filter((n) => filterExternalLinks(n.SubItems).length > 0)
        .map((navItem) => (
          <Redirect
            key={navItem.Link}
            from={navItem.Link}
            to={filterExternalLinks(navItem.SubItems)[0].Link}
            exact={true}
          />
        ))}
      {/* Redirect language route and missing sections to first section */}
      {props.linkMapper(props.doc.Sections[0].SuggestedLink) && (
        <Redirect to={props.linkMapper(props.doc.Sections[0].SuggestedLink)!} />
      )}
    </Switch>
  );
}

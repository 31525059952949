import styled from 'styled-components';
import { FlexParentMixin, InvertedScrollableMixin } from '../StyledElements';
import { defaultElements as defaultTabElements, makeCustomTabs } from '../Tabs';

// Customize tabs sub-components for dark background

const TabsWrapper = styled(defaultTabElements.TabsWrapper)`
  ${FlexParentMixin};
  height: 100%;
  flex-direction: column;
  width: 100%;
`;
TabsWrapper.displayName = 'TabsWrapper';

const TabListItem = styled(defaultTabElements.TabListItem)`
  font-size: 12px;
  border: none;
  border-bottom: ${(props) => (props.selected ? '2px solid #fff' : 'none')};
  font-weight: normal;
  line-height: 16px;
  padding: 4px 12px 8px;
`;
TabListItem.displayName = 'TabListItem';

const TabList = styled(defaultTabElements.TabList)`
  background-color: ${(props) => props.theme.colors.C1000};
  border: 2px solid ${(props) => props.theme.colors.C1000};
  margin: 0;
  padding: 0;
  height: 36px;
  border-radius: 8px 8px 0px 0px;
`;

TabList.displayName = 'TabList';

const TabPanelWrapper = styled(defaultTabElements.TabPanelWrapper)`
  ${InvertedScrollableMixin};
  overflow-x: auto;
  flex: 1;
`;
TabPanelWrapper.displayName = 'TabPanelWrapper';

/**
 * Create custom tabs components for dark theme
 */
export const { Tabs: DarkTabs, TabPanel: DarkTabPanel } = makeCustomTabs({
  TabListItem,
  TabList,
  TabsWrapper,
  TabPanelWrapper,
});

export const ArrowUpSmall = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.33"
    height="9"
    viewBox="0 0 9.33 9"
  >
    <g
      id="arrow-up_1_"
      data-name="arrow-up (1)"
      transform="translate(-3.94 -4.024)"
    >
      <line
        id="Line_402"
        data-name="Line 402"
        y1="7.274"
        transform="translate(8.5 5)"
        fill="none"
        stroke="#858BA5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_36241"
        data-name="Path 36241"
        d="M5,8.6l3.49-3.83L12.21,8.6"
        fill="none"
        stroke="#858BA5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ChevronRightGroup = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox={props.viewBox || '0 0 16 16'}
    fill={props.fill || 'none'}
  >
    <g>
      <circle
        cx="8"
        cy="8"
        r="7.5"
        transform="rotate(180 8 8)"
        fill="#F8FAFC"
        stroke="#24313E"
        opacity="0.25"
      />
      <path
        d="M7.09055 4.72701L10.3633 7.99973L7.09055 11.2725"
        stroke="#24313E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="0.55"
      />
    </g>
  </SVGIcon>
);

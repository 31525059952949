/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ErrorIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '14.5'}
    height={props.height || '14.5'}
    viewBox="0 0 14.5 14.5"
  >
    <g transform="translate(-1.25 -1.25)" fill={props.fill || '#6b7e8f'}>
      <circle
        cx="6.5"
        cy="6.5"
        r="6.5"
        transform="translate(2 2)"
        fill="none"
        stroke="#ff4451"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        y1="4"
        x2="4"
        transform="translate(6.5 6.5)"
        fill="none"
        stroke="#ff4451"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="4"
        y1="4"
        transform="translate(6.5 6.5)"
        fill="none"
        stroke="#ff4451"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SVGIcon>
);

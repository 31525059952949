import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const UpDownArrows = (props: SVGIconProps) => {
  const fallbackColor = '#858ba5';
  return (
    <SVGIcon
      {...props}
      width={props.width || '14.622'}
      height={props.height || '14.871'}
      viewBox={props.viewBox || '0 0 14.622 14.871'}
    >
      <g transform="translate(-452.939 -172.939)">
        <path
          d="M8.25,5.656,10.906,3m0,0,2.656,2.656M10.906,3V15.75"
          transform="translate(445.75 171)"
          fill="none"
          stroke={props.stroke || fallbackColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M8.25,13.094l2.656,2.656m0,0,2.656-2.656M10.906,15.75V3"
          transform="translate(452.938 171)"
          fill="none"
          stroke={props.stroke || fallbackColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </SVGIcon>
  );
};

import styled from 'styled-components';
import { Div } from './CleanSlate';
import { AllSearchSections } from './DxDom';

interface SearchItemProps {
  name: string;
  count: number;
  selectedNavItem: AllSearchSections;
  onNavItemClick: (selectedNavItem: AllSearchSections) => void;
}

interface ItemProp {
  isSelected: boolean;
}

const ItemContainer = styled(Div)<ItemProp>`
  border: 1px solid ${(props) => props.theme.colors.C300};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.staticColors.Purple.C400
      : props.theme.colors.C201};
  position: relative;
  padding: 0px 16px;
  border-radius: 8px 8px 0px 0px;
  font-size: 15px;
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.C901
      : props.theme.staticColors.Goose.C300};
  position: normal;
  top: 1px;

  &:hover {
    cursor: pointer;
  }

  &::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    bottom: -1px;
    background-color: ${(props) =>
      props.isSelected ? props.theme.staticColors.Purple.C400 : 'transparent'};
  }

  @media screen and (max-width: 460px) {
    padding: 0px 5px;
  }
`;

const ItemCount = styled(Div)<ItemProp>`
  height: 18px;
  width: fit-content;
  margin-left: 6px;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.C901
      : props.theme.staticColors.Goose.C300};
  border-radius: 9px;
  font-size: 11px;
  color: ${(props) => props.theme.colors.C001};
  padding: 0px 8px;
  pointer-events: none;
  text-align: center;
  line-height: 19px;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 575px) {
    display: none;
  }
`;

const SearchNavItem = (props: SearchItemProps) => {
  const { name, count, onNavItemClick, selectedNavItem } = props;
  const isSelected = selectedNavItem === name;

  return (
    <ItemContainer
      isSelected={isSelected}
      onClick={(e) => {
        const selectedNavItem = (e.target as Element)?.firstChild
          ?.textContent as AllSearchSections;
        onNavItemClick(selectedNavItem);
      }}
    >
      {name}
      <ItemCount isSelected={isSelected}>
        {count > 99 ? '99+' : count}
      </ItemCount>
    </ItemContainer>
  );
};

export default SearchNavItem;

import styled, { css } from 'styled-components';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { ChatMessagesComponent } from '../chat-messages/ChatMessages';
import { Search } from '../search/Search';
import {
  controller,
  isAbortError,
  resetAbortController,
  signal,
} from '../../utils/AbortController';
import { ChatHeader } from '../chat-header/ChatHeader';
import { extractQuestions } from '../../utils/util-methods';
import { AppContext } from '../ApiCopilot';
import { DisclaimerHeight } from '../../typings';
import { createPortal } from 'react-dom';
import { Dimensions } from '../../hooks/useDimensions';
import { ChatMessage, ChatMessages, Theme } from '@dx-portal/design-system';

interface ChatRoomParentProps {
  isMaximised: boolean;
  offsetWidth: number;
}

export interface ChatRequestDto {
  messages?: ChatMessages | null;
}

const maximisedStyles = css<ChatRoomParentProps>`
  position: fixed;
  right: 50%;
  transform: translateX(50%);
  width: calc(${({ offsetWidth }) => offsetWidth}px - 28px);
  height: auto;
`;

const defaultStyles = css<ChatRoomParentProps>`
  position: absolute;
  right: 100px;
  width: 550px;
  height: auto;
  box-sizing: border-box;
`;

const ChatRoomParent = styled(Theme.Div)<ChatRoomParentProps>`
  ${({ isMaximised }) => (isMaximised ? maximisedStyles : defaultStyles)}

  display: flex;
  flex-direction: column;
  bottom: 10px;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  transition: all 0.6s ease-in-out, left 0.6s ease-in-out;
  -webkit-transition: none;

  z-index: 1000;
  overflow: hidden;
`;

const DisclaimerComponent = styled(Theme.P)`
  height: ${DisclaimerHeight};
  color: ${({ theme }) => theme.staticColors.Goose.C200};

  font-size: 11.85px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 12px 0;
`;

export interface ChatRoomProps {
  toggleChat: () => void;
  setMessages: Dispatch<SetStateAction<ChatMessages>>;
  messages: ChatMessages;
  welcomeMessage: string;
  isInputDisabled: boolean;
  setIsInputDisabled: Dispatch<SetStateAction<boolean>>;
  prompt: string;
  setPrompt: Dispatch<SetStateAction<string>>;
  dimensions: Dimensions;
  layoutContainerRef?: React.RefObject<HTMLDivElement>;
  markdownRenderer?: (source: string, className?: string) => JSX.Element;
}

export function ChatRoom({
  toggleChat,
  messages,
  setMessages,
  welcomeMessage,
  isInputDisabled,
  setIsInputDisabled,
  prompt,
  setPrompt,
  dimensions,
  layoutContainerRef,
  markdownRenderer,
}: ChatRoomProps) {
  let currentMessage = '';
  const { apiKey, language, logEvent, componentState } = useContext(AppContext);
  const [isInternalError, setInternalError] = useState<true | undefined>();
  const { questions } = extractQuestions(welcomeMessage);
  const [showPromptsOverlay, setShowPromptsOverlay] = useState<boolean>(false);

  const setDataBeforeAxiosCall = (prompt: ChatMessage) => {
    resetAbortController();
    const messagesClone =
      messages?.length !== 0 ? [...messages, prompt] : [prompt];
    setMessages(messagesClone);
    onSearchSubmit(messagesClone);
    logEvent && logEvent('MessageSent', messagesClone);
  };

  const onStopGenerating = () => {
    controller.abort();
    setMessages((prevMessages: ChatMessages) =>
      prevMessages.filter((message) => message.content !== 'Thinking...')
    );
    logEvent && logEvent('ClearedChat');
  };

  const onSearchSubmit = async (completeChat: ChatMessages) => {
    setInternalError(undefined);
    setIsInputDisabled(true);
    setMessages((prev: ChatMessages) => [
      ...prev,
      { role: 'assistant', content: 'Thinking...' },
    ]);
    const goodRequest: ChatRequestDto = {
      messages: completeChat,
    };

    try {
      const response = await fetch(
        `${
          process.env.NODE_ENV === 'production'
            ? 'https://chatbotapi.apimatic.io'
            : 'https://chatbotapi.dev.apimatic.io'
        }/chat/${apiKey}/${language}`,
        {
          method: 'POST',
          body: JSON.stringify(goodRequest), // Replace with your actual data
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );

      // Check if the response is successful (status code in the range 200-299)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const streamReader = response?.body?.getReader();
      const decoder = new TextDecoder();

      if (streamReader) {
        await streamReader
          .read()
          .then(async function processText({
            done,
            value,
          }: ReadableStreamReadResult<Uint8Array>): Promise<void> {
            if (done) {
              return;
            }

            const text = decoder.decode(value);
            currentMessage += text;
            setMessages((prevMessages: ChatMessages) => {
              const lastMessageIndex = prevMessages.length - 1;

              // Make a copy of the previous messages array
              const updatedMessages = [...prevMessages];

              // Update the content and role of the last message
              updatedMessages[lastMessageIndex] = {
                ...updatedMessages[lastMessageIndex],
                role: 'assistant',
                content: currentMessage,
              };

              return updatedMessages;
            });

            // Read some more, and call this function again
            return streamReader?.read().then(processText);
          });
      }
    } catch (err: unknown) {
      // Type of error is usually unknown
      if (isAbortError(err)) {
        resetAbortController();
        setMessages((prevMessages: ChatMessages) =>
          prevMessages.filter((message) => message.content !== '')
        );
        setIsInputDisabled(false);
        return;
      } else {
        logEvent && logEvent('ErrorInResponse', completeChat);
        setInternalError(true);
        setMessages((prevMessages: ChatMessages) => prevMessages.slice(0, -1));
      }
    }
    setIsInputDisabled(false);
    if (currentMessage === '') {
      logEvent && logEvent('ErrorInResponse', completeChat);
      setInternalError(true);
      setMessages((prevMessages: ChatMessages) => prevMessages.slice(0, -1));
    }
  };

  return createPortal(
    <ChatRoomParent
      isMaximised={componentState.isMaximized}
      offsetWidth={dimensions.width}
      data-testid="api-copilot-popup"
    >
      <ChatHeader
        toggleMaximise={componentState.setIsMaximized}
        clearChat={() => {
          setMessages([]);
          setInternalError(undefined);
          onStopGenerating();
          setShowPromptsOverlay(false);
        }}
        toggleChat={toggleChat}
        isMaximized={componentState.isMaximized}
        isClearChatButtonDisabled={messages.length === 0}
      />
      <ChatMessagesComponent
        messages={messages}
        isMaximised={componentState.isMaximized}
        isInternalError={isInternalError}
        welcomeMessage={welcomeMessage}
        onPromptClick={setDataBeforeAxiosCall}
        isInputDisabled={isInputDisabled}
        dimensions={dimensions}
        markdownRenderer={markdownRenderer}
        toggleChat={toggleChat}
      />
      <Search
        isMaximised={componentState.isMaximized}
        isInputDisabled={isInputDisabled}
        onSubmit={setDataBeforeAxiosCall}
        onStopGenerating={onStopGenerating}
        isResetPromptsButtonDisabled={messages.length === 0}
        isResetPromptsButtonHidden={questions.length === 0}
        suggestedPrompts={questions}
        prompt={prompt}
        setPrompt={setPrompt}
        showOverlay={showPromptsOverlay}
        setShowOverlay={setShowPromptsOverlay}
      />
      <DisclaimerComponent data-testid="api-copilot-disclaimer-text">
        API Copilot is in Beta. Kindly report if you see unexpected results.
      </DisclaimerComponent>
    </ChatRoomParent>,
    layoutContainerRef?.current || document.body
  );
}

export default ChatRoom;

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const LinkIcon2 = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '14'}
    height={props.height || '14'}
    fill={props.fill || 'none'}
    viewBox="0 0 16 17"
    style={{ marginRight: '5px', marginBottom: '2px' }}
    {...props}
  >
    <g clipPath="url(#clip0_833_3841)">
      <path
        d="M6.66602 8.99607C6.95232 9.37882 7.31759 9.69553 7.73705 9.9247C8.15651 10.1539 8.62035 10.2901 9.09712 10.3243C9.57388 10.3584 10.0524 10.2896 10.5002 10.1226C10.9481 9.95553 11.3548 9.69412 11.6927 9.35607L13.6927 7.35607C14.2999 6.7274 14.6359 5.88539 14.6283 5.0114C14.6207 4.13741 14.2701 3.30137 13.6521 2.68334C13.0341 2.06532 12.198 1.71475 11.324 1.70716C10.45 1.69956 9.60802 2.03554 8.97935 2.64274L7.83268 3.78274"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33347 7.66291C9.04716 7.28016 8.68189 6.96346 8.26243 6.73428C7.84297 6.50511 7.37913 6.36883 6.90237 6.33469C6.4256 6.30054 5.94707 6.36933 5.49924 6.53639C5.0514 6.70345 4.64472 6.96486 4.3068 7.30291L2.3068 9.30291C1.69961 9.93158 1.36363 10.7736 1.37122 11.6476C1.37881 12.5216 1.72938 13.3576 2.3474 13.9756C2.96543 14.5937 3.80147 14.9442 4.67546 14.9518C5.54945 14.9594 6.39146 14.6234 7.02013 14.0162L8.16013 12.8762"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_833_3841">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.330078)"
        />
      </clipPath>
    </defs>
  </SVGIcon>
);

import styled, { ThemeProvider } from 'styled-components';
import { BotIcon } from '../Icons/BotIcon';
import { useState, useEffect, createContext } from 'react';
import { ChatRoom } from './chat-room/ChatRoom';
import * as Sentry from '@sentry/react';
import { generateGUID } from '../utils/util-methods';
import { fetchSettings } from '../utils/api-client';
import { ApiCopilotContext, APICoPilotEvent } from '../typings';
import { controller } from '../utils/AbortController';
import { useDimensions } from '../hooks/useDimensions';

import {
  Theme,
  Tooltip,
  defaultSettings,
  PortalSettings,
  UserFeedback,
  ChatMessages,
} from '@dx-portal/design-system';

const ChatBotPopupWrapper = styled(Theme.Div)`
  position: absolute;

  @media screen and (max-width: 990px) {
    position: fixed;
  }

  right: 34px;
  bottom: 116px;
  z-index: 8;
`;

const ChatBotPopup = styled(Theme.Div)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 55px;
  width: 55px;
  background: #ff4451;
  border-radius: 50%;

  cursor: pointer;
  z-index: 10;
`;

export type ChatbotSettings = {
  isEnabled: boolean;
  welcomeMessage: string;
};

export interface MarkdownProps {
  source?: string;
  inline?: boolean;
  className?: string;
}

export type ApiCopilotProps = {
  apikey: string;
  language: string;
  portalSettings: PortalSettings;
  logEvent?: (
    event: APICoPilotEvent,
    chatMessages?: ChatMessages,
    apiCopilotResponse?: string,
    feedback?: UserFeedback,
    completeFeedback?: string
  ) => void;
  layoutContainerRef?: React.RefObject<HTMLDivElement>;
  markdownRenderer?: (source: string, className?: string) => JSX.Element;
};

const userId = generateGUID();

export const AppContext = createContext<ApiCopilotContext>({
  userId: userId,
  apiKey: '',
  language: '',
  portalSettings: defaultSettings,
  componentState: {
    isMaximized: false,
    setIsMaximized() {
      return;
    },
  },
});

export function ApiCopilot({
  apikey,
  language,
  logEvent,
  portalSettings,
  layoutContainerRef,
  markdownRenderer,
}: ApiCopilotProps) {
  const theme = {
    primaryColor: portalSettings.themeOverrides.palette.primaryColor,
    secondaryColor: portalSettings.themeOverrides.palette?.secondaryColor,
    linkColor: portalSettings.themeOverrides.palette.linkColor,
    colors: portalSettings.themeOverrides.palette.colors,
    cssStyles: portalSettings.themeOverrides.cssStyles,
    staticColors: Theme.staticColors,
  };
  const [userSettings, setUserSettings] = useState<ChatbotSettings>({
    isEnabled: false,
    welcomeMessage: '',
  });
  const [messages, setMessages] = useState<ChatMessages>([]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [prompt, setPrompt] = useState('');
  const dimensions = useDimensions(layoutContainerRef);
  const [isMaximised, setIsMaximised] = useState(
    window.innerWidth <= 680 ? true : false
  );
  const toggleMaximise = () => setIsMaximised(!isMaximised);

  const toggleChat = () =>
    setIsChatOpen((prev) => {
      if (logEvent) {
        prev === false ? logEvent('Opened') : logEvent('Closed');
      }
      return !prev;
    });

  useEffect(() => {
    controller.abort();
    setMessages([]);
    const fetchDataAndSetState = async () => {
      try {
        const settings = await fetchSettings({ apikey, language });
        if (settings) {
          setUserSettings(settings);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    if (language !== 'http_curl_v1') fetchDataAndSetState();
  }, [apikey, language]);

  return userSettings.isEnabled && language !== 'http_curl_v1' ? (
    <AppContext.Provider
      value={{
        userId: userId,
        apiKey: apikey,
        language: language,
        portalSettings: portalSettings,
        logEvent: logEvent,
        componentState: {
          isMaximized: isMaximised,
          setIsMaximized: toggleMaximise,
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <ChatBotPopupWrapper>
          {isChatOpen ? (
            <ChatBotPopup
              data-testid="api-copilot-popup-button"
              onClick={() => toggleChat()}
            >
              <BotIcon />
            </ChatBotPopup>
          ) : (
            <Tooltip
              text="API Copilot"
              children={
                <ChatBotPopup
                  data-testid="api-copilot-popup-button"
                  onClick={() => toggleChat()}
                >
                  <BotIcon />
                </ChatBotPopup>
              }
            />
          )}
        </ChatBotPopupWrapper>
        {isChatOpen && (
          <ChatRoom
            toggleChat={toggleChat}
            messages={messages}
            setMessages={setMessages}
            welcomeMessage={userSettings.welcomeMessage}
            layoutContainerRef={layoutContainerRef}
            isInputDisabled={isInputDisabled}
            setIsInputDisabled={setIsInputDisabled}
            prompt={prompt}
            setPrompt={setPrompt}
            dimensions={dimensions}
            markdownRenderer={markdownRenderer}
          />
        )}
      </ThemeProvider>
    </AppContext.Provider>
  ) : null;
}

export default ApiCopilot;

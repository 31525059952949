export const DocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.5"
    height="21.5"
    viewBox="0 0 17.5 21.5"
  >
    <g
      id="file-text_1_"
      data-name="document"
      transform="translate(-3.25 -1.25)"
    >
      <path
        id="Path_36739"
        d="M14,2H6A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8Z"
        fill="none"
        stroke="#455666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_36740"
        d="M14,2V8h6"
        fill="none"
        stroke="#455666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_410"
        x1="4.951"
        transform="translate(11.549 13)"
        fill="none"
        stroke="#455666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_411"
        x1="4.951"
        transform="translate(11.549 17)"
        fill="none"
        stroke="#455666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse_322"
        cx="1.25"
        cy="1.25"
        r="1.25"
        transform="translate(6.5 7.75)"
        fill="#455666"
      />
      <circle
        id="Ellipse_323"
        cx="1.25"
        cy="1.25"
        r="1.25"
        transform="translate(6.5 11.75)"
        fill="#455666"
      />
      <circle
        id="Ellipse_324"
        cx="1.25"
        cy="1.25"
        r="1.25"
        transform="translate(6.5 15.75)"
        fill="#455666"
      />
    </g>
  </svg>
);

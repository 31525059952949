import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const OverviewIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      /* eslint-disable max-len */
      d="M12.000,-0.001 L3.000,-0.001 C1.346,-0.001 0.000,1.304 0.000,2.908 L0.000,13.091 C0.000,14.695 1.346,16.000 3.000,16.000 L12.000,16.000 C13.654,16.000 15.000,14.695 15.000,13.091 L15.000,2.908 C15.000,1.304 13.654,-0.001 12.000,-0.001 ZM9.250,11.636 L2.750,11.636 L2.750,10.181 L9.250,10.181 L9.250,11.636 ZM12.250,8.727 L2.750,8.727 L2.750,7.272 L12.250,7.272 L12.250,8.727 ZM12.250,5.818 L2.750,5.818 L2.750,4.363 L12.250,4.363 L12.250,5.818 Z"
    />
  </SVGIcon>
);

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const RefreshIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '15.62'}
    height={props.height || '12.596'}
    viewBox={props.viewBox || '0 0 15.62 12.596'}
  >
    <g id="refresh-cw" transform="translate(0.06 -2.225)">
      <path
        id="Path_93"
        data-name="Path 93"
        d="M20.682,4V7.682H17"
        transform="translate(-6.182 -0.386)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_94"
        data-name="Path 94"
        d="M1,17.682V14H4.682"
        transform="translate(0 -4.249)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_95"
        data-name="Path 95"
        d="M2.54,6.683a5.523,5.523,0,0,1,9.113-2.062L14.5,7.3M1,9.751l2.847,2.675a5.523,5.523,0,0,0,9.112-2.062"
        transform="translate(0 0)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SVGIcon>
);

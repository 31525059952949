/**
 * Call console proxy (which calls the real API endpoint) and get response
 * @param url Request URL
 * @param retryOn Retry requests on specefic list of HTTP status codes
 * @param retryLimit Max number of retires.
 * @param retryCount Only used for recursion purposes and is meant to be called internally.
 * @param fetchOptions Options are passed to the fetch i.e. Headers etc.
 */

export function fetchRetry(
  url: string,
  retryOn: number[],
  retryLimit: number,
  retryCount: number,
  fetchOptions: Record<string, unknown>
  /* eslint-disable  @typescript-eslint/no-explicit-any */
): any {
  retryLimit = retryLimit || Number.MAX_VALUE;
  retryCount = Math.max(retryCount || 0, 0);

  return fetch(url, fetchOptions).then((res) => {
    if (retryOn.indexOf(res.status) !== -1 && retryCount < retryLimit) {
      return fetchRetry(url, retryOn, retryLimit, retryCount + 1, fetchOptions);
    } else {
      return res;
    }
  });
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DataType from '../fields/DataType';

import UnsupportedField from './UnsupportedField';
import {
  getWidget,
  getDefaultFormState,
  getUiOptions,
  isMultiSelect,
  isFilesArray,
  isFixedItems,
  allowAdditionalItems,
  optionsList,
  retrieveSchema,
  getDefaultRegistry,
  prefixClass as pfx,
  classNames,
  getEvenOdd,
  getEvenOddClass,
} from '../../utils';
import { ArrowUpIcon, DeleteIcon, ArrowDownIcon, ChevronIcon } from '../Icons';

function ArrayFieldTitle({
  TitleField,
  title,
  required,
  onNullifyChange,
  nullify,
  disabled,
  onClick,
  fromDiscriminator = false,
}) {
  if (!title) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  return (
    <TitleField
      title={title}
      required={required}
      nullify={nullify}
      onNullifyChange={onNullifyChange}
      disabled={disabled}
      onClick={onClick}
      fromDiscriminator={fromDiscriminator}
    />
  );
}

function ArrayFieldDescription({ DescriptionField, description }) {
  if (!description) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }

  return <DescriptionField description={description} />;
}

export function IconBtn(props) {
  const { type = 'default', icon, className, ...otherProps } = props;
  return (
    <button
      type="button"
      className={pfx(`btn btn-${type}`) + ' ' + className}
      {...otherProps}
    >
      {props.children}{' '}
    </button>
  );
}

// Used in the two templates
function DefaultArrayItem(props) {
  // const isObj = (() => {
  //   try {
  //     return props.children.props.schema.type === "object";
  //   } catch (e) {
  //     return false;
  //   }
  // })();

  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 0,
    marginRight: 20,
    fontWeight: 'bold',
  };

  const arrayItemWrapper = {
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div
      key={props.index}
      className={pfx(`array-item-wrapper ${props.className}`)}
      style={arrayItemWrapper}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* {!isObj && <label>[{props.index}]</label>} */}{' '}
        {props.hasToolbar && (
          <div
            className={pfx('array-item-toolbox')}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div className={pfx(' btn-group')}>
              {' '}
              {(props.hasMoveUp || props.hasMoveDown) && (
                <IconBtn
                  className={pfx('array-item-move-up')}
                  tabIndex="-1"
                  style={btnStyle}
                  disabled={
                    props.disabled || props.readonly || !props.hasMoveUp
                  }
                  onClick={props.onReorderClick(props.index, props.index - 1)}
                >
                  <ArrowUpIcon width={14} />{' '}
                </IconBtn>
              )}
              {(props.hasMoveUp || props.hasMoveDown) && (
                <IconBtn
                  className={pfx('array-item-move-down')}
                  tabIndex="-1"
                  style={btnStyle}
                  disabled={
                    props.disabled || props.readonly || !props.hasMoveDown
                  }
                  onClick={props.onReorderClick(props.index, props.index + 1)}
                >
                  <ArrowDownIcon width={14} />{' '}
                </IconBtn>
              )}
              {props.hasRemove && (
                <IconBtn
                  type="danger"
                  className={pfx('array-item-remove')}
                  tabIndex="-1"
                  style={btnStyle}
                  disabled={props.disabled || props.readonly}
                  onClick={props.onDropIndexClick(props.index)}
                >
                  <DeleteIcon width={18} />{' '}
                </IconBtn>
              )}{' '}
            </div>{' '}
          </div>
        )}{' '}
      </div>{' '}
      <div> {props.children} </div>{' '}
    </div>
  );
}

function DefaultFixedArrayFieldTemplate(props) {
  return (
    <fieldset className={pfx(props.className)}>
      ({' '}
      <ArrayFieldTitle
        TitleField={props.TitleField}
        title={props.uiSchema['ui:title'] || props.title}
        required={props.required}
        nullify={props.nullify}
        onNullifyChange={props.onNullifyChange}
        disabled={props.disabled}
        fromDiscriminator={props.fromDiscriminator}
      />
      ){' '}
      {(props.uiSchema['ui:description'] || props.schema.description) && (
        <div className={pfx('field-description')}>
          {props.uiSchema['ui:description'] ||
            props.schema.description.replace(/<br>/gi, '\n')}{' '}
        </div>
      )}{' '}
      <div className={pfx('row array-item-list')}>
        {props.items &&
          props.items.map((item, index) => (
            <DefaultArrayItem
              key={`outer-array-item-${index}`}
              {...item}
              index={index}
            />
          ))}
      </div>
      {props.canAdd && (
        <AddButton
          onClick={props.onAddClick}
          disabled={props.disabled || props.readonly}
        />
      )}{' '}
    </fieldset>
  );
}

function DefaultNormalArrayFieldTemplate(props) {
  const { fromDiscriminator, depth } = props;
  const headerClasses = classNames({
    [pfx('object-header')]: true,
    'position-unset': fromDiscriminator,
  });
  const title =
    props.uiSchema['ui:title'] ||
    props.schema.title ||
    props.itemsSchema.title ||
    props.title;

  const dataType = props.schema.dataTypeDisplayText;

  const markdown = props.schema.dataTypeMarkdown;

  const arrayContainerClasses = classNames({
    'array-container': true,
    'even-bg': getEvenOdd(depth),
    'odd-bg': !getEvenOdd(depth),
  });

  const dataTypeFieldClasses =
    (dataType && props.schema.containerKind) || markdown
      ? 'type-container type-container-dataType'
      : 'type-container';

  return (
    <fieldset className={pfx(props.className)}>
      <div className={headerClasses}>
        <div className={pfx('header-left hand')} onClick={props.toggleCollapse}>
          {props.fromDiscriminator ? null : (
            <ArrayFieldTitle
              TitleField={props.TitleField}
              title={title}
              required={props.required}
              nullify={props.nullify}
              onNullifyChange={props.onNullifyChange}
              disabled={props.disabled}
              fromDiscriminator={props.fromDiscriminator}
            />
          )}
          {props.required && (props.uiSchema['ui:title'] || props.title) && (
            <div className={pfx('element-required')}>
              {/* <RequiredInfoIcon /> */}
              <span>Required</span>
            </div>
          )}
        </div>
        <IconBtn
          tabIndex="-1"
          onClick={props.toggleCollapse}
          className={pfx(`btn toggle-button`)}
        >
          {props.collapse ? (
            <ChevronIcon width={14} rotate={-90} />
          ) : (
            <ChevronIcon width={14} />
          )}{' '}
        </IconBtn>{' '}
      </div>
      <div className={pfx(dataTypeFieldClasses)}>
        <DataType
          containerKind={props.schema.containerKind}
          containerName={props.schema.containerName}
          dataType={dataType}
          link={props.schema.dataTypeLink}
          linkTo={props.schema.linkTo}
          type="array-type"
          markdown={markdown}
        />
        {props.schema.paramType && (
          <div className={pfx('param-type')}> {props.schema.paramType} </div>
        )}{' '}
      </div>
      {(props.uiSchema['ui:description'] ||
        props.schema.description ||
        props.itemsSchema.description) && (
        <ArrayFieldDescription
          DescriptionField={props.DescriptionField}
          description={
            props.uiSchema['ui:description'] ||
            props.schema.description ||
            props.itemsSchema.description
          }
        />
      )}
      {!props.collapse && (
        <div className={pfx(arrayContainerClasses)}>
          <div className={pfx('row array-item-list')}>
            {props.items &&
              props.items.map((item, index) => (
                <DefaultArrayItem
                  key={`inner-array-item-${index}`}
                  {...item}
                  index={index}
                />
              ))}
          </div>
          {props.canAdd && (
            <AddButton
              onClick={props.onAddClick}
              disabled={props.disabled || props.readonly}
            />
          )}{' '}
        </div>
      )}{' '}
    </fieldset>
  );
}

class ArrayField extends Component {
  static defaultProps = {
    uiSchema: {},
    formData: [],
    required: false,
    disabled: false,
    readonly: false,
    autofocus: false,
  };

  constructor(props) {
    super(props);

    this.state = this.getStateFromProps(props);
    this.state.collapse = false;
    this.state.expandAllLevel = props.expandAllLevel;
    this.state.depth = props.depth ? props.depth : 1;

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const collapse = nextProps.fromDiscriminator
      ? false
      : this.state.depth === this.state.expandAllLevel &&
        this.state.expandAll !== nextProps.expandAll
      ? !nextProps.expandAll
      : this.state.collapse;
    this.setState({
      ...this.getStateFromProps(nextProps),
      expandAllLevel: this.state.expandAllLevel,
      expandAll: nextProps.expandAll,
      collapse,
    });
  }

  getStateFromProps(nextProps) {
    return {
      originalFormData:
        nextProps.formData === undefined ||
        (nextProps.formData && nextProps.formData.length === 0)
          ? this.state
            ? this.state.originalFormData
            : undefined
          : nextProps.formData,
    };
  }

  get itemTitle() {
    const { schema } = this.props;
    return schema.items.title || schema.items.description || 'Item';
  }

  isItemRequired(itemSchema) {
    if (Array.isArray(itemSchema.type)) {
      // While we don't yet support composite/nullable jsonschema types, it's
      // future-proof to check for requirement against these.
      return !itemSchema.type.includes('null');
    }
    // All non-null array item types are inherently required by design
    return itemSchema.type !== 'null';
  }

  canAddItem(formItems) {
    const { schema, uiSchema } = this.props;
    let { addable } = getUiOptions(uiSchema);
    if (addable !== false) {
      // if ui:options.addable was not explicitly set to false, we can add
      // another item if we have not exceeded maxItems yet
      if (schema.maxItems !== undefined) {
        addable = formItems.length < schema.maxItems;
      } else {
        addable = true;
      }
    }
    return addable;
  }

  onAddClick = (event) => {
    event.preventDefault();
    const { formData, registry = getDefaultRegistry() } = this.props;
    const { dxInterface } = registry;
    const { portalSettings, trackEvent } = dxInterface;
    const itemSchema = this.getItemSchema();

    trackEvent(portalSettings, 'API Playground - Item Added To Array', {});
    this.props.onChange(
      [
        ...formData,
        getDefaultFormState(itemSchema, undefined, undefined, dxInterface),
      ],
      {
        validate: false,
      }
    );
  };

  onDropIndexClick = (index) => {
    return (event) => {
      if (event) {
        event.preventDefault();
      }
      const {
        formData,
        onChange,
        registry: {
          dxInterface: { portalSettings, trackEvent },
        },
      } = this.props;
      trackEvent(
        portalSettings,
        'API Playground - Item Deleted From Array',
        {}
      );
      const updatedFormData = formData.filter((_, i) => i !== index);
      // refs #195: revalidate to ensure properly reindexing errors
      onChange(
        updatedFormData.length > 0 || this.props.required
          ? updatedFormData
          : undefined,
        {
          validate: true,
        }
      );
    };
  };

  onReorderClick = (index, newIndex) => {
    return (event) => {
      if (event) {
        event.preventDefault();
        event.target.blur();
      }
      const {
        formData,
        onChange,
        registry: {
          dxInterface: { portalSettings, trackEvent },
        },
      } = this.props;

      trackEvent(
        portalSettings,
        'API Playground - Array Item Position Changed',
        {}
      );
      onChange(
        formData.map((item, i) => {
          if (i === newIndex) {
            return formData[index];
          } else if (i === index) {
            return formData[newIndex];
          } else {
            return item;
          }
        }),
        {
          validate: true,
        }
      );
    };
  };

  onChangeForIndex = (index) => {
    return (value, options, schemaIndex) => {
      const { formData, onChange } = this.props;
      const newFormData = formData.map((item, i) => {
        // We need to treat undefined items as nulls to have validation.
        // See https://github.com/tdegrunt/jsonschema/issues/206
        const jsonValue = typeof value === 'undefined' ? null : value;
        return index === i ? jsonValue : item;
      });

      onChange(newFormData, {
        validate: false,
      });
    };
  };

  onSelectChange = (value) => {
    this.props.onChange(value, {
      validate: false,
    });
  };

  shouldDisable = () => {
    return (
      (this.props.formData === undefined ||
        (this.props.formData && this.props.formData.length === 0)) &&
      !this.props.required &&
      !this.props.fromDiscriminator
    );
  };

  getItemSchema = () => {
    const { schema } = this.props;

    if (isFixedItems(schema) && allowAdditionalItems(schema)) {
      return schema.additionalItems;
    } else {
      return schema.items;
    }
  };

  getFormData = () => {
    const {
      schema,
      registry: { dxInterface },
    } = this.props;
    const { originalFormData } = this.state;
    const defaultState = getDefaultFormState(
      schema,
      undefined,
      undefined,
      dxInterface
    );

    const itemSchema = this.getItemSchema();
    const formData = originalFormData || defaultState;

    if (formData && formData.length > 0) {
      return formData;
    }

    return [getDefaultFormState(itemSchema, undefined, undefined, dxInterface)];
  };

  onNullifyChange = () => {
    const { onChange } = this.props;
    const formData = this.getFormData();

    if (this.shouldDisable()) {
      onChange(formData);
    } else {
      onChange(undefined);
    }
  };

  additionalFieldSchema = (itemSchema, index) => {
    const { schema } = this.props;

    return {
      ...itemSchema,
      description: undefined,
      title: `[${index}]`,
      discriminator: schema.discriminator,
      discriminatorValue: schema.discriminatorValue,
      readOnly: schema.readOnly,
      writeOnly: schema.writeOnly,
    };
  };

  render() {
    const { schema, uiSchema, registry = getDefaultRegistry() } = this.props;
    const { dxInterface } = registry;
    if (!Object.prototype.hasOwnProperty.call(schema, 'items')) {
      return (
        <UnsupportedField schema={schema} reason="Missing items definition" />
      );
    }
    if (isFixedItems(schema)) {
      return this.renderFixedArray();
    }
    if (isFilesArray(schema, uiSchema, dxInterface)) {
      return this.renderFiles();
    }
    if (isMultiSelect(schema, dxInterface)) {
      return this.renderMultiSelect();
    }
    return this.renderNormalArray();
  }

  toggleCollapse() {
    this.setState((prevState, props) => {
      return {
        ...prevState,
        collapse: !prevState.collapse,
      };
    });
  }

  renderNormalArray() {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      formContext,
      onBlur,
      onFocus,
      schemaIndex,
      fromDiscriminator,
      typeCombinatorTypes,
      depth,
    } = this.props;
    const { ArrayFieldTemplate, fields, dxInterface } = registry;
    const { TitleField, DescriptionField } = fields;
    const itemsSchema = retrieveSchema(schema.items, formData, dxInterface);
    const title = name;
    // schema.title === undefined && itemsSchema.title === undefined
    //   ? name
    //   : name === undefined
    //     ? schema.title || itemsSchema.title
    //     : name + " (" + (schema.title || itemsSchema.title) + ")";

    const dataArray = Array.isArray(formData) ? formData : [];

    const arrayProps = {
      canAdd: this.canAddItem(formData),
      items: dataArray.map((item, index) => {
        const itemSchema = retrieveSchema(schema.items, item, dxInterface);
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;

        return this.renderArrayFieldItem({
          index,
          depth: depth,
          isEven: getEvenOdd(depth),
          canMoveUp: index > 0,
          canMoveDown: index < dataArray.length - 1,
          itemSchema: this.additionalFieldSchema(itemSchema, index),
          itemErrorSchema,
          itemData: item,
          itemUiSchema: uiSchema.items,
          autofocus: autofocus && index === 0,
          onBlur,
          onFocus,
          schemaIndex,
          typeCombinatorTypes,
        });
      }),
      className: `field field-array field-array-of-${
        itemsSchema.type
      } ${getEvenOddClass(depth)} depth_${depth}`,
      collapse: this.state.collapse,
      toggleCollapse: this.toggleCollapse,
      DescriptionField,
      disabled,
      uiSchema,
      onAddClick: this.onAddClick,
      readonly,
      required,
      schema,
      itemsSchema,
      title,
      TitleField,
      formContext,
      formData,
      onNullifyChange: this.onNullifyChange,
      nullify: formData && formData.length > 0,
      fromDiscriminator,
      depth,
    };

    // Check if a custom render function was passed in
    const Component = ArrayFieldTemplate || DefaultNormalArrayFieldTemplate;
    return <Component {...arrayProps} />;
  }

  renderMultiSelect() {
    const {
      schema,
      uiSchema,
      formData,
      disabled,
      readonly,
      autofocus,
      onBlur,
      onFocus,
      registry = getDefaultRegistry(),
    } = this.props;
    const items = this.props.formData;
    const { widgets, dxInterface, formContext } = registry;
    const itemsSchema = retrieveSchema(schema.items, formData, dxInterface);
    const enumOptions = optionsList(itemsSchema);
    const { widget = 'select', ...options } = {
      ...getUiOptions(uiSchema),
      enumOptions,
    };
    const Widget = getWidget(schema, widget, widgets);
    return (
      <Widget
        multiple
        onChange={this.onSelectChange}
        onBlur={onBlur}
        onFocus={onFocus}
        options={options}
        schema={schema}
        value={items}
        disabled={disabled}
        readonly={readonly}
        formContext={formContext}
        autofocus={autofocus}
      />
    );
  }

  renderFiles() {
    const {
      schema,
      uiSchema,
      name,
      disabled,
      readonly,
      autofocus,
      onBlur,
      onFocus,
      registry = getDefaultRegistry(),
    } = this.props;
    const title =
      schema.title === undefined
        ? name
        : name === undefined
        ? schema.title
        : name + ' (' + schema.title + ')';
    const items = this.props.formData;
    const { widgets, formContext } = registry;
    const { widget = 'files', ...options } = getUiOptions(uiSchema);
    const Widget = getWidget(schema, widget, widgets);
    return (
      <Widget
        options={options}
        multiple
        onChange={this.onSelectChange}
        onBlur={onBlur}
        onFocus={onFocus}
        schema={schema}
        title={title}
        value={items}
        disabled={disabled}
        readonly={readonly}
        formContext={formContext}
        autofocus={autofocus}
      />
    );
  }

  renderFixedArray() {
    const {
      schema,
      uiSchema,
      formData,
      errorSchema,
      name,
      required,
      disabled,
      readonly,
      autofocus,
      registry = getDefaultRegistry(),
      onBlur,
      onFocus,
      typeCombinatorTypes,
    } = this.props;
    const title =
      schema.title === undefined
        ? name
        : name === undefined
        ? schema.title
        : name + ' (' + schema.title + ')';
    let items = this.props.formData;
    const { ArrayFieldTemplate, fields, dxInterface } = registry;
    const { TitleField } = fields;
    const itemSchemas = schema.items.map((item, index) =>
      retrieveSchema(item, formData[index], dxInterface)
    );
    const additionalSchema = allowAdditionalItems(schema)
      ? retrieveSchema(schema.additionalItems, formData, dxInterface)
      : null;

    if (!items || items.length < itemSchemas.length) {
      // to make sure at least all fixed items are generated
      items = items || [];
      items = items.concat(new Array(itemSchemas.length - items.length));
    }

    // These are the props passed into the render function
    const arrayProps = {
      canAdd: this.canAddItem(items) && additionalSchema,
      className: 'field field-array field-array-fixed-items',
      disabled,
      formData,
      items: items.map((item, index) => {
        const additional = index >= itemSchemas.length;
        const itemSchema = additional
          ? retrieveSchema(schema.additionalItems, item, dxInterface)
          : itemSchemas[index];
        const itemUiSchema = additional
          ? uiSchema.additionalItems || {}
          : Array.isArray(uiSchema.items)
          ? uiSchema.items[index]
          : uiSchema.items || {};
        const itemErrorSchema = errorSchema ? errorSchema[index] : undefined;

        return this.renderArrayFieldItem({
          index,
          // indexAsTitle: `[${index}]`,
          canRemove: additional,
          canMoveUp: index >= itemSchemas.length + 1,
          canMoveDown: additional && index < items.length - 1,
          itemSchema: this.additionalFieldSchema(itemSchema, index),
          itemData: item,
          itemUiSchema,
          itemErrorSchema,
          autofocus: autofocus && index === 0,
          onBlur,
          onFocus,
          typeCombinatorTypes,
        });
      }),
      onAddClick: this.onAddClick,
      readonly,
      required,
      schema,
      uiSchema,
      title,
      TitleField,
      onNullifyChange: this.onNullifyChange,
      nullify: formData && formData.length > 0,
    };

    // Check if a custom template template was passed in
    const Template = ArrayFieldTemplate || DefaultFixedArrayFieldTemplate;
    return <Template {...arrayProps} />;
  }

  renderArrayFieldItem(props) {
    const {
      index,
      depth,
      canRemove = true,
      canMoveUp = true,
      canMoveDown = true,
      itemSchema,
      itemData,
      itemUiSchema,
      itemErrorSchema,
      autofocus,
      onBlur,
      onFocus,
      schemaIndex,
      typeCombinatorTypes,
    } = props;
    const {
      disabled,
      readonly,
      uiSchema,
      registry = getDefaultRegistry(),
    } = this.props;

    const {
      fields: { SchemaField },
    } = registry;
    const { orderable, removable } = {
      orderable: true,
      removable: true,
      ...uiSchema['ui:options'],
    };
    const has = {
      moveUp: orderable && canMoveUp,
      moveDown: orderable && canMoveDown,
      remove: removable && canRemove,
    };
    const childDepth = depth + 1;
    has.toolbar = Object.keys(has).some((key) => has[key]);

    return {
      children: (
        <SchemaField
          schema={itemSchema}
          depth={childDepth}
          isEven={getEvenOdd(childDepth)}
          index={index}
          uiSchema={itemUiSchema}
          formData={itemData}
          errorSchema={itemErrorSchema}
          required={this.isItemRequired(itemSchema)}
          onChange={this.onChangeForIndex(index, schemaIndex)}
          onBlur={onBlur}
          onFocus={onFocus}
          registry={this.props.registry}
          disabled={this.props.disabled}
          readonly={this.props.readonly}
          autofocus={autofocus}
          typeCombinatorTypes={typeCombinatorTypes}
        />
      ),
      className: 'array-item',
      disabled,
      hasToolbar: has.toolbar,
      hasMoveUp: has.moveUp,
      hasMoveDown: has.moveDown,
      hasRemove: has.remove,
      index,
      onDropIndexClick: this.onDropIndexClick,
      onReorderClick: this.onReorderClick,
      readonly,
    };
  }
}

function AddButton({ onClick, disabled }) {
  return (
    <div>
      <p className={pfx('array-item-add text-right')}>
        <IconBtn
          type="info"
          className={pfx('btn-add')}
          tabIndex="0"
          onClick={onClick}
          disabled={disabled}
        >
          {/* <PlusIcon width={14} /> */}
          Add Item{' '}
        </IconBtn>{' '}
      </p>{' '}
    </div>
  );
}

/* istanbul ignore else */
if (process.env.NODE_ENV !== 'production') {
  ArrayField.propTypes = {
    schema: PropTypes.object.isRequired,
    uiSchema: PropTypes.shape({
      'ui:options': PropTypes.shape({
        addable: PropTypes.bool,
        orderable: PropTypes.bool,
        removable: PropTypes.bool,
      }),
    }),
    errorSchema: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    formData: PropTypes.array,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    dxInterface: PropTypes.shape({
      registry: PropTypes.shape({
        widgets: PropTypes.objectOf(
          PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        ).isRequired,
        fields: PropTypes.objectOf(PropTypes.func).isRequired,
        definitions: PropTypes.object.isRequired,
        formContext: PropTypes.object.isRequired,
      }),
    }),
  };
}

export default ArrayField;

import { staticColors } from 'src/Color';
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const PythonIconColored = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '19'}
    height={props.height || '19'}
    viewBox={props.viewBox || '0 0 19 19'}
    fill={props.fill || staticColors.Goose.C200}
    {...props}
  >
    <path
      d="M9.38686 0.000242442C8.65308 0.000951474 7.92066 0.0627054 7.19734 0.184851C5.25804 0.522963 4.90594 1.233 4.90594 2.54098V4.26856H9.48771V4.84436H3.1856C1.85359 4.84436 0.687384 5.63622 0.322634 7.14251C-0.0981789 8.86907 -0.11681 9.94646 0.322634 11.7493C0.648414 13.0912 1.42629 14.0474 2.75829 14.0474H4.33403V11.9765C4.35081 11.2321 4.65836 10.523 5.19218 9.99803C5.72601 9.47303 6.44464 9.17288 7.19734 9.16052H11.7745C12.0779 9.15825 12.3779 9.09684 12.6573 8.97983C12.9367 8.86281 13.1901 8.69247 13.4029 8.47855C13.6157 8.26463 13.7838 8.01132 13.8975 7.73311C14.0113 7.4549 14.0685 7.15724 14.0659 6.85714V2.54098C14.0659 1.31262 13.0181 0.389747 11.7745 0.184851C10.9852 0.0573632 10.1866 -0.00438972 9.38686 0.000242442ZM6.90848 1.38971C7.08166 1.38706 7.25174 1.43541 7.39708 1.52859C7.54242 1.62178 7.65646 1.7556 7.72472 1.91306C7.79297 2.07051 7.81236 2.24448 7.7804 2.41285C7.74845 2.58123 7.6666 2.7364 7.54527 2.85865C7.42393 2.98091 7.26858 3.06472 7.09897 3.09943C6.92935 3.13415 6.75313 3.1182 6.59271 3.05362C6.43228 2.98903 6.29489 2.87873 6.198 2.73673C6.10111 2.59473 6.04909 2.42743 6.04856 2.25612C6.04674 2.0285 6.13623 1.80945 6.29742 1.64698C6.45861 1.48451 6.67835 1.39187 6.90848 1.38937V1.38971Z"
      fill="#306998"
    />
    <path
      d="M14.6374 4.84412V6.85689C14.6374 8.41745 13.2998 9.73084 11.7745 9.73084H7.19731C6.5868 9.74271 6.00521 9.99036 5.57692 10.4208C5.14862 10.8513 4.9076 11.4304 4.9054 12.0344V16.3506C4.9054 17.5789 5.98546 18.3015 7.19679 18.6539C8.68705 19.1148 10.284 19.1148 11.7743 18.6539C12.928 18.3234 14.0657 17.6585 14.0657 16.3506V14.6233H9.48768V14.0472H16.3566C17.6886 14.0472 18.1854 13.1284 18.6481 11.749C19.1267 10.329 19.1062 8.96367 18.6481 7.14226C18.3189 5.83089 17.691 4.84412 16.3566 4.84412H14.6374ZM12.0628 15.7747C12.2362 15.7721 12.4065 15.8206 12.552 15.914C12.6974 16.0074 12.8115 16.1416 12.8796 16.2993C12.9477 16.4571 12.9667 16.6314 12.9344 16.7999C12.902 16.9684 12.8196 17.1236 12.6977 17.2456C12.5758 17.3677 12.4199 17.451 12.2499 17.4852C12.08 17.5193 11.9036 17.5025 11.7432 17.4371C11.5829 17.3717 11.4459 17.2606 11.3497 17.1178C11.2534 16.9751 11.2023 16.8073 11.2029 16.6357C11.2021 16.5234 11.2238 16.4119 11.2666 16.3078C11.3094 16.2037 11.3726 16.109 11.4525 16.029C11.5323 15.949 11.6273 15.8854 11.7321 15.8418C11.8368 15.7982 11.9492 15.7754 12.0628 15.7747Z"
      fill="#FFE873"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_4530"
        x1="3.60089"
        y1="2.40211"
        x2="22.2176"
        y2="18.2859"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5A9FD4" />
        <stop offset="1" stop-color="#306998" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_4530"
        x1="24.8484"
        y1="31.4423"
        x2="18.1578"
        y2="22.1555"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFD43B" />
        <stop offset="1" stop-color="#FFE873" />
      </linearGradient>
    </defs>
  </SVGIcon>
);

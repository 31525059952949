import {
  useCallback,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { usePopper } from 'react-popper';
import styled, { CSSProperties } from 'styled-components';
import { Placement } from '@popperjs/core';

import { Div } from 'src/CleanSlate';

type HTMLRef = HTMLElement | null;

type SetState<T> = Dispatch<SetStateAction<T>>;

type OffsetItem = number | null | undefined;

type OffsetProp = [OffsetItem, OffsetItem];

export interface PopoverProps {
  placement?: Placement;
  fallbackPlacements?: Placement[];
  offset?: OffsetProp;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
  children: (props: {
    visible: boolean;
    styles: {
      [key: string]: CSSProperties;
    };
    attributes: {
      [key: string]:
        | {
            [key: string]: string;
          }
        | undefined;
    };
    setVisible: SetState<boolean>;
    toggleVisibility: VoidFunction;
    setReferenceElement: SetState<HTMLRef>;
    setPopperElement: SetState<HTMLRef>;
    setArrowElement: SetState<HTMLRef>;
    hoverVisibility: VoidFunction;
    isHover: boolean;
  }) => ReactNode;
}

const defaultFallbackPlacements: Placement[] = [
  'top-start',
  'bottom-start',
  'top',
];
const defaultPlacement: Placement = 'bottom-start';

const defaultOffset: OffsetProp = [0, 10];

const PopoverStyled = styled(Div)``;
export default function Popover(props: PopoverProps) {
  const {
    children,
    fallbackPlacements = defaultFallbackPlacements,
    placement = defaultPlacement,
    offset = defaultOffset,
    onMouseEnter,
  } = props;
  const [isHover, setIsHover] = useState(false);
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLRef>(null);
  const [popperElement, setPopperElement] = useState<HTMLRef>(null);
  const [arrowElement, setArrowElement] = useState<HTMLRef>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'flip',
        options: {
          fallbackPlacements: fallbackPlacements,
        },
      },
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
    ],
  });

  const toggleVisibility = useCallback(() => {
    setVisible((visible) => !visible);
  }, []);

  const hoverVisibility = useCallback(() => {
    setIsHover((isHover) => !isHover);
  }, []);

  return (
    <PopoverStyled onMouseEnter={onMouseEnter}>
      {children({
        setArrowElement,
        setPopperElement,
        setReferenceElement,
        setVisible,
        toggleVisibility,
        hoverVisibility,
        isHover,
        visible,
        attributes,
        styles,
      })}
    </PopoverStyled>
  );
}

import * as React from 'react';
import { Div } from 'src/CleanSlate';
import styled from 'styled-components';

const TimeStyle = styled(Div)`
  font-size: 10px;
  color: #f14f12;
`;

interface CountDownTimerProps {
  duration: number;
  onStop?: () => void;
}

interface CountDownTimerState {
  time: {
    h?: number;
    m?: number;
    s?: number;
  };
  seconds: number;
}

export class CountDownTimer extends React.Component<
  CountDownTimerProps,
  CountDownTimerState
> {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  timer: any;
  state: CountDownTimerState;

  constructor(props: CountDownTimerProps) {
    super(props);
    this.state = {
      time: {},
      seconds: this.props.duration,
    };
    this.timer = 0;
    this.startTimer();
  }

  secondsToTime(secs: number) {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    const timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds < 1) {
      if (this.props.onStop) {
        this.props.onStop();
      }
      clearInterval(this.timer);
    }
  };

  render() {
    return (
      <TimeStyle>
        {this.state.time.m} : {this.state.time.s}
      </TimeStyle>
    );
  }
}

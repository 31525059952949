/**
 * This portal requires a slightly modified version of DX Document because
 * the requires for the portal and the document evolved independantly.
 *
 * We implement a compability adapter in this file for this purpose. Since
 * the DxDOM is a readonly-AST, we do not modify any part of the original
 * document.
 */

import * as DxDom from './DxDom';
import { filterPlatforms } from './PlatformConstants';
import { Language } from './PortalSettings';
import { LinkMapper, getHashId } from './LinkMapperContext';
import { getFlattenedSections } from './DxDomUtils';
import {
  extractModelStructureFromDocument,
  isExpandable,
  mergeSchemaWithParameters,
} from './Utilities/MergeParams';
import jsonSchemaTraverse from 'json-schema-traverse';

/**
 * Make document compatible with our portal.
 *
 * A new document is returned and the original is not modified.
 * @param doc Document
 * @param guideSettingsJson User-provided guides
 */
export function makeDocumentCompat(
  doc: DxDom.Document,
  tpl: string,
  responseHeaders: DxDom.ResponseHeaders,
  useBrowserRouting: boolean
): [DxDom.Document, LinkMapper] {
  const linkMap: Record<string, string> = {};
  const { ModelSchemas = {} } = doc;

  // This is the only way to build a function object with a property
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const linkMapper: any = (link?: string) =>
    link && link in linkMap ? linkMap[link] : link;
  linkMapper.reverse = (link?: string) =>
    link ? Object.keys(linkMap).find((k) => linkMap[k] === link) : link;
  linkMapper.isDxDomLink = createDxDomLinkerCheckerFn(doc);

  const modelStructure = extractModelStructureFromDocument(doc);
  const modelSchemas = makeJsonSchemaCompat(ModelSchemas);

  const newDoc: DxDom.Document = {
    ...doc,
    ModelSchemas: modelSchemas,
    DataModelSchema: makeJsonSchemaCompat(doc.DataModelSchema),
    NavItems: makeNavCompat(doc.NavItems, linkMap, tpl, useBrowserRouting),
    Sections: makeSectionsCompat(
      doc.Sections,
      modelStructure,
      linkMapper,
      modelSchemas
    ),
    ResponseHeaders: responseHeaders,
    Structures: modelStructure,
  };

  return [newDoc, linkMapper];
}

/**
 * Create a new nav for our portal
 * @param nav Current nav
 * @param guideSettings User-provided guides
 */
function makeNavCompat(
  nav: ReadonlyArray<DxDom.NavItem>,
  linkMap: { [oldLink: string]: string },
  tpl: string,
  useBrowserRouting: boolean
): DxDom.NavItem[] {
  // Title case all links; rewrite all links according to old portal style
  const linkLangPrefix =
    '/' +
    getHashId(
      filterPlatforms([tpl.toLowerCase() as Language])[0].templates[0].name
    );
  const newNav = removeApiListFromNav(nav);
  return makeCompatLinks(
    makeCompatLinkTexts(newNav),
    linkMap,
    false,
    useBrowserRouting,
    linkLangPrefix
  );
}

/**
 * Make all nav links reader friendly by title-casing it
 * @param nav Nav
 */
function makeCompatLinkTexts(
  nav: ReadonlyArray<DxDom.NavItem>
): DxDom.NavItem[] {
  const specialCases: Record<string, string> = {
    '$h/__api_reference': 'API Endpoints',
    '$h/__model_reference': 'Models',
  };

  return nav.map((n) => {
    const text: string | undefined =
      n.Link in specialCases ? specialCases[n.Link] : n.Text;

    return {
      ...n,
      Text: text,
      SubItems: makeCompatLinkTexts(n.SubItems),
    };
  });
}

function isListOfApisSection(value: string) {
  return (
    value.indexOf('List%20of%20APIs') !== -1 ||
    value.indexOf('__list_of_apis') !== -1
  );
}

/**
 * Remove List of APIs section link from nav
 * @param subItems Nav
 */
function removeApiListNavItem(
  subItems: ReadonlyArray<DxDom.NavItem>
): DxDom.NavItem[] {
  return subItems.filter((s) => !isListOfApisSection(s.Link));
}

/**
 * Remove unnecessary section link from nav
 * @param nav Nav
 */

function removeApiListFromNav(
  nav: ReadonlyArray<DxDom.NavItem>
): DxDom.NavItem[] {
  return nav.map((n) => ({
    ...n,
    SubItems: n.SubItems.length
      ? removeApiListFromNav(removeApiListNavItem(n.SubItems))
      : n.SubItems,
  }));
}

/**
 * Convert to old and shit linking style
 * @param nav Nav
 */
function makeCompatLinks(
  nav: ReadonlyArray<DxDom.NavItem>,
  linkMap: { [oldLink: string]: string },
  isPage: boolean,
  useBrowserRouting: boolean,
  parentLink?: string,
  linkCounter: { [link: string]: number } = {}
): DxDom.NavItem[] {
  if (!isPage) {
    isPage = nav.every((f) => f.Skip);
  }

  return nav.map((n) => {
    if (n.IsExternal) {
      return n;
    }

    const separator = isPage && useBrowserRouting ? '#' : '/';
    let link = parentLink
      ? parentLink + separator + getHashId(n.Text)
      : getHashId(n.Text);

    // check for duplicate and increment counter
    if (linkCounter.hasOwnProperty(link)) {
      linkCounter[link]++;
      link = link + '-' + linkCounter[link];
    } else {
      linkCounter[link] = 0;
    }

    linkMap[n.Link] = link;

    const newParentLink = isPage ? parentLink : link;

    return {
      ...n,
      Link: link,
      InSection: getTypeOfSectionFromPermaLink(n.Link),
      SubItems: makeCompatLinks(
        n.SubItems,
        linkMap,
        isPage,
        useBrowserRouting,
        newParentLink,
        linkCounter
      ),
    };
  });
}

function getTypeOfSectionFromPermaLink(
  permaLink: string
): DxDom.MainSearchSections {
  if (permaLink.startsWith('page:')) return 'Documentation';
  else if (
    permaLink.startsWith('$h/__api_reference') ||
    permaLink.startsWith('$h/API%20Endpoints') ||
    permaLink.startsWith('$e/')
  )
    return 'API Endpoints';
  else if (
    permaLink.startsWith('$h/__model_reference') ||
    permaLink.startsWith('$h/Models') ||
    permaLink.startsWith('$m/')
  )
    return 'Models';
  else return 'Documentation';
}

// Removes Enumeration list, Exceptions list and Structures list Model from section.
function removeModelListFromSection(
  sections: ReadonlyArray<DxDom.Section>
): ReadonlyArray<DxDom.Section> {
  return sections.map((s) => {
    if (
      s.PlaceholderId === null &&
      s.SuggestedLink &&
      s.SuggestedLink.indexOf('__model_reference') !== -1
    ) {
      const newNode = s.Nodes.slice(1);
      return {
        ...s,
        Nodes: newNode,
      };
    }
    return s;
  });
}

/*
 * Check if Step by step tutorial is enabled
 */
function checkStepByStepTurorial(sections: ReadonlyArray<DxDom.Section>) {
  return sections.some(
    (section) => section.PlaceholderId === '__getting_started'
  );
}

/**
 * Make sections compatible with our portal.
 * @param sections Sections generated
 * @param guideSettings Guide settings provided by user
 */
function makeSectionsCompat(
  sections: ReadonlyArray<DxDom.Section>,
  modelStructure: DxDom.ReferenceType,
  linkMapper: LinkMapper,
  modelSchemas: object
): DxDom.Section[] {
  const compSection = sections.map((section) =>
    makeSectionCompat(
      { ...section, hasStepByStepPage: checkStepByStepTurorial(sections) },
      modelStructure,
      linkMapper,
      modelSchemas
    )
  );
  const flattenedSections = getFlattenedSections(compSection);
  const removeModelListFromSections =
    removeModelListFromSection(flattenedSections);
  return removeModelListFromSections.filter((s) => {
    // Removes list of APIs from endpoint section.
    const isApiListSection =
      s.SuggestedLink && isListOfApisSection(s.SuggestedLink);
    return (
      !s.HideFromNavigation &&
      !isApiListSection &&
      (s.Nodes.some((n) => n.Type !== 'section') ||
        s.Nodes.every(
          (n) => n.Type === 'section' && n.HideFromNavigation === true
        ))
    );
  });
}

function makeSectionCompat(
  section: DxDom.Section,
  modelStructure: DxDom.ReferenceType,
  linkMapper: LinkMapper,
  modelSchemas: object
): typeof section {
  return {
    ...section,
    Nodes: makeEndpointRefsCompat(
      section.Nodes,
      modelStructure,
      linkMapper,
      modelSchemas,
      section.hasStepByStepPage
    ),
  };
}

/**
 * Make endpoint references compatible with our Json Schema form generator.
 *
 * This is done by pruning data models and schema in all endpoint references.
 * @param sections
 */
function makeEndpointRefsCompat(
  nodes: ReadonlyArray<DxDom.SectionNode>,
  modelStructure: DxDom.ReferenceType,
  linkMapper: LinkMapper,
  modelSchemas: object,
  hasStepByStepPage?: boolean
): typeof nodes {
  return nodes.map((n) =>
    n.Type === 'endpointreference'
      ? ({
          ...n,
          hasStepByStepPage,
          UsageExample: {
            ...n.UsageExample,
            CallModel: pruneDataModel(n.UsageExample.CallModel),
            CallModelSchema: mergeSchemaWithParameters(
              { structure: modelStructure, linkMapper, modelSchemas },
              makeJsonSchemaCompat(n.UsageExample.CallModelSchema),
              n.Parameters
            ),
            isExpandable: isExpandable(n.UsageExample.CallModelSchema),
          },
        } as DxDom.SectionNode)
      : n.Type === 'section'
      ? makeSectionCompat(
          { ...n, hasStepByStepPage },
          modelStructure,
          linkMapper,
          modelSchemas
        )
      : n
  );
}

/**
 * Cleans-up the endpoint call model for use with our jsonschema form generator.
 *
 * The original object is not changed.
 *
 * @param {*data} data Call model
 */
function pruneDataModel<T, K extends Extract<keyof T, string>>(
  data: T
): Pick<T, K> {
  return removeNulls(data);
}

/**
 * Compact arrays with null entries; delete keys from objects with null value
 *
 * The original object is not changed.
 *
 * @param {*object} obj Any object
 */
function removeNulls<T, K extends Extract<keyof T, string>>(
  obj: Pick<T, K>
): Pick<T, K> {
  return Object.keys(obj)
    .filter((k) => obj[k as keyof typeof obj] !== null)
    .reduce((ob, k) => {
      ob[k as keyof Pick<T, K>] =
        typeof obj[k as keyof typeof obj] === 'object'
          ? (removeNulls(obj[k as keyof typeof obj] as Pick<T, K>) as T[K])
          : obj[k as keyof typeof obj];
      return ob;
    }, (obj instanceof Array ? [] : {}) as Pick<T, K>);
}

function updateExclusiveProperty(element: DxDom.JSchema): void {
  const newElement = element;

  if (
    'exclusiveMaximum' in newElement &&
    typeof newElement.exclusiveMaximum === 'boolean'
  ) {
    if (newElement.exclusiveMaximum) {
      newElement.exclusiveMaximum = newElement.maximum;
      delete newElement.maximum;
    } else {
      delete newElement.exclusiveMaximum;
    }
  }

  if (
    'exclusiveMinimum' in newElement &&
    typeof newElement.exclusiveMinimum === 'boolean'
  ) {
    if (newElement.exclusiveMinimum) {
      newElement.exclusiveMinimum = newElement.minimum;
      delete newElement.minimum;
    } else {
      delete newElement.exclusiveMinimum;
    }
  }
}

/**
 *
 * Formats for integer and string with format uuid are removed.
 * In case when exclusiveMaximum/exclusiveMinimum is true:
 * - replaced exclusiveMaximum/exclusiveMinimum value with minimum/maximum value
 * - removed minimum/maximum property.
 *
 * @param {*schema} schema Json schema
 *
 */
function updateJsonSchemaProperties(element: DxDom.JSchema): void {
  if (
    (element.format === 'int64' ||
      element.format === 'int32' ||
      element.type === 'integer' ||
      element.format === 'uuid' ||
      element.format === 'date-time-rfc1123' ||
      element.format === 'unix-timestamp') &&
    element.format
  ) {
    delete element.format;
  } else if (element.format === 'password') {
    element['isSecret'] = true;
    delete element.format;
  }

  if (
    element.hasOwnProperty('exclusiveMaximum') ||
    element.hasOwnProperty('exclusiveMinimum')
  ) {
    return updateExclusiveProperty(element);
  }
}

function makeJsonSchemaCompat(schema: DxDom.JSchema): DxDom.JSchema {
  const newSchema = JSON.parse(JSON.stringify(schema));

  jsonSchemaTraverse(newSchema, {
    allKeys: true,
    cb: updateJsonSchemaProperties as jsonSchemaTraverse.Callback,
  });

  return newSchema;
}

function createDxDomLinkerCheckerFn(doc: DxDom.Document) {
  return (link?: string): boolean => {
    if (link === null || link === undefined) {
      return false;
    }
    // Is legacy DX DOM link?
    if (link.startsWith('$')) {
      return true;
    }
    // Is new DX DOM link?
    if (doc.KnownLinkPrefixes) {
      for (const prefix of doc.KnownLinkPrefixes) {
        if (link.startsWith(prefix + ':')) {
          return true;
        }
      }
    }
    return false;
  };
}

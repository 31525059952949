import styled from 'styled-components';

import { Img } from '../CleanSlate';
import { ProxyResponseSuccessInterface } from './ProxyResponseInterface';
import { getHeaderValue, makeDataUrl } from '../HttpUtils';

const ImageViewerImg = styled(Img)`
  display: block;
  margin: 0 auto;
  margin-top: 60px;
  max-width: 95%;
`;

/**
 * Image viewer for image responses
 */
export function ImageViewer(props: {
  response: ProxyResponseSuccessInterface;
}) {
  const contentType = getHeaderValue(props.response.Headers, 'content-type');
  const dataUrl = makeDataUrl(props.response.RawContent, contentType);
  return <ImageViewerImg src={dataUrl} alt="image body" />;
}

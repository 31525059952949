export function getDefaultTitleTemplate(title: string) {
  // no title in head
  if (!title) {
    return '{title}';
  }

  if (title.indexOf('|') !== -1) {
    return `{title} | ${title}`;
  } else {
    return `{title} - ${title}`;
  }
}

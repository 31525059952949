/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const AlertIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '15.087'}
    height={props.height || '15.087'}
    viewBox={props.height || '0 0 15.087 13.236'}
  >
    <g transform="translate(-0.783 -2.147)">
      <path
        d="M7.217,3.521,1.726,12.688a1.3,1.3,0,0,0,1.109,1.945H13.816a1.3,1.3,0,0,0,1.109-1.945L9.434,3.521a1.3,1.3,0,0,0-2.217,0Z"
        fill="none"
        stroke="#ff8a70"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        y2="3.48"
        transform="translate(8.325 6.265)"
        fill="none"
        stroke="#ff8a70"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x2="0.01"
        transform="translate(8.324 12.04)"
        fill="none"
        stroke="#ff8a70"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SVGIcon>
);

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown, { Components, uriTransformer } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import * as CS from './CleanSlate';
import { LinkMapperContext } from './LinkMapperContext';
import { TableWrapper } from './StyledElements';
import { PortalContextConsumer } from './PortalContext';
import { isExternalLink } from './HttpUtils';
import { CodeWithCopy } from './CodeWithCopy';
import { combinePaths } from './Utilities/utility';
import { ChatbotCodeBox } from './ChatbotCode';

export interface MarkdownProps {
  source?: string;
  inline?: boolean;
  className?: string;
  fromChatbot?: boolean;
  toggleChatbot?: () => void;
}

export const StyledLink = styled(Link)`
  ${CS.anchorMixin};

  & ${CS.InlineCode} {
    cursor: pointer;
  }

  ${CS.Em} & {
    font-style: italic;
  }

  ${CS.Strong} & {
    font-weight: 500;
  }
`;
const Li = styled(CS.Li)`
  > ${CS.P} {
    display: initial;
  }
`;
const ChatbotPre = styled(CS.Pre)`
  padding: 0px;
  margin: 0px;
`;

/**
 * Renders Markdown source
 * @param props
 */
export const Markdown = React.memo(function MarkdownComp({
  className,
  source,
  inline,
  toggleChatbot,
}: MarkdownProps) {
  const { isDxDomLink } = React.useContext(LinkMapperContext);
  const transformLinkUri = React.useCallback(
    (link: string) => (isDxDomLink(link) ? link : uriTransformer(link)),
    [isDxDomLink]
  );

  /**
   * Customize how different elements are rendered in Markdown.
   * We use the custom elements from CleanSlate for this.
   */

  const portalRenderers: Components = {
    a: ({ href = '', children }) => {
      const handleClick = () => {
        if (toggleChatbot) {
          toggleChatbot();
        }
      };

      return (
        <LinkMapperContext.Consumer>
          {(linkMapper) =>
            linkMapper.isDxDomLink(href) ? (
              <StyledLink to={linkMapper(href)} onClick={handleClick}>
                {children}
              </StyledLink>
            ) : isExternalLink(href) ? (
              <CS.A href={href} target="_blank" rel="nofollow noopener">
                {children}
              </CS.A>
            ) : (
              <CS.A href={href} onClick={handleClick}>
                {children}
              </CS.A>
            )
          }
        </LinkMapperContext.Consumer>
      );
    },
    h1: ({ children }) => {
      return <CS.H1>{children}</CS.H1>;
    },
    h2: ({ children }) => {
      return <CS.H2>{children}</CS.H2>;
    },
    h3: ({ children }) => {
      return <CS.H3>{children}</CS.H3>;
    },
    h4: ({ children }) => {
      return <CS.H4>{children}</CS.H4>;
    },
    h5: ({ children }) => {
      return <CS.H5>{children}</CS.H5>;
    },
    h6: ({ children }) => {
      return <CS.H6>{children}</CS.H6>;
    },
    p: ({ children }: { children: React.ReactNode }) => {
      // TODO: Improve markdown typings
      const reactChildNode = children as React.ReactElement[];
      const count = React.Children.count(reactChildNode);
      if (count === 1 && reactChildNode[0].type === 'img') {
        return <CS.P className="display-block">{reactChildNode}</CS.P>;
      }
      return <CS.P>{reactChildNode}</CS.P>;
    },
    strong: ({ children }) => {
      return <CS.Strong>{children}</CS.Strong>;
    },
    em: ({ children }) => {
      return <CS.Em>{children}</CS.Em>;
    },
    table: ({ children }) => {
      return (
        <TableWrapper overflowX={true} overflowY={true}>
          <CS.Table className="table-wrapper">{children}</CS.Table>
        </TableWrapper>
      );
    },
    thead: ({ children }) => {
      return <CS.Thead>{children}</CS.Thead>;
    },
    tr: ({ children }) => {
      return <CS.Tr>{children}</CS.Tr>;
    },
    tbody: ({ children }) => {
      return <CS.Tbody>{children}</CS.Tbody>;
    },
    td: ({ children }) => {
      return <CS.Td>{children}</CS.Td>;
    },
    th: ({ children }) => {
      return <CS.Th className="testing">{children}</CS.Th>;
    },
    blockquote: ({ children }) => {
      return <CS.Blockquote>{children}</CS.Blockquote>;
    },
    code: ({ inline, children, className }) => {
      const match = /language-(\w+)/.exec(className || '');
      const lang = match ? match[1] : undefined;
      return inline ? (
        <CS.InlineCode>{children}</CS.InlineCode>
      ) : (
        <CodeWithCopy
          text={String(children)}
          lang={lang}
          code={String(children)}
        />
      );
    },
    img: ({ src = '', alt }) => {
      return isExternalLink(src) ? (
        <CS.Img src={src} alt={alt} />
      ) : (
        <PortalContextConsumer>
          {(ctxt) =>
            ctxt && <CS.Img src={combinePaths(ctxt.baseUrl, src)} alt={alt} />
          }
        </PortalContextConsumer>
      );
    },
    hr: () => <CS.Hr />,
    ol: ({ start = 1, children }) => {
      let attrs = {};

      if (start !== 1) {
        attrs = { start: start.toString() };
      }

      return <CS.Ol {...attrs}>{children}</CS.Ol>;
    },
    ul: ({ children }) => <CS.Ul>{children}</CS.Ul>,
    li: ({ children, checked }) => {
      const ListItem = checked !== null ? CS.CheckedLi : Li;
      return <ListItem>{children}</ListItem>;
    },
  };

  /**
   * Same as renderers except paragraph is disabled.
   */
  const inlineRenderers: Components = {
    ...portalRenderers,
    p: ({ children }) => {
      return <React.Fragment>{children}</React.Fragment>;
    },
  };

  const chatBotRenderers: Components = {
    ...portalRenderers,
    code: ({ inline, children, className }) => {
      const match = /language-(\w+)/.exec(className || '');
      const lang = match ? match[1] : undefined;
      return inline ? (
        <CS.InlineCode>{children}</CS.InlineCode>
      ) : (
        <ChatbotCodeBox sourceCode={String(children)} language={lang} />
      );
    },
    pre: ({ children }) => {
      return <ChatbotPre>{children}</ChatbotPre>;
    },
  };

  const remarkPlugins = [remarkGfm];

  const rehypePlugins = [rehypeRaw];

  const selectRenderer = inline ? inlineRenderers : portalRenderers;

  const renderer =
    className === 'chatbot-markdown-renderer'
      ? chatBotRenderers
      : selectRenderer;

  const MarkdownRenderer = (markdownString: string) => {
    const markdownSource = splitString(markdownString);

    if (markdownSource.length <= 1) {
      return (
        <ReactMarkdown
          className={className}
          components={renderer}
          remarkPlugins={remarkPlugins}
          rehypePlugins={rehypePlugins}
          transformLinkUri={transformLinkUri}
        >
          {markdownString}
        </ReactMarkdown>
      );
    }

    return (
      <>
        {markdownSource.map((source) => {
          return (
            <ReactMarkdown
              className={className}
              components={renderer}
              remarkPlugins={remarkPlugins}
              rehypePlugins={rehypePlugins}
              transformLinkUri={transformLinkUri}
            >
              {source}
            </ReactMarkdown>
          );
        })}
      </>
    );
  };

  return source ? MarkdownRenderer(source) : null;
});

function splitString(input: string) {
  const regex = /(<ul>.*?<\/ul>|<br\/><ul>.*?<\/ul>|<\/blockquote>)/g;
  return input.split(regex);
}

import * as React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import { ResponseInfoAlert } from './ResponseInfoAlert';

/**
 * Error boundary component for ResponseViewer
 */

interface ResponseViewerErrorBoundaryProps {
  children: React.ReactNode;
}

export class ResponseViewerErrorBoundary extends React.Component<ResponseViewerErrorBoundaryProps> {
  render() {
    return (
      <SentryErrorBoundary
        fallback={
          <ResponseInfoAlert>
            Could not show the response body.
          </ResponseInfoAlert>
        }
        beforeCapture={(scope) => {
          scope.setTag(
            'apimaticAppReferance',
            'Response Viewer Error Boundary'
          );
        }}
      >
        {this.props.children}
      </SentryErrorBoundary>
    );
  }
}

import { SVGIcon, SVGIconProps } from './SvgIcon';

export const AlphaTagIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '28'}
    height={props.height || '11'}
    viewBox={props.viewBox || '0 0 28 11'}
    fill={props.fill || 'none'}
  >
    <g clip-path="url(#clip0_538_13015)">
      <rect width="28" height="11" rx="2" fill="#6B7E8F" />
      <path
        d="M3.93231 7.99998H2.54736L4.10773 3.27271H5.86199L7.42236 7.99998H6.03742L5.00333 4.57455H4.9664L3.93231 7.99998ZM3.67378 6.13492H6.27748V7.09515H3.67378V6.13492Z"
        fill="white"
      />
      <path
        d="M7.87766 7.99998V3.27271H9.16104V6.96589H11.0723V7.99998H7.87766Z"
        fill="white"
      />
      <path
        d="M11.591 7.99998V3.27271H13.6315C13.9824 3.27271 14.2894 3.34195 14.5525 3.48045C14.8157 3.61894 15.0203 3.8136 15.1665 4.06443C15.3127 4.31526 15.3858 4.60841 15.3858 4.94387C15.3858 5.28241 15.3104 5.57556 15.1596 5.82331C15.0103 6.07106 14.8003 6.26187 14.5294 6.39575C14.2601 6.52963 13.9454 6.59657 13.5854 6.59657H12.3666V5.59941H13.3268C13.4776 5.59941 13.6061 5.57325 13.7123 5.52093C13.82 5.46707 13.9024 5.3909 13.9593 5.29241C14.0178 5.19393 14.047 5.07775 14.047 4.94387C14.047 4.80845 14.0178 4.69304 13.9593 4.59763C13.9024 4.50069 13.82 4.42682 13.7123 4.37604C13.6061 4.32372 13.4776 4.29756 13.3268 4.29756H12.8744V7.99998H11.591Z"
        fill="white"
      />
      <path
        d="M15.8567 7.99998V3.27271H17.14V5.1193H18.8389V3.27271H20.1223V7.99998H18.8389V6.15339H17.14V7.99998H15.8567Z"
        fill="white"
      />
      <path
        d="M21.9597 7.99998H20.5747L22.1351 3.27271H23.8893L25.4497 7.99998H24.0648L23.0307 4.57455H22.9937L21.9597 7.99998ZM21.7011 6.13492H24.3048V7.09515H21.7011V6.13492Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_538_13015">
        <rect width="28" height="11" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
);

import { PureComponent } from 'react';
import styled from 'styled-components';

import {
  getUrlFriendlyName,
  sortPlatforms,
  filterPlatforms,
} from '../PlatformConstants';
import { withPortalContext } from '../PortalContext';
import { Language, PortalSettings } from '../PortalSettings';
import { FlexParent } from '../StyledElements';
import { PublishedPackage } from '../DxDom';
import { GetSdk } from './GetSdk';
import { ApiExportButton } from './ApiExportButton';
import { QuickSearch } from 'src/QuickSearch';
import { DocumentContextConsumer } from 'src/DocumentContext';
import { RoutedPlatform } from 'src/Platform/RoutedPlatform';
import { LinkMapper } from 'src/LinkMapperContext';

export interface InlineNavbarProps {
  isInlineNavbar?: boolean;
  isOnlySearch?: boolean;
}

const ActionsWrapper = styled(FlexParent)<InlineNavbarProps>`
  min-width: ${(props) => (props.isInlineNavbar ? '100px' : '186px')};
  justify-content: space-between;
  margin-top: ${(props) => (props.isInlineNavbar ? '0' : '0')};
  flex: ${(props) => (props.isInlineNavbar ? 'none' : '1')};

  ${(props) =>
    props.isOnlySearch
      ? `
				display: none;
			`
      : ''};
  @media screen and (max-width: 990px) {
    margin: 0;
  }
  @media screen and (max-width: 575px) {
    margin: 0;
    min-width: ${(props) => (props.isInlineNavbar ? '74px' : '144px')};
  }
`;
ActionsWrapper.displayName = 'ActionsWrapper';

const NavBarWrapper = styled(FlexParent)<InlineNavbarProps>`
  margin-top: 20px;
  padding: 0 15px;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isInlineNavbar ? 'row' : 'column')};

  .platform-selector-wrapper {
    display: none;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    padding: 0;
    margin-top: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 5px;

    .platform-selector-wrapper {
      display: block;
    }
  }

  @media screen and (max-width: 575px) {
    width: auto;
    padding-left: 0px;
  }
`;
NavBarWrapper.displayName = 'NavBarWrapper';

const NavBarSubWrapper = styled(FlexParent)<InlineNavbarProps>`
  .platform-selector-wrapper {
    display: none;
  }
  @media screen and (max-width: 990px) {
    min-width: ${(props) =>
      props.isInlineNavbar && props.isOnlySearch
        ? '113px'
        : props.isInlineNavbar
        ? '214px'
        : '297px'};
    flex-direction: row-reverse;
    justify-content: ${(props) =>
      props.isInlineNavbar ? 'end' : 'space-between'};
    margin-left: 5px;

    .platform-selector-wrapper {
      display: block;
      min-width: 108px;
    }
  }
  @media screen and (max-width: 575px) {
    width: auto;
    padding-left: 0px;
    min-width: ${(props) =>
      props.isInlineNavbar && props.isOnlySearch
        ? '120'
        : props.isInlineNavbar
        ? '149px'
        : '219px'};
    margin-left: 0px;

    .platform-selector-wrapper {
      display: block;
      max-width: 105px;
    }
  }
`;

export interface NavbarProps {
  onTemplateChange?: (tpl: Language) => void;
  disabled?: boolean;
  packageInfo?: PublishedPackage;
  currentTemplate: Language;
  linkMapper?: LinkMapper;
  onNavigate?: (key: string) => void;

  isWorkflowPage?: boolean;
}

type NavbarCompProps = NavbarProps & { portalSettings: PortalSettings };

/**
 * Navbar component
 */
class NavbarComp extends PureComponent<NavbarCompProps> {
  onTplChange = (tpl: Language) => {
    if (!this.props.disabled && this.props.onTemplateChange) {
      this.props.onTemplateChange(tpl);
    }
  };

  render() {
    const {
      portalSettings: { languageSettings, enableExport },
      currentTemplate,
      packageInfo,
      disabled,
      onNavigate,
      isWorkflowPage,
    } = this.props;

    const allowedLanguage = Object.keys(languageSettings) as Array<Language>;
    const filteredPlatforms = filterPlatforms(allowedLanguage);
    const platforms = sortPlatforms(allowedLanguage, filteredPlatforms);
    const lang = platforms.findIndex((m) =>
      m.templates.some((t) => t.svalue === currentTemplate)
    );

    const disableSDKDownload =
      !!languageSettings[currentTemplate]!.disableSdkDownload;

    const isOnlyHTTP =
      allowedLanguage.length === 1 && allowedLanguage[0] === 'http_curl_v1';
    const isHTTPandNoSdk =
      platforms[lang].noSDK ||
      platforms[lang].name === 'HTTP' ||
      disableSDKDownload;

    const isInlineNavbar = isOnlyHTTP || !enableExport;
    return (
      <DocumentContextConsumer>
        {(doc) =>
          doc && (
            <NavBarWrapper
              isInlineNavbar={isInlineNavbar}
              isOnlySearch={isOnlyHTTP && !enableExport}
              className="NavBar-Wrapper"
            >
              <NavBarSubWrapper
                isInlineNavbar={isInlineNavbar}
                isOnlySearch={isOnlyHTTP && !enableExport}
                className="Sub-NavBar-Wrapper"
              >
                <RoutedPlatform
                  disabled={this.props.disabled}
                  currentTemplate={this.props.currentTemplate}
                  linkMapper={this.props.linkMapper}
                  packageInfo={this.props.packageInfo}
                />
                <ActionsWrapper
                  isInlineNavbar={isInlineNavbar}
                  isOnlySearch={isOnlyHTTP && !enableExport}
                  className="Action-Wrapper"
                >
                  {!isOnlyHTTP && !isWorkflowPage && (
                    <GetSdk
                      packageInfo={packageInfo}
                      currentTemplate={currentTemplate}
                      selectedPlatform={getUrlFriendlyName(currentTemplate)}
                      disabled={isHTTPandNoSdk}
                      isInlineNavbar={isInlineNavbar}
                    />
                  )}
                  {enableExport && !isWorkflowPage && (
                    <ApiExportButton
                      isInlineNavbar={isInlineNavbar}
                      portalSettings={this.props.portalSettings}
                    />
                  )}
                </ActionsWrapper>
              </NavBarSubWrapper>
              {!isWorkflowPage && (
                <QuickSearch
                  navItems={doc.NavItems}
                  disabled={disabled}
                  embed={false}
                  onSelect={onNavigate}
                  isInlineNavbar={isInlineNavbar}
                  isOnlySearch={isOnlyHTTP && !enableExport}
                  portalSettings={this.props.portalSettings}
                />
              )}
            </NavBarWrapper>
          )
        }
      </DocumentContextConsumer>
    );
  }
}

/**
 * Renders a navbar that contains a language selector and SDK and API export buttons
 */
export const Navbar = withPortalContext(NavbarComp);

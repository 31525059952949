import styled from 'styled-components';
import { P } from '../CleanSlate';

/**
 * Info message component for use in ResponseViewer
 */
export const ResponseInfoAlert = styled(P)`
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.3);
  letter-spacing: 0.01em;
`;

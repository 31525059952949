import * as React from 'react';
import { PublishedPackage } from 'src/DxDom';
import { filterPlatforms, sortPlatforms } from 'src/PlatformConstants';
import { withPortalContext } from 'src/PortalContext';
import { Language, PortalSettings } from 'src/PortalSettings';
import { PlatformSelector } from './PlatformSelector';

interface PlatformProps {
  onTemplateChange?: (tpl: Language) => void;
  disabled?: boolean;
  packageInfo?: PublishedPackage;
  currentTemplate: Language;
}

type PlatformCompProps = PlatformProps & { portalSettings: PortalSettings };

/**
 * Platform component
 */
class PlatformComp extends React.PureComponent<PlatformCompProps> {
  onTplChange = (tpl: Language) => {
    if (!this.props.disabled && this.props.onTemplateChange) {
      this.props.onTemplateChange(tpl);
    }
  };

  render() {
    const { portalSettings, currentTemplate } = this.props;
    const { languageSettings } = portalSettings;
    const allowedLanguage = Object.keys(languageSettings) as Array<Language>;

    const filteredPlatforms = filterPlatforms(allowedLanguage);
    const platforms = sortPlatforms(
      allowedLanguage,
      filteredPlatforms,
      languageSettings
    );
    const lang = platforms.findIndex((m) =>
      m.templates.some((t) => t.svalue === currentTemplate)
    );

    return (
      <PlatformSelector
        selected={lang}
        onSelect={this.onTplChange}
        disabled={this.props.disabled}
        platforms={platforms}
        portalSettings={portalSettings}
      />
    );
  }
}

/**
 * Renders a navbar that contains a language selector and SDK and API export buttons
 */
export const Platform = withPortalContext(PlatformComp);

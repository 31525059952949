import { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { Div } from 'src/CleanSlate';

type TooltipType = {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  placement?: string;
};

type TooltipsProps = TooltipType;

export const TooltipStyle = styled(Div)`
  max-width: 200px;
  padding: 6.4px;
  position: absolute;
  z-index: 999999999;

  &.bottom {
    top: ${(props: TooltipType) => props.top || '-45px'};
    left: ${(props: TooltipType) => props.left || '-20px'};
    right: ${(props: TooltipType) => props.right};
    bottom: ${(props: TooltipType) => props.bottom};
  }
`;
TooltipStyle.displayName = 'Toolttip';

export const TooltipInnerStyle = styled(Div)`
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  padding: 4px 8px;
  font-size: 11.85px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
`;
TooltipInnerStyle.displayName = 'TooltipInner';

export const Arrow = styled(Div)`
  position: absolute;
  display: block;
  width: 12.8px;
  height: 6.4px;
  bottom: ${(props: TooltipType) => (props.placement === 'bottom' ? '0' : '')};
  top: ${(props: TooltipType) => (props.placement === 'top' ? '0' : '')};
  left: ${(props: TooltipType) => (props.placement === 'left' ? '0' : '')};
  right: ${(props: TooltipType) => (props.placement === 'right' ? '0' : '')};

  &.arrow-bottom {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.8);
    margin: 0 auto;
    left: 50%;
    bottom: -2px;
    transform: translate(-50%, -3px);
  }
`;
Arrow.displayName = 'Arrow';

export class Tooltip extends PureComponent<TooltipsProps> {
  render() {
    return createPortal(
      <TooltipStyle {...this.props} className={this.props.placement}>
        <Arrow
          placement={this.props.placement}
          className={'arrow-' + this.props.placement}
        />
        <TooltipInnerStyle> copied </TooltipInnerStyle>
      </TooltipStyle>,
      document.body
    );
  }
}

import styled from 'styled-components';
import { Div, Span } from '../CleanSlate';
import Tooltip from 'src/Tooltip/Tooltip';
import { InfoIconStatus } from 'src/Icons';

/**
 * HTTP status code classifications
 */
enum StatusCodeClasses {
  dummy,
  info,
  success,
  redirection,
  error,
}

const statusCodeClasses: StatusCodeClasses[] = [
  StatusCodeClasses.dummy,
  StatusCodeClasses.info,
  StatusCodeClasses.success,
  StatusCodeClasses.redirection,
  StatusCodeClasses.error,
  StatusCodeClasses.error,
];

/**
 * HTTP status code wrapper
 */
const Status = styled(Div)`
  font-size: 12px;
  position: absolute;
  right: 36px;
  top: 0px;
  line-height: 1.6em;
  color: ${(props) => props.theme.colors.C001};
  z-index: 1;
  display: flex;
  align-items: center;
  height: 36px;
  .reason {
    font-size: 12px;
    color: ${(props) => props.theme.colors.C001};
    margin-left: 5px;
    margin-right: 0px;
  }

  @media screen and (max-width: 990px) {
    right: 13px;
  }
`;
Status.displayName = 'Status';

const StatusIcon = styled(Span)`
  cursor: pointer;
  margin: 5px 0px 0px 5px;
  display: flex;
`;

/**
 * HTTP status code classification to CSS color mappings
 */
const statusCircleStatusColors = {
  [StatusCodeClasses.success]: '#49d560',
  [StatusCodeClasses.error]: '#f01833',
  [StatusCodeClasses.info]: '#00acee',
  [StatusCodeClasses.redirection]: '#00acee',
};

/**
 * A colored circle for HTTP status code
 */
const StatusCircle = styled(Span)<{ status: StatusCodeClasses }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 20px;
  display: block;
  float: left;
  margin-right: 7px;
  background: ${({ status }) =>
    statusCircleStatusColors[status as keyof typeof statusCircleStatusColors]};
`;
StatusCircle.displayName = 'StatusCircle';

/**
 * HTTP status code component
 * @param props
 */
export function StatusCode({
  code,
  reason,
}: {
  code: number;
  reason?: string;
}) {
  const statusCodeType = statusCodeClasses[Math.floor(code / 100)];
  const shortString = reason && truncateString(reason);
  return (
    <Status>
      <StatusCircle status={statusCodeType} />
      {code}
      {!shortString && (
        <Span className="reason hide-sm">{shortString || reason}</Span>
      )}
      {shortString && (
        <StatusIcon>
          <Tooltip text={reason} placement="bottom-end">
            <InfoIconStatus />
          </Tooltip>
        </StatusIcon>
      )}
    </Status>
  );
}

function truncateString(text: string) {
  if (text.length > 11) {
    return text.substring(0, 11) + '...';
  } else {
    return false;
  }
}

import styled from 'styled-components';

import { CodeBlock, CodeBlockCode } from 'src/StyledElements';
import { blockCodeMixin } from '../CleanSlate';
import { CodeHighlighter } from '../CodeHighlighter';

const CodeWrapper = styled(CodeBlockCode)`
  padding: 8px 14px;
  & pre,
  & > code {
    ${blockCodeMixin};
    white-space: pre !important;
  }
`;

/**
 * Code viewer for highlightable text responses
 */
export function CodeViewer(props: { lang: string; children: string }) {
  return (
    <CodeBlock type="column">
      <CodeWrapper scrollWidth="4px" invert={true}>
        <CodeHighlighter code={props.children} dark={true} lang={props.lang} />
      </CodeWrapper>
    </CodeBlock>
  );
}

/**
 * This file should be loaded as a bundle by code-splitting because
 * the dependencies used here are quite heavy.
 */
import { useCallback, useContext, useEffect } from 'react';
import { Form } from '@dx-portal/rjsf';
import { JSONSchema6 } from 'json-schema';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Div, InlineCode } from './CleanSlate';

// TODO Need to get rid of these global imports eventually
// because this breaks encapsulation
import { Markdown } from './Markdown';
import {
  discriminatorMixin,
  reactSelectMixin,
  reactDatePickerMixin,
  ScrollableMixin,
  rotateMixin,
  textAreaMixin,
  PrimaryButtonMixin,
} from './StyledElements';
import { renderCssStyles } from './Theme/Theme';

import Color from 'color';
import { Popover } from './base-components';
import { HttpTypes } from './TypesPopover';
import { Document } from './DxDom';
import { DocumentContext } from './DocumentContext';
import { LinkMapperContext } from './LinkMapperContext';
import { Callout } from './MultipleResponses/Callout';
import Tooltip from './Tooltip/Tooltip';
import {
  getSteps,
  WorkflowContext,
  WorkflowEndpointContext,
} from './Context/WorkflowContext';
import { AuthRendererProps } from './Authorization/Authorization';
import { PortalSettings } from './PortalSettings';
/**
 * Adapted from old portal project
 *
 * TODO Rewrite into something human-readable
 */

const BORDER_RADIUS = '8px';
const DANGER_COLOR = '#F23A25';

interface CalloutProps {
  message?: string;
  icon?: 'info' | 'success' | 'error' | 'alert';
}
/**
 *
 * @param color : color of even rjsf depth;
 * @param maxDepth : Max depth of the nesting
 * @returns multiple rjsf classes based on depth level
 */
function getDynamicDepthStyling(color: string, maxDepth = 20) {
  const classes = [];

  for (let i = 0; i < maxDepth; i++) {
    if (i % 2 === 0) {
      classes.push(`
         .rjsf-depth_${i} {
           .code-with-copy {
             background: ${color};
             margin: 0px;
           }
         }
       `);
    } else {
      classes.push(`
         .rjsf-depth_${i} {
           .code-with-copy {
             background: #fff;
             margin: 0px;
           }
         }
       `);
    }
  }

  return classes.join(' ');
}

const StyledJSchemaForm = styled(Div)`
  width: 100%;
  padding: 0 15px;
  font-family: ${({ theme: { cssStyles } }) =>
    cssStyles && cssStyles.body.fontFamily};
  @media screen and (max-width: 520px) {
    ${ScrollableMixin};
    overflow: visible;
  }

  #root__title {
    display: none;
  }
  #root_showFullCode__title,
  #root_showFullCode__title + div {
    display: none;
  }
  svg {
    pointer-events: none;
  }
  .react-code-sample {
    position: relative;
    height: 100%;
  }

  button[type='submit'] {
    display: none;
  }

  .rjsf > p {
    margin: 0;
  }

  button {
    &:not(.react-datepicker__navigation, .reset-token-button) {
      background: none;
      border: none;

      &:hover,
      &:focus {
        cursor: pointer;
        outline: none;
        border: none;
      }
    }
  }
  .rjsf-panel {
    margin: 15px 10px;
    display: none;
    &.rjsf-errors {
      border-left: 4px solid ${DANGER_COLOR};
      .rjsf-panel-heading h3 {
        margin: 0 0 10px 22px;
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text1.fontSize) || '16px'};

        font-weight: 500;
      }
      .rjsf-list-group {
        margin: 0;
        .rjsf-list-group-item {
          font-size: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
          line-height: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
        }
      }
    }
  }
  form {
    max-width: 100%;
    /* min-width: 600px; */
    @media screen and (max-width: 520px) {
      min-width: 520px;
    }
  }
  .rjsf-error-detail {
    font-size: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
    line-height: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
    list-style: none;
    margin: 5px 0px 0px 0px;
    padding: 0px 0px 5px 0px;
    li {
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
      line-height: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
      color: ${DANGER_COLOR};
    }
  }
  .config-button {
    border: 1px solid ${({ theme }) => theme.colors.C300};
    color: ${({ theme }) => theme.colors.C801};
    font-size: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
    line-height: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
    margin: 15px 15px 15px 25px;
    line-height: 25px;
    border-radius: 8px;
    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.C300};
    }
  }
  .config-settings {
    form {
      border: none;
    }
    button[type='submit'] {
      display: none;
    }
    .rjsf-showFullCode {
      display: none;
    }
    fieldset {
      margin-top: 15px;
      border: 1px solid ${({ theme }) => theme.colors.C300};
      padding: 5.6px 12px 10px 12px;
      legend {
        color: ${({ theme }) => theme.colors.C801};
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
        line-height: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
        font-weight: 500;
        text-transform: none;
        width: auto;
        padding: 0 2px;
        margin-bottom: 0;
      }
      label[for='root_showFullCode'] {
        display: none;
        .rjsf-field-radio-group {
          display: none;
        }
      }
      .rjsf-field {
        label {
          font-weight: 700;
          margin: 0;
        }
        .field-description {
          margin: 0px;
          color: ${DANGER_COLOR} !important;
          white-space: pre-wrap;
        }
      }
      textarea {
        ${textAreaMixin}
      }

      input,
      textarea,
      select {
        max-width: 250px;
        height: 32px;
        border: 1px solid ${({ theme }) => theme.colors.C300};
        margin-top: 14px;
        padding: 0px 12px;
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.C801};
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text3.fontSize) || '12.64'};
        &:hover,
        &:focus {
          outline: none;
          border: 1px solid ${(props) => props.theme.primaryColor || '#0058a9'};
        }
      }
      select[multiple] {
        height: 80px;
      }
      textarea:nth-child(1),
      input:nth-child(1) {
        margin-left: -1px;
      }
    }
  }

  fieldset {
    border: none;
    padding: 5.6px 12px 10px 12px;
    margin-bottom: 0px;
    legend {
      color: #898fa3;
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text1.fontSize) || '16px'};
      font-family: ${({ theme }) =>
        (theme.cssStyles && theme.cssStyles.code.fontFamily) || 'monospace'};

      font-weight: 500;
      text-transform: uppercase;
      width: auto;
      padding: 0 2px;
      margin-bottom: 0;
    }
    fieldset {
      border: 1px solid ${({ theme }) => theme.colors.C300};
      padding: 5.6px 12px 10px 12px;
      legend {
        color: ${({ theme }) => theme.colors.C801};
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text1.fontSize) || '16px'};
        font-family: ${({ theme }) =>
          (theme.cssStyles && theme.cssStyles.code.fontFamily) || 'monospace'};

        font-weight: 500;
        text-transform: none;
      }
    }
  }
  .rjsf-form-group {
    margin-bottom: 10px;
    label,
    p {
      color: ${({ theme }) => theme.colors.C801};
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
      line-height: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
      margin: 0;
    }

    label {
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text1.fontSize) || '16px'};
      font-family: ${({ theme }) =>
        (theme.cssStyles && theme.cssStyles.code.fontFamily) || 'monospace'};
      font-weight: 600;
      line-height: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text1.lineHeight) || '1.5'};
    }

    textarea {
      ${textAreaMixin};
    }
    input,
    textarea,
    select {
      height: 32px;
      border: 1px solid ${({ theme }) => theme.colors.C300};
      margin-top: 5px;
      padding: 0px 12px;
      color: ${({ theme }) => theme.colors.C801};
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '11.85px'};
      line-height: normal;
      &:hover,
      &:focus {
        outline: none;
        border: 1px solid ${(props) => props.theme.primaryColor || '#0058a9'};
      }
    }

    select[multiple] {
      height: 80px;
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox'],
    input[type='radio'] {
      height: auto;
      position: relative;
      top: 3px;
      margin: 3px;
    }
    pre {
      color: ${({ theme }) => theme.colors.C801};
    }
    .rjsf-field {
      legend {
        color: ${({ theme }) => theme.colors.C901};
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text1.fontSize) || '16px'};
        font-weight: 600;
        width: auto;
        padding: 0 2px;
        margin-bottom: 0;
        border-bottom: none;
      }
      label {
        font-weight: 600;
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text1.fontSize) || '16px'};
        margin: 0;
        line-height: normal;
      }
      .rjsf-field-description {
        margin: 0px;
        padding: 5px 0;
        overflow-wrap: break-word;

        * {
          font-size: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text2.fontSize) || '14px'};
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
        }

        p {
          word-break: break-word;
          margin-bottom: 12px;
          > strong {
            font-weight: 500;
          }
        }

        ${InlineCode} {
          word-break: break-word;
        }

        p:last-child,
        div:last-child {
          margin-bottom: 0px;
        }

        &.tag-description {
          padding: 0px;
        }
      }
    }

    textarea {
      ${textAreaMixin};
    }
    input,
    textarea,
    select {
      max-width: 250px;
      height: 32px;
      border: 1px solid ${({ theme }) => theme.colors.C300};
      border-radius: 8px;
      /* margin-top: 11px; */
      padding: 0px 12px;
      color: ${({ theme }) => theme.colors.C801};
      &:hover,
      &:focus {
        outline: none;
        border: 1px solid ${(props) => props.theme.primaryColor || '#0058a9'};
      }
    }

    select:disabled,
    textarea:disabled,
    input:disabled {
      background: ${({ theme }) => theme.colors.C300} !important;
      color: ${({ theme }) => theme.colors.C801};
      border: 1px solid ${({ theme }) => theme.colors.C400};
    }

    select[multiple] {
      height: 80px;
    }
    input[type='file'] {
      border: none;
      max-width: 300px;
    }
    textarea:nth-child(1),
    input:nth-child(1) {
      margin-left: -1px;
    }
    .rjsf-file-info {
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text1.fontSize) || '16px'};
    }
  }
  .rjsf-array-item-list {
    .rjsf-array-item {
      float: none;
      clear: both;

      [class*='col-'] {
        float: left;
      }
      .rjsf-col-xs-9 {
        width: 80%;
      }
      .rjsf-field {
        label {
          font-weight: 600;
        }
        .rjsf-field-description {
          margin: 0px;
        }
      }
      textarea {
        ${textAreaMixin};
      }
      input,
      textarea,
      select {
        max-width: 250px;
        height: 32px;
        border: 1px solid ${({ theme }) => theme.colors.C300};
        /* margin-top: 11px; */
        padding: 0px 12px;
        color: ${({ theme }) => theme.colors.C801};
        &:hover,
        &:focus {
          outline: none;
          border: 1px solid ${(props) => props.theme.primaryColor || '#0058a9'};
        }
      }
      select[multiple] {
        height: 80px;
      }
      textarea:nth-child(1),
      input:nth-child(1) {
        margin-left: -1px;
      }
      ${reactSelectMixin}
      .rjsf-array-item-remove {
        margin: 10px 0 0 10px;
        i {
          color: ${({ theme }) => theme.colors.C801};
          font-size: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
          line-height: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
          -webkit-text-stroke: 1px #fff;
        }
      }
      .rjsf-array-item-toolbox {
        i {
          color: ${({ theme }) => theme.colors.C801};
          font-size: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
          line-height: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
          -webkit-text-stroke: 1px #fff;
        }
        .rjsf-array-item-move-up {
          margin: 0 0 0 3px;
        }
        .rjsf-array-item-move-down {
          margin: 0 0 0 3px;
        }
        .rjsf-array-item-remove {
          margin: 0 0 0 3px;
        }
      }
    }
  }
  .rjsf-array-item-add,
  .rjsf-map-item-add {
    float: none;
    clear: both;
    display: flex;
    justify-content: flex-end;

    button {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border: 1px solid ${({ theme }) => theme.primaryColor};
      border-radius: 8px;
      padding: 0 24px;
      font-weight: 400;
      background-color: #fff;
      color: ${({ theme }) => theme.primaryColor};
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '13px'} !important;
      line-height: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
      & .rjsf-icon > path {
        fill: ${({ theme }) => theme.primaryColor};
      }
      svg {
        margin-right: 4px;
      }
      &:focus {
        border: 1px solid ${(props) => props.theme.primaryColor};
      }
      &:hover {
        ${PrimaryButtonMixin}
      }
    }
  }
  .rjsf-map-item > .rjsf-array-item-list .rjsf-array-item [class*='col-'] {
    float: none;
  }
  .rjsf-map-item > .rjsf-col-xs-3,
  .rjsf-map-item > .rjsf-col-xs-8,
  .rjsf-map-item > .rjsf-col-xs-9,
  .rjsf-map-item > .rjsf-col-xs-1 {
    float: left;
  }
  .rjsf-map-item {
    clear: both;
  }

  .rjsf-map-item .rjsf-col-xs-12 {
    clear: both;
  }

  .rjsf-map-item > .rjsf-col-xs-3 {
    padding-top: 7px;
    margin-right: 4px;
  }
  .rjsf-field-array-of-object
    > .rjsf-map-item-list
    > .rjsf-map-item
    > .rjsf-col-xs-3 {
    max-width: 25%;
  }
  .rjsf-field-array-of-object
    > .rjsf-map-item-list
    > .rjsf-map-item
    > .rjsf-col-xs-3
    > input[type='text'] {
    width: 100%;
  }
  .rjsf-field-array-of-object
    > .rjsf-map-item-list
    > .rjsf-map-item
    > .rjsf-col-xs-8 {
    width: 64%;
  }
  .rjsf-field-array-of-object
    > .rjsf-map-item-list
    > .rjsf-map-item
    > .rjsf-col-xs-8
    > .rjsf-field-object {
    padding-top: 14px;
  }
  .rjsf-field-array-of-object
    > .rjsf-map-item-list
    > .rjsf-map-item
    > .rjsf-col-xs-1 {
    width: 5%;
  }
  .rjsf-map-item > .rjsf-map-item-toolbox {
    padding-left: 10px;
    line-height: 56px;
  }
  /* .rjsf-map-item-add {
     padding-top: 8px;
     margin-top: 13px;
   } */
  .rjsf-map-item > .rjsf-map-item-toolbox .rjsf-icon,
  .rjsf-map-item-add .rjsf-icon {
    color: ${({ theme }) => theme.colors.C901};
  }
  .rjsf-form-group p {
  }
  button.rjsf-btn.rjsf-toggle-button {
    padding-top: 0px;
    padding-bottom: 0px;
    @media screen and (max-width: 990px) {
      padding-right: 0px;
    }
  }
  .rjsf-btn-group {
    padding-top: 6px;
  }
  .rjsf-map-item input[type='checkbox'] {
    margin-top: 10px;
  }
  .rjsf-editor-validation-errors {
    ul {
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
      line-height: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
      margin: 0;
      background: rgba(242, 71, 34, 0.1);
      li {
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text3.fontSize) || '13px'};
        line-height: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text3.lineHeight) || '1.5'};
        color: ${DANGER_COLOR};
      }
    }
  }
  .rjsf-field-object > fieldset > legend input[type='checkbox'] {
    height: 13px;
    margin: 0;
  }
  .react-datepicker__time-list {
    padding: 0;
  }
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    top: 1px !important;
  }
  h2.react-datepicker__current-month {
    margin: 0 0 10px 0;
  }
  .react-datepicker-popper {
    z-index: 10 !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-right: 0;
  }
  .react-datepicker__close-icon {
    &::after {
      color: ${({ theme }) => theme.colors.C801};
      width: 13px;
      height: 13px;
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text1.fontSize) || '13.33px'};
      line-height: 1.2;
      padding: 4px 6px 0 0;
      font-weight: 500;
      background-color: transparent;
    }
    &:hover {
      &::after {
      }
    }
  }

  .react-datepicker__navigation {
    width: 16px !important;
    height: 16px !important;
    top: 12px !important;

    &.react-datepicker__navigation--next,
    &.react-datepicker__navigation--previous {
      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: #6a6a6a;
        content: '';
        display: inline-block;
        height: 7px;
        left: 2px;
        position: relative;
        top: 5px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        vertical-align: top;
        width: 7px;
      }
    }

    &.react-datepicker__navigation--previous {
      &::before {
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }

  .react-datepicker__navigation {
    width: 10px;
    height: 10px;

    &.react-datepicker__navigation--next,
    &.react-datepicker__navigation--previous {
      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        border-color: #6a6a6a;
        content: '';
        display: inline-block;
        height: 7px;
        left: 2px;
        position: relative;
        top: 5px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        vertical-align: top;
        width: 7px;
      }
    }

    &.react-datepicker__navigation--previous {
      &::before {
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous::before,
  .react-datepicker__navigation.react-datepicker__navigation--next::before {
    border-style: hidden !important;
  }
  .react-datepicker__navigation-icon--next::before {
    left: -14px !important;
  }

  // NEW STYLING

  .rjsf-error-detail {
    li {
      color: ${DANGER_COLOR};
      //todo: Replace it with theme text variants
      font-size: 10.53px;
    }
  }

  .rjsf-icon {
    display: inline-block;
    width: 12px;
    height: 12px;

    & > path {
      fill: ${({ theme }) => theme.colors.C801};
    }
  }

  .rjsf-object-header {
    display: flex;
    position: relative;
    align-items: center;

    &.position-unset {
      position: initial;

      @media (max-width: 1024px) {
        .rjsf-toggle-button {
          top: 36px;
          left: 0px;
        }
      }
      @media only screen and (min-width: 1024px) {
        .rjsf-toggle-button {
          top: 25px;
          left: 0px;
        }
      }
    }

    .rjsf-btn-default {
      height: 14px;
    }
  }

  .rjsf-header-left,
  .rjsf-field-header {
    position: relative;
    align-items: center;
    display: flex;
    flex: 1;
    cursor: pointer;
    .rjsf-control-label {
      cursor: pointer;
    }
  }

  .rjsf-field-header {
    label {
      // font-style: italic;
      font-weight: normal;
    }
    .chevron-icon-rotate-0 {
      ${rotateMixin(0)}
    }
    .chevron-icon-rotate-90 {
      ${rotateMixin(-90)}
    }
  }

  .rjsf-object-header legend,
  .rjsf-field-header label .label-text {
    margin-right: 8px;
    line-height: normal;
    word-break: break-all;
  }

  .rjsf-hand {
    cursor: pointer;
  }

  .rjsf-element-required {
    color: ${DANGER_COLOR};
    //todo: Replace it with theme text variants
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }

  fieldset {
    border: none !important;
    padding: 0px !important;

    legend {
      padding: 0px !important;
      textarea,
      input {
        margin: 0px 4px 0px px !important;
        top: 0px !important;
        height: auto !important;
      }
    }
  }

  .rjsf-discriminator-field-child {
    border-radius: ${BORDER_RADIUS};
    padding: 20px 25px !important;
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
  }

  .rjsf-discriminator-field,
  .rjsf-element-properties,
  .rjsf-array-container,
  .rjsf-map-field-value-container,
  .rjsf-map-item-list-container {
    border-radius: ${BORDER_RADIUS};
    padding: 20px 25px !important;
    position: relative;
    margin-top: 15px;
    transition: all 0.3s ease-in-out;
  }
  .rjsf-element-properties.rjsf-json-edit-view {
    padding: 10px 15px !important;
  }
  .rjsf-content {
    #root__object > .rjsf-element-properties {
      padding: 0px !important;
    }

    #root__object > .rjsf-element-properties > .rjsf-field {
      border-bottom: none;
    }

    > .rjsf-field {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .rjsf-depth_1 > .rjsf-element-properties > .rjsf-form-group:first-child {
      .rjsf-depth_2 {
        > .rjsf-object-header,
        > .rjsf-object-type,
        > .rjsf-array-container::before,
        > .rjsf-element-properties::before {
          display: none;
        }
      }
    }

    .rjsf-depth_1 {
      > .rjsf-object-header,
      > .rjsf-object-type,
      > .rjsf-array-container::before,
      > .rjsf-element-properties::before {
        display: none;
      }
    }

    .rjsf-depth_1,
    .rjsf-depth_2 {
      padding: 0px;
      margin: 0px;

      > .rjsf-element-properties {
        padding: 0px !important;
        margin: 0px !important;

        > .rjsf-field:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .rjsf-depth_1 {
    > .rjsf-object-header,
    > .rjsf-object-type,
    > .rjsf-array-container::before,
    > .rjsf-element-properties::before {
      display: none;
    }
  }

  .rjsf-discriminator-field::before,
  .rjsf-discriminator-field::before,
  .rjsf-element-properties::before,
  .rjsf-array-container::before,
  .rjsf-map-item-list-container::before,
  .rjsf-discriminator-field-child::before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 20px;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;
  }

  .rjsf-map-field-value-container::before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 20px;
    margin-top: -13px;
    border-width: 7px;
    border-style: solid;
    transform: rotate(180deg);
  }

  .rjsf-odd-bg,
  .rjsf-even-bg {
    &.rjsf-discriminator-field-child-empty {
      background: unset;
    }
  }

  .rjsf-odd-bg {
    background-color: ${({ theme }) => theme.colors.C201};

    &::before {
      border-color: #fff transparent transparent transparent;
    }

    &.rjsf-map-field-value-container {
      &::before {
        border-color: ${({ theme }) => theme.colors.C201} transparent
          transparent transparent;
      }
    }
  }

  .rjsf-even-bg {
    background-color: #fff;

    &::before {
      border-color: ${({ theme }) => theme.colors.C201} transparent transparent
        transparent;
    }

    &.rjsf-map-field-value-container {
      &::before {
        border-color: #fff transparent transparent transparent;
      }
    }
  }

  /* For  */
  .rjsf-depth_1 > .rjsf-element-properties > .rjsf-form-group:nth-child(2) {
    fieldset.rjsf-odd > .rjsf-map-item-list-container::before,
    fieldset.rjsf-odd
      > .rjsf-map-item-list-container
      > .rjsf-map-item-list
      > .rjsf-map-item
      > .rjsf-map-field-value-container::before {
      border-color: ${({ theme }) => theme.colors.C201} transparent transparent
        transparent;
    }
  }
  .rjsf-base-type,
  .rjsf-object-type,
  .rjsf-array-type,
  .rjsf-map-field-type {
    font-size: ${({ theme: { cssStyles } }) =>
      (cssStyles && cssStyles.body.text2.fontSize) || '11.85px'};
    font-family: ${({ theme }) =>
      (theme.cssStyles && theme.cssStyles.code.fontFamily) || 'monospace'};
    margin-right: 8px;
    a {
      word-break: break-word;
      font-family: inherit;
      color: ${({ theme }) => theme.linkColor};
      transition: all 0.3s ease-in-out;
      font-size: inherit;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .rjsf-type-container {
    display: flex;
  }
  .rjsf-type-container-dataType {
    margin-top: 8px;
  }

  .rjsf-base-type {
    display: inline-block;
    color: ${({ theme }) => theme.colors.C700};
  }
  .rjsf-object-type {
    display: inline-block;
  }
  .rjsf-array-type,
  .rjsf-map-field-type {
    display: inline-block;
    color: ${({ theme }) => theme.colors.C700};
  }
  .rjsf-param-type {
    font-size: ${(props) =>
      props.theme.cssStyles.body.text2.fontSize || '12.64px'};
    font-family: ${({ theme }) =>
      (theme.cssStyles && theme.cssStyles.code.fontFamily) || 'monospace'};
    color: ${({ theme }) => theme.colors.C700};
    display: inline-block;
    padding-left: 9px;
    font-weight: 500;
    position: relative;
    top: 1px;

    &::before {
      position: absolute;
      width: 1px;
      height: 60%;
      background-color: rgb(125 125 125 / 0.5);
      content: '';
      display: block;
      left: 1px;
      top: 21%;
    }
  }
  .rjsf-view-json-button {
    background-color: transparent !important;
    height: fit-content !important;
  }
  .rjsf-view-json-button:disabled,
  .rjsf-view-json-button[disabled] {
    opacity: 0.3;
    cursor: default;
  }
  .rjsf-toggle-button {
    cursor: pointer;
    position: absolute;
    left: -22px;
    height: 14px;

    @media screen and (max-width: 990px) {
      left: -26px;
    }

    svg {
      width: 12px;
      height: 12px;
      stroke: ${({ theme }) => theme.colors.C901};
    }

    &.rjsf-object-toggle {
      svg {
        transition: all 0.2s ease-in-out;
      }
      .chevron-icon-rotate-0 {
        ${rotateMixin(0)}
      }
      .chevron-icon-rotate-90 {
        ${rotateMixin(-90)}
      }
    }
  }
  fieldset .rjsf-field-description pre {
    display: grid !important;
    border-radius: ${BORDER_RADIUS} !important;
    padding: 0px !important;
    code {
      line-height: 1.2;
      padding-bottom: 10px;
      ${ScrollableMixin};
    }
    .copy-to-clip-board {
      right: 5px;
      top: 5px;
    }
  }
  fieldset .react-codemirror2 {
    display: flex;
    line-height: 22px;
    margin-top: 15px;

    ${({ theme: { cssStyles } }) =>
      cssStyles && renderCssStyles(cssStyles.code.blockCode)};

    .CodeMirror-wrap {
      width: 0;
      flex-grow: 1;
    }

    .CodeMirror-vscrollbar {
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.C300};
      }
      &:hover,
      &:focus {
        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.colors.C400};
        }
      }
    }
  }

  .rjsf-field,
  .rjsf-map-item {
    border-bottom: 1px solid
      ${({ theme }) => Color(theme.colors.C300).alpha(0.6).toString()};
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .rjsf-array-item {
    border-bottom: 1px solid
      ${({ theme }) => Color(theme.colors.C300).alpha(0.6).toString()};
    padding-bottom: 15px;
    margin-bottom: 15px;

    > div {
      > .rjsf-field-object,
      > .rjsf-field {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
    }
  }

  .rjsf-depth_2 {
    > .rjsf-element-properties,
    > .rjsf-array-container {
      > .rjsf-field {
      }
    }
  }
  form.rjsf > .rjsf-field {
    border-bottom: none;
  }
  .rjsf-element-properties {
    > .rjsf-field:last-child {
      border-bottom: none;
      padding: 0px;
      margin: 0;
    }
  }
  .rjsf-field-object > fieldset > div > div > legend,
  .rjsf-field-array-of-object > .rjsf-object-header legend {
    cursor: pointer;
  }
  .rjsf-array-item {
    position: relative;
    > div
      > .rjsf-field-object
      > fieldset
      > .rjsf-object-header
      > .rjsf-header-left
      > legend
      input {
      display: none;
      + span {
        margin: 0px;
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
  .rjsf-array-item-toolbox {
    position: absolute;
    z-index: 2;
    top: 2px;
    right: 50px;
    .rjsf-array-item-remove {
      svg {
        stroke: ${({ theme }) => theme.colors.C901};
      }
      &:hover {
        svg {
          stroke: ${DANGER_COLOR}!important;
        }
      }
    }
  }
  .rjsf-field {
    > input,
    > textarea,
    > select {
      transition: all 0.3s ease-in-out;
      border-radius: 8px;
      width: 100%;
    }
  }
  // button colors for normal & hover state

  fieldset > .rjsf-object-header {
    > .rjsf-btn.rjsf-json-button {
      svg {
        width: 14px;
        height: 14px;
        transition: all 0.3s ease-in-out;
      }
      svg path,
      svg rect,
      svg circle {
        fill: ${({ theme }) => theme.colors.C901};
      }
      &:disabled {
        svg path,
        svg rect,
        svg circle {
          fill: ${({ theme }) => theme.colors.C500};
        }
        svg {
          cursor: not-allowed;
        }
      }
    }
    > .rjsf-btn.rjsf-toggle-button {
      svg {
        transition: all 0.3s ease-in-out;
        stroke: ${({ theme }) => theme.colors.C901};
      }
      .chevron-icon-rotate-0 {
        ${rotateMixin(0)}
      }
      .chevron-icon-rotate-90 {
        ${rotateMixin(-90)}
      }
    }
  }
  /* fieldset:hover > .rjsf-object-header {
     > .rjsf-btn.rjsf-json-button {
       svg {
         fill: ${({ theme }) => theme.colors.C900};
       }
     }
     > .rjsf-btn.rjsf-toggle-button {
       svg {
         stroke: ${({ theme }) => theme.colors.C900};
       }
     }
   } */

  .rjsf-array-item .rjsf-array-item-toolbox {
    .rjsf-array-item-move-up,
    .rjsf-array-item-move-down,
    .rjsf-array-item-remove {
      svg {
        transition: all 0.3s ease-in-out;
        opacity: 0.6;
      }
      .arrow-icon-up {
        ${rotateMixin(0)}
      }
      .arrow-icon-down {
        ${rotateMixin(180)}
      }
    }
  }
  .rjsf-array-item:hover .rjsf-array-item-toolbox {
    .rjsf-array-item-move-up,
    .rjsf-array-item-move-down,
    .rjsf-array-item-remove {
      svg {
        stroke: ${({ theme }) => theme.colors.C901};
        opacity: 1;
      }
    }
  }
  .rjsf-expand-all {
    display: flex;
    flex: 1;
    button {
      margin-left: auto;
      color: ${({ theme }) => theme.colors.C901};
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontSize) || '11.85px'};
      font-family: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text3.fontFamily) || undefined};
      font-weight: 500;
    }
  }

  .rjsf {
    input,
    textarea,
    select {
      font-family: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.fontFamily) || 'inherit'} !important;
    }
    // CHECKBOX

    .rjsf-field-boolean {
      .rjsf-checkbox {
        margin-top: 11px;
      }
      .rjsf-checkbox,
      .rjsf-checkbox-title {
        display: flex;
        align-items: center;
        font-size: ${({ theme: { cssStyles } }) =>
          (cssStyles && cssStyles.body.text2.fontSize) || '13.33px'};
        .rjsf-checkbox-text {
          padding-top: 1px;
          color: ${({ theme }) => theme.colors.C801};
        }
      }
    }

    .rjsf-checkbox,
    legend label {
      display: block;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .rjsf-checkbox,
    legend label {
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }

    .rjsf-checkbox,
    legend label {
      span {
        position: absolute;
        top: 0;
        left: 0;
        height: 14px;
        width: 14px;
        margin-top: 2px;
        background-color: #fff;
        border: 1px solid gray;
        border-radius: 2px;
      }
    }
    .rjsf-checkbox,
    legend label {
      input.checked ~ span {
        background-color: #3784f7;
      }
    }
    .rjsf-checkbox,
    legend label {
      input.checked:hover ~ span {
        background-color: #005ce6;
      }
    }

    .rjsf-checkbox,
    legend label {
      input:disabled ~ span {
        border-color: ${({ theme }) => theme.colors.C400};
        background-color: ${({ theme }) => theme.colors.C300};
      }
    }
    .rjsf-checkbox,
    legend label {
      input.checked ~ span {
        width: 14px;
        height: 14px;
        border: none;
      }
    }

    .rjsf-checkbox,
    legend label {
      span:after {
        content: '';
        position: absolute;
        display: none;
        transition: all 0.3s ease-in-out;
      }
    }
    .rjsf-checkbox,
    legend label {
      input.checked ~ span:after {
        display: block;
      }
    }
    .rjsf-checkbox,
    legend label {
      span:after {
        left: 4.9px;
        top: 2px;
        width: 2px;
        height: 7px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .rjsf-map-field-key {
    position: relative;

    .rjsf-form-control {
      margin: 0;
    }

    svg {
      transition: all 0.2s ease-in-out;
    }
    .chevron-icon-rotate-0 {
      ${rotateMixin(0)}
    }
    .chevron-icon-rotate-90 {
      ${rotateMixin(-90)}
    }
    /* .rjsf-map-item-toolbox {
       padding-top: 11px;
     } */
  }

  .rjsf-form-control {
    width: 100%;
    &.rjsf-form-control-password {
      padding-right: 36px;
    }
  }

  .rjsf-form-control-parent {
    position: relative;
    max-width: 250px;
  }

  .rjsf-form-control-svg {
    display: inline-block;
    position: absolute;
    right: 12px;
    top: 11px;
    height: 18px;

    &:hover {
      cursor: pointer;
    }
  }

  .rjsf-map-item::last-child {
    .rjsf-map-field-value-container {
      border-bottom: 1px solid ${({ theme }) => theme.colors.C300};
      padding-bottom: 21px;
      margin-bottom: 21px;
    }
  }

  .rjsf-map-field-value-container {
    > div > div > .rjsf-field {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  // END
  // TOOLTIP
  .rjsf-tooltip {
    position: relative;
    display: none;
  }
  .rjsf-json-button .rjsf-tooltip::before {
    content: 'View JSON';
    width: 60px;
  }
  .rjsf-json-button.rjsf-form-view .rjsf-tooltip::before {
    content: 'View Form';
    width: 60px;
  }
  .rjsf-json-button:hover .rjsf-tooltip {
    display: block;
  }
  .rjsf-json-button-disabled .rjsf-tooltip::before {
    content: 'Please fix the validation errors before closing';
    width: 144px;
  }
  .rjsf-json-button:hover .rjsf-tooltip {
    display: block;
  }
  .rjsf-tooltip::before {
    font-size: 10.53px;
    min-width: 50px;
    max-width: 210px;
    position: absolute;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    color: #fff;
    padding: 8px;
    text-align: center;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
  }
  .rjsf-tooltip::after {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.8);
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
  // TOOLTIP END

  ${reactSelectMixin}
  // TOOLTIP END 
 
   // DATE PICKER
   .react-datepicker-popper {
    /* ${reactDatePickerMixin} */
  }
  ${({ theme }) => getDynamicDepthStyling(theme.colors.C201)}

  ${discriminatorMixin}
   
   .rjsf-discriminator-field-child-empty > .rjsf-field,
   .rjsf-discriminator-field-child > .rjsf-field {
    border-bottom: none;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .rjsf-discriminator-field,
  .rjsf-discriminator-field-child {
    margin-top: 15px !important;
    margin-bottom: 0px;
  }

  .rjsf-no-checkbox {
    line-height: normal;
  }
  .rjsf-checkbox-text {
    margin-left: 20px;
    line-height: normal;
  }

  .rjsf-discriminator-field-child-object
    > .rjsf-field-array
    > fieldset
    > .rjsf-object-header
    > .rjsf-header-left
    > legend,
  .rjsf-discriminator-field-child-object
    > .rjsf-field-object
    > fieldset
    > .rjsf-object-header
    > .rjsf-header-left
    > legend {
    margin-top: 7px;
  }

  .rjsf-discriminator-field-child-object
    > .rjsf-field-array
    > fieldset
    > .rjsf-object-header
    > .rjsf-toggle-button,
  .rjsf-discriminator-field-child-object
    > .rjsf-field-object
    > fieldset
    > .rjsf-object-header
    > .rjsf-toggle-button {
    top: 15px;
  }

  .rjsf-field-object {
    &.rjsf-from-discriminator {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }
  }

  .rjsf-object-legend,
  .rjsf-codemirror-wrapper {
    position: relative;
  }

  .rjsf-auth-scope-tags {
    margin-top: 7px;
    .rjsf-base-type {
      margin-right: 12px !important;
      border: ${(props) =>
        `1.5px solid ${props.theme.staticColors.Goose.C100}`};
      border-radius: 3px;
      opacity: 1;
      background-color: ${(props) => props.theme.colors.C001};

      .tag-label {
        display: flex;
        align-items: center;
        position: relative;
        top: 1px;
        padding: 0px 8px;
        font-size: 11.85px;
        font-family: 'Rubik', sans-serif;
        color: ${(props) => props.theme.staticColors.Goose.C100};
        font-weight: 500;
        line-break: anywhere;
      }
    }
  }

  .rjsf-auth-scope-container {
    margin: 10px 0px 10px 15px;
  }
  .react-datepicker__input-container > input {
    width: 250px;
  }
`;

export const markdownRenderer = (
  source: string,
  className?: string,
  toggleChat?: () => void
) => (
  <Markdown source={source} className={className} toggleChatbot={toggleChat} />
);

const renderTypesPopover = (
  source: string,
  label: string,
  containerName?: string,
  link?: string,
  dataType?: string,
) => (
  <Popover>
    {(props) => (
      <HttpTypes
        {...props}
        source={source}
        label={label}
        containerName={containerName}
        link={link}
        dataType={dataType}
      />
    )}
  </Popover>
);

const renderToolTip = (children: JSX.Element, tooltipLabel: string) => {
  return (
    <Tooltip text={tooltipLabel} parentRefranceId="app-layout-docs-container">
      {children}
    </Tooltip>
  );
};

const renderCallOutFallback = (props: CalloutProps) => {
  const { message, icon = 'info' } = props;
  return <Callout icon={icon}>{message}</Callout>;
};

/**
 * Creates a form from JSON Schema and initial data
 * levelReversal: within the depths of json level reversal will ensure that even/odd class is in reverse order
 *                that means if level reversal is true the first level is even. this will reverse the color within depth
 */
export function JSchemaForm<FormDataType>({
  schema,
  data,
  onChange,
  disableFormJsonEdit = false,
  expandAllLevel,
  className,
  levelReversal,
  isExpandable,
  definitions,
  isConsole,
  authTokenRenderProps,
  removeViewJsonButton,
  portalSettings,
  trackEvent,
}: {
  schema: JSONSchema6;
  data: FormDataType;
  onChange: (data: FormDataType) => void;
  className?: string;
  disableFormJsonEdit?: boolean;
  expandAllLevel?: number;
  levelReversal?: boolean;
  isExpandable?: boolean;
  definitions: Document['ModelSchemas'];
  isConsole?: boolean;
  authTokenRenderProps?: AuthRendererProps;
  removeViewJsonButton?: boolean;
  portalSettings: PortalSettings;
  trackEvent: (
    portalSettings: PortalSettings,
    eventName: string,
    eventData: { [key: string]: unknown },
  ) => void;
}) {
  const history = useHistory();
  const { payload } = useContext(WorkflowEndpointContext);
  const linkMapper = useContext(LinkMapperContext);
  const { getSelectedWorkflow } = useContext(WorkflowContext);
  const { workflowName, workflowSteps } = getSelectedWorkflow(linkMapper);
  const { selectedStepValue } = getSteps(workflowSteps);

  const onFormChange = useCallback(
    (e: { formData: FormDataType }) => {
      onChange(e.formData);
    },
    [onChange],
  );
  const docContext = useContext(DocumentContext);

  const onRouteChange = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    // TODO: Unable to type cast correctly due to generics. Will fix it later
    const formData = payload as Record<string, unknown>;
    if (isConsole && formData?.args) {
      onChange(formData as FormDataType);
    }
  }, [onChange, payload, isConsole]);

  return (
    <StyledJSchemaForm>
      <Form
        disabled={!!workflowName && selectedStepValue?.status === 'complete'}
        schema={schema}
        liveValidate={true}
        className={'rjsf ' + (className ? className : '')}
        showErrorList={true}
        uiSchema={{
          disableFieldJsonEdit: true,
          args: {
            disableFieldJsonEdit: true,
            'ui:title': 'Parameters',
          },
          auth: {
            'ui:readonly': true,
            'ui:disabled': true,
          },
          levelReversal: levelReversal,
          expandAllLevel: isExpandable ? expandAllLevel : undefined,
        }}
        formData={data}
        onChange={onFormChange}
        dontAssignDefaults={true}
        disableFormJsonEdit={disableFormJsonEdit}
        dxInterface={{
          markdownRenderer,
          renderTypesPopover,
          renderToolTip,
          renderCallOutFallback,
          onRouteChange: onRouteChange,
          authTokenRenderProps,
          definitions: definitions,
          structures: docContext?.Structures,
          linkMapper,
          removeViewJsonButton,
          portalSettings,
          trackEvent,
        }}
      />
    </StyledJSchemaForm>
  );
}

StyledJSchemaForm.displayName = 'StyledJSchemaForm';

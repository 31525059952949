import styled from 'styled-components';

import { Overlay } from './StyledElements';
import { Spinner } from './Spinner';
import { H2 } from './CleanSlate';
import { ErrorScreen } from './ResponseViewer/ResponseViewerError';

/**
 * Loading overlay component
 *
 * Show a spinner and loading text
 */
export function LoadingOverlay() {
  return (
    <Overlay direction="row">
      <Spinner />
      <H2>Loading</H2>
    </Overlay>
  );
}

const ErrorOverlayHeading = styled(H2)`
  color: #f56f6f;
`;
ErrorOverlayHeading.displayName = 'ErrorOverlayHeading';

/**
 * Error overlay component with refresh button
 * @param props
 */
export function ErrorOverlay(props: {
  onRetry?: () => void;
  statusCode?: number;
}) {
  const { statusCode, onRetry } = props;
  const isOnline = window.navigator.onLine;

  if (!isOnline) {
    return <ErrorScreen variant="errorDocsConnection" onRetry={onRetry} />;
  }

  if (statusCode === 400) {
    return <ErrorScreen variant="errorDocsCode400" />;
  }

  if (statusCode === 402) {
    return <ErrorScreen variant="errorDocsCode402" />;
  }

  if (statusCode === 403) {
    return <ErrorScreen variant="errorDocsCode403" />;
  }

  if (statusCode === 404) {
    return <ErrorScreen variant="errorDocsCode404" />;
  }

  if (statusCode === 406) {
    return <ErrorScreen variant="errorDocsCode406" />;
  }

  if (statusCode === 422) {
    return <ErrorScreen variant="errorDocsCode422" />;
  }

  if (statusCode === 500) {
    return <ErrorScreen variant="errorDocsCode500" />;
  }

  if (statusCode === 502) {
    return <ErrorScreen variant="errorDocsCode502" onRetry={onRetry} />;
  }

  if (statusCode === 503) {
    return <ErrorScreen variant="errorDocsCode503" onRetry={onRetry} />;
  }

  if (statusCode === 504 || statusCode === 524) {
    return <ErrorScreen variant="errorDocsCode524" onRetry={onRetry} />;
  }

  /* 
    General Technical Error for App crashing fallback
  */
  if (statusCode === 900) {
    // With back and retry button
    return <ErrorScreen variant="technicalIssues" />;
  }

  return <ErrorScreen variant="errorDocsDefault" onRetry={onRetry} />;
}

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const CollapseCodeSampleIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox={props.viewBox || '0 0 24 24'}
  >
    <path
      d="M18 6L6 18"
      stroke="#F8FAFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#F8FAFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);

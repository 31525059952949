/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ConnectionDarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M35.7,41.3L35.7,41.3c-1.1,0-2.1,0.9-2.1,2.1
	v43.3c0,1.1,0.9,2.1,2.1,2.1h0c1.1,0,2.1-0.9,2.1-2.1V43.3C37.8,42.2,36.9,41.3,35.7,41.3z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M97.4,41.3L97.4,41.3c-1.1,0-2.1,0.9-2.1,2.1
	v43.3c0,1.1,0.9,2.1,2.1,2.1h0c1.1,0,2.1-0.9,2.1-2.1V43.3C99.4,42.2,98.5,41.3,97.4,41.3z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M101.4,100.9h-72c-3.5,0-6.4-2.9-6.4-6.4v-5.2
	c0-3.5,2.9-6.4,6.4-6.4h72c3.5,0,6.4,2.9,6.4,6.4v5.2C107.9,98,105,100.9,101.4,100.9z"
    />
    <path
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M78.8,94.2L78.8,94.2c-1.5,0-2.7-1.2-2.7-2.7v0
	c0-1.5,1.2-2.7,2.7-2.7l0,0c1.5,0,2.7,1.2,2.7,2.7v0C81.6,93,80.3,94.2,78.8,94.2z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M88.1,94.2L88.1,94.2c-1.5,0-2.7-1.2-2.7-2.7v0
	c0-1.5,1.2-2.7,2.7-2.7h0c1.5,0,2.7,1.2,2.7,2.7v0C90.8,93,89.6,94.2,88.1,94.2z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M96.7,94.2L96.7,94.2c-1.5,0-2.7-1.2-2.7-2.7v0
	c0-1.5,1.2-2.7,2.7-2.7l0,0c1.5,0,2.7,1.2,2.7,2.7v0C99.4,93,98.2,94.2,96.7,94.2z"
    />
    <path
      fill="#F6F6F9"
      d="M101.1,85.6H27c-0.5,0-0.9-0.4-0.9-0.9l0,0c0-0.5,0.4-0.9,0.9-0.9h74.1c0.5,0,0.9,0.4,0.9,0.9l0,0
	C102,85.1,101.6,85.6,101.1,85.6z"
    />
    <path
      opacity="0.1"
      fill="#606774"
      d="M106.9,100.9H30c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h76.9c0.5,0,0.9,0.4,0.9,0.9
	v0C107.9,100.5,107.4,100.9,106.9,100.9z"
    />
    <rect
      x="33.7"
      y="80.1"
      opacity="0.1"
      fill="#606774"
      width="4.1"
      height="2.8"
    />
    <rect
      x="95.3"
      y="80.1"
      opacity="0.1"
      fill="#606774"
      width="4.1"
      height="2.8"
    />
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M71.8,39.3v3.2H56.9v-2.6l9.4-11.3h-9.2v-3.2
		h14.4V28l-9.3,11.3H71.8z"
      />
    </g>
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M53,54v1.8h-8.3v-1.4l5.2-6.3h-5.1v-1.8h8v1.4
		L47.5,54H53z"
      />
    </g>
    <path
      opacity="0.1"
      fill="#606774"
      d="M108.1,102.8H21.6c-0.5,0-0.9-0.4-0.9-0.9l0,0c0-0.5,0.4-0.9,0.9-0.9h86.5
	c0.5,0,0.9,0.4,0.9,0.9l0,0C109,102.3,108.6,102.8,108.1,102.8z"
    />
  </SVGIcon>
);

export const ConnectionLightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M37.7,43.5L37.7,43.5c-1.1,0-2.1,0.9-2.1,2.1
	v43.3c0,1.1,0.9,2.1,2.1,2.1h0c1.1,0,2.1-0.9,2.1-2.1V45.5C39.8,44.4,38.9,43.5,37.7,43.5z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M99.3,43.5L99.3,43.5c-1.1,0-2.1,0.9-2.1,2.1
	v43.3c0,1.1,0.9,2.1,2.1,2.1l0,0c1.1,0,2.1-0.9,2.1-2.1V45.5C101.4,44.4,100.5,43.5,99.3,43.5z"
    />
    <path
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M103.4,103.1h-72c-3.5,0-6.4-2.9-6.4-6.4v-5.2
	c0-3.5,2.9-6.4,6.4-6.4h72c3.5,0,6.4,2.9,6.4,6.4v5.2C109.8,100.2,107,103.1,103.4,103.1z"
    />
    <path
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M80.8,96.4L80.8,96.4c-1.5,0-2.7-1.2-2.7-2.7
	l0,0c0-1.5,1.2-2.7,2.7-2.7h0c1.5,0,2.7,1.2,2.7,2.7l0,0C83.5,95.2,82.3,96.4,80.8,96.4z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M90,96.4L90,96.4c-1.5,0-2.7-1.2-2.7-2.7l0,0
	c0-1.5,1.2-2.7,2.7-2.7h0c1.5,0,2.7,1.2,2.7,2.7l0,0C92.8,95.2,91.5,96.4,90,96.4z"
    />
    <path
      fill="#9199A5"
      stroke="#49505A"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M98.7,96.4L98.7,96.4c-1.5,0-2.7-1.2-2.7-2.7
	l0,0c0-1.5,1.2-2.7,2.7-2.7h0c1.5,0,2.7,1.2,2.7,2.7l0,0C101.4,95.2,100.2,96.4,98.7,96.4z"
    />
    <path
      fill="#F6F6F9"
      d="M103.1,87.8H29c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h74.1c0.5,0,0.9,0.4,0.9,0.9v0
	C104,87.3,103.6,87.8,103.1,87.8z"
    />
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M73.8,41.5v3.2H58.9v-2.6l9.4-11.3h-9.2v-3.2
		h14.4v2.6l-9.3,11.3H73.8z"
      />
    </g>
    <g>
      <path
        fill="#F6F6F9"
        stroke="#9CAAB8"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        d="M54.9,56.2V58h-8.3v-1.4l5.2-6.3h-5.1v-1.8h8
		v1.4l-5.2,6.3H54.9z"
      />
    </g>
  </SVGIcon>
);

import { useContext, useEffect, useRef, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { BotIconSmall } from '../../Icons/BotIconSmall';
import { UserIcon } from '../../Icons/UserIcon';
import { CrossIconRed } from '../../Icons/CrossIconRed';
import { WelcomeMessage } from '../welcome-message/WelcomeMessage';
import { AppContext } from '../ApiCopilot';
import { DisclaimerHeight, HeaderHeight } from '../../typings';
import {
  ChatMessage,
  ChatMessages,
  FeedbackForm,
  Feedback,
  Div,
  ScrollableMixin,
} from '@dx-portal/design-system';
import { Loader } from '../../Icons/Loader';

interface MessagesWrapperProps {
  isMaximised: boolean;
  dimensions: { height: number; width: number };
}

interface MessageParentProps {
  isMaximised: boolean;
  isUser: boolean;
}

interface IconWrapperProps {
  isUser: boolean;
}

interface ChatMessageProps {
  isMaximised: boolean;
  shouldblink: boolean;
}

interface InternalErrorComponentProps {
  isInternalError?: true;
}

const MessagesWrapper = styled(Div)<MessagesWrapperProps>`
  ${ScrollableMixin};
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: transparent;
  }

  height: ${({ dimensions }) =>
    `calc(${dimensions.height}px - 118px - ${HeaderHeight} - ${DisclaimerHeight})`};
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          align-items: center;
        `
      : css`
          padding: 0 16px;
          align-items: start;
        `}
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  margin: 0 18px 0 0;
  position: relative;
`;

type LoaderWrapperProps = {
  hidden?: boolean;
};

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  position: absolute;
  left: 44px;
  top: 2px;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  gap: 8px;
  align-items: center;
  color: #3e445d;
`;

const MessageParent = styled.div<MessageParentProps>`
  height: fit-content;
  padding: 8px 0px;
  display: flex;
  align-items: start;
  background-color: '#fff';
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow-wrap: break-word;

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          justify-content: center;
        `
      : css`
          justify-content: flex-start;
        `}
`;

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

export const ChatMessageComponent = styled.div<ChatMessageProps>`
  height: fit-content;
  animation: ${(props) => (props.shouldblink ? blinkingEffect : 'none')} 1s
    linear infinite;

  > p:first-child {
    margin: 0px;
  }

  .chatbot-markdown-renderer > *:first-child {
    margin-top: 0px;
  }

  ${({ isMaximised }) =>
    isMaximised
      ? css`
          @media screen and (min-width: 1280px) {
            width: 700px;
          }
          @media screen and (max-width: 680px) {
            width: 60vw;
          }
          width: 550px;
        `
      : css`
          width: 450px;
        `}
`;

const InternalErrorComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: solid ${({ theme }) => theme.staticColors.Red.C000};
  border-width: 1px 1px 1px 8px;
  background: #fff0f1;
  padding: 0 13px;
  opacity: 0.8;
  width: 340px;
  height: fit-content;
  color: ${({ theme }) => theme.staticColors.Red.C000};

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ErrorWrapper = styled.div<InternalErrorComponentProps>`
  display: ${(props) => (props.isInternalError ? 'flex' : 'none')};
  justify-content: center;
  width: 100%;
  margin: 8px 0px 24px;
`;

export type ChatMessagesProps = {
  messages: ChatMessages;
  isInputDisabled: boolean;
  isMaximised: boolean;
  isInternalError?: true;
  welcomeMessage: string;
  onPromptClick: (prompt: ChatMessage) => void;
  dimensions: { height: number; width: number };
  markdownRenderer?: (
    source: string,
    className?: string,
    toggleChat?: () => void
  ) => JSX.Element;
  toggleChat: () => void;
};

export const ChatMessagesComponent = (props: ChatMessagesProps) => {
  const {
    messages,
    isInputDisabled,
    isMaximised,
    isInternalError,
    onPromptClick,
    welcomeMessage,
    dimensions,
    markdownRenderer,
    toggleChat,
  } = props;
  const { logEvent } = useContext(AppContext);

  const messagesWrapperRef = useRef<HTMLDivElement>(null);

  let messageArray = [...messages];

  if (
    messageArray.length &&
    messageArray[messageArray.length - 1].role === 'user'
  ) {
    messageArray = [...messageArray];
  }

  useEffect(() => {
    const wrapperObj = messagesWrapperRef.current;

    if (wrapperObj) {
      wrapperObj.scrollTop = wrapperObj.scrollHeight;
    }
  }, []);

  useEffect(() => {
    const wrapperObj = messagesWrapperRef.current;

    if (wrapperObj) {
      wrapperObj.scrollTop = wrapperObj.scrollHeight;
      const observer = new MutationObserver(() => {
        // This waits for the next frame to ensure the DOM update is complete. Only then it performs the scrolling.
        requestAnimationFrame(() => {
          wrapperObj.scrollTop = wrapperObj.scrollHeight;
          observer.disconnect(); // disconnect the observer afterward
        });
      });

      //This listens for any changes in the DOM within the messages-wrapper. When new changes are detected then observer triggers a callback.
      observer.observe(wrapperObj, { childList: true, subtree: true });
    }
  }, [messages]);

  const handleFeedbackSubmission = (feedback: Feedback) => {
    logEvent &&
      feedback &&
      logEvent(
        'FeedbackSubmitted',
        messages,
        undefined,
        feedback.feedback,
        feedback.completeFeedback
      );
  };

  const onResponseCopy = () => {
    logEvent &&
      logEvent(
        'CopiedTheResponse',
        undefined,
        messages[messages.length - 1].content
      );
  };

  return messageArray.length ? (
    <MessagesWrapper
      id="messages-wrapper"
      data-testid="api-copilot-message-component"
      style={{
        justifyContent: messageArray.length || !isMaximised ? 'start' : 'end',
      }}
      isMaximised={isMaximised}
      dimensions={dimensions}
      ref={messagesWrapperRef}
    >
      {messageArray.map((message, index) => {
        const isUser = message.role === 'user';
        return (
          <MessageParent key={index} isUser={isUser} isMaximised={isMaximised}>
            <IconWrapper isUser={isUser}>
              {isUser ? <UserIcon /> : <BotIconSmall />}
              <LoaderWrapper hidden={message.content !== 'Thinking...'}>
                <Loader />
                Thinking...
              </LoaderWrapper>
            </IconWrapper>
            <ChatMessageComponent
              data-testid={'api-copilot-chat-message-component'}
              isMaximised={isMaximised}
              shouldblink={message.content === 'Thinking...'}
            >
              {markdownRenderer?.(
                message.content === 'Thinking...' ? '' : message.content,
                'chatbot-markdown-renderer',
                toggleChat
              )}
              {shouldShowFeedbackForm(index, message, messageArray) &&
                !isInputDisabled && (
                  <FeedbackForm
                    platform="API Copilot"
                    handleFeedbackSubmission={handleFeedbackSubmission}
                    messages={messageArray}
                    onResponseCopy={onResponseCopy}
                  />
                )}
            </ChatMessageComponent>
          </MessageParent>
        );
      })}
      <ErrorWrapper isInternalError={isInternalError}>
        <InternalErrorComponent
          data-testid={'api-copilot-internal-error-component'}
        >
          <CrossIconRed />
          <p>Oops! Something Unexpected happened, Please try again.</p>
        </InternalErrorComponent>
      </ErrorWrapper>
    </MessagesWrapper>
  ) : (
    <WelcomeMessage
      welcomeMessage={welcomeMessage}
      isMaximised={isMaximised}
      onPromptClick={onPromptClick}
      dimensions={dimensions}
    />
  );
};

function shouldShowFeedbackForm(
  index: number,
  message: ChatMessage,
  messageArray: ChatMessage[]
) {
  return (
    messageArray.length - 1 === index &&
    index !== 0 &&
    message.content !== 'Thinking...' &&
    message.role !== 'user' &&
    message.content !== ''
  );
}

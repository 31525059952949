// AbortController.ts
export let controller = new AbortController();
export let signal = controller.signal;

export const resetAbortController = () => {
  // Create a new AbortController instance
  controller = new AbortController();
  signal = controller.signal;
};

export const isAbortError = (error: unknown): error is DOMException =>
  error instanceof DOMException && error.name === 'AbortError';

import { AuthTokenDef, SimpleJsonType } from 'src/DxDom';

export function authTokenValidator(
  token: SimpleJsonType & Record<string, string>
) {
  const validAuthToken: AuthTokenDef & Record<string, string> = {};

  if (token && Object.keys(token).length) {
    Object.keys(token).forEach((key) => {
      const tokenCurrentValue = token[key];
      if (key === 'expires_in' && typeof tokenCurrentValue === 'number') {
        validAuthToken[key] = tokenCurrentValue;
      } else if (typeof tokenCurrentValue === 'string') {
        validAuthToken[key] = tokenCurrentValue;
      } else {
        /* eslint-disable  no-console */
        console.error(
          `APIMatic Portal Error: Unable to parse ${key} value in auth token`
        );
      }
    });
  }

  return validAuthToken;
}

import * as React from 'react';
import * as DxDom from './DxDom';
import {
  CodeBlock,
  CodeBlockCode,
  FlexParent,
  TypeWrapper,
} from './StyledElements';
import { CodeBox } from './AppLayout';
import { CodeBlockHeader, CodeBlockTools } from './RModelReference';
import { CodeHighlighter, HighlighterLanguage } from './CodeHighlighter';
import { CopyToClipboardComp } from './uiComponents/CopyToClipboard';
import styled from 'styled-components';
import { Div } from './CleanSlate';
import { ExpandableCardWrapper } from './ExpandableCardWrapper';
import { CodeSelector } from './CodeSelector';
import { DocumentContextConsumer } from './DocumentContext';

export const HeaderTab = styled(Div)`
  height: 100%;
  width: fit-content;
  font-size: 12px;
  border-bottom: 2px solid ${(props) => props.theme.colors.C001};
  padding: 8px 12px;
  margin-left: 2px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.C100};
  letter-spacing: 0.24px;
`;

const Line = styled(Div)`
  position: relative;
  left: -80px;
  height: 2px;
  border-bottom: 1px solid ${(props) => props.theme.staticColors.Snow.C100};
  width: 150%;
`;

export const typeCombinatorContainerRef = (
  mref: DxDom.TypeCombinatorContainer,
  nodeRenderer: DxDom.NodeHandler<JSX.Element>
): JSX.Element => {
  const nodes: JSX.Element[] = [];

  const renderNode = (n: DxDom.SectionNode) =>
    DxDom.callNodeHandler(n, nodeRenderer);

  let i = 0;
  const pushNode = (n: DxDom.SectionNode) =>
    nodes.push(
      <React.Fragment key={'typeCombinatorContainerRef' + i++}>
        {renderNode(n)}
      </React.Fragment>
    );

  if (mref.StructureType) {
    pushNode({
      Type: 'heading',
      Text: mref.StructureType,
      Level: 2,
    });
    nodes.push(
      <TypeWrapper key="class-name">
        {renderNode({
          Type: 'paragraph',
          Text: '`' + mref.DataType + '`',
        })}
      </TypeWrapper>
    );
  }

  if (mref.Description) {
    pushNode({
      Type: 'heading',
      Text: 'Description',
      Level: 2,
    });
    pushNode({
      Type: 'paragraph',
      Text: mref.Description,
    });
  }

  if (mref.DiscriminatorField) {
    pushNode({
      Type: 'heading',
      Text: 'Discriminator Field',
      Level: 2,
    });
    nodes.push(
      <TypeWrapper key="discriminator-field">
        {renderNode({
          Type: 'paragraph',
          Text: '`' + mref.DiscriminatorField + '`',
        })}
      </TypeWrapper>
    );
  }

  if (mref.Cases) {
    nodes.push(
      <ExpandableCardWrapper Cases={mref.Cases} renderNode={renderNode} />
    );
  }

  if (mref.Examples && Object.keys(mref.Examples).length) {
    nodes.push(<Line />);
    pushNode({
      Type: 'heading',
      Text: 'Container Examples',
      Level: 2,
    });
    nodes.push(
      <CodeSelector
        renderNode={renderNode}
        Examples={mref.Examples}
        isContainerExample={true}
      />
    );
  }

  nodes.push(
    <DocumentContextConsumer>
      {(doc) => {
        const language = doc?.lang ? HighlighterLanguage[doc.lang] : 'bash';
        return (
          <CodeBox key="model-example">
            <CodeBlock type="column">
              <CodeBlockHeader>
                {mref.UtilizationCode && (
                  <HeaderTab>Utilization Code</HeaderTab>
                )}
              </CodeBlockHeader>
              <CodeBlockCode invert={true} scrollWidth="7px">
                <CodeHighlighter
                  code={mref.UtilizationCode || ''}
                  dark={true}
                  lang={language}
                />
              </CodeBlockCode>
              {mref.UtilizationCode && (
                <CodeBlockTools>
                  <FlexParent>
                    <CopyToClipboardComp
                      text={mref.UtilizationCode || ''}
                      iconButtonType="dark"
                      from="Utilization Code"
                    />
                  </FlexParent>
                </CodeBlockTools>
              )}
            </CodeBlock>
          </CodeBox>
        );
      }}
    </DocumentContextConsumer>
  );

  return <>{nodes}</>;
};

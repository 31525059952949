import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ResetIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '10.5'}
    height={props.height || '12'}
    viewBox={props.viewBox || '0 0 10.5 12'}
  >
    <path
      /* eslint-disable max-len */
      d="M3.75,2a.75.75,0,0,1,.75.75V4.326A5.252,5.252,0,0,1,13.2,6.25a.75.75,0,1,1-1.414.5,3.752,3.752,0,0,0-6.538-1H7.5a.75.75,0,0,1,0,1.5H3.75A.75.75,0,0,1,3,6.5V2.75A.75.75,0,0,1,3.75,2Zm.006,6.793a.75.75,0,0,1,.957.458,3.752,3.752,0,0,0,6.538,1H9a.75.75,0,0,1,0-1.5h3.75a.75.75,0,0,1,.75.75v3.75a.75.75,0,1,1-1.5,0V11.674A5.252,5.252,0,0,1,3.3,9.75a.75.75,0,0,1,.457-.957Z"
      transform="translate(-3 -2)"
    />
  </SVGIcon>
);

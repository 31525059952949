import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const AngularIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M15.716,17.498 L19.434,17.498 L17.458,13.590 L15.716,17.498 ZM17.465,
    7.988 L8.001,11.137 L9.496,22.861 L17.475,27.007 L25.494,22.805 L26.988,11.082 L17.465,
    7.988 L17.465,7.988 ZM14.947,19.159 L13.786,21.903 L11.618,21.912 L17.464,9.668 L17.453,
    9.645 L17.443,9.645 L17.443,9.625 L17.453,9.645 L17.475,9.645 L17.495,9.604 L17.495,9.645 L17.475,
    9.645 L17.464,9.668 L23.563,21.963 L21.475,21.919 L20.191,19.114 L17.448,19.126 "
    />
  </SVGIcon>
);

import styled from 'styled-components';
import { AnimationDefinition } from 'rc-menu';
import { Div } from './CleanSlate';

const animate = require('css-animation').default;

/**
 * All the styling for rc-menu is put here so that we don't have to import
 * a separate .css file for it. Eventually, we should convert rc-menu or move
 * to a more component-based solution than rc-menu. I don't like components
 * that depend on global stuff.
 */
export const RcMenuWrapper = styled(Div)`
  .rc-menu {
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    color: #666;
    position: relative;
  }

  .rc-menu-hidden {
    display: none;
  }

  .rc-menu-collapse {
    overflow: hidden;
  }

  .rc-menu-collapse-active {
    -o-transition: height 0.3s ease-out;
    transition: height 0.3s ease-out;
  }

  .rc-menu-item-group-list {
    margin: 0;
    padding: 0;
  }

  .rc-menu-item-group-title {
    // color: #999;
    // line-height: 1.5;
  }

  .rc-menu-item-active,
  .rc-menu-item-selected,
  .rc-menu-submenu-active > .rc-menu-submenu-title {
    // background-color: #fafafa;
  }

  .rc-menu-item-selected {
    transform: translateZ(0);
  }

  .rc-menu-submenu-selected {
    // background-color: #eaf8fe;
  }

  .rc-menu > li.rc-menu-submenu {
    padding: 0;
  }

  .rc-menu-horizontal.rc-menu-sub,
  .rc-menu-vertical-left.rc-menu-sub,
  .rc-menu-vertical-right.rc-menu-sub,
  .rc-menu-vertical.rc-menu-sub {
    min-width: 143px;
    margin-top: 0;
  }

  .rc-menu-item,
  .rc-menu-item > a,
  .rc-menu-submenu-title {
    margin: 0;
    position: relative;
    display: block;
    padding: 7px 7px 7px 16px;
    color: ${(props) => props.theme.colors.C801};
    /* overflow: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
  }

  .rc-menu-item.rc-menu-item-disabled,
  .rc-menu-item.rc-menu-submenu-disabled,
  .rc-menu-submenu-title.rc-menu-item-disabled,
  .rc-menu-submenu-title.rc-menu-submenu-disabled {
    color: #777 !important;
  }

  .rc-menu > .rc-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    padding: 0;
    line-height: 0;
    background-color: #e5e5e5;
  }

  .rc-menu-submenu-popup {
    position: absolute;
  }

  .rc-menu-submenu > .rc-menu {
    background-color: #fff;
  }

  .rc-menu .rc-menu-item .anticon,
  .rc-menu .rc-menu-submenu-title .anticon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
    top: -1px;
  }

  .rc-menu-horizontal {
    background-color: #f3f5f7;
    border: none;
    box-shadow: none;
  }

  .rc-menu-horizontal > .rc-menu-item,
  .rc-menu-horizontal > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 15px 20px;
  }

  .rc-menu-horizontal > .rc-menu-item,
  .rc-menu-horizontal > .rc-menu-submenu {
    float: left;
    border-bottom: 2px solid transparent;
  }

  .rc-menu-horizontal > .rc-menu-item-active,
  .rc-menu-horizontal > .rc-menu-submenu-active {
    border-bottom: 2px solid #2db7f5;
    background-color: #fafafa;
    color: #2baee9;
  }

  .rc-menu-horizontal:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
  }

  .rc-menu-inline,
  .rc-menu-vertical,
  .rc-menu-vertical-left,
  .rc-menu-vertical-right {
    padding: 12px 0;
  }

  .rc-menu-inline > .rc-menu-item,
  .rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical-left > .rc-menu-item,
  .rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical-right > .rc-menu-item,
  .rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title,
  .rc-menu-vertical > .rc-menu-item,
  .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 12px 8px 12px 24px;
  }

  .rc-menu-inline .rc-menu-submenu-arrow,
  .rc-menu-vertical-left .rc-menu-submenu-arrow,
  .rc-menu-vertical-right .rc-menu-submenu-arrow,
  .rc-menu-vertical .rc-menu-submenu-arrow,
  .rc-menu-horizontal .rc-menu-submenu-arrow {
    display: inline-block;
    font-size: inherit;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    position: absolute;
    line-height: 24px;
  }

  .rc-menu-horizontal .rc-menu-submenu-arrow {
    // transform: rotate(0);
    // position: absolute;
    // top: 13px !important;
    // right: 16px;
  }

  .rc-menu-vertical-left .rc-menu-submenu-arrow:before,
  .rc-menu-vertical-right .rc-menu-submenu-arrow:before,
  .rc-menu-horizontal .rc-menu-submenu-arrow:before,
  .rc-menu-vertical .rc-menu-submenu-arrow:before {
    content: '';
    // width: 0;
    // height: 0;
    // border-left: 5px solid transparent;
    // border-right: 5px solid transparent;
    // border-radius: 2px;
    // border-top: 5px solid #314659;
  }

  .rc-menu-submenu-vertical .rc-menu-submenu-arrow {
    position: absolute;
    right: 0;
  }
  .rc-menu-submenu-popup .rc-menu-submenu-arrow,
  .rc-menu-inline .rc-menu-submenu-arrow {
    transform: rotate(-90deg);
  }
  //***************************
  // INLINE MENUE ARROW STYLES STARTS
  .rc-menu-horizontal .rc-menu-submenu-arrow,
  .rc-menu-inline .rc-menu-submenu-arrow {
    position: absolute;
    right: 15px;
  }

  .rc-menu-submenu-vertical .rc-menu-submenu-arrow,
  .rc-menu-horizontal .rc-menu-submenu-arrow,
  .rc-menu-inline .rc-menu-submenu-arrow {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .rc-menu-submenu-vertical .rc-menu-submenu-arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    right: 15px;
  }

  .rc-menu-submenu-vertical .rc-menu-submenu-arrow:before,
  .rc-menu-horizontal .rc-menu-submenu-arrow:before,
  .rc-menu-inline .rc-menu-submenu-arrow:before {
    content: '';
    width: 0;
    height: 0;
    border: solid ${(props) => props.theme.colors.C901};
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
  }

  .rc-menu-inline
    .rc-menu-submenu-open
    > .rc-menu-submenu-title
    .rc-menu-submenu-arrow {
    transform: rotate(-135deg);
    top: 5px;
  }
  // INLINE MENUE ARROW STYLES ENDS
  //***************************

  .rc-menu-vertical-left.rc-menu-sub,
  .rc-menu-vertical-right.rc-menu-sub,
  .rc-menu-vertical.rc-menu-sub {
    padding: 0;
  }

  .rc-menu-sub.rc-menu-inline {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .rc-menu-sub.rc-menu-inline > .rc-menu-item,
  .rc-menu-sub.rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 0;
  }

  .rc-menu-open-slide-up-appear,
  .rc-menu-open-slide-up-enter {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  .rc-menu-open-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  .rc-menu-open-slide-up-appear.rc-menu-open-slide-up-appear-active,
  .rc-menu-open-slide-up-enter.rc-menu-open-slide-up-enter-active {
    animation-name: rcMenuOpenSlideUpIn;
    animation-play-state: running;
  }

  .rc-menu-open-slide-up-leave.rc-menu-open-slide-up-leave-active {
    animation-name: rcMenuOpenSlideUpOut;
    animation-play-state: running;
  }

  @keyframes rcMenuOpenSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0);
    }
    to {
      opacity: 1;
      transform-origin: 0 0;
      transform: scaleY(1);
    }
  }

  @keyframes rcMenuOpenSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0 0;
      transform: scaleY(1);
    }
    to {
      opacity: 0;
      transform-origin: 0 0;
      transform: scaleY(0);
    }
  }

  .rc-menu-open-zoom-appear,
  .rc-menu-open-zoom-enter {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  .rc-menu-open-zoom-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  .rc-menu-open-zoom-appear.rc-menu-open-zoom-appear-active,
  .rc-menu-open-zoom-enter.rc-menu-open-zoom-enter-active {
    animation-name: rcMenuOpenZoomIn;
    animation-play-state: running;
  }

  .rc-menu-open-zoom-leave.rc-menu-open-zoom-leave-active {
    animation-name: rcMenuOpenZoomOut;
    animation-play-state: running;
  }

  @keyframes rcMenuOpenZoomIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes rcMenuOpenZoomOut {
    0% {
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }
`;
RcMenuWrapper.displayName = 'RcMenuWrapper';

export const RcMenuExpandAnimation: AnimationDefinition = {
  enter(node: HTMLElement, done: () => void) {
    let height = -1;
    node.style.transition = 'height 0.2s ease-out';
    return animate(node, 'rc-menu-collapse', {
      start() {
        height = node.offsetHeight;
        node.style.height = '0';
      },
      active() {
        node.style.height = `${height}px`;
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },

  appear() {
    // TODO: Didn't find solution of this error, need to fix it later
    // @ts-ignore
    return RcMenuExpandAnimation.enter.apply(this, arguments);
  },

  leave(node: HTMLElement, done: () => void) {
    return animate(node, 'rc-menu-collapse', {
      start() {
        node.style.height = `${node.offsetHeight}px`;
      },
      active() {
        node.style.height = '0';
      },
      end() {
        node.style.height = '';
        done();
      },
    });
  },
};

import { useState, FC, PropsWithChildren } from 'react';
import { Div, H3, Span } from 'src/CleanSlate';
import { Markdown } from 'src/Markdown';

import styled from 'styled-components';

const AccordionContentButton = styled(H3)`
  padding: 20px 0px;
  cursor: pointer;
  margin: 0px;
  display: flex;
  span {
    position: relative;
    left: -13px;
  }
`;

const AccordionContentItem = styled(Div)`
  padding: 0px 10px;
`;

const ExpandableSvg = styled(Span)`
  cursor: pointer;
  .open {
    transform: rotate(0deg);
  }
  .closed {
    transform: rotate(270deg);
  }
`;

interface ExpandableButtonProps {
  defaultOpen?: boolean;
  heading: string;
}

export const ExpandableButton: FC<PropsWithChildren<ExpandableButtonProps>> = (
  props
) => {
  const { children, defaultOpen, heading } = props;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    setIsOpen((st) => !st);
  };

  return (
    <div>
      <AccordionContentButton onClick={toggleAccordion}>
        <ExpandableSvg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.505"
            height="8.057"
            viewBox="0 0 12.505 8.057"
            className={isOpen ? 'open' : 'closed'}
          >
            <path
              // eslint-disable-next-line max-len
              d="M151.952,104.46l6.225-5.334a.916.916,0,0,0,0-1.391L151.952,92.4a.916.916,0,0,0-1.512.7v10.67A.915.915,0,0,0,151.952,104.46Z"
              transform="translate(104.683 -150.44) rotate(90)"
            />
          </svg>
        </ExpandableSvg>
        <Markdown className="model-table-link" source={heading} inline={true} />
      </AccordionContentButton>
      {isOpen && <AccordionContentItem>{children}</AccordionContentItem>}
    </div>
  );
};

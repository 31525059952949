import { NavItem } from "src/DxDom";

export interface NavItemTextAndLinkWrapper {
  Text: string;
  Link: string;
}


export function getDefaultTitleTemplate(title: string) {
  // no title in head
  if (!title) {
    return '{title}';
  }

  if (title.indexOf('|') !== -1) {
    return `{title} | ${title}`;
  } else {
    return `{title} - ${title}`;
  }
}

export function getValidTitleTemplate(title: string, errorLog: VoidFunction) {
  const matchedStr = title.match(/{title}/);

  if (matchedStr) {
    return title;
  } else {
    errorLog();

    return '{title}';
  }
}

export function getListRootItem(markdown: string) {
  const items = markdown.split('*');
  return items[1];
}

export function removeNullorEmptyValues<T extends object>(obj: T): T {
  return Object.entries(obj).reduce<T>(
    (acc, [key, value]) =>
      value === null || value === undefined
        ? acc
        : ((acc[key as keyof typeof acc] = value), acc),
    {} as T
  );
}

export function isSafari() {
  const sUsrAg = navigator.userAgent.toLowerCase();
  const chromeAgent = sUsrAg.indexOf('chrome') > -1;
  const safariAgent = sUsrAg.indexOf('safari') > -1;

  if (!chromeAgent && safariAgent) {
    return true;
  } else {
    return false;
  }
}

export function getWalkthroughContentType(isContent?: boolean) {
  return isContent ? 'Guide' : 'Endpoint';
}

export function getFlattenedNavItems(
  navItems: ReadonlyArray<NavItem>
): NavItemTextAndLinkWrapper[] {

  const item = navItems.flatMap((navItem)=> [{
    Text: navItem.Text || '',
    Link: navItem.Link,
  },])

  const nestedItems = navItems.flatMap((navItem) =>
  navItem.SubItems.length
    ? getFlattenedNavItems(navItem.SubItems)
    : [
        {
          Text: navItem.Text || '',
          Link: navItem.Link,
        },
      ]
);
  return [...item, ...nestedItems];
}

export function combinePaths(baseUrl: string, path: string): string {
  const baseUrlHasSlash = baseUrl.endsWith('/');
  const pathHasSlash = path.startsWith('/');

  if (baseUrl === '') {
    return path;
  } else if (path === '') {
    return baseUrl;
  } else if (baseUrlHasSlash && pathHasSlash) {
    return baseUrl + path.substring(1);
  } else if (baseUrlHasSlash || pathHasSlash) {
    return baseUrl + path;
  } else {
    return baseUrl + '/' + path;
  }
}

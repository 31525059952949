import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const TypeLinkIcon = (props: SVGIconProps) => {
  return (
    <SVGIcon
      {...props}
      width={props.width || '10'}
      height={props.height || '10'}
      viewBox={props.viewBox || '0 0 10.961 10.951'}
    >
      <g id="Group_2270" data-name="Group 2270" transform="translate(0.6 0.6)">
        <g id="link_4_" data-name="link (4)" transform="translate(0 0)">
          <path
            id="Path_36573"
            data-name="Path 36573"
            d="M10,7.369a2.439,2.439,0,0,0,3.678.263l1.463-1.463a2.439,2.439,0,1,0-3.449-3.449l-.839.834"
            transform="translate(-6.095 -2.005)"
            fill="none"
            stroke=""
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            id="Path_36574"
            data-name="Path 36574"
            d="M7.851,9.973A2.439,2.439,0,0,0,4.173,9.71L2.71,11.173a2.439,2.439,0,1,0,3.449,3.449l.834-.834"
            transform="translate(-1.995 -5.585)"
            fill="none"
            stroke=""
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
        </g>
      </g>
    </SVGIcon>
  );
};

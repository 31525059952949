/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const GeneralDarkIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#3E445D" cx="64" cy="64" r="62" />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="64.1"
      cy="64"
      r="39.7"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="64.1"
      cy="64"
      r="29.1"
    />
    <rect
      x="60.9"
      y="45.5"
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="6.3"
      height="25.8"
    />
    <rect
      x="60.9"
      y="73.7"
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="6.3"
      height="5.5"
    />
  </SVGIcon>
);

export const GeneralLightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="64.1"
      cy="64"
      r="39.7"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="64.1"
      cy="64"
      r="29.1"
    />
    <rect
      x="60.9"
      y="45.5"
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="6.3"
      height="25.8"
    />
    <rect
      x="60.9"
      y="73.7"
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      width="6.3"
      height="5.5"
    />
  </SVGIcon>
);

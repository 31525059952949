/**
 * We re-export styled-components here with proper typings for the theme interface.
 * Ref: https://www.styled-components.com/docs/api#typescript
 */

import { Colors, StaticColors } from './Color';
import { CssStyles } from './Theme';
import { Hr, styled } from '../theme/CleanSlate';

// theme.ts
export interface ThemeInterface {
  primaryColor: string;
  secondaryColor?: string;
  linkColor: string;
  colors: Colors;
  cssStyles: CssStyles;
  staticColors: StaticColors;
}

export const Separator = styled(Hr)`
  margin: 0 -80px;
`;

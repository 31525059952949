import styled from 'styled-components';

import { Div } from 'src/CleanSlate';
import {
  WorkflowContextType,
  WorkflowState,
} from 'src/Context/WorkflowContext';

import Step from './WorkflowStep';
import { ScrollableMixin } from 'src/StyledElements';

interface StepsProps {
  steps: WorkflowState[string];
  onStepClick?: WorkflowContextType['onStepClick'];
  workflowName?: string;
  closeWorkflow: () => void;
}

const StepsContainer = styled(Div)`
  flex: 1;
  margin-top: 20px;
  overflow: auto;
  ${ScrollableMixin}

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  @media screen and (max-width: 990px) {
    padding-bottom: 80px;
  }
`;

const StepsStyled = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;

  > div:last-child {
    > div:first-child {
      > div {
        display: none;
      }
    }
  }
`;

export function Steps(props: StepsProps) {
  const { steps, onStepClick: setSteps, workflowName, closeWorkflow } = props;

  const onStepClick = (clickedStepName: string) => {
    closeWorkflow();
    if (workflowName) {
      setSteps?.(workflowName, clickedStepName);
    }
  };

  return (
    <StepsContainer scrollWidth="4px">
      <StepsStyled>
        {Object.entries(steps).map(([stepName, step]) => (
          <Step
            key={stepName}
            stepName={stepName}
            data={step}
            selected={step.isSelected ? stepName : ''}
            onStepClick={onStepClick}
          />
        ))}
      </StepsStyled>
    </StepsContainer>
  );
}

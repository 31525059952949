import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ChevronIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={props.strokeWidth || '4'}
      d="M19 9l-7 7-7-7"
    />
  </SVGIcon>
);

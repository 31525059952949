import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const ExpandCodeSampleIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '19'}
    height={props.height || '18'}
    viewBox={props.viewBox || '0 0 19 18'}
  >
    <path
      d="M10.73 3.75H15.23V8.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.22998 14.25H4.72998V9.75"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.23 3.75L4.72998 14.25"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);

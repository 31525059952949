/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const InfoIconStatus = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
  >
    <g clip-path="url(#clip0_105_1337)">
      <path
        d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6667 4.31814 11.6819 1.33337 8.00004 1.33337C4.31814 1.33337 1.33337 4.31814 1.33337 8.00004C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6667V8"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33337H8.00667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_1337">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
);

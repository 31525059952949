import * as React from 'react';
import { PublishedPackage } from '../DxDom';
import { LinkMapper } from '../LinkMapperContext';
import { Language } from '../PortalSettings';
import { getUrlFriendlyName } from '../PlatformConstants';
import { RouteComponentProps, withRouter } from 'react-router';
import { Platform } from './Platform';
import { createPath } from 'history';

// import * as ReactDOM from 'react-dom';
/**
 * Props for Navbar component bound with router props
 */
interface RoutedPlatformProps extends RouteComponentProps<object> {
  disabled?: boolean;
  currentTemplate: Language;
  linkMapper?: LinkMapper;
  packageInfo?: PublishedPackage;
}

/**
 * A Navbar component wrapper that jumps to the correct route when
 * language/template is changed.
 */
class RoutedPlatformComp extends React.Component<RoutedPlatformProps> {
  /**
   * Language change event handler.
   *
   * Build and redirect to a special x-redirect route.
   */
  jumpToLang = (lang: Language) => {
    // get DX link for section; DX links are language agnostic
    const realDxLink =
      this.props.linkMapper &&
      this.props.linkMapper.reverse(createPath(this.props.location));

    // pass DX link to next language doc where it can be used
    // to lookup  the correct section in that language and route to it
    const link =
      '/' +
      getUrlFriendlyName(lang) +
      (realDxLink ? '/x-redirect/' + encodeURIComponent(realDxLink) : '');
    this.props.history.push(link);
  };

  render() {
    return (
      <Platform
        disabled={this.props.disabled}
        currentTemplate={this.props.currentTemplate}
        onTemplateChange={this.jumpToLang}
        packageInfo={this.props.packageInfo}
      />
    );
  }
}

/**
 * Navbar component that binds with router
 */
export const RoutedPlatform = withRouter(RoutedPlatformComp);

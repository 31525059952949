import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const AndroidIcon = (props: SVGIconProps) => (
  <SVGIcon {...props}>
    <path
      d="M9.017,13.000 C8.354,12.999 7.816,13.548 7.816,14.223 L7.817,19.345 C7.817,
      20.022 8.355,20.569 9.019,20.569 C9.682,20.569 10.220,20.022 10.219,19.346 L10.219,
      14.222 C10.219,13.548 9.681,12.999 9.017,13.000 M18.704,8.399 L19.542,6.840 C19.587,
      6.759 19.558,6.655 19.476,6.609 C19.395,6.565 19.294,6.595 19.250,6.677 L18.403,
      8.252 C17.691,7.929 16.894,7.748 16.050,7.749 C15.208,7.748 14.411,7.929 13.702,8.250 L12.854,
      6.678 C12.810,6.596 12.708,6.566 12.628,6.611 C12.547,6.656 12.517,6.759 12.562,6.841 L13.401,
      8.399 C11.753,9.264 10.640,10.913 10.641,12.805 L21.460,12.804 C21.461,10.912 20.349,9.267 18.704,
      8.399 M13.589,10.809 C13.340,10.808 13.137,10.602 13.137,10.347 C13.137,10.093 13.339,9.885 13.590,
      9.885 C13.840,9.885 14.043,10.093 14.043,10.347 C14.043,10.602 13.840,10.809 13.589,10.809 M18.515,
      10.808 C18.265,10.808 18.062,10.602 18.062,10.346 C18.063,10.093 18.265,9.884 18.516,9.883 C18.765,
      9.884 18.969,10.093 18.969,10.346 C18.969,10.601 18.766,10.808 18.515,10.808 M10.685,13.231 L10.686,
      21.169 C10.686,21.891 11.260,22.474 11.968,22.474 L12.841,22.475 L12.842,25.185 C12.842,25.860 13.381,
      26.409 14.042,26.409 C14.706,26.409 15.244,25.860 15.244,25.184 L15.243,22.474 L16.865,22.474 L16.865,
      25.184 C16.865,25.859 17.404,26.409 18.066,26.407 C18.730,26.408 19.268,25.859 19.268,25.183 L19.267,
      22.474 L20.143,22.473 C20.850,22.473 21.425,21.890 21.425,21.167 L21.424,13.229 L10.685,13.231 L10.685,
      13.231 ZM24.289,14.221 C24.289,13.545 23.751,12.998 23.088,12.998 C22.424,12.997 21.887,13.546 21.887,
      14.222 L21.888,19.344 C21.888,20.021 22.425,20.567 23.089,20.567 C23.752,20.567 24.290,20.021 24.290,
      19.343 L24.289,14.221 L24.289,14.221 Z"
    />
  </SVGIcon>
);

/**
 * Parse a data-uri into a Blob
 */
export function parseDataUriIntoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const filename = dataURI.split(',')[0].split(';')[1].split('=')[1];

  // write the bytes of the string to an ArrayBuffer
  const ab = convertBase64ToArrayBuffer(byteString);
  return new File([ab], filename, { type: mimeString });
}

/**
 * Convert base64-encoded byte string to an ArrayBuffer
 */
export function convertBase64ToArrayBuffer(base64EncodedByteString: string) {
  const ab = new ArrayBuffer(base64EncodedByteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < base64EncodedByteString.length; i++) {
    ia[i] = base64EncodedByteString.charCodeAt(i);
  }
  return ab;
}

/**
 * Convert blob to base64-encoded byte string
 */
export function convertBlobToBase64(blob: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve((reader.result as string).split(',')[1]);
    };
    reader.readAsDataURL(blob);
  });
}

/**
 * Make a data-uri from a blob
 */
export function makeDataUrl(
  base64EncodedByteString: string,
  contentType?: string
): string {
  return `data:${contentType || ''};base64,${base64EncodedByteString}`;
}

/**
 * Get file name from a content-disposition header
 */
export function tryGetFilenameFromContentDisposition(
  contentDispHeader: string | undefined | null
) {
  if (!contentDispHeader) {
    return undefined;
  }

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDispHeader);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  } else {
    return undefined;
  }
}

/**
 * Get header value from a header map
 */
export function getHeaderValue(
  headers: { [key: string]: string },
  headerName: string
) {
  const lowerCaseHeader = headerName.toLowerCase();
  const header = Object.keys(headers).find(
    (h) => h.toLowerCase() === lowerCaseHeader
  );
  if (header) {
    return headers[header];
  }

  return undefined;
}

/**
 * Map of mime-types and file extensions
 */
const extensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'image/bmp': 'bmp',
  'image/webp': 'webp',
  'text/html': 'html',
  'application/javascript': 'js',
  'text/css': 'css',
  'text/csv': 'csv',
  'application/json': 'json',
  'application/xml': 'xml',
  'text/xml': 'xml',
  'text/yaml': 'yaml',
  'text/markdown': 'md',
  'text/x-markdown': 'md',
  'text/plain': 'txt',
  'application/zip': 'zip',
  'application/pdf': 'pdf',
};

/**
 * Get extension for filename from mime-type
 */
export function getExtensionFromContentType(
  contentTypeHeader: string
): string | undefined {
  const cth = getMimeTypeWithoutEncoding(contentTypeHeader);

  if (cth in extensionMap) {
    return extensionMap[cth as keyof typeof extensionMap];
  }

  return undefined;
}

/**
 * Get mime-type with charset encoding part
 */
export function getMimeTypeWithoutEncoding(header: string) {
  header = header.toLowerCase();
  const pos = header.indexOf(';');
  if (pos !== -1) {
    return header.substr(0, pos).trim();
  }

  return header;
}

/**
 * Check if the link is an external link
 */
export function isExternalLink(link: string) {
  if (link.startsWith('/') || link.startsWith('#')) {
    return false;
  }
  try {
    const url = new URL(link);
    if (url.host !== window.location.host) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}

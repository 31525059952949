import * as React from 'react';
import styled from 'styled-components';

import { FullTextPicker } from './FuzzyPicker';
import { NavItem } from './DxDom';
import { Button, Div } from './CleanSlate';
import { withRouter, RouteComponentProps } from 'react-router';
import { flatMap } from './DxDomUtils';
import { renderCssStyles } from './Theme/Theme';
import { InlineNavbarProps } from './Navbar/Navbar';
import { SearchIcon } from './Icons/Ui/SearchIcon';
import { Language, PortalSettings } from './PortalSettings';
import { onSearchOptionOpen } from './Analytics';

const MutedText = styled.div<{ selected: boolean }>`
  color: ${(props) =>
    props.selected ? props.theme.colors.C201 : props.theme.colors.C1100};

  ${({ theme: { cssStyles } }) =>
    cssStyles && renderCssStyles(cssStyles.body.text1)};

  font-size: 13.33px;
  font-weight: 400;
`;

const SearchButton = styled(Button)<InlineNavbarProps & { embed?: boolean }>`
  background-color: ${(props) => props.theme.colors.C001};
  width: 100%;
  height: ${(props) =>
    props.isInlineNavbar && !props.isOnlySearch ? '32px' : '40px'};
  margin: ${(props) => (props.isInlineNavbar ? '0' : '22px auto 0')};
  line-height: ${(props) =>
    props.isInlineNavbar && !props.isOnlySearch ? '30px' : '38px'};
  padding: 0 12px !important;
  border: 1px solid ${(props) => props.theme.colors.C300}!important;
  font-weight: 400;
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.embed
      ? `
				width: 180px;
				margin: auto 13px;
				height: 30px;
			`
      : ''};

  ${(props) =>
    props.isInlineNavbar && !props.isOnlySearch
      ? `
				max-width: 121px;
			`
      : ''};

  svg {
    margin-right: 7px;
  }

  @media screen and (max-width: 1200px) {
    ${(props) =>
      props.isInlineNavbar && !props.isOnlySearch
        ? `
				max-width: 106px;
			`
        : ''};
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    height: 32px;
    line-height: 30px;
    max-width: 200px;
    margin: 0;
    min-width: 37px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    height: 50px;
    line-height: 30px;
    margin: 0;
    max-width: ${(props) => (props.isInlineNavbar ? '90px' : '41px')};
    background-color: transparent;
    border-radius: 0;
    border: none !important;
    border-left: 4px solid white !important;
    position: relative;
  }
`;

const Search = styled(Div)<{ isInlineNavbar: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13.33px;
  color: ${(props) => props.theme.colors.C1100};
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 589px) {
    ${(props) =>
      props.isInlineNavbar
        ? `
				color: ${props.theme.colors.C901};
			`
        : ''};

    path {
      stroke: ${(props) => props.theme.colors.C901};
    }
  }
`;

const IconWrapper = styled(Div)`
  width: 19px;
  height: 14px;
  display: flex;
  flex: 0 0 auto;
`;

const SearchShortcut = styled(Div)`
  color: ${(props) => props.theme.colors.C700};
  background-color: ${(props) => props.theme.colors.C201};
  border: 1px solid ${(props) => props.theme.colors.C300};
  border-radius: 4px;
  width: 60px;
  height: 16px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Consolas;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 990px) {
    display: none;
  }
`;

export interface NavItemWrapper {
  item: NavItem;
  parents: NavItem[];
}

export interface QuickSearchProps extends RouteComponentProps<object> {
  navItems: ReadonlyArray<NavItem>;
  disabled?: boolean;
  embed: boolean | undefined;
  className?: string;
  onSelect?: (key: string) => void;
  isInlineNavbar: boolean;
  isOnlySearch?: boolean;
}

export type QuickSearchCompProps = QuickSearchProps & {
  portalSettings: PortalSettings;
};

interface QuickSearchState {
  isOpen: boolean;
}

class QuickSearchComp extends React.Component<
  QuickSearchCompProps,
  QuickSearchState
> {
  state = {
    isOpen: false,
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onChange = (item: NavItemWrapper) => {
    this.setState({ isOpen: false }, () => {
      this.props.history.push(item.item.Link);

      // check for the leaf-node case first
      if (item.parents.length > 1) {
        this.props.onSelect!(item.parents[item.parents.length - 1].Link);
      } else {
        this.props.onSelect!(item.item.Link);
      }
    });
  };

  onKeyEvent = (event: KeyboardEvent) => {
    if (event.key === 'k' && event.ctrlKey && !this.props.disabled) {
      event.preventDefault();
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  onSearchButtonClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
    onSearchOptionOpen(this.props.portalSettings);
  };

  getItem(item: NavItemWrapper) {
    return item.item.Text || 'Unnamed';
  }

  getItemKey(item: NavItemWrapper) {
    return item.item.Link;
  }

  renderItem(item: NavItemWrapper, selected: boolean, inputQuery: string) {
    let Text: string;

    /*
     * Regix to remove hyper links
     * \[[^]+\] - checks if a words is in between [some words]
     * (https?:\/\/\S+\) - checks if the links is in betwween [some links] and starts with https
     * /!|\[[^]+\]\(https?:\/\/\S+\)|&nbsp;/g
     */
    const TEXT_REGIX = /!|\[[^]+\]\(https?:\/\/\S+\)|&nbsp;/g;

    if (item.item.Text && new RegExp(TEXT_REGIX).test(item.item.Text)) {
      Text = item.item.Text.replace(TEXT_REGIX, '');
    } else {
      Text = item.item.Text || '';
    }

    const splitInputQuery = inputQuery.split(' ');
    const splitResultString = Text.split(' ');

    const highlighter = (filteredArray: string[], part: string) => {
      const arrayWithoutDuplicates = Array.from(new Set(filteredArray));
      const longestElementOfArray = arrayWithoutDuplicates.reduce(
        (longest, current) =>
          current.length > longest.length ? current : longest
      );
      const regex = new RegExp(longestElementOfArray, 'i');
      const parts = part.split(regex);

      return (
        <>
          {parts[0]}
          <mark>{part.match(regex)?.[0]}</mark>
          {`${parts[1]} `}
        </>
      );
    };

    return (
      <React.Fragment>
        <div>
          {splitResultString.map((item) => {
            const arrayOfQueryItemsMatchingSearchResults =
              splitInputQuery.filter((singleQueryItem) =>
                item.toLowerCase().includes(singleQueryItem.toLowerCase())
              );
            return arrayOfQueryItemsMatchingSearchResults.length
              ? highlighter(arrayOfQueryItemsMatchingSearchResults, item)
              : `${item} `;
          })}
        </div>
        <MutedText selected={selected}>
          {item.parents.map((i) => i.Text).join(' > ')}
        </MutedText>
      </React.Fragment>
    );
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyEvent);
  }
  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyEvent);
  }

  render() {
    const {
      isInlineNavbar,
      portalSettings,
      isOnlySearch,
      disabled,
      className,
      navItems,
    } = this.props;

    const showShortcut = !isInlineNavbar || (isInlineNavbar && isOnlySearch);
    return (
      <React.Fragment>
        <SearchButton
          isInlineNavbar={isInlineNavbar}
          isOnlySearch={isOnlySearch}
          title="Press Ctrl+K to open search."
          onClick={this.onSearchButtonClick}
          disabled={disabled}
          className={className}
        >
          <Search isInlineNavbar={isInlineNavbar}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            Search
          </Search>

          {showShortcut && <SearchShortcut>Ctrl + K</SearchShortcut>}
        </SearchButton>
        <FullTextPicker
          isOpen={this.state.isOpen}
          onClose={this.onClose}
          onChange={this.onChange}
          items={getFlattenedLinks(navItems).filter((n) => !n.item.IsExternal)}
          renderItem={this.renderItem}
          itemValue={this.getItem}
          itemKey={this.getItemKey}
          displayCount={30}
          portalSettings={portalSettings}
        />
      </React.Fragment>
    );
  }
}

export const QuickSearch = withRouter(QuickSearchComp);

function getFlattenedLinks(
  navItems: ReadonlyArray<NavItem>,
  parents: NavItem[] = []
): NavItemWrapper[] {
  const items: NavItemWrapper[] = navItems.map((navItem) => ({
    item: navItem,
    parents: parents,
  }));
  return flatMap(
    (f) => [f].concat(getFlattenedLinks(f.item.SubItems, [...parents, f.item])),
    items
  );
}

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { PopoverProps } from 'src/base-components/Popover';
import { Div, P } from 'src/CleanSlate';
import { Markdown, StyledLink } from 'src/Markdown';
import { PopoverButton } from './PopoverButton';
import { TypesPopover } from './TypesPopover';
import Tooltip from 'src/Tooltip/Tooltip';
import { LinkMapperContext } from 'src/LinkMapperContext';

type HttpTypesProps = {
  source: string;
  label: string;
  useAlwaysPortal?: boolean;
  containerName?: string;
  link?: string;
  dataType?: string;
  isFromRParamInfo?: boolean;
} & Parameters<PopoverProps['children']>[0];

const HttpTypesStyled = styled(Div)`
  display: flex;
  align-items: center;
  .rjsf-base-type {
    margin-right: 12px !important;
    border: ${(props) => `1.5px solid ${props.theme.staticColors.Goose.C100}`};
    border-radius: 3px;
    opacity: 1;
    background-color: ${(props) => props.theme.colors.C001};

    .tag-label {
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      padding: 0px 8px;
      font-size: 11.85px;
      font-family: 'Rubik', sans-serif;
      color: ${(props) => props.theme.staticColors.Goose.C100};
      font-weight: 500;
    }
  }
  .type-empty-source {
    color: ${(props) => props.theme.staticColors.Goose.C200}!important;
    font-size: ${(props) =>
      props.theme.cssStyles.body.text2.fontSize || '13.33px'} !important;
    font-style: italic;
  }
`;

const DataTypeLink = styled(Div)`
  margin-right: 12px;
  font-size: ${(props) =>
    props.theme.cssStyles.body.text2.fontSize || '13.33px'} !important;
  font-family: ${({ theme }) => theme.cssStyles.code.fontFamily};
  position: relative;
  top: 2px;
  a {
    font-size: ${(props) =>
      props.theme.cssStyles.body.text2.fontSize || '13.33px'} !important;
    font-family: ${({ theme }) =>
      theme.cssStyles.code.fontFamily + '!important'};
  }
`;
const PopoverButtonWrapper = styled(Div)`
  margin-right: 12px;
`;

export const HttpTypes: FC<PropsWithChildren<HttpTypesProps>> = (props) => {
  const {
    attributes,
    styles,
    visible,
    source,
    label,
    useAlwaysPortal,
    containerName,
    toggleVisibility,
    setPopperElement,
    setReferenceElement,
    link,
    dataType,
    isFromRParamInfo,
  } = props;

  const hasSameContainerName = dataType === containerName;

  const sliceChar = isFromRParamInfo ? 25 : 50;

  const updatedDataType =
    dataType && checkIfDataTypeIsLarge(dataType, sliceChar);

  const markdownText = !hasSameContainerName ? dataType : source || '';

  return (
    <HttpTypesStyled>
      {label && (
        <div className="rjsf-base-type" ref={setReferenceElement}>
          <span className="tag-label">{label}</span>
        </div>
      )}
      <DataTypeLink>
        {link ? (
          !dataType ? (
            <Markdown source={`[${updatedDataType || dataType}](${link})`} />
          ) : (
            <LinkMapperContext.Consumer>
              {(linkMapper) => (
                <StyledLink to={{ pathname: linkMapper(link) || '' }}>
                  {updatedDataType || dataType}
                </StyledLink>
              )}
            </LinkMapperContext.Consumer>
          )
        ) : (
          updatedDataType || dataType
        )}
      </DataTypeLink>

      {(!hasSameContainerName || updatedDataType || markdownText) && (
        <PopoverButtonWrapper>
          <Tooltip
            text={'More Details'}
            parentRefranceId="app-layout-docs-container"
          >
            <PopoverButton onClick={toggleVisibility} />
          </Tooltip>
        </PopoverButtonWrapper>
      )}
      {visible && (
        <TypesPopover
          title={hasSameContainerName ? dataType : containerName}
          linkTo={link}
          refFunc={setPopperElement}
          toggleVisibility={toggleVisibility}
          style={styles.popper}
          {...attributes.popper}
          useAlwaysPortal={useAlwaysPortal}
        >
          {markdownText && !hasSameContainerName ? (
            <Markdown source={markdownText} />
          ) : (
            <P className="type-empty-source">No further detail available!</P>
          )}
        </TypesPopover>
      )}
    </HttpTypesStyled>
  );
};

export function checkIfDataTypeIsLarge(
  dataType: string,
  trimTextLimit: number
) {
  if (dataType.length > trimTextLimit) {
    return dataType.slice(0, trimTextLimit) + '...';
  }
}

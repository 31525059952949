import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const RightLeftArrows = (props: SVGIconProps) => {
  const fallbackColor = '#858ba5';
  return (
    <SVGIcon
      {...props}
      width={props.width || '32.838'}
      height={props.height || '7.413'}
      viewBox={props.viewBox || '0 0 32.838 7.413'}
    >
      <g transform="translate(-484.941 -178.189)">
        <path
          d="M0,10.054,2.646,12.7m0,0,2.646-2.646M2.646,12.7V0"
          transform="translate(498.702 179.25) rotate(90)"
          fill="none"
          stroke={props.stroke || fallbackColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M0,2.646,2.646,0m0,0L5.292,2.646M2.646,0V12.7"
          transform="translate(516.718 179.25) rotate(90)"
          fill="none"
          stroke={props.stroke || fallbackColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </SVGIcon>
  );
};

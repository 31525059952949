import Menu, { MenuItem, MenuKeyChangeEvent, SubMenu } from 'rc-menu';
import React from 'react';
import { Div } from 'src/CleanSlate';
import { ResponseContentInfo } from 'src/DxDom';
import { ChevronIcon } from 'src/Icons/Ui/ChevronIcon';
import { RcMenuExpandAnimation, RcMenuWrapper } from 'src/RcMenuWrapper';
import styled from 'styled-components';
import { ellipsisMixin } from 'src/StyledElements';

const MenuWrapper = styled(RcMenuWrapper)`
  height: 32px;
  margin-left: 5px;

  @media screen and (max-width: 1200px) {
    min-width: 100px;
  }

  .rc-menu-root.rc-menu-horizontal.rc-menu {
    background-color: ${(props) => props.theme.colors.C001} !important;
    width: 100%;
    max-width: 155px;
    min-width: 105px;
    height: 32px;
    font-weight: 400 !important;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    border: 1px solid ${(props) => props.theme.colors.C300} !important;
    line-height: 30px;

    &:hover {
      border-color: ${(props) => props.theme.colors.C400} !important;
    }

    @media screen and (max-width: 1200px) {
      min-width: 96px;
    }

    @media screen and (max-width: 500px) {
      background: transparent !important;
      color: ${(props) => props.theme.colors.C801}!important;
      border-color: transparent !important;

      &:hover {
        border-color: transparent !important;
      }
    }
  }

  .rc-menu-root.rc-menu-horizontal.rc-menu
    > .rc-menu-submenu.rc-menu-submenu-horizontal
    > .rc-menu-submenu-title {
    padding: 0;
    background: no-repeat no-repeat left center;

    .rc-menu-submenu-arrow {
      display: none;
    }

    @media screen and (max-width: 500px) {
      background-position: 3%;
      transform: translate(8%);
    }
  }

  .rc-menu-horizontal > .rc-menu-submenu {
    width: 100%;
    border: none;
  }
  .rc-menu-submenu-title {
    width: 100%;
    padding: 0 12px !important;
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme.colors.C801} !important;
    text-align: left;
    font-weight: 400;
    text-decoration: none;

    @media screen and (max-width: 500px) {
      background: transparent !important;
      color: ${(props) => props.theme.colors.C801}!important;
      border-color: transparent !important;
      padding: 0 12px !important;
      line-height: 50px;
    }
  }

  .rc-menu-submenu-selected {
    background-color: ${(props) => props.theme.colors.C001};

    @media screen and (max-width: 500px) {
      background: transparent !important;
    }
  }

  .rc-menu-submenu {
    z-index: 1;
  }

  .rc-menu-submenu .rc-menu {
    border: 1px solid ${(props) => props.theme.colors.C300};
    box-shadow: 0 0 4px ${(props) => props.theme.colors.C300};
    border-radius: 4px;
  }

  .rc-menu-item-selected,
  .rc-menu-item-active {
    background-color: ${(props) => props.theme.colors.C100};
    color: ${(props) => props.theme.colors.C801};
  }

  .rc-menu-submenu-active > .rc-menu-submenu-title,
  .rc-menu-submenu-selected {
    background-color: ${(props) => props.theme.colors.C100};

    &:hover {
      background-color: ${(props) => props.theme.colors.C100};
      border-color: ${(props) => props.theme.colors.C001};
    }
  }
  .rc-menu-item,
  .rc-menu-item > a,
  .rc-menu-vertical > .rc-menu-item,
  .rc-menu-submenu > .rc-menu > .rc-menu-submenu,
  .rc-menu-submenu > .rc-menu > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 2px 8px;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }
  /* .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 10px 12px!important;
  } */
  .rc-menu-item > a {
    padding: 10px 12px !important;
  }
  .rc-menu-horizontal .rc-menu-submenu-arrow {
    right: 8px;
    top: -2px !important;
    @media screen and (max-width: 500px) {
      line-height: 50px;
    }
  }
  .rc-menu-horizontal .rc-menu-submenu-arrow:before {
    border-top-color: ${(props) => props.theme.primaryColor};
  }

  .rc-menu-submenu-vertical
    > .rc-menu-submenu-title
    > .rc-menu-submenu-arrow:before {
  }
`;
MenuWrapper.displayName = 'MenuWrapper';

interface ContentSelectorProps {
  contentInfoList: ReadonlyArray<ResponseContentInfo>;
  onSelect: (ev: MenuKeyChangeEvent) => void;
  selected: string;
}

const SMenuItem = styled(MenuItem)`
  cursor: pointer;
`;

const Title = styled(Div)`
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: '0 12px';

  span {
    color: ${(props) => props.theme.colors.C901};
    font-size: 13.33px;
    line-height: 24px;
    font-weight: 400;
    margin-right: 6px;
    ${ellipsisMixin};
  }

  @media screen and (max-width: 1200px) {
    padding: '0 24px';

    span {
      margin-left: 8px;
    }
  }
`;
Title.displayName = 'Title';

export function ContentTypeSelector(props: ContentSelectorProps) {
  const { contentInfoList, onSelect, selected } = props;
  const containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  const SubMenuTitle = () => {
    return (
      <Title>
        <span>{props.selected}</span>
        <ChevronIcon
          viewBox="0 0 24 24"
          width="12px"
          fill="none"
          stroke="#9CAAB8"
        />
      </Title>
    );
  };

  return (
    <MenuWrapper>
      <span ref={containerRef} />
      <Menu
        mode="horizontal"
        triggerSubMenuAction="click"
        openAnimation={RcMenuExpandAnimation}
        // TODO Do not use lambda functions in render()
        getPopupContainer={() =>
          containerRef.current ? containerRef.current : document.body
        }
        onSelect={onSelect}
        selectedKeys={[selected]}
      >
        <SubMenu title={SubMenuTitle()}>
          {contentInfoList.map((contentInfo) => (
            <SMenuItem key={contentInfo.ContentType}>
              {contentInfo.ContentType}
            </SMenuItem>
          ))}
        </SubMenu>
      </Menu>
    </MenuWrapper>
  );
}

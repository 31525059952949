export type TypographyVariants =
  | 'fontFamily'
  | 'fontSize'
  | 'fontWeight'
  | 'fontStyle'
  | 'lineHeight'
  | 'textTransform';

export type TypographyStyles = { [typo in TypographyVariants]: string };

export type HeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type BodyVariants = 'text1' | 'text2' | 'text3';

export type CodeVariants = 'inlineCode' | 'blockCode';

export interface CssStyles {
  headings: { [heading in HeadingVariants]: TypographyStyles } & {
    fontFamily: string;
  };
  body: { [body in BodyVariants]: TypographyStyles } & { fontFamily: string };
  code: { [code in CodeVariants]: TypographyStyles } & { fontFamily: string };
}

/* eslint-disable max-len */
// '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue, Arial", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",  "Segoe UI Symbol"';

export const defaultFontSource =
  'https://fonts.googleapis.com/css?family=Rubik:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i';
export const bodyDefaultFamily = 'Rubik, sans-serif';
export const codeDefaultFamily = 'Courier Prime, monospace';

//     monospace
export const defaultCssStyles: CssStyles = {
  headings: {
    fontFamily: bodyDefaultFamily,
    h1: {
      fontFamily: bodyDefaultFamily,
      fontSize: '27px',
      fontWeight: '500',
      fontStyle: 'normal',
      lineHeight: '1.3',
      textTransform: 'none',
    },
    h2: {
      fontFamily: bodyDefaultFamily,
      fontSize: '24px',
      fontWeight: '500',
      fontStyle: 'normal',
      lineHeight: '1.3',
      textTransform: 'none',
    },
    h3: {
      fontFamily: bodyDefaultFamily,
      fontSize: '21.36px',
      fontWeight: '500',
      fontStyle: 'normal',
      lineHeight: '1.3',
      textTransform: 'none',
    },
    h4: {
      fontFamily: bodyDefaultFamily,
      fontSize: '19px',
      fontWeight: '500',
      fontStyle: 'normal',
      lineHeight: '1.3',
      textTransform: 'none',
    },
    h5: {
      fontFamily: bodyDefaultFamily,
      fontSize: '16.88px',
      fontWeight: '500',
      fontStyle: 'normal',
      lineHeight: '1.3',
      textTransform: 'none',
    },
    h6: {
      fontFamily: bodyDefaultFamily,
      fontSize: '15px',
      fontWeight: '500',
      fontStyle: 'normal',
      lineHeight: '1.3',
      textTransform: 'none',
    },
  },
  body: {
    fontFamily: bodyDefaultFamily,
    text1: {
      fontFamily: bodyDefaultFamily,
      fontSize: '15px',
      fontWeight: '400',
      fontStyle: 'normal',
      lineHeight: '1.75',
      textTransform: 'none',
    },
    text2: {
      fontFamily: bodyDefaultFamily,
      fontSize: '13.33px',
      fontWeight: '400',
      fontStyle: 'normal',
      lineHeight: '1.75',
      textTransform: 'none',
    },
    text3: {
      fontFamily: bodyDefaultFamily,
      fontSize: '11.85px',
      fontWeight: '400',
      fontStyle: 'normal',
      lineHeight: '1.75',
      textTransform: 'none',
    },
  },
  code: {
    fontFamily: codeDefaultFamily,
    blockCode: {
      fontFamily: codeDefaultFamily,
      fontSize: '13.33px',
      fontWeight: '400',
      fontStyle: 'normal',
      lineHeight: '1.75',
      textTransform: 'none',
    },
    inlineCode: {
      fontFamily: codeDefaultFamily,
      fontSize: '15px',
      fontWeight: '400',
      fontStyle: 'normal',
      lineHeight: '1.75',
      textTransform: 'none',
    },
  },
};

export function renderCssStyles(type: TypographyStyles) {
  return `
  font-family: ${type.fontFamily};
  font-size: ${type.fontSize};
  font-style: ${type.fontStyle};
  font-weight: ${type.fontWeight};
  line-height: ${type.lineHeight};
  text-transform: ${type.textTransform};
`;
}

export function compareWithDefaultSettings(
  object: CssStyles,
  defaultStyles: CssStyles
) {
  Object.keys(defaultStyles).forEach((t) => {
    const key = t as keyof CssStyles;
    if (object.hasOwnProperty(key)) {
      if (isObject(object[key]) && Object.keys(object[key]).length > 0) {
        const objectValue = object[key] as unknown;
        const defaultCssStyles = defaultStyles[key] as unknown;
        compareWithDefaultSettings(
          objectValue as CssStyles,
          defaultCssStyles as CssStyles
        );
      } else {
        return;
      }
    } else {
      //@ts-ignore
      return (object[key] = defaultStyles[t]);
    }
  });
  return object;
}

export function isObject(obj: object): boolean {
  if (obj instanceof Array || typeof obj !== 'object') {
    return false;
  }
  return true;
}

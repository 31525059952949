/* eslint-disable camelcase */
import { Colors } from '../theme/Color';
import { defaultColors } from '../theme/DefaultTheme';
import {
  defaultCssStyles,
  CssStyles,
  compareWithDefaultSettings,
} from '../theme/Theme';
import { getDefaultTitleTemplate } from '../utilities/Utility';
/**
 * Portal settings interface
 */
export interface PortalSettings {
  titleTemplate?: string;
  container: string;
  portalStyle: 'default' | 'embedded';
  apiKey: string;
  baseUrl: string;
  baseRoute: string;
  enableExport: boolean;
  enableConsoleCalls: boolean;
  useProxyForConsoleCalls: boolean;
  renameTypeScriptToNodejs: boolean;
  sideMenuTitleBehavior: 'default' | 'collapsible';
  languageSettings: { [language in Language]?: LanguageSettings };
  allowedExportFormats: ExportFormatTemplate[];
  initialPlatform: Language;
  themeOverrides: {
    themeType?: 'cool';
    palette: {
      primaryColor: string;
      secondaryColor?: string;
      linkColor: string;
      colors: Colors;
    };
    fontSource: string[];
    cssStyles: CssStyles;
  };
  codegenApiRoutes: CodegenApiRoutes;
  renameHttpToRest: boolean;
  routeStyle: 'hash' | 'memory' | 'browser';
  enableAnalytics: boolean;
}
export interface LanguageSettings {
  sdkDownloadLink?: string;
  disableSdkDownload?: boolean;
  stabilityLevelTag?: 'alpha' | 'beta';
}
export interface CodegenApiRoutes {
  docsgen: string;
  codegen: string;
  transform: string;
  apiProxy: string;
  apiProxy2: string;
  oauth2Callback: string;
}

const APIMATIC_URL_PROD = 'https://api.apimatic.io/';
const APIMATIC_URL_DEV = 'https://api.dev.apimatic.io/';

/**
 * Portal settings compatible language names
 *
 * TODO: Migrate to DX Dom language type instead of custom type
 */
export type Language =
  | 'http_curl_v1'
  | 'cs_net_standard_lib'
  | 'cs_portable_net_lib'
  | 'cs_universal_windows_platform_lib'
  | 'java_gradle_android_lib'
  | 'objc_cocoa_touch_ios_lib'
  | 'java_eclipse_jre_lib'
  | 'php_generic_lib_v2'
  | 'python_generic_lib'
  | 'ruby_generic_lib'
  | 'angular_javascript_lib'
  | 'ts_generic_lib'
  | 'go_generic_lib';

/**
 * Allowed export formats template names
 */
export type ExportFormatTemplate =
  | 'postman10'
  | 'postman20'
  | 'openapi3json'
  | 'openapi3yaml'
  | 'openapi31json'
  | 'openapi31yaml'
  | 'swagger20'
  | 'swaggeryaml'
  | 'swagger10'
  | 'raml'
  | 'raml10'
  | 'insomnia'
  | 'insomniayaml'
  | 'apiblueprint'
  | 'wadl2009'
  | 'apimatic'
  | 'wsdl'
  | 'graphqlschema';

/**
 * Allowed language/template names
 */
export const validLanguages: Language[] = [
  'http_curl_v1',
  'cs_net_standard_lib',
  'cs_portable_net_lib',
  'cs_universal_windows_platform_lib',
  'java_gradle_android_lib',
  'objc_cocoa_touch_ios_lib',
  'java_eclipse_jre_lib',
  'php_generic_lib_v2',
  'python_generic_lib',
  'ruby_generic_lib',
  'angular_javascript_lib',
  'ts_generic_lib',
  'go_generic_lib',
];

export const defaultExportFormatTemplates: ExportFormatTemplate[] = [
  'postman10',
  'postman20',
  'openapi3json',
  'openapi3yaml',
  'openapi31json',
  'openapi31yaml',
  'swagger20',
  'swaggeryaml',
  'swagger10',
  'raml',
  'raml10',
  'insomnia',
  'insomniayaml',
  'apiblueprint',
  'wadl2009',
  'apimatic',
  'wsdl',
];

export const allowedExportFormatTemplates: ExportFormatTemplate[] = [
  ...defaultExportFormatTemplates,
  // GraphQL is not shown in the API Spec export menu by default. It is not
  // ready for public use yet.
  'graphqlschema',
];

/**
 * Default settings to override from
 */
export const defaultSettings: PortalSettings = {
  container: '',
  titleTemplate: getDefaultTitleTemplate(document.title),
  portalStyle: 'default',
  apiKey: '',
  baseUrl: 'https://api.apimatic.io',
  baseRoute: '',
  enableExport: true,
  enableConsoleCalls: true,
  useProxyForConsoleCalls: true,
  renameTypeScriptToNodejs: false,
  sideMenuTitleBehavior: 'default',
  languageSettings: {
    http_curl_v1: {},
    cs_net_standard_lib: {},
    java_gradle_android_lib: {},
    objc_cocoa_touch_ios_lib: {},
    java_eclipse_jre_lib: {},
    php_generic_lib_v2: {},
    python_generic_lib: {},
    ruby_generic_lib: {},
    angular_javascript_lib: {},
    ts_generic_lib: {},
    go_generic_lib: {},
  },
  allowedExportFormats: [
    'postman10',
    'postman20',
    'openapi31json',
    'openapi31yaml',
    'openapi3json',
    'openapi3yaml',
    'swagger20',
    'swaggeryaml',
    'swagger10',
    'raml',
    'raml10',
    'insomnia',
    'insomniayaml',
    'apiblueprint',
    'wadl2009',
    'apimatic',
    'wsdl',
  ],
  initialPlatform: 'http_curl_v1',
  themeOverrides: {
    themeType: 'cool',
    palette: {
      primaryColor: defaultColors.primary,
      linkColor: defaultColors.link,
      colors: defaultColors.grayCool,
    },
    fontSource: [],
    cssStyles: defaultCssStyles,
  },
  codegenApiRoutes: {
    docsgen: '/docsgen?template={template}&apiKey={apikey}',
    codegen: '/codegen?template={template}&apikey={apikey}&dl=1',
    transform: '/transform?format={format}&apikey={apikey}&dl=1',
    apiProxy: 'https://proxy.apimatic.io/api/proxy',
    apiProxy2: 'https://proxy2.apimatic.io/',
    oauth2Callback: 'https://proxy.apimatic.io/oauth2_callback',
  },
  // codegenApiRoutes: {
  //   docsgen:
  //     '/api/api-entities/{apikey}/published-artifacts/docs-generations/generated-file?template={template}',
  //   codegen:
  //     '/api/api-entities/{apikey}/published-artifacts/code-generations/generated-file?template={template}',
  //   transform:
  //     '/api/api-entities/{apikey}/published-artifacts/transformations/generated-file?format={format}',
  //   apiProxy: 'https://apimatic-proxy.azurewebsites.net/api/proxy'
  // },
  renameHttpToRest: false,
  routeStyle: 'hash',
  enableAnalytics: true,
};

/**
 * Validate user-provided portal settings and return a new portal settings
 * with defaults filled in.
 * @param obj User-provided portal settings
 * @param logError Error logger
 */
export function validatePortalSettings(
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  obj: any,
  logError: (x: string) => void = logConsoleError
): PortalSettings | undefined {
  const settings: PortalSettings = { ...defaultSettings };

  if (typeof obj !== 'object') {
    logError('Invalid portal settings provided. Expected object.');
    return undefined;
  }

  if (!obj.container || typeof obj.container !== 'string') {
    logError('Expected container to be string.');
    return undefined;
  }
  if (document.getElementById(obj.container) === null) {
    logError('Expected container to be a valid HTML element ID.');
    return undefined;
  }
  settings.container = obj.container;

  if (obj.titleTemplate) {
    if (typeof obj.titleTemplate !== 'string') {
      logError('Expected titleTemplate to be string.');
      return undefined;
    }

    settings.titleTemplate = obj.titleTemplate;
  }

  if (obj.portalStyle) {
    // 'embedded' portal style is depreciated.
    // Overiding embedded portal style with 'default' portal style.
    if (obj.portalStyle === 'embedded') {
      obj.portalStyle = 'default';
    }
    if (obj.portalStyle !== 'default') {
      logError("Expected portalStyle to be either 'default' or 'embedded'.");
      return undefined;
    }
    settings.portalStyle = obj.portalStyle;
  }

  if (!obj.apiKey || typeof obj.apiKey !== 'string') {
    logError('Expected apiKey to be string.');
    return undefined;
  }
  settings.apiKey = obj.apiKey;

  if (obj.baseUrl) {
    if (typeof obj.baseUrl !== 'string') {
      logError('Expected baseUrl to be string.');
      return undefined;
    }
    settings.baseUrl = obj.baseUrl;
  }

  if (obj.baseRoute) {
    if (typeof obj.baseRoute !== 'string') {
      logError('Expected baseRoute to be string.');
      return undefined;
    }
    settings.baseRoute = obj.baseRoute;
  }

  if (obj.enableExport !== undefined) {
    if (typeof obj.enableExport !== 'boolean') {
      logError('Expected enableExport to be boolean.');
      return undefined;
    }
    settings.enableExport = obj.enableExport;
  }
  if (obj.allowedExportFormats && obj.enableExport) {
    if (!(obj.allowedExportFormats instanceof Array)) {
      logError('Expected allowedExportFormats to be array.');
      return undefined;
    }
    if (
      obj.allowedExportFormats.some(
        (f: string) => allowedExportFormatTemplates.indexOf(f as any) === -1
      )
    ) {
      logError('Expected allowedExportFormat to contain valid format names.');
      return undefined;
    }
    if (obj.allowedExportFormats.length === 0) {
      logError('Expected allowedExportFormat to contain at least one value');
      return undefined;
    }
    settings.allowedExportFormats = obj.allowedExportFormats;
  }

  if (obj.enableConsoleCalls !== undefined) {
    if (typeof obj.enableConsoleCalls !== 'boolean') {
      logError('Expected enableConsoleCalls to be boolean.');
      return undefined;
    }
    settings.enableConsoleCalls = obj.enableConsoleCalls;
  }

  if (obj.useProxyForConsoleCalls !== undefined) {
    if (typeof obj.useProxyForConsoleCalls !== 'boolean') {
      logError('Expected useProxyForConsoleCalls to be boolean.');
      return undefined;
    }
    settings.useProxyForConsoleCalls = obj.useProxyForConsoleCalls;
  }

  if (obj.renameTypeScriptToNodejs) {
    if (typeof obj.renameTypeScriptToNodejs !== 'boolean') {
      logError('Expected renameTypeScriptToNodejs to be boolean.');
      return;
    }
    settings.renameTypeScriptToNodejs = obj.renameTypeScriptToNodejs;
  }

  if (obj.sideMenuTitleBehavior) {
    if (
      obj.sideMenuTitleBehavior === 'default' ||
      obj.sideMenuTitleBehavior === 'collapsible'
    ) {
      settings.sideMenuTitleBehavior = obj.sideMenuTitleBehavior;
    } else {
      logError(
        'Expected value of sideMenuTitleBehavior to be default or collapsible.'
      );
      return;
    }
  }

  if (
    (obj.languageSettings === null || obj.languageSettings === undefined) &&
    (obj.allowedLanguages === null || obj.allowedLanguages === undefined)
  ) {
    logError('Expected languageSettings to be an object.');
    return undefined;
  }
  if (obj.languageSettings) {
    if (!(obj.languageSettings instanceof Object)) {
      logError('Expected languageSettings to be an object.');
      return undefined;
    }
    if (Object.keys(obj.languageSettings).length === 0) {
      logError('Expected languageSettings to contain atleast one element.');
      return undefined;
    }
    if (obj.languageSettings.hasOwnProperty('docs-http')) {
      // rename docs-http to http_curl_v1 for legacy support
      const key = 'http_curl_v1';
      obj.languageSettings[key] = obj.languageSettings['docs-http'];
      delete obj.languageSettings['docs-http'];
    }

    if (
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      (Object.keys(obj.languageSettings) as Array<any>).some(
        (x) => validLanguages.indexOf(x) === -1
      )
    ) {
      logError('Expected languageSettings to contain valid language names.');
      return undefined;
    }
    settings.languageSettings = obj.languageSettings;
  } else if (obj.allowedLanguages) {
    if (!(obj.allowedLanguages instanceof Array)) {
      logError('Expected allowedLanguages to be an array.');
      return undefined;
    }
    if (obj.allowedLanguages.length === 0) {
      logError('Expected allowedLanguages to contain atleast one element.');
      return undefined;
    }
    // TODO: Replace with indexof
    if (obj.allowedLanguages.includes('docs-http')) {
      // rename docs-http to http_curl_v1 for legacy support
      const index = obj.allowedLanguages.indexOf('docs-http');
      obj.allowedLanguages.splice(index, 1);
      obj.allowedLanguages.push('http_curl_v1');
    }
    if (
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      (obj.allowedLanguages as Array<any>).some(
        (x) => validLanguages.indexOf(x) === -1
      )
    ) {
      logError('Expected allowedLanguages to contain valid language names.');
      return undefined;
    }

    const mapedAllowedLaguages = obj.allowedLanguages.reduce(function (
      result: Record<string, unknown>,
      item: string
    ) {
      result[item] = {};
      return result;
    },
    {});
    settings.languageSettings = mapedAllowedLaguages;
  }

  if (obj.initialPlatform) {
    if (typeof obj.initialPlatform !== 'string') {
      logError('Expected initialPlatform to be string.');
      return undefined;
    }

    if (
      obj.languageSettings &&
      !obj.languageSettings.hasOwnProperty(obj.initialPlatform)
    ) {
      logError('Expected initialPlatform to be in languageSettings.');
      return undefined;
    } else if (
      obj.allowedLanguages &&
      obj.allowedLanguages.indexOf(obj.initialPlatform) === -1
    ) {
      logError('Expected initialPlatform to be in allowedLanguages.');
      return undefined;
    }

    settings.initialPlatform = obj.initialPlatform;
  } else {
    if (obj.languageSettings) {
      const allowedLanguages = Object.keys(
        obj.languageSettings
      ) as Array<Language>;
      settings.initialPlatform = allowedLanguages[0];
    } else if (obj.allowedLanguage) {
      settings.initialPlatform = obj.allowedLanguages[0];
    }
  }

  if (obj.themeOverrides) {
    if (!obj.themeOverrides || typeof obj.themeOverrides !== 'object') {
      logError('Expected themeOverrides to be object.');
      return undefined;
    }

    if (
      !obj.themeOverrides.palette ||
      typeof obj.themeOverrides.palette !== 'object'
    ) {
      logError('Expected palette in themeOverrides to be object.');
      return undefined;
    }

    if (obj.themeOverrides.palette.primaryColor1) {
      obj.themeOverrides.palette.primaryColor =
        obj.themeOverrides.palette.primaryColor1;
    }

    if (!obj.themeOverrides.themeType) {
      obj.themeOverrides.themeType = 'cool';
    }

    if (
      !obj.themeOverrides.palette.primaryColor ||
      typeof obj.themeOverrides.palette.primaryColor !== 'string'
    ) {
      logError(
        'Expected primaryColor in palette in themeOverrides to be string.'
      );
      return undefined;
    }

    if (
      obj.themeOverrides.palette?.secondaryColor &&
      typeof obj.themeOverrides.palette?.secondaryColor !== 'string'
    ) {
      logError(
        'Expected secondaryColor in palette in themeOverrides to be string.'
      );
      return undefined;
    }

    if (!obj.themeOverrides.palette.linkColor) {
      obj.themeOverrides.palette.linkColor = defaultColors.link;
    }

    if (
      !obj.themeOverrides.palette.linkColor ||
      typeof obj.themeOverrides.palette.linkColor !== 'string'
    ) {
      logError('Expected linkColor in palette in themeOverrides to be string.');
      return undefined;
    }

    if (typeof obj.themeOverrides.themeType !== 'string') {
      logError('Expected themeType in palette in themeOverrides to be string.');
      return undefined;
    }

    if (obj.themeOverrides.fontSource) {
      settings.themeOverrides.fontSource = obj.themeOverrides.fontSource;
    }

    if (obj.themeOverrides.cssStyles) {
      if (
        obj.themeOverrides.cssStyles instanceof Array ||
        typeof obj !== 'object'
      ) {
        logError('Expected palette in themeOverrides to be object.');
        return undefined;
      }

      settings.themeOverrides.cssStyles = compareWithDefaultSettings(
        obj.themeOverrides.cssStyles,
        defaultCssStyles
      );
    } else {
      obj.themeOverrides.cssStyles = defaultCssStyles;
    }

    const paletteTheme =
      obj.themeOverrides.themeType === 'cool'
        ? defaultColors.grayCool
        : defaultColors.grayWarm;

    if (!obj.themeOverrides.palette.colors) {
      obj.themeOverrides.palette.colors = paletteTheme;
    }

    const themeColors = obj.themeOverrides.palette.colors;

    settings.themeOverrides = {
      ...obj.themeOverrides,
      palette: {
        ...obj.themeOverrides.palette,
        colors: {
          ...paletteTheme,
          ...themeColors,
          C001: themeColors.C001 ?? themeColors.C000 ?? paletteTheme.C001,
          C201: themeColors.C201 ?? themeColors.C200 ?? paletteTheme.C201,
          C801: themeColors.C801 ?? themeColors.C800 ?? paletteTheme.C801,
          C901: themeColors.C901 ?? themeColors.C900 ?? paletteTheme.C901,
        },
      },
    };
  }

  if (obj.codegenApiRoutes) {
    if (typeof obj.codegenApiRoutes !== 'object') {
      logError('Expected codegenApiRoutes to be object.');
      return undefined;
    }

    const routeObj = { ...defaultSettings.codegenApiRoutes };

    if (obj.codegenApiRoutes.transform) {
      if (typeof obj.codegenApiRoutes.transform === 'string') {
        routeObj.transform = obj.codegenApiRoutes.transform;
      } else {
        logError('Expected transform in codegenApiRoutes to be string.');
        return undefined;
      }
    }

    if (obj.codegenApiRoutes.docsgen) {
      if (typeof obj.codegenApiRoutes.docsgen === 'string') {
        routeObj.docsgen = obj.codegenApiRoutes.docsgen;
      } else {
        logError('Expected docsgen in codegenApiRoutes to be string.');
        return undefined;
      }
    }

    if (obj.codegenApiRoutes.codegen) {
      if (typeof obj.codegenApiRoutes.codegen === 'string') {
        routeObj.codegen = obj.codegenApiRoutes.codegen;
      } else {
        logError('Expected codegen in codegenApiRoutes to be string.');
        return undefined;
      }
    }

    if (obj.codegenApiRoutes.apiProxy) {
      if (typeof obj.codegenApiRoutes.apiProxy === 'string') {
        routeObj.apiProxy = obj.codegenApiRoutes.apiProxy;
      } else {
        logError('Expected apiProxy in codegenApiRoutes to be string.');
        return undefined;
      }
    }

    if (obj.codegenApiRoutes.apiProxy2) {
      if (typeof obj.codegenApiRoutes.apiProxy2 === 'string') {
        routeObj.apiProxy2 = obj.codegenApiRoutes.apiProxy2;
      } else {
        logError('Expected apiProxy2 in codegenApiRoutes to be string.');
        return undefined;
      }
    }

    if (obj.codegenApiRoutes.oauth2Callback) {
      if (typeof obj.codegenApiRoutes.oauth2Callback === 'string') {
        routeObj.oauth2Callback = obj.codegenApiRoutes.oauth2Callback;
      } else {
        logError('Expected oauth2Callback in codegenApiRoutes to be string.');
        return undefined;
      }
    }

    settings.codegenApiRoutes = routeObj;
  }

  settings.codegenApiRoutes.transform = makeAbsolutePath(
    settings.codegenApiRoutes.transform,
    settings.baseUrl
  );
  settings.codegenApiRoutes.docsgen = makeAbsolutePath(
    settings.codegenApiRoutes.docsgen,
    settings.baseUrl
  );
  settings.codegenApiRoutes.codegen = makeAbsolutePath(
    settings.codegenApiRoutes.codegen,
    settings.baseUrl
  );
  settings.codegenApiRoutes.apiProxy = makeAbsolutePath(
    settings.codegenApiRoutes.apiProxy,
    settings.baseUrl
  );
  settings.codegenApiRoutes.apiProxy2 = makeAbsolutePath(
    settings.codegenApiRoutes.apiProxy2,
    settings.baseUrl
  );
  settings.codegenApiRoutes.oauth2Callback = makeAbsolutePath(
    settings.codegenApiRoutes.oauth2Callback,
    settings.baseUrl
  );

  if (obj.renameHttpToRest !== undefined) {
    if (typeof obj.renameHttpToRest !== 'boolean') {
      logError('Expected renameHttpToRest to be boolean.');
      return undefined;
    }
    settings.renameHttpToRest = obj.renameHttpToRest;
  }

  if (obj.routeStyle !== undefined) {
    if (typeof obj.routeStyle !== 'string') {
      logError('Expected routeStyle to be boolean.');
      return undefined;
    }
    if (['hash', 'memory', 'browser'].indexOf(obj.routeStyle) === -1) {
      logError('Expected routeStyle to be "hash", "browser" or "memory".');
      return undefined;
    }
    settings.routeStyle = obj.routeStyle;
  }

  if (obj.enableAnalytics !== undefined) {
    if (typeof obj.enableAnalytics !== 'boolean') {
      logError('Expected enableAnalytics to be boolean.');
    }
    settings.enableAnalytics = obj.enableAnalytics;
  }

  return settings;
}

export function isObject(obj: object): boolean {
  if (obj instanceof Array || typeof obj !== 'object') {
    return false;
  }
  return true;
}

/**
 * Convert route into an absolute URL if it is not one
 */
function makeAbsolutePath(route: string, baseUrl: string) {
  return checkBaseRoute(route, baseUrl);
}

/**
 * Checks and processes a given route to ensure it starts with a valid base URL.
 * If the route already starts with 'http://' or 'https://', it is considered valid.
 * If not, it combines the provided base URL and the route, sanitizes the URL, and
 * replaces it with the corresponding value based on certain prefixes.
 *
 * @param {string} route - The route to be checked and processed.
 * @param {string} baseUrl - The base URL to prepend to the route.
 * @returns {string} - The processed route with a valid base URL.
 */
const checkBaseRoute = (route: string, baseUrl: string) => {
  if (route.indexOf('http://') === 0 || route.indexOf('https://') === 0) {
    return route;
  } else {
    const urlLinks = sanitizeUrl(baseUrl + route);

    return replaceURLString(urlLinks);
  }
};

/**
 * Replaces specific URL prefixes in the input route with corresponding values.
 * This function is used to map prefixes to different URLs for routing.
 *
 * @param {string} route - The route to be processed and have prefixes replaced.
 * @returns {string} - The route with prefixes replaced by their corresponding values.
 */
const replaceURLString = (route: string): string => {
  const replacements = [
    { prefix: 'https://www.apimatic.io', value: APIMATIC_URL_PROD },
    { prefix: 'https://api.apimatic.io', value: APIMATIC_URL_PROD },
    { prefix: 'https://dev.apimatic.io', value: APIMATIC_URL_DEV },
  ];

  for (const { prefix, value } of replacements) {
    const regex = new RegExp(`^${prefix}(\\/api)?\\/`);
    route = route.replace(regex, value);
  }

  return route;
};

/**
 * Removes duplicate forward slashes from route.
 *
 * @param url URL to clean
 * @returns Sanitized URL
 */
function sanitizeUrl(url: string): string {
  // ensure that the urls are absolute
  const protocolRegex = /^https?:\/\/[^/]+/;
  const match = url.match(protocolRegex);

  // remove redundant double-forward slashes
  const protocol = match === null ? '' : match[0];
  const queryUrl = url.substring(protocol.length).replace(/\/\/+/g, '/');
  return protocol + queryUrl;
}

/* istanbul ignore next */
export function logConsoleError(message: string) {
  /* eslint-disable  no-console */
  console.error(
    'APIMatic Portal Error: Validation of settings failed.\nError: ' + message
  );
}

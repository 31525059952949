/* eslint-disable max-len */
import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const Error403LightIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    viewBox={props.viewBox || '0 0 128 128'}
    width={props.width || '128'}
    height={props.height || '128'}
  >
    <circle fill="#EBF0F5" cx="64" cy="64" r="62" />
    <circle
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="64.7"
      cy="64"
      r="39.7"
    />
    <circle
      fill="#F6F6F9"
      stroke="#9CAAB8"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      cx="64.7"
      cy="64"
      r="29.1"
    />
    <path
      fill="#FF4451"
      stroke="#BA1E45"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      d="M72,58.6v5.9h-1V50.8c0-1-0.8-1.9-1.9-1.9h0
	c-1,0-1.9,0.8-1.9,1.9v13.7h-0.8v-16c0-1-0.8-1.9-1.9-1.9h0c-1,0-1.9,0.8-1.9,1.9v16h-0.9V50.8c0-1-0.8-1.9-1.9-1.9h0
	c-1,0-1.9,0.8-1.9,1.9v13.7h-0.7V54.4c0-1-0.8-1.9-1.9-1.9h0c-1,0-1.9,0.8-1.9,1.9v10.1v6c0,6.1,4.9,11,11,11h0c6.1,0,11-4.9,11-11
	v-6v-5.9c0-1-0.8-1.9-1.9-1.9l0,0C72.8,56.7,72,57.5,72,58.6z"
    />
  </SVGIcon>
);

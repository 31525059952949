import styled from 'styled-components';
import { CodeBox } from './AppLayout';
import { CodeBlock } from './StyledElements';
import { Div } from './CleanSlate';
import { sharedStyles } from './Sidebar/SidebarSkeleton';

const CodeWrapper = styled(Div)`
  padding: 41px 32px;
  width: 100%;
  height: 75%;
  overflow: hidden;
  > div:first-child {
    margin-left: 0px;
    width: 100px;
  }
`;

const CodeLines = styled(Div)`
  ${sharedStyles}
  height: 16px;
  margin-left: 18px;
  width: 80%;
  background-color: ${(props) =>
    props.theme.staticColors.CodeSampleSkeleton.C000};

  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.staticColors.CodeSampleSkeleton.C100} 0%,
    ${(props) => props.theme.staticColors.CodeSampleSkeleton.C200} 20%,
    ${(props) => props.theme.staticColors.CodeSampleSkeleton.C300} 40%,
    ${(props) => props.theme.staticColors.CodeSampleSkeleton.C400} 100%
  );
`;

export const CodeBoxLoader = () => {
  return (
    <CodeBox>
      <CodeBlock>
        <CodeWrapper>
          <CodeLines />
          <CodeLines />
          <CodeLines />
          <CodeLines />
          <CodeLines />
        </CodeWrapper>
      </CodeBlock>
    </CodeBox>
  );
};

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const CopyIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '21.944'}
    height={props.height || '21.944'}
    viewBox={props.viewBox || '0 0 21.944 21.944'}
    {...props}
  >
    <g transform="translate(-5 -5)">
      <rect
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
        width="16.205"
        height="16.205"
        rx="2"
        transform="translate(9.74 9.74)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
        d="M2,15.712V4.493A2.493,2.493,0,0,1,4.493,2H15.712"
        transform="translate(4 4)"
      />
    </g>
  </SVGIcon>
);

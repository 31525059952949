import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const WarningIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox={props.viewBox || '0 0 12 13'}
    fill="none"
  >
    <path
      d="M10.8642 9.67782L6.86423 2.45201C6.77701 2.29315 6.65053 2.16101 6.49769 2.06908C6.34485 1.97715 6.17113 1.92871 5.99423 1.92871C5.81734 1.92871 5.64361 1.97715 5.49077 2.06908C5.33793 2.16101 5.21145 2.29315 5.12423 2.45201L1.12423 9.67782C1.03607 9.83542 0.989846 10.0143 0.990237 10.1963C0.990627 10.3783 1.03762 10.5569 1.12646 10.7141C1.21529 10.8713 1.34281 11.0015 1.49608 11.0914C1.64935 11.1813 1.82294 11.2278 1.99923 11.2262H9.99923C10.1747 11.226 10.347 11.1782 10.4989 11.0875C10.6507 10.9968 10.7768 10.8665 10.8645 10.7096C10.9521 10.5527 10.9983 10.3748 10.9982 10.1937C10.9982 10.0126 10.952 9.83466 10.8642 9.67782Z"
      stroke="#C9190B"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 5.0332V7.09772"
      stroke={props.stroke || '#C9190B'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 9.16211H6.005"
      stroke={props.stroke || '#C9190B'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
);

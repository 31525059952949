import { createRef, PureComponent, RefObject } from 'react';
import styled from 'styled-components';

import Menu, { MenuItem, SubMenu, MenuKeyChangeEvent } from 'rc-menu';
import { RcMenuWrapper } from '../RcMenuWrapper';
import {
  TransformerTemplateDef,
  TransformerDef,
  filterExportFormats,
} from '../TransformerConstants';
import { PortalContextConsumer } from '../PortalContext';
import { A, Div } from 'src/CleanSlate';
import { makeApiFileExportLink } from 'src/ApiClient';
import { InlineNavbarProps } from './Navbar';
import { DownloadIcon, encodedDownloadIcon } from 'src/Icons/Ui/DownloadIcon';
import { PortalSettings } from 'src/PortalSettings';
import { apiSpecDownloadEvent } from 'src/Analytics';

type ExportMenuProps = {
  encodedDownloadIcon?: string;
  portalSettings: PortalSettings;
} & InlineNavbarProps;

type MenuWrapperProp = { encodedDownloadIcon?: string } & InlineNavbarProps;

const MenuWrapper = styled(RcMenuWrapper)<MenuWrapperProp>`
  height: 32px;
  margin-right: ${(props) => (props.isInlineNavbar ? '10px' : '0')};

  @media screen and (max-width: 990px) {
    margin-right: 5px;
  }

  @media screen and (max-width: 1200px) {
    margin-right: 0px;
    min-width: 100px;
  }

  @media screen and (max-width: 575px) {
    min-width: 74px;
    border-left: 4px solid ${(props) => props.theme.staticColors.Purple.C400};
    height: 100%;
    position: relative;
  }

  .rc-menu-root.rc-menu-horizontal.rc-menu {
    background-color: ${(props) => props.theme.colors.C001} !important;
    min-width: 105px;
    height: 32px;
    font-weight: 400 !important;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    border: 1px solid ${(props) => props.theme.colors.C300} !important;
    line-height: 30px;

    &:hover {
      border-color: ${(props) => props.theme.colors.C400} !important;
    }

    @media screen and (max-width: 1200px) {
      min-width: 96px;
    }

    @media screen and (max-width: 575px) {
      background: transparent !important;
      color: ${(props) => props.theme.colors.C801}!important;
      border-color: transparent !important;
      min-width: 70px;
      margin-top: 8px;

      &:hover {
        border-color: transparent !important;
      }
    }
  }

  .rc-menu-root.rc-menu-horizontal.rc-menu
    > .rc-menu-submenu.rc-menu-submenu-horizontal
    > .rc-menu-submenu-title {
    padding: 0;
    background: no-repeat no-repeat left center;
    /* background-image: url(${(props) => props.encodedDownloadIcon}) ;
    background-position: 5%; */
    padding: 0 !important;
    /* text-align: center;
    transform: translate(7%); */

    .rc-menu-submenu-arrow {
      display: none;
    }

    @media screen and (max-width: 575px) {
      background-position: 3%;
      transform: translate(0%);
    }
  }

  .rc-menu-horizontal > .rc-menu-submenu {
    width: 100%;
    border: none;
  }
  .rc-menu-submenu-title {
    width: 100%;
    padding: 0 11px !important;
    text-align: center;
    font-size: 14px;
    color: ${(props) => props.theme.colors.C801} !important;
    text-align: left;
    font-weight: 400;
    text-decoration: none;

    @media screen and (max-width: 575px) {
      background: transparent !important;
      color: ${(props) => props.theme.colors.C801}!important;
      border-color: transparent !important;
      padding: 0 11px !important;
      line-height: 50px;
    }
  }

  .rc-menu-submenu-selected {
    background-color: ${(props) => props.theme.colors.C001};

    @media screen and (max-width: 575px) {
      background: transparent !important;
    }
  }

  .rc-menu-submenu {
    z-index: 1;
  }

  .rc-menu-submenu .rc-menu {
    border: 1px solid ${(props) => props.theme.colors.C300};
    box-shadow: 0 0 4px ${(props) => props.theme.colors.C300};
  }

  .rc-menu-item-selected,
  .rc-menu-item-active {
    background-color: ${(props) => props.theme.colors.C100};
    color: ${(props) => props.theme.primaryColor};
  }

  .rc-menu-submenu-active > .rc-menu-submenu-title,
  .rc-menu-submenu-selected {
    background-color: ${(props) => props.theme.colors.C100};

    &:hover {
      background-color: ${(props) => props.theme.colors.C100};
      border-color: ${(props) => props.theme.colors.C001};
    }
  }
  .rc-menu-item,
  .rc-menu-item > a,
  .rc-menu-vertical > .rc-menu-item,
  .rc-menu-submenu > .rc-menu > .rc-menu-submenu,
  .rc-menu-submenu > .rc-menu > .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
  }
  .rc-menu-submenu > .rc-menu-submenu-title {
    padding: 10px 12px !important;
  }
  .rc-menu-item > a {
    padding: 10px 12px !important;
  }
  .rc-menu-horizontal .rc-menu-submenu-arrow {
    right: 8px;
    top: -2px !important;
    @media screen and (max-width: 575px) {
      line-height: 50px;
    }
  }
  .rc-menu-horizontal .rc-menu-submenu-arrow:before {
    border-top-color: ${(props) => props.theme.primaryColor};
  }

  .rc-menu-submenu-vertical
    > .rc-menu-submenu-title
    > .rc-menu-submenu-arrow:before {
  }
`;
MenuWrapper.displayName = 'MenuWrapper';

const Title = styled(Div)<InlineNavbarProps>`
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: ${(props) => (props.isInlineNavbar ? '0 18px' : '0 24px')};

  span {
    max-width: 65px;
    color: ${(props) => props.theme.colors.C901} !important;
    font-size: 13.33px;
    line-height: 24px;
    font-weight: 400;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 1200px) {
    padding: ${(props) => (props.isInlineNavbar ? '0 12px' : '0 15px')};

    span {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: 990px) {
    padding: 0px 11px;
  }

  @media screen and (max-width: 575px) {
    padding: 0px;
    justify-content: center;
    .download-icon {
      display: none;
    }
    span {
      margin: 0px;
    }
  }
`;
Title.displayName = 'Title';

export class ApiExportButton extends PureComponent<ExportMenuProps> {
  ref: RefObject<HTMLDivElement>;
  constructor(props: ExportMenuProps) {
    super(props);
    this.ref = createRef<HTMLDivElement>();
  }

  onSelect = (ev: MenuKeyChangeEvent) => {
    apiSpecDownloadEvent(this.props.portalSettings, ev.key);
  };

  render() {
    const { isInlineNavbar, portalSettings } = this.props;
    const filteredExportFormats = filterExportFormats(
      portalSettings.allowedExportFormats
    );
    return (
      <MenuWrapper
        isInlineNavbar={isInlineNavbar}
        encodedDownloadIcon={encodedDownloadIcon}
      >
        {/* Drop-down menu container */}
        <span ref={this.ref} />
        <Menu
          mode="horizontal"
          triggerSubMenuAction="click"
          selectable={true}
          onSelect={this.onSelect}
          // TODO Do not use lambda functions in render()
          getPopupContainer={() =>
            this.ref.current ? this.ref.current : document.body
          }
        >
          {menu(filteredExportFormats, isInlineNavbar)}
        </Menu>
      </MenuWrapper>
    );
  }
}

const SubMenuTitle = (isInlineNavbar?: boolean) => {
  return (
    <Title isInlineNavbar={isInlineNavbar}>
      <DownloadIcon
        width="12px"
        height="12px"
        className="download-icon"
        stroke="#282D44"
        fill="none"
      />
      <span>API Spec</span>
    </Title>
  );
};

function menu(
  filteredExportFormats: TransformerDef[],
  isInlineNavbar?: boolean
) {
  return (
    <SubMenu title={SubMenuTitle(isInlineNavbar)} key="apiSpec">
      {filteredExportFormats
        .slice(0, 3)
        .map((format) =>
          format.templates.length > 1
            ? subMenu(format)
            : menuItem(format.templates[0])
        )}

      {filteredExportFormats.length > 3 && (
        <SubMenu title="More Specs" key="moreSpecs">
          {filteredExportFormats
            .slice(3, filteredExportFormats.length)
            .map((format) =>
              format.templates.length > 1
                ? subMenu(format)
                : menuItem(format.templates[0])
            )}
        </SubMenu>
      )}
    </SubMenu>
  );
}

const MenuLink = styled(A)`
  text-decoration: none;
  font-size: 14px;
`;

function subMenu(format: TransformerDef) {
  return (
    <SubMenu title={format.name} key={format.value}>
      {format.templates.map((t) => menuItem(t))}
    </SubMenu>
  );
}

function menuItem(template: TransformerTemplateDef) {
  return (
    <MenuItem key={template.value}>
      <PortalContextConsumer>
        {(portalSettings) =>
          portalSettings && (
            <MenuLink
              href={makeApiFileExportLink(
                template.value,
                template.extension,
                portalSettings
              )}
              target="_blank"
            >
              {template.name}
            </MenuLink>
          )
        }
      </PortalContextConsumer>
    </MenuItem>
  );
}

import { WorkflowState } from 'src/Context/WorkflowContext';
import styled from 'styled-components';
import { Div } from '../CleanSlate';
import { Description } from './WorkflowStepDescription';
import { Name } from './WorkflowStepName';
import { StepTracker } from './WorkflowStepTracker';

const StepWrapper = styled(Div)<{ isDisabled?: boolean }>`
  display: flex;
  min-height: 84px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
`;

const ContentWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 8px;
  height: fit-content;
  margin-bottom: 37px;
`;

export interface StepProps {
  stepName: string;
  data: WorkflowState[string][string];
  selected: string;
  onStepClick: (x: string) => void;
}
function Step({ stepName, data, selected, onStepClick }: StepProps) {
  const isDisableStep = data?.status === 'incomplete';

  return (
    <StepWrapper isDisabled={isDisableStep}>
      <StepTracker status={data.status} />
      <ContentWrapper>
        <Name
          stepName={stepName}
          selected={selected}
          isDisabled={isDisableStep}
          onStepClick={onStepClick}
        />
        <Description description={data.name || ''} />
      </ContentWrapper>
    </StepWrapper>
  );
}

export default Step;

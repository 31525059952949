import { Div, H6, P } from '../CleanSlate';
import { AuthTokenDef } from '../DxDom';
import { ClockIcon } from '../Icons/Ui/ClockIcon';
import { CopyIcon } from '../Icons/Ui/CopyIcon';
import { FlexParent, IconButton } from '../StyledElements';
import { CopyToClipboardComp } from '../uiComponents/CopyToClipboard';
import { CountDownTimer } from '../Utilities/CountDownTimer';
import styled from 'styled-components';

const CalloutWrapperStyle = styled(Div)`
  width: 100%;
  background: #fff6d9;
  border-left: 4px solid #ffd550;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 21px;
`;

const CountDownTimerWrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${P} {
    color: #455666;
    font-size: 10px;
    margin: 0 5px;
  }
`;

const CalloutItemStyle = styled(Div)`
  margin-bottom: 5px;

  label,
  p {
    font-size: 13.33px;
  }

  label {
    color: #8e561d;
    font-weight: 600;
  }

  p {
    width: calc(100% - 21px);
    color: #455666;
    margin-top: 0;
    margin-bottom: 0;
    line-break: anywhere;
  }

  ${IconButton} {
    margin-left: 6px;
  }

  .copy-to-clip-board {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled(H6)`
  margin: 10px 0;
`;

const TokenText = styled(P)`
  margin: 0px 5px !important;
`;

interface CalloutItemsProps {
  label: string;
  value: string | number;
  copyEnabled?: boolean;
}

function CalloutItems(props: CalloutItemsProps) {
  const { label, value = '', copyEnabled } = props;

  return (
    <CalloutItemStyle>
      <label>{label}</label>
      <FlexParent>
        <p>{value}</p>
        {copyEnabled && (
          <CopyToClipboardComp
            iconButtonType="light"
            text={value as string}
            tooltip={{
              adjustTop: 30,
              adjustLeft: 19,
            }}
          >
            <IconButton width="15px">
              <CopyIcon width="10" height="10" stroke="#3e445d" fill="none" />
            </IconButton>
          </CopyToClipboardComp>
        )}
      </FlexParent>
    </CalloutItemStyle>
  );
}

interface AuthTokenCalloutProps {
  authToken: AuthTokenDef;
  onTokenExpiry: () => void;
  tokenTime?: string;
}

function calculateTokenDuration(
  authExpiry: number,
  tokenTime?: string
): number {
  let tokenDurationLeft = 0;
  const tokenStamp = tokenTime || localStorage.getItem('token_stamp');
  if (tokenStamp && authExpiry) {
    const timeDiff = Date.now() / 1000 - JSON.parse(tokenStamp);
    if (timeDiff > 0) {
      tokenDurationLeft = authExpiry - timeDiff;
    }
  }
  return tokenDurationLeft;
}

export function AuthTokenCallout(props: AuthTokenCalloutProps) {
  const { onTokenExpiry, authToken, tokenTime } = props;

  return (
    <CalloutWrapperStyle>
      <Title>Authorization Token</Title>
      {authToken.access_token && (
        <CalloutItems
          label="Access Token"
          value={authToken.access_token}
          copyEnabled={true}
        />
      )}
      {authToken.refresh_token && (
        <CalloutItems
          label="Refresh Token"
          value={authToken.refresh_token}
          copyEnabled={true}
        />
      )}
      {authToken.token_type && (
        <CalloutItems label="Token Type" value={authToken.token_type} />
      )}
      {authToken.expires_in && (
        <CountDownTimerWrapper>
          <ClockIcon />
          <TokenText>Token will expire in</TokenText>
          <CountDownTimer
            onStop={onTokenExpiry}
            duration={calculateTokenDuration(authToken.expires_in, tokenTime)}
          />
        </CountDownTimerWrapper>
      )}
    </CalloutWrapperStyle>
  );
}

import styled from 'styled-components';
import { Button, Div } from '../CleanSlate';
import {
  convertBase64ToArrayBuffer,
  tryGetFilenameFromContentDisposition,
  getHeaderValue,
} from '../HttpUtils';
import { prettyBytes } from './PrettyBytes';
import { ProxyResponseSuccessInterface } from './ProxyResponseInterface';
import { FileIcon } from '../Icons/Ui/FileIcon';

const FileViewerWrapper = styled(Div)`
  text-align: center;
  margin-top: 20px;
  color: #dddddd;

  svg {
    display: inline-block;
    margin-bottom: 15px;
  }
`;

const FileViewerButton = styled(Button)`
  background: transparent;
  border: 2px solid #cccccc;
  color: #dddddd;
  display: block;
  margin: 15px auto;

  &:hover {
    background: rgba(255, 255, 255, 0.07);
  }

  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
`;

/**
 * File download viewer for binary responses
 */
export function FileViewer(props: {
  response: ProxyResponseSuccessInterface;
  onDownload: () => void;
}) {
  const contentType = getHeaderValue(props.response.Headers, 'content-type');
  const contentDispHeader = getHeaderValue(
    props.response.Headers,
    'content-disposition'
  );
  const fileName = tryGetFilenameFromContentDisposition(contentDispHeader);
  const blobPart = convertBase64ToArrayBuffer(atob(props.response.RawContent));
  return (
    <FileViewerWrapper>
      <FileIcon width="84" fill="#dddddd" />
      {fileName && (
        <div>
          <strong>File</strong> → &nbsp;
          {fileName}
        </div>
      )}
      <div>
        <strong>Type</strong> → &nbsp;
        {contentType}
      </div>
      <div>
        <strong>Size</strong> → &nbsp;
        {prettyBytes(blobPart.byteLength)}
      </div>
      <FileViewerButton onClick={props.onDownload}>
        Download file
      </FileViewerButton>
    </FileViewerWrapper>
  );
}

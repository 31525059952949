import * as React from 'react';
import { ResponseHeaders } from '../DxDom';

/**
 * DocsGen API response headers context interface
 */
export interface ResponseHeaderContextProps {
  responseHeaders: ResponseHeaders;
}

/**
 * DocsGen API Response Header Context
 */

const ResponsHeaderContext = React.createContext<
  ResponseHeaderContextProps | undefined
>(undefined);

export interface ResponseHeaderContextProviderProps {
  responseHeaders: ResponseHeaders;
}

/**
 * A stateful container for DocsGen API response headers.
 *
 * Response Headers is passed to components that need it using ResponseHeaderConsumer.
 */

export class ResponseHeaderContextProvider extends React.Component<
  React.PropsWithChildren<ResponseHeaderContextProviderProps>,
  ResponseHeaderContextProps
> {
  constructor(props: ResponseHeaderContextProviderProps) {
    super(props);

    this.state = {
      responseHeaders: props.responseHeaders,
    };
  }

  render() {
    return (
      <ResponsHeaderContext.Provider value={this.state}>
        {this.props.children}
      </ResponsHeaderContext.Provider>
    );
  }
}

export const ResponseHeaderContextConsumer = ResponsHeaderContext.Consumer;

import React from 'react';
import PropTypes from 'prop-types';
import { getListRootItem, prefixClass as pfx } from '../../utils';
import { ContextConsumer } from '../context';

function DataType(props) {
  const {
    dataType,
    link,
    type,
    markdown,
    containerKind,
    containerName,
    linkTo,
  } = props;

  if (!dataType && !markdown) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }

  if ((dataType && containerKind) || markdown) {
    // TODO: Will be removed when DataTypeMarkdown will be Removed for Docs.
    const label =
      !dataType && markdown ? getListRootItem(markdown) : containerKind;

    return (
      <ContextConsumer>
        {({ renderTypesPopover }) =>
          renderTypesPopover(markdown, label, containerName, linkTo, dataType)
        }
      </ContextConsumer>
    );
  }

  return (
    <div
      className={pfx(
        type !== 'schema' ? type : link ? 'object-type' : 'base-type'
      )}
    >
      {link ? (
        <ContextConsumer>
          {({ onRouteChange }) => (
            <a onClick={() => onRouteChange(link)}>{dataType}</a>
          )}
        </ContextConsumer>
      ) : (
        dataType
      )}
    </div>
  );
}

/* istanbul ignore else */
if (process.env.NODE_ENV !== 'production') {
  DataType.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    dataType: PropTypes.string,
  };
}

export default DataType;

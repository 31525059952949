import * as React from 'react';
import { Div } from './CleanSlate';

interface HandleOutsideClickProps {
  onOutsideClick?(element: HTMLElement): void;
  targetRefrance?:
    | React.RefObject<HTMLButtonElement>
    | React.RefObject<HTMLDivElement>;
}

interface HandleOutsideClickState {
  outsideClick: boolean;
}

export class HandleOutsideClick extends React.PureComponent<
  React.PropsWithChildren<HandleOutsideClickProps>,
  HandleOutsideClickState
> {
  state: HandleOutsideClickState = {
    outsideClick: false,
  };

  currentElement: React.RefObject<HTMLDivElement>;

  constructor(props: HandleOutsideClickProps) {
    super(props);
    this.currentElement = React.createRef();
  }

  componentDidMount() {
    // Add event listeners to detect clicks outside the component
    document.body.addEventListener('click', this.handleOutsideClick, true);
    document.body.addEventListener('touchstart', this.handleOutsideClick, true);
  }

  componentWillUnmount() {
    // Remove event listeners when the component is unmounted
    document.body.removeEventListener('click', this.handleOutsideClick, true);
    document.body.removeEventListener(
      'touchstart',
      this.handleOutsideClick,
      true,
    );
  }

  handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    // Check if the click was outside of this component
    if (
      this.currentElement.current &&
      !this.currentElement.current.contains(event.target as Node)
    ) {
      this.setState({
        outsideClick: true,
      });

      // Call the onOutsideClick handler if provided
      if (
        this.props.onOutsideClick &&
        !this.props.targetRefrance?.current?.contains(event.target as Node)
      ) {
        this.props.onOutsideClick(event.target as HTMLElement);
      }
    }
  };

  render() {
    return (
      <Div id="outsideClickWrapper" ref={this.currentElement}>
        {this.props.children}
      </Div>
    );
  }
}

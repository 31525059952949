import { JSONSchema6 } from 'json-schema';
import {
  ParamInfo,
  ReferenceType,
  Section,
  Document as DxDocument,
  ContainerReference,
  ContainerType,
} from 'src/DxDom';
import { LinkMapper } from 'src/LinkMapperContext';

interface ObjectType {
  properties?: ObjectType & { [key: string]: object };
  items?: ObjectType;
  additionalProperties?: ObjectType;
  dataTypeDisplayText?: string;
  dataTypeLink?: string;
  paramType?: string;
  type: string;
  oneOf?: Array<ObjectType>;
  anyOf?: Array<ObjectType>;
  allOf?: Array<ObjectType>;
  typeCombinatorTypes?: Array<ContainerType>;
}

export function getDescription(type: ParamInfo) {
  return type.Description
    ? type.Description.trim() !== '-'
      ? type.Description
      : undefined
    : undefined;
}

function getDataTypeDisplayText(type: ParamInfo) {
  return type.DataType ? type.DataType : undefined;
}

// Extract base Class Fields to merge data-type in inherited structure
// Supports multi level inheritance: a inherited from b inherited from c
function extractBaseClassFields(
  modelStructure: ReferenceType,
  LinkTo: string
): ReadonlyArray<ParamInfo> {
  const baseClassModel = extractFromStructureArray(modelStructure, LinkTo);
  let nestedBaseClassFields: ReadonlyArray<ParamInfo> = [];

  if ('Fields' in baseClassModel) {
    if (baseClassModel.BaseClassLink) {
      nestedBaseClassFields = extractBaseClassFields(
        modelStructure,
        baseClassModel.BaseClassLink
      );
    }
    return [...nestedBaseClassFields, ...baseClassModel.Fields];
  }
  return nestedBaseClassFields;
}

function extractFromStructureArray(
  modelStructure: ReferenceType,
  LinkTo: string
) {
  const structure = modelStructure.filter((e) => e.SuggestedLink === LinkTo)[0];

  if ('Fields' in structure && structure.BaseClassLink) {
    const baseClassFields = extractBaseClassFields(
      modelStructure,
      structure.BaseClassLink
    );

    return {
      ...structure,
      Fields: [...baseClassFields, ...structure.Fields],
    };
  }

  return structure;
}

function getUrl(linkMapper: LinkMapper, link: string) {
  return linkMapper(link);
}

function findFieldInParams(
  params: ReadonlyArray<ParamInfo>,
  name: string
): ParamInfo | null {
  const i = params.findIndex(
    (p) =>
      typeof p.GenericName === 'string' &&
      p.GenericName.toLowerCase() === name.toLowerCase()
  );
  return i === -1 ? null : params[i];
}

// Tackles 2 types of datatypes
// any<any<any>>
// any<any, any>
// For a more error prone solution get the inner type seperately from backend
function extractInnerTypeFromDataType(type: string) {
  const i = type.indexOf('<');

  if (i && i !== -1) {
    const updatedType = type.slice(i + 1, -1);
    // TODO: Replace with indexof
    if (updatedType.includes('<')) {
      return updatedType;
      // TODO: Replace with indexof
    } else if (updatedType.includes(',')) {
      const updatedType2 = updatedType.split(',').pop();
      return updatedType2 ? updatedType2 : '';
    }

    return updatedType;
  }

  return type;
}

function determineType(
  globals: {
    structure: ReferenceType;
    linkMapper: LinkMapper;
  },
  LinkTo: string | undefined,
  dataTypeDisplayText: string | undefined
) {
  let type1;
  let type2;

  // get type from model structure
  if (LinkTo) {
    const extractedInfo = extractFromStructureArray(globals.structure, LinkTo);

    type2 = extractedInfo.Title;
  }

  // get type from Datatype if that can be determined
  if (dataTypeDisplayText) {
    type1 = extractInnerTypeFromDataType(dataTypeDisplayText);
    type2 = type2 ? type2 : extractInnerTypeFromDataType(type1); // Extract from array type
  }

  if (type1 === dataTypeDisplayText) {
    type1 = undefined;
  }
  if (type2 === dataTypeDisplayText) {
    type2 = undefined;
  }

  return { type1, type2 };
}

function generateAdditionalProperties(type: ParamInfo, linkMapper: LinkMapper) {
  return {
    additionalDescription: type.AdditionalDescription,
    description: getDescription(type),
    dataTypeDisplayText: getDataTypeDisplayText(type),
    dataTypeLink: type.LinkTo ? getUrl(linkMapper, type.LinkTo) : undefined,
    linkTo: type.LinkTo,
    dataTypeMarkdown: type.DataTypeMarkdown,
    paramType: type.ParamType,
    title: type.Name,
    typeCombinatorTypes: type.TypeCombinatorTypes,
    discriminator: type.Discriminator,
    discriminatorValue: type.DiscriminatorValue,
    readOnly: type.ReadOnly,
    writeOnly: type.WriteOnly,
    containerKind: type.ContainerKind,
    containerName: type.ContainerName,
    isJsonViewDisabled: type.DisableJsonView,
  };
}

function deepMergeScheme(
  globals: {
    structure: ReferenceType;
    linkMapper: LinkMapper;
    modelSchemas: object;
  },
  theObject: ObjectType,
  LinkTo: string,
  parentObj?: ObjectType,
  params?: ReadonlyArray<ParamInfo>
) {
  if (typeof theObject === 'object' && theObject.properties) {
    // OBJECT CASE
    const extractedInfo = params
      ? { Fields: params }
      : extractFromStructureArray(globals.structure, LinkTo);

    if (extractedInfo && 'Fields' in extractedInfo) {
      for (const prop in theObject.properties) {
        if (theObject.properties[prop]) {
          const type = findFieldInParams(extractedInfo.Fields, prop);
          if (type) {
            const additionalProperties = generateAdditionalProperties(
              type,
              globals.linkMapper
            );
            theObject.properties[prop] = {
              ...theObject.properties[prop],
              ...additionalProperties,
            };

            const linkTo = type.LinkTo ? type.LinkTo : '';

            deepMergeScheme(
              globals,
              theObject.properties[prop] as ObjectType,
              linkTo,
              theObject.properties[prop] as ObjectType
            );
          }
        }
      }
    }
  } else if (typeof theObject === 'object' && theObject.items) {
    // ARRAY CASE

    const mapTypes = determineType(
      globals,
      LinkTo,
      theObject.dataTypeDisplayText
    );

    if (theObject.items.additionalProperties) {
      theObject.items.dataTypeDisplayText = mapTypes.type1;
    } else {
      theObject.items.dataTypeDisplayText = mapTypes.type2;
    }

    theObject.items.dataTypeLink = theObject.dataTypeLink;

    if (
      theObject.items.oneOf ||
      theObject.items.anyOf ||
      theObject.typeCombinatorTypes
    ) {
      theObject.items.typeCombinatorTypes = theObject.typeCombinatorTypes;
    }

    deepMergeScheme(globals, theObject.items, LinkTo, theObject);
  } else if (typeof theObject === 'object' && theObject.additionalProperties) {
    // MAP CASE
    if (parentObj) {
      const mapTypes = determineType(
        globals,
        LinkTo,
        parentObj.dataTypeDisplayText
      );

      theObject.additionalProperties.dataTypeLink = parentObj.dataTypeLink;

      let nextObj;

      // set the types in case of map of array
      if (theObject.additionalProperties.items) {
        theObject.additionalProperties.dataTypeDisplayText = mapTypes.type1;
        theObject.additionalProperties.items.dataTypeDisplayText =
          mapTypes.type2;
        theObject.additionalProperties.items.dataTypeLink =
          parentObj.dataTypeLink;

        nextObj = theObject.additionalProperties.items;

        if (nextObj.oneOf || nextObj.anyOf || theObject.typeCombinatorTypes) {
          nextObj.typeCombinatorTypes = theObject.typeCombinatorTypes;
        }
      } else if (theObject.additionalProperties.properties) {
        // set the types in case of map of object
        theObject.additionalProperties.dataTypeDisplayText = mapTypes.type2;
        nextObj = theObject.additionalProperties;
      }

      if (nextObj) {
        deepMergeScheme(globals, nextObj, LinkTo, parentObj);
      }
    }
  } else if (
    typeof theObject === 'object' &&
    (theObject.hasOwnProperty('oneOf') ||
      theObject.hasOwnProperty('anyOf') ||
      theObject.hasOwnProperty('allOf'))
  ) {
    const list = (theObject.oneOf ||
      theObject.anyOf ||
      theObject.allOf) as ObjectType[];

    if (LinkTo) {
      const extractedInfo = (
        params
          ? { Fields: params }
          : extractFromStructureArray(globals.structure, LinkTo)
      ) as ContainerReference;

      list.forEach((item, index) => {
        const linkTo = extractedInfo.ContainerTypes
          ? extractedInfo.ContainerTypes[index].LinkTo
          : LinkTo;

        deepMergeScheme(globals, item, linkTo, theObject);
      });
    } else {
      list.forEach((item, index) => {
        const type = (theObject.typeCombinatorTypes &&
          theObject.typeCombinatorTypes[index]) || {
          LinkTo: undefined,
          DataType: undefined,
        };
        const linkTo = type.LinkTo || '';

        if (type.DataType === 'OneOf' || type.DataType === 'AnyOf') {
          //@ts-ignore
          item.typeCombinatorTypes = type.SubTypes;
        }

        deepMergeScheme(globals, item, linkTo, theObject);
      });
    }
  }
}

export function mergeSchemaWithParameters(
  globals: {
    structure: ReferenceType;
    linkMapper: LinkMapper;
    modelSchemas: object;
  },
  schema: JSONSchema6,
  params: ReadonlyArray<ParamInfo> | undefined
) {
  try {
    const newObj = JSON.parse(JSON.stringify(schema)) as {
      properties: { args: unknown };
    };
    const args = newObj.properties.args as JSONSchema6;

    if (params && args) {
      Object.keys(args).forEach((e) => {
        if (args) {
          deepMergeScheme(
            globals,
            newObj.properties.args as ObjectType,
            '',
            newObj.properties.args as ObjectType,
            params
          );
        }
      });
    }

    return newObj;
  } catch {
    /* eslint-disable  no-console */
    console.log('Team APIMATIC: Unable to merge data-types');
    return schema;
  }
}

/**
 * This function will check if base level have any object or array type
 * @param schema JSONSchema6 Received from API
 */
export function isExpandable(schema: JSONSchema6) {
  if (schema.properties) {
    if (schema.properties.args) {
      const temp = schema.properties.args as {
        properties: object & {
          [key: string]: {
            type: string;
          };
        };
      };
      if (temp.properties) {
        return (
          Object.keys(temp.properties).findIndex(
            (e) =>
              temp.properties[e].type === 'object' ||
              temp.properties[e].type === 'array'
          ) !== -1
        );
      }
    }
  }

  return false;
}

/**
 * Extract All Structure and EnumReferences Recursively
 * @param section Section
 */
function extractStructureAndEnumReference(section: Section) {
  let arr: ReferenceType = [];

  for (let i = 0; i < section.Nodes.length; i++) {
    const obj = section.Nodes[i];
    if (obj.Type === 'section') {
      arr = [...arr, ...extractStructureAndEnumReference(obj)];
    }
  }

  const st = section.Nodes.filter(
    (s) =>
      s.Type === 'structurereference' ||
      s.Type === 'enumreference' ||
      s.Type === 'containerreference' ||
      s.Type === 'typecombinatorcontainerreference'
  ).map((e) => ({
    ...e,
    SuggestedLink: section.SuggestedLink,
  }));

  return [...st, ...arr] as ReferenceType;
}

/**
 * Extract All StructureReferences, EnumReferences and ContainerReferences from Doc
 * This assumes that every linkable section will only have
 * one element of type (enumreference, structurereference, containerReferences)
 * The function can handle sections within sections assuming that the path of every section within doc is unique
 * @param doc Document Received from API
 */
export function extractModelStructureFromDocument(doc: DxDocument) {
  try {
    let modelStructures: ReferenceType = [];
    for (let i = 0; i < doc.Sections.length; i++) {
      modelStructures = [
        ...modelStructures,
        ...extractStructureAndEnumReference(doc.Sections[i]),
      ];
    }
    return modelStructures;
  } catch (e) {
    /* eslint-disable  no-console */
    console.log(
      'Team APIMATIC: Unable to extract model strcutures for merging data-types'
    );
    return [];
  }
}

import { SVGIcon, SVGIconProps } from '../SvgIcon';

export const SearchIcon = (props: SVGIconProps) => (
  <SVGIcon
    width={props.width || '11.811px'}
    height={props.height || '11.811px'}
    viewBox={props.viewBox || '0 0 11.811 11.811'}
    {...props}
  >
    <g transform="translate(0.75 0.75)">
      <path
        fill={props.fill || 'none'}
        stroke={props.stroke || '#858ba5'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
        d="M13,13,9.667,9.667m1.111-2.778A3.889,3.889,0,1,1,6.889,3,3.889,3.889,0,0,1,10.778,6.889Z"
        transform="translate(-3 -3)"
      />
    </g>
  </SVGIcon>
);

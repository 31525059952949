import { ReactNode, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css, CSSProperties } from 'styled-components';

import { renderCssStyles } from 'src/Theme/Theme';
import { Div, Ul, Span, Button, Li, P } from 'src/CleanSlate';
import { CrossIcon, TypeLinkIcon } from 'src/Icons';
import { POPOVER_BTN_ID } from './PopoverButton';
import { ScrollableMixin } from 'src/StyledElements';
import { HandleOutsideClick } from 'src/HandleOutsideClick';
import { StyledLink } from 'src/Markdown';
import { LinkMapperContext } from 'src/LinkMapperContext';
import { checkIfDataTypeIsLarge } from '.';

interface TypesPopoverProps {
  title?: string;
  children: ReactNode;
  refFunc: (instance: HTMLElement | null) => void;
  toggleVisibility: VoidFunction;
  style: CSSProperties;
  useAlwaysPortal?: boolean;
  linkTo?: string;
}

const TypesPopoverStyled = styled(Div)<{ isExpand: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  flex-direction: column;
  background: ${(props) => props.theme.staticColors.Purple.C400} 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  border-radius: 6px;
  z-index: 1000;

  ${({ isExpand }) =>
    isExpand
      ? css`
          position: fixed;
          left: 20px;
          top: 20px;
          width: calc(100vw - 40px);
          height: calc(100vh - 40px);
        `
      : css`
          width: 100%;
          max-width: 400px;
        `};

  .popover-header {
    min-height: 40px;
    max-height: 80px;
    background: ${(props) => props.theme.colors.C201};
    padding: 12px 30px 12px 18px;
    padding-right: 30px;
    ${({ theme: { cssStyles } }) => renderCssStyles(cssStyles.body.text1)};
    font-weight: 600;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    overflow: auto;
    overflow-x: hidden;
    ${P} {
      font-size: ${({ theme: { cssStyles } }) =>
        (cssStyles && cssStyles.body.text2.fontSize) || '13.33px'};
      margin: 0px;
    }

    ${Span} {
      flex: 1;
      display: flex;
      align-items: center;

      &.popover-header-link-icon {
        a {
          margin-left: 20px;
          font-size: ${({ theme: { cssStyles } }) =>
            (cssStyles && cssStyles.body.text3.fontSize) || '11px'};
        }
        svg {
          margin-right: 2px;
          position: relative;
          top: 1px;
          stroke: ${(props) => props.theme.linkColor};
          pointer-events: none;
        }
      }
    }

    ${Button} {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 12px;
      padding: 0;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }

  .popover-body {
    max-height: ${({ isExpand }) => (isExpand ? 'unset' : '190px')};
    padding: 15px 25px;
    overflow: auto;

    ${P} {
      margin: 0px;
    }

    ${ScrollableMixin}

    ${({ theme: { cssStyles } }) => renderCssStyles(cssStyles.body.text3)};

    ${Ul} {
      margin: 0;
    }
    ${Li} {
      font-family: ${(props) => props.theme.cssStyles.code.fontFamily};
      line-height: normal;
    }
  }
`;

const Background = styled(Div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.C1000};
  opacity: 0.5;
  z-index: 1000;
`;

const POPOVER_ID = 'types-popover';

const DEFAULT_TITLE = 'Data types';

export function TypesPopover(props: TypesPopoverProps) {
  const {
    title,
    style,
    children,
    useAlwaysPortal,
    refFunc,
    toggleVisibility,
    linkTo,
  } = props;

  const [isExpand] = useState(false);

  const onOutsideClick = useCallback(
    (event: HTMLElement) => {
      if (event.id !== POPOVER_BTN_ID) {
        toggleVisibility();
      }
    },
    [toggleVisibility]
  );

  const isLong = title && checkIfDataTypeIsLarge(title, 30);

  const RenderedElement = (
    <HandleOutsideClick onOutsideClick={onOutsideClick}>
      {isExpand ? <Background /> : null}
      <TypesPopoverStyled
        id={POPOVER_ID}
        className="popover-content"
        ref={refFunc}
        style={isExpand ? {} : style}
        isExpand={isExpand}
      >
        <Div className="popover-header">
          <Span>
            <P>{title || DEFAULT_TITLE}</P>
            {linkTo && !isLong && (
              <Span className="popover-header-link-icon">
                <LinkMapperContext.Consumer>
                  {(linkMapper) => (
                    <StyledLink to={{ pathname: linkMapper(linkTo) || '' }}>
                      <TypeLinkIcon />
                      View Model
                    </StyledLink>
                  )}
                </LinkMapperContext.Consumer>
              </Span>
            )}
          </Span>
          <Button onClick={toggleVisibility}>
            {<CrossIcon stroke="#6b7e8f" />}
          </Button>
        </Div>
        <Div className="popover-body">{children}</Div>
      </TypesPopoverStyled>
    </HandleOutsideClick>
  );

  return useAlwaysPortal || isExpand
    ? createPortal(RenderedElement, document.body)
    : RenderedElement;
}

import * as React from 'react';

import { getSdkDocument } from './ApiClient';
import { Document as DxDocument } from './DxDom';
import { LinkMapper } from './LinkMapperContext';
import { Language, PortalSettings } from './PortalSettings';
import { CancellablePromise, makeCancellable } from './CancellablePromise';
import { LiquidJS } from '@dx-portal/utils-liquid';
import { ReadyArgs } from './Context/EventsContext';

export interface DocLoaderProps {
  tpl: Language;
  portalSettings: PortalSettings;
  readyArgsInstance: ReadyArgs;
  children: (value: DocLoaderState, refresh: () => void) => JSX.Element;
}

export interface DocLoaderState {
  // SDK document if loaded
  doc?: DxDocument;
  // Link mapper for SDK document if loaded
  linkMapper?: LinkMapper;
  // Is SDK document loading?
  loading?: boolean;
  // Template name for currently loaded or failed SDK document
  currentTpl?: string;
  // Did the last SDK document failed to load?
  isError?: boolean;

  // Status code of call on error
  statusCode?: number;

  liquidInstance: LiquidJS;
}

/**
 * Loads SDK document and passes it to children.
 */
export class DocLoader extends React.Component<DocLoaderProps, DocLoaderState> {
  docLoadingPromise: CancellablePromise<[DxDocument, LinkMapper]>;

  static getDerivedStateFromProps(
    nextProps: DocLoaderProps,
    prevState: DocLoaderState
  ): DocLoaderState | null {
    if (nextProps.tpl !== prevState.currentTpl && !prevState.loading) {
      return {
        ...prevState,
        loading: true,
        isError: false,
      };
    } else {
      return null;
    }
  }

  constructor(props: DocLoaderProps) {
    super(props);

    this.state = {
      currentTpl: props.tpl,
      loading: true,
      liquidInstance: new LiquidJS(),
    };

    this.docLoadingPromise = makeCancellable(Promise.reject(null));
    this.docLoadingPromise.promise.catch(() => true);
    this.docLoadingPromise.cancel();
  }

  loadDocument(tpl: Language, refresh?: boolean) {
    const dxTpl = tpl.toUpperCase();
    this.setState(
      (prevState: Readonly<DocLoaderState>, props: DocLoaderProps) => {
        this.docLoadingPromise.cancel();
        if (refresh) {
          getSdkDocument.cache.delete(dxTpl);
        }
        this.docLoadingPromise = makeCancellable(
          getSdkDocument(dxTpl, props.portalSettings)
        );
        this.docLoadingPromise.promise
          .then((doc) => {
            this.setState({
              doc: {
                ...doc[0],
                DataModel: doc[0]?.DataModel,
              },
              linkMapper: doc[1],
              loading: false,
              currentTpl: tpl,
              isError: false,
              liquidInstance: new LiquidJS(doc[0].PartialTemplates),
            });
          })
          .catch((err?: { isCancelled?: boolean; statusCode?: number }) => {
            if (err && err.isCancelled) {
              return;
            }
            this.setState({
              doc: undefined,
              linkMapper: undefined,
              loading: false,
              currentTpl: tpl,
              isError: true,
              statusCode: err?.statusCode,
            });
          });
      }
    );
  }

  /**
   * Try loading the SDK document again
   */
  refresh = () => {
    this.setState({ loading: true, isError: false }, () => {
      this.loadDocument(this.props.tpl, true);
    });
  };

  componentDidUpdate(prevProps: DocLoaderProps, prevState: DocLoaderState) {
    if (this.props.tpl !== prevProps.tpl) {
      this.loadDocument(this.props.tpl);
    }
  }

  componentDidMount() {
    this.loadDocument(this.props.tpl);
  }

  componentWillUnmount() {
    // cancel promise to prevent it from resolving and attempting to mutate state
    // after this component is unmounted
    this.docLoadingPromise.cancel();
  }

  render() {
    return this.props.children(this.state, this.refresh);
  }
}

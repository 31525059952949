import * as React from 'react';
import styled from 'styled-components';

import { LinkMapperContext, LinkMapper } from 'src/LinkMapperContext';
import {
  DefaultButtonMixin,
  Link,
  PrimaryButton,
  PrimaryButtonMixin,
  Scrollable,
} from '../StyledElements';
import { ThemeInterface } from '../StyledComponents';
import { Div, A, H5, P, Span } from '../CleanSlate';
import { PublishedPackage } from '../DxDom';
import { PortalSettings, Language } from '../PortalSettings';
import {
  DownloadSdkButtonComp,
  DownloadSdkButtonStyle,
} from './DownloadSdkButton';
import { GitHubIcon } from '../Icons/Ui/GitHubIcon';
import { HandleOutsideClick } from 'src/HandleOutsideClick';
import { GetSdkButtonStyle } from './GetSdk';
import {
  sdkDownloadSuccessEvent,
  viewSDKInstallationInstructionsEvent,
  visitedSDKRepoLinkViaDocsEvent,
  visitedSDKSourceCodeLinkViaDocsEvent,
} from 'src/Analytics';
import { filterPlatforms } from 'src/PlatformConstants';
import { getLanguageIcon } from 'src/Platform/PlatformSelector';
import { PackageCheckIcon, DownloadIcon2, LinkIcon2 } from 'src/Icons';
import { CopyToClipboardComp } from 'src/uiComponents/CopyToClipboard';
import { staticColors } from 'src/Color';

export const GET_SDK_BUTTON_ID = 'get-sdk-button';

const PopupWrappersStyle = styled(Div)`
  background: ${(props: { theme: ThemeInterface }) => props.theme.colors.C001};
  width: 100%;
  max-width: 436px;
  box-shadow: ${(props) => props.theme.colors.C400} 0px 3px 6px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.C300};
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 60px;
  z-index: 999;

  ${DownloadSdkButtonStyle} {
    margin: 0;
  }

  @media screen and (max-width: 990px) {
    top: 55px;
    right: 20px;
  }

  @media screen and (max-width: 575px) {
    right: 8px;
  }
`;

const PopupHeaderStyle = styled(Div)`
  display: flex;
  align-items: center;
  margin: 0;
  max-height: 60px;
  background: ${({ theme }) => theme.staticColors.Snow.C300};
`;

const PopupHeaderTitleIconStyle = styled(Div)`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const PopupHeaderTitleStyle = styled(H5)`
  color: ${(props) => props.theme.colors.C901};
  margin: 10px 0 10px 20px;
  display: flex;
  align-items: center;
`;

const PopupBodyStyle = styled(Div)`
  margin: 20px;
  border-radius: 0 0 8px 8px;

  ${Link} {
    display: block;
    text-align: center;
    font-size: 11.85px;
  }

  .package-instruction-content {
    justify-content: space-between;
    align-items: center;
    > p {
      color: ${({ theme }) => theme.colors.C801};
      font-size: 11.85px;
      margin-bottom: 0;
      > a {
        margin-left: 5px;
        > svg {
          margin-left: 5px;
        }
      }
    }
  }
`;

const InstallationCommand = styled(Div)``;

const InstallationCommandBody = styled(Div)``;

const InstallationInstruction = styled(Div)<{ InstallCommand?: boolean }>`
  display: flex;
  margin-top: ${(props) => (props.InstallCommand ? '6px' : '0px')};
`;

const InstallationIcons = styled(Span)`
  display: flex;
`;

const PopupSectionStyle = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const ActionButtonWrapper = styled(Div)`
  display: flex;
  gap: 12px;
`;

const PopupSectionTitleStyle = styled(P)<{ hasInstallationSection?: boolean }>`
  color: ${(props) => props.theme.staticColors.Goose.C100};
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: ${(props) => (props.hasInstallationSection ? '32px' : '0px')};
`;

const RepoLinkStyle = styled(A)<{ InstallCommand?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px !important;
  line-height: 14.52px !important;
  margin-top: ${(props) => (props.InstallCommand ? '6px' : '0px')};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > svg {
    stroke: ${({ theme }) => theme.linkColor};
  }
`;

const InstallPackageLink = styled(Link)<{ InstallCommand?: boolean }>`
  display: flex !important;
  align-items: center;
  font-size: 12px !important;
  line-height: 14.52px !important;
  margin-top: ${(props) => (props.InstallCommand ? '6px' : '0px')};
  > svg {
    stroke: ${({ theme }) => theme.linkColor};
  }
`;

const ViewSourceStyle = styled(PrimaryButton)`
  ${PrimaryButtonMixin};
  display: flex;
  justify-content: start;
  padding: 0 24px 0 0 !important;
  gap: 24px;
`;

const IconSection = styled(Span)`
  background-color: ${({ theme }) => theme.colors.C001};
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ViewSourceSecondaryStyle = styled(PrimaryButton)`
  ${DefaultButtonMixin};
  gap: 6px;
  display: flex;
  align-items: center;
`;

const SdkDownloadLinkStyle = styled(A)`
  ${DefaultButtonMixin}
  gap: 6px;
  display: flex;
  align-items: center;
`;

// Copy Installation Command Styles

const CopyContainer = styled(Div)`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.staticColors.Snow.C100};
  border-radius: 8px;
  padding: 5px 12px;
  background-color: ${({ theme }) => theme.staticColors.Snow.C400};
`;

const CommandText = styled(Scrollable)`
  color: ${({ theme }) => theme.staticColors.Unique.C100};
  height: 24px;
  font-family: 'Courier New', Courier, monospace;
  white-space: nowrap;
  width: 333px;
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const Line = styled(Div)<{
  height?: string;
  borderWidth?: string;
  borderColor?: string;
  margin?: string;
}>`
  height: ${(props) => props.height ?? '0px'};
  width: 0px;
  border-left: ${(props) => props.borderWidth ?? '2px'} solid
    ${(props) => props.borderColor ?? props.theme.staticColors.Goose.C300};
  margin: ${(props) => props.margin ?? '8px 12px 0px'};
`;

interface PopupCompProps {
  packageInfo?: PublishedPackage;
  portalSettings: PortalSettings;
  currentTemplate: Language;
  sdkDownloadLink: string | undefined;
  selectedPlatform: string;
  isInlineNavbar: boolean;
}

interface PopupCompState {
  togglePopup: boolean;
}

export class PopupComp extends React.Component<PopupCompProps, PopupCompState> {
  DOMNode?: React.RefObject<HTMLDivElement>;
  constructor(props: PopupCompProps) {
    super(props);
    this.state = {
      togglePopup: false,
    };
  }

  togglePopup = () => {
    this.setState((prevState) => ({
      togglePopup: !prevState.togglePopup,
    }));
  };

  onOutsideClick = (element: HTMLElement) => {
    if (element.id !== GET_SDK_BUTTON_ID) {
      this.setState({
        togglePopup: false,
      });
    }
  };

  renderPopup = (linkMapper: LinkMapper) => {
    const {
      packageInfo,
      portalSettings,
      portalSettings: { languageSettings },
      currentTemplate,
      sdkDownloadLink,
      isInlineNavbar,
    } = this.props;

    const filteredPlatforms = filterPlatforms([currentTemplate]);
    const disableSdkDownload =
      languageSettings[currentTemplate]?.disableSdkDownload;
    const enableViewSource = !!packageInfo?.SourceLink;
    const enableInstructions = !!packageInfo?.Version;
    const installPackageRoute = linkMapper(packageInfo?.InstallHeadingLink);
    const tooltipAdjustment = {
      adjustTop: 38,
      adjustLeft: 24,
    };

    const onActionButtonClick = (
      logEvent: (portalSettings: PortalSettings, template: string) => void
    ) => {
      logEvent(portalSettings, currentTemplate);
      this.togglePopup();
    };

    return (
      <HandleOutsideClick onOutsideClick={this.onOutsideClick}>
        <PopupWrappersStyle>
          <PopupHeaderStyle>
            <PopupHeaderTitleStyle>
              <PopupHeaderTitleIconStyle>
                {getLanguageIcon(filteredPlatforms[0].iconKey, true)}
              </PopupHeaderTitleIconStyle>
              {filteredPlatforms[0].title} SDK
            </PopupHeaderTitleStyle>
          </PopupHeaderStyle>
          <PopupBodyStyle>
            {packageInfo?.Name && (
              <InstallationCommand>
                {packageInfo?.InstallCommand && (
                  <PopupSectionTitleStyle>
                    PACKAGE INSTALLATION COMMAND
                  </PopupSectionTitleStyle>
                )}
                <InstallationCommandBody>
                  {packageInfo?.InstallCommand && (
                    <CopyContainer>
                      <CommandText
                        overflowX={true}
                        overflowY={false}
                        scrollHeight="2px"
                      >
                        {packageInfo?.InstallCommand}
                      </CommandText>
                      <Line
                        height="24px"
                        margin="0px 10px 0px 6px"
                        borderWidth="1px"
                        borderColor={staticColors.Snow.C100}
                      />
                      <InstallationIcons>
                        <CopyToClipboardComp
                          text={packageInfo?.InstallCommand}
                          iconButtonType="basic"
                          from={GET_SDK_BUTTON_ID}
                          tooltip={tooltipAdjustment}
                        />
                      </InstallationIcons>
                    </CopyContainer>
                  )}

                  <InstallationInstruction
                    InstallCommand={Boolean(packageInfo?.InstallCommand)}
                  >
                    {enableInstructions && installPackageRoute && (
                      <InstallPackageLink
                        to={installPackageRoute}
                        title={installPackageRoute}
                        onClick={() =>
                          onActionButtonClick(
                            viewSDKInstallationInstructionsEvent
                          )
                        }
                        InstallCommand={Boolean(packageInfo?.InstallCommand)}
                      >
                        <PackageCheckIcon
                          stroke={
                            portalSettings.themeOverrides.palette.linkColor
                          }
                        />
                        {`How to set up SDK`}
                      </InstallPackageLink>
                    )}
                    {enableInstructions && installPackageRoute && (
                      <Line
                        height={'13px'}
                        margin={
                          packageInfo?.InstallCommand
                            ? '8px 12px 0px'
                            : '2px 12px 0px'
                        }
                      />
                    )}
                    {packageInfo?.Link && (
                      <RepoLinkStyle
                        href={packageInfo?.Link}
                        target="_blank"
                        title={packageInfo?.Link}
                        onClick={() =>
                          visitedSDKRepoLinkViaDocsEvent(
                            portalSettings,
                            currentTemplate
                          )
                        }
                        InstallCommand={Boolean(packageInfo?.InstallCommand)}
                      >
                        <LinkIcon2 />
                        {`View Package on ${packageInfo.Provider}`}
                      </RepoLinkStyle>
                    )}
                  </InstallationInstruction>
                </InstallationCommandBody>
              </InstallationCommand>
            )}
            <PopupSectionStyle>
              {packageInfo?.Name &&
                (enableViewSource || !disableSdkDownload) && (
                  <PopupSectionTitleStyle
                    hasInstallationSection={Boolean(
                      packageInfo?.InstallCommand ||
                        packageInfo?.InstallCommand ||
                        enableInstructions
                    )}
                  >
                    OTHER SOURCES
                  </PopupSectionTitleStyle>
                )}
              <ActionButtonWrapper>
                {enableViewSource &&
                  (packageInfo?.Name ? (
                    <ViewSourceSecondaryStyle
                      as={A}
                      href={packageInfo && packageInfo.SourceLink}
                      target="_blank"
                      onClick={() =>
                        onActionButtonClick(
                          visitedSDKSourceCodeLinkViaDocsEvent
                        )
                      }
                    >
                      <GitHubIcon width="15" height="15" />
                      View Source
                    </ViewSourceSecondaryStyle>
                  ) : (
                    <ViewSourceStyle
                      as={A}
                      href={packageInfo && packageInfo.SourceLink}
                      target="_blank"
                      onClick={() =>
                        onActionButtonClick(
                          visitedSDKSourceCodeLinkViaDocsEvent
                        )
                      }
                    >
                      <IconSection>
                        <GitHubIcon width="15" height="15" />
                      </IconSection>
                      View Source
                    </ViewSourceStyle>
                  ))}
                {!disableSdkDownload && sdkDownloadLink && (
                  <SdkDownloadLinkStyle
                    href={sdkDownloadLink}
                    target="_blank"
                    onClick={() => onActionButtonClick(sdkDownloadSuccessEvent)}
                  >
                    <DownloadIcon2 />
                    Download SDK
                  </SdkDownloadLinkStyle>
                )}
                {!disableSdkDownload && !sdkDownloadLink && (
                  <DownloadSdkButtonComp
                    template={currentTemplate}
                    portalSettings={portalSettings}
                    name="Download SDK"
                    typeSecondary
                    isInlineNavbar={isInlineNavbar}
                    from={GET_SDK_BUTTON_ID}
                  />
                )}
              </ActionButtonWrapper>
            </PopupSectionStyle>
          </PopupBodyStyle>
        </PopupWrappersStyle>
      </HandleOutsideClick>
    );
  };

  render() {
    const {
      portalSettings: { enableExport },
      isInlineNavbar,
    } = this.props;
    return (
      <Div>
        <GetSdkButtonStyle
          id={GET_SDK_BUTTON_ID}
          onClick={this.togglePopup}
          enableExport={enableExport}
          isInlineNavbar={isInlineNavbar}
        >
          Get SDK
        </GetSdkButtonStyle>
        <LinkMapperContext.Consumer>
          {(linkMapper) =>
            this.state.togglePopup && this.renderPopup(linkMapper)
          }
        </LinkMapperContext.Consumer>
      </Div>
    );
  }
}

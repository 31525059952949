import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { guidedWalkThroughEvents } from 'src/Analytics';
import { Document, EnumReference } from 'src/DxDom';
import { RSectionDocument } from 'src/DxDomRender';
import { LinkMapper } from 'src/LinkMapperContext';
import { Markdown } from 'src/Markdown';
import { PortalContext } from 'src/PortalContext';
import { getWalkthroughContentType } from 'src/Utilities/utility';
import { createPath } from 'history';
import { useLocation } from 'react-router-dom';
import { DataModelContext } from 'src/DataModelContext';

const WALKTHROUGH_NEXT_STEP = 'Guided Walkthrough Next Step';
const WALKTHROUGH_RESET_STEP = 'Guided Walkthrough Reset Step';
const WALKTHROUGH_FINISH_STEP = 'Guided Walkthrough Finish';

export type WorkflowStateItems = {
  name?: string;
  stepCallback: (prevState: unknown) => Promise<JSX.Element>;
  isSelected?: boolean;
  status?: 'incomplete' | 'current' | 'complete';
  state?: unknown;
  verified?: boolean;
  error?: string;
  isContent?: boolean;
  formData?: unknown;
};

export type WorkflowState = Record<string, Record<string, WorkflowStateItems>>;

export interface ShowEndpointParams {
  endpointPermalink: string;
  description?: string;
  args?: unknown;
  verify: (response: unknown, setError: (error: string) => void) => boolean;
}

export interface WorkflowContextType {
  setWorkflowState: React.Dispatch<React.SetStateAction<WorkflowState>>;
  showContent: (source: string) => Promise<JSX.Element>;
  getShowEndpoint: (
    docRef: React.MutableRefObject<Document>,
    hasCodeSamplesBox: (hasCodeBox: boolean) => void,
  ) => (params: ShowEndpointParams) => Promise<JSX.Element>;
  onStepClick: (workflowName: string, selectedStep: string) => void;
  onNextClick: (workflowName: string) => void;
  resetToInitialState: (workflowName: string) => void;
  onBackClick: (workflowName: string) => void;
  onNextPreview: (workflowName: string) => void;
  getSelectedWorkflow: (linkMapper?: LinkMapper) => {
    workflowName?: string;
    workflowSteps?: WorkflowState[string];
    selectedWorkflowStep?: string;
  };
  setStepState: (
    workflowName: string,
    data: unknown,
    verify?: ShowEndpointParams['verify'],
  ) => void;
  setVerified: (workflowName: string, isContent?: boolean) => void;
  onResetClick: (workflowName: string) => void;
  onBackRef?: React.MutableRefObject<() => void | null>;
  formDataRef?: React.MutableRefObject<Record<string, unknown>>;
  titles: Record<string, string>;
  setTitles: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  resetCallbackRef?: React.MutableRefObject<(() => void) | undefined>;
}
interface GetStepsReturnType {
  selectedStepName?: string;
  selectedStepValue?: WorkflowState[string][string];
  nextStepName?: string;
  nextStepValue?: WorkflowState[string][string];
  backStepName?: string;
  backStepValue?: WorkflowState[string][string];
}

export interface WorkflowEndpointContextType {
  payload?: unknown;
  verify?: ShowEndpointParams['verify'];
}

export const WorkflowContext = createContext<WorkflowContextType>({
  setWorkflowState: () => {},
  showContent: async () => {
    return <div />;
  },
  getShowEndpoint: (doc, hasCodeSamplesBox) => async () => {
    return <div />;
  },
  onStepClick() {},
  onNextClick() {},
  onBackClick() {},
  onNextPreview() {},
  getSelectedWorkflow() {
    return {};
  },
  setStepState() {},
  setVerified() {},
  onResetClick() {},
  titles: {},
  setTitles() {},
  resetToInitialState() {},
});

const WorkflowContextProvider = WorkflowContext.Provider;

export const WorkflowContextConsumer = WorkflowContext.Consumer;

export const WorkflowEndpointContext =
  createContext<WorkflowEndpointContextType>({
    verify() {
      return true;
    },
  });

export const WorkflowEndpointProvider = WorkflowEndpointContext.Provider;

export const WorkflowEndpointConsumer = WorkflowEndpointContext.Consumer;

function getEndpoint(
  endpointPermalink: string,
  sections: Document['Sections'],
) {
  return sections.find((se) => {
    return se.SuggestedLink === endpointPermalink;
  });
}

export function getSteps(
  workflowSteps?: WorkflowState[string],
): GetStepsReturnType {
  if (workflowSteps) {
    let selectedIndex = 0;

    const workflowStepsArr = Object.entries(workflowSteps);
    const selectedStep = workflowStepsArr.find(([, stepValue], index) => {
      if (stepValue.isSelected) {
        selectedIndex = index;
      }
      return stepValue.isSelected;
    });
    const nextStep = workflowStepsArr[selectedIndex + 1];
    const backStep = workflowStepsArr[selectedIndex - 1];
    return {
      selectedStepName: selectedStep?.[0],
      selectedStepValue: selectedStep?.[1],
      nextStepName: nextStep?.[0],
      nextStepValue: nextStep?.[1],
      backStepName: backStep?.[0],
      backStepValue: backStep?.[1],
    };
  } else {
    return {};
  }
}

export function getWorkflowStepsState(
  workflowSteps?: WorkflowState[string],
): Record<string, unknown> {
  if (workflowSteps) {
    return Object.entries(workflowSteps).reduce<Record<string, unknown>>(
      (prev, [stepName, stepValue]) => {
        const state: unknown = stepValue.state ? { ...stepValue.state } : {};
        prev[stepName] = state;
        return prev;
      },
      {},
    );
  } else {
    return {};
  }
}

function resetWorkflow(
  workflowSteps: WorkflowState[string],
): WorkflowState[string] {
  let selectedIndex: number;
  return Object.entries(workflowSteps).reduce<WorkflowState[string]>(
    (prev, [stepName, stepValue], index) => {
      if (stepValue.isSelected) {
        selectedIndex = index;
        prev[stepName] = {
          ...stepValue,
          status: 'current',
          error: undefined,
          state: undefined,
          formData: undefined,
          verified: stepValue.isContent ?? undefined,
        };
      } else if (selectedIndex !== undefined && selectedIndex < index) {
        prev[stepName] = {
          ...stepValue,
          status: 'incomplete',
          error: undefined,
          verified: undefined,
          isSelected: false,
          state: undefined,
          formData: undefined,
        };
      } else {
        prev[stepName] = {
          ...stepValue,
        };
      }

      return prev;
    },
    {},
  );
}

function selectedWorkflow(
  selectedStep: string,
  workflowSteps: WorkflowState[string],
): WorkflowState[string] {
  return Object.entries(workflowSteps).reduce<WorkflowState[string]>(
    (prev, curr) => {
      const [stepName, stepValue] = curr;
      if (stepName === selectedStep) {
        return {
          ...prev,
          [stepName]: {
            ...stepValue,
            isSelected: true,
          },
        };
      } else if (stepValue.isSelected) {
        return {
          ...prev,
          [stepName]: {
            ...stepValue,
            isSelected: false,
            error: undefined,
          },
        };
      }

      return { ...prev, [stepName]: stepValue };
    },
    {},
  );
}

export const Workflow: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [workflowState, setWorkflowState] = useState<WorkflowState>({});
  const [titles, setTitles] = useState<Record<string, string>>({});
  const [selectedWorkflowStep, setSelectedWorkflowStep] = useState<string>('');

  const portalSettings = useContext(PortalContext);
  const dataModelContext = useContext(DataModelContext);

  const onBackRef: WorkflowContextType['onBackRef'] = useRef(() => {});
  const formDataRef = useRef<Record<string, unknown>>({});
  const resetCallbackRef = useRef<() => void>();
  const errorStepState = useRef<WorkflowStateItems | null>(null);
  const location = createPath(useLocation());

  const showContent = useCallback(async (source: string) => {
    return <Markdown source={source} />;
  }, []);

  const getShowEndpoint: WorkflowContextType['getShowEndpoint'] = useCallback(
    (docRef, hasCodeSamplesBox) => async (params) => {
      const doc = docRef.current;
      const endpointSection = getEndpoint(
        params.endpointPermalink,
        doc.Sections,
      );

      if (endpointSection) {
        const { Nodes, ...restSection } = { ...endpointSection };
        const [nodeItem, ...nodes] = Nodes;
        const node = {
          ...nodeItem,
          Description: params.description,
          PlaygroundTitle: (nodeItem as EnumReference).Title,
        };

        return (
          <WorkflowEndpointProvider
            value={{ payload: { args: params.args }, verify: params.verify }}
          >
            <RSectionDocument
              hasWorkFlow={true}
              section={{ ...restSection, Nodes: [node, ...nodes] }}
              activeLanguage={
                dataModelContext?.activeLanguage || 'http_curl_v1'
              }
              hasCodeSamplesBox={hasCodeSamplesBox}
            />
          </WorkflowEndpointProvider>
        );
      } else {
        return <div />;
      }
    },
    [],
  );

  const onStepClick: WorkflowContextType['onStepClick'] = useCallback(
    (workflowName, selectedStep) => {
      setWorkflowState((st) => {
        const workflowSteps = st[workflowName];
        const updatedSteps = selectedWorkflow(selectedStep, workflowSteps);
        setSelectedWorkflowStep(selectedStep);
        if (
          !(
            workflowSteps[selectedStep]?.isSelected &&
            updatedSteps[selectedStep]?.status === 'current'
          ) &&
          updatedSteps[selectedStep]?.status !== 'incomplete'
        ) {
          onBackRef.current?.();
          return { ...st, [workflowName]: updatedSteps };
        } else {
          return st;
        }
      });
    },
    [],
  );

  const onNextClick: WorkflowContextType['onNextClick'] = useCallback(
    (workflowName: string) => {
      setWorkflowState((st) => {
        const workflow = { ...st[workflowName] };
        const { selectedStepName, selectedStepValue, nextStepName } =
          getSteps(workflow);

        nextStepName && setSelectedWorkflowStep(nextStepName);

        if (
          nextStepName &&
          selectedStepName &&
          selectedStepValue?.status === 'complete'
        ) {
          const updatedSteps = selectedWorkflow(nextStepName, workflow);

          onBackRef.current?.();
          return { ...st, [workflowName]: updatedSteps };
        } else if (
          nextStepName &&
          selectedStepName &&
          selectedStepValue?.status === 'current'
        ) {
          workflow[selectedStepName].status = 'complete';
          workflow[selectedStepName].isSelected = false;

          if (formDataRef.current) {
            workflow[selectedStepName].formData =
              formDataRef.current[selectedStepName];
          }

          workflow[nextStepName].status = 'current';
          workflow[nextStepName].isSelected = true;

          const stepType = getWalkthroughContentType(
            selectedStepValue?.isContent,
          );

          portalSettings &&
            guidedWalkThroughEvents(
              WALKTHROUGH_NEXT_STEP,
              portalSettings,
              workflowName,
              selectedStepName,
              stepType,
            );

          onBackRef.current?.();

          return { ...st, [workflowName]: workflow };
        } else if (!nextStepName && selectedStepName) {
          portalSettings &&
            guidedWalkThroughEvents(
              WALKTHROUGH_FINISH_STEP,
              portalSettings,
              workflowName,
              selectedStepName,
            );
          workflow[selectedStepName].status = 'complete';
          if (formDataRef.current) {
            workflow[selectedStepName].formData =
              formDataRef.current[selectedStepName];
          }
          return { ...st, [workflowName]: workflow };
        } else {
          return st;
        }
      });
    },
    [portalSettings],
  );

  const onBackClick: WorkflowContextType['onBackClick'] = useCallback(
    (workflowName: string) => {
      setWorkflowState((st) => {
        const workflow = { ...st[workflowName] };
        const { selectedStepName, backStepName } = getSteps(workflow);

        if (backStepName && selectedStepName) {
          workflow[selectedStepName].isSelected = false;
          workflow[selectedStepName].error = undefined;
          workflow[backStepName].isSelected = true;

          onBackRef.current?.();

          return { ...st, [workflowName]: workflow };
        }

        return st;
      });
    },
    [],
  );

  const onNextPreview: WorkflowContextType['onNextPreview'] = useCallback(
    (workflowName: string) => {
      setWorkflowState((st) => {
        const workflow = { ...st[workflowName] };
        const { selectedStepName, nextStepName } = getSteps(workflow);

        if (nextStepName && selectedStepName) {
          workflow[selectedStepName].isSelected = false;
          workflow[selectedStepName].error = undefined;
          workflow[nextStepName].isSelected = true;

          onBackRef.current?.();

          return { ...st, [workflowName]: workflow };
        }

        return st;
      });
    },
    [],
  );

  const getSelectedWorkflow: WorkflowContextType['getSelectedWorkflow'] =
    useCallback(
      (linkMapper) => {
        const registeredPages = Object.entries(workflowState);
        const selectedWorkflow = registeredPages.find(([pageLink, value]) => {
          const mappedUrl = linkMapper?.(pageLink);
          return mappedUrl === location;
        });
        const workflowSteps = selectedWorkflow?.[1];
        const workflowName = selectedWorkflow?.[0];

        return {
          workflowName,
          workflowSteps,
          selectedWorkflowStep,
        };
      },
      [selectedWorkflowStep, workflowState, location],
    );

  const getSetError = useCallback(
    (workflowName: string, stateData: WorkflowState, data: unknown) =>
      (error = '') => {
        const workflowSteps = stateData[workflowName];
        const { selectedStepName, selectedStepValue } = getSteps(workflowSteps);

        if (selectedStepName && selectedStepValue) {
          const updatedWorkflowSteps: WorkflowStateItems = {
            ...selectedStepValue,
            state: data,
            error,
            verified: false,
          };
          errorStepState.current = updatedWorkflowSteps;
        } else {
          errorStepState.current = null;
        }
      },
    [],
  );

  const setStepState: WorkflowContextType['setStepState'] = useCallback(
    (workflowName, data, verify) => {
      setWorkflowState((st) => {
        const workflow = st[workflowName];
        const { selectedStepName, selectedStepValue } = getSteps(workflow);
        if (selectedStepName && selectedStepValue) {
          const setErrorState = getSetError(workflowName, st, data);
          const verified = verify?.(data, setErrorState);

          const updatedWorkflow: WorkflowState[string] = {
            ...workflow,
            [selectedStepName]: {
              ...selectedStepValue,
              state: data,
              verified,
              error: verified ? undefined : errorStepState.current?.error,
            },
          };
          return { ...st, [workflowName]: updatedWorkflow };
        } else {
          return st;
        }
      });
    },
    [getSetError, setWorkflowState],
  );

  const setVerified: WorkflowContextType['setVerified'] = useCallback(
    (workflowName, isContent) => {
      setWorkflowState((st) => {
        const workflowSteps = st[workflowName];
        const { selectedStepName, selectedStepValue } = getSteps(workflowSteps);
        if (selectedStepName && selectedStepValue) {
          const updatedWorkflowSteps: WorkflowState[string] = {
            ...workflowSteps,
            [selectedStepName]: {
              ...selectedStepValue,
              verified: true,
              error: undefined,
              isContent,
            },
          };

          return { ...st, [workflowName]: updatedWorkflowSteps };
        } else {
          return st;
        }
      });
    },
    [],
  );

  const onResetClick: WorkflowContextType['onResetClick'] = useCallback(
    (workflowName: string) => {
      setWorkflowState((st) => {
        const workflowSteps = st[workflowName];

        const { selectedStepName = '' } = getSteps(workflowSteps);

        const updatedWorkflowSteps = resetWorkflow(workflowSteps);

        if (formDataRef.current) {
          formDataRef.current[selectedStepName] = undefined;
        }

        resetCallbackRef.current?.();
        portalSettings &&
          guidedWalkThroughEvents(
            WALKTHROUGH_RESET_STEP,
            portalSettings,
            workflowName,
            selectedStepName,
          );

        onBackRef.current?.();

        return { ...st, [workflowName]: { ...updatedWorkflowSteps } };
      });
    },
    [portalSettings],
  );

  const resetToInitialState: WorkflowContextType['resetToInitialState'] =
    useCallback((workflowName) => {
      setWorkflowState((st) => {
        const workflowSteps = st[workflowName];
        if (workflowSteps) {
          const firstStepName = Object.keys(workflowSteps)?.[0];
          const updateSelectionWorkflowSteps = selectedWorkflow(
            firstStepName,
            workflowSteps,
          );
          const updatedWorkflowSteps = resetWorkflow(
            updateSelectionWorkflowSteps,
          );

          return { ...st, [workflowName]: { ...updatedWorkflowSteps } };
        } else {
          return st;
        }
      });
    }, []);

  return (
    <WorkflowContextProvider
      value={{
        showContent,
        onStepClick,
        onNextClick,
        onBackClick,
        onNextPreview,
        setStepState,
        setVerified,
        onResetClick,
        getShowEndpoint,
        setWorkflowState,
        getSelectedWorkflow,
        onBackRef,
        setTitles,
        titles,
        resetToInitialState,
        formDataRef,
        resetCallbackRef,
      }}
    >
      {children}
    </WorkflowContextProvider>
  );
};

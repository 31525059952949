import { SVGIcon, SVGIconProps } from './SvgIcon';

export const BetaTagIcon = (props: SVGIconProps) => (
  <SVGIcon
    {...props}
    width={props.width || '28'}
    height={props.height || '11'}
    viewBox={props.viewBox || '0 0 28 11'}
    fill={props.fill || 'none'}
  >
    <g clip-path="url(#clip0_538_13010)">
      <rect width="28" height="11" rx="2" fill="#FF4451" />
      <path
        d="M5.59253 7.99998V3.27271H7.64225C8.00233 3.27271 8.30471 3.32118 8.54938 3.41812C8.7956 3.51507 8.98102 3.65203 9.10567 3.82899C9.23185 4.00596 9.29494 4.21447 9.29494 4.45452C9.29494 4.62841 9.25647 4.78691 9.17953 4.93002C9.10413 5.07313 8.99795 5.19316 8.861 5.29011C8.72404 5.38551 8.564 5.45168 8.38088 5.48861V5.53478C8.58401 5.54247 8.76867 5.59402 8.93486 5.68943C9.10105 5.7833 9.23339 5.91333 9.33188 6.07952C9.43036 6.24418 9.4796 6.43807 9.4796 6.6612C9.4796 6.91972 9.41189 7.14978 9.27648 7.35136C9.1426 7.55295 8.95179 7.71145 8.70404 7.82686C8.45628 7.94227 8.16083 7.99998 7.81767 7.99998H5.59253ZM6.87591 6.97512H7.47605C7.69149 6.97512 7.85306 6.93511 7.96078 6.85509C8.0685 6.77353 8.12236 6.65351 8.12236 6.49501C8.12236 6.38421 8.09697 6.29034 8.04619 6.2134C7.99541 6.13646 7.92308 6.07798 7.82921 6.03798C7.73688 5.99797 7.62532 5.97796 7.49452 5.97796H6.87591V6.97512ZM6.87591 5.18393H7.40219C7.51452 5.18393 7.61378 5.16623 7.69995 5.13084C7.78613 5.09544 7.85306 5.04466 7.90077 4.97849C7.95001 4.91079 7.97463 4.82846 7.97463 4.73151C7.97463 4.58532 7.92231 4.47376 7.81767 4.39682C7.71303 4.31834 7.58069 4.2791 7.42065 4.2791H6.87591V5.18393Z"
        fill="white"
      />
      <path
        d="M9.93433 7.99998V3.27271H13.3413V4.3068H11.2177V5.1193H13.1659V6.15339H11.2177V6.96589H13.3321V7.99998H9.93433Z"
        fill="white"
      />
      <path
        d="M13.8485 4.3068V3.27271H17.9572V4.3068H16.5353V7.99998H15.2704V4.3068H13.8485Z"
        fill="white"
      />
      <path
        d="M19.0715 7.99998H17.6865L19.2469 3.27271H21.0012L22.5615 7.99998H21.1766L20.1425 4.57455H20.1056L19.0715 7.99998ZM18.813 6.13492H21.4167V7.09515H18.813V6.13492Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_538_13010">
        <rect width="28" height="11" fill="white" />
      </clipPath>
    </defs>
  </SVGIcon>
);
